import React from 'react';
import './article.css';
import {NavLink} from "react-router-dom";

const Article = ({imgURL, date, title, path}) => {
    return (
        <div className="em__blog-container_article">
            <div className="em__blog-container_article-image">
                <img src={imgURL} alt="blog"/>
            </div>
            <div className="em__blog-container_article-content">
                <div>
                    {date && <p>{date}</p>}
                    <h3><NavLink to={path}>{title}</NavLink></h3>
                </div>
                {
                    date && <p><NavLink to={path}>Read the post.</NavLink></p>
                }
            </div>
        </div>
    );
}

export default Article
