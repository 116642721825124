import React from "react";
import "./mcu_hal34.css";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";

const stm32_udp_server_h = `
#ifndef INC_UDP_SERVER_H_
#define INC_UDP_SERVER_H_

void upd_server_init();

#endif
`;

const stm32_udp_server_c = `
#include "udp_server.h"
#include "lwip/pbuf.h"
#include "lwip/udp.h"

#include "stdio.h"

void udp_receive_callback(void *arg, struct udp_pcb *upcb, struct pbuf *p, const ip_addr_t *addr, u16_t port){
    struct pbuf *txBuf;
    char *remoteIP = ipaddr_ntoa(addr);
    char buf[100];
    
    int len = sprintf(buf, "Hello %s from UDP server.\\r\\n", (char*)p->payload);
    txBuf = pbuf_alloc(PBUF_TRANSPORT, len, PBUF_RAM);
    pbuf_take(txBuf, buf, len);
    udp_connect(upcb, addr, port);
    udp_send(upcb, txBuf);
    udp_disconnect(upcb);
    pbuf_free(txBuf);
    pbuf_free(p);
}

void upd_server_init(){
    struct udp_pcb *upcb;
    err_t err;
    
    upcb = udp_new();
    
    ip_addr_t myIPADDR;
    IP_ADDR4(&myIPADDR, 192, 168, 69, 123);
    
    err = udp_bind(upcb, &myIPADDR, 7);
    
    if(err == ERR_OK)
        udp_recv(upcb, udps_receive_callback, NULL);
    else
        udp_remove(upcb);
}
`;

const python_udp_client = `
import socket

if __name__ == "__main__":
    msgFromClient = "Client"
    bytesToSend = str.encode(msgFromClient)
    serverAddressPort = ("192.168.69.123", 7)
    
    bufferSize = 1024
    
    UDPClientSocket = socket.socket(family=socket.AF_INET, type=socket.SOCK_DGRAM)
    
    UDPClientSocket.sendto(bytesToSend, serverAddressPort)
    msgFromServer = UDPClientSocket.recvfrom(bufferSize)
    msg = "Message from Server {}".format(msgFromServer[0])
    print(msg)
`;

const stm32_udp_client_h = `
#ifndef INC_UDP_CLIENT_H_
#define INC_UDP_CLIENT_H_

void udp_client_connect();

#endif
`;

const stm32_udp_client_c = `
#include "udp_client.h"
#include "lwip/pbuf.h"
#include "lwip/udp.h"

#include "stdio.h"
#include "string.h"


struct udp_pcb *upcb;
char buffer[100];
int counter = 0;

extern TIM_HandleTypeDef htim1;

void udp_receive_callback(void *arg, struct udp_pcb *upcb, struct pbuf *p, const ip_addr_t *addr, u16_t port){
    strncpy(buffer, (char*)p->payload, p->len);
    ++counter;
    pbuf_free(p);
}

static void udp_client_send(){
    struct pbuf *txBuf;
    char data[100];
    
    int len = sprintf(data, "Sending UDP client message %d\\r\\n", counter);
    txBuf = pbuf_alloc(PBUF_TRANSPORT, len, PBUF_RAM);
    if(txBuf != NULL){
        pbuf_take(txBuf, data, len);
        udp_send(upcb, txBuf);
        pbuf_free(txBuf);
    }
}

void HAL_TIM_PeriodElapsedCallback(TIM_HandleTypeDef *htim){
    udp_client_send();
}

void udp_client_connect(){
    err_t err;
    
    upcb = udp_new();
    
    ip_addr_t myIPADDR;
    IP_ADDR4(&myIPADDR, 192, 168, 69, 123);
    udp_bind(upcb, &myIPADDR, 8);
    
    ip_addr_t destIPADDR;
    IP_ADDR4(&destIPADDR, 192, 168, 69, 118);
    err = udp_connect(upcb, &destIPADDR, 7);
    
    if(err == ERR_OK){
        udp_client_send();
        udp_recv(upcb, udp_receive_callback, NULL);
    }
}`;

const python_udp_server = `
import socket


def start_udp_server(host='0.0.0.0', port=0):
    udp_server_socket = socket.socket(socket.AF_INET, socket.SOCK_DGRAM)

    udp_server_socket.bind((host, port))

    assigned_port = udp_server_socket.getsockname()[1]
    print(f"UDP server is listening on port {assigned_port}")

    while True:
        data, client_address = udp_server_socket.recvfrom(4096)
        print(f"Received message from {client_address}: {data.decode('utf-8')}")
        udp_server_socket.sendto(b"Message received", client_address)


if __name__ == "__main__":
    start_udp_server(port=7)
`;



function MCU_HAL34(){
    return(
        <div className="em__post">
            <div className="em__post-title">
                <h1>UDP server and client</h1>
            </div>

            <div className="em__post-section">
                <h3>Aim of this tutorial:</h3>
                <p>
                    In this tutorial we are tackling two problems.
                    First, we will configure the NUCLEO-H745 as an UDP server, and write a Python-based UDP client that connects to it.
                    Second, we will reverse the order, create a Python-based UDP server that listens to every address, and sends a message when the
                    NUCLEO-H745, as an UDP client connects.
                </p>
            </div>

            <div className="em__post-section">
                <h3>The User Data Protocol (UDP):</h3>
                <p>
                    The UDP is one of the core communication protocols that is used to send messages (as datagrams in packets)
                    to other hosts on an internet protocol (IP) network.
                    The UDP does not require prior communication to setup channels or data paths.
                    It is a connectionless protocol, there is no handshaking,
                    so we can consider this protocol a simple one, perfect to start our communication journey.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Project setup:</h3>
                <p>
                    The project setup is the same as in the previous tutorial.
                    Either create the new files in that project, or clone the project.
                </p>
            </div>

            <div className="em__post-section">
                <h3>STM32 UDP server:</h3>

                <p>
                    Create a header file, that exposes the the init function.
                    I named the header <i>udp_server.h</i>, so the function handle looks like:
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {stm32_udp_server_h}
                </SyntaxHighlighter>
                <p>
                    The source contains two functions.
                    The init function establishes a connection with the application layer.
                    Here we define the IP address and bind the UDP server to a post (port 7 in my case).
                    If the binding is successful, we can define the reception function.

                    <br/>
                    Any time there is a new received packet, this <i>udp_receive_callback</i> function is called.
                    In this function, we can read the address of the sending client (if we need it), and the received message.
                    I created a function, that gets the payload of the received packet and sends back a message that encapsulates this payload to the client.
                    <br/>
                    If you want to send message, or broadcast it, you can use the same three function: <i> upd_connect, udp_send, udp_disconnect</i>.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {stm32_udp_server_c}
                </SyntaxHighlighter>
                <p>
                    Here, we need to include the <i>pbuf.h</i> header, so that we can work with the IP packet buffers,
                    and the <i>udp.h</i> for the UDP specific function handles.
                    Don't forget to include the header in the main source, and call the init function before the super loop.
                </p>
            </div>

            <div className="em__post-section">
                <h3>UDP client in Python:</h3>

                <p>
                    The Python script is simple.
                    We have a string, we encode it into a binary array.
                    Then we create a datagram-type socket, and send the encoded message to the given address.
                    Finally, we wait for a message to arrive.
                    <br/>
                    In this case, we should receive a message: <b>Hello Client from UDP server.</b>
                </p>

                <SyntaxHighlighter language="python" style={AtomOneDark}>
                    {python_udp_client}
                </SyntaxHighlighter>
            </div>

            <div className="em__post-section">
                <h3> STM32 UDP client: </h3>

                <p>
                    Clone the previous project, or rename the newly-added files as <i>udp_server.h_old</i> and <i>udp_server.c_old</i>.
                    Then create a header and source file for the client.
                    <br/>
                    I want to send messages periodically to the server, so I enabled the TIM1 interrupt with a period of 1s.
                </p>

                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {stm32_udp_client_h}
                </SyntaxHighlighter>

                <p>
                    The header is similar to the server, we only need to expose the connect function handle.
                </p>

                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {stm32_udp_client_c}
                </SyntaxHighlighter>

                <p>
                    The client connection is a bit involved.
                    We need to bind the client (own) address, and then connect to the server (PC) address.
                    If the connection is successful, the client sends a message and defines the reception function.
                    Here, the reception function has a single role, if we get a message, a global counter is increased.
                    The UDP message sending is done in the same manner as before.
                    This time, the client sends a message every second in the <i>HAL_TIM_PeriodElapsedCallback</i> function.
                    <br/>
                    In the main source, call the <i>HAL_TIM_Base_Start_IT(&htim1)</i> to start the Timer 1 interrupt,
                    and then call the UDP connection function.
                    Also, don't forget to leave the LWIP process function in the super loop.
                </p>

                <SyntaxHighlighter language="python" style={AtomOneDark}>
                    {python_udp_server}
                </SyntaxHighlighter>

                <p>
                    On the Python side, the server listens to every address.
                    If a message arrives, the server replies, this way the client counter increases after every sent message.
                </p>
            </div>


            <div className="em__post-navigation">

                <NavLink to="./../stm-hal-33">
                    <Button btnID={"leftBTN"} buttonSize="btn--medium"> Previous Post</Button>
                </NavLink>

                <NavLink to="./../stm-hal-35">
                    <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
                </NavLink>
            </div>

        </div>
    );
}

export default MCU_HAL34;