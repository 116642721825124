import React from "react";
import "./dsp_3.css";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";
import {MathJax, MathJaxContext} from "better-react-mathjax";

function ShowTex({string}){
    const config = {
        loader: { load: ["[tex]/html"]},
        tex: {packages: {"[+]": ["html"]},
            inlineMath: [["$", "$"]],
            displayMath: [["$$", "$$"]]
        }
    };

    return(
        <MathJaxContext config={config} version={3}>
            <MathJax dynamic inline>
                {string}
            </MathJax>
        </MathJaxContext>
    );
}

const measurement = `
/*Simple toggling for base time measurement*/
HAL_GPIO_WritePin(FL_GPIO_Port, FL_Pin, GPIO_PIN_SET);
HAL_GPIO_WritePin(FL_GPIO_Port, FL_Pin, GPIO_PIN_RESET);

/*Integer addition*/
HAL_GPIO_WritePin(FL_GPIO_Port, FL_Pin, GPIO_PIN_SET);
for(uint8_t i = 0; i <100; ++i)
    result_i[i] = operand_iA[i] + operand_iB[i];
HAL_GPIO_WritePin(FL_GPIO_Port, FL_Pin, GPIO_PIN_RESET);

/*Integer multiplication*/
HAL_GPIO_WritePin(FL_GPIO_Port, FL_Pin, GPIO_PIN_SET);
for(uint8_t i = 0; i <100; ++i)
    result_i[i] = operand_iA[i] * operand_iB[i];
HAL_GPIO_WritePin(FL_GPIO_Port, FL_Pin, GPIO_PIN_RESET);

/*Integer division*/
HAL_GPIO_WritePin(FL_GPIO_Port, FL_Pin, GPIO_PIN_SET);
for(uint8_t i = 0; i <100; ++i)
    result_i[i] = operand_iA[i] / operand_iB[i];
HAL_GPIO_WritePin(FL_GPIO_Port, FL_Pin, GPIO_PIN_RESET);

/*Float addition*/
HAL_GPIO_WritePin(FL_GPIO_Port, FL_Pin, GPIO_PIN_SET);
for(uint8_t i = 0; i <100; ++i)
    result_f[i] = operand_fA[i] + operand_fB[i];
HAL_GPIO_WritePin(FL_GPIO_Port, FL_Pin, GPIO_PIN_RESET);

/*Float multiplication*/
HAL_GPIO_WritePin(FL_GPIO_Port, FL_Pin, GPIO_PIN_SET);
for(uint8_t i = 0; i <100; ++i)
    result_f[i] = operand_fA[i] * operand_fB[i];
HAL_GPIO_WritePin(FL_GPIO_Port, FL_Pin, GPIO_PIN_RESET);

/*Float division*/
HAL_GPIO_WritePin(FL_GPIO_Port, FL_Pin, GPIO_PIN_SET);
for(uint8_t i = 0; i <100; ++i)
    result_f[i] = operand_fA[i] / operand_fB[i];
HAL_GPIO_WritePin(FL_GPIO_Port, FL_Pin, GPIO_PIN_RESET);
`;


function DSP_3(){
   return(
       <div className="em__post">
           <div className="em__post-title">
               <h1>Integers, fixed point and floating point numbers</h1>
           </div>
           <div className="em__post-section">
               <h3>Overview:</h3>
               <p>
                   In this tutorial, we will have a look at different number representation in the ARM processor.
                   We will test out and measure the execution time of different arithmetic operations using integer, floating point and fixed point representations.
                   We will implement the fixpoint arithmetic operations our selves.
               </p>
           </div>

           <div className="em__post-section">
               <h3>Theory:</h3>
               <p>
                   Before we talk about number representation, first we need to talk about an important concept: <b> dynamic range</b>.
                   Let's say that we have a set where the maximum value is <ShowTex string="$x_{max}$"/>,
                   and the noise floor (resolution) is <ShowTex string="$\delta_{x}$"/>.
                   Then the dynamic range is given as <ShowTex string="$DNR = 20 \mathrm{lg} \frac{x_{max}}{\delta_x}$"/>.
                   A representation with bigger <b>DNR</b> can represent more numbers (larger range),
                   and it's less likely that overflow or saturation occurs with arithmetic operations.
                   <br/>
                   If we take a look at the unsigned integer numbers, we have:
                   <table>
                       <tr>
                           <th> Integer type</th>
                           <th> Resolution </th>
                           <th> Max value </th>
                           <th> DNR (dB)</th>
                       </tr>
                       <tr>
                           <th> uint8_t</th>
                           <th> 1</th>
                           <th> <ShowTex string="$2^8-1$"/></th>
                           <th> 48</th>
                       </tr>
                       <tr>
                           <th> uint16_t</th>
                           <th> 1</th>
                           <th> <ShowTex string="$2^{16}-1$"/></th>
                           <th> 96</th>
                       </tr>
                       <tr>
                           <th> uint32_t</th>
                           <th> 1</th>
                           <th> <ShowTex string="$2^{32}-1$"/></th>
                           <th> 192</th>
                       </tr>
                       <tr>
                           <th> uint64_t</th>
                           <th> 1</th>
                           <th> <ShowTex string="$2^{64}-1$"/></th>
                           <th> 385</th>
                       </tr>
                   </table>
               </p>
               <p>
                   The signed values are represented in 2s complement.
                   E.g. let's take <ShowTex string="$35_{10} = 23_{16} = 0010.0011_2$"/> as an <i>int8_t</i>.
                   In order to represent <ShowTex string="$-35_{10}$"/>, first we have to perform bitwise inversion (1s complement)
                   <ShowTex string="1101.1100_2$"/>, then add 1 to the result, so we get <ShowTex string="$-35_{10} = 1101.1101_{2} = dd_{16}$"/> as an <i>int8_t</i>.
                   These numbers have the same dynamic range as their unsigned counterparts.
                   <br/>
                   Both signed and unsigned numbers can be stored in the same integer registers, and the same arithmetic instructions can be applied to them.
                   According to ARM, the integer addition, subtraction, multiplication and multiply-accumulate instructions are single cycle instructions for 32b numbers.
               </p>
               <p>
                   Let's have a look at floating point numbers.
                   Floats are represented inside processors according to the IEEE754 standard.
                   There are three types of floating point numbers: half precision (16b), single precision (32b) and double precision (64b).
                   Each float type contains a <b>sign bit</b>, an <b> exponent region</b> (5b, 8b, 11b) and <b>mantissa/fraction region</b>
                   (10b, 23b, 52b).
                   The floats were designed to work in a similar fashion to decimal scientific notation.
                   <ShowTex string="$0.00361$"/> can be represented as <ShowTex string="$0.361 \cdot 10^{-2}$"/>, while <ShowTex string="$21300$"/> can be
                   represented as <ShowTex string="$0.213 \cdot 10^{5}$"/>. In these cases the fractional parts are <ShowTex string="$0.361, 0.213$"/> and
                   the exponents are <ShowTex string="$-2, 5$"/> respectively - the floating point representation is similar
                   with binary fractions and binary exponents. The number can be normalised:
                   <ShowTex string="$$ -1^s (1 + \sum_{i=0}^{n_f}f_i 2^{-i}) 2^{e - bias} $$"/>, alternatively
                   numbers can be denormalised if they are too small to be represented in the normalised form:
                   <ShowTex string="$$ -1^s \sum_{i=0}^{n_f}f_i 2^{-i} 2^{-bias} $$"/>.
                   Float numbers can represent positive or negative zero, positive or negative infinity, also a representation for not a number (NaN).
                   For more information look up <a href="https://en.wikipedia.org/wiki/Floating-point_arithmetic">this</a> wiki article.
                   <table>
                       <tr>
                           <th> </th>
                           <th> Exponent </th>
                           <th> Exponent bias (bits)</th>
                           <th> Exponent range</th>
                           <th> Fraction (bits)</th>
                           <th> Resolution</th>
                           <th> Maximum value</th>
                           <th> DNR (dB)</th>
                       </tr>
                       <tr>
                           <th> half prec.</th>
                           <th> 5</th>
                           <th> 15</th>
                           <th> <ShowTex string="$[-14, 15]$"/> </th>
                           <th> 10</th>
                           <th> <ShowTex string="$6.1 \cdot 10^{-5}$"/></th>
                           <th> 65504 </th>
                           <th> 180</th>
                       </tr>
                       <tr>
                           <th> single prec.</th>
                           <th> 8</th>
                           <th> 127</th>
                           <th> <ShowTex string="$[-126, 127]$"/> </th>
                           <th> 23</th>
                           <th> <ShowTex string="$1.18 \cdot 10^{-38}$"/></th>
                           <th> <ShowTex string="$3.4 \cdot 10^{38}$"/> </th>
                           <th> 1529</th>
                       </tr>
                       <tr>
                           <th> double prec.</th>
                           <th> 11</th>
                           <th> 1023</th>
                           <th> <ShowTex string="$[-1022, 1023]$"/> </th>
                           <th> 52</th>
                           <th> <ShowTex string="$2.23 \cdot 10^{-308}$"/></th>
                           <th> <ShowTex string="$1.8 \cdot 10^{308}$"/> </th>
                           <th> 12318</th>
                       </tr>
                   </table>
               </p>
               <p>
                   Looks like the floating point numbers have the highest DNR, can represent larger number and the resolution is smaller.
                   This means that using floating point numbers is the optimal choice in every case?
                   <br/>
                   Well, no.
                   Firstly, the floating point arithmetics are resource and cycle intensive.
                   According to the ARM float coprocessor datasheet the absolute value, arithmetic negation,
                   addition and subtraction can be done in one instruction cycle.
                   The multiplication, multiply accumulate, multiply subtract, multiply accumulate negate, multiply subtract negate
                   can be done in three instruction cycles.
                   The division and square root can be done in 14 instruction cycles.
                   Furthermore, the float coprocessor has it owm register bank (S0 - S31) and status/control registers.
                   A <a href="https://developer.arm.com/documentation/dai0298/a/">lazy stacking</a> scheme is implemented to reduce the interrupt and task context switching latencies.
                   <br/>
                   Under normal circumstances, there is the interrupt, super loop (main) context.
                   If we do not use the float coprocessor, the xPSR, PC, LR, R12, R3, R2, R1, R0 registers are pushed into the stack before an interrupt handling and
                   these are restored after the interrupt is handled.
                   If we enable the float coprocessor, the stack must be increased to incorporate the FPSCR (floating point status and command register) adn S0-S31 before the general purpose registers.
                   This decreases the available memory, and increases the switching latency.
                   If lazy stacking is enabled (on by default), then the stack space is reserved for the float registers, but the register push operation is only done if:
                       <b>both the interrupting process and the interrupted process uses floating point operations</b>.
                   The moral of the story: we know that interrupt routines must be as short as possible, so only use float if you must, and even then use them only in the main loop.
               </p>
               <p>
                   Now, if we could only use integers, but we wanted to work with fractional numbers, then there's always the fixed point number route.
                   While in the case of floats, we had a fractional part adn an exponent (moving fractional)
                   in this case we need to specify the fractional part at the beginning and stick with it or use conversion functions.
                   For fixed point numbers, we use the <ShowTex string="$Qm.n$"/> notation, where the number is stored in an <ShowTex string="$m+n$"/> bit array,
                   <ShowTex string="$n$"/> is the fractional part and <ShowTex string="$n$"/> is the signed integer part.
                   The DNR for 8b, 16b and 32b fixed point numbers are 42dB, 90dB and 186dB respectively.
                   <br/>
                   Let's visualize the fixed point operations in decimal numbers: say we want to store <ShowTex string="$1.12, 2.37$"/> values in a
                   <ShowTex string="$[-10, 10]$"/> with two fractions precision, but we can store only integers.
                   The numbers can be written as <ShowTex string="$\frac{112}{100}, \frac{237}{100}$"/>, we store only 112 and 237.
                   <b>Addition/Subtraction:</b> We can simply add/subtract the numbers, and the base will remain the
                   same - <ShowTex string="$112+237=349 \implies 3.49$"/>, <ShowTex string="$112-237=-125 \implies -1.25$"/>.
                   <b>Multiplication:</b> <ShowTex string="$\frac{112}{100} \cdot \frac{237}{100} = \frac{26544}{10000}$"/>, but the base is not right.
                   We need to divide by 100, but we can only store integers, so we store <ShowTex string="$265 \implies 2.65$"/>. We have an error or <ShowTex string="$0.0044$"/>.
                   <b>Division:</b> <ShowTex string="$\frac{112}{100} : \frac{237}{100} = \frac{112}{237}$"/>, but in order to have a denominator of 100, we must multiply by 100.
                   <ShowTex string="$\frac{47}{100}$"/> - here we have an error of <ShowTex string="$0.0026$"/>.

               </p>

           </div>
           <div className="em__post-section">
               <h3>Firmware:</h3>
               <p>
                   This time around we will not need the Monitor program, only an oscilloscope or logic analyzer to detect changes in pin voltage level.
                   First we will measure the run-time of integer and floating point operations, then we will write our fixed point math functions.
                   <br/>
                   Create a new project for the NUCLEO-F411RE board.
                   Set a GPIO as high speed output in the configurator.
                   Generate the project.
                   Go to the Project Properties, C/C++ Build, MCU Settings and make sure that the floating point ABI is set to <b>hardware implementation</b>,
                   and the floating point unit is set to <b>FPv4-SP-D16</b>.
                   There are other options like no floating point usage or soft float
                   (this will implement every float operation in firmware, without the use of the hardware coprocessor).
               </p>
               <p>
                   Create 3 <i>int32_t</i> arrays with element size of 100, set the content of the first two to some random values between <ShowTex string="[-16000, 16000]"/>,
                   and zeros for the third one.
                   Create 3 <i>float</i> arrays with element size of 100, set the content of the first two to some random values between <ShowTex string="[-1, 1]"/> ,
                   and zeros for the third one.
                   <br/>
                   Before the super loop paste the following code snippet:
               </p>
               <div className="em_unselectable">
               <SyntaxHighlighter language="c" style={AtomOneDark}>
                   {measurement}
               </SyntaxHighlighter>
               </div>
               <p>
                   The first part is a dummy output toggle so that we can subtract that time fro every other measurement.
                   What are your observations?
               </p>
           </div>
           <div className="em__post-section">
               <h3>Task:</h3>
               <p>
                   With the help of <a href="https://en.wikipedia.org/wiki/Q_(number_format)">this</a> article,
                   create a fixed point math library (source and header) that provides functions for the following operations:
                   <ul>
                       <li>Converts a single precision float number to <ShowTex string="$Q1.31$"/>. </li>
                       <li>Converts a <ShowTex string="$Q1.31$"/> number to single precision float number.</li>
                       <li>Takes a 12b unsigned integer and normalises it into the interval <ShowTex string="$[-1, 1)$"/> and returns it as <ShowTex string="$Q1.31$"/></li>
                       <li>Adds two <ShowTex string="$Q1.31$"/> with and without saturation.</li>
                       <li>Subtracts two <ShowTex string="$Q1.31$"/> numbers.</li>
                       <li>Multiplies two <ShowTex string="$Q1.31$"/> numbers.</li>
                       <li>Divides two <ShowTex string="$Q1.31$"/> numbers.</li>
                       <li>Converts a <ShowTex string="$Qm_1.n_1$"/> number into a <ShowTex string="$Qm_2.n_2$"/> number
                           (where <ShowTex string="$m_2 + n_2 = m_1 + n_1 = 32$"/>).</li>
                   </ul>
               </p>
           </div>


           <div className="em__post-navigation">

               <NavLink to="./../dsp-2">
                   <Button btnID={"leftBTN"} buttonSize="btn--medium"> Prev Post</Button>
               </NavLink>

               <NavLink to="./../dsp-4">
                   <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
               </NavLink>
           </div>
       </div>
   );
}

export default DSP_3;