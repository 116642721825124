import React from "react";
import "./mcu_hal23.css";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";
import {MathJax, MathJaxContext} from "better-react-mathjax";

import block_scheme from "./block_scheme.jpg";
import qspi_setup1 from "./qspi_setup1.jpg";
import qspi_setup2 from "./qspi_setup2.jpg";

function ShowTex({string}){
    const config = {
        loader: { load: ["[tex]/html"]},
        tex: {packages: {"[+]": ["html"]},
            inlineMath: [["$", "$"]],
            displayMath: [["$$", "$$"]]
        }
    };

    return(
        <MathJaxContext config={config} version={3}>
            <MathJax dynamic inline>
                {string}
            </MathJax>
        </MathJaxContext>
    );
}

const func_headers = `
uint8_t OWN_QSPI_Init(void);
uint8_t OWN_QSPI_EraseSector(uint32_t startAddr, uint32_t endAddr);
uint8_t OWN_QSPI_WriteMemory(uint8_t* buffer, uint32_t addr, uint32_t buff_size);
uint8_t OWN_QSPI_EnableMemoryMappedMode(void);

uint8_t OWN_QSPI_Read_ID(uint8_t* mfID, uint16_t* chipSize);
uint8_t OWN_QSPI_ReadStatus(uint8_t* statusReg);
uint8_t OWN_QSPI_Tester(void);
`;

const func_defs = `
// IS25LQ020B memory parameters

#define MEMORY_FLASH_SIZE   0x40000 // 2Mb => 256kB
#define MEMORY_BLOCK_SIZE   0x10000  // 4 sectors of 64kB
#define MEMORY_SECTOR_SIZE  0x1000   // 64 sub-sectors of 4kB
#define MEMORY_PAGE_SIZE    0x100    // 1024 pages of 256B

// IS25LQ020B commands

#define WRITE_ENABLE_CMD        0x06
#define READ_STATUS_REG_CMD     0x05
#define WRITE_STATUS_REG_CMD    0x01
#define SECTOR_ERASE_CMD        0x20 // or 0xD7
#define CHIP_ERASE_CMD          0xC7 // or 0x60
#define QUAD_IN_FAST_PROG_CMD   0x38  // or 0x32
#define QUAD_READ_IO_CMD        0xEB
#define QUAD_OUT_FAST_READ_CMD  0x6B
#define RESET_ENABLE_CMD        0x66
#define RESET_EXECUTE_CMD       0x99
#define READ_JEDEC_ID_CMD       0x9F
#define WRITE_ENABLE_CMD        0x06
#define WRITE_DISABLE_CMD       0x04

#define DUMMY_CLOCK_CYCLES_READ_QUAD 8
`;

const wen_cmd = `
static uint8_t OWN_QSPI_WriteEnable(void){
    QSPI_CommandTypeDef sCommand;

    sCommand.InstructionMode = QSPI_INSTRUCTION_1_LINE;
    sCommand.AddressMode = QSPI_ADDRESS_NONE;
    sCommand.DataMode = QSPI_DATA_NONE;
    sCommand.AlternateByteMode = QSPI_ALTERNATE_BYTES_NONE;
    sCommand.DdrMode = QSPI_DDR_MODE_DISABLE;
    sCommand.DdrHoldHalfCycle = QSPI_DDR_HHC_ANALOG_DELAY;
    sCommand.SIOOMode = QSPI_SIOO_INST_EVERY_CMD;
    sCommand.Instruction = WRITE_ENABLE_CMD;
    sCommand.DummyCycles = 0;

    if (HAL_QSPI_Command(&hqspi1, &sCommand, HAL_QSPI_TIMEOUT_DEFAULT_VALUE)!= HAL_OK) {
        return HAL_ERROR;
    }

    return HAL_OK;
}
`;

const poll_cmd = `
static uint8_t OWN_QSPI_AutoPollingMemReady(void){
    QSPI_CommandTypeDef sCommand;
    QSPI_AutoPollingTypeDef sConfig;

    sCommand.InstructionMode = QSPI_INSTRUCTION_1_LINE;
    sCommand.DataMode = QSPI_DATA_1_LINE;
    sCommand.AddressMode = QSPI_ADDRESS_NONE;
    sCommand.DataMode = QSPI_DATA_1_LINE;
    sCommand.AlternateByteMode = QSPI_ALTERNATE_BYTES_NONE;
    sCommand.DdrMode = QSPI_DDR_MODE_DISABLE;
    sCommand.DdrHoldHalfCycle = QSPI_DDR_HHC_ANALOG_DELAY;
    sCommand.SIOOMode = QSPI_SIOO_INST_EVERY_CMD;
    sCommand.Instruction = READ_STATUS_REG_CMD;
    sCommand.DummyCycles = 0;

    sConfig.Match = 0x00;
    sConfig.Mask = 0x01;
    sConfig.MatchMode = QSPI_MATCH_MODE_AND;
    sConfig.StatusBytesSize = 1;
    sConfig.Interval = 0x10;
    sConfig.AutomaticStop = QSPI_AUTOMATIC_STOP_ENABLE;

    if (HAL_QSPI_AutoPolling(&hqspi1, &sCommand, &sConfig,HAL_QSPI_TIMEOUT_DEFAULT_VALUE) != HAL_OK) {
        return HAL_ERROR;
    }

    return HAL_OK;
}
`;

const func_erase = `
uint8_t OWN_QSPI_EraseSector(uint32_t startAddr, uint32_t endAddr){
    QSPI_CommandTypeDef sCommand;

    startAddr = startAddr - startAddr % MEMORY_SECTOR_SIZE;

    sCommand.InstructionMode = QSPI_INSTRUCTION_1_LINE;
    sCommand.AddressMode = QSPI_ADDRESS_1_LINE;
    sCommand.AddressSize = QSPI_ADDRESS_24_BITS;
    sCommand.DataMode = QSPI_DATA_NONE;
    sCommand.AlternateByteMode = QSPI_ALTERNATE_BYTES_NONE;
    sCommand.DdrMode = QSPI_DDR_MODE_DISABLE;
    sCommand.DdrHoldHalfCycle = QSPI_DDR_HHC_ANALOG_DELAY;
    sCommand.SIOOMode = QSPI_SIOO_INST_EVERY_CMD;
    sCommand.Instruction = SECTOR_ERASE_CMD;
    sCommand.DummyCycles = 0;

    while (endAddr >= startAddr) {
        sCommand.Address = (startAddr & 0x0FFFFFFF);

        if (OWN_QSPI_WriteEnable() != HAL_OK) {
            return HAL_ERROR;
        }

        if (HAL_QSPI_Command(&hqspi1, &sCommand, HAL_QSPI_TIMEOUT_DEFAULT_VALUE)
            != HAL_OK) {
            return HAL_ERROR;
        }
        startAddr += MEMORY_SECTOR_SIZE;

        if (OWN_QSPI_AutoPollingMemReady() != HAL_OK) {
            return HAL_ERROR;
        }
    }

    return HAL_OK;
}
`;

const mmap = `
uint8_t OWN_QSPI_EnableMemoryMappedMode(void){
    QSPI_CommandTypeDef sCommand;
    QSPI_MemoryMappedTypeDef sMemMappedCfg;

    sCommand.InstructionMode = QSPI_INSTRUCTION_1_LINE;
    sCommand.AddressSize = QSPI_ADDRESS_24_BITS;
    sCommand.AlternateByteMode = QSPI_ALTERNATE_BYTES_NONE;
    sCommand.DdrMode = QSPI_DDR_MODE_DISABLE;
    sCommand.DdrHoldHalfCycle = QSPI_DDR_HHC_ANALOG_DELAY;
    sCommand.SIOOMode = QSPI_SIOO_INST_EVERY_CMD;
    sCommand.AddressMode = QSPI_ADDRESS_1_LINE;
    sCommand.DataMode = QSPI_DATA_4_LINES;
    sCommand.NbData = 0;
    sCommand.Address = 0;
    sCommand.Instruction = QUAD_OUT_FAST_READ_CMD;
    sCommand.DummyCycles = DUMMY_CLOCK_CYCLES_READ_QUAD;

    sMemMappedCfg.TimeOutActivation = QSPI_TIMEOUT_COUNTER_DISABLE;

    if (HAL_QSPI_MemoryMapped(&hqspi1, &sCommand, &sMemMappedCfg) != HAL_OK) {
        return HAL_ERROR;
    }
    return HAL_OK;
}
`;

const memory_access = `
for (var = 0; var < SECTORS_COUNT; var++) {
      if (memcmp(buffer_test,(uint8_t*) (0x90000000 + var * MEMORY_SECTOR_SIZE),MEMORY_SECTOR_SIZE) != HAL_OK) {
           Error_Handler();
      }
  }
`;

function MCU_HAL23(){
    return(
        <div className="em__post">
            <div className="em__post-title">
                <h1>Quad SPI Flash</h1>
            </div>

            <div className="em__post-section">
                <h3>Aim of this tutorial:</h3>
                <p>
                    In this tutorial, we will learn how to use the quad SPI peripheral to interface a serial Flash memory IC (IS25LQ020B).
                    We will take a look at the basic read and write operation and how to create a read-only memory-mapped external memory region.
                </p>
            </div>

            <div className="em__post-section">
                <h3>The quad SPI interface:</h3>
                <p>
                    Quad SPI or QSPI is a four data line synchronous peripheral specifically designed for memory chip communication.
                    We saw how the classic SPI interface works: it has 2 data lines (MOSI - for the data from master to slave, MISO - for the data from slave to master),
                    a clock and a chip/slave select line.
                    The SPI communication is great for speeds up to 16MHz (with proper pcb tracks),
                    but Flash memory can be used at much higher speeds (~20.5MB/s and ~61.5MB/s reading speed for NAND and NOR Flash respectively).
                    Of course, there was the old but gold parallel interface, but that requires many connections (e.g. 20 addr, 16 data, 6 ), which could create timing issues,
                    and the pins of the MCU could be required elsewhere.

                </p>
                <img className="img_block_scheme_hal23" src={block_scheme} alt="block_scheme_hal23"/>
                <p>
                    And here comes the QSPI, which can operate as a normal SPI device (SS, SCK, MISO, MOSI), but that is not all.
                    It can operate in dual data line mode - Where SS, SCK, IO0, IO1 are used. Here IO0/1 are bidirectional lines, which means
                    that the communication speed can be doubled, but it is only half duplex.
                    We can add another two lines (IO3/4) and we have a half duplex communication with four times the speed (it is able to transfer a nibble in a single event).
                    Further more, the single data rate of the standard SPI is replaced by double data rate (data can be sampled on both edges of the SCK signal) -
                    this further increases the transfer speed to a total of eight times.

                    <br/> Of course, these properties can be disabled. E.g. We coud use the the QSPI in single data rate,
                    as a matter of fact, we will use it as such, because loose wires and high speed are not good friends.
                    Further more, the unused IOs can be configured as flag IOs (write protection, data holding etc.).

                    You can read more about QSPI from <a href="https://embeddedinventor.com/quad-spi-everything-you-need-to-know/">here</a>.
                </p>
            </div>

            <div className="em__post-section">
                <h3>The Flash memory:</h3>
                <p>
                    In this tutorial, we will use an <a href="https://www.digikey.com/en/products/detail/issi-integrated-silicon-solution-inc/IS25LQ020B-JNLE/5189754">IS25LQ020B</a> serial
                    Flash IC for testing purposes.
                    So download the datasheet and study it, since we will use the commands and instruction sequences.
                    This little memory IC packs 256KB memory with 256B programmable page standard, 4kB sectors or uniform 32/64kB blocks.
                    It supports single, dual, quad multi I/O SPI communication up to 104MHz speeds.
                </p>
                <p>
                    By default, the device operates in single line communication mode (see Table 6.2 in the datasheet),
                    so we will have to change that using the status register.
                    This Status register can be used to check whether we can write to the device (write enable latch - WEL) or
                    if a write operation is in progress (WIP).
                    Any kind of write operation requires the WEL to be set - this can be done by issuing a WREN instruction,
                    which lasts until the next write instruction.
                </p>
                <p>
                    We will use single line communication to configure the device.
                    For data read and write operation, the instruction and data will be sent in single line mode,
                    the data will be sent/read in quad mode.
                </p>
                <p>
                    I've used a SOP breakout board, and wired it to the NUCLEO board directly.
                    Including a breadboard would not doo too much good to the data transfer.

                    Connect pin 1 (CE#) to CN10/18, pin2 (SO/IO1) to CN7/34, pin3 (WP#/IO2) to CN10/15,
                    pin4 (GND) to a ground pin, pin5 (SI/IO0) to CN10/24, pin6 (SCK) to CN10/25,
                    pin7 (HOLD#/IO3) to CN10/13, and pin8 (VCC) to a +3.3V pin.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Project setup:</h3>
                <p>
                    Create a new project with the usual clock and debug settings.
                    Go to Connectivity/QUADSPI1, enable Bank1 with quad SPI lines
                    (BANK1 - since we only want to interface a single IC, there is dual bank mode too).
                    Set the prescaler to 255 (~660kHz) so that we don't have to debug data corruption due to improper wiring.
                    Set Sample shifting to no sample shifting, Clock mode Low, Chip Select high time 1 Cycle.
                    Flash size needs an integer between 0 and 31, and the formula for it is
                    <ShowTex string="$$s =\log_2(d) - 1 = \frac{\lg(d) - \lg(2)}{\lg(2)},$$"/>
                    where <i>d</i> is the memory in bytes, so for our IC <ShowTex string="$d = 262144B$"/> which means that <ShowTex string="$s = 17$"/>.
                </p>
                <Popup trigger={<img className="img_qspi_setup1_hal23 clickable_img" src={qspi_setup1} alt="qspi_setup1_hal23"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={qspi_setup1} alt="qspi_setup1_hal23" />
                    )}
                </Popup>
                <p>
                    Finally, go to Project Manager / Code Generator and select Generate peripheral initialisation as a pair of c/h files,
                    so that we can focus on the qspi functions.
                    Generate the project.
                </p>
                <img className="img_qspi_setup2_hal23" src={qspi_setup2} alt="qspi_setup2_hal23"/>
            </div>

            <div className="em__post-section">
                <h3>The firmware:</h3>
                <p>
                    If we take a look in the Core/Src and Core/Inc folders, we can see the main, and qspi files (amongst others).
                    Let's create the following fucntion headers in the private defines section:
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {func_headers}
                </SyntaxHighlighter>
                <p>
                    Then we have some defines, which are taken out directly from the Flash IC datasheet:
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {func_defs}
                </SyntaxHighlighter>
                <p>
                    Now let's take a look at some instructions.
                    We can send a command using the <i>HAL_QSPI_Command</i> function, which requires a command structure:
                </p>

                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {wen_cmd}
                </SyntaxHighlighter>

                <p>
                    Here we say that the instruction is sent using one line, there will be no address and no data transmitted.
                    We don't want to send alternate bytes, and we use the single data rate mode.
                    The instruction is the 0x06 write enable command.
                    <br/>
                    According to the datasheet bit0 of the status register shows if a writing operation is in progress or not.
                    Let's create a function that polls this bit:
                </p>

                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {poll_cmd}
                </SyntaxHighlighter>
                <p>
                    Here we are sending and instruction and we want to read a single byte of data on a line.
                    We mask the 0 bit and check for a reset state.
                </p>
                <p>
                    The <i>OWN_QSPI_Configuration</i> function must read the status register and set bit6 so that we can use the quad SPI functionality.
                    Read the status register using 0x05 command, store it in a <i>temp_reg</i>, set the required bit by <i>temp_reg |= 0x40</i>, and write the data
                    using a single line write operation with 0x01 command.
                    <br/>
                    The <i>OWN_QSPI_ResetChip</i> resets the IC. This is done by first enabling the reset (0x66), and then sending the reset command (0x99).
                    <br/>
                    The <i>OWN_QSPI_Read_ID</i> reads the JEDEC ID. Send the 0x9F command and read 3B of data.
                    The first byte is the manufacturer byte.
                    If we have the correct IC, we should get 0x9D here.
                    The next two bytes are for the Flash size.
                    My 2Mb variant should have 0x4012 as the concatenated last part.
                </p>

                <p>
                    Now for the <i>OWN_QSPI_Init</i> function.
                    First we de-initialize the QSPI peripheral using <i>HAL_QSPI_DeInit</i>, next we initialize it using <i>MX_QUADSPI1_Init</i>.
                    We reset the IC using <i>OWN_QSPI_ResetChip</i> - if you want to create an external program memory,
                    resetting the Flash at every startup is not the brightest of ideas - this is just for a simple exercise.
                    We can check for the completion of the reset by using th <i>OWN_QSPI_AutoPollingMemReady</i> function.
                    We can enable the 4 lines (QSPI) operation using the previously written <i>OWN_QSPI_Configuration</i>.
                </p>

                <p>
                    Since we can only erase and write memory regions in sectors (or blocks),
                    if we have a starting and end address, we need to erase the data in a loop by incrementing on the sectors:
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {func_erase}
                </SyntaxHighlighter>

                <p>
                    The writing is done in a similar fashion.
                    <br/>
                    The reading function could be implemented similarly as well, but there is another option.
                    Since the QSPI was developed to create a fast memory extention for the MCU,
                    there is a possibility to map the Flash into the addressable memory region of the processor,
                    and access it as a read-only zone from 0x90000000 to 0x9FFFFFFF (STM32G4 reference manual, Memory map and register boundary addresses).
                </p>

                <p>
                    This is done using the following function, after which we can read the memory using <i>memcpy</i>:
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {mmap}
                </SyntaxHighlighter>

                <p>
                    Check out <a href="https://gitlab.com/stm32_mcu_group/stm32_hal/23_qspi_flash.git">the source files</a>, compile and debug the project.
                    You can see that the memory is accessed using some ugly 32b pointers.
                    This can be overcome if we modify the linker to include the external region too - but that is no the scope of this tutorial.
                </p>

                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {memory_access}
                </SyntaxHighlighter>

                <p>
                    Either way, the 0x90000000 memory zone should be populated with data, and you can access and view this zone in the IDE in debug mode.
                    Stay tuned for further external memory use (bootloader and more) in the bare-metal section.
                </p>
            </div>




            <div className="em__post-navigation">

                <NavLink to="./../stm-hal-22">
                    <Button btnID={"leftBTN"} buttonSize="btn--medium"> Previous Post</Button>
                </NavLink>

                <NavLink to="./../stm-hal-24">
                    <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
                </NavLink>
            </div>
        </div>
    );
}

export default MCU_HAL23;