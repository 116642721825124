import React from "react";
import "./dsp_9.css";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";
import {MathJax, MathJaxContext} from "better-react-mathjax";

import exp_avg_magnitude from "./exp_avg_magnitude.png";
import exp_avg_phase from "./exp_avg_phase.png";
import monitor_graph from "./monitor_graph.jpg";
import monitor_setup from "./monitor_setup.jpg";




function ShowTex({string}){
    const config = {
        loader: { load: ["[tex]/html"]},
        tex: {packages: {"[+]": ["html"]},
            inlineMath: [["$", "$"]],
            displayMath: [["$$", "$$"]]
        }
    };

    return(
        <MathJaxContext config={config} version={3}>
            <MathJax dynamic inline>
                {string}
            </MathJax>
        </MathJaxContext>
    );
}

const global_vars = `
volatile float input = 0, output = 0;
float alpha = 0.5f;
`;

const loop_filter = `
if(is_sample_ready){
    input = ((float)adc_value * 2.0f)/4095.0f - 1.0f;
    y[0] = alpha * input + (1.0f - alpha) * y[1];
    y[1] = y[0];
    output = y[0];
    is_sample_ready = false;
}
`;

const monitor_json = `
{
    "variablelist" : [
        {
            "address": "0x20000000",
            "type": 9,
            "value": "{{payload}}"
        }
    ],
    "accesspoint": 0
`;


function DSP_9(){
   return(
       <div className="em__post">
           <div className="em__post-title">
               <h1>Exponential moving average filter</h1>
           </div>
           <div className="em__post-section">
               <h3>Overview:</h3>
               <p>
                   In this tutorial, we will implement the simples digital filter that has a feedback, the exponential moving average filter.
               </p>
           </div>

           <div className="em__post-section">
               <h3>Theory:</h3>
               <p>
                   Let's take a look at the mighty continuous-time, unit gain, first order transfer function
                   <ShowTex string="$$ H(s) = \frac{1}{Ts+1},$$"/>
                   with <ShowTex string="$T > 0$"/> time constant.
                   The magnitude function is <ShowTex string="$$ A(\omega) = |H(\omega)| = \frac{1}{\sqrt{T^2\omega^2+1}},$$"/>
                   from which we know that the system is low-pass filter.
                   If we could somehow transform this model into a discrete-time equivalent, we could use it in our MCU.
                   The differential equation of the systems can be written as
                   <ShowTex string="$$ T\dot{y}(t) + y(t) = x(t),$$"/>
                   and we can approximate the derivative with the backward-Euler finite difference method as
                   <ShowTex string="$$ Tf_s(y[n] - y[n-1]) + y[n] = x[n],$$"/>
                   where <ShowTex string="$f_s > 0$"/> is the sampling frequency.
                   With a bit of variable manipulation, we arrive to the difference equation
                   <ShowTex string="$$ y[n]= \underbrace{\frac{1}{(Tf_s + 1)}}_{\alpha}x[n] + \underbrace{\frac{Tf_s}{(Tf_s + 1)}}_{1-\alpha}y[n-1],$$"/>
                   and transfer function
                   <ShowTex string="$$ H(z) = \frac{\alpha z }{z - (1 - \alpha)},$$"/>
                   with a single shape parameter <ShowTex string="$\alpha \in [0, 1]$"/>.
               </p>
               <p>
                   The difference equation tells us the meaning of <ShowTex string="$\alpha$"/>.
                   If <ShowTex string="$\alpha = 1$"/>, then the input is directly fed to the output.
                   If <ShowTex string="$\alpha = 0$"/>, then the input is disconnected from the output.
                   If <ShowTex string="$\alpha < 0.5$"/>, then the input has less weight than the delayed output - this is better when the input has larger noise levels.
                   If <ShowTex string="$\alpha > 0.5$"/>, then the input has more weight than the delayed output.
               </p>
           </div>

           <div className="em__post-section">
               <h3>Simulation:</h3>
               <p>
                   Open up Python, use <i>freqz</i> and plot the frequency response of the filter.
                   You should get something like on the figures below:
               </p>
               <img className="img_exp_avg_magnitude_dsp-9" src={exp_avg_magnitude} alt="exp_avg_magnitude_dsp-9"/>
               <img className="img_exp_avg_phase_dsp-9" src={exp_avg_phase} alt="exp_avg_phase_dsp-9"/>
           </div>
           <div className="em__post-section">
               <p>
                   You can see that as we increase <ShowTex string="$\alpha$"/>,  the center frequency is shifted to the right.
                   Also, since we did not change the degree of the system, the magnitude has a <ShowTex string="$-20dB/dec$"/> decrease after the center frequency.
                   Pn the phase plot, we can see that we are no longer dealing with a linear phase system.
               </p>
           </div>
           <div className="em__post-section">
               <h3>Firmware:</h3>
               <p>
                   This algorithm should be a straight-forward programming based on the difference equation.
                   Create a new project, or clone the one from tutorial 2.
                   Besides the input and output signals, let's make the parameter <ShowTex string="$\alpha$"/> a global variable too,
                   so that we can change it from the Monitor.
               </p>
               <div className="em_unselectable">
               <SyntaxHighlighter language="c" style={AtomOneDark}>
                   {global_vars}
               </SyntaxHighlighter>
               </div>
               <p>
                   Normalise the input, and implement the difference equation.
                   step the buffer and reset the flag.
               </p>
               <div className="em_unselectable">
               <SyntaxHighlighter language="c" style={AtomOneDark}>
                   {loop_filter}
               </SyntaxHighlighter>
               </div>
               <p>
                   Build and upload the firmware to the development board.
               </p>
           </div>
           <div className="em__post-section">
               <h3>Data visualisation:</h3>
               <p>
                   Open up STM32CubeMonitor and copy the setup from a previous filter tutorial.
                   This time we will add a few blocks to be able to change a global variable.
               </p>
               <img className="img_monitor_setup_dsp-9" src={monitor_setup} alt="monitor_setup_dsp-9"/>
               <p>
                   First, import the generated elf file and check the input, output and alpha variables (also, note the address of the last one).
                   Next, add a slider (2) where the range is from 0 to 1 with 0.01 steps.
                   The output should be generated only on release.
                   <br/>
                   Add an edit template node (3).
                   Here we will precondition the data to be sent to ST-Link.
                   Paste the following code into the template.
               </p>
               <div className="em_unselectable">
               <SyntaxHighlighter language="json" style={AtomOneDark}>
                   {monitor_json}
               </SyntaxHighlighter>
               </div>
               <p>
                   The address is the address of the alpha variable.
                   The type is float (enumerated as 9 in this environment).
                   and the value is the payload of the slider.
                   <br/>
                   We need a change node (4).
                   This node will set the <b>topic</b> of the message to <b>write</b> so that the acq out node will
                   now that this value should be written to the device.
                   Finally, connect the node to the ack out, deploy and start the data monitoring.
               </p>
               <img className="img_monitor_graph_dsp-9" src={monitor_graph} alt="monitor_graph_dsp-9"/>
               <p>
                   Change the value of alpha using the slider.
                   You should see that the closer is alpha to 1, the less filtering is done.
                   You should also see that even the smallest non-zero alpha will not create a clean, single sine wave output.
                   <br/>
                   In the next tutorial, we will improve this filtering technique so that we can create more precise filters.
               </p>
           </div>

           <div className="em__post-section">
               <h3>Further work:</h3>
               <p>
                   <ol>
                       <li>
                           Compare the number of coefficients of the moving average and exponential moving average
                           filter in a case where we are isolating the lowest frequency component from our 10Hz,
                           100Hz, 1000Hz composite signal.
                       </li>
                       <li>
                           Design the high-pass counterpart of the exponential moving average filter.
                       </li>
                       <li>
                           Design the band-pass counterpart of the exponential moving average filter.
                       </li>

                   </ol>
               </p>
           </div>


           <div className="em__post-navigation">

               <NavLink to="./../dsp-8">
                   <Button btnID={"leftBTN"} buttonSize="btn--medium"> Prev Post</Button>
               </NavLink>

               <NavLink to="./../dsp-10">
                   <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
               </NavLink>
           </div>
       </div>
   );
}

export default DSP_9;