import React from "react";
import "./../posts.css";
import "./fpga_3.css";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import segments from "./Segment_Display.png";
import table from "./table_1.png";
import kmap1 from "./kmap_1.png";
import kmap2 from "./kmap_2.png";
import sim from "./sim.jpg";
import gates from "./gates.jpg"
import pinout from "./fpga_ax_pinout.jpg";
import pierce from "./pierce-osc-cmos-inverter.png";
import exto from "./ext_osc.png";
import into from "./int_osc.png";
import rom from "./rom.jpg";
import {MathJax, MathJaxContext} from "better-react-mathjax";
import SyntaxHighlighter from 'react-syntax-highlighter';
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import Popup from "reactjs-popup";


function ShowTex({string}){
    const config = {
        loader: { load: ["[tex]/html"]},
        tex: {packages: {"[+]": ["html"]},
            inlineMath: [["$", "$"]],
            displayMath: [["$$", "$$"]]
        }
    };

    return(
        <MathJaxContext config={config} version={3}>
            <MathJax dynamic inline>
                {string}
            </MathJax>
        </MathJaxContext>
    );
}

const segmentLogic = `
module SegmentLogic(input[3:0] din, output[6:0] dout);
        assign dout[0] =  (din[2] & ~din[1] & ~din[0]) | (~din[3] & ~din[2] & ~din[1] & din[0]);
        assign dout[1] = (din[2] & ~din[1] & din[0]) | (din[2] & din[1] & ~din[0]);
        assign dout[2] = ~din[2] & din[1] & ~din[0];
        assign dout[3] = (~din[3] & ~din[2] & ~din[1] & din[0]) | (din[2] & ~din[1] & ~din[0])
        | (din[2] & din[1] & din[0]);
        assign dout[4] = din[0] | (din[2] & ~din[1]);
        assign dout[5] = (~din[2] & din[1]) | (~din[3] & ~din[2] & din[0]) | (din[1] & din[0]);
        assign dout[6] = (~din[3] & ~din[2] & ~din[1]) | (din[2] & din[1] & din[0]);
endmodule`;

const buttonCounter = `
module ButtonCounter(input clk, output[6:0] segments);
    reg[3:0] counter_reg;
    
    initial begin
        counter_reg = 0;
    end
    
    always @(posedge clk) begin
        if(counter_reg == 4'd9)
            counter_reg <= 0;
        else
            counter_reg <= counter_reg + 4'd1;
    end
    
    SegmentCoder SegmentCoder_instance (
        .bcd_in(counter_reg),
        .segment_out(segments)
    );
endmodule`;

const oscInst = `
OSCH_inst.NOM_FREQ = "7.00";
OSCH OSCH_inst( 
    .STDBY(1'b0),
    .OSC(clk_wire),
    .SEDSTDBY()
    );`;

const segmentLogic_alt = `
module SegmentLogic2(input[3:0] din, output[6:0] dout);
    reg[6:0] d_reg;
        always @(din)
        begin
            case (din) //case statement
                0 : d_reg = 7'b0000001;
                1 : d_reg = 7'b1001111;
                2 : d_reg = 7'b0010010;
                3 : d_reg = 7'b0000110;
                4 : d_reg = 7'b1001100;
                5 : d_reg = 7'b0100100;
                6 : d_reg = 7'b0100000;
                7 : d_reg = 7'b0001111;
                8 : d_reg = 7'b0000000;
                9 : d_reg = 7'b0000100;
                default : d_reg = 7'b1111111; 
            endcase
        end    
    assign dout = d_reg;
endmodule`;

function FPGA_3(){
    return(
        <div className="em__post">
            <div className="em__post-title">
                <h1>FPGA as a box with a lot of gates</h1>
            </div>
            <div className="em__post-section">
                <h3>Aim of this post:</h3>
                <p>
                    In this tutorial we will design a hardware which counts the rising edges of a logic signal and displays the value on a single 7-Segment LED display.
                </p>
            </div>
            <div className="em__post-section">
                <h3>Prerequisites:</h3>
                <p>
                    We will use the hardware and software mentioned in the previous section.
                    A little knowledge of digital electronics will be necessary for the hardware - gates, registers, counters, Boole algebra, and Karnaugh-maps.
                </p>
            </div>
            <div className="em__post-section">
                <h3>Gate level implementation:</h3>
                <img className="img_segments" src={segments} alt="segments" />
                <p>
                    We have a single 7-Segment display, on which we want to show counted edges.
                    This means we want an encoder which creates the necessary signals for the display from a given binary coded decimal (BCD) value.
                    The input, in this case, is a 4bit number in the range of 0-9, while the output is a 7bit number named as A, B,.., G. as shown in the figure below.
                </p>
                <p>
                    The DP is the decimal point; we won't use it in this tutorial.
                    My particular display is a TDSR5150, which is a red, common anode display as shown in the datasheet.
                    The common anode displays need the anode tied to the supply rail, and we will connect the cathodes to the FPGA pins through 100Ω resistors.
                    If your display is a common cathode one than the cathode needs to be tied to ground, and the output signals in this tutorial must be inverted.
                </p>
                <p>
                    First, we need to design a truth table with the digits from zero to nine,
                    their respective binary values (these will be the inputs of our boolean functions) and the segment logic,
                    with zero denoting a lit-up state, and one denoting a shut-down state.
                </p>
                <p>
                    First, we need to design a truth table with the digits from zero to nine,
                    their respective binary values (these will be the inputs of our boolean functions) and the segment logic,
                    with zero denoting a lit-up state, and one denoting a shut-down state.
                </p>
                <img className="img_table" src={table} alt="table"/>
                <p>
                    We can create a Karnaugh map for each digit from the table above.
                    We can then write the simplified boolean expression from the Karnaugh map, e.g.
                    The maps of digits A and B:
                </p>
                <div className="em__post-section_distribute">
                    <img className="img_kmap1" src={kmap1} alt="kmap1"/>
                    <img className="img_kmap2" src={kmap2} alt="kmap2"/>
                </div>
                <p>
                    This gives
                </p>
                <ul className="ul_no_dot">
                    <li><ShowTex string="$A = D_2 \overline{D}_1 \overline{D}_0 + \overline{D}_3\overline{D}_2 \overline{D}_1 D_0$"/></li>
                    <li><ShowTex string="$B = D_2 \overline{D}_1 D_0 + D_2 D_1 \overline{D}_0$"/>.</li>
                </ul>
                <p>
                    We can find the values for the other digits in a similar fashion:
                </p>
                <ul className="ul_no_dot">
                    <li><ShowTex string="$C =  \overline{D}_2 D_1 \overline{D}_0$"/></li>
                    <li><ShowTex string="$D = \overline{D}_3 \overline{D}_2 \overline{D}_1 D_0 + D_2 \overline{D}_1 \overline{D}_0 + D_2 D_1 D_0$"/>.</li>
                    <li><ShowTex string="$E = D_0 + D_2 \overline{D}_1$"/></li>
                    <li><ShowTex string="$F = \overline{D}_2 D_1 + \overline{D}_3 \overline{D}_2 D_0 + D_1 D_0 $"/>.</li>
                    <li><ShowTex string="$G = \overline{D}_3 \overline{D}_2 \overline{D}_1 + D_2 D_1 \overline{D}_0$"/></li>
                </ul>
            </div>
            <div className="em__post-section">
                <p>
                    We can write the encoder module in verilog with the help of these boolean functions:
                </p>

                <SyntaxHighlighter language="verilog" style={AtomOneDark}>
                    {segmentLogic}
                </SyntaxHighlighter>
                <p>
                    Diamond has a built-in Simulation tool: Aldec Active-HDL. We can use this tool to simulate our SegmentLogic module.
                </p>
                <img className="img_sim" src={sim} alt="sim"/>
                <p>
                    And while we are at it, we could generate the RTL schematic of our module with the help of Synplify:
                </p>
                <Popup trigger={<img className="img_gates clickable_img" src={gates} alt="gates"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={gates} alt="gates"/>
                    )}
                </Popup>

                <p>
                    Now we have the simple logic module with a 4b input and 7b output.
                    Next, we need to create a counter for the button interface. We will design this counter in the top module.
                </p>
                <SyntaxHighlighter language="verilog" style={AtomOneDark}>
                    {buttonCounter}
                </SyntaxHighlighter>
                <p>
                    The top module is equivalent to the main function in C (in a loose sense).
                    We will connect the clk input to our button, and the segments output to the 7-Segment display.
                </p>
                <p>
                    We need a 4b counter since we want to count from zero to nine. We initialise the counter to 0 with the
                    <i className="emph">initial begin ... end </i> structure .
                </p>
                <p>
                    Next, we create the counter routine with an always block.
                    The sensitivity is set to the rising edge of the clk input.
                    This routine increments the counter register and resets it after the value 9.
                </p>

                <p>
                    Finally, we create an instance of the coder logic, with the previously created counter as the input.
                </p>

                <p>
                    In Diamond, we need to synthesise the design (in the process window), and we need to create a map trace.
                    In the spreadsheet view (Tools/Spreadsheet View) we need to create the I/O constraints. Here are some important properties:
                </p>

                <ul>
                    <li><b>O TYPE:</b> here we set different voltage standards such as LVCMOS33, LVCMOS25 etc.
                        This setting tells the synthesiser which bank must be used for the specified port.</li>
                    <li><b>Pull mode:</b> here we can set weak pull-ups, pull-downs or keeper ports (The weak keeper circuit drives a weak 0 or 1 level to match the level it detects on the pad.
                        When the pad is driven externally, transitions that override the weak keeper output change the state of the weak keeper's output to match.
                        The weak keeper circuit maintains the last logic state present on the pad to prevent the pad from floating, when the pad is not driven either externally or by the  logic).</li>
                    <li><b>PIN:</b> here we set which pin must be used for which IO port and the synthesiser will place the design near said port if possible.</li>
                </ul>
                <p>
                    For my design, I've used pin 21 for the button input, and pins 4, 28, 10, 11, 12, 5, 9 for the segments,
                    but we can choose other pins too. Here is the pinout of the TinyFPGA board:
                </p>
                <img className="img_pinout" src={pinout} alt="pinout"/>
                <p>
                    After generating the JEDEC file and uploading with the programmer,
                    we have <a href="https://imgur.com/yrqCIZq">a simple 7-Segment LED driver</a> which counts the rising edges generated by a push button.
                </p>
                <p>
                    We can see that using a button as a clock source is not the brightest idea.
                    Fortunately for us, we have multiple clock options available at our hand.
                    The simplest one is to use the internal oscillator.
                    This oscillator can be set to a frequency of 2.08MHz to 133 MHz as shown on page 29&nbsp;
                    <a href="http://www.latticesemi.com/-/media/LatticeSemi/Documents/ApplicationNotes/MO/MachXO2sysCLOCKPLLDesignandUsageGuide.ashx?document_id=39080">here</a>.
                </p>
                <SyntaxHighlighter language="verilog" style={AtomOneDark}>
                    {oscInst}
                </SyntaxHighlighter>
                <p>
                    Another counter must be used to lower the frequency to a readable 1-2Hz level.
                </p>
                <p>
                    The problem with the internal oscillator is the jitter.
                    In the case of the Machx02, the internal oscillator has a 5% precision.
                    We can compare the internal oscillator signal with the output of a pierce oscillator.
                </p>
                <div>
                    <img className="img_pierce" src={pierce} alt="pierce"/>
                    <p>
                        Here the inverter is created inside the FPGA using Verilog.
                        The input and output pins are mapped to valid pll pins.
                        The components I've used are: Rf = 1MΩ, Rs = 1kΩ, Ca=Cb = 20pF, with a 25MHz crystal.
                    </p>
                    <p>
                        Below we can see the differences between the internal and external oscillators
                        (The distortion on the second image is due to my entry level oscilloscope probes).
                        The oscilloscope is set to infinite persistence with the trigger shifted 39us to the left.
                        The shaded regions show the clock jitter.
                    </p>
                </div>
            </div>
            <div className="em__post-section">
                <div className="em__post-section_distribute">
                    <img className="img_int_osc" src={into} alt="int_osc"/>
                    <img className="img_ext_osc" src={exto} alt="ext_osc"/>
                </div>

                <p>
                    One last question would be: If the FPGA is more than just logic gates, could we create the BCD to 7Segment coder module more easily?
                </p>
                <p>
                    The answer is yes. If we treat the above mentioned truth table as a ROM,
                    the inputs are the addresses, while the data will be the output.
                    We can create a ROM with a switch-case structure:
                </p>
                <SyntaxHighlighter language="verilog" style={AtomOneDark}>
                    {segmentLogic_alt}
                </SyntaxHighlighter>
                <p>
                    Here we can see the coder logic implemented with a switch-case structure,
                    and the synthesized module is a ROM instead of simple gates, as expected.
                </p>
                <img className="img_rom" src={rom} alt="rom"/>
                <p>
                    <a href="https://imgur.com/IwcCXaK">Here</a> is a short video of the working example, and as always, the source code can be downloaded from&nbsp;
                    <a href="https://gitlab.com/Csurleny/fpga-tutorial-files.git">here</a>.
                </p>
            </div>


            <div className="em__post-navigation">

                <NavLink to="./../fpga-tut-2">
                    <Button btnID={"leftBTN"} buttonSize="btn--medium"> Previous Post</Button>
                </NavLink>

                <NavLink to="./../fpga-tut-4">
                    <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
                </NavLink>
            </div>


        </div>
    );
}

export default FPGA_3;