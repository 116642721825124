import React from "react";
import "./mcu_bm3.css";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";

import gpio_pin_block from "./gpio_pin_block.jpg";

const gpio_header = `
#pragma once
#include \< cstdint \>

namespace driver{

    enum class GPIO_MODE: uint32_t{
        INPUT = 0,
        OUTPUT = 1,
        ALTERNATE = 2,
        ANALOG = 3
    };

    enum class GPIO_PULL: uint32_t{
        NONE = 0,
        UP = 1,
        DOWN = 2
    }; 

    enum class GPIO_SPEED: uint32_t{
        LOW = 0,
        MED = 1,
        HIGH = 2,
        VERY_HIGH = 3
    };

    enum class GPIO_PORT: uint32_t{
        GPIOA = 0x48000000, 
        GPIOB = 0x48000400, 
        GPIOC = 0x48000800, 
        GPIOD = 0x48000C00, 
        GPIOE = 0x48001000, 
        GPIOF = 0x48001400, 
        GPIOG = 0x48001800
    };

    class dGPIOp{
        private:
            /* data */
            const uint32_t _port_address;
            const uint16_t _pin;
            
        public:
            dGPIOp(GPIO_PORT port, uint16_t pin, GPIO_MODE mode, GPIO_PULL pull, GPIO_SPEED speed);
            void write(uint8_t val);
            uint8_t read();
            void toggle();
        };
}
`;

const gpio_source = `
#include "../inc/dGPIO.hpp"

using namespace driver;

static constexpr uint32_t RCC_AHB2ENR = 0x4002104CU;
static constexpr uint32_t MODER_ADDRESS = 0x00U;
static constexpr uint32_t OTYPER_ADDRESS = 0x04U;
static constexpr uint32_t OSPEEDR_ADDRESS = 0x08U;
static constexpr uint32_t PUPDR_ADDRESS = 0x0CU;
static constexpr uint32_t IDR_ADDRESS = 0x10U;
static constexpr uint32_t ODR_ADDRESS = 0x14U;

dGPIOp::dGPIOp(GPIO_PORT port, uint16_t pin, GPIO_MODE mode, GPIO_PULL pull, GPIO_SPEED speed): 
_port_address(static_cast\< uint32_t \>(port)), _pin(pin){
    
    // Enable the clock for the GPIO
    *((volatile uint32_t*)(RCC_AHB2ENR)) |= 0x1UL << 2U;
    *((volatile uint32_t*)(RCC_AHB2ENR)) |= 0x1UL;

    // Set GPIO mode: Input or output
    *((volatile uint32_t*)(_port_address + MODER_ADDRESS)) &= ~(0x3 << (_pin*2));
    *((volatile uint32_t*)(_port_address + MODER_ADDRESS)) |= (static_cast<uint32_t>(mode)<<(_pin*2));
        
    // Set Speed
    *((volatile uint32_t*)(_port_address + OSPEEDR_ADDRESS)) &= ~(0x3 <<(_pin*2));
    *((volatile uint32_t*)(_port_address + OSPEEDR_ADDRESS)) |= (static_cast<uint32_t>(speed)<<(_pin*2));

    // Set Pull-up/down
    *((volatile uint32_t*)(_port_address + PUPDR_ADDRESS)) &= ~(0x3 <<(_pin*2));
    *((volatile uint32_t*)(_port_address + PUPDR_ADDRESS)) |= ~(static_cast<uint32_t>(pull) <<(_pin*2));


}

void dGPIOp::write(uint8_t val){
    uint32_t port_val = *((volatile uint32_t*)(_port_address + ODR_ADDRESS));
    if(val == 0x01)
        *((volatile uint32_t*)(_port_address + ODR_ADDRESS)) = port_val | (1 << _pin);
    if(val == 0x00)
        *((volatile uint32_t*)(_port_address + ODR_ADDRESS)) = port_val & ~(1 << _pin);
}

uint8_t dGPIOp::read(){
    uint32_t idr_val;
    idr_val = (*((volatile uint32_t*)(_port_address + IDR_ADDRESS)) >> _pin) & 0x1;
    return (uint8_t) idr_val;
}

void dGPIOp::toggle(){
    uint8_t pin_val = read();
    pin_val ^= 1;
    write(pin_val);
}
`;

const gpio_main = `
#include "../inc/dGPIO.hpp"

using namespace driver;

int main(void){

    dGPIOp PA5 = dGPIOp(GPIO_PORT::GPIOA, 5, GPIO_MODE::OUTPUT, GPIO_PULL::NONE, GPIO_SPEED::LOW);
    dGPIOp PC13 = dGPIOp(GPIO_PORT::GPIOC, 13, GPIO_MODE::INPUT, GPIO_PULL::NONE, GPIO_SPEED::LOW);


    PA5.write(0);
    for(uint32_t i = 0; i < 10000000; ++i)
        __asm("NOP");
    PA5.write(1);
    for(uint32_t i = 0; i < 10000000; ++i)
        __asm("NOP");
    PA5.write(0);

    while(1){
        PA5.write(PC13.read());
        for(uint32_t i = 0; i < 100000; ++i)
            __asm("NOP");
    }
}
`;

const linker_extension = `
.preinit_array     :
  {
    . = ALIGN(4);
    PROVIDE_HIDDEN (__preinit_array_start = .);
    KEEP (*(.preinit_array*))
    PROVIDE_HIDDEN (__preinit_array_end = .);
    . = ALIGN(4);
  } >FLASH

  .init_array :
  {
    . = ALIGN(4);
    PROVIDE_HIDDEN (__init_array_start = .);
    KEEP (*(SORT(.init_array.*)))
    KEEP (*(.init_array*))
    PROVIDE_HIDDEN (__init_array_end = .);
    . = ALIGN(4);
  } >FLASH

  .fini_array :
  {
    . = ALIGN(4);
    PROVIDE_HIDDEN (__fini_array_start = .);
    KEEP (*(SORT(.fini_array.*)))
    KEEP (*(.fini_array*))
    PROVIDE_HIDDEN (__fini_array_end = .);
    . = ALIGN(4);
  } >FLASH
`;


function MCU_BM3(){
   return(
       <div className="em__post">
           <div className="em__post-title">
               <h1>General purpose inputs and outputs</h1>
           </div>
           <div className="em__post-section">
               <h3>Aim of this tutorial:</h3>
               <p>
                   In this tutorial we will configure the GPIO peripherals to as inputs and outputs.
                   We will read the state of the on-board button and toggle the LED in response.
                   Finally we will wrap the methods in a class for better handling.
               </p>
           </div>
           <div className="em__post-section">
               <h3>Writing the driver class:</h3>
               <p>
                   As you start to reuse components, the project setup will be more bothersome,
                   and with the increase of the used peripherals, the setup time also increases.
                   It's a better idea to copy a few portable library files and add the additional functionalities as you go.
                   <br/>
                   There are many libraries and abstractions that fit in this portability category,
                   sometimes it's better to write your own (for learning, or ease of certification).
                   <br/>
                   Same as in the previous tutorial, we will only use our gcc and the datasheet to write a
                   minimal driver to control individual pins of the GPIO.
               </p>

               <img className="img_gpio_pin_block_bm3" src={gpio_pin_block} alt="gpio_pin_block"/>
               <p>
                   According to the datasheet, we can use a GPIO as a digital input, digital output,
                   we can connect it to an alternate peripheral, an ADC, or we can make it a low power consumption analog pin.
                   The digital output can be used with a push-pull driver or with an open-drain sink.
                   The pins have a configurable pull-up/pull-down resistor pair - one can set one of them or neither.
                   There is also a speed setting to control the slew rate (drive strength).
                   High slew rates are nice for fast transitions, but they might cause ringing and EMI on the outputs,
                   so it's a good idea to provide a speed setting option.
               </p>

               <SyntaxHighlighter language="c" style={AtomOneDark}>
                   {gpio_header}
               </SyntaxHighlighter>
               <p>
                   I've created a driver namespace.
                   This would contain all the peripheral classes like GPIO, I2C, SPI, ADC etc.
                   Here, to mask magic numbers we should create some enums for the mode, pull-up/down, speed and port selection.
                   You could use simple enums, I've opted for an enum class, but this will introduce some casting issues latter on.
                   The values are all copied from the datasheet of the STM32G491 MCU.
                   It has 7 GPIO ports, each on the AHB address 0x48000000, with offsets 0, 0x400, 0x800, 0xC00, 0x1000, 0x1400, 0x1800
                   for the A, B, ..., G ports respectively.
               </p>
               <p>
                   The driver class will be a simple one.
                   Only able to handle a single pin per class instance.
                   It will be able to write to the ODR, read from the IDR, and toggle the ODR value.
                   For this, we need a private variable to store the port address and pin number.
               </p>

               <SyntaxHighlighter language="c" style={AtomOneDark}>
                   {gpio_source}
               </SyntaxHighlighter>
               <p>
                   In the source, first we set the namespace.
                   Then we can create some additional constants for the different GPIO register address.
                   <i>RCC_AHB2ENR</i> is used to enable the GPIO peripheral clock.
                   <i>MODER</i> is used to set the mode of the pin (input, output, alternate or analog).
                   <i>OSPEED</i> is used to set the slew rate of the pin.
                   <i>PUPDR</i> is used to set the pull-up/down resistor states.
                   <i>IDR</i> will be used to read the input state.
                   <i>ODR</i> will be used to write the output state.
               </p>
               <p>
                   The constructor enables the GPIO clock, sets the direction, speed and resistor.
                   It is done by first resetting the required bit, then setting the bit if required.
                   A GPIO output write operation is done by setting or resetting the pin in question depending on a high or low state.
                   The GPIO read operation takes the value of the IDR register, and shifts/masks the value so that only the required bit remains.
                   The toggle method uses the previously defined two methods.
               </p>

               <SyntaxHighlighter language="c" style={AtomOneDark}>
                   {gpio_main}
               </SyntaxHighlighter>
               <p>
                   In the main, we include the class header and we set the namespace.
                   We can create two GPIO instances, one for the LED (PA15) and one for the button (PC13).
                   We can use the write method to change the LED state and the read method to get the button state.
                   Since we don't have sys_tick or a timer configured, we can only create delays using a loop and some dummy operations.
                   <br/>
                   If you build the program and upload it, you will find out that it does not work as intended.
                   The objects base class will not be always constructed, so when the program calls a method it will land on the 0 address (reset handler).
                   We need to add a few memory sections to the linker script so that C++ specific functionalities (constructor, destructor) could be properly mapped.
                   These are the <i>.preinit_array</i>, <i>.init_array</i> - sections which hold functions that should be called before the start of a program.
                   <i>.fini_array</i> - section that holds functions that are called after a function -
                   in embedded devices this is not used, there is no after, but I've added for completeness.
               </p>

               <SyntaxHighlighter language="c" style={AtomOneDark}>
                   {linker_extension}
               </SyntaxHighlighter>
               <p>
                   This part is pasted between the ARM and DATA section.
                   With this slight modification, you should see the initial LED blink,
                   and then blink the LED yourself by pushing the button.
               </p>
               <p>
                   Of course, you could write a class that works with full port - creating parallel read and write operations.
                   Furthermore, you should sense that writing/reading peripheral addresses, enabling/disabling peripheral clocks is a common operation to all peripherals.
                   One could create a base peripheral class containing virtual methods, and derive the peripheral class from it using inheritance.
               </p>
           </div>


           <div className="em__post-navigation">

               <NavLink to="./../stm-bm-2">
                   <Button btnID={"leftBTN"} buttonSize="btn--medium"> Previous Post</Button>
               </NavLink>

               <NavLink to="./../stm-bm-4">
                   <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
               </NavLink>
           </div>

       </div>
   );
}

export default MCU_BM3;