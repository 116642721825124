import React from "react";
import "./mcu_hal21.css";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";
import {MathJax, MathJaxContext} from "better-react-mathjax";

import adc_hp_setup from "./adc_hp_setup.jpg";
import adc_lp_setup from "./adc_lp_setup.jpg";
import tim_hp_setup from "./tim_hp_setup.jpg";
import tim_lp_setup from "./tim_lp_setup.jpg";
import rtc_stdby from "./rtc_standby.jpg";
import hal_unused from "./hal_unused.jpg";

const variables = `
volatile uint16_t adc_val = 0;
volatile uint8_t adc_flag = 0;
uint16_t temperature = 0;
char temp_string[6] = "00.0C";
`;

const hp_setup = `
    HAL_ADCEx_Calibration_Start(&hadc1, ADC_SINGLE_ENDED);
    HAL_ADC_Start_IT(&hadc1);
    HAL_TIM_Base_Start(&htim6);
    printf("Finished configuring.\\r\\n");
`;

const hp_loop = `
    if(adc_flag){
          temperature = adc_val * 3300 / 4096; // voltage in mV
          temp_string[0] = (temperature / 100) + '0';
          temp_string[1] = (temperature / 10)%10 + '0';
          temp_string[3] = temperature % 10 + '0';
          printf("New conv value: %s\\r\\n", temp_string);
          adc_flag = 0;
    }
`;

const hp_adc_irq = `
void HAL_ADC_ConvCpltCallback(ADC_HandleTypeDef *hadc){
    adc_val = HAL_ADC_GetValue(hadc);
    adc_flag = 1;
}
`;

const usart_write = `
int _write(int fd, char *ptr, int len){
    //HAL_UART_Transmit(&huart2, (uint8_t *) ptr, len, HAL_MAX_DELAY);
    HAL_UART_Transmit(&hlpuart1, (uint8_t *) ptr, len, HAL_MAX_DELAY);
    return len;
}
`;

const sleep_loop = `
printf("Entering SLEEP mode.\\r\\n");
HAL_SuspendTick();
HAL_PWR_EnterSLEEPMode(PWR_MAINREGULATOR_ON, PWR_SLEEPENTRY_WFI);
`;

const sleep_adc = `
HAL_ResumeTick();
printf("Wake from SLEEP.\\r\\n");
`;

const stop_loop = `
printf("Entering STOP mode.\\r\\n");
HAL_SuspendTick();
HAL_PWR_EnterSTOPMode(PWR_LOWPOWERREGULATOR_ON, PWR_STOPENTRY_WFI);
`;

const stop_exti = `
void HAL_GPIO_EXTI_Callback(uint16_t GPIO_Pin){
    if(GPIO_Pin == B1_Pin){
        SystemClock_Config();
        HAL_ResumeTick();
        printf("Waked by EXTI.\\r\\n");
    }
}
`;

const standby = `
if(__HAL_PWR_GET_FLAG(PWR_FLAG_SB) != RESET){
    __HAL_PWR_CLEAR_FLAG(PWR_FLAG_SB);
    printf("Wake up from STANDBY mode.\\r\\n");
    HAL_ADC_Start(&hadc1);
    HAL_ADC_PollForConversion(&hadc1, HAL_MAX_DELAY);
    adc_val = HAL_ADC_GetValue(&hadc1);
    temperature = adc_val * 3300 / 4096; // voltage in mV
    temp_string[0] = (temperature / 100) + '0';
    temp_string[1] = (temperature / 10)%10 + '0';
    temp_string[3] = temperature % 10 + '0';
    printf("New conv value: %s\\r\\n", temp_string);
    HAL_RTCEx_DeactivateWakeUpTimer(&hrtc);
}
__HAL_PWR_CLEAR_FLAG(PWR_FLAG_WU);
__HAL_RTC_WAKEUPTIMER_CLEAR_FLAG(&hrtc, RTC_FLAG_WUTF);
printf("Entering STANDBY mode.\\r\\n");
HAL_RTCEx_SetWakeUpTimer_IT(&hrtc, 10, RTC_WAKEUPCLOCK_CK_SPRE_16BITS);
HAL_PWR_EnterSTANDBYMode();
`;


function MCU_HAL21(){
    return(
        <div className="em__post">
            <div className="em__post-title">
                <h1>Low power mode</h1>
            </div>

            <div className="em__post-section">
                <h3>Aim of this tutorial:</h3>
                <p>
                    In this tutorial, we will create a simple temperature sensor which sends the measured data to the PC using UART.
                    We will try different power modes and measure the current consumption of the device.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Normal operation at 160MHz:</h3>
                <p>
                    So far, at the start of the project creation we used the external crystal oscillator, and pumped the system clock frequency as high as 160 or 170MHz.
                    Higher clock frequency means more instructions per second, so the bigger, the better?
                    <br/>
                    As it turns out, that is not always the case. Especially when our device is powered by a battery (rechargeable or otherwise).
                    In that case the optimal setup is not simply speed. We must take into consideration the life of the battery too.
                </p>
                <p>
                    We will discuss the different power saving, low and high power modes of the STM32 MCU based on a simple temperature sampling circuit.
                    But first, we will need a reference case for the comparison.
                    The reference case will be the MCU with 160MHz system clock.
                    We will use a basic timer to trigger the ADC every two seconds, and transmit the sampled data to the PC using UART communication.
                    We will use an <a href="https://www.ti.com/lit/ds/symlink/lm35.pdf">LM35</a> sensor from Texas Instruments,
                    which is a nice little three legged analog temperature sensor IC.
                    It's a convenient IC calibrated in Celsius: the output is 0V at 0K and it has a linear 10mV/C scale factor.
                </p>

                <p>
                    Create a new project, enable the external high speed clock, and the debug ports.
                    Set the system clock frequency to 160MHz.
                    Set the ADC1/IN6 to single-ended input with Timer 6 trigger out event as external trigger source and
                    640.5 cycles for sampling time.
                    Enable the ADC interrupt.
                </p>
                <img className="img_adc_hp_hal21" src={adc_hp_setup} alt="adc_hp_hal21"/>
                <p>
                    Go to Timers/TIM6 and activate it. Create a 2Hz trigger signal from the 160MHz system clock (e.g. Prescaler = 1600, Counter = 50000).
                    Set the trigger event to update event.
                </p>
                <img className="img_tim_hp_hal21" src={tim_hp_setup} alt="tim_hp_hal21"/>

                <p>
                    Finally, go to Connectivity/USART2 and enable the peripheral in Asynchronous mode.
                    Set pins PA2 and PA3 to TX and RX respectively.
                    Generate the project.
                </p>

                <p>
                    Let's create the firmware. We need a few variables beforehand.
                    <i>adc_val</i> and <i>adc_flag</i> are the usual adc sample variable and the conversion complete flag.
                    We will use the <i>temperature</i> variable to store the scaled integer temperature value.
                    <i>temp_string</i> will be used as a string buffer to send the measured temperature to the PC.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {variables}
                </SyntaxHighlighter>
                <p>
                    We can calibrate the ADC, then start it using interrupt.
                    The timer needs to be started so that the conversions get triggered.
                    The <i>printf</i> statement will not work right now, but we will fix it soon.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {hp_setup}
                </SyntaxHighlighter>
                <p>
                    In the infinite loop we wait for a conversion flag.
                    If a new conversion is present, we can convert the voltage to temperature in Celsius degree, and send it with a <i>printf</i> fucntion.
                    Here I was working with mV instead of V units so that I can avoid floating point operations.
                    At  the end of the processing, we reset the flag.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {hp_loop}
                </SyntaxHighlighter>
                <p>
                    The ADC interrupt function is simple.
                    we read the value and set the completion flag.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {hp_adc_irq}
                </SyntaxHighlighter>
                <p>
                    Let's overwrite the default <i>_write</i> function so that we can use the <i>printf</i> function in combination with the UART.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {usart_write}
                </SyntaxHighlighter>
                <p>
                    Build and upload the firmware.
                    Test for the expected operation.
                    <br/>
                    The board has a jumper that let's us measure current consumption.
                    Remove the JP6 (IDD) jumper, attach some cables and connect an Amp meter.

                    According to my multimeter, the current is 31.9mA at 160MHz clock.
                    We will use this result as reference, and we will try to lower this value.

                </p>
            </div>

            <div className="em__post-section">
                <h3>Normal operation at 4MHz:</h3>
                <p>
                    According to the datasheet, the pseudo-drystone test resulted in a 168 uA/MHz power consumtion.
                    At 170MHz the power consumption would be 28.56mA - we got a higher number, but we had other peripherals activated too.
                    According to this number, we could lower the power consumption by lowering the clock frequency.
                </p>
                <p>
                    Open the .ioc file, and change the system clock frequency to 4MHz.
                    This means that the TIM6 configurations should be also changed to still give a 2s trigger event.
                </p>
                <img className="img_tim_lp_hal21" src={tim_lp_setup} alt="tim_lp_hal21"/>
                <p>
                    Upload the firmware, and measure the current.
                    This time the current is 2.6mA at 4MHz clock.
                </p>

                <p>
                    There are other possibilities to reduce power consumption:
                </p>
                <ul>
                    <li>
                        <b>Sleep mode:</b> the CPU is stopped while all peripherals continue to operate and can wake the CPU when an interrupt/event occurs.
                    </li>
                    <li>
                        <b>Stop mode:</b> the SRAM and register contents are saved, clocks in the VCORE domain are stopped.
                        The high-speed oscillators are disabled while the low speed ones keep running.
                        The RTC is active and some peripherals can enable the high speed internal oscillator to process the wake-up event.
                    </li>
                    <li>
                        <b>Standby mode:</b> the internal regulator is switched off to power down the VCORE. The PLL, low and high speed clocks are disabled.
                        The RTC can remain active, the BOR is always active. SRAM and register contents are lost except the backup, RTC and standby domain registers.
                        The device can wake up from this mode using reset, WDT or RTC event
                    </li>
                    <li>
                        <b>Shutdown mode:</b> the internal regulator, PLL and clock sources are shut down.
                        The RTC is active. The BOR is unavailable. RTC domain is not supported.
                        The device exits this mode upon reset, WDT or RTC event.
                    </li>
                </ul>

            </div>

            <div className="em__post-section">
                <h3>Sleep mode:</h3>
                <p>
                    In order to test the sleep mode, we need to make some minor adjustments to the loop and interrupt codes.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {sleep_loop}
                </SyntaxHighlighter>
                <p>
                    After we send the data and we reset the adc flag, we need to disable the systick interrupt (this would wake the MCU).
                    Next, the MCU enters sleep mode by executing wait for interrupt (WFI) or wait for event (WFE) instructions.
                    In WFI mode, any interrupt present in the NVIC can be used to wake the system.
                    Also, we can tell if we want to keep the main regulator powered or not.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {sleep_adc}
                </SyntaxHighlighter>
                <p>
                    The system will be waked by the interrupt generated by the ADC conversion complete.
                    In the interrupt routine, we can re enable the systick interrupt so that delay functions could be used if needed.

                    The rest of the code remains the same as before.
                </p>

                <p>
                    If we are only using interrupts (no code execution in the main function), we could use the <i>SLEEP ON EXIT</i> mode.
                    In this mode the MCU enters sleep mode, the interrupt wakes the processor, the code is executed inside the interrupt, and the processor re enters the sleep mode.

                    Either way, upload the firmware and measure the current.
                    In my case, the current was 2.1mA.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Stop mode:</h3>
                <p>
                    Before we enter the STOP mode (and to stay in it), we must disable the systick so that the interrupts would not wake the CPU.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {stop_loop}
                </SyntaxHighlighter>
                <p>
                    Just like before, we enter STOP mode by executing a wait for interrupt instruction.
                    This time though, only EXTI, IWDG or RTC can wake the system.
                    We will turn the main regulator off, leave only the low power regulator running.
                </p>

                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {stop_exti}
                </SyntaxHighlighter>
                <p>
                    We will use the on-board button to wake the system.
                    Since the system clock was disabled, we need to reconfigure it.
                    Oh, and don't forget to resume systick.
                </p>

                <p>
                    Upload the firmware and measure the current.
                    I had a current consumtion of 10uA while the CPU was in STOP mode, and 2.1mA while running.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Standby mode:</h3>
                <p>
                    The last low power mode that we will try out is the Standby mode.
                    Here we will use the RTC to wake the CPU, take an ADC sample in polled mode, and go to sleep again.
                    So open the ioc file and in System Core/RCC enable the LSE using crystal - we will use the 32.768kHz external crystal.
                    Furthermore, set the ADC trigger source to software and disable the interrupt.
                </p>
                <img className="img_adc_lp_hal21" src={adc_lp_setup} alt="adc_lp_hal21"/>
                <p>
                    Disable TIM6 - we have no use for it.
                    Enable the RTC and set an internal WakeUp of 2 seconds (or more - I used 2 to be similar to the previous cases).
                </p>
                <img className="img_rtc_stdby_hal21" src={rtc_stdby} alt="rtc_stdby_hal21"/>
                <p>
                    This time we won't need any interrupt routines or loops.
                    The wake-up is same as a system reset, the only difference is that the SBF status flag in the PWR_CSR register is set.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {standby}
                </SyntaxHighlighter>
                <p>
                    First, we check if there was a wake event using the PWR_FLAG_SB.
                    If there was, we clear the flag, we acquire a new analog sample using the polling method.
                    We can transform the voltage value into temperature (C) and send it using printf.

                    After these instructions, we can clear the power and RTC flags, enable the RTC interrupt (remember, this setting will be reset after wake-up),
                    and put the system in STANDBY mode.
                </p>

                <p>
                    Build and upload the firmware, measure the current.
                    In my case, in STANDBY mode the current was 3uA, while in wake mode it was 1.5mA.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Other Low-powered features:</h3>
                <p>
                    We can see that the power consumption can be massively optimised compared to a constant high frequency operation mode by
                    placing the device into a low-powered state while inactive.
                </p>
                <p>
                    There are low-powered solutions for the active state too.
                    The most obvious one is to lower the system clock frequency.
                    Another option is to use low-powered peripherals.
                    The STM32G4 has a timer with reduced power consumption LPTIM, and LPUART which could operate using the low speed internal or external oscillator.
                    By only using LPUART, the 1.5mA active current could be reduced to 1.3mA.
                    Lastly, we could reduce the power consumption of inactive pins by putting them in analog mode.
                    This can be done in CubeMX in Project Manager/Code Generation/HAL Settings:
                </p>
                <img className="img_hal_unused_hal21" src={hal_unused} alt="hal_unused_hal21"/>
                <p>
                    This way the current could be reduced to ~1mA in active mode, while retaining a fairly moderate 4MHz system clock.
                    The code with preprocessor directives can be downloaded from <a href="https://gitlab.com/stm32_mcu_group/stm32_hal/21_lowpower.git">here</a>.
                </p>
            </div>



            <div className="em__post-navigation">

                <NavLink to="./../stm-hal-20">
                    <Button btnID={"leftBTN"} buttonSize="btn--medium"> Previous Post</Button>
                </NavLink>

                <NavLink to="./../stm-hal-22">
                    <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
                </NavLink>
            </div>
        </div>
    );
}

export default MCU_HAL21;