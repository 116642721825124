import React from "react";
import "./collection.css";
import {Tag} from "../../common";
import {useNavigate} from "react-router-dom";


const TutList = [
    {name:"1. Setup the programming environment", link: "./stm-hal-1"},
    {name:"2. All about the GPIO", link: "./stm-hal-2"},
    {name:"3. Hardware timers - timing", link: "./stm-hal-3"},
    {name:"4. Hardware timers - CCP", link: "./stm-hal-4"},
    {name:"5. 16x2 LCD interface in 4b mode", link: "./stm-hal-5"},
    {name:"6. Hardware timers - rotary encoder", link: "./stm-hal-6"},
    {name:"7. UART TX", link: "./stm-hal-7"},
    {name:"8. UART RX", link: "./stm-hal-8"},
    {name:"9. Watchdog timers", link: "./stm-hal-9"},
    {name:"10. Real-Time Clock", link: "./stm-hal-10"},
    {name:"11. I2C master - Oled", link: "./stm-hal-11"},
    {name:"12. I2C slave - board to board", link: "./stm-hal-12"},
    {name:"13. SPI master - SRAM interface", link: "./stm-hal-13"},
    {name:"14. SPI master & slave", link: "./stm-hal-14"},
    {name:"15. ADC regular channel", link: "./stm-hal-15"},
    {name:"16. ADC injected channels", link: "./stm-hal-16"},
    {name:"17. ADC timer trigger", link: "./stm-hal-17"},
    {name:"18. DAC regular channel", link: "./stm-hal-18"},
    {name:"19. DAC timer trigger", link: "./stm-hal-19"},
    {name:"20. Other analog features", link: "./stm-hal-20"},
    {name:"21. Low power mode", link: "./stm-hal-21"},
    {name:"22. Direct Memory Access", link:"./stm-hal-22"},
    {name:"23. Quad SPI FLASH",link: "./stm-hal-23"},
    {name:"24. FATFS, SDIO lines", link: "./stm-hal-24"},
    {name:"25. Audio interfaces", link: "./stm-hal-25"},
    {name:"26.  USB virtual COM port", link: "./stm-hal-26"},
    {name:"27.  USB human interface device", link: "./stm-hal-27"},
    {name:"28. USB mass storage class", link: "./stm-hal-28"},
    {name:"29. Dual-Core MCU debugging", link: "./stm-hal-29"},
    {name:"30. Hardware semaphores", link: "./stm-hal-30"},
    {name:"31. OpenAmp communication", link: "./stm-hal-31"},
    {name:"32. Memory Protection unit", link: "./stm-hal-32"},
    {name:"33. Ethernet with LwIP", link: "./stm-hal-33"},
    {name:"34. UDP client and server", link: "./stm-hal-34"},
    {name:"35. TCP client and server", link: "./stm-hal-35"},
    {name:"36. STM32WB BT setup", link: "./stm-hal-36"},
    //{name:"29. USB audio device", link: "./stm-hal-29"},
    //{name:"30. USB device firmware upgrade", link: "./stm-hal-30"}
]

function STM_HAL(){

    const navigate = useNavigate();

    return(
        <div className="em__collection">
            <div className="em__collection-title">
                <h1 className="gradient__text">Microcontroller tutorials using the hardware abstraction layer provided by ST</h1>
            </div>

            <div className="em__collection-list">
                {
                    TutList.map((item) => (
                        <div >
                            <Tag title={item.name} description={item.description} onClick={() => navigate(item.link)}/>
                        </div>
                    ))
                }
            </div>

        </div>
    );
}

export default STM_HAL;