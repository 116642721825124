import React from "react";
import "./../../../global.css";
import "./../../../index.css";
import "./whatem.css";
import Feature from "./../feature/Feature";
import { NavLink } from "react-router-dom";
import {Button} from "../../../common";

function Whatem(){
    return(
        <div className="em__whatem section__margin" id="whatEM">
            <div className="em__whatem-feature">
                <Feature title="Who is the Embedded Musician?"
                         text="Fehér Áron was born in Romania in 1992.
                He received the B.Sc. degree in Automation and Applied Informatics from Sapientia Hungarian University of Transylvania, in 2015.
                He received the M.Sc. degree in Intelligent Control Systems from the same university, in 2017.
                He won twice the 2nd price in the Digilent Design Contests European regional phase.
                His research interests include digital signal processing, control theory and artificial intelligence.
                He is known for his natural ability to explain abstract concepts in engaging, interesting and thought-provoking ways.
                His compassionate approach sparks curiosity and connects with people at all skill levels, whether it’s
                within his published works and or the courses he teaches."/>
            </div>
            <div className="em__whatem-heading">
                <h1 className="gradient__text">
                    Let's create something that really matters!
                </h1>
                <p>
                    <NavLink to="/projects">
                        <Button buttonSize="btn--medium"> Explore my past and current projects</Button>
                    </NavLink>
                </p>
            </div>
            <div className="em__whatem-technologies">
                <h1>
                    I've worked with a range of technologies from hardware design
                    to firmware and user space programs.
                </h1>
            </div>
            <div className="em__whatem-container">
                <Feature title="PCB Design"
                         text="Experience with Altium Designer, Autodesk Eagle and KiCad"/>
                <Feature title="Firmware Development"
                         text="ARM, C55X, MIPS, RISC-V programming in C/C++ and ASM"/>
                <Feature title="Embedded Operating Systems"
                         text="From FreeRtOS to Linux with BusyBox and Yocto"/>
                <Feature title="Advanced Engineering Topics"
                         text="System modelling and identification, analog and digital signal processing,
                modern and robust control algorithms"/>
            </div>
            <div className="em__whatem-moreAbout">
                <h1>
                    <NavLink to="/about">
                        <Button buttonSize="btn--large">Learn more about the Embedded Musician </Button>
                    </NavLink>
                </h1>
            </div>
        </div>
    );
}

export default Whatem;