import React from "react";
import "./mcu_hal35.css";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";

const stm32_tcp_server_h = `
#ifndef INC_TCP_SERVER_H_
#define INC_TCP_SERVER_H_

void tcp_server_init();

#endif
`;

const stm32_tcp_server_c1 = `
#include "lwip/pbuf.h"
#include "lwip/udp.h"
#include "lwip/tcp.h"

#include "stdio.h"
#include "string.h"
#include "tcp_server.h.old"

enum tcp_server_states{
  ES_NONE = 0,
  ES_ACCEPTED,
  ES_RECEIVED,
  ES_CLOSING
};

struct tcp_server_struct{
  u8_t state;
  u8_t retries;
  struct tcp_pcb *pcb;
  struct pbuf *p;
};

static err_t tcp_server_accept(void *arg, struct tcp_pcb *newpcb, err_t err);
static err_t tcp_server_recv(void *arg, struct tcp_pcb *tpcb, struct pbuf *p, err_t err);
static void tcp_server_error(void *arg, err_t err);
static err_t tcp_server_poll(void *arg, struct tcp_pcb *tpcb);
static err_t tcp_server_sent(void *arg, struct tcp_pcb *tpcb, u16_t len);
static void tcp_server_send(struct tcp_pcb *tpcb, struct tcp_server_struct *es);
static void tcp_server_connection_close(struct tcp_pcb *tpcb, struct tcp_server_struct *es);

static void tcp_server_handle (struct tcp_pcb *tpcb, struct tcp_server_struct *es);


void tcp_server_init(void){
    struct tcp_pcb *tpcb;
    
    tpcb = tcp_new();
    
    err_t err;
    
    ip_addr_t myIPADDR;
    IP_ADDR4(&myIPADDR, 192, 168, 69, 123);
    err = tcp_bind(tpcb, &myIPADDR, 42069);
    
    if (err == ERR_OK){
        tpcb = tcp_listen(tpcb);
        tcp_accept(tpcb, tcp_server_accept);
    }else{
        memp_free(MEMP_TCP_PCB, tpcb);
    }
}

static err_t tcp_server_accept(void *arg, struct tcp_pcb *newpcb, err_t err){
  err_t ret_err;
  struct tcp_server_struct *es;

  LWIP_UNUSED_ARG(arg);
  LWIP_UNUSED_ARG(err);

  tcp_setprio(newpcb, TCP_PRIO_MIN);

  es = (struct tcp_server_struct *)mem_malloc(sizeof(struct tcp_server_struct));
  if (es != NULL){
    es->state = ES_ACCEPTED;
    es->pcb = newpcb;
    es->retries = 0;
    es->p = NULL;

    tcp_arg(newpcb, es);
    tcp_recv(newpcb, tcp_server_recv);
    tcp_err(newpcb, tcp_server_error);
    tcp_poll(newpcb, tcp_server_poll, 0);
    ret_err = ERR_OK;
  }else{
    tcp_server_connection_close(newpcb, es);
    ret_err = ERR_MEM;
  }
  return ret_err;
}

static err_t tcp_server_recv(void *arg, struct tcp_pcb *tpcb, struct pbuf *p, err_t err){
  struct tcp_server_struct *es;
  err_t ret_err;

  LWIP_ASSERT("arg != NULL",arg != NULL);

  es = (struct tcp_server_struct *)arg;

  if (p == NULL){
    es->state = ES_CLOSING;
    if(es->p == NULL)
       tcp_server_connection_close(tpcb, es);
    else {
      tcp_sent(tpcb, tcp_server_sent);
      tcp_server_send(tpcb, es);
    }
    ret_err = ERR_OK;
  }else if(err != ERR_OK){
    if (p != NULL){
      es->p = NULL;
      pbuf_free(p);
    }
    ret_err = err;
  }else if(es->state == ES_ACCEPTED){
    es->state = ES_RECEIVED;
    es->p = p;
    tcp_sent(tpcb, tcp_server_sent);
    tcp_server_handle(tpcb, es);
    ret_err = ERR_OK;
  }else if (es->state == ES_RECEIVED){
    if(es->p == NULL){
      es->p = p;
      tcp_server_handle(tpcb, es);
    }else{
      struct pbuf *ptr;
      ptr = es->p;
      pbuf_chain(ptr,p);
    }
    ret_err = ERR_OK;
  }else if(es->state == ES_CLOSING){
    tcp_recved(tpcb, p->tot_len);
    es->p = NULL;
    pbuf_free(p);
    ret_err = ERR_OK;
  }else{
    tcp_recved(tpcb, p->tot_len);
    es->p = NULL;
    pbuf_free(p);
    ret_err = ERR_OK;
  }
  return ret_err;
}`

const stm32_tcp_server_c2 = `
static void tcp_server_error(void *arg, err_t err){
  struct tcp_server_struct *es;

  LWIP_UNUSED_ARG(err);
  es = (struct tcp_server_struct *)arg;
  if (es != NULL)
    mem_free(es);
}

static err_t tcp_server_poll(void *arg, struct tcp_pcb *tpcb){
  err_t ret_err;
  struct tcp_server_struct *es;

  es = (struct tcp_server_struct *)arg;
  if (es != NULL){
    if (es->p != NULL){
      tcp_sent(tpcb, tcp_server_sent);
      tcp_server_send(tpcb, es);
    }else{
      if(es->state == ES_CLOSING)
        tcp_server_connection_close(tpcb, es);
    }
    ret_err = ERR_OK;
  }else{
    tcp_abort(tpcb);
    ret_err = ERR_ABRT;
  }
  return ret_err;
}

static err_t tcp_server_sent(void *arg, struct tcp_pcb *tpcb, u16_t len){
  struct tcp_server_struct *es;

  LWIP_UNUSED_ARG(len);
  es = (struct tcp_server_struct *)arg;
  es->retries = 0;
  if(es->p != NULL){
    tcp_sent(tpcb, tcp_server_sent);
    tcp_server_send(tpcb, es);
  }else{
    if(es->state == ES_CLOSING)
      tcp_server_connection_close(tpcb, es);
  }
  return ERR_OK;
}

static void tcp_server_send(struct tcp_pcb *tpcb, struct tcp_server_struct *es){
  struct pbuf *ptr;
  err_t wr_err = ERR_OK;

  while ((wr_err == ERR_OK) &&
         (es->p != NULL) &&
         (es->p->len <= tcp_sndbuf(tpcb))){
    ptr = es->p;
    wr_err = tcp_write(tpcb, ptr->payload, ptr->len, 1);
    if (wr_err == ERR_OK){
      u16_t plen;
      u8_t freed;
      plen = ptr->len;
      es->p = ptr->next;
      if(es->p != NULL){
        pbuf_ref(es->p);
      }
      do{
        freed = pbuf_free(ptr);
      }while(freed == 0);
     tcp_recved(tpcb, plen);
   }else if(wr_err == ERR_MEM){
     es->p = ptr;
   }else{
   }
  }
}

static void tcp_server_connection_close(
    struct tcp_pcb *tpcb, 
    struct tcp_server_struct *es){
  tcp_arg(tpcb, NULL);
  tcp_sent(tpcb, NULL);
  tcp_recv(tpcb, NULL);
  tcp_err(tpcb, NULL);
  tcp_poll(tpcb, NULL, 0);

  if (es != NULL)
    mem_free(es);

  tcp_close(tpcb);
}

static void tcp_server_handle (struct tcp_pcb *tpcb, 
    struct tcp_server_struct *es){
    struct tcp_server_struct *esTx;
    
    ip4_addr_t inIP = tpcb->remote_ip;
    uint16_t inPort = tpcb->remote_port;
    
    char *remIP = ipaddr_ntoa(&inIP);
    
    esTx = (struct tcp_server_struct *)mem_malloc(
        sizeof(struct tcp_server_struct));
    
    esTx->state = es->state;
    esTx->pcb = es->pcb;
    esTx->p = es->p;
    
    char buf[100];
    memset(buf, '\\0', 100);
    
    strncpy(buf, (char *)es->p->payload, es->p->tot_len);
    strcat(buf, ": Hello from TCP SERVER\\r\\n");
    
    
    esTx->p->payload = (void *)buf;
    esTx->p->tot_len = (es->p->tot_len - es->p->len) + strlen (buf);
    esTx->p->len = strlen (buf);
    
    tcp_server_send(tpcb, esTx);
    
    pbuf_free(es->p);
}


`;

const python_tcp_client = `
import socket


def tcp_client(host, port):
    client_socket = socket.socket(socket.AF_INET, socket.SOCK_STREAM)

    server_address = (host, port)
    print(f"Connecting to {host} port {port}")
    client_socket.connect(server_address)

    try:
        message = '1'
        print(f"Sending: {message}")
        client_socket.sendall(message.encode())

        amount_received = 0
        amount_expected = len(message)

        while amount_received < amount_expected:
            data = client_socket.recv(31)
            amount_received += len(data)
            print(f"Received: {data.decode()}")

    finally:
        print("Closing connection")
        client_socket.close()


if __name__ == "__main__":
    host = '192.168.69.123'  
    port = 42069  
    tcp_client(host, port)
`;

const stm32_tcp_client_h = `
#ifndef INC_TCP_CLIENT_H_
#define INC_TCP_CLIENT_H_

void tcp_client_init();

#endif
`;

const stm32_tcp_client_c1 = `
#include "tcp_client.h"

#include "lwip/pbuf.h"
#include "lwip/udp.h"
#include "lwip/tcp.h"

#include "stdio.h"
#include "string.h"

enum tcp_client_states{
  ES_NONE = 0,
  ES_CONNECTED,
  ES_RECEIVING,
  ES_CLOSING
};

struct tcp_client_struct{
  u8_t state;
  u8_t retries;
  struct tcp_pcb *pcb;
  struct pbuf *p;
};

static err_t tcp_client_connected(void *arg, struct tcp_pcb *newpcb, err_t err);
static err_t tcp_client_recv(void *arg, struct tcp_pcb *tpcb, struct pbuf *p, err_t err);
static err_t tcp_client_poll(void *arg, struct tcp_pcb *tpcb);
static err_t tcp_client_sent(void *arg, struct tcp_pcb *tpcb, u16_t len);
static void tcp_client_send(struct tcp_pcb *tpcb, struct tcp_client_struct *es);
static void tcp_client_connection_close(struct tcp_pcb *tpcb, struct tcp_client_struct *es);
static void tcp_client_handle(struct tcp_pcb *tpcb, struct tcp_client_struct *es);

int counter = 0;
uint8_t data[100];

extern TIM_HandleTypeDef htim1;

struct tcp_client_struct *esTx = 0;
struct tcp_pcb *pcbTx = 0;

void HAL_TIM_PeriodElapsedCallback(TIM_HandleTypeDef *htim){
    char buf[100];
    int len = sprintf(buf, "Sending TCP client message %d.\\r\\n", counter);
    
    if(counter != 0){
        esTx->p = pbuf_alloc(PBUF_TRANSPORT, len, PBUF_POOL);
        pbuf_take(esTx->p, (char*)buf, len);
        tcp_client_send(pcbTx, esTx);
        pbuf_free(esTx->p);
    }

}

void tcp_client_init(){
    struct tcp_pcb *tpcb;
    
    tpcb = tcp_new();
    ip_addr_t destIPADDR;
    IP_ADDR4(&destIPADDR, 192, 168, 69, 118);
    tcp_connect(tpcb, &destIPADDR, 42069, tcp_client_connected);
}

static err_t tcp_client_connected(void *arg, struct tcp_pcb *newpcb, err_t err){
    err_t ret_err;
    struct tcp_client_struct *es;
    
    LWIP_UNUSED_ARG(arg);
    LWIP_UNUSED_ARG(err);
    es = (struct tcp_client_struct*)mem_malloc(sizeof(struct tcp_client_struct));
    if(es != NULL){
        es->state = ES_CONNECTED;
        es->pcb = newpcb;
        es->retries = 0;
        es->p = NULL;
        tcp_arg(newpcb, es);
        tcp_recv(newpcb, tcp_client_recv);
        tcp_poll(newpcb, tcp_client_poll, 0);
        tcp_sent(newpcb, tcp_client_sent);
        tcp_client_handle(newpcb, es);
        ret_err = ERR_OK;
    }else{
        tcp_client_connection_close(newpcb, es);
        ret_err = ERR_MEM;
    }
    return ret_err;
}

static err_t tcp_client_recv(void *arg, struct tcp_pcb *tpcb, struct pbuf *p, err_t err)
{
  struct tcp_client_struct *es;
  err_t ret_err;

  LWIP_ASSERT("arg != NULL",arg != NULL);

  es = (struct tcp_client_struct *)arg;
  if (p == NULL){
    es->state = ES_CLOSING;
    if(es->p == NULL){
       tcp_client_connection_close(tpcb, es);
    }else{

    }
    ret_err = ERR_OK;
  }else if(err != ERR_OK){
    if (p != NULL){
      es->p = NULL;
      pbuf_free(p);
    }
    ret_err = err;
  }else if(es->state == ES_CONNECTED){
    es->p = p;
    tcp_recved(tpcb, p->tot_len);
    tcp_client_handle(tpcb, es);
    pbuf_free(p);
    ret_err = ERR_OK;
  }else if(es->state == ES_CLOSING){
    tcp_recved(tpcb, p->tot_len);
    es->p = NULL;
    pbuf_free(p);
    ret_err = ERR_OK;
  }else{
    tcp_recved(tpcb, p->tot_len);
    es->p = NULL;
    pbuf_free(p);
    ret_err = ERR_OK;
  }
  return ret_err;
}`

const stm32_tcp_client_c2 = `
static err_t tcp_client_poll(void *arg, struct tcp_pcb *tpcb){
  err_t ret_err;
  struct tcp_client_struct *es;

  es = (struct tcp_client_struct *)arg;
  if (es != NULL){
    if (es->p != NULL)
    {

    }else{
      if(es->state == ES_CLOSING)
      {
        tcp_client_connection_close(tpcb, es);
      }
    }
    ret_err = ERR_OK;
  }else{
    tcp_abort(tpcb);
    ret_err = ERR_ABRT;
  }
  return ret_err;
}

static err_t tcp_client_sent(void *arg, struct tcp_pcb *tpcb, u16_t len)
{
  struct tcp_client_struct *es;
  LWIP_UNUSED_ARG(len);
  es = (struct tcp_client_struct *)arg;
  es->retries = 0;

  if(es->p != NULL){

  }else{
    if(es->state == ES_CLOSING)
      tcp_client_connection_close(tpcb, es);
  }
  return ERR_OK;
}

static void tcp_client_send(struct tcp_pcb *tpcb, struct tcp_client_struct *es){
  struct pbuf *ptr;
  err_t wr_err = ERR_OK;

  while ((wr_err == ERR_OK) &&
         (es->p != NULL) &&
         (es->p->len <= tcp_sndbuf(tpcb))){
    ptr = es->p;
    wr_err = tcp_write(tpcb, ptr->payload, ptr->len, 1);
    if (wr_err == ERR_OK){
      u16_t plen;
      u8_t freed;

      plen = ptr->len;
      es->p = ptr->next;
      if(es->p != NULL){
        pbuf_ref(es->p);
      }
      do{

        freed = pbuf_free(ptr);
      }while(freed == 0);
   }else if(wr_err == ERR_MEM){
     es->p = ptr;
   }
   else
   {
   }
  }
}

static void tcp_client_connection_close(
    struct tcp_pcb *tpcb, struct tcp_client_struct *es){
  tcp_arg(tpcb, NULL);
  tcp_sent(tpcb, NULL);
  tcp_recv(tpcb, NULL);
  tcp_err(tpcb, NULL);
  tcp_poll(tpcb, NULL, 0);

  if (es != NULL){
    mem_free(es);
  }
  tcp_close(tpcb);
}

static void tcp_client_handle (struct tcp_pcb *tpcb, 
    struct tcp_client_struct *es){
    ip4_addr_t inIP = tpcb->remote_ip;
    uint16_t inPort = tpcb->remote_port;
    char *remIP = ipaddr_ntoa(&inIP);
    esTx = es;
    pcbTx = tpcb;
    counter++;
}

}`;

const python_tcp_server = `
import socket


def tcp_server(host, port):
    server_socket = socket.socket(socket.AF_INET, socket.SOCK_STREAM)

    server_address = (host, port)
    print(f"Starting server on {host} port {port}")
    server_socket.bind(server_address)

    server_socket.listen(1)

    while True:
        print("Waiting for a connection")
        connection, client_address = server_socket.accept()

        try:
            print(f"Connection from {client_address}")

            while True:
                data = connection.recv(31)
                if data:
                    print(f"Received: {data.decode()}")
                    response = f"Echoing: {data.decode()}"
                    print(f"Sending: {response}")
                    connection.sendall(response.encode())
                else:
                    print("No more data from", client_address)
                    break

        finally:
            connection.close()


if __name__ == "__main__":
    host = '0.0.0.0'  
    port = 42069 
    tcp_server(host, port)
`;



function MCU_HAL35(){
    return(
        <div className="em__post">
            <div className="em__post-title">
                <h1>TCP server and client</h1>
            </div>

            <div className="em__post-section">
                <h3>Aim of this tutorial:</h3>
                <p>
                    Similarly to the previous one, we are tackling two problems in this tutorial.
                    First, we will configure the NUCLEO-H745 as a TCP server and write a Python-based TCP client that connects to it.
                    Second, we will reverse the order, create a Python-based TCO server that listens to every address and sends a message when the
                    NUCLEO-H745, as a TCP client, connects.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Transmission Control Protocol (TCP):</h3>
                <p>
                    The Transmission Control Protocol (TCP) is a fundamental communication protocol in the Internet protocol suite,
                    designed to provide reliable, ordered, and error-checked data delivery between applications running on different hosts.
                    TCP is connection-oriented, connecting the sender and receiver before data transmission begins.
                    This is done through a process known as the three-way handshake,
                    which ensures that both parties are ready to communicate and agree on initial parameters.
                    Once the connection is established, TCP manages the data flow to prevent congestion,
                    ensuring that packets are delivered in the correct order and retransmitting any lost packets.
                    <br/>
                    One of the critical features of TCP is its ability to detect and correct errors.
                    It uses checksums to verify the data's integrity; if any discrepancies are found, it can request retransmission of the corrupted packets.
                    TCP also implements flow control mechanisms using sliding windows, which allow the sender to send multiple packets before needing an acknowledgement from the receiver,
                    improving the efficiency of data transfer.
                    Additionally, congestion control algorithms are employed to adjust the rate of data transmission based on the network's current capacity,
                    helping to prevent network overload and maintain smooth communication.
                    These features make TCP a robust and reliable protocol widely used for various applications, including web browsing, email, and file transfer.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Project setup:</h3>
                <p>
                    The project setup is the same as in the previous tutorial.
                    Either create the new files for that project or clone the project.
                </p>
            </div>

            <div className="em__post-section">
                <h3>STM32 TCP server:</h3>

                <p>
                    Create a header file that exposes the init function.
                    I named the header <i>tcp_server.h</i>, so the function handle looks like:
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {stm32_tcp_server_h}
                </SyntaxHighlighter>
                <p>
                    This time, the source is more involved than in the case of the UDP server.
                    I've based this design on the ones provided by STMicro in their example projects.
                    <br/>
                    The server is created by creating a TCP socket, binding it to the server address (device),
                    and then using the listen function to put the server in a passive mode, waiting for a client to connect.
                    Finally, the connection is established with the accept function, after which the data transfer can happen.
                    <br/>
                    When a client connection is accepted, the <i>tcp_server_accept</i> function is called.
                    This function initializes more callbacks, such as reception, error, and polling.
                    <br/>
                    The <i>tcp_server_recv</i> is called to handle the received data.
                    The <i>tcp_server_handle</i> processes the received data.
                    Here, we can get the client's IP and port.

                </p>

                <div className="two-column-code_35">
                    <div className="column_35">
                        <SyntaxHighlighter language="c" style={AtomOneDark}>
                            {stm32_tcp_server_c1}
                        </SyntaxHighlighter>
                    </div>
                    <div className="column_35">
                        <SyntaxHighlighter language="c" style={AtomOneDark}>
                            {stm32_tcp_server_c2}
                        </SyntaxHighlighter>
                    </div>
                </div>
                <p>
                    The main source must only include our TCP header and the init function call.
                </p>
            </div>

            <div className="em__post-section">
                <h3>TCP client in Python:</h3>

                <p>
                    On the Python side, we create a socket. The socket type is <b>STREAM</b> this time.
                    The client connects to the server, and if it's successful, then sends a message and waits for a response.
                </p>

                <SyntaxHighlighter language="python" style={AtomOneDark}>
                    {python_tcp_client}
                </SyntaxHighlighter>
            </div>

            <div className="em__post-section">
                <h3> STM32 TCP client: </h3>

                <p>
                    Clone the previous project or rename the newly-added files as <i>tcp_server.h_old</i> and <i>tcp_server.c_old</i>.
                    Then, create a header and source file for the client.
                    <br/>
                    I want to send messages periodically to the server, so I enabled the TIM1 interrupt with a period of 1s, just as in the previous tutorial.
                </p>

                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {stm32_tcp_client_h}
                </SyntaxHighlighter>

                <p>
                    The header is similar to the server, we only need to expose the connect function handle.
                </p>
                <div className="two-column-code_35">
                    <div className="column_35">
                        <SyntaxHighlighter language="c" style={AtomOneDark}>
                            {stm32_tcp_client_c1}
                        </SyntaxHighlighter>
                    </div>
                    <div className="column_35">
                        <SyntaxHighlighter language="c" style={AtomOneDark}>
                            {stm32_tcp_client_c2}
                        </SyntaxHighlighter>
                    </div>
                </div>

                <p>
                    The client functions are similar to the TCP server; here, we have an additional timer interrupt callback for periodical message generation.
                    <br/>
                    In the main source, call the <i>HAL_TIM_Base_Start_IT(&htim1)</i> to start the Timer 1 interrupt,
                    and then call the TCP client init function.
                </p>

                <SyntaxHighlighter language="python" style={AtomOneDark}>
                    {python_tcp_server}
                </SyntaxHighlighter>

                <p>
                    On the Python side, the server listens to every address.
                    If a message arrives, the server replies; this way, the client counter increases after every sent message.
                </p>
            </div>


            <div className="em__post-navigation">

                <NavLink to="./../stm-hal-34">
                    <Button btnID={"leftBTN"} buttonSize="btn--medium"> Previous Post</Button>
                </NavLink>

                <NavLink to="./../stm-hal-36">
                    <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
                </NavLink>
            </div>

        </div>
    );
}

export default MCU_HAL35;