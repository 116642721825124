import React from "react";
import "./mcu_hal24.css";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";

import card_io from "./card_io.png";
import block_sdio_cube from "./block_sdio_cube.jpg";

const block_test = `
uint8_t rx_buffer[1024];
uint8_t tx_buffer[1024];

uint8_t compare_result = 1;

if(HAL_SD_GetError(&hsd) != HAL_SD_ERROR_NONE)
    Error_Handler();

for(uint16_t i = 0; i < 1024; ++i) {
    tx_buffer[i] = (uint8_t) (i & 0x00FF);
    rx_buffer[i] = 0;
}

if(HAL_SD_WriteBlocks(&hsd, tx_buffer, 1, 4, HAL_MAX_DELAY) != HAL_OK)
    Error_Handler();

do{
    cardState = HAL_SD_GetCardState(&hsd);
} while (cardState != HAL_SD_CARD_TRANSFER);

if(HAL_SD_ReadBlocks(&hsd, rx_buffer, 1, 4, HAL_MAX_DELAY) != HAL_OK)
    Error_Handler();

do{
    cardState = HAL_SD_GetCardState(&hsd);
} while (cardState != HAL_SD_CARD_TRANSFER);

for(uint16_t i = 0; i < 1024; ++i)
    if(tx_buffer[i] != rx_buffer[i])
        compare_result = 0;

if(compare_result == 1)
    HAL_GPIO_WritePin(LD2_GPIO_Port, LD2_Pin, GPIO_PIN_SET);
else
    Error_Handler();
`;

function MCU_HAL24(){
    return(
        <div className="em__post">
            <div className="em__post-title">
                <h1>Quad SPI Flash</h1>
            </div>

            <div className="em__post-section">
                <h3>Aim of this tutorial:</h3>
                <p>
                    In this tutorial, we will learn how to interface a cheap SD memory card to the MCU using SDIO peripheral.
                    We will try out the low-level memory operations and the file system operations.
                </p>
            </div>

            <div className="em__post-section">
                <h3>About SD cards:</h3>
                <p>
                    In the last tutorial, we talked about QSPI Flash which was wast, it had relatively large storage capacity, but it came in a non portable form.
                    This time around, we will use a Secure Digital (SD) card (or micro SD card) to store our data in a way that can be read and modified even on another device.
                    If you want to learn more about SD cards, <a href="https://en.wikipedia.org/wiki/SD_card#/media/File:SD_Cards.svg">this</a> Wikipedia article is a nice starting source.
                </p>
                <img className="img_card_io_hal24" src={card_io} alt="card_io_hal24"/>
                <p>
                    The SD card can be used in SPI mode or in SDIO mode.
                    Here we can se the eight pins of the micro SD card (The SD card is similar, with an additional supply pin).
                    <ul>
                        <li>Pin1 - data bit 2 (DAT2) in SDIO mode, unused in SPI mode</li>
                        <li>Pin2 - data bit 3 (CD/DAT3) in SDIO mode, chip select (CS) in SPI mode</li>
                        <li>Pin3 - command bit (CMD) in SDIO mode, data input (MOSI) in SPI mode</li>
                        <li>Pin4 - supply pin (VDD) in both modes</li>
                        <li>Pin5 - clock signal (CLK) in both modes</li>
                        <li>Pin6 - ground pin (GND) in both modes</li>
                        <li>Pin7 - data bit 0 (DAT0) in SDIO mode, data output (MISO) in SPI mode.</li>
                        <li>Pin8 - data bit 1 (DAT1) in SDIO mode, unused in SPI mode.</li>
                    </ul>
                </p>
                <p>
                    We already saw that more data lines mean more speed, so we will use the SDIO mode.
                    About the hardware... So far, these tutorials were based on the NUCLEO-G491RE board,
                    but sadly the STM32G do not have SDIO interface,
                    so we will switch to a <a href="https://www.st.com/en/evaluation-tools/nucleo-f411re.html">NUCLEO-F411RE</a> board instead.
                    If it was not obvious so far, we need an SD card with an SD card adapter (or the micro equivalent).
                    I've opted for a Fujifilm 2GB micro SD card in SD card adapter with the Digilent <a href="https://digilent.com/reference/pmod/pmodsd/start">PmodSD</a> breakout board
                    (It's a bit overpriced compared to those on eBay, but I only had this one at hand).
                </p>
            </div>

            <div className="em__post-section">
                <h3>Block based instructions - setup:</h3>
                <p>
                    The first step to using file systems is to not use any file system, and to check out the block read and write functionality.
                    Here we will create two buffers with 1024 elements.
                    We will initialize one of them, and write the data to four blocks in the SD card.
                    After the write operation finishes, we will read back the data and we will compare the received data with the original one.
                </p>
                <Popup trigger={<img className="img_block_sdio_cube_hal24 clickable_img" src={block_sdio_cube} alt="block_sdio_cube_hal24"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={block_sdio_cube} alt="block_sdio_cube_hal24" />
                    )}
                </Popup>
                <p>
                    Create a new project with the usual clock and debug settings.
                    Under connectivity, enable the SDIO peripheral with <i>SD 4 bits Wide bus</i>.
                    Set the SDIO clock divider to 39 so that our cabling can handle the signals.
                    The SD clock signal is generated from the Q divider of the main PLL block, which in my case generates 40MHz clock signal -
                    this creates a 1MHz clock signal after division.
                    Generate the code and go to the main.c file.
                </p>

            </div>

            <div className="em__post-section">
                <h3>Block based instructions - firmware:</h3>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {block_test}
                </SyntaxHighlighter>

                <p>
                    The code is mostly self explanatory. First we check if there were any errors with the SD card initialisation (like loose connections or not plugging in the card).
                    If there are no errors, we can fill the transmit buffer.
                    We send the data to the SD card, and we wait for the writing to finish by probing the state register.
                    After the writing procedure finished, we can read the data back (and wait for the procedure to finish).
                    Finally, we can compare the original data with the received one and look for a lit up LED.
                </p>

                <p>
                    Speaking of writing data.
                    The write function has two important parameters: start address and block length.
                    In our case the start address is 1 and the block length is 4 (4*256 = 1024).
                    This means that no matter how many times we restart the MCU, it will write this data starting the address 1.
                    This action leads to memory wearing - the start of the memory region will always have a shorter life than the rest.
                    <br/>
                    This wearing effect can be solved by logging how many writes each block has and distributing
                    the data in such a way that every block is used equally (or as close as possible).
                    Of course, this requires some additional resources to keep track where our data is being held.
                    Also, it would be nice if we could store some common files instead of raw data from a random array.
                </p>
            </div>

            <div className="em__post-section">
                <h3>File systems:</h3>
                <p>
                    The solution for the previously mentioned problems can be solved using file systems.
                    A file system, in most basic words, keeps track of the data type and the location of the data.
                    There are many file systems such as FAT (file alocation table) - with some variations like FAT16, FAT32,
                    exFAT which maintains a table of each files allocation;
                    NTFS (new technology file system) is a journaling file system for Windows which is more robust and effective than FAT;
                    ReFS (resilient file systems) which overcome some of the NTFS shortcomings;
                    EXT (extended file system) a journaling file system for Linux with EXT2, EXT3, EXT4 versions.
                </p>
                <p>
                    In the next part, we will use the FatFS middleware provided for the STM32 MCUs with our SD card formatted to FAT32.
                </p>
            </div>
            <div className="em__post-section">
                <h3>File system operation:</h3>
                <p>
                    Open up the .ioc file.
                    Enable USART2 so that we can log some messages and go to Middleware/FATFS.
                    Enable the SD card and leave everything on default value.
                    There will be a warning, since we do not use a GPIO for card detection, but the project will work regardless.
                </p>
                <p>
                    Here are some useful functions for data handling:
                    <ul>
                        <li><b>f_getfree</b> returns the free clusters on a logical drive,</li>
                        <li><b>f_mount</b> mounts or unmounts a logical drive,</li>
                        <li><b>f_mkdir</b> creates a directory,</li>
                        <li><b>f_opendir</b> creates a directory object,</li>
                        <li><b>f_readdir</b> reads directory objects in sequence,</li>
                        <li><b>f_unlink</b> deletes a file or directory,</li>
                        <li><b>f_open</b> open or create a file,</li>
                        <li><b>f_write</b> writes a file,</li>
                        <li><b>f_read</b> read a file,</li>
                        <li><b>f_close</b> closes file,</li>
                    </ul>
                </p>

                <p>
                    Go to <a href="https://gitlab.com/stm32_mcu_group/stm32_hal/24_sdio.git">this</a> repo and download the source files.
                    There is a <i>file_handling</i> header and source combo.
                    These contain functions for mounting, unmounting and formatting the card.
                    You can create directories; create, read, write and delete files.
                    <br/>
                    Build and upload the code.
                    Start a virtual COM port and study the program execution.
                    After a few seconds, push the user button to stop the writing operation and to unmount the SD card.
                    Load it onto an USB card reader and check that there are two files on the card and that they are not empty.
                </p>
            </div>

            <div className="em__post-navigation">

                <NavLink to="./../stm-hal-23">
                    <Button btnID={"leftBTN"} buttonSize="btn--medium"> Previous Post</Button>
                </NavLink>

                <NavLink to="./../stm-hal-25">
                    <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
                </NavLink>
            </div>
        </div>
    );
}

export default MCU_HAL24;