import React from "react";
import "./mcu_hal30.css";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";

import hsem_m4 from "./hsem_int_m4.jpg";
import hsem_m7 from "./hsem_int_m7.jpg";

const cm7_signal = `
HAL_HSEM_FastTake(HSEM_ID_0);
for(uint8_t i = 0; i < 6; ++i){
    HAL_GPIO_TogglePin(LD1_GPIO_Port, LD1_Pin);
    HAL_Delay(500);
}
HAL_HSEM_Release(HSEM_ID_0, 0);
`;

const cm4_signal1 = `
volatile uint8_t hsem_notif = 0;

void HAL_HSEM_FreeCallback(uint32_t SemMask){
    hsem_notif = 1;
    HAL_HSEM_ActivateNotification(__HAL_HSEM_SEMID_TO_MASK(HSEM_ID_0));
}
`;

const cm4_signal2 = `
if(hsem_notif == 1){
    hsem_notif = 0;
    HAL_GPIO_TogglePin(LD2_GPIO_Port, LD2_Pin);
}
`;

const cm7_sync = `
HAL_HSEM_FastTake(HSEM_ID_0);
HAL_GPIO_WritePin(LD1_GPIO_Port, LD1_Pin, GPIO_PIN_SET);
HAL_Delay(3000);
HAL_GPIO_WritePin(LD1_GPIO_Port, LD1_Pin, GPIO_PIN_RESET);
HAL_HSEM_Release(HSEM_ID_0, 0);
`;

const cm4_sync = `
HAL_GPIO_WritePin(LD2_GPIO_Port, LD2_Pin, GPIO_PIN_RESET);
while(HAL_HSEM_IsSemTaken(HSEM_ID_0));
HAL_GPIO_WritePin(LD2_GPIO_Port, LD2_Pin, GPIO_PIN_SET);
HAL_Delay(3000);
HAL_GPIO_WritePin(LD2_GPIO_Port, LD2_Pin, GPIO_PIN_RESET);
`;

const cm7_share = `
if(HAL_HSEM_Take(HSEM_ID_0, 7) == HAL_OK){
    HAL_UART_Transmit(&huart3, tx_str, 17, 0xFFFF);
    HAL_Delay(500);
    HAL_HSEM_Release(HSEM_ID_0, 7);
    HAL_Delay(500);
}
`;

const cm4_share = `
if(HAL_HSEM_Take(HSEM_ID_0, 4) == HAL_OK){
    HAL_UART_Transmit(&huart3, tx_str, 17, 0xFFFF);
    HAL_Delay(500);
    HAL_HSEM_Release(HSEM_ID_0, 4);
    HAL_Delay(500);
}
`;

function MCU_HAL30(){
    return(
        <div className="em__post">
            <div className="em__post-title">
                <h1>Hardware semaphores and core synchronisation</h1>
            </div>

            <div className="em__post-section">
                <h3>Aim of this tutorial:</h3>
                <p>
                    Hardware semaphores are the hard implementations of semaphores that we encountered in the RtOS tutorials.
                    There, we used the semaphores to synchronise tasks and shared resources.
                    In this tutorial, we will use the hardware semaphores to synchronise the cores and to safely use shared peripherals/memory.

                </p>
            </div>

            <div className="em__post-section">
                <h3>About hardware semaphores (HSEM):</h3>
                <p>
                    According to the reference manual, the HSEM block provides 32 x 32-bit register-based semaphores.
                    It offers a non-blocking mechanism for lock semaphores in an <b>atomic</b> way.
                    <br/>
                    A semaphore can be locked in a 2-step process by writing <i>COREID</i> and
                    <i>PROCID</i> to the semaphore, followed by a read operation.
                    We can also use a 1-step lock by reading the <i>COREID</i> from the semaphore.
                    Each semaphore can generate an interrupt when it is unlocked.
                    A semaphore is only unlocked if the <i>COREID</i> and <i>PROCID</i> match.
                    <br/>
                    The HSEM includes 32 semaphores, 8-bit <i>PROCID</i>, 4-bit <i>COREID</i>, one interrupt per core,
                    and a lock indicator.
                </p>

            </div>

            <div className="em__post-section">
                <h3>Project setup:</h3>
                <p>
                    Start STM32CubeIDE and create a new project using the ioc file from the previous project.
                    We have two interrupt categories in the system core tab: NVIC1 for the M7 core and NVIC2 for the core M4.
                    Inside the NVICx, we have HSEMx global interrupt. Enable them.
                </p>

                <div>
                    <Popup trigger={<img className="img_hsem_m4_hal30 clickable_img" src={hsem_m4} alt="hsem_m4_hal30"/>} modal nested>
                        {
                            close =>(
                                <img className="em__img_full" src={hsem_m4} alt="hsem_m4_hal30"/>
                            )
                        }
                    </Popup>
                    <Popup trigger={<img className="img_hsem_m7_hal30 clickable_img" src={hsem_m7} alt="hsem_m7_hal30"/>} modal nested>
                        {
                            close =>(
                                <img className="em__img_full" src={hsem_m7} alt="hsem_m7_hal30"/>
                            )
                        }
                    </Popup>
                </div>
            </div>

            <div className="em__post-section">
                <p>
                    Generate the project and build it.
                    This time, we will not debug the project; instead, we will upload and run the firmware.
                    Create a new run configuration by selecting the main.c of the CM7 sub-folder.
                    Halt all cores when connecting under reset.
                    At startup, the program should build, download and load symbols of the CM7 first, then CM4 <b>in this order</b>.
                </p>
                <h3>Firmware (1) - core notification:</h3>
                <p>
                    We will use the CM7 to notify CM4 of an event.
                    Since we've enabled the semaphore interrupts in both cores, we could do the same thing from the CM4 side.

                    <br/>
                    In the CM7 main program, we already have defined a hardware semaphore <i>HSEM_ID_0</i>.
                    In the super loop, paste the following code segment:
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {cm7_signal}
                </SyntaxHighlighter>
                <p>
                    Here, we have the single-step semaphore taking.
                    Then we toggle the LED for 3 seconds; then the CM7 releases the semaphore with the process ID of 0.
                    On the CM4 side, we create a volatile semaphore flag that can be used from an interrupt and the super loop.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {cm4_signal1}
                </SyntaxHighlighter>
                <p>
                    If the CM7 core releases the semaphore, and the CM4 core has an active notification, an interrupt is generated.
                    Of course, we also have to activate this notification outside the interrupt for it to catch the notification.
                    So, paste the activation after the initialisation of the GPIO.
                    <br/>
                    Finally, add the following lines to the super loop.
                    If we get a notification from CM7, the CM4 toggles the LED.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {cm4_signal2}
                </SyntaxHighlighter>
                <p>
                    Test the program.
                    CM7 should blink LD1 3 times, after which the state of LD2 is changed by CM4.
                </p>

            </div>

            <div className="em__post-section">
                <h3>Firmware (2) - core synchronisation:</h3>
                <p>
                    We saw that an interrupt can be triggered by the release of a hardware semaphore.
                    We could also use a loop to stall the program's execution until the semaphore is released, creating a blocking-like feature.
                    Consider CM7  the main core, which has to do some configurations, and CM4 should only execute its task after CM7 is finished.
                </p>

                <p>
                    In the CM7 source file, before the super loop, add the following code segment:
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {cm7_sync}
                </SyntaxHighlighter>

                <p>
                    Here, the task is symbolised by the LED blinking and 3s wait time.
                    After this, CM7 takes and releases the semaphore - signalling CM4 that the task is executed.

                    On the CM4 side, we have the following segment before the super loop:
                </p>

                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {cm4_sync}
                </SyntaxHighlighter>

                <p>
                    There is a while loop that checks whether the hardware semaphore is taken.
                    As soon as CM7 releases the semaphore, CM4 exits the loop and flashes the LED with 3s execution time.
                </p>

                <p>
                    If everything is done right, LD1 should flash first, followed by the flash of LD2.
                </p>

            </div>

            <div className="em__post-section">
                <h3>Firmware (3) - sharing the UART:</h3>
                <p>
                    Finally, presume that we have a single communication channel, and we want to send messages from both CM7 and CM4
                    using that channel.
                    The elegant way would be to dedicate a core for the message handling, and the other core sends the data to the communication core.
                    We don't know how to share data, only using semaphores, so we'll use these hardware semaphores to guard the shared UART.
                </p>
                <p>
                    Add the following code segment to the CM7 super loop:
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {cm7_share}
                </SyntaxHighlighter>
                <p>
                    The core checks first to see if the HSEM 0 is taken.
                    If it is, the program ignores the rest of the code; otherwise, CM7 transmits a string using UART and then releases the semaphore.
                    I added 2 x 500ms delays just to give enough time to the other core to do its job.
                    I used the following string here <i>const uint8_t tx_str[] = "Hello from CM7!\r\n"</i>.
                </p>
                <p>
                    On the CM4 side, first, we need to import the UART headers using <i>#include "usart.h"</i>.
                    Then, we should ensure that the CM7 initialises the UART before the CM4 wants to use it.
                    This can be achieved by placing the previous sync method before the <i>MX_USART3_UART_Init()</i> function call.
                    Alternatively, one could place this function into the CM4, too.
                    <br/>
                    The CM4 super loop looks like:
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {cm4_share}
                </SyntaxHighlighter>
                <p>
                    Here, we have the same structure; I've changed the process ID to 4 and added a different string
                    <i>const uint8_t tx_str[] = "Hello from CM4!\r\n"</i>.
                </p>
                <p>
                    Upload the program and reset the board.
                    You should see the messages sent from the MCU to the PC, starting with CM7, followed by CM4.
                </p>

            </div>

            <div className="em__post-navigation">

                <NavLink to="./../stm-hal-29">
                    <Button btnID={"leftBTN"} buttonSize="btn--medium"> Previous Post</Button>
                </NavLink>

                <NavLink to="./../stm-hal-31">
                    <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
                </NavLink>
            </div>

        </div>
    );
}

export default MCU_HAL30;