import React from "react";
import "./fpga_10.css"
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";
import max1000 from "./max1000.jpg";
import qinst from "./qinst.jpg";
import qnewproj from "./q_newProj.jpg";
import quart1 from "./quart_1.jpg";
import quart2 from "./quart_2.jpg";
import quart3 from "./quart_3.jpg";
import quart4 from "./quart_4.jpg";
import quart5 from "./quart_5.jpg";
import quart6 from "./quart_6.jpg";
import quart7 from "./quart_7.jpg";
import quart8 from "./quart_8.jpg";
import tinyB from "./tinyB.png";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";

const blinky = `
module MAX1000_Blink(
    input i_clk,
    output o_led1,
    output logic o_led2,
    output logic [5:0] o_unused_leds);
    
    wire lockb;
    wire w_clk_50;
    
    mypll pll_inst(
        .inclk0(i_clk), 
        .c0(w_clk_50), 
        .locked(lockb));
    
    logic [25:0] blink_counter;
    wire [31:0] blink_pattern = 32'b101010001110111011100010101;
    
    always @(posedge w_clk_50) begin
        if(~lockb)
            blink_counter <= 0;
        else
            blink_counter <= blink_counter + 1;
    end
    
    logic [25:0] clock_counter;
    
    always @(posedge w_clk_50) begin
        if(~lockb)
            clock_counter <= 0;
        else begin
            clock_counter <= clock_counter + 1;
            if(clock_counter == 26'd25000000) begin
                clock_counter <= 0;
                o_led2 <= ~o_led2;
            end
        end
    end
    
    assign o_led1 = blink_pattern[blink_counter[25:21]];
    assign o_unused_leds = 6'd0;

endmodule
`;

const tinyBlink = `
module top (
   input  CLK,   
   output LED,   
   output USBPU  
   );

   wire LOCKB;
   wire RESETB;

    mypll mypll_inst(.REFERENCECLK(CLK),
                     .PLLOUTCORE(clk_50mhz),
                     .PLLOUTGLOBAL(clk_global),
                     .RESET(RESETB),
                     .LOCK(LOCKB));

    assign RESETB = 1;

     reg [25:0] blink_counter;
     wire [31:0] blink_pattern = 32'b101010001110111011100010101;
     always @(posedge clk_50mhz) begin
        blink_counter <= blink_counter + 1;
    end
    assign LED = blink_pattern[blink_counter[25:21]];
   assign USBPU = 1'b0;   // disable USB
endmodule  
`;

const test = `
\`timescale 1ns / 10ps

module top_tb;
  reg clk;
  wire led;

  integer i;

  top top_instance(.CLK(clk),   .LED(led));

  initial begin
    $dumpfile("top_tb.vcd");
    $dumpvars;
    $display("%d %m: Starting testbench simulation...", $stime);
    clk = 0;
    for(i=0; i < 100; i = i + 1)
      #1 clk = ~clk;
    $finish;
  end

endmodule    
`;

function FPGA_10(){
    return(
        <div className="em__post">
            <div className="em__post-title">
                <h1>Two more affordable FPGA boards</h1>
            </div>
            <div className="em__post-section">
                <h3>Aim of this post:</h3>
                <p>
                    Recently I've got my hands on the TinyFPGA-BX and the MAX1000 - IoT Maker boards.
                    So far I've only tested them superficially, so let's try them with some new projects.
                </p>
            </div>
            <div className="em__post-section">
                <h3>Prerequisites:</h3>
                <p>
                    The two boards mentioned above, Visual Studio Code and a Terminal/CMD promt.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Why new boards?</h3>
                <p>
                    In the first post of this FPGA series I showed the TinyFPGA-AX board which hosted a
                    Lattice semiconductor MachXO2-1200, and it served us well until now (and I'm still using it when time arises).
                    If we would want to test out some 32b soft processors, or ethernet stack or camera IPs or some combination of these,
                    the chances would be high that we would run out of FPGA space.
                </p>
                <p>The TinyFPGA-BX board hosts an ICE40LP8K FPGA from Lattice semiconductor, while the MAX1000 -
                    IoT Maker Board hosts an Intel MAX 10 FPGA. The following table summarises the capabilities of the FPGAs:</p>
                <div className="em__post-section_tableContainer">
                    <table className="em__post-section_table">
                        <tr>
                            <th>Property</th>
                            <th>TinyFPGA-AX</th>
                            <th>TinyFPGA-BX</th>
                            <th>MAX1000 </th>
                        </tr>
                        <tr>
                            <th>FPGA</th>
                            <td>MachXO2-1200</td>
                            <td>ICE40LP8K</td>
                            <td>MAX 10</td>
                        </tr>
                        <tr>
                            <th>Logic cells:</th>
                            <td>1200</td>
                            <td>7680</td>
                            <td>8000</td>
                        </tr>
                        <tr>
                            <th>Embedded RAM:</th>
                            <td>74kb</td>
                            <td>128kb</td>
                            <td>378kb</td>
                        </tr>
                        <tr>
                            <th>User Flash:</th>
                            <td>64kb</td>
                            <td>6000kb</td>
                            <td>1376kb</td>
                        </tr>
                        <tr>
                            <th>PLL:</th>
                            <td>1</td>
                            <td>1</td>
                            <td>1</td>
                        </tr>
                        <tr>
                            <th>Oscillator:</th>
                            <td>Internal</td>
                            <td>16MHz MEMS</td>
                            <td>12MHz MEMS</td>
                        </tr>
                        <tr>
                            <th>Internal ADC:</th>
                            <td>No</td>
                            <td>No</td>
                            <td>1Msps 12b SAR</td>
                        </tr>
                        <tr>
                            <th>Programmer:</th>
                            <td>JTAG</td>
                            <td>USB Bootloader</td>
                            <td>JTAG</td>
                        </tr>
                        <tr>
                            <th>DSP:</th>
                            <td>No</td>
                            <td>8 MUL</td>
                            <td>24 MUL</td>
                        </tr>
                        <tr>
                            <th>Price*:</th>
                            <td><a href="https://www.sparkfun.com/products/14829">$20</a></td>
                            <td><a href="https://www.sparkfun.com/products/14829">$39</a></td>
                            <td><a href="https://shop.trenz-electronic.de/en/TEI0001-03-08-C8-MAX1000-IoT-Maker-Board-8KLE-8-MByte-RAM">$35</a></td>
                        </tr>
                    </table>
                </div>
                <p>
                    * - Price at the time when this post was written.
                </p>
                <p>Every one of these boards is breadboard compatible, so the hardware testing is easy (at low speeds and low currents :) ).</p>
            </div>

            <div className="em__post-section">
                <h3>Programming environment</h3>
                <p>
                    The official program for the TinyFPGA-BX is iCEcube2, which is a pain in the bass if someone would ask me.
                    There are more pleasant alternatives like iceStudio, which is a graphical interface, and apio which is a command line interface for verilog.
                    Both of these solutions are open source.
                </p>
                <p>
                    The official program for the MAX1000 board is Quartus, which is such closed that I could not find other alternatives.
                    Still, I'll only use it for running some instructions.
                </p>
                <p>
                    My setup is: Visual Studio Code with Sigasi end Teros HDL extension for writing the (System)Verilog descriptions,
                    GTKWave for simulations, apio to synthesize and download bitstream for the BX board, Quartus for the second board.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Getting started with Quartus and the MAX1000 board</h3>
                <img className="img_max1000" src={max1000} alt="max1000"/>
                <p>
                    This little board is packed with features. In the previous table I was only showing the properties of the FPGA,
                    but there is much more to show...
                    We have a full-fledged USB blaster (FT2232) with UART/JTAG for programming and debug boundary scan.
                    The FPGA is paired with a 64Mb SDRAM for cases when FPGA block RAM and distributed RAM is too precious to use,
                    and a 64Mb SPI Flash to store those big configuration files, or to be used as storage for a soft processor.
                    There are 8 red LEDs, 2 push buttons and a 3 axis accelerometer on board.
                </p>
                <p>
                    Speaking of accelerometer, I would've loved it to be an accelerometer/gyroscope combo, but I've got this board for free, so I can't really complain.
                </p>
                <p>We have some nice breadboard friendly Arduino MKR headers on the side,
                    and a PMOD header at the front for all the Digilent and compatible board fan boys.</p>
                <p>
                    In addition to the user configurable LEDs, we have two additional status indicator (there can't be too many LEDs on a board).
                    A green one for power indication, and a red one to show when the data was loaded without error (CONF_DONE).
                </p>
                <p>
                    Oh, did I mention that this FPGA has a 12-bit SAR ADC with 1Msps sampling speed?
                </p>

                <div>

                    <h4>
                        Installing the software
                    </h4>
                    <div>
                        <img className="img_qinst" src={qinst} alt="qinst"/>
                        <p>
                            First, we need the <a href="https://shop.trenz-electronic.de/en/Download/?path=Trenz_Electronic/Software/Drivers/Arrow_USB_Programmer">Arrow USB Programmer2</a> so that Quartus can recognise the FTDI and to program our FPGA.
                            After downloading the appropriate version of the driver and installing it, two USB Serial Converters should appear in the device manager.
                        </p>
                        <p>
                            Next, we need to download <a href="https://www.intel.com/content/www/us/en/collections/products/fpga/software/downloads.html?edition=lite&s=Newest">Quartus Prime Lite</a> (or Pro, if that is your thing).
                            In the installation window, the program will automatically have the MAX10 support and that is enough for us (we won't use Cyclone FPGAs).
                            We will also have a simulator (ModelSim), which is a fine simulator, but I'm staying with GTKWave for now.
                        </p>
                    </div>
                </div>

                <div>
                    <h4>
                        Trying out the board with a Morse-code blinking LED.
                    </h4>
                    <p>
                        <b>1.1</b> Create a new project using the New Project Wizard.
                    </p>
                    <Popup trigger={<img className="img_qnewproj clickable_img" src={qnewproj} alt="qnewproj" />} modal nested>
                        {close => (
                            <img className="em__img_full" src={qnewproj} alt="qnewproj" />
                        )}
                    </Popup>
                    <p>
                        <b>1.2</b> Set the project location, name of the project and the name of the top module.
                    </p>
                    <Popup trigger={<img className="img_quart1 clickable_img" src={quart1} alt="quart1"/>} modal nested>
                        {close => (
                            <img className="em__img_full" src={quart1} alt="quart1" />
                        )}
                    </Popup>

                    <p>
                        <b>1.3</b> Select the FPGA with the help of the Name filter: <i>10M08SAU169C8G</i>.
                    </p>
                    <Popup trigger={<img className="img_quart2 clickable_img" src={quart2} alt="quart2"/>} modal nested>
                        {close => (
                            <img className="em__img_full" src={quart2} alt="quart2" />
                        )}
                    </Popup>

                    <p>
                        <b>2.1</b> Now that we have a project, let's add a PLL module to convert the 12MHz clock signal to 50MHz.
                        This module can be dound in the IP catalog.
                        Set the input frequency to 12 MHz, disable the async reset, add a lock output
                        (to signal when the PLL generates valid clock signal), and the the output frequency to 50MHz.
                    </p>
                    <Popup trigger={<img className="img_quart3 clickable_img" src={quart3} alt="quart3"/>} modal nested>
                        {close => (
                            <img className="em__img_full" src={quart3} alt="quart3" />
                        )}
                    </Popup>

                    <p>
                        <b>2.2</b> We need to create a pll instance and to write our blinking logic.
                        So, create a new system verilog file.
                        Add an input for the clock, and two outputs, one for the Morse-code signal,
                        another to see if the clock is working.
                    </p>

                    <SyntaxHighlighter language="verilog" style={AtomOneDark}>
                        {blinky}
                    </SyntaxHighlighter>
                    <p>
                        Next we create the PLL instance, we can define the Morse-code patter of "SOS" and a counter to reduce the clock speed.
                        We can create another counter to generate a blinking signal with 1Hz frequency.
                    </p>
                    <p> Finally, I've explicitly set every unused pin connected to an LED to logic 0. This is not required, but otherwise the LEDs will have a faint glow.</p>
                    <p>
                        Next we can call the <i>Analysis & Synthesis</i> task under the <i>Compile Design</i> functionality.
                        Here we can check the generated RTL schematic.
                    </p>
                    <Popup trigger={<img className="img_quart5 clickable_img" src={quart5} alt="quart5"/>} modal nested>
                        {close => (
                            <img className="em__img_full" src={quart5} alt="quart5" />
                        )}
                    </Popup>

                    <p>
                        We can tell the program where to fan out the I/Os using the <i>Pin Planner</i> under the <i>Assignments</i> menu.
                        The clock and LED pins are shown in the <a href="https://www.trenz-electronic.de/trenzdownloads/Trenz_Electronic/Modules_and_Module_Carriers/2.5x6.15/TEI0001/User_Guide/MAX1000%20User%20Guide.pdf">board user guide</a>.
                        And we should set the I/O standard to 3.3V LVCMOS since that is the operation voltage.
                    </p>
                    <Popup trigger={<img className="img_quart4 clickable_img" src={quart4} alt="quart4"/>} modal nested>
                        {close => (
                            <img className="em__img_full" src={quart4} alt="quart4" />
                        )}
                    </Popup>

                    <p> Now we can fully compile our design.</p>
                    <p>
                        <b>3.1</b> The final step is the programming step.
                        Double click on the <i>Program Device</i> option in the <i>Tasks</i> tab.
                        This opens the Programmer window.
                    </p>
                    <Popup trigger={<img className="img_quart7 clickable_img" src={quart7} alt="quart7"/>} modal nested>
                        {close => (
                            <img className="em__img_full" src={quart7} alt="quart7" />
                        )}
                    </Popup>

                    <p>
                        <b>3.2</b>Click on <i>Hardware Setup</i> and at the <i>Currently selected hardware</i> choose <b>Arrow-USB-Blaster</b>.
                    </p>
                    <Popup trigger={<img className="img_quart6 clickable_img" src={quart6} alt="quart6"/>} modal nested>
                        {close => (
                            <img className="em__img_full" src={quart6} alt="quart6" />
                        )}
                    </Popup>

                    <p>
                        <b>3.3</b>Click <i>Auto Detect</i> and <i>Start</i>, this operation automatically chooses the .sof
                        file present in the current project and programs the CRAM of the FPGA.
                        The CONF_DONE LED toggles and you should see the 1Hz signal and the Morse-Code on the LEDs.
                        Now, if one would power cycle the board, this configuration would be gone.
                        In order to retain the configuration, one must program the .pof file. The programmer writes this data to the CFM,
                        from where the data is automatically loaded into the CRAM at power-up.
                    </p>
                    <Popup trigger={<img className="img_quart8" src={quart8} alt="quart8"/>} modal nested>
                        {close => (
                            <img className="em__img_full" src={quart8} alt="quart8" />
                        )}
                    </Popup>

                </div>

            </div>
            <div className="em__spacer"/>

            <div className="em__post-section">
                <h3>Getting started with APIO and the tinyFPGA-Bx</h3>
                <img className="img_tinyB" src={tinyB} alt="tinyB"/>

                <p>
                    APIO is a command line interface, so we will need a Terminal or a CMD interface.
                    Python is a requirement, so make sure that you have that installed - I'm using version 3.10, and a virtual environment.
                </p>
                <ol>
                    <li>Install APIO with the command: <i>pip install apio</i>.</li>
                    <li>Install the required packages for FPGA development: <i>apio install oss-cad-suite iverilog drivers gtkwave</i>.</li>
                    <li>This board uses a serial bootloader, so we need to enable that: <i>apio drivers --serial-enable</i>.</li>
                    <li>We need the programmer driver: <i>pip install -U apio[tinyprog]</i>.</li>
                    <li>Connect the FPGA board, and update the bootloader if required: <i>tinyprog --update-bootloader</i>.</li>
                </ol>

                <p>
                    Now we are ready to create the same blinking project.
                </p>
                <p>
                    Create a new directory for the project, and initialize it with the command : <i> apio init --board TinyFPGA-BX</i>.
                    In here create a new file named <i> top.v</i> - this time I'm using Verilog.
                </p>
                <SyntaxHighlighter language="verilog" style={AtomOneDark}>
                    {tinyBlink}
                </SyntaxHighlighter>
                <p>
                    This description has the same logic as before with two differences: I did not fan out the slow clock pulse, and we have a pull-up on the USB leg.
                    The last modification is done so that the bootloader is stopped.
                </p>
                <p> We can verify our code with the <i>apio verify</i> command</p>.
                <p> A .pcf file is needed to configure the pins. The clock is on <b>B2</b>, the USB pull-up is on <b>B4</b>,
                    and the on-board LED is on <b>B3</b>, but any other pins can be found <a href="https://github.com/tinyfpga/TinyFPGA-BX/blob/master/apio_template/pins.pcf">here</a>.</p>
                <p> We can build and program the FPGA using the commands <i>apio build</i> and <i>apio upload</i></p>.
                <p>
                    The simulation is done using <i>apio sim</i>, and the simation behavioral model must be named the same as the simulated model with <b>_tb</b> attached to the name.
                    In our case this would be <b>top_tb.v</b>.
                </p>
                <SyntaxHighlighter language="verilog" style={AtomOneDark}>
                    {test}
                </SyntaxHighlighter>
                <p>
                    In this test bench, there are some new things. for instance <i className="emph">$dumpfile("top_tb.vcd")</i>
                    specifies which file to use for data storage, where should gtkwave look for the data.
                    <i className="emph">$dumpvars</i> flushes all the monitored variables, signals.
                    <i className="emph">$finish</i> is the end of the simulation and closes the file.
                </p>
            </div>

            <div className="em__post-navigation">

                <NavLink to="./../fpga-tut-9">
                    <Button btnID={"leftBTN"} buttonSize="btn--medium"> Previous Post</Button>
                </NavLink>

                <NavLink to="./..">
                    <Button btnID={"rightBTN"} buttonSize="btn--medium"> Projects</Button>
                </NavLink>
            </div>

        </div>
    );
}

export default FPGA_10;