import React from "react";
import "./mcu_hal29.css";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";

import stm_arch from "./stm_arch.png";
import stm_corecon from "./stm_corecon.png";
import stm_memory from "./stm_memory.png";
import setup_pins from "./setup_pins.jpg";
import osc_src from "./osc_src.jpg";
import clock_setup from "./clock_setup.jpg";
import debug from "./debug.jpg";
import gpio_config from "./gpio_config.jpg";
import usart_setup from "./usart_setup.jpg";
import cm4_debug from "./cm4_debug.jpg";
import cm7_debug from "./cm7_debug.jpg";
import debug_all from "./debug_all.jpg";
import cube_programmer from "./cube_programmer.jpg"

const cm7_main =`
#ifndef HSEM_ID_0
#define HSEM_ID_0 (0U) /* HW semaphore 0*/
#endif
...
int main(void){
    int32_t timeout;
    timeout = 0xFFFF;
    while((__HAL_RCC_GET_FLAG(RCC_FLAG_D2CKRDY) != RESET) && (timeout-- > 0));
    if ( timeout < 0 ){
        Error_Handler();
    }
    ...
    HAL_Init();
    SystemClock_Config();
    __HAL_RCC_HSEM_CLK_ENABLE();
    HAL_HSEM_FastTake(HSEM_ID_0);
    HAL_HSEM_Release(HSEM_ID_0,0);
    timeout = 0xFFFF;
    while((__HAL_RCC_GET_FLAG(RCC_FLAG_D2CKRDY) == RESET) && (timeout-- > 0));
    if ( timeout < 0 ){
        Error_Handler();
    }
    MX_GPIO_Init();
    MX_USART3_UART_Init();
    while (1){}
}
`;

const cm4_main = `
#ifndef HSEM_ID_0
#define HSEM_ID_0 (0U) /* HW semaphore 0*/
#endif
...
int main(void){
    __HAL_RCC_HSEM_CLK_ENABLE();
    HAL_HSEM_ActivateNotification(__HAL_HSEM_SEMID_TO_MASK(HSEM_ID_0));
    HAL_PWREx_ClearPendingEvent();
    HAL_PWREx_EnterSTOPMode(PWR_MAINREGULATOR_ON, \\
        PWR_STOPENTRY_WFE, PWR_D2_DOMAIN);
    __HAL_HSEM_CLEAR_FLAG(__HAL_HSEM_SEMID_TO_MASK(HSEM_ID_0));
    HAL_Init();
    MX_GPIO_Init();
    while (1){}
}
`;

function MCU_HAL29(){
    return(
        <div className="em__post">
            <div className="em__post-title">
                <h1>Dual-Core MCU debugging</h1>
            </div>

            <div className="em__post-section">
                <h3>Aim of this tutorial:</h3>
                <p>
                    In this tutorial, we will look at the STM32H7xx dual-core MCUs.
                    We will create a simple project for each core, flash them to the MCUs and debug them in a single environment.
                </p>
            </div>

            <div className="em__post-section">
                <h3>A few words about the dual-core MCUs:</h3>
                <p>
                    Some time ago, I designed a drone controller board that had two separate MCUs on it.
                    One gathered the data from the inertial measurement units
                    and fused them to generate precise attitude estimations (STM32F4); another one implemented the motor control
                    radio communication with an NRF24 SoC and performed the reference tracking.
                    <br/>
                    This board was a pain to assemble (primarily because of BGA RF parts) but also a pain to debug and bring up.
                    Could I have used a single MCU for every function? Yes, a single MCU would suffice, but why go on the easier route?
                    Back then, I wished that there was an IC that housed two MCUs (which are not meant to boot Linux).
                    A few years later, STMicro released the STM32H7xx dual-core series.
                </p>

                <p>
                    Naturally, I grabbed a nice Nucleo-H745ZI board to test out the capabilities.
                    The board itself hosts the MCU and the programmer. It has USB and Ethernet connectivity, so I can test several features.
                </p>
                <p>
                    The development board uses an STM32H745 IC with an ARM Cortex-M4 and an ARM Cortex-M7 core inside.
                    The smaller core can be run at 240MHz maximum and has a single-precision floating point unit,
                    while the larger core can run up to 480MHz (the maximum frequencies have some caveats on this development platform),
                    and it has a double-precision floating point unit incorporated -- this is ideal for some nice, computation-heavy DSP task.
                </p>

                <Popup trigger={<img className="img_stm_arch_hal29 clickable_img" src={stm_arch} alt="stm_arch_hal29"/>} modal nested>
                    {
                        close =>(
                            <img className="em__img_full img_bg_hal29" src={stm_arch} alt="stm_arch_hal29"/>
                        )
                    }
                </Popup>

                <p>
                    The image above shows the complete dual-core architecture.
                    The M4 core is connected with a 32-bit AHB bus to 2 x 128 KB SRAM and an additional 32 KB SRAM.
                    The M7 core has instruction and data caches, and it's connected with a 64-bit AXI bus to 2 x 1 MB of FLASH,
                    512 KB of AXI SRAM.
                    <br/>
                    We can see that there is a bidirectional communication option with the M4 core through the AHB bus.
                    The AXI bus can also write to the AHB bus, and an instruction accelerator (ART) is implemented between the AXI and AHB buses.
                    <br/>
                    Check out all the rich peripherals.
                    Thanks to the previously mentioned interconnection, every core can access every peripheral,
                    but some are optimized for specific core usage (e.g. SDMMC2 is directly connected to the 32-bit AHB bus,
                    so the M4 core can access it directly, while SDMMC1 is connected to the AXI bus, so it's closer to the M7 core).
                    The next figure will show a more detailed connection between cores and peripherals.
                </p>

                <Popup trigger={<img className="img_stm_corecon_hal29 clickable_img" src={stm_corecon} alt="stm_corecon_hal29"/>} modal nested>
                    {
                        close =>(
                            <img className="em__img_full img_bg_hal29" src={stm_corecon} alt="stm_corecon_hal29"/>
                        )
                    }
                </Popup>

                <p>
                    I've also included a figure with memory caching.
                    This part is only relevant for the M7 core.
                    The instruction and data values are stored in a tightly coupled memory region and then transferred to the core.
                    This improves performance but also creates issues when we want to use the DMA feature - more on this later at the memory protection part.
                </p>

                <Popup trigger={<img className="img_stm_memory_hal29 clickable_img" src={stm_memory} alt="stm_memory_hal29"/>} modal nested>
                    {
                        close =>(
                            <img className="em__img_full img_bg_hal29" src={stm_memory} alt="stm_memory_hal29"/>
                        )
                    }
                </Popup>

                <p>
                    This IC has a single debug port (SWD or JTAG), but ideally, we would like to debug both cores.
                    We should also check the boot sequence, which core sets up which peripheral, and how to synchronize the cores.
                    In most cases, the M7 core boots up first and sets up the supply scheme and clock configuration, and then the M4 core can run properly.
                </p>

            </div>

            <div className="em__post-section">
                <h3>Initializing our first dual-core project:</h3>
                <p>
                    Start STM32CubeIDE (or VSC, CLion, etc.) and create a new STM32 project.
                    Go to Board Selector, choose <i>NUCLEO-H745ZI-Q</i>, and opt-out from initializing peripherals with default values.
                </p>
                <p>
                    First and foremost, we need to check the supply operation and the clock sourcing from the user manual of the Nucleo board.
                    In the UM2408, section 6.9.1, we can see that by default, the MCO from the on-board ST-Link is used, which is 8MHz.
                    Section 6.4.8 shows that the board is configured to use the internal SMPS unit by default.
                </p>
                <Popup trigger={<img className="img_setup_pins_hal29 clickable_img" src={setup_pins} alt="setup_pins_hal29"/>} modal nested>
                    {
                        close =>(
                            <img className="em__img_full" src={setup_pins} alt="setup_pins_hal29"/>
                        )
                    }
                </Popup>
                <p>
                    We can see a nice, big IC with lots of pre-selected pins.
                    We can also see different configurations for the M4 and M7 cores.
                    First, we bypass the clock source in the HSE to use the provided 8MHz clock.
                    It's important that in the <i>Power Parameters</i>, the <i>Supply Source</i> is set to <b>Direct SMPS supply</b> -
                    otherwise, the IC will freeze up (don't ask me how I know).
                    If that happens, we need to use a programmer app, connect to the IC using hardware reset and perform a full chip erase.
                </p>
                <Popup trigger={<img className="img_osc_src_hal29 clickable_img" src={osc_src} alt="osc_src_hal29"/>} modal nested>
                    {
                        close =>(
                            <img className="em__img_full" src={osc_src} alt="osc_src_hal29"/>
                        )
                    }
                </Popup>
                <p>
                    You can also see an option for a power regulator voltage scale.
                    This option sets the core voltage so that the required performance is achieved.
                    Currently, it's at a voltage scale of 3, with a system clock of 64MHz.
                    Let's change the clock source to HSE with PLL to 400 MHz for M7 and 200 MHz for M4.
                </p>
                <Popup trigger={<img className="img_clock_setup_hal29 clickable_img" src={clock_setup} alt="clock_setup_hal29"/>} modal nested>
                    {
                        close =>(
                            <img className="em__img_full" src={clock_setup} alt="clock_setup_hal29"/>
                        )
                    }
                </Popup>
                <p>
                    If you return to the pinout and configuration, you should see a voltage scale of 1.
                    This is a higher-performance mode.
                    If we wanted full performance (480MHz/240MHz), we must change this setting to voltage scale 0.
                    But if we do that, the supply source gives an error since the LDO is used for high-performance mode, which is not configured correctly on the board by default.
                    So, slower speed it is.
                </p>
                <p>
                    Next, we enable the debug ports.
                    You can see that both cores utilize the debugger.
                </p>
                <Popup trigger={<img className="img_debug_hal29 clickable_img" src={debug} alt="debug_hal29"/>} modal nested>
                    {
                        close =>(
                            <img className="em__img_full" src={debug} alt="debug_hal29"/>
                        )
                    }
                </Popup>
                <p>
                    Each core should perform a simple task.
                    An LED blinking is ideal for this purpose.
                    If you go to <i>System Core/GPIO</i>, you will find some defined output pins.
                    There is a new column in the setup table, <b>pin context assignment</b>.
                    A <b>free</b> context means that it's not assigned to either core.
                    In this case, one should ensure the peripheral is not used and take it.
                    When the core is done using the peripheral, the other core can use it, too.
                    <br/>
                    Alternatively, we can explicitly set the context to M7 core (LED1) or M4 core (LED2).
                    This way, only the specified core can use that peripheral.
                </p>
                <Popup trigger={<img className="img_gpio_config_hal29 clickable_img" src={gpio_config} alt="gpio_config_hal29"/>} modal nested>
                    {
                        close =>(
                            <img className="em__img_full" src={gpio_config} alt="gpio_config_hal29"/>
                        )
                    }
                </Popup>
                <p>
                    Finally, let's configure the UART.
                    Selecting both Cortex-M7 and Cortex-M4 as runtime contexts means both cores can use this peripheral.
                    Let's make the M7 initialize it. We can access the UART by default using simple HAL instructions.
                </p>
                <Popup trigger={<img className="img_usart_setup_hal29 clickable_img" src={usart_setup} alt="usart_setup_hal29"/>} modal nested>
                    {
                        close =>(
                            <img className="em__img_full" src={usart_setup} alt="usart_setup_hal29"/>
                        )
                    }
                </Popup>
                <p>
                    Save the configuration and generate the project.
                    You can see a <i>Common</i> folder in the project folder, which contains the dual-core boot C file.
                    The <i>Drivers</i> folder contains the HAL and CMSIS drivers that have to be accessed by both cores.
                    Finally, there are two sub-directories for each of the cores.
                    Each sub-directory contains the core-specific linker, driver and source files.
                </p>

            </div>

            <div className="em__post-section">
                <h3>Simple firmware:</h3>
                <p>
                    In the CM7 folder, open the <i>Core/Src/main.c</i> file.
                    This file is the main program of the M7 core.
                </p>
                <div className="container_hal29">
                    <div className="codeBlock_hal29">
                        <SyntaxHighlighter language="c" style={AtomOneDark} customStyle={{ margin: 0 }}>
                            {cm7_main}
                        </SyntaxHighlighter>
                    </div>
                    <div className="codeBlock_hal29">
                        <SyntaxHighlighter language="c" style={AtomOneDark} customStyle={{ margin: 0 }}>
                            {cm4_main}
                        </SyntaxHighlighter>
                    </div>
                </div>

                <p>
                    On the left side, we have the M7 program code.
                    The boot mode sequence begins with an internal clock.
                    The M7 core waits until the second dore (M4) boots and enters stop mode.
                    The HAL is initialized for any peripheral that can be accessed by the M7 core.
                    The clock is then initialized when the core switches over to the external 8MHz clock and enables the PLL.
                    The M7 core takes and releases a hardware semaphore to wake the M4 core (more about this in another tutorial).
                    If the M4 core runs, the GPIO and UART are set up, and we have the super loop.
                    <br/>
                    On the right side, we have the M4 program code.
                    The hardware semaphore notification is activated, any present events are cleared, and the M4 core
                    enters deep sleep mode.
                    When the clock initialization is done on the M7 side, the M4 core can wake up and clear the semaphore.
                    It then initializes the HAL and the GPIO that we configure.
                </p>

                <p>
                    Add an LED blinking sequence to both cores using the <i>HAL_GPIO_TogglePin()</i> and <i>HAL_Delay()</i> functions.
                    Use <i>LD1</i> in the case of the M7 core and <i>LD2</i> in the case of the M4 core.
                    Then add a breakpoint to each <i>HAL_Init</i> function.
                </p>

            </div>

            <div className="em__post-section">
                <h3>Debug configuration:</h3>
                <p>
                    The debug idea is simple: upload the firmware to the main booting core and the secondary core in a single debug sequence.
                    Then, we can watch the program separately for each core.

                </p>
                <p>
                    Right-click the CM7 project folder and choose <i>Debug As/Debug Configuration</i>.
                    Then right-click on the <i>STM32 C/C++ Application</i> and create a new configuration.
                    In the Debugger section, note the GDB port number, which, in my case, is 61234.
                    Change the reset behaviour to Connect under reset and <b>Halt all cores</b>
                    - The second important option is to upload the CM4 firmware.
                    Be sure to check the Shared ST-Link option too.
                    <br/>
                    In the Startup section, we have our CM7 project. It will be built and downloaded, and the symbols will be loaded.
                    We need to add the second core, but first, apply the changes and build the project.
                    Then, in the previous window, add the CM4 project, download and load the symbols.
                </p>
                <Popup trigger={<img className="img_cm7_debug_hal29 clickable_img" src={cm7_debug} alt="cm7_debug_hal29"/>} modal nested>
                    {
                        close =>(
                            <img className="em__img_full img_bg_hal29" src={cm7_debug} alt="cm7_debug_hal29"/>
                        )
                    }
                </Popup>
                <p>
                    Then, a new debug configuration for the CM4 will be added, too.
                    Ensure you increase the GDB Port number by at least 3 (I've used 61238).
                    Since the core is stopped by the M7, the reset behaviour is <i>None</i>.
                    Also, we already downloaded the firmware, so in the Startup menu, uncheck the Download option.
                </p>
                <Popup trigger={<img className="img_cm4_debug_hal29 clickable_img" src={cm4_debug} alt="cm4_debug_hal29"/>} modal nested>
                    {
                        close =>(
                            <img className="em__img_full img_bg_hal29" src={cm4_debug} alt="cm4_debug_hal29"/>
                        )
                    }
                </Popup>
                <p>
                    Start the debug session for the M7 Core.
                    If everything goes well, the program should break at the timeout.
                    Go to Debug Configurations and start the M4 debug too.
                    At this point, you should have two suspended threads:
                </p>
                <Popup trigger={<img className="img_debug_all_hal29 clickable_img" src={debug_all} alt="debug_all_hal29"/>} modal nested>
                    {
                        close =>(
                            <img className="em__img_full img_bg_hal29" src={debug_all} alt="debug_all_hal29"/>
                        )
                    }
                </Popup>
                <p>
                    Add breakpoints after the initialization section in both source files.
                    Select both threads and press continue. This is crucial since if we start one core sooner, it may time out, and the error handler may be entered.
                    Now, you can debug both cores separately.
                    You should see the green and orange LEDs toggling.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Boot options:</h3>
                <p>
                    You could use the basic project we've created so far if you need two cores operating simultaneously.
                    If you remove the semaphore locks, you could use the M7 core, but there are more elegant ways to achieve that.
                    One could disable the M4 core if it's not needed.
                    Consequently, one could disable the M7 core and make the peripheral setup only in the M4 core, but why purchase a dual-core IC?
                </p>
                <p>
                    Either way, these options are accessed in the Option Bytes.
                    If we use STM32CubeProgrammer under User Configuration, we have the following flags:
                </p>
                <Popup trigger={<img className="img_cube_programmer_hal29 clickable_img" src={cube_programmer} alt="cube_programmer_hal29"/>} modal nested>
                    {
                        close =>(
                            <img className="em__img_full img_bg_hal29" src={cube_programmer} alt="cube_programmer_hal29"/>
                        )
                    }
                </Popup>
                <p>
                    Here, you can set the reset behaviour of the cores, as well as if the booting is enabled or disabled.
                    Important note: in the debugging section, the M7 core appeared as [core:0], while the M4 core appeared as[core:3].
                    By default, the programmer connects to Access Port 0 (CM7).
                    If the M7 does not boot, one cannot connect on that port, so Access Port 3 (CM4) must be used.
                    I was under the impression that I'd bricked my board for a week until I read about this in the boot section of the user manual.
                </p>


            </div>

            <div className="em__post-navigation">

                <NavLink to="./../stm-hal-28">
                    <Button btnID={"leftBTN"} buttonSize="btn--medium"> Previous Post</Button>
                </NavLink>

                <NavLink to="./../stm-hal-30">
                    <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
                </NavLink>
            </div>

        </div>
    );
}

export default MCU_HAL29;