import React from "react";
import "./mcu_hal20.css";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";
import {MathJax, MathJaxContext} from "better-react-mathjax";

import comp_schem from "./comp_schem.jpg";
import opamp_schem from "./opamp_schem.jpg";
import anal_cond from "./analog_sig_cond.jpg";
import opamp_setup from "./opamp_setup.jpg";
import adc_pin from "./adc_pin.jpg";
import filter_freq from "./filter_freq.jpg";
import hardware from "./hardware.jpg";
import monitor from "./monitor.jpg";
import graph from "./graph.jpg";

function ShowTex({string}){
    const config = {
        loader: { load: ["[tex]/html"]},
        tex: {packages: {"[+]": ["html"]},
            inlineMath: [["$", "$"]],
            displayMath: [["$$", "$$"]]
        }
    };

    return(
        <MathJaxContext config={config} version={3}>
            <MathJax dynamic inline>
                {string}
            </MathJax>
        </MathJaxContext>
    );
}

const float_compile = `
add_compile_definitions(ARM_MATH_CM4;ARM_MATH_MATRIX_CHECK;ARM_MATH_ROUNDING)
add_compile_options(-mfloat-abi=hard -mfpu=fpv4-sp-d16)
add_link_options(-mfloat-abi=hard -mfpu=fpv4-sp-d16)
`;

const globals = `
volatile uint16_t adc_val;
volatile uint8_t adc_flag = 0;
float r_val = 0.0f;
uint16_t ir_debug, rd_debug;
`;

const adc_irq = `
void HAL_ADC_ConvCpltCallback(ADC_HandleTypeDef *hadc){
    adc_val = HAL_ADC_GetValue(hadc);
    adc_flag = 1;
}
`;

const locals = `
uint8_t fsm_state = 0;
uint8_t sample_counter = 0;
uint16_t ir_min, ir_max, rd_min, rd_max;
uint16_t ir_buffer[10] = {0};
uint16_t rd_buffer[10] = {0};
uint32_t accu;

uint16_t ir_filtered_buffer[20];
uint16_t rd_filtered_buffer[20];
uint8_t ir_index = 0, rd_index = 0;
`;

const setup = `
HAL_OPAMP_Init(&hopamp3);
HAL_OPAMP_Start(&hopamp3);
HAL_OPAMP_Init(&hopamp2);
HAL_OPAMP_Start(&hopamp2);

HAL_GPIO_WritePin(LD1_GPIO_Port, LD1_Pin, GPIO_PIN_RESET);
HAL_GPIO_WritePin(LD2_GPIO_Port, LD2_Pin, GPIO_PIN_SET);

HAL_ADCEx_Calibration_Start(&hadc2, ADC_SINGLE_ENDED);

HAL_ADC_Start_IT(&hadc2);
HAL_TIM_Base_Start(&htim6);
`;

const store_data = `
if(adc_flag){
  if(fsm_state == 0){
      ir_buffer[sample_counter++] = adc_val;
  }else{
      if(fsm_state == 1){
          rd_buffer[sample_counter++] = adc_val;
      }
  }
  adc_flag = 0;
}
`;

const ir_process = `
if(sample_counter == 10){
    //We can process the data, so don't get interrupt by the ADC
    HAL_TIM_Base_Stop(&htim6);
    sample_counter = 0;
    if(fsm_state == 0){
      // ir buffer is ready to be processed
      accu = 0;
      for(uint8_t i = 0; i < 10; ++i)
          accu += ir_buffer[i];
      ir_debug = (uint16_t)(accu / 10);
      ir_filtered_buffer[ir_index++] = ir_debug;
    
      // change light setup
      HAL_GPIO_WritePin(LD1_GPIO_Port, LD1_Pin, GPIO_PIN_SET);
      HAL_GPIO_WritePin(LD2_GPIO_Port, LD2_Pin, GPIO_PIN_RESET);
}
`;

const r_index = `
if(rd_index == 20 && ir_index == 20){
  HAL_TIM_Base_Stop(&htim6);
  rd_index = 0;
  ir_index = 0;
  ir_min = 4095;
  ir_max = 0;
  rd_min = 4095;
  rd_max = 0;
  for(uint8_t i = 0; i < 20; ++i){
      if(rd_max < rd_filtered_buffer[i])
          rd_max = rd_filtered_buffer[i];
      if(rd_min > rd_filtered_buffer[i])
          rd_min = rd_filtered_buffer[i];
      if(ir_max < ir_filtered_buffer[i])
          ir_max = ir_filtered_buffer[i];
      if(ir_min > ir_filtered_buffer[i])
          ir_min = ir_filtered_buffer[i];
  }
  r_val = (((float)(rd_max - rd_min)) / (float)(rd_max + rd_min)) / (((float)(ir_max - ir_min)) / (float)(ir_max + ir_min)) * 100.0f;
  HAL_TIM_Base_Start(&htim6);
}
`;

function MCU_HAL20(){
    return(
        <div className="em__post">
            <div className="em__post-title">
                <h1>Other Analog Features</h1>
            </div>

            <div className="em__post-section">
                <h3>Aim of this tutorial:</h3>
                <p>
                    In this tutorial we will check out the other analog features hidden in the STM32G microcontroller.
                    We will create a simple photodiode interface and use it for basic blood oxygen level measurements.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Operation amplifiers and comparators:</h3>
                <p>
                    So far, we have used ADCs and DACs to create an interface between the analog and digital world, which is good,
                    but there are cases when analog preprocessing or core/memory independent action is required.
                    Some cases are:
                    <ul>
                        <li>Buffering/amplifying weak input/output signals,</li>
                        <li>Filtering noise from the input signal, or removing carrier signal from output (see PWM DAC),</li>
                        <li>Measuring differential signals with single-ended converters,</li>
                        <li>Current to voltage or voltage to current conversion,</li>
                        <li>Fast threshold detection,</li>
                    </ul>
                    and many many more.
                </p>
                <p>
                    If we want closed-loop stable operation with high fidelity linear output we need to use an operation amplifier (OpAmp).
                    On the other hand, if we need open-loop stability (or positive feedback) with fast switching to detect changes in voltage levels,
                    we need to use a comparator.
                    If we use an external component, the bill of material and the number of potential noise source inreases.
                </p>

                <h3>Embedded comparators:</h3>
                <p>
                    The STM32G embeds up to seven (4 in the case of STM32G491) ultra-fast analog comparators.
                    Each comparator has configurable positive and negative inputs multiplexed to I/O pins, DAC channels and internal reference voltage.
                    The comparators have programmable hysteresis, and output redirection to I/Os or timer inputs.
                    The outputs feature blanking for immunity to switching noise.
                    Each comparator is capable to generate interrupts which can wake up the CPU from Sleep and Stop modes.
                </p>
                <img className="img_comp_schem_hal20" src={comp_schem} alt="comp_schem_hal20"/>

                <h3>Embedded operation amplifiers:</h3>
                <p>
                    The STM32G embeds up to six (4 in the case of STM32G491) operation amplifier.
                    These OpAmps have rail-to-rail input and output levels, low input bias current, low input offset voltage,
                    high frequency gain bandwidth, high speed mode to achieve better slew-rate.
                    The inputs and outputs can be connected to external I/Os.
                    They can be configured as followers, as inverting programmable gain amplifier (-1 to -63), or as non-inverting programmable
                    gain amplifiers (2 to 64).
                    The positive input can be connected to DAC, and the output can be connected to internal ADC.
                </p>

                <img className="img_opamp_schem_hal20" src={opamp_schem} alt="opamp_schem_hal20"/>
            </div>

            <div className="em__post-section">
                <h3>Interfacing the photodiode:</h3>
                <p>
                    A <a href="https://en.wikipedia.org/wiki/Photodiode">photodiode</a> is a light-sensitive semiconductor diode that produces current when it absorbs photons.
                    Contrary to ordinary diodes, photodiodes are reverse polarised.
                    Since it is a current source, we need to design a transimpedance amplifier to convert the current into voltage.
                    Also some analog signal conditioning before we can sample the data.
                </p>
                <Popup trigger={<img className="img_anal_cond_hal20 clickable_img" src={anal_cond} alt="anal_cond_hal20"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={anal_cond} alt="anal_cond_hal20" />
                    )}
                </Popup>
                <p>
                    The photodiode is capable to source a current in the range of micro Amps.
                    The output of the transimpedance amplifier can be given using the formula <ShowTex string="$u = Ri$"/>.
                    We need a feed-back resistance in the megaOhms range to generate unit voltage from micro Amps of current.
                    Furthermore, we implement a passive high-pass filter to remove the DC signal present due to the static light conditions.
                    This coudl be achieved using a simple RC filter to ground, but our next stage must be biased to half of the supply so we can
                    use two resistor of equal value to the supply and to ground (they are connected in parallel from the AC point of view).
                </p>
                <p>
                    Let's design a high-pass filter with a cut-off frequency of 0.45Hz around a 470nF capacitor:
                    <ShowTex string="$R = 1 / (2 \pi f_cC) = 1/1.33e-6 \approx 750k \Omega$" />, this conveniently result in two 1.5MR resistors.
                </p>
                <p>
                    Finally, the filtered signal must be amplified before the ADC can pick up any meaningful data.
                    We could use a non-inverting amplifier, but that would also amplify the DC bias.
                    Here I'm using an amplifier with gain <ShowTex string="G=11"/>. That means the DC bias would saturate the output.
                    If we include a nice and beefy capacitor from the negative input resistor to ground,
                    the DC gain will be unity, while the AC gain will remain mostly unchanged
                    (this means we will have to subtract 2048 or 1.65V from the converted result).
                </p>
            </div>

            <div className="em__post-section">
                <h3>Project setup:</h3>
                <p>
                    And now that we know what we want to do, let's start the <s>execution</s> executing the plan.
                    Create a new project with the debugger enabled, and the system clock set to external oscillator.
                    Set the system frequency to 160MHz.
                    Go to the <i>Analog</i> category and enable both OpAmp 2 and OpAmp3 in Standalone mode as high-speed device.
                    The OpAmps are routed out to external I/Os: OpAmp3+ to PA1, OpAmp3- to PB2, OpAmp3Out to PB1;
                    OpAmp2+ to PA7, OpAmp2- to PC5, OpAmp2Out to PA6.
                </p>
                <Popup trigger={<img className="img_opamp_setup_hal20 clickable_img" src={opamp_setup} alt="opamp_setup_hal20"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={opamp_setup} alt="opamp_setup_hal20" />
                    )}
                </Popup>
                <p>
                    We will use OpAmp3 as the transimpedance amplifier, and OpAmp2 as the non-inverting amplifier.
                    This means that we should connect OpAmp2Out to the ADC, which we can do in the IDE.
                    Left click on Pin PA6 and add ADC2 IN3 to that Pin.
                </p>
                <img className="img_adc_pin_hal20" src={adc_pin} alt="adc_pin_hal20"/>
                <p>
                    Next, go to ADC2 and configure IN3 to Single-ended operation.
                    Set the external trigger event to Timer 6 Trigger Out event so that we have a constant sampling time.
                    Set the sampling time to 47.5Cycles.
                    Enable the ADC interrupts.
                </p>
                <p>
                    Go to Timers/TIM6 enable it and create a trigger event with a frequency of 500Hz.
                    One possible configuration for a clock of 160MHz is Prescaler: 8, Counter Period 40000.
                    Oh, and set the trigger event to Update Event.
                </p>
                <p>
                    Enable two digital output pins.
                    I've used PC2 and PC1 - we will use these outputs to turn on two different LEDs.
                    Generate the project.
                </p>
            </div>

            <div className="em__post-section">
                <h3>The firmware:</h3>
                <p>
                    Before we start to write the firmware we need to enable something.
                    At the beginning I stated that we will create a blood oxygen level measuring device.

                    The <a href="https://www.howequipmentworks.com/pulse_oximeter/">theory</a> states that the blood has
                    different light absorbing capability based on how much oxygen/carbon-dioxide it carries.
                    We can use this fact to measure oxygen levels by using a red light and an IR light source, taking two measurements
                    calculating the r value <ShowTex string="$$r = \frac{red_{AC}/red_{DC}}{ir_{AC}/ir_{DC}}$$"/>of the signals.
                    This could be done using integer/fixed point math, but that is outside the scope of this tutorial.
                    For now we will use floats.
                    Open <i>CMakeList.txt</i> and uncomment lines 22 - 24 which enable the floating point operation hardware.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {float_compile}
                </SyntaxHighlighter>
                <p>
                    First, we need some static variables.
                    <i>adc_val</i> will contain the raw sample from the adc, <i>adc_flag</i> will show when a new sample is available.
                    <i>r_val</i> will contain the oxygen level and the <i>_debug</i> variables are used only for visualisation at a latter stage.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {globals}
                </SyntaxHighlighter>
                <p>
                    The interrupt routine is straight-forward.
                    We read the value, then raise a flag so that the main routine can process the data.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {adc_irq}
                </SyntaxHighlighter>
                <p>
                    In the main routine we need some variables:
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {locals}
                </SyntaxHighlighter>
                <p>
                    We have to distinguish the different light measurement stages, we have to count the number of samples held etc.
                    The raw data will be contaminated with 50Hz noise, so we need to filter it out.
                    The simplest filter is the moving average filter, and we will use the nice comb property of the filter.
                    If we have a filter with fs sampling frequency and N samples, the filter will have a huge
                    attenuations at frequencies <ShowTex string="kf_s/N"/> where k is a natural number
                    (more on filter applications in the signal processing section).
                    In our case N = 10 will get rid of most of the unwanted noise.
                </p>
                <img className="img_filter_freq_hal20" src={filter_freq} alt="filter_freq_hal20"/>
                <p> Moreover, we need to store 20 filtered values so that we can be sure to have a full period of signal a our given sampling frequency.</p>
                <p>
                    Next, we need to setup the peripherals:
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {setup}
                </SyntaxHighlighter>
                <p>
                    Here, we initialize and start the OpAmps, set the lighting conditions (in my hardware, this corresponds to the IR LED).
                    Calibrate the ADC and start the acquisition.
                </p>
                <p>
                    If we have a new data, we can store it in the appropriate buffer, and increment the buffer counter.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {store_data}
                </SyntaxHighlighter>
                <p>
                    If we have collected 10 samples of the IR data, we can stop the timer so that interrupts won't bother the calculations.
                    Reset the counter for the next data acquisition.
                    Filter the data, store the filtered value in the debug variable, and in the filtered buffer for further processing.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {ir_process}
                </SyntaxHighlighter>
                <p>
                    The procedure is the same in the case of the red light.
                    If we have collected 20 values both from filtered IR and red samples, we can calculate the rindex.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {r_index}
                </SyntaxHighlighter>
                <p>
                    Here we search for the minimum and maximum values in the IR and red buffers.
                    The AC amplitude can be given as <ShowTex string="$x_{AC} = (x_{max} - x_{min})/2$"/>,
                    while the DC component can be given as <ShowTex string="$x_{DC} = (x_{max} + x_{min})/2$"/>
                    After that we can give the rindex in percent, and restart the timer.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Monitoring the acquired data:</h3>
                <p>
                    Before we start anithing, we need to wire up the setup.
                    I'm using an over-the-counter red and IR LED encased in a 3D printed finger mount.
                    The photodiode is a BPV10 PIN diode.
                    Allegedly my setup is crude and a wire mess, but it gets the job done, and we will look at some possible improvements at the end.
                </p>
                <img className="img_hardware_hal20" src={hardware} alt="hardware_hal20"/>
                <p>
                    The LEDs are connected anti parallel and are hooked up to pins 35 and 37 on CN7.
                    CN8/2 (OpAmp3+) is connected to ground.
                    The OpAmp3- (CN10/22) is connected to the cathode of the photodiode, the anode is grounded.
                    You can see a third LED on the finger mount, that was the initial position of the photodiode,
                    but I've found that under the finger works better.
                    This one was glued in place, so I left it there, and hooked up a new one.
                    The OpAmp3 out (CN10/24) is connected to the high-pass filter, which is connected to the OpAmp2+ (CN5/4).
                    The OpAmp2- (CN9/1) is connected to the RC components, and so is the OpAmpOut/ADC2_IN3 (CN5/5).
                </p>
                <p>
                    For debugging purposes, I will be using STM32CubeMonitor, which is a variant of NodeRed.
                    Go to <a href="https://wiki.st.com/stm32mcu/wiki/STM32CubeMonitor:Basic_flow_to_perform_a_simple_acquisition">this</a> page
                    to learn about the Monitor concepts.
                </p>
                <img className="img_monitor_hal20" src={monitor} alt="monitor_hal20"/>
                <p>
                    Here I have two buttons to start and stop the acquisition.
                    The <i>var_group</i> node contains the generated .elf file, so that the monitor can read values
                    from specified memory regions corresponding to the variables under observation.
                    The <i>Nucleo_Out/Nucleo_In</i> nodes are sending and receiving data using the on-board programmer/debugger.
                    Here I'm using a chart to plot the two debug variables, and a numeric display to show the rindex.
                    There are red dots since at the time of the print screen I did not bother to connect the device, so it was not found :).
                </p>
                <img className="img_graph_hal20" src={graph} alt="graph_hal20"/>
                <p>
                    Here we can see a snippet of the device in action.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Further development:</h3>
                <p>
                    This project can be optimized to the skies and beyond.
                    <ul>
                        <li>
                            The two-light based measurement method must be calibrated before relying on the values.
                        </li>
                        <li>
                            The most obvious improvement is in the hardware: we could create a proper pcb and a shielded enclosure,
                            and use a shielded cable for the LEDs. That would drastically reduce the induced noise values.
                        </li>
                        <li>
                            Another improvement can be done with the use of DMA. Use a half-word DMA of depth 20,
                            store the IR measurements in the first half, store the red measurements in the second half.
                        </li>
                        <li>
                            We could use a fancier filter like a FIR or IIR band-stop filter to remove only the 50Hz noise (or a small region around it).
                        </li>
                        <li>
                            Finally, we could include another light sensor. According to a <a href="https://pubmed.ncbi.nlm.nih.gov/19405774/">study</a>, if
                            we use 3 light sources we don't need to calibrate the sensor.
                            Also, a blood saturated with carbon-monoxide looks like an oxygenated blood with a two
                            lighted sensor according to <a href="https://pubmed.ncbi.nlm.nih.gov/8037391/">this</a> source,
                            so this is another reason to opt for more light sources.
                        </li>
                    </ul>

                </p>
                <p>
                    As some wise professors would say, these improvements are left as an exercise for the reader.
                    The project files can be downloaded from <a href="https://gitlab.com/stm32_mcu_group/stm32_hal/20_opamp.git">this</a> repo.
                </p>
            </div>



            <div className="em__post-navigation">

                <NavLink to="./../stm-hal-19">
                    <Button btnID={"leftBTN"} buttonSize="btn--medium"> Previous Post</Button>
                </NavLink>

                <NavLink to="./../stm-hal-21">
                    <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
                </NavLink>
            </div>
        </div>
    );
}

export default MCU_HAL20;