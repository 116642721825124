import React from "react";
import "./../posts.css";
import "./maglev.css";
import Popup from "reactjs-popup";
import comp from "./Comp.png";
import error from "./error.png";
import pid from "./PID.png";
import power from "./power.png";
import pwm from "./PWM.png";
import spool_holder from "./Spool_Holder.png";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";

function MagLev(){
    return(
        <div className="em__post">
            <div className="em__post-title">
                <h1>Suspended electromagnetic levitation</h1>
            </div>
            <div className="em__post-section">
                <h3>Beginning</h3>
                <ul>
                    <li>
                        <b>Summary:</b> In this tutorial, we will create a purely analog suspended electromagnetic levitation device.
                        The suspended object will be a permanent magnet.
                        We will use an air cored electromagnet as the actuator, and Hall-effect sensors as ... sensors.
                    </li>

                    <li>
                        <b>Prerequisites:</b>
                        To successfully build the systems and to understand the underlying design principles the user should have some basic analogue electronics and control design knowledge.
                        The simulations were carried out in LTSpice ( from Linear Technology can be downloaded from&nbsp;
                        <a href="https://www.analog.com/en/design-center/design-tools-and-calculators/ltspice-simulator.html">here</a>).
                        The circuit was designed using Autodesk Eagle (can be downloaded from&nbsp;
                        <a href="https://www.autodesk.com/products/eagle/free-download">here</a>) but other PCB designer software can be utilized such as&nbsp;
                        <a href="https://www.kicad.org/download/">KiCad</a>,&nbsp;<a href="https://www.altium.com/">Altium Designer</a>, etc.
                    </li>
                    <li>
                        <b>Why suspended, why air-cored, why Hall-effect sensor(s)?</b>
                        <ul>
                            <li>There are two types of electromagnetic levitation configurations.
                                The first one, the suspended has the electromagnet on top, the levitated object is under the coil.
                                In this configuration we need to compensate only for the gravitational acceleration.
                                The second configuration is the inverted levitation, where the levitated object is above the coil.
                                In this case any slight misalignment in orientation will create a repelling force, and the levitated object would fall off
                                (this effect is negated by the use of a big magnet ring or, small magnets placed in a ring configuration).</li>
                            <li>We can use opto-electronics or hall-effect sensors as the positional sensors in purely analog circuits.
                                With optical sensing we can only detect the position at a given point,
                                while with magnetic sensing we can have a continuous reading of the position.</li>
                            <li>The Hall-effect sensor creates some constraints on the setup.
                                First we need to use a permanent magnet in the suspended object for better sensing.
                                Second, we need to use air cored coil to have a linear magnetic field, and to minimize the unwanted attractive force.</li>
                        </ul>
                    </li>
                </ul>
            </div>
            <div className="em__post-section">
                <h3>Mechanical setup:</h3>
                <img className="img_holder" src={spool_holder} alt="holder" />
                <p>
                    We will use&nbsp;<a href="https://www.autodesk.com/products/fusion-360/overview">Autodesk Fusion</a>&nbsp;
                    360 to design the mechanical setup.
                    We have to make a spool for the air-cored electro-magnet.
                    We need a support for the driver and control electronics a chassis which holds the spool,
                    has a wire canal and elevates the actuator/sensor part from the electronics.
                    The inner diameter of the spool has a diameter of 11.5 mm, a length of 28 mm,
                    and an effective winding diameter of 12.25mm. The sides of the spool have two 1.2mm wide slits for the coil ends.
                    We will use a Hall effect sensor under the coil and above the coil, everything inside a spool harness.
                    The harness is locked in a three part wire canal, which continues in a hollow two part rigid harness.
                    The project files are&nbsp;
                    <a href="https://a360.co/3ao0ytA">Inner spool</a>,&nbsp;
                    <a href="https://a360.co/3j1OvWW">outer spool</a>,&nbsp;
                    <a href="https://a360.co/3r92PzW">the holder</a>.&nbsp;
                </p>
            </div>
            <div className="em__post-section">
                <h3>The coil:</h3>
                <p>
                    The air cored coil is wound with 0.3mm enamel copper wire,
                    and it was impregnated with thermal conductive silicone glue for better heat dissipation and to reduce vibrations.
                    The resistance of the coil is 2Ω and the inductance is 3.96mH.
                </p>
            </div>
            <div className="em__post-section">
                <h3>The power stage:</h3>
                <p>I've designed a completely discrete power stage with MOS-FETs and BJTs.</p>
                <Popup trigger={<img className="img_power clickable_img" src={power} alt="power" />} modal nested>
                    {close => (
                        <img className="em__img_full" src={power} alt="power" />
                    )}
                </Popup>
                <p>The M1 N-FET is the power switch, we will use it in switching configuration to mitigate the power losses.
                    The D1 diode is a flyback diode, which provides an alternate path for the inductor current in the transient phase,
                    thus saving M1 from destructive current spikes.
                    R1/R2 is a shunt resistor pair for future current measurements.</p>
                <p>Although FETs are voltage controlled switches, if we want to switch high currents at high frequencies,
                    we need to charge the gate capacitance fast (with relatively large currents). This is the purpose of the next stage.
                    Here Q2 pulls the gate signal low through D2.
                    Yes, the diode introduces a voltage drop, but the overall effect is nearly the same as with an emitter follower.
                    Q1 amplifies the current through R4, driving the FET gate.
                    So D2 takes care discharging the gate of M1, while Q1 takes care of charging it.
                    The problem is that now the driving signal is inverted.</p>
                <p>This is why we introduce the final (low power) stage.
                    While the previous stage must be supplied with at least 12V (gate voltage of M1), this stage can use the same supply voltage as the controller.
                    Its only purpose is to invert the input signal.
                    Care must be taken when choosing R5, to have the proper power value. </p>
                <p><i>Note:</i> this whole driving circuit can be substituted by a simple gate driver IC such as the&nbsp;
                    <a href="https://ww1.microchip.com/downloads/en/DeviceDoc/21419D.pdf">TC4420</a>.
                    The only reason to use the discrete one is to learn the underlying principles or being in a lockdown/lazy and not having such an IC at hand.</p>
            </div>
            <div className="em__post-section">
                <h3>Controller stage:</h3>
                <Popup trigger={<img className="img_error clickable_img" src={error} alt="error" />} modal nested>
                    {close => (
                        <img className="em__img_full" src={error} alt="error" />
                    )}
                </Popup>
                <p>We will use two&nbsp;
                    <a href="https://datasheet.octopart.com/SS495B-Honeywell-datasheet-116854133.pdf">SS495b</a>&nbsp;HALL-effect sensors.
                    One below, one above the coil IN THE SAME ORIENTATION.</p>
                <p>Here we two differential amplifiers and a virtual ground.
                    Since the supply is single-ended (5V to ground) we need a half supply virtual ground for the operation amplifiers to work properly.
                    OA1 takes care of the bias problem.</p>
                <p>OA2 in differential amplifier configuration removes the effects of the electromagnet,
                    since the magnetic field generated by the coil has the same magnitude on both sides.</p>
                <p>The second differential amplifier generates the error signal,
                    which is the difference between the magnetic field generated by the neodymium magnet and by the signal generated by the potentiometer
                    (the reference or set-point signal).</p>
                <p>The capacitors C2/C3 are placed in the feed-back path for stability reasons.
                    If the feed-back signal is slow, the capacitors are open circuit,
                    while for fast signals the capacitor and input resistor creates a low-pass filter and attenuates the signals.  </p>
                <Popup trigger={<img className="img_pid clickable_img" src={pid} alt="pid" />} modal nested>
                    {close => (
                        <img className="em__img_full" src={pid} alt="pid" />
                    )}
                </Popup>
                <p>This is the PID controller setup.
                    OA4 is configured as the inverting proportional (P) control.
                    The gain be tuned with the output potentiometer.
                    If the error term is large, the P controller generates a large control signal.
                    If the error is zero, the output is zero, which is problematic in our case.
                    This means that the magnet will be dropped.</p>
                <p>OA5 is configured as an integrator (I).
                    This controller part eliminates the residual error and any constant biases in the input value.
                    In terms of signal frequency, this is considered a low-pass filter. R18/C6 is responsible for the integrator effect,
                    but with only these passive components we would have infinite DC gain, so by adding R20, we set finite DC gain value.</p>
                <p>For fast changes in the error term, we use the derivative part (D) with OA6.
                    This part of the controller is sensitive to the change in error (the derivative of the error),
                    C8 together with R25 is providing the derivative effect, R25/R23 gives the DC gain,
                    while the R23/C7 pair is a low-pass filtering configuration for causality and stability. </p>
                <p>Finally, the P, I and D signals are combined with OA7 in summing inverting amplifier configuration.</p>
                <p>So far we could generate the control signal, which is a linear signal, but remember, the power stage expects switch mode input.
                    We can convert our continuous analog signal to a switched signal by implementing pulse width modulation (PWM).</p>
            </div>
            <div className="em__post-section">
                <h3>Generating the driving signal:</h3>
                <Popup trigger={<img className="img_pwm clickable_img" src={pwm} alt="pwm" />} modal nested>
                    {close => (
                        <img className="em__img_full" src={pwm} alt="pwm" />
                    )}
                </Popup>
                <p>The PWM signal is generated by using a triangle or saw-tooth carrier, and by comparing the carrier with the reference signal. </p>
                <p>Here we can see the carrier generator and the comparator circuits.
                    The triangle wave generator consists of two stages: a Schmitt-trigger oscillator and an integrator.
                    The first one generates a square wave signal, the second one integrates it and generates the triangle wave.
                    For in-depth design notes please refer to the second answer on&nbsp;
                    <a href="https://electronics.stackexchange.com/questions/272446/making-a-triangle-wave-on-ltspice-using-lm741-opamp">this</a>&nbsp;page.</p>
            </div>
            <div className="em__post-section">
                <h3>Tuning the controller</h3>
                <p>The PID controller is an old design, so it is well studied.
                    There are numerous tuning methods written for it based on the required performances
                    (time domain characteristics, frequency domain characteristics, gradient decent for optimality, iterative feedback, assignment pole, etc.).
                    There are heuristic methods (Ziegler-Nichols, Tyreus Luiben, Oppelt, Åström-Hägglund, etc.).</p>
                <p>Here we will use the simplest tuning process possible, which does not require sophisticated math, knowledge of the system model, linearisation etc. </p>
                <ol>
                    <li>First remove the I and D terms, use only P.</li>
                    <li>Increase P term until the magnet is suspended but it oscillates (steady oscillation, not increasing amplitude).</li>
                    <li>Increase D until the oscillation goes away (critically damped). </li>
                    <li>Repeat 2 and 3 until the D gain does not stop the oscillations.</li>
                    <li>Set P and D to the last stable values.</li>
                    <li>Increase I until the steady state error disappears.</li>
                </ol>

                <Popup trigger={<img className="img_comp clickable_img" src={comp} alt="comp" />} modal nested>
                    {close => (
                        <img className="em__img_full" src={comp} alt="comp" />
                    )}
                </Popup>
                <p>Oh, and watch out for the cheap potentiometers.
                    One minute you have a well tuned loop with nice levitation, the other minute the magnet will be dropped no mater how you turn the pots.
                    The mechanical connection can be a real PITA.</p>
                <p>If one want's to be sure that the controller works as intended,
                    one measures the pot values, and creates a type 3 compensator, with fixed value components.</p>
                <p>This circuit replaces the whole PID controller, so the control electronics is done with a quad op-amp and a dual comparator IC.</p>

            </div>
            <div className="em__post-section">
                <p>Either way, here is a sample of the finished levitation device.</p>
                <div className="em__post-section_wrapper">
                    <iframe className="em__maglev_vid" id="odysee-iframe"
                            src="https://odysee.com/$/embed/suspended-maglev/f48843b99918a58c351038c883c8bb2cf95c9fa0?r=GnmnynsTTPMgpaqzhST1c1mm2MSchnzt"
                            allowFullScreen title="finished_lev"/>
                </div>

            </div>

            <div className="em__post-navigation">

                <NavLink to="./../head-bass">
                    <Button btnID={"leftBTN"} buttonSize="btn--medium"> Previous Post</Button>
                </NavLink>

                <NavLink to="./..">
                    <Button btnID={"rightBTN"} buttonSize="btn--medium"> Projects</Button>
                </NavLink>
            </div>
        </div>
    );
}

export default MagLev;