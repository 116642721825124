import React from "react";
import "./../../../global.css";
import "./../../../index.css";
import "./blogprev.css";
import Article from "../article/Article";
import cmsn from "./../assets/cmsn.png";
import old from "./../assets/old.png";
import {fpga_1_profile} from "../../projects/Posts";
import micro from "../../projects/assets/micro.png";
import rtos from "./../assets/rtos_cm4.jpg";

const blogShorts = [
    {img: micro, date: "2023.05.07", title: "Register level programming of STM32 MCU in C++", path: "/"},
    {img: rtos, date: "2022.08.28", title: "RtOS on STM32 MCU", path: "/projects/micro-tut/stm-rtos"},
    {img: micro, date: "2022.08.28", title: "STM32 MCU programming using HAL", path: "/projects/micro-tut/stm-hal"},
    {img: fpga_1_profile, date: "2022.01.31", title: "Hands on a tiny and affordable FPGA board",
        path: "/projects/fpga-tut/fpga-tut-1"},
    {img: cmsn, date: "2022.01.26", title: "Building an autonomous drone", path: "/"},
];

const oldImg = {img: old, date: "2022.01.26", title: "Browse older entries", path: "/projects"};

function Blogprev(){
    return(
        <div className="em__blog section__padding" id="blog">
            <div className="em__blog-heading">
                <h1 className="gradient__text">
                    My recent projects are listed below
                </h1>
            </div>
            <div className="em__blog-container">
                <div className="em__blog-container_groupA">
                    <Article imgURL={blogShorts[0].img} date={blogShorts[0].date} title={blogShorts[0].title} path={blogShorts[0].path}/>
                </div>
                <div className="em__blog-container_groupB">
                    {
                        blogShorts.map((item, index) =>(
                                index > 0  &&
                        <Article imgURL={item.img} date={item.date} title={item.title} path={item.path}/>

                        ))
                    }
                </div>
                <div className="em__blog-container_groupC">
                    <Article imgURL={oldImg.img} title={oldImg.title} path={oldImg.path}/>
                </div>
            </div>
        </div>
    );
}

export default Blogprev;