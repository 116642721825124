import React from "react";
import "./mcu_hal1.css";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";
import cores from "./cores.png";
import toolchain from "./toolchain.png";
import new_proj from "./new_proj.png";
import cube1 from "./cube_1.jpg";
import cube2 from "./cube_2.jpg";
import cube3 from "./cube_3.jpg";
import cube4 from "./cube_4.jpg";
import cube5 from "./cube_5.jpg";
import cube6 from "./cube_6.jpg";

const section = `
/*USER CODE BEGIN ---- */

/* USER CODE END ---- */
`;

const value = `
/* USER CODE BEGIN 1 */
    uint8_t i = 0;
  /* USER CODE END 1 */
`;

const whl = `
/* USER CODE BEGIN WHILE */
while (1)
{
  ++i;
  if(i == 200)
      i = 0;
  if(i == 100)
      asm("NOP");
/* USER CODE END WHILE */

/* USER CODE BEGIN 3 */
}
/* USER CODE END 3 */
`;

function MCU_HAL1(){
   return(
       <div className="em__post">
           <div className="em__post-title">
               <h1>Environment setup</h1>
           </div>
           <div className="em__post-section">
               <h3>History and aim of the series:</h3>
               <p>
                   I've started working with microcontrollers back in my university days with a PIC16F628,
                   and I was amazed how versatile a little 8b MCU can be, and how different it was writing code on an MCU with
                   "limited" clock speeds and memory space compared to writing code on a PC.
               </p>
               <p>
                   Latter, I've found out about the 16b DSC variants called dsPIC33 which besides the obvious
                   architecture change hosted a hardware accelerator for digital signal processing.
                   Then there was the 32b variant, the PIC32MX.
               </p>
               <p>
                   Now there are many more MCU flavors.
                   One such MCU family that piqued my interests is the STM32 family from
                   ST Microelectronics.
                   These are relatively cheap 32b MCUs with rich periphery, nice support, and great choice for IDEs.
               </p>
               <p>
                   In this tutorial series I'll try and document as much of the processor and periphery use-cases as I can,
                   starting with the setup of the development toolchain. Mostly to document the steps for latter personal use-cases,
                   If by doing that, I can help other people to find useful information, all the better.
               </p>
           </div>

           <div className="em__post-section">
               <h3>About the boards:</h3>
               <p>These tutorials will focus on using the hardware abstraction layer (HAL) provided by ST Micro,
                   so any board with an ARM Cortex M3 or M4 MCU can be used with minimal or no modification.
                   I have an original NUCLEO-G491RE, so mostly I will be using that board.
               </p>
               <p>
                   Further more I have a custom Arduino compatible board with STM32F446,
                   and I want to get my hands on some M7 processors, so there will be more to come.
               </p>
               <Popup trigger={<img className="img_cores clickable_img" src={cores} alt="cores"/>} modal nested>
                   {close => (
                       <img className="em__img_full" src={cores} alt="cores" />
                   )}
               </Popup>
               <p>
                   <i>A bit about the ARM Cortex instruction set:</i> For starters, there are three major ARM architectures.
                   <b>Cortex-M</b> is the smallest core, with the lowest energy consumption.
                   It is optimised for discrete processing and microcontroller use-cases.
                   <b>Cortex-R</b> has fast response time. It's optimised for high performance, hard real-time applications.
                   <b>Cortex-A</b> has the highest performance rating, and it's optimized for rich embedded and personal operating systems.
                   Furthermore, there are single and multicore processors.
                   The latter can be of homogeneous architecture with same ISA, same micro architecture (e.g. 4 x Cortex-A7 ),
                   heterogeneous with performance asymmetry with same ISA, different micro architecture  (e.g. 4 x Cortex-A7 and 2 x Cortex-R clusters) or
                   heterogeneous with functional asymmetry with different ISA, different micro architecture(e.g. 2x Cortex-A7 and Cortex-).
               </p>
               <p>
                   The Cortex-M ISA family is divided into subsets, where the supersets offers the instruction of the previous mode.
                   Cortex-M0/M0+ which has instructions for general data processing and I/O control tasks.
                   Cortex-M3 offers advanced data processing and bit field manipulations.
                   Cortex-M4 implements DSP, SIMD, FMAC instructions. There is the plane M4 variant which only supports fixed point numbers and soft floats,
                   and there is the M4F with floating point accelerator.
                   Finally the Cortex-M7 implements instruction and data caches.
               </p>

               <p>
                   The <a href="https://www.st.com/en/microcontrollers-microprocessors/stm32g491re.html">STM32G491RE</a> is an ARM Cortex M4F microcontroller running at maximum 170MHz.
                   It combines three hardware accelerators: ART Accelerator, CCM-SRAM routine booster and mathematical accelerators.
                   The analog peripherals compromise of comparators, op-amps in normal, follower or PGA configuration, ADCs and DACs.
                   The memory is dual-bank Flash with error-correcting code.
                   It contains securable memory area, multiple high-resolution and advanced timers, USB-C interface with power delivery and AES hard encryption.
               </p>
               <p> The <a href="https://www.st.com/en/evaluation-tools/nucleo-g491re.html">NUCLEO-G491RE</a> contains the aforementioned MCU,
                   an ST-LINK-V3E debugger/programmer with virtual serial port.
                   It has one user programmable LED, reset and user push-buttons. There is a 24MHz and 32.768kHz crystal oscillator on-board.
                   The pins of the microcontroller are fanned out to ARDUINO Uno style connectors and ST morpho headers.
               </p>
           </div>

           <div className="em__post-section">
               <h3>About the programs:</h3>
               <p>
                   There are many ways to program an STM32.
                   The easiest one is to flash an Arduino bootloader, and use the Arduino environment.
                   We do not do that around here.
                   Then there's MicroPython, which does not directly support the G491 variant,
                   but it could be ported with the help of the STM32 Cube HAL libraries.
                   Again, we don't script on MCUs with limited resources.
                   Rust is another option, but so far I did not find a nice and easy debug option, so C/C++ it is.
               </p>
               <p>
                   The major IDEs for STM32 developments (at the time of writing this tutorial) are:
               </p>
               <ul>
                   <li><a href="https://www.st.com/en/development-tools/stm32cubeide.html">STM32CubeIDE</a> (formerly known as Atollic TrueStudio),
                       the main IDE supported by ST microelectronics. It's free, it contains the programmer and graphical configurator.
                       - use this, if you want nice support and quick development with the MCUs</li>
                   <li><a href="https://code.visualstudio.com/">Visual Studio Code</a>  with <a href="https://platformio.org/">PlatformIO</a> is
                       also a free IDE, although you need to separately download STM32CubeMX to configure the MCUs graphically.
                       Alternatively you could write bare-metal or Arduino code, which does not require the configurator.</li>
                   <li><a href="https://www.jetbrains.com/clion/">JetBrains</a> CLion is not free, you also need the configurator, but for some reason I love JetBrains products.
                       It's nice, elegant, fast and and it's free for academic use, so I'll keep using it until I can.</li>
               </ul>
               <h4> Let's download the programs</h4>
               <p>
                   If you are using STM32CubeIDE, download it, and you are done.
                   For CLion, first download the <a href="https://www.st.com/en/development-tools/stm32cubemx.html">STM32CubeMX</a> configuration software,
                   and install it. Next, install CLion.
               </p>
               <p>
                   Every ARM MCU uses the <a href="https://developer.arm.com/tools-and-software/open-source-software/developer-tools/gnu-toolchain/gnu-rm/downloads">ARM GNU eabi toolchain</a>, so install that too, and add it to the PATH.
                   We need a debugger. Without that we are no better than using Arduino.
                   Download and install <a href="https://gnutoolchains.com/arm-eabi/openocd/">OpenOCD</a>, and add to the PATH.
               </p>
               <p> Make sure that these programs are recognised by the system. In a CMD type the following commands: <i>arm-none-eabi-gcc --version</i> and <i>openocd --version</i>,
                   which should return the version of the installed programs. </p>

               <p> Next, customize the toolchains. You could use the bunded MinGW or download one yourself. Same with the debugger, for which I've used the one provided by the arm gcc toolchain.</p>
               <img className="img_toolchain" src={toolchain} alt="toolchain"/>
               <p>If everithing works, it should show up with green checkmark. </p>
           </div>

           <div className="em__post-section">
               <h3>Setting up a hello world program:</h3>
               <p>Open up Clion and create a new project from <i>File/New/Project...</i>, then chose the STM32Cube project with a preferred path.</p>
               <img className="img_new_proj" src={new_proj} alt="new_proj"/>
               <p>
                   So far this only gave an .ioc file, and a message that <i>Project sources are not generated. Run 'GENERATE CODE' in STM32CubeMX</i>.
                   Click on the <i>Open with STM32CubeMX</i>. The first time, the program will ask to give the path for STM32CubeMX, so give the path of the binaries.
                   Now we can open the GUI for the MCU setup.
               </p>
               <img className="img_cube1" src={cube1} alt="cube1"/>
               <p>The default part is STM32F030F4Px. We don't want that, so go to <i>Home</i>, so that we can change the MCU.</p>
               <img className="img_cube2" src={cube2} alt="cube2"/>
               <p>Here, start a project by searching for the board name.</p>
               <img className="img_cube3" src={cube3} alt="cube3"/>
               <p>
                   Search for <i>NUCLEO-G491RE</i>. There is only one result, since there is only one variant of this board. Select it.
                   The project manager will ask if you want to load the default periphery settings, just say no. We will get to those in the next tutorials.
               </p>
               <img className="img_cube4" src={cube4} alt="cube4"/>
               <p> In the Project Manager tab give a name and location for the project, and set the Toolchain to STM32CubeIDE.
                   CLion will not complain. Click the generate code, and wait for CubeMX to generate our project files.</p>
               <img className="img_cube5" src={cube5} alt="cube5"/>
               <p> A board configuration window will appear. select the <i>st_nucleo_g4.cfg</i> and click <i>Copy to Project & Use</i>.
                   This way we place every file that we want to use in the current directory.</p>
               <p>
                   In CLion, click on <i>Build/Build ' OCD ~ProjectName~'</i>. If every tool is set up correctly, this will generate the required files for project Debug.
                   Let's take a look at the project structure.
                   There are a few files and folders in our project:
               </p>

               <ul>
                   <li>
                       <i>cmake-build-debug</i> - this folder contains the linked and compiled files out of which the most important ones are .elf, .bin.
                       The latter is a pure binary file with no memory fig-ups, only explicit instructions to  be loaded at specific memory addresses.
                       The former consists of symbol look-ups and relocatable tables. We will use this file for graphical debugging later on.
                   </li>
                   <li>
                       <i>Core</i> - this folder has three sub-folders containing the program code.
                       <i>Inc </i> is for the .h or .hpp include files, <i> Src </i> for the .c or .cpp source files,
                       <i>Startup </i> contains an assembly that sets up the stack pointer, program counter, interrupt vector tables,
                       clock configurations, and a minimal boot loader to call the main().
                   </li>
                   <li>
                       <i>Drivers </i> contains CMSIS and HAL driver files. We will use the latter in this series quite heavily.
                   </li>
               </ul>
               <p>
                   Other important files: <i> CMakeList.txt</i> contains the linker and compilation flags.
                   E.g. There is a section under line 21 which will be essential if we want to use the floating point accelerator.
                   Another important section is around line 39 which tells the optimisation levels for the compiler.
                   In this tutorial series we will not optimize our code, but it's good to know where could one accomplish such tasks.
               </p>

               <p>
                   The <i> st_nucleo_g4.cfg</i> contains information regarding the debug/upload procedures. There are four lines in the default file.
                   <i>source [find interface/stlink.cfg]</i> - instructs OpenOCD to find the on-board debugger.
                   <i>transport select hla_swd</i> - sets the communication protocol between the probe and the MCU.
                   <i>source [find target/stm32g4x.cfg]</i> - instructs OpenOCD to find the MCU.
                   <i>reset_config srst_only</i> - sets the reset method before and after programming.
                   For more information, refer to
                   the <a href="https://openocd.org/doc/html/Reset-Configuration.html#index-Reset-Configuration">OpenOCD documentation</a>.
               </p>

               <p>
                   Finally, let's write a minimal code, so that we can test the hardware and the communication.
                   Go to <i>Core/Src/main.c</i>. Here you can see that it's already populated with some code.
                   The default project files will be overwritten every time that we re-generate the project with CubeMX.
                   The only files that are untouched are the files added by us, and some special regions between user code tags as shown below.
               </p>
               <SyntaxHighlighter language="c" style={AtomOneDark}>
                   {section}
               </SyntaxHighlighter>

               <p>
                   Inside the main function define a variable with 0 initial value
               </p>

               <SyntaxHighlighter language="c" style={AtomOneDark}>
                   {value}
               </SyntaxHighlighter>
               <p>
                   in the infinite loop increase the value, reset it at 200, and insert a no operation (NOP) instruction at 100.
               </p>
               <SyntaxHighlighter language="c" style={AtomOneDark}>
                   {whl}
               </SyntaxHighlighter>

               <p>
                   Set a breakpoint at <i>++i</i> and at <i>asm("NOP");</i> by clicking at the line numbers. Then start the debugging with the bug icon.
                   If everything went well, the program starts, and stop at the first breakpoint. We can even check the values of the variables.
               </p>
               <img className="img_cube6" src={cube6} alt="cube6"/>
               <p>
                   The important files for this project can be found <a href="https://gitlab.com/stm32_mcu_group/stm32_hal/1_settings">here</a>.
                   In the next tutorial we will test out the GPIOs of the MCU.
               </p>

           </div>


           <div className="em__post-navigation">

               <NavLink to="./..">
                   <Button btnID={"leftBTN"} buttonSize="btn--medium"> Micro Projects</Button>
               </NavLink>

               <NavLink to="./../stm-hal-2">
                   <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
               </NavLink>
           </div>
       </div>
   );
}

export default MCU_HAL1;