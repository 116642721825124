import React from "react";
import "./dsp_5.css";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";
import {MathJax, MathJaxContext} from "better-react-mathjax";
import fft_butterfly_2 from "./fft_butterfly_2.jpg";
import fft_butterfly_4 from "./fft_butterfly_4.jpg";
import fft_butterfly_8 from "./fft_butterfly_8.jpg";
import mx_adc_1 from "./mx_adc_1.jpg";
import mx_adc_2 from "./mx_adc_2.jpg";
import fft_64_timing from "./fft_64_timing.jpg";
import fft_64_combined_monitor from "./fft_64_combined_monitor.jpg";
import chirp_fft from "./chirp_fft.gif";

function ShowTex({string}){
    const config = {
        loader: { load: ["[tex]/html"]},
        tex: {packages: {"[+]": ["html"]},
            inlineMath: [["$", "$"]],
            displayMath: [["$$", "$$"]]
        }
    };

    return(
        <MathJaxContext config={config} version={3}>
            <MathJax dynamic inline>
                {string}
            </MathJax>
        </MathJaxContext>
    );
}

const fft_header = `
#define N_TIME 32
#define N_FREQ  N_TIME/2

void setInputBuffer(uint16_t *src, uint16_t offset);
void fft();
void magnitude(float *dstV);
`;

const fft_lut = `
#if N_TIME == 32
const cplx twiddle[] = {
    { 1.0000, 0.0000 },{ 0.9808, -0.1951 },{ 0.9239, -0.3827 },{ 0.8315, -0.5556 },
    { 0.7071, -0.7071 },{ 0.5556, -0.8315 },{ 0.3827, -0.9239 },{ 0.1951, -0.9808 },
    { 0.0000, -1.0000 },{ -0.1951, -0.9808 },{ -0.3827, -0.9239 },{ -0.5556, -0.8315 },
    { -0.7071, -0.7071 },{ -0.8315, -0.5556 },{ -0.9239, -0.3827 },{ -0.9808, -0.1951 }
};
const uint8_t brev[32] = {
    0,16,8,24,4,20,12,28,
    2,18,10,26,6,22,14,30,
    1,17,9,25,5,21,13,29,
    3,19,11,27,7,23,15,31
};
#endif
`;

const fft_buffer = `
const int num_stages = (int) log2f(N_TIME);

cplx input[N_TIME];
cplx output[N_TIME];
`;

const fft_set_input_buffer = `
void setInputBuffer(uint16_t *src, uint16_t offset){
    for(uint16_t i = 0; i < N_TIME; ++i){
        input[i].re = (float)src[i + offset]* 2.0f / 4095.0f - 1.0f;
        input[i].im = 0.0f;
    }
}
`;

const fft_butterfly = `
void fft(){
    cplx temp, wq;
    uint16_t iQ, iL, iM, iA, iB;

    iL = 1;
    iM = N_TIME/2;

    for(uint16_t i = 0; i < num_stages; ++i){
        iQ = 0;

        for(uint16_t j = 0; j < iM; ++j){
            iA = j;

            if(iQ < N_TIME/2){
                wq.re = twiddle[iQ].re;
                wq.im = twiddle[iQ].im;
            }else{
                wq.re = -twiddle[iQ % (N_TIME/2)].re;
                wq.im = -twiddle[iQ % (N_TIME/2)].im;
            }

            for(uint16_t k = 0; k < iL; ++k){
                iB = iA + iM;

                temp.re = input[iA].re - input[iB].re;
                input[iA].re += input[iB].re;
                temp.im = input[iA].im - input[iB].im;
                input[iA].im += input[iB].im;

                input[iB].re = temp.re * wq.re - temp.im * wq.im;
                input[iB].im = temp.im * wq.re + temp.re * wq.im;

                iA += 2*iM;
            }
            iQ += iL;
        }
        iL *= 2;
        iM /= 2;
    }
    for(uint16_t i = 0; i < N_TIME; ++i){
        output[i].re = input[brev[i]].re;
        output[i].im = input[brev[i]].im;
    }
}
`;

const main_buffers = `
volatile uint16_t adc_buffer[2*N_TIME];
volatile uint8_t block_complete_flag = 0;
float result[N_FREQ];
`;

const main_dma_irq = `
void HAL_ADC_ConvHalfCpltCallback(ADC_HandleTypeDef* hadc){
    HAL_GPIO_WritePin(D0_GPIO_Port, D0_Pin, GPIO_PIN_SET);
    block_complete_flag = 1;
}

void HAL_ADC_ConvCpltCallback(ADC_HandleTypeDef* hadc){
    HAL_GPIO_WritePin(D0_GPIO_Port, D0_Pin, GPIO_PIN_RESET);
    block_complete_flag = 2;
}
`;

const main_function = `
HAL_ADC_Start_DMA(&hadc1, (uint16_t* )adc_buffer, 2*N_TIME);
HAL_TIM_Base_Start(&htim3);

while (1){
    if(block_complete_flag == 1){
        HAL_GPIO_WritePin(D1_GPIO_Port, D1_Pin, GPIO_PIN_SET);
        setInputBuffer((uint16_t*)adc_buffer, 0);
        fft();
        magnitude(result);
        block_complete_flag = 0;
        HAL_GPIO_WritePin(D1_GPIO_Port, D1_Pin, GPIO_PIN_RESET);
    }
    if(block_complete_flag == 2){
        HAL_GPIO_WritePin(D1_GPIO_Port, D1_Pin, GPIO_PIN_SET);
        setInputBuffer((uint16_t*)adc_buffer, (uint16_t)N_TIME);
        fft();
        magnitude(result);
        block_complete_flag = 0;
        HAL_GPIO_WritePin(D1_GPIO_Port, D1_Pin, GPIO_PIN_RESET);
    }
}
`;

function DSP_5(){
   return(
       <div className="em__post">
           <div className="em__post-title">
               <h1>The Radix-2 Fast Fourier Transform</h1>
           </div>
           <div className="em__post-section">
               <h3>Overview:</h3>
               <p>
                   In this tutorial, we will try to optimize the DFT algorithm from the previous tutorial.
                   We will look at the patterns of the twiddle factor.
                   We will also accelerate the FIFO handling with the use of DMA.
               </p>
           </div>

           <div className="em__post-section">
               <h3>Theory:</h3>
               <p>
                   The twiddle factor <ShowTex string="$W_{N}^{nk}$" /> is basically the unit circle centered at the origin of
                   the complex plain sampled in N points starting from the real axis in clockwise direction.
                   From this fact we can state the periodicity <ShowTex string="$$W_{N}^{nk + N/2} = -W_{N}^{nk}$$"/> and
                   symmetry <ShowTex string="$$W_{N}^{nk + N} = W_{N}^{nk}$$"/> properties.
                   It's easy to see that in case of an <ShowTex string="$N$"/> point DFT we only need to save <ShowTex string="$N/2$"/> twiddle factors.
                   E.g. if <ShowTex string="$N = 8$"/>: <ShowTex string="$W_{8}^{0}, W_{8}^{1}, W_{8}^{2}, W_{8}^{3}$"/> are saved.
                   <ShowTex string="$W_{8}^{4} = -W_{8}^{0}$"/>, <ShowTex string="$W_{8}^{5} = -W_{8}^{1}$"/>, ...,
                   <ShowTex string="$W_{8}^{8} = W_{8}^{0}$"/>, ...
                   Now onto the transform itself, let's split the sum into first and second half:
                   <ShowTex string="$$ X_N[k] = \sum_{n=0}^{N/2-1}x[n]W_N^{nk} + \sum_{n=N/2}^{N-1}x[n]W_N^{nk}. $$"/>
                   Change the variable in the second sum with <ShowTex string="$n \mapsto n + N/2$"/>:
                   <ShowTex string="$$ X_N[k] = \sum_{n=0}^{N/2-1}x[n]W_N^{nk} + \sum_{n=0}^{N/2-1}x[n+N/2]W_N^{(n+N/2)k}$$"/>
                   <ShowTex string="$$ X_N[k] = \sum_{n=0}^{N/2-1}x[n]W_N^{nk} + W_N^{Nk/2}\sum_{n=0}^{N/2-1}x[n+N/2]W_N^{nk}.$$"/>
                   We show using the Euler convention that <ShowTex string="$W_N^{Nk/2} = (-1)^k$"/>,
                   and the properties of the fractions <ShowTex string="$W_N^{2m} = W_{N/2}^{m}$"/> so for even
                   values <ShowTex string="k = 2p"/> we have
                   <ShowTex string="$$ X_N[2p] = \sum_{n=0}^{N/2-1}x[n]W_N^{2pn} + \sum_{n=0}^{N/2-1}x[n+N/2]W_N^{2pn} = \sum_{n=0}^{N/2-1}\Big(x[n] + x[n+N/2] \Big)W_{N/2}^{pn},$$"/>
                   and for odd values <ShowTex string="k = 2p + 1"/> we have
                   <ShowTex string="$$ X_N[2p+1] = \sum_{n=0}^{N/2-1}x[n]W_N^{(2p+1)n} - \sum_{n=0}^{N/2-1}x[n+N/2]W_N^{(2p+1)n} = \sum_{n=0}^{N/2-1}\Big(x[n] + x[n+N/2] \Big)W_{N/2}^{pn} W_N^n.$$"/>

                   This way we have defined the N-point DFT using two N/2-point DFTs. We can divide the inner terms further: Each N/2-point DFT can be calculated using N/2-point DFTs.
                   If N is a power of 2, then we can divide the whole operations into 2-point DFTs.
                   This creates <ShowTex string="$\log_2N$"/> stages, and it's called <b> Fast Fourier Transform</b> (FFT).
               </p>
               <p>
                   Let's check the algorithm for N = 2
                   <ShowTex string="$$ X_2[0] = x[0]W_2^0 + x[1]W_2^0 = x[0] + x[1] , \quad X_2[1] = x[0]W_2^0 + x[1]W_2^1 = x[0] - x[1].$$"/>
                   We will symbolise this transform with the following butterfly construction:
               </p>
               <img className="img_fft_butterfly_2_dsp-5" src={fft_butterfly_2} alt="fft_butterfly_2_dsp-5"/>
               <p>
                   Now, if N = 4, we have the terms
                   <ShowTex string="$$ X_4[0] = x[0]W_4^0 + x[1]W_4^0 + x[2]W_4^0 + x[3]W_4^0 = (x[0] + x[2]) + (x[1] + x[3]),$$"/>
                   <ShowTex string="$$ X_4[2] = x[0]W_4^0 + x[1]W_4^2 + x[2]W_4^4 + x[3]W_4^6 = (x[0] + x[2]) + (x[1] - x[3]),$$"/>
                   <ShowTex string="$$ X_4[1] = x[0]W_4^0 + x[1]W_4^1 + x[2]W_4^2 + x[3]W_4^3 = (x[0] - x[2]) + W^1_4(x[1] - x[3]),$$"/>
                   <ShowTex string="$$ X_4[3] = x[0]W_4^0 + x[1]W_4^3 + x[2]W_4^6 + x[3]W_4^9 = (x[0] - x[2]) - W^1_4(x[1] - x[3]).$$"/>
                   We will symbolise this transform with the following butterfly construction:
               </p>
               <img className="img_fft_butterfly_4_dsp-5" src={fft_butterfly_4} alt="fft_butterfly_4_dsp-5"/>
               <p>
                   Finally, if N = 8, the structure would look like:
               </p>
               <img className="img_fft_butterfly_8_dsp-5" src={fft_butterfly_8} alt="fft_butterfly_8_dsp-5"/>
               <p>
                   You can see that in this configuration, the FFT has a strangely ordered output.
                   If we want to keep the input samples in increasing order, the output will be produced in bit-reverse order.
                   Write the numbers from 0 to 7 in binary form, reverse the bit order and you have the output sequence.
                   Some DSP hardware know the bit-reverse addressing, but our ARM does not, so we'll use a LUT method for it.
               </p>
           </div>
           <div className="em__post-section">
               <h3>Firmware:</h3>
               <p>
                   In the DFT tutorial, we used sample-based signal processing, and we've created an input FIFO.
                   This time, we will switch to block-based signal processing - the ADC samples N times and it will transfer the data using DMA.
                   To further increase the speed, we will implement the double buffering technique - we will have a DMA width of 2N,
                   when N amount of data is ready, we will process it, meanwhile the ADC can fill up the next N data slots.
                   <br/>
                   We will only store half of the required twiddle values, and we will account for it using simple runtime logic.
               </p>
               <p>
                   Create a new project based on the one in the previous tutorial.
                   This time, add a circular DMA request to the ADC with half word data width and high priority.
                   Also, enable the DMA continuous request so that the transfer can be re-triggered.
               </p>
               <Popup trigger={<img className="img_mx_adc_1_dsp-5 clickable_img" src={mx_adc_1} alt="mx_adc_1_dsp-5"/>} modal nested>
                   {close => (
                       <img className="em__img_full" src={mx_adc_1} alt="mx_adc_1_dsp-5" />
                   )}
               </Popup>
               <Popup trigger={<img className="img_mx_adc_2_dsp-5 clickable_img" src={mx_adc_2} alt="mx_adc_2_dsp-5"/>} modal nested>
                   {close => (
                       <img className="em__img_full" src={mx_adc_2} alt="mx_adc_2_dsp-5" />
                   )}
               </Popup>
           </div>
           <div className="em__post-section">
               <p>
                   Create a header and source combo for the FFT.
                   The header is almost the same as the DFT header:
               </p>
               <div className="em_unselectable">
               <SyntaxHighlighter language="c" style={AtomOneDark}>
                   {fft_header}
               </SyntaxHighlighter>
               </div>
               <p>
                   The source file must contain the typedef of the complex struct just as before.
                   This time create a half width twiddle and full width bit-reverse LUT.
                   For N=32, this would look like:
               </p>
               <div className="em_unselectable">
               <SyntaxHighlighter language="c" style={AtomOneDark}>
                   {fft_lut}
               </SyntaxHighlighter>
               </div>
               <p>
                   We must specify the stages of the FFT.
                   Also, this time the result will reside in the main source.
               </p>
               <div className="em_unselectable">
               <SyntaxHighlighter language="c" style={AtomOneDark}>
                   {fft_buffer}
               </SyntaxHighlighter>
               </div>
               <p>
                   When a DMA transfer is completed, we can copy the result using the <i>setInputBuffer</i> function.
                   If the half-complete interrupt is triggered, we start the copy from location 0, otherwise from location N_TIME.
                   We will also normalise the data so that the dc bias can be removed.
               </p>
               <div className="em_unselectable">
               <SyntaxHighlighter language="c" style={AtomOneDark}>
                   {fft_set_input_buffer}
               </SyntaxHighlighter>
               </div>
               <p>
                   And here is the glorious FFT algorithm.
                   We have to handle the twiddle values for every index, since we've only stored half of them.
                   Then we have to implement the butterfly algorithm.
                   The first four operations in the last loop calculate the upper branch, while the lower ones give the lower branch value.
               </p>
               <div className="em_unselectable">
               <SyntaxHighlighter language="c" style={AtomOneDark}>
                   {fft_butterfly}
               </SyntaxHighlighter>
               </div>
               <p>
                   Before moving forward, try to understand this algorithm.
                   Check how the function works for N = 8.
               </p>
               <p>
                   In the main, program the signal generation part.
                   We need a buffer for the ADC that is twice the size of the FFT buffer.
                   the result will be saved into a global float array so that we can monitor it.
                   The flag this time will not only show the presence of new data, but which part of the buffer is filled.
               </p>
               <div className="em_unselectable">
               <SyntaxHighlighter language="c" style={AtomOneDark}>
                   {main_buffers}
               </SyntaxHighlighter>
               </div>
               <p>
                   The interrupt routines will set the flag so that the main loop can process the data.
                   I've also included some GPIO write operations for timing purposes.
               </p>
               <div className="em_unselectable">
               <SyntaxHighlighter language="c" style={AtomOneDark}>
                   {main_dma_irq}
               </SyntaxHighlighter>
               </div>
               <p>
                   After the start of signal generation, start the ADC in DMA mode, and also start the TIM3 for ADC trigger.
                   Then in the super-loop process the data.
               </p>
               <div className="em_unselectable">
               <SyntaxHighlighter language="c" style={AtomOneDark}>
                   {main_function}
               </SyntaxHighlighter>
               </div>
           </div>
           <div className="em__post-section">
               <h3>Data visualisation in the Monitor:</h3>
               <p>
                   I've hooked up a logic analyser to the digital output pins to measure the performance.
                   The first trace shows the acquisition time - the sampling frequency is 5kHz, and an interrupt is generated every 64th sample,
                   this means that the 12.8ms period is right on point.
               </p>
               <img className="img_fft_64_timing_dsp-5" src={fft_64_timing} alt="fft_64_timing_dsp-5"/>
               <p>
                   The execution time of the N=64 FFT is around the execution time of an N=32 DFT.
                   And while we are at it, we can generate a combined signal with our DAC generator:
               </p>
               <img className="img_fft_64_combined_monitor_dsp-5" src={fft_64_combined_monitor} alt="fft_64_combined_monitor_dsp-5"/>
               <p>
                   This monitor image shows a triple sine with frequencies: 80Hz, 470Hz, 1170 Hz.
                   The generated signal has components with equal amplitudes, but the RC anti-aliasing filter attenuates them a bit.
                   <br/>
                   Finally, I've hooked up a signal generator with sine wave sweep from 1Hz to 2500Hz (this time with the anti-aliasing filter disconnected):
               </p>
               <img className="img_chirp_fft_dsp-5" src={chirp_fft} alt="chirp_fft_dsp-5"/>
           </div>

           <div className="em__post-section">
               <h3>Further work:</h3>
               <p>
                   <ol>
                       <li>
                           Change the source code in such a way that it calculates the FFT for N = 1024.
                           What's the memory usage, what's the execution time?
                       </li>
                       <li>
                           Implement the IFFT function and test if it.
                       </li>
                       <li>
                           Transcribe the whole FFT source to use 31b fixed-point values in the range <ShowTex string="$[-1, 1)$"/>.
                           Compare the float and fixed FFT execution time for N = 1024.
                       </li>

                   </ol>
               </p>
           </div>


           <div className="em__post-navigation">

               <NavLink to="./../dsp-4">
                   <Button btnID={"leftBTN"} buttonSize="btn--medium"> Prev Post</Button>
               </NavLink>

               <NavLink to="./../dsp-6">
                   <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
               </NavLink>
           </div>
       </div>
   );
}

export default DSP_5;