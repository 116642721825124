import React from "react";
import "./mcu_hal14.css";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";

import spi_s_cube from "./spi_s_cube.jpg";

const spi_ms_defs = `
enum{
    TRANSFER_WAIT,
    TRANSFER_COMPLETE,
    TRANSFER_ERROR
};

#define SPI_ACK_BYTES       0xA5A5
#define SPI_NACK_BYTES      0xDEAD
#define SPI_TIMEOUT_MAX     0x1000
#define SPI_SLAVE_SYNBYTE   0x53
#define SPI_MASTER_SYNBYTE  0xAC


#define ADDR_CMD_MASTER_RD  ((uint16_t)0x1234)
#define ADDR_CMD_MASTER_WR  ((uint16_t)0x5678)
#define CMD_LEN             ((uint16_t)0x0004)
#define DAT_LEN             ((uint16_t)0x0020)
`;

const spi_s_sync = `
static void slaveSync(){
    uint8_t tx_ack_byte = SPI_SLAVE_SYNBYTE;
    uint8_t rx_ack_byte = 0x00;

    do{
        if(HAL_SPI_TransmitReceive(&hspi2, &tx_ack_byte, &rx_ack_byte, 1, HAL_MAX_DELAY) != HAL_OK)
            Error_Handler();
    }while(rx_ack_byte != SPI_MASTER_SYNBYTE);
}
`;

const spi_ms_flush = `
static void flushBuffer(uint8_t *pBuffer, uint16_t len){
    while(len--){
        *pBuffer = 0;
        pBuffer++;
    }
}
`;

const spi_ms_local = `
uint8_t spi_rx_buffer[DAT_LEN] = {0};

uint8_t spi_tx_master_buffer[] = "SPI - MASTER - Transmit message";
uint8_t spi_tx_slave_buffer[] = "SPI - SLAVE - Transmit message";

volatile uint8_t transfer_state_flag = TRANSFER_WAIT;
`;

const spi_s_var = `
uint16_t addr_cmd = 0;
uint16_t com_len = 0;
uint8_t paddr_cmd[CMD_LEN] = {0};
uint16_t ack_bytes = 0x0000;
`;

const spi_s_first_sync = `
slaveSync();
if(HAL_SPI_Receive_IT(&hspi2, paddr_cmd, CMD_LEN) != HAL_OK)
  Error_Handler();
while(HAL_SPI_GetState(&hspi2) != HAL_SPI_STATE_READY){}

addr_cmd = (uint16_t) ((paddr_cmd[0] << 8) | paddr_cmd[1]);
com_len = (uint16_t)((paddr_cmd[2] <<8) | paddr_cmd[3]);
`;

const spi_s_read = `
if(addr_cmd == ADDR_CMD_MASTER_RD || (addr_cmd == ADDR_CMD_MASTER_WR && com_len > 0)) {
  slaveSync();

  ack_bytes = SPI_ACK_BYTES;
  if (HAL_SPI_Transmit_IT(&hspi2, (uint8_t *) &ack_bytes, sizeof(ack_bytes)) != HAL_OK)
      Error_Handler();
  while (HAL_SPI_GetState(&hspi2) != HAL_SPI_STATE_READY) {}

  if (addr_cmd == ADDR_CMD_MASTER_RD) {
      slaveSync();
      if (HAL_SPI_Transmit_IT(&hspi2, spi_tx_slave_buffer, DAT_LEN) != HAL_OK)
          Error_Handler();
      while (HAL_SPI_GetState(&hspi2) != HAL_SPI_STATE_READY) {}

      slaveSync();

      ack_bytes = 0;
      if (HAL_SPI_Receive_IT(&hspi2, (uint8_t *) &ack_bytes, sizeof(ack_bytes)) != HAL_OK)
          Error_Handler();
      while (HAL_SPI_GetState(&hspi2) != HAL_SPI_STATE_READY) {}

      if (ack_bytes != SPI_ACK_BYTES)
          Error_Handler();

}
`;

const spi_s_error = `
slaveSync();

ack_bytes = SPI_NACK_BYTES;
if (HAL_SPI_Transmit_IT(&hspi2, (uint8_t *) &ack_bytes, sizeof(ack_bytes)) != HAL_OK)
  Error_Handler();
while (HAL_SPI_GetState(&hspi2) != HAL_SPI_STATE_READY) {}
Error_Handler();
`;

const spi_m_sync = `
static void masterSync(){
    uint8_t tx_ack_byte = SPI_MASTER_SYNBYTE;
    uint8_t rx_ack_byte = 0x00;

    do{
        if(HAL_SPI_TransmitReceive(&hspi2, &tx_ack_byte, &rx_ack_byte, 1, 1) != HAL_OK)
            Error_Handler();
        while(HAL_SPI_GetState(&hspi2) != HAL_SPI_STATE_READY){}
    }while(rx_ack_byte != SPI_SLAVE_SYNBYTE);
}
`;

const spi_m_read = `
masterSync();

addr_cmd[0] = (uint8_t) (ADDR_CMD_MASTER_RD >>8);
addr_cmd[1] = (uint8_t) (ADDR_CMD_MASTER_RD);
addr_cmd[2] = (uint8_t) (DAT_LEN >>8);
addr_cmd[3] = (uint8_t) (DAT_LEN);

if(HAL_SPI_Transmit_IT(&hspi2, addr_cmd, CMD_LEN) != HAL_OK)
  Error_Handler();
while(HAL_SPI_GetState(&hspi2) != HAL_SPI_STATE_READY){}

masterSync();

ack_bytes = 0;
if(HAL_SPI_Receive_IT(&hspi2, (uint8_t *) &ack_bytes, sizeof(ack_bytes))!=HAL_OK)
  Error_Handler();
while(HAL_SPI_GetState(&hspi2) != HAL_SPI_STATE_READY){}

if(ack_bytes == SPI_ACK_BYTES){
  masterSync();

  if(HAL_SPI_Receive_IT(&hspi2, spi_rx_buffer, DAT_LEN) != HAL_OK)
      Error_Handler();
  while(HAL_SPI_GetState(&hspi2) != HAL_SPI_STATE_READY){}

  masterSync();

  ack_bytes = SPI_ACK_BYTES;
  if(HAL_SPI_Transmit_IT(&hspi2, (uint8_t*) &ack_bytes, sizeof(ack_bytes)) != HAL_OK)
      Error_Handler();
  while(HAL_SPI_GetState(&hspi2) != HAL_SPI_STATE_READY){}
}else{
  Error_Handler();
}
`;

const spi_m_write = `
masterSync();

addr_cmd[0] = (uint8_t) (ADDR_CMD_MASTER_WR >>8);
addr_cmd[1] = (uint8_t) (ADDR_CMD_MASTER_WR);
addr_cmd[2] = (uint8_t) (DAT_LEN >>8);
addr_cmd[3] = (uint8_t) (DAT_LEN);

if(HAL_SPI_Transmit_IT(&hspi2, addr_cmd, CMD_LEN) != HAL_OK)
  Error_Handler();
while(HAL_SPI_GetState(&hspi2) != HAL_SPI_STATE_READY){}

masterSync();

if(ack_bytes == SPI_ACK_BYTES){
  masterSync();

  if(HAL_SPI_Transmit_IT(&hspi2, spi_tx_master_buffer, DAT_LEN) != HAL_OK)
      Error_Handler();
  while(HAL_SPI_GetState(&hspi2) != HAL_SPI_STATE_READY){}

  masterSync();

  ack_bytes = 0;
  if(HAL_SPI_Receive_IT(&hspi2, (uint8_t*) &ack_bytes, sizeof(ack_bytes))!= HAL_OK)
      Error_Handler();
  while(HAL_SPI_GetState(&hspi2) != HAL_SPI_STATE_READY){}
}else{
  Error_Handler();
}

flushBuffer(spi_rx_buffer, DAT_LEN);
HAL_GPIO_TogglePin(LD2_GPIO_Port, LD2_Pin);
HAL_Delay(100);
`;


function MCU_HAL14(){
    return(
        <div className="em__post">
            <div className="em__post-title">
                <h1>SPI master and slave</h1>
            </div>

            <div className="em__post-section">
                <h3>Aim of this tutorial:</h3>
                <p>
                    In this tutorial, we will use two NUCLEO boards to test out SPI communication.
                    One board configured as SPI master, where the transaction can be initiated with the push of a button.
                    Another board as SPI slave which listens and transmits to the master.

                </p>
            </div>

            <div className="em__post-section">
                <h3>Physical and logical setup:</h3>
                <p>
                    We will use two boards, so in order to ease the programming and debugging routine,
                    we will check the serial numbers of the on-board programmers, and use them latter in the configuration files,
                    similarly to <NavLink to="./../stm-hal-12">this</NavLink> tutorial.
                </p>
                <p>
                    The master board will supply the power, so connect pin 20 from CN7 from each board (common GND),
                    and pin 18 from CN7 (+5V Source from master board) to pin 22 from CN7 (Vin for slave board).
                    Also move the jumper on JP5 from the 4th position to the 3rd position to switch supplies.
                </p>
                <p>
                    We will use the same SPI connections as in the previous tutorial, so use 4 more wires,
                    and connect pins 30, 28, 26, 24 from CN10 on one of the boards to the same pins on CN10 from the other board.
                </p>
            </div>
            <div className="em__post-section">
                <h3>Project Setup (Slave):</h3>
                <p>
                    Create a new project in CLion using the default board setup with external oscillator.
                    Enable the SPI2 peripheral from the Connectivity category.
                    This time we will enable the hardware slave select line.
                    Also let's enable the SPI interrupts.
                </p>
                <Popup trigger={<img className="img_spi_s_cube_hal14 clickable_img" src={spi_s_cube} alt="spi_s_cube_hal14"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={spi_s_cube} alt="spi_s_cube_hal14" />
                    )}
                </Popup>
                <p>
                    Create the project and copy the cfg file.
                    Open the cfg, and paste the apropriate hla_serial number.
                    This way OpenOCD knows which board should be used.
                </p>
            </div>
            <div className="em__post-section">
                <h3>Firmware (Slave)</h3>
                <p>
                    Let's define some universal values:
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {spi_ms_defs}
                </SyntaxHighlighter>
                <p>
                    We will use the enum to track SPI transfer startes.
                    <i>TRANSFER_WAIT</i> when the transaction is in progress.
                    <i>TRANSFER_COMPLETE</i> and <i>TRANSFER_ERROR</i> when the transaction is done
                    or a transaction error occurs respectively.
                </p>
                <p>
                    We will implement a simple synchronizing/handshake method.
                    If the sync transaction is successful, the respective device acknowledges it (ACK),
                    otherwise it will send a not acknowledge (NACK) signal.
                    Further more, the master will perform read and write operations, so we need to define the operation commands.
                </p>
                <p>
                    Let's create three functions before we implement the transfer logic in the main.
                    The first function is the <i>BufferCMP</i>, which we used before in the I2C tutorial.
                    The second function is to clear the receive buffer:
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {spi_ms_flush}
                </SyntaxHighlighter>
                <p>
                    The last function is the sync function, where the slave sends the sync byte, and waits for the master sync byte:
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {spi_s_sync}
                </SyntaxHighlighter>
                <p>Next, we create some local variables for the transmit and receive buffers and the SPI state flag.</p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {spi_ms_local}
                </SyntaxHighlighter>
                <p>
                    If an error interrupt occurs, we set the state flag to <i>TRANSFER_ERROR</i>,
                    if a transfer (RX or TX) complete interrupt occurs, we set the state flag to <i>TRANSFER_COMPLETE</i>.
                    Now onto the main function:
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {spi_s_var}
                </SyntaxHighlighter>

                <p>
                    After the HAL sets up the components, we can start the data transfer.
                    The slave is in listening mode.
                    When a new data packet arrives, the slave parses the data to address and command length.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {spi_s_first_sync}
                </SyntaxHighlighter>
                <p>
                    If the master transferred a read command, the program enters the read sequence: sync handshake,
                    transfer of acknowledgement, data transmission to the master, another sync and another acknowledgement.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {spi_s_read}
                </SyntaxHighlighter>
                <p>
                    If the master transferred a write command, the program behaves similarly,
                    only this time the slave goes into a receiving state between the syncs.
                    After the end of the data transfer,
                    we can use the comparator function to check if every byte arrived successfully.
                </p>
                <p>
                    If the slave receives a command other than the defined read or write ones,
                    it will transfer the NACK flag, and it will go into error mode.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {spi_s_error}
                </SyntaxHighlighter>
                <p>
                    If everything went well, the program should build without errors.
                    You could write the whole main logic as a one-time routine, or inside the infinite loop.
                    Either way, after the firmware is loaded onto the mcu, the device will enter into listening mode,
                    and nothing will happen visually.
                </p>

            </div>
            <div className="em__post-section">
                <h3>Project Setup (Master):</h3>
                <p>
                    The setup for the master is pretty similar to the setup of the slave.
                    Create a new project in CLion using the default board setup with external oscillator.
                    Enable the SPI2 from the Connectivity category.
                    Use the same speed as in the case of the slave, and enable interrupts.
                    Finally, set the slave select signal to hardware output.
                </p>
                <p>
                    Remember that the SS is open drain, so go to System Core/GPIO/SPI and enable the pull-up resistor for the SS pin.
                </p>
            </div>
            <div className="em__post-section">
                <h3>Firmware (master):</h3>
                <p>
                    The defines, private variables and functions are all the same as in the case of the slave except the sync function,
                    where the sync bytes are reversed.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {spi_m_sync}
                </SyntaxHighlighter>
                <p>
                    After the HALL setup is complete, wait for a button event.
                    If a button is pushed, the master starts the reading sequence with sync.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {spi_m_read}
                </SyntaxHighlighter>
                <p>
                    The command and transfer length are both pares into 8b chunks, sent to the slave.
                    If the slave acknowledges the command, the master can receive the data and acknowledge the received data.
                    Finally we could use the buffer compare function to check the validity of the received data.
                </p>
                <p>
                    The transmit sequence is done similarly to the receive one.
                    The master tells the slave that it wants to transmit data, the slave sends the acknowledge signal,
                    then the master starts the data transmission.
                    Finally we can clear the receive buffer, ant toggle an LED for a completed RX/TX sequence.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {spi_m_write}
                </SyntaxHighlighter>
            </div>
            <div className="em__post-section">
                <h3>The test:</h3>
                <p>
                    After everything is connected and programmed, we can start the test. The boards are configured:
                </p>
                <ul>
                    <li>The slave is waiting for data, the master is waiting for a button event. Push the button.</li>
                    <li>If the data transmission is successful, the LEDs should turn off for a brief moment
                        (if the program is done in the main loop, then the LED will flash rapidly).</li>
                    <li>If there was any kind of error, the LED will blink with a period of 2s.</li>
                </ul>
                <p>
                    The source files for this tutorial can be found in <a href="https://gitlab.com/stm32_mcu_group/stm32_hal/14_spi_master_slave.git">this</a> repo.
                </p>
            </div>





            <div className="em__post-navigation">

                <NavLink to="./../stm-hal-13">
                    <Button btnID={"leftBTN"} buttonSize="btn--medium"> Previous Post</Button>
                </NavLink>

                <NavLink to="./../stm-hal-15">
                    <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
                </NavLink>
            </div>
        </div>
    );
}

export default MCU_HAL14;