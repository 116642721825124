import React from "react";
import "./../hobby.css";
import "./../collection.css";
import {Tag} from "../../../common";
import {useNavigate} from "react-router-dom";
import {useRef} from "react";


function GREENHUB(){
    const navigate = useNavigate();
    const saemidoriGyokuroRef = useRef(null);
    const jadeSwordRef = useRef(null);
    const imperialGreenRef = useRef(null);
    const EarlySpringSilverStrandsRef = useRef(null);
    const hoshinoKukichaRef= useRef(null);
    const xiangChaRef = useRef(null);
    const shizuokaGurichaRef = useRef(null);
    const hakkaLuchaRef = useRef(null);
    const longJingRef = useRef(null);
    const zhuYeChingRef = useRef(null);
    const cloudLakeRef = useRef(null);
    const fukamushiSaefariRef = useRef(null);
    const greenCoilRef = useRef(null);
    const gyokuroChaMusumeRef = useRef(null);
    const gyokuroKariganeRef = useRef(null);
    const gyokuroSasaHimeRef = useRef(null);
    const hentaOkumidoriSenchaRef = useRef(null);
    const hentaSaemidoriSenchaRef = useRef(null);
    const issinKamairichaRef = useRef(null);
    const issinKukiChaRef = useRef(null);
    const kasugaenSenchaRef = useRef(null);
    const kasugaenMurasakiRef = useRef(null);
    const kukichaOsadaRef = useRef(null);
    const masudaenBanchaRef = useRef(null);
    const mohanaSenchaRef = useRef(null);
    const moikeHojichaRef = useRef(null);
    const nurukiShinchaRef = useRef(null);
    const senchaIsagawaRef = useRef(null);
    const yamagaNoSatoRef = useRef(null);

    const tealist = [
        {name:"1. Saemidori Gyokuro (ML)", ref:saemidoriGyokuroRef},
        {name:"2. Jade Sword - Pre Qing Ming (ML)", ref:jadeSwordRef},
        {name:"3. Imperial Green - Pre Qing Ming (ML)", ref:imperialGreenRef},
        {name:"4. Hoshino Kukicha (NNS)", ref:hoshinoKukichaRef},
        {name:"5. Xiang cha (NNS)", ref:xiangChaRef},
        {name:"6. Shizuoka Guricha (NNS)", ref:shizuokaGurichaRef},
        {name:"7. Hakka Lü cha (NNS)", ref:hakkaLuchaRef},
        {name:"8. Long Jing (NNS)", ref:longJingRef},
        {name:"9. Zhu ye qing (NNS)", ref:zhuYeChingRef},
        {name:"10. Cloud Lake (ML)", ref:cloudLakeRef},
        {name:"11. Fukamushi Saekari sencha (ML)", ref:fukamushiSaefariRef},
        {name:"12. Green coil - Pre Qing Ming (ML)", ref:greenCoilRef},
        {name:"13. Gyokuro Cha Musume (NT)", ref:gyokuroChaMusumeRef},
        {name:"14. Gyokuro Karigane (NT)", ref:gyokuroKariganeRef},
        {name:"15. Gyokuro Sasa Hime (NT)", ref:gyokuroSasaHimeRef},
        {name:"16. Henta Okumidori Sencha (NT)", ref:hentaOkumidoriSenchaRef},
        {name:"17. Henta Saemidori Sencha (NT)", ref:hentaSaemidoriSenchaRef},
        {name:"18. Issin-en kamairicha (NT)", ref:issinKamairichaRef},
        {name:"19. Issin-en kuki houjicha (NT)", ref:issinKukiChaRef},
        {name:"20. Kasugaen golden sencha (NT)", ref:kasugaenSenchaRef},
        {name:"21. Kasugaen murasaki sencha (NT)", ref:kasugaenMurasakiRef},
        {name:"22. Kukicha Osada (NT)", ref:kukichaOsadaRef},
        {name:"23. Masudaen bancha (NT)", ref:masudaenBanchaRef},
        {name:"24. Nohana sencha (NT)", ref:mohanaSenchaRef},
        {name:"25. Noike hojicha (NT)", ref:moikeHojichaRef},
        {name:"26. Nuruki shincha (NT)", ref:nurukiShinchaRef},
        {name:"27. Sencha Isagawa (NT)", ref:senchaIsagawaRef},
        {name:"28. Yamaga no Sato (NT)", ref:yamagaNoSatoRef},
        {name:"29. Early Spring Silver Strands (YS)", ref:EarlySpringSilverStrandsRef},

    ];


    return (
        <div className="em__post">
            <div className="em__post-section">
                <h3>About the history and production of green teas</h3>
                <p>
                    Green tea has been a beloved beverage for centuries with its vibrant colour, delicate aroma, and health benefits.
                    Originating in ancient China (or at least in the area where China is now),
                    green tea has a rich history intertwined with cultural significance and a meticulous production process that sets it apart from other teas.
                    In this exploration, we embark on a fascinating journey through time, uncovering the origins and intricacies of green tea production.
                    <br/>
                    Green tea's story begins over 4,000 years ago in China,
                    where legend has it that Emperor Shen Nong discovered tea when tea leaves accidentally fell into his boiling water.
                    Recent findings suggest that people used tea leaves before this as soups or stews.
                    The early tea consumption was primarily in the form of compressed tea bricks.
                    Over time, the process evolved, leading to the creation of loose-leaf green tea as we know it today.
                    <br/>
                    Green tea holds immense cultural importance in various societies, particularly China and Japan.
                    In China, it is an integral part of traditional medicine and philosophy, while in Japan,
                    the Japanese tea ceremony (Chanoyu or Sado) elevates the preparation and consumption of green tea to an art form,
                    emphasizing harmony, respect, purity, and tranquillity.
                </p>
                <p>
                    The process is as follows: plucking young buds and leaves, withering, fixation, rolling and finally drying.
                    The production process emphasizes no oxidation, preserving the green and nutty flavours of the fresh tea.
                    Here are the key steps involved:
                </p>
                <ul>
                    <li>
                        <b>Plucking:</b>
                        The production of green tea begins with the careful harvesting of tender tea leaves and buds.
                        The best green teas are often plucked in the early spring when the leaves are young and contain the highest concentration of flavour compounds.
                    </li>
                    <li>
                        <b>Withering:</b>
                        After harvesting, the leaves undergo withering, a process where they are spread out to wilt slightly.
                        This reduces moisture content and initiates enzymatic reactions, which are crucial for the development of flavour.
                    </li>
                    <li>
                        <b>Fixation (Kill green):</b>
                        To halt the oxidation process and retain the vibrant green colour,
                        the leaves are subjected to high temperatures through steaming (common in Japan), pan-firing (common in China), or even baking.
                        This step, known as fixation or kill green, preserves the tea's freshness and natural antioxidants.
                    </li>
                    <li>
                        <b>Rolling:</b>
                        Following fixation, the leaves may undergo rolling, shaping, or curling, depending on the tea type and regional traditions.
                        This step helps form the leaves' distinct appearance and influences the tea's flavour.
                    </li>
                    <li>
                        <b>Drying:</b>
                        The final step involves drying the tea to remove any remaining moisture.
                        This is often done by firing the leaves in large ovens or using hot air.
                        The drying process further stabilizes the tea and enhances its shelf life.
                    </li>
                </ul>
                <p>
                    Green tea comes in various styles, each with its unique characteristics. Some notable varieties include:
                </p>
                <ol>
                    <li>
                        <b>Anji White Tea (Anji Bai Cha):</b>
                        Contrary to its name, Anji Bai Cha is a green tea, but it's plucked early on,
                        when the leaves have a minimal amount of chlorophyll, thus making them more pale.
                        It's a relatively new tea with sweet, green and nutty flavours.
                    </li>
                    <li>
                        <b>Matcha:</b>
                        Japanese shaded tea that is finely ground with a deep green colour,
                        vegetal taste is often accompanied by astringency that transforms into long-lasting sweetness.
                    </li>
                    <li>
                        <b>Fukamushi Sencha:</b>
                        It's a type of shaded Japanese green tea that is heavily steamed. So much so that the leaves partially break.
                    </li>
                    <li>
                        <b>Jasmine Pearl tea (Shou Mei):</b>
                        This is a type of Chinese green tea that is infused with jasmine aroma and then rolled into small balls.
                        The lower quality teas often include jasmine petals mixed amongst the tea leaves.
                        Higher-grade teas are scented by placing the flowers on the surface of the tea multiple times and removing the flowers after each scenting step.
                    </li>
                </ol>
                <p>
                    When the tea dries out, it's ready for consumption.
                    Since green tea is so delicate, most of the time, it's not meant for ageing,
                    and it's recommended to consume it within a year of its production (half a year, without proper storage conditions).
                </p>
            </div>

            <div className="em__collection">
                <div className="em__collection-title">
                    <h1 className="gradient__text">Sampled white teas:</h1>
                </div>

                <div className="em__collection-list">
                    {
                        tealist.map((item) => (
                            <div >
                                <Tag title={item.name} description={item.description} onClick={() => item.ref.current.scrollIntoView({ behavior: 'smooth' })}/>
                            </div>
                        ))
                    }
                </div>

                <div className="em__post-section">
                    <div id="1. Saemidori Gyokuro (ML)" ref={saemidoriGyokuroRef}>
                        <h3>1. Saemidori Gyokuro (ML)</h3>
                        <p>
                            Mengding Huang Cha is a beautiful and delicate yellow tea, a slight derivation from the usual single bud; this version has one bud and two leaves, making it unique.
                            Harvested in April 2018 from Mingshan Ya'an Sichuan.
                            <br/>
                            The dried leaves smelled like roasted pumpkin seeds, barley and honey.
                            After a quick rinse, the tea smelled like spinach and tamarind paste.
                            The tea soup was pale yellow, thick and brothy.
                            It tasted like sweet corn, candy floss and avocado puree.
                            The aftertaste was slightly floral with juicy sweetness.
                            I used 3g of leaf in 100ml gaiwan, with 85C water. On average, I could brew seven times.
                            <br/>
                            Green/Vegtal: 7/10, Floral: 7/10, Fruits: 4/10, Spices: 4/10, Wood/Earth: 1/10, Roast/Nuts: 3/10, Cream: 5/10, Mineral: 2/10.
                        </p>
                    </div>
                    <div id="2. Jade Sword - Pre Qing Ming (ML)" ref={jadeSwordRef}>
                        <h3>2. Jade Sword - Pre Qing Ming (ML)</h3>
                        <p>
                            It's an Anji Bai Cha from the Bai Ye Hao cultivar harvested in March from Anji, Zhejiang.
                            This variety does not produce as much chlorophyll as others at low temperatures, and therefore
                            If farmers pick in very early spring (end of March), they have buds and leaves that are pale lime green and yellow.
                            If the leaves are picked later in April or May (when the temperature is above 25 Celsius), then the leaves have more chlorophyll, and the flavour profile is compromised.
                            This makes 2nd grade Anji Bai Cha.
                            <br/>
                            Steamed spinach has a cherry taste with a hint of broth and astringency.
                            The tea has a sweet aftertaste, which becomes dominant with consecutive brewing.
                            <br/>
                            Green/Vegtal: 7/10, Floral: 6/10, Fruits: 5/10, Spices: 4/10, Wood/Earth: 1/10, Roast/Nuts: 8/10, Cream: 5/10, Mineral: 5/10.
                        </p>
                    </div>
                    <div id="3. Imperial Green - Pre Qing Ming (ML)" ref={imperialGreenRef}>
                        <h3>2. Imperial Green - Pre Qing Ming (ML)</h3>
                        <p>
                            First flush, pre-qing-ming Long Jing picked on March from 40-year-old bushes of the small leaf variety.
                            <br/>
                            Smooth blend of pistachios, mung bean and spinach with a sweet aftertaste.
                            There is a hint of astringency and flowery notes. The perfect tea to enjoy in the morning.
                            <br/>
                            Green/Vegtal: 6/10, Floral: 6/10, Fruits: 4/10, Spices: 5/10, Wood/Earth: 2/10, Roast/Nuts: 8/10, Cream: 7/10, Mineral: 5/10.
                        </p>
                    </div>
                    <div id="4. Hoshino Kukicha (NNS)" ref={hoshinoKukichaRef}>
                        <h3>4. Hoshino Kukicha (NNS)</h3>
                        <p>
                            Kukicha, or stem tea, represents a category of its own, considering the time-consuming tasks of sorting and sieving the harvest.
                            Kukicha is composed more of the stalks, stems, and more prominent leaf veins of the tea plant than the actual leaves,
                            giving it a milder and sweeter flavour than other Japanese greens like Sencha or Bancha.

                            This tea was harvested in Hoshino, Yame, Fukuoka.

                            <br/>
                            This tea tastes like green peas, seaweed broth, and boiled chestnut with a cooling, minty aftertaste.
                            <br/>
                            Green/Vegtal: 8/10, Floral: 0/10, Fruits: 0/10, Spices: 4/10, Wood/Earth: 0/10, Roast/Nuts: 4/10, Cream: 0/10, Mineral: 5/10.
                        </p>
                    </div>
                    <div id="5. Xiang cha (NNS)" ref={xiangChaRef}>
                        <h3>5. Xiang cha (NNS)</h3>
                        <p>
                            An obscure green tea from the east of China, the thin curls of these dark leaves open to expose a distinctly savoury character.
                            With a slight grassiness and juicy mouthfeel, it resembles a sencha before a walnut note confirms its roasting, exploding in a wave of spinach and bouillon umami.
                            <br/>
                            The dry leaves smelled like stevia, fresh grass and spinach.
                            The wet leaves smelled like mung bean soup and Brussels sprouts.
                            The tea soup was medium and brothy with a pearl green colour and lime tinge.
                            The tea tastes savoury and sweet, with steamed leafy greens, stevia sweetener and seawater.
                            With 3g of leaf in 100ml gaiwan and 80C water, I could brew eight infusion on average.
                            <br/>
                            Green/Vegtal: 8/10, Floral: 2/10, Fruits: 3/10, Spices: 2/10, Wood/Earth: 1/10, Roast/Nuts: 2/10, Cream: 1/10, Mineral: 4/10.
                        </p>
                    </div>
                    <div id="6. Shizuoka Guricha (NNS)" ref={shizuokaGurichaRef}>
                        <h3>6. Shizuoka Guricha (NNS)</h3>
                        <p>
                            This steamed Japanese green tea is considered a type of tamaryokucha, somewhat resembling sencha but somewhat rarer.
                            It is differentiated first by the lack of a final kneading step, which leaves the leaves in their namesake curls rather than sencha's glossy needles.
                            This particular rendition is still unique because it uses the Benifuuki cultivar, a hybrid developed initially for use in black teas.
                            <br/>
                            The dry leaves smelled like stevia and seaweed stock.
                            The wet leaves smelled like steamed spinach and wild berries.
                            The tea soup was thick and brothy with a lime juice green colour.
                            The tea tastes like vegetable stock, steamed greens, lime zest and butter.
                            With 3g of leaf in 100ml gaiwan and 80C water, I could brew five infusion on average.
                            <br/>
                            Green/Vegtal: 9/10, Floral: 6/10, Fruits: 7/10, Spices: 1/10, Wood/Earth: 0/10, Roast/Nuts: 3/10, Cream: 5/10, Mineral: 3/10.
                        </p>
                    </div>
                    <div id="7. Hakka Lü cha (NNS)" ref={hakkaLuchaRef}>
                        <h3>7. Hakka Lü cha (NNS)</h3>
                        <p>
                            Rather than being consumed as fresh as possible, the style of wok-roasted green tea that the Hakka minority produces is
                            usually left to age and develop a characteristic toasted rice aroma.
                            This tea, produced from a local variety of Shui Xian, has already been stored for one year and could certainly develop further.
                            The tea originates from Meizhou, Guangdong.
                            <br/>
                            The tea tastes like toasted rice with a hint of butter. It has some pineapple and lavender notes and an underlying astringency.
                            <br/>
                            Green/Vegtal: 6/10, Floral: 6/10, Fruits: 6/10, Spices: 0/10, Wood/Earth: 0/10, Roast/Nuts: 5/10, Cream: 0/10, Mineral: 2/10.
                        </p>
                    </div>
                    <div id="8. Long Jing (NNS)" ref={longJingRef}>
                        <h3>8. Long Jing (NNS)</h3>
                        <p>
                            The distinctive, flattened buds of Long Jing, often known as Dragonwell, are renowned for their chestnut flavour,
                            A medium roasting has brought this to the fore here, along with a green coffee pungency and the hearty vegetality of fiddlehead fern.
                            Sourced from Zhejiang near the beautiful Thousand Island Lake, the softening leaves floating in a glass pitcher may suggest nothing so
                            much as the vibrant colours and unusual forms of this neighbouring landscape, while its rich aromas waft toward the nose, infusion after infusion.
                            <br/>
                            The dry leaves smelled like toasted edamame and salty almonds.
                            The wet leaves smelled like steamed corn, herbal broth and boiled peanuts.
                            The tea soup was light and soft with a bright citrine colour.
                            The tea tastes like vegetable broth and unsweetened adzuki paste.
                            With 3g of leaf in 100ml gaiwan and 80C water, I could brew six infusion on average.
                            <br/>
                            Green/Vegtal: 9/10, Floral: 3/10, Fruits: 2/10, Spices: 1/10, Wood/Earth: 1/10, Roast/Nuts: 8/10, Cream: 4/10, Mineral: 4/10.
                        </p>
                    </div>
                    <div id="9. Zhu ye qing (NNS)" ref={zhuYeChingRef}>
                        <h3>9. Zhu ye qing (NNS)</h3>
                        <p>
                            Derived from the same cultivar as fellow Sichuan tea Ganlu, the deep and bright green leaves are somewhat flattened,
                            having been placed in a wok and roasted as they are pressed into the side, losing their trichomes and becoming glossier and slightly browned.
                            <br/>
                            The dry leaves smelled like steamed asparagus and porridge.
                            The wet leaves smelled like roasted and salted pistachios.
                            The tea soup looked like bright pomelo juice and was medium thick and brothy.
                            The tea tastes like green peas, sour apple, and kale and has a slightly floral, juicy, and sweet aftertaste.
                            With 3g of leaf in 100ml gaiwan and 80C water, I could brew 6-7 infusion on average.
                            <br/>
                            Green/Vegtal: 8/10, Floral: 5/10, Fruits: 3/10, Spices: 1/10, Wood/Earth: 1/10, Roast/Nuts: 4/10, Cream: 5/10, Mineral: 5/10.
                        </p>
                    </div>
                    <div id="10. Cloud Lake (ML)" ref={cloudLakeRef}>
                        <h3>10. Cloud Lake (ML)</h3>
                        <p>
                            Yun Wu green tea was harvested in April from Lishui, Zhejiang.
                            The look of the dry leaves gives the impression of a standard average-quality Green tea.
                            But it rivals many teas multiple times in terms of price when brewed.
                            <br/>
                            The tea is like spring in a cup.
                            Freshly cut grass, steamed baby spinach, magnolia, lemon zest.
                            It leaves a long, sweet, and zesty aftertaste.
                            <br/>
                            Green/Vegtal: 9/10, Floral: 4/10, Fruits: 7/10, Spices: 2/10, Wood/Earth: 1/10, Roast/Nuts: 5/10, Cream: 4/10, Mineral: 5/10.
                        </p>
                    </div>
                    <div id="11. Fukamushi Saekari sencha (ML)" ref={fukamushiSaefariRef}>
                        <h3>11. Fukamushi Saekari sencha (ML)</h3>
                        <p>
                            Fukamushi-style sencha is steamed for longer (up to a minute) during the kill-green process, which makes the tea thicker in texture and smoother in taste.
                            The more prolonged steaming means the leaf becomes more fragile and breaks up during the rolling phase.
                            While this may not look great in the dry leaf, it produces a thick, cloudy, luminously vibrant green tea that will wow your senses.

                            This tea was harvested in April from Kirishima, Kagoshima, Japan.
                            This tea style is in my top three for green tea.
                            <br/>
                            The tea smells like artichokes, shortbread and cereal milk.
                            The brew is thick and sticky with cloudy but luminous green - drink quickly because it becomes yellow.
                            The tea soup is full of umami, spinach, artichoke flavour and hints of cherry and rhubarb.
                            I brew 4g of leaf in a 100ml gaiwan using 70C water, lasting around five brews.
                            <br/>
                            Green/Vegtal: 10/10, Floral: 4/10, Fruits: 5/10, Spices: 2/10, Wood/Earth: 0/10, Roast/Nuts: 2/10, Cream: 3/10, Mineral: 6/10.
                        </p>
                    </div>
                    <div id="12. Green coil - Pre Qing Ming (ML)" ref={greenCoilRef}>
                        <h3>12. Green coil - Pre Qing Ming (ML)</h3>
                        <p>
                            Suzhou Bi Luo Chun is a famous Chinese tea along Long Jing.
                            This tea was picked in the Dong Shan region of Dong Ting mountains in Jiangsu.
                            It's one of my three top favourite green teas.
                            <br/>
                            The tea smelled like buttered spinach, cured ham and chestnuts.
                            The brew was thick and brothy with a light lime green colour.
                            Fresh grass, butter-baked spinach, kale.
                            A hint of savoriness and caramel.
                            No bitter flavours, lightly astringent.
                            The perfect tea to start the day with.
                            <br/>
                            Green/Vegtal: 8/10, Floral: 6/10, Fruits: 7/10, Spices: 4/10, Wood/Earth: 0/10, Roast/Nuts: 3/10, Cream: 4/10, Mineral: 7/10.
                        </p>
                    </div>
                    <div id="13. Gyokuro Cha Musume (NT)" ref={gyokuroChaMusumeRef}>
                        <h3>13. Gyokuro Cha Musume (NT)</h3>
                        <p>
                            This tea is grown on a small farm in Kagoshima, Japan.
                            It is shaded for three weeks before harvest to boost the levels of theanine, caffeine and chlorophyll.
                            Gyokuro teas are one of my top three green tea types.
                            <br/>
                            The tea was pale yellow-green; the texture was thick and brothy.
                            Strong and rich umami, steamed leaves, cut grass, hazelnut, spinach.
                            Sweet aftertaste, smooth finish.
                            I used 5g tea leaves in a 120ml shiboridashi and 60C water.
                            On average, I could make 4-5 brews, and at the end, I had a nice snack with a dash of soy sauce and a few lemon juice drops.
                            <br/>
                            Green/Vegtal: 10/10, Floral: 0/10, Fruits: 1/10, Spices: 2/10, Wood/Earth: 0/10, Roast/Nuts: 3/10, Cream: 1/10, Mineral: 5/10.
                        </p>
                    </div>
                    <div id="14. Gyokuro Karigane (NT)" ref={gyokuroKariganeRef}>
                        <h3>14. Gyokuro Karigane (NT)</h3>
                        <p>
                            Karigane is a stem tea made from the stems and leaves of Gyokuro, Tencha and Kabusecha.
                            In this case, the stems of high-quality gyokuro were removed.
                            It has a lower price, but the quality of the tea is by no means lower.
                            <br/>
                            The early infusions have steamed asparagus, butter-fried spinach and a rich umami taste, while the latter infusions are floral and sweet.
                            Full-bodied and rich tea. 3 - 5 infusions in a 120ml shiboridashi with 70C water.
                            <br/>
                            Green/Vegtal: 9/10, Floral: 6/10, Fruits: 0/10, Spices: 1/10, Wood/Earth: 0/10, Roast/Nuts: 1/10, Cream: 1/10, Mineral: 5/10.
                        </p>
                    </div>
                    <div id="15. Gyokuro Sasa Hime (NT)" ref={gyokuroSasaHimeRef}>
                        <h3>15. Gyokuro Sasa Hime (NT)</h3>
                        <p>
                            This gyokuro is a tea blend made in Kagoshima, Japan, after picking young, three-week shaded leaves.
                            <br/>
                            The tea soup is clean and brothy.
                            It tastes like spinach, hazelnut, and sea breeze.
                            It has a robust umami taste with a Smooth and sweet aftertaste.
                            <br/>
                            Green/Vegtal: 8/10, Floral: 5/10, Fruits: 0/10, Spices: 2/10, Wood/Earth: 0/10, Roast/Nuts: 4/10, Cream: 2/10, Mineral: 5/10.
                        </p>
                    </div>
                    <div id="16. Henta Okumidori Sencha (NT)" ref={hentaOkumidoriSenchaRef}>
                        <h3>16. Henta Okumidori Sencha (NT)</h3>
                    </div>
                    <div id="17. Henta Saemidori Sencha (NT)" ref={hentaSaemidoriSenchaRef}>
                        <h3>17. Henta Saemidori Sencha (NT)</h3>
                    </div>
                    <div id="18. Issin-en kamairicha (NT)" ref={issinKamairichaRef}>
                        <h3>18. Issin-en kamairicha (NT)</h3>
                    </div>
                    <div id="19. Issin-en kuki houjicha (NT)" ref={issinKukiChaRef}>
                        <h3>19. Issin-en kuki houjicha (NT)</h3>
                    </div>
                    <div id="20. Kasugaen golden sencha (NT)" ref={kasugaenSenchaRef}>
                        <h3>20. Kasugaen golden sencha (NT)</h3>
                    </div>
                    <div id="21. Kasugaen murasaki sencha (NT)" ref={kasugaenMurasakiRef}>
                        <h3>21. Kasugaen murasaki sencha (NT)</h3>
                    </div>
                    <div id="22. Kukicha Osada (NT)" ref={kukichaOsadaRef}>
                        <h3>22. Kukicha Osada (NT)</h3>
                    </div>
                    <div id="23. Masudaen bancha (NT)" ref={masudaenBanchaRef}>
                        <h3>23. Masudaen bancha (NT)</h3>

                    </div>
                    <div id="24. Nohana sencha (NT)" ref={mohanaSenchaRef}>
                        <h3>24. Nohana sencha (NT)</h3>
                    </div>
                    <div id="25. Noike hojicha (NT)" ref={moikeHojichaRef}>
                        <h3>25. Noike hojicha (NT)</h3>
                    </div>
                    <div id="26. Nuruki shincha (NT)" ref={nurukiShinchaRef}>
                        <h3>26. Nuruki shincha (NT)</h3>
                    </div>
                    <div id="27. Sencha Isagawa (NT)" ref={senchaIsagawaRef}>
                        <h3>27. Sencha Isagawa (NT)</h3>
                    </div>
                    <div id="28. Yamaga no Sato (NT)" ref={yamagaNoSatoRef}>
                        <h3>28. Yamaga no Sato (NT)</h3>
                    </div>
                    <div id="29. Early Spring Silver Strands (YS)" ref={EarlySpringSilverStrandsRef}>
                        <h3>3. Early Spring Silver Strands (YS)</h3>
                        <p>
                            A very fragrant and pungent green tea of Simao that can be infused many times.
                            Has the robust characteristics of Camellia Sinensis var.
                            Assamica with a potent aftertaste that lingers in the back of the mouth.
                            It's the Yin Si varietal tea grown near Wangzi Mountain in Ning'er County of Simao, China.
                            <br/>
                            The dry leaves smelled like kale cream, blanched spinach, and steamed asparagus.
                            The wet leaves smelled like corn, steamed Brussels sprouts and toasted pumpkin seeds.
                            The tea soup had a pale melon-green colour; it was soft, light and brothy.
                            The tea tastes like mushroom soup, sweet and savoury, with a hint of green melons and nuts.
                            With 4.5g of leaf in a 100ml gaiwan and 75C water, on average, I could brew seven infusions.
                            <br/>
                            Green/Vegtal: 10/10, Floral: 2/10, Fruits: 1/10, Spices: 2/10, Wood/Earth: 0/10, Roast/Nuts: 8/10, Cream: 1/10, Mineral: 4/10.
                        </p>
                    </div>
                </div>



            </div>
        </div>
    );
}

export default GREENHUB;
