import React from "react";
import "./mcu_hal19.css";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";

import dac_dma_mx from "./dac_dma_mx.jpg";
import dac_rng_mx from "./dac_rng_mx.jpg";
import dac_tri_mx from "./dac_tri_mx.jpg";
import tmr_dma_mx from "./tmr_dma_mx.jpg";
import tmr_rng_mx from "./tmr_rng_mx.jpg";
import tmr_tri_mx from "./tmr_tri_mx.jpg";
import tri_scope from "./tri_scope.jpg";
import sin_scope from "./sin_scope.jpg";
import rng_scope from "./rng_scope.jpg";
import lfsr from "./lfsr_pic.jpg";

const dma_dac = `
HAL_DAC_Start_DMA(&hdac1, DAC_CHANNEL_1, dac_buffer, 128, DAC_ALIGN_12B_R);
HAL_TIM_Base_Start(&htim6);
`;


function MCU_HAL19(){
    return(
        <div className="em__post">
            <div className="em__post-title">
                <h1>DAC regular channel</h1>
            </div>

            <div className="em__post-section">
                <h3>Aim of this tutorial:</h3>
                <p>
                    In this tutorial we will set a basic timer to trigger the DAC in order to create a simple signal generator
                    without loading the processor with DAC instructions.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Triangle wave generation:</h3>
                <p>
                    It is possible to add a small-amplitude triangular waveform on a DC or slowly varying signal.
                    This signal can be used together with a comparator to get a fast analog voltage level check or to create an
                    analog PWM signal.
                </p>
                <p>
                    The aim in this section is to create a triangle wave of full level (0V - 3.3V) with a frequency of 5Hz.
                </p>
                <h4>Project Setup:</h4>
                <p>
                    Create a new project with the usual settings.
                    This time set the HCLK to 160MHz so that we can have exact clock divisions.
                    Go to Analog/DAC1 and connect OUT1 to <i>external pin only</i>.
                    Enable the output buffer, and set the trigger to <i>Timer 6 trigger out event</i>.
                    Set the maximum triangle amplitude to 4095.
                </p>
                <Popup trigger={<img className="img_dac_tri_mx_hal19 clickable_img" src={dac_tri_mx} alt="dac_tri_mx_hal19"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={dac_tri_mx} alt="dac_tri_mx_hal19" />
                    )}
                </Popup>
                <p>
                    Setting the amplitude to 4095 means that with every trigger, the digital value will be increased with 1b,
                    and when the value reaches the peak, it will be decreased with 1b.
                    In order to have a signal with approximately 5Hz frequency, we must create a trigger signal with 40.96kHz frequency.
                    This can be achieved by setting the counter period to 3909.
                    Dont' forget to set the Trigger Event to <i>Update Event</i>.
                </p>
                <Popup trigger={<img className="img_tmr_tri_mx_hal19 clickable_img" src={tmr_tri_mx} alt="tmr_tri_mx_hal19"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={tmr_tri_mx} alt="tmr_tri_mx_hal19" />
                    )}
                </Popup>
                <h4>Firmware: </h4>
                <p>
                    The firmware is simple and straight-forward.
                    The first step is to enable the DAC using the instruction <i>HAL_DAC_Start(&hdac1, DAC_CHANNEL_1)</i>,
                    then enable the timer with <i>HAL_TIM_Base_Start(&htim6)</i>.

                    You can blink an LED in the main loop and check with an oscilloscope that the core can execute the blinking without interruption.
                    You can also visualise the triangle wave with the oscilloscope:
                </p>
                <img className="img_tri_scope_hal19" src={tri_scope} alt="tri_scope_hal19"/>
            </div>

            <div className="em__post-section">
                <h3>Band limited white noise generation:</h3>
                <p>
                    The DACs on the STM32G4 include a linear feedback shift register (LFSR) based pseudo random number generator,
                    which can create a band limited pseudo random white noise
                    (band limited, since the sampling frequency is finite and the Nyquist theorem holds).
                    The LFSR is a shift register whose input bit is a linear function of the previous states.
                </p>
                <p>
                    In the case of the STM32, the shift register is 12b wide (equal to the width of the DAC),
                    and the linear function is a logical XOR.
                    The LFSR value can be masked partially or totally.
                </p>
                <img className="img_lfsr_hal19" src={lfsr} alt="lfsr_hal19"/>
                <p>
                    This peripheral can be useful if we want to test the operation of a state/parameter estimator or
                    if we want to analyse the amplitude response of filters.
                    If we need true randomness, there is a peripheral precisely for that purpose, but that is besides the scope of this tutorial.
                    For now, let's generate a noise signal with 1kHz sampling frequency.
                </p>
                <h4>Project Setup:</h4>
                <p>
                    Open up the .ioc file in CubeMx.
                    Go to the DAC parameter settings, change wave generation mode to <i>Noise wave generation</i> and set the
                    Noise amplitude to <i>Unmask DAC channel LFSR bits [11:0]</i> so that we can have a signal in the range of [0V, 3.3V].
                </p>
                <Popup trigger={<img className="img_dac_rng_mx_hal19 clickable_img" src={dac_rng_mx} alt="dac_rng_mx_hal19"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={dac_rng_mx} alt="dac_rng_mx_hal19" />
                    )}
                </Popup>
                <p>
                    Now go to the TIM6 parameter settings, and change the Prescaler/Counter period to get a constant 1kHz Update event.
                    One such parameter pair is a prescaler of 4 with a counter period of 40000.
                </p>
                <Popup trigger={<img className="img_tmr_rng_mx_hal19 clickable_img" src={tmr_rng_mx} alt="tmr_rng_mx_hal19"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={tmr_rng_mx} alt="tmr_dma_mx_hal19" />
                    )}
                </Popup>
                <h4>Firmware: </h4>
                <p>
                    The firmware remains unchanged, since we only wanted to change the shape and the frequency of the signal.
                    The image below shows a snapshot of the generated signal and the spectrum.
                </p>
                <img className="img_rng_scope_hal19" src={rng_scope} alt="rng_scope_hal19"/>
                <p>
                    We have quite a high peak at 0 in the FFT spectrum, since the generated signal is DC shifted.
                    If you measure the mean, it will give a nearly perfect 1.65V.
                    Other than that, the spectrum is almost completely flat - which is expected of a white noise.
                    The steep attenuation at the end of the spectrum is generated by the software filter enabled in the oscilloscope.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Arbitrary waveform generation:</h3>
                <p>
                    Finally, it's time to generate our own signal using DAC.
                    We will generate a sine wave with 10Hz frequency because our imagination is endless.
                    The samples will be stored in a 128 element array.
                </p>
                <h4>Project Setup:</h4>
                <p>
                    Open the .ioc file again, and disable the wave generation mode in the DAC.
                    Go to the DMA Settings, add a new request to <i>DAC1_CH1</i> with high priority.
                    Set the mode to <i>Circular</i> so that when the last sample is processed, the DMA goes to the first one.
                    Set the data width to <i>Word</i>.
                </p>
                <p>
                    The last setting is necessary even though the DAC is 12b wide, since the data for CH1 and CH2 are stored in a word.
                    In single channel mode the active channel is stored in the lower half word of the memory
                    (see pages 730 and 731 in the <a href="https://www.st.com/resource/en/reference_manual/rm0440-stm32g4-series-advanced-armbased-32bit-mcus-stmicroelectronics.pdf">reference manual</a>).
                </p>
                <Popup trigger={<img className="img_dac_dma_mx_hal19 clickable_img" src={dac_dma_mx} alt="dac_dma_mx_hal19"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={dac_dma_mx} alt="dac_dma_mx_hal19" />
                    )}
                </Popup>
                <p>
                    If we want to generate a 10Hz signal using 128 samples, the update event should have a frequency of 1280Hz.
                    Go to TIM6 and update the settings. One such combination is Prescaler: 4, Counter Period: 31250.
                    Save and re-generate the project.
                </p>
                <Popup trigger={<img className="img_tmr_dma_mx_hal19 clickable_img" src={tmr_dma_mx} alt="tmr_dma_mx_hal19"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={tmr_dma_mx} alt="tmr_dma_mx_hal19" />
                    )}
                </Popup>
                <h4>Firmware: </h4>
                <p>
                    The environment has math functions, so we could create an array, and populate the values before the main loop.
                    A better approach is to use a constant lookup table (LUT), and interpolate if needed.
                </p>
                <p>
                    Create a constant array <i>static const uint32_t dac_buffer[128]</i>,
                    and initialise it with the sine wave values. You can use any online sine wave LUT generator.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {dma_dac}
                </SyntaxHighlighter>
                <p>
                    replace the starter function with a DMA function. Give the DAC handle, the channel,
                    the buffer pointer, the length of the buffer and the alignment of the data in the buffer.
                    Then start the timer.
                </p>
                <p>
                    Here you can see the generated sine wave.
                    You can spot two peculiarities:
                    <ul>
                        <li>
                            The signal is clipped, since the buffer is not calibrated - you could calibrate it,
                            or decrease the amplitude of the sine wave in the LUT.
                        </li>
                        <li>
                            The samples are visible - you could create an analog low-pass filter. If the signal frequency is 10Hz,
                            but the update frequency is 1kHz, an RC filter with a cut-off frequency of 20Hz could do the trick
                            (80R series resistor, 100uF capacitor from resistor to ground).
                        </li>
                    </ul>
                </p>
                <img className="img_sin_scope_hal19" src={sin_scope} alt="sin_scope_hal19"/>
            </div>

            <div className="em__post-section">
                <p>
                    Either way, the signal generator is working. You can find the source files <a href="https://gitlab.com/stm32_mcu_group/stm32_hal/19_dac_trigger.git">here</a>.
                </p>
            </div>



            <div className="em__post-navigation">

                <NavLink to="./../stm-hal-18">
                    <Button btnID={"leftBTN"} buttonSize="btn--medium"> Previous Post</Button>
                </NavLink>

                <NavLink to="./../stm-hal-20">
                    <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
                </NavLink>
            </div>
        </div>
    );
}

export default MCU_HAL19;