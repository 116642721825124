import React from "react";
import "./mcu_hal17.css";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";

import adc_buffer2 from "./adc_buffer2.jpg";
import adc_dma_circ from "./adc_dma_circ.jpg";
import adc_trigger from "./adc_trigger.jpg";
import adc_tim6 from "./adc_tim6.jpg";

const constants = `
const uint8_t buffer_len = 8; // Must be a power of two
const uint8_t shifter = 3;
volatile int8_t adc_flag = -1;
`;

const interrupts = `
void HAL_ADC_ConvCpltCallback(ADC_HandleTypeDef *hadc){
    adc_flag = buffer_len;
}

void HAL_ADC_ConvHalfCpltCallback(ADC_HandleTypeDef *hadc){
    adc_flag = 0;
}
`;

const localBuffer = `
uint16_t adc_buffer[2*buffer_len];
uint32_t temp_buffer;
uint8_t adc_char[4];
`;

const setup = `
SSD1306_Init();
SSD1306_GotoXY(1,1);
SSD1306_Puts("Value:", &font_7x10, SSD1306_COLOR_WHITE);
SSD1306_UpdateScreen();

HAL_ADCEx_Calibration_Start(&hadc1, ADC_SINGLE_ENDED);

HAL_ADC_Start_DMA(&hadc1, (uint32_t *)adc_buffer, buffer_len*2);
HAL_TIM_Base_Start(&htim6);
`;

const loop = `
 if(adc_flag == 0 || adc_flag == buffer_len){
      temp_buffer = 0;
      for(uint8_t i = 0; i < buffer_len; ++i)
          temp_buffer += adc_buffer[i+adc_flag];
      //temp_buffer = temp_buffer / buffer_len;
      temp_buffer = temp_buffer >> shifter;
      adc_flag = -1;
  }

  adc_char[0] = temp_buffer/1000 + '0';
  adc_char[1] = (temp_buffer/100)%10 + '0';
  adc_char[2] = (temp_buffer/10)%10 + '0';
  adc_char[3] = (temp_buffer)%10 + '0';

  SSD1306_GotoXY(49,1);
  SSD1306_Puts((char*)adc_char, &font_7x10, SSD1306_COLOR_WHITE);
  SSD1306_UpdateScreen();
`;


function MCU_HAL17(){
    return(
        <div className="em__post">
            <div className="em__post-title">
                <h1>ADC Timer trigger</h1>
            </div>

            <div className="em__post-section">
                <h3>Aim of this tutorial:</h3>
                <p>
                    In this tutorial we will configure a regular ADC channel and sample data using double buffering DMA method.
                    We will trigger the ADC with the help of a basic Timer to achieve a constant sampling time.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Background:</h3>
                <p>
                    In the previous tutorials we've learnt how to trigger the ADC sampling by software.
                    This procedure is okay if the timing is not critical (e.g. when checking device temperature or battery voltage),
                    but there are cases where the sampling time (period) must be constant (control, filtering, estimation).
                    In the latter cases we need a precise trigger, which can be achieved using a timer.
                </p>
                <img className="img_adc_buffer2_hal17" src={adc_buffer2} alt="adc_buffer2_hal17"/>
                <p>
                    The other problem is the buffering.
                    If we have a DMA buffer with <i>n</i> elements, and we wait to fill the buffer before we try to process the data,
                    the buffer may be prematurely overwritten (the timer is in control of the sampling).
                    This can be avoided by using a technique called double buffering.
                    If we want to work on <i>n</i> values, we could use a buffer with <i>2n</i> elements, generate an interrupt event
                    when half of the buffer is filled, and work on that part while the other half gets filled.
                    Then another interrupt is generated, and we can work on the second half of the buffer while the first half gets filled.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Project Setup:</h3>
                <p>
                    Before we create a new project, let's plan ahead.
                    We would like to sample the potentiometer, filter it, and display it on the OLED screen.
                    The refresh rate of the OLED screen is below 50Hz in this unoptimised state, so an 50Hz sampling frequency would be enough,
                    but let's go for 1kHz - because why not?
                    The easiest filtering method is the moving average.
                    That requires a single division of the summed terms.
                    If we choose the number of elements to be power of 2, we could use a faster operation - the arithmetic shift operator.
                    Let's say that <i>n</i>=8, that means we need to shift the sum to the right with 3 bits.
                    Now, we have raw and filtered data. If the reading frequency is stated for the filtered data,
                    then the sampling frequency must be multiplied by the number of summed elements.

                    So, overall: we must create a buffer with 2*8=16 elements, the sampling frequency of the raw data is 8kHz,
                    and we must shift the summed data to the right with 3 bits to get the filtered value.
                </p>
                <p>
                    Create a new project with the external oscillator, debugger connection, I2C1 connectivity and enable
                    IN1 from ADC1.
                    Enable the DMA for ADC1 in Circular, Half Word mode.
                </p>
                <img className="img_adc_dma_circ_hal17" src={adc_dma_circ} alt="adc_dma_circ_hal17"/>
                <p>
                    Enable the continuous conversion mode, and the DMA continuous request.
                    Then in the regular conversion menu, set the external trigger to Timer 6 Trigger Out event - TIM6 is
                    a basic timer, we don't need general or advanced timers for triggers.
                </p>
                <img className="img_adc_trigger_hal17" src={adc_trigger} alt="adc_trigger_hal17"/>
                <p>
                    Enable TIM6 from the Timers, and set it up for a 8kHz update event
                    (one possible solution is 1 divider and 21250 counter period).
                    Also, set the trigger event selection to update event.
                </p>
                <img className="img_adc_tim6_hal17" src={adc_tim6} alt="adc_tim6_hal17"/>
                <p>
                    Generate the project, and include the <i>SSD1306</i> and font drivers.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Firmware:</h3>
                <p>
                    First, we define the half length of the buffer, and the shift amount.
                    This time the adc flag is a signed integer: -1 means that there is no data to process,
                    0 means the first half is complete, 8 means the second half is complete - this notation will be handy latter on.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {constants}
                </SyntaxHighlighter>
                <p>
                    We need two interrupts.
                    One for the half complete and one for the full complete event.
                    We could process the data in these routines, but this time we are only setting an appropriate value for the flag.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {interrupts}
                </SyntaxHighlighter>
                <p>
                    Let's declare the buffer, the accumulator variable and the display value:
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {localBuffer}
                </SyntaxHighlighter>
                <p>
                    Now, onto the setup.
                    Initialise the OLED display, and print the static text.
                    Calibrate the ADC, and start it in DMA mode - this will not start the sampling, since that is not done by the program.
                    Start the base timer of TIM6 - this will start the sampling process.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {setup}
                </SyntaxHighlighter>
                <p>
                    Finally, the main loop.
                    We check is there is a 0 or 8 flag present - if so, we calculate the average of the finished data block, and reset the flag.
                    Otherwise the display value is updated and it is sent to the OLED screen.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {loop}
                </SyntaxHighlighter>
                <p>
                    Use the line-by-line debug functionality to check the execution of the code, the execution time, and the filling of the DMA register.
                    The project files can be downloaded from <a href="https://gitlab.com/stm32_mcu_group/stm32_hal/17_adc_trigger.git">here</a>.
                </p>
            </div>


            <div className="em__post-navigation">

                <NavLink to="./../stm-hal-16">
                    <Button btnID={"leftBTN"} buttonSize="btn--medium"> Previous Post</Button>
                </NavLink>

                <NavLink to="./../stm-hal-18">
                    <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
                </NavLink>
            </div>
        </div>
    );
}

export default MCU_HAL17;