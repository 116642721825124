import React from "react";
import "./../posts.css";
import "./fpga_7.css";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import sine from "./sinus.png";
import {MathJax, MathJaxContext} from "better-react-mathjax";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";
import dsm_linear from "./dsm_linear.png";
import dsm_zdomain from "./dsm_zdomain.png";
import dsm2_zdomain from "./dsm2_zdomain.png";
import dsm2_plot from "./dsm2_plot.png";
import dsm_internal from "./dsm_internal.png";
import pwm_internal from "./pwm_internal.png";
import dsm_flt_pulse from "./dsm_flt_pulse.png";
import pwm_flt_pulse from "./pwm_flt_pulse.png";
import dsm_raw_pulse from "./dsm_raw_pulse.png";
import pwm_raw_pulse from "./pwm_raw_pulse.png";
import pwm_raw from "./pwm_raw.png";
import dsm_raw from "./dsm_raw.png";
import dac_adc_out from "./dac_adc_out.png";
import ds_adc from "./ds_adc.png";
import ds_adc_fpga from "./ds_adc_fpga.png";
import dsm_analog from "./dsm_analog.png";
import Popup from "reactjs-popup";

function ShowTex({string}){
    const config = {
        loader: { load: ["[tex]/html"]},
        tex: {packages: {"[+]": ["html"]},
            inlineMath: [["$", "$"]],
            displayMath: [["$$", "$$"]]
        }
    };

    return(
        <MathJaxContext config={config} version={3}>
            <MathJax dynamic inline>
                {string}
            </MathJax>
        </MathJaxContext>
    );
}

const sine_generator=`
module sineTable(
    input i_clk,
    input i_rstn,
    output reg [7:0] o_sin);
    
    reg[7:0] r_sin [0:15];
    integer i, k;
    ............................
    always @(posedge i_clk) begin
        if(i_rstn == 0)begin
            i = 0;
            o_sin <= 0;
        end
        else begin
            if(k == 0)
                o_sin <= r_sin[i%16];
            else if(k == 1)
                o_sin <= r_sin[15 - i % 16];
            else if(k == 2)
                o_sin <= (255 - r_sin[i%16]);
            else
                o_sin <= (255 - r_sin[15 - i % 16]);
            if (i % 16 == 15)
                k = (k + 1) % 4;
    
            if(i == 63)
                i = 0;
            else
                i = i + 1;
        end
    end
endmodule
`;

const dsm1_py = `
t = np.arange(0, 5, 0.01)
u = (np.sin(2*np.pi*t) + 1.0)/2.0

v1 = np.zeros(t.shape)
x2 = np.zeros((2, 1))
for ii, element in enumerate(u):
    if ii == 0:
        x1 = element
        x2[0] = x1
    else:
        x1 = element - v1[ii-1]
        x2[0] = x1 + x2[1]
    v1[ii] = (np.sign(x2[0]) + 1.0)/2.0
    x2[1] = x2[0]
`;

const dsm2_py= `
v2 = np.zeros(t.shape)
x2 = np.zeros((2, 1))
x3 = np.zeros((2, 1))
for ii, element in enumerate(u):
    if ii == 0:
        v = 0
    else:
        v = v2[ii-1]
    x2[0] = x2[1] + element - v
    x3[0] = x3[1] + x2[0] - v
    v2[ii] = (np.sign(x3[0])+1.0)/2.0
    x2[1] = x2[0]
    x3[1] = x3[0]
`;

const delta_sigma = `
module delta_sigma#(
    parameter DATA_WIDTH=8)(
    input i_clk, i_rstn, 
    input[DATA_WIDTH-1:0] i_data,
    output reg o_data);

    reg [DATA_WIDTH+1:0] r_delta, r_sigma, r_sigma_latched, r_delta_b;

    always @(posedge i_clk) begin
        if(~i_rstn) begin
            r_sigma_latched <= 0;
            o_data <= 0;
        end
        else begin
            r_sigma_latched <= r_sigma;
            o_data <= r_sigma_latched[DATA_WIDTH+1];
        end
    end
    
    always @(*) begin
        r_delta_b = {2{r_sigma_latched[DATA_WIDTH+1]}} << DATA_WIDTH;
        r_delta = i_data + r_delta_b;
        r_sigma = r_delta + r_sigma_latched;
    end

endmodule
`;


function FPGA_7(){
    return(
        <div className="em__post">
            <div className="em__post-title">
                <h1>Delta Sigma or Sigma Delta modulators</h1>
            </div>
            <div className="em__post-section">
                <h3>Aim of this post:</h3>
                <p>
                    In this tutorial, we will try to improve on the previously presented DAC module by reducing the LUT size
                    and by implementing a delta sigma modulation technique.
                    Furthermore, we will use the same technique to create an ADC in the FPGA fabric.
                </p>
            </div>
            <div className="em__post-section">
                <h3>Prerequisites:</h3>
                <p>
                    Previous FPGA tutorials, basic python knowledge.
                </p>
            </div>

            <div className="em__post-section">
                <h3>LUT reduction</h3>
                <img className="img_sine" src={sine} alt="sine" />
                <p>
                    In the previous tutorial we used a LUT of a full sine wave with 30 elements.
                    The sine function has some nice properties: first, it's periodic, hence the finite LUT entries.
                    Second,  it's an odd function <ShowTex string="$sin(-x) = -sin(x)$"/>,
                    and its graph is anti-symmetric with respect to the origin.
                    As a matter of fact, this anti-symmetry axis is periodic in itself,
                    with a period that is half the period of the sine function <ShowTex string="$sin(-x + k \pi) = -sin(x + k\pi)$"/>.
                    Third there are two symmetry lines in a single period of the function, at the two extremas.
                </p>
                <p>
                    If we know the values of the sine function <ShowTex string="$y_1=sin(x) : [0, \pi/2] \to [-1, 1]$"/>
                    (region 1 in the picture), we can get the values of the 3 consecutive regions as:
                </p>
                <div className="em_indented">
                    <ul >
                        <li>Region 2: <ShowTex string="$y_2: [\pi/2, \pi] \to [-1, 1]$"/>,
                            <ShowTex string="$y_2(x) = y_1(\pi - x)$"/> by applying the symmetry law to <ShowTex string="$y_1$"/>.</li>
                        <li>Region 3: <ShowTex string="$y_3: [\pi, 3\pi/2] \to [-1, 1]$"/>,
                            <ShowTex string="$y_3(x) = -y_1(x-\pi)$"/> by applying the anti-symmetry law to <ShowTex string="$y_2$"/>.</li>
                        <li>Region 4: <ShowTex string="$y_4: [3\pi/2, 2\pi] \to [-1, 1]$"/>,
                        <ShowTex string="$y_4(x) = -y_1(2\pi-x)$"/> by applying the symmetry law to <ShowTex string="$y_3$"/>.</li>
                    </ul>
                </div>

                <p> We could leave things as are right now, but at the boundary of the regions,
                    we will use each sample twice, which will add noticeable distortion to our signal.
                    We can eliminate this effect by adding a small phase delay to the generated signal:
                    meaning that our starting interval is not <ShowTex string="$[0, \pi/2]$"/>,
                    but <ShowTex string="$[\pi/100, \pi/2 + \pi/100]$"/> and so on.
                </p>

                <p> This is all nice in a continuous world, but in an FPGA we only have discrete memory regions,
                    so we discretize our function, and the modified sine generator will look like:</p>
                <div className="code_to_right">
                    <SyntaxHighlighter language="verilog" style={AtomOneDark} >
                        {sine_generator}
                    </SyntaxHighlighter>
                </div>
                <p>
                    We can see that the width of the LUT decreased from 30 to 16, but this is only a quarter wave,
                    so in essence we have 64 samples, we only need to figure out the addressing.
                </p>
                <p> There is more than one way to achieve our logic described above,
                    like using up-down counter or modulo division. We will use the latter.</p>
                <p> We need two integers to track the sample count (<i>i</i>) and the group count (<i>k</i>).
                    If the group counter is less than two,
                    we are in the positive half period of the sine wave, so no sign extension is needed.
                    If the group counter is greater then or equal to two, we have flip the sign of the sine.
                    We do not have negative numbers at our hand, so the negative of a number&nbsp;
                    <ShowTex string="$x$"/> means <ShowTex string="$(2^n - 1) -x$"/> or two's complement.
                </p>
                <p> We can treat the sample counter similarly to the group counter.
                There are 16 samples, and 4 groups which means the counter must count up until 63.
                When we are in the first or in the third group we count up until 15 -
                    this can be done using modulo division <ShowTex string="$i % 16$"/>.
                In the second or fourth group we must count back, which is done using the two's complement of
                    the modulo number <ShowTex string="$(2^m - 1) - i % 16$"/>.
                </p>
                <p> The group counter is increased after the counter reaches a quarter,
                    and we use the modulo division to limit the max value to 3.
                    Alternatively we could define the group counter as a two bit register.
                    Similarly, the sample counter is increased at the end of every cycle, and set to zero after 63,
                    or we could define it as a six bit register, and the overflow takes care of the reset.
                    You can see that the synthesizer automatically optimizes the width of these counters to 3 and 6 respectively.
                </p>

                <p> At this point, we can replace the previously written sine LUT module with our current module,
                    and check that we still have our sine output.</p>

            </div>

            <div className="em__post-section">
                <h3>Delta sigma modulation (DSM) techniques</h3>
                <p>
                    DSM is a method for encoding analog signals into digital signals,
                    but it's also used to convert high bit-count, low frequency signals into low bit-count,
                    higher frequency signals as part of a digital to analog converter.
                </p>
                <div>
                    <p>
                        DSM converts an analog signal into a pulse density modulated (PDM) signal, which is easy to generate,
                        transmit, cost effective and the required circuitry/program has reduced complexity.
                        The frequency of the generated pulses is proportional to the amplitude of the input signal -
                        in contrast with PWM signals, where the frequency is fixed and
                        the duty cycle is proportional to the amplitude of the input signal.
                    </p>
                    <img className="img_dsm_linear" src={dsm_linear} alt="dsm_linear" />
                    <p>
                        The delta term stands for a subtracting block, which creates a difference using the current input and the digitized output.
                        The sigma term stands for an integrator or a summing block.
                    </p>
                    <p> The ADC and DAC here are both just placeholder blocks for a quantizer and zero order hold functions respectively.</p>
                    <p> We can study this time domain model in frequency domain with the help of discretization and z-transform tools.</p>
                </div>
                <div>
                    <img className="img_dsm_zdomain" src={dsm_zdomain} alt="dsm_zdomain" />
                    <p>
                        The delta block remains unchanged.
                        The sigma block (integrator) can be interpreted as the sum of the current input and the previous output.
                        We will use the sign function for quantization (or step function for unsigned operations), and a simple delay as the DAC.
                    </p>
                    <p> This system is called a MOD1 DSM, or first order delta sigma modulator.
                    The quantization noise of MOD1 is non-white (the noise when the input is near zero).
                        It has a dead-band proportional to the inverse of the internal gain.
                    </p>
                    <p>
                        The tonal behavior and the dead-band can be improved by the use of MOD2 DSM.
                    </p>
                </div>
            </div>
            <div className="em__post-section">
                <div>
                    <img className="img_dsm2_zdomain" src={dsm2_zdomain} alt="dsm2_zdomain" />
                    <p>
                        We can make a second order delta sigma modulator by replacing the quantizer block of a MOD1 DSM
                        with a complete MOD1 DSM.
                    </p>
                    <p>
                        The dead-band of the MOD2 DSM is proportional to the inverse of the square of the internal gain.
                        We can write a simple python script to test the previously shown DSM techniques.
                        The first code snippet generates a MOD1 while the second one generated a MOD2 DSM wave.
                        The figure shows the MOD1 wave on the upper subplot and the MOD2 wave on the lower subplot.
                    </p>
                </div>
            </div>

            <div className="em__post-section">
                <div className="post_inline">
                    <div className="code1">
                        <SyntaxHighlighter language="python" style={AtomOneDark} >
                            {dsm1_py}
                        </SyntaxHighlighter>
                    </div>
                    <div className="code2">
                        <SyntaxHighlighter language="python" style={AtomOneDark} >
                            {dsm2_py}
                        </SyntaxHighlighter>
                    </div>
                </div>

                <img className="img_code" src={dsm2_plot} alt="dsm2_plot" />
            </div>

            <div className="em__post-section">
                <h3>MOD1 delta sigma modulator</h3>
                <p>
                    Now it's time to write a MOD1 DSM in verilog and compare its behaviour to that of the PWM DAC.
                </p>
                <div className="code_to_left">
                    <SyntaxHighlighter language="python" style={AtomOneDark} >
                        {delta_sigma}
                    </SyntaxHighlighter>
                </div>
                <div>
                    <p>
                        I've used a parametric module, but we will leave the bit depth at 8 bits,
                        so that we can compare the results to the PWM module.
                    </p>
                    <p>
                        We have two important registers <i>r_delta</i> which will contain the difference value,
                        and <i> r_sigma</i> which will contain the sum. We are using larger registers (10b in this case)
                        to counteract occasional overflows.
                    </p>
                    <p>
                        The synchronous block corresponds to the delay and quantization blocks,
                        while the lower block creates the sum and difference.
                        We can see this internal schematic on the image below:
                    </p>
                    <Popup trigger={<img className="img_dsm_internal clickable_img" src={dsm_internal} alt="dsm_internal"/>} modal nested>
                        {close => (
                            <img className="em__img_full" src={dsm_internal} alt="dsm_internal" />
                        )}
                    </Popup>

                </div>
            </div>

            <div className="em__post-section">
                <p>Here's the internal schematic of the PWM module:</p>
                <Popup trigger={<img className="img_pwm_internal clickable_img" src={pwm_internal} alt="pwm_internal"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={pwm_internal} alt="pwm_internal" />
                    )}
                </Popup>
                <p>
                    Below we can see the some saved scope images with the previously mentioned modules in action.
                    The input clock was 53.2MHz in both cases. Similarly,
                    I've used the same RLC filter from the previous tutorial for both cases.
                </p>
                <p>
                    The first image pair shows the modulated signals and the filtered values (here, I've used the software filter of the oscilloscope).
                    The yellow signal is the PWM, while the green signal is the DSM.
                    That curved artifact on the green signal is due to the bad impedance matching and using long ground wire.
                </p>
                <div className="em__post-section_distribute">
                    <Popup trigger={<img className="img_pwm_raw clickable_img" src={pwm_raw} alt="pwm_raw"/>} modal nested>
                        {close => (
                            <img className="em__img_full" src={pwm_raw} alt="pwm_raw" />
                        )}
                    </Popup>
                    <Popup trigger={<img className="img_dsm_raw clickable_img" src={dsm_raw} alt="dsm_raw"/>} modal nested>
                        {close => (
                            <img className="em__img_full" src={dsm_raw} alt="dsm_raw" />
                        )}
                    </Popup>
                </div>
                <p>
                    I've zoomed in on the signals and I've enabled persistence.
                    On the left side we have a PWM with a frequency of 211kHz and variable duty cycle.
                    On the right side we have the DSM with minimum pulse lenghts of 20ns.
                    This speed explains why we would need better probing.
                </p>
                <div className="em__post-section_distribute">
                    <Popup trigger={<img className="img_pwm_raw_pulse clickable_img" src={pwm_raw_pulse} alt="pwm_raw_pulse"/>} modal nested>
                        {close => (
                            <img className="em__img_full" src={pwm_raw_pulse} alt="pwm_raw_pulse" />
                        )}
                    </Popup>
                    <Popup trigger={<img className="img_dsm_raw_pulse clickable_img" src={dsm_raw_pulse} alt="dsm_raw_pulse"/>} modal nested>
                        {close => (
                            <img className="em__img_full" src={dsm_raw_pulse} alt="dsm_raw_pulse" />
                        )}
                    </Popup>
                </div>
                <p>
                    Finally we have the filtered signals.
                    Optimally one would redesign the passive filters for the proper use-case, but this time I was lazy.
                </p>
                <div className="em__post-section_distribute">
                    <Popup trigger={<img className="img_pwm_flt_pulse clickable_img" src={pwm_flt_pulse} alt="pwm_flt_pulse"/>} modal nested>
                        {close => (
                            <img className="em__img_full" src={pwm_flt_pulse} alt="pwm_flt_pulse" />
                        )}
                    </Popup>
                    <Popup trigger={<img className="img_dsm_flt_pulse clickable_img" src={dsm_flt_pulse} alt="dsm_flt_pulse"/>} modal nested>
                        {close => (
                            <img className="em__img_full" src={dsm_flt_pulse} alt="dsm_flt_pulse" />
                        )}
                    </Popup>
                </div>


            </div>
            <div className="em__post-section">
                <h3>Delta sigma (DS) ADC in a nutshell</h3>
                <p>
                    When it comes to ADCs we have to open a big can of worms, which is far beyond the scope of this tutorial.
                    Hence, we will glide over the ADC literature with only a few words.
                </p>
                <p>
                    There are many ADC types sharing the same goal of sampling an analog signal and converting it to digital values.
                    The most used ones are:
                </p>
                <ul>
                    <li><b>Flash ADC</b>s, which uses comparator banks and resistor dividers.
                        This way, simultaneous high conversion speeds can be achieved with increased complexity, silicon die.</li>
                    <li><b>Successive approximation ADC</b>s, which uses a single comparator and binary search to
                        narrow the output range that contains the input voltage.
                        This makes the ADC high speed but slower then the previous one, has medium accuracy but much less resource intensive.
                        It's the most widespread type used in microcontrollers.
                    </li>
                    <li>
                        <b>Delta sigma ADC</b>s use modulation and signal processing techniques to achieve high resolutions.
                        This configuration requires minimal components (as we will see), but it's slow in conversion due to oversampling.
                    </li>
                </ul>

                <img className="img_ds_adc" src={ds_adc} alt="ds_adc"/>
                <p>
                    The first component of the DS ADC is the DS modulator which generates a pulse density modulated signal
                    where the frequency is proportional to the amplitude of the input signal.
                    Next a fast low-pass filter filters this signal to create a low resolution, high bitrate digital replica of the input signal.
                    The final component is a decimator which lowers the bitrate and increases the resolution.
                </p>
                <p>
                    The last two blocks (also called decimator filter) can be easily implemented in an FPGA, but the
                    first one requires some critical thinking.
                </p>
                <img className="img_dsm_analog" src={dsm_analog} alt="dsm_analog"/>
                <p>
                    The usual analog design consists of a difference amplifier, an opamp integrator,
                    a comparator and a 1b DAC in the feedback path.
                </p>
                <p>
                    Integration is sum in discrete time systems. The comparator is done using conditional operations.
                    We can implement an 1b DAC using a single D latch (sample and hold) if the data is fully digital.
                    What about the differential amplifier?
                </p>
                <p>
                    We are in luck, since most of the FPGAs are designed for fast data transfer with good integrity,
                    which often requires differential signals. These components are called LVDSx blocks
                    (LVDS2.5 or LVDS3.3 depending on the intended voltage levels). And our MachXO2 FPGA luckily has a few differential I/Os waiting to be configured.
                </p>

            </div>

            <div className="em__post-section">
                <h3>Implementing a delta sigma ADC</h3>
                <p>
                    The following block diagram gives a peek into the logic of the ADC:
                </p>
                <img className="img_ds_adc_fpga" src={ds_adc_fpga} alt="ds_adc_fpga"/>
                <p>
                    The LVDS differential input is used as differential amplifier, with the positive part being the actual analog signal (<b>max 3V</b>),
                    and the negative part being the filtered and delayed digital signal (the 1b DAC).
                    The result is fed into a DSM module, and finally into a decimator filter.
                    The simplest filtering technique is used, which is the moving average filtering.
                </p>

                <p>
                    <b>DISCLAIMER:</b> This solution is not my original idea. This is a well-known/used/disqussed technique, so I won't go in details,
                    because the scope was to show the applicability of the DSM module. For further informations please refer to the paper from <a href="https://www.latticesemi.com/en/Products/DesignSoftwareAndIP/IntellectualProperty/ReferenceDesigns/ReferenceDesign03/SimpleSigmaDeltaADC">Lattice Semiconductor</a>.
                    I've started from their files, which I included in the PWM vs DSM project.
                </p>
                <p>
                    The final design implements a PWM based DAC, which generates a sine wave.
                    This signal is fed into the newly created DS ADC (which was configured to have 8b data width 10b accumulator and 3b filter depth),
                    from where the digital data is connected to the DSM DAC.
                    This filtered output is measured with the help of the trusty oscilloscope.
                </p>
                <Popup trigger={<img className="img_dac_adc_out clickable_img" src={dac_adc_out} alt="dac_adc_out"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={dsm_flt_pulse} alt="dsm_flt_pulse" />
                    )}
                </Popup>
                <p>
                    Yes, it's noisy, and yes, it has some distortion.
                    Considering the fact that this was achieved on a low-cost FPGA using internal oscillator,
                    with uncalibrated filters on a breadboard... I say it's at least half decent.
                    Of course, the code can be found <a href = "https://gitlab.com/Csurleny/fpga-tutorial-files/-/tree/main/deltaSigmaModulator">here</a>, as usual.
                </p>
            </div>

            <div className="em__post-navigation">

                <NavLink to="./../fpga-tut-6">
                    <Button btnID={"leftBTN"} buttonSize="btn--medium"> Previous Post</Button>
                </NavLink>

                <NavLink to="./../fpga-tut-8">
                    <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
                </NavLink>
            </div>
        </div>
    );
}

export default FPGA_7;