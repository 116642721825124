import React from "react";
import "./mcu_hal25.css";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";

import spi_mx from "./spi_mx.jpg";
import sai1_mx from "./sai1_mx.jpg";
import sai_a_mx from "./sai_a_mx.jpg";
import sai_b_mx from "./sai_b_mx.jpg";
import analog_io from "./analog_io.jpg";
import bclk_fs from "./bclk_fs.jpg";
import data_fs from "./data_fs.jpg";
import logic_sai from "./logic_sai.jpg";


const sai_buffers = `
volatile uint32_t receiveBuffer[4];
volatile uint32_t transmitBuffer[4] = {0};
`;

const sai_interrupts = `
void HAL_SAI_RxCpltCallback(SAI_HandleTypeDef *hsai){
    transmitBuffer[0] = receiveBuffer[0];
    HAL_SAI_Receive_IT(&hsai_BlockA1, receiveBuffer, 4);
}

void HAL_SAI_TxCpltCallback(SAI_HandleTypeDef *hsai){
    HAL_SAI_Transmit_IT(hsai, transmitBuffer, 4);
}
`;

const sai_main = `
HAL_SAI_Init(&hsai_BlockB1);
HAL_SAI_Init(&hsai_BlockA1);

HAL_SAI_Receive_IT(&hsai_BlockA1, receiveBuffer, 4);
HAL_SAI_Transmit_IT(&hsai_BlockB1, transmitBuffer, 4);
`;


function MCU_HAL25(){
    return(
        <div className="em__post">
            <div className="em__post-title">
                <h1>Audio interfaces</h1>
            </div>

            <div className="em__post-section">
                <h3>Aim of this tutorial:</h3>
                <p>
                    In this tutorial, we will configure the AD1938 audio CODEC and create a clean audio pass-through
                    using the SAI interface of the STM32G4.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Audio CODECs and interfaces:</h3>
                <p>
                    Audio setups consist of three major parts: the source generator - this is an analog sensor most of the time,
                    the processor and the signal sink - this can be an amplifier or a speaker.
                    We can see that the first and last parts are analog devices while the processing device could be analog or digital.
                    In this day and age, the audio processing is mostly done in the digital domain, so some sort of A/D and D/A conversion must be done.
                    <br/>
                    The ideal human hearing range is from 20Hz to 20kHz, which means that the minimum sampling frequency of the converters should be greater than 40kHz.
                    The first audio applications sampled the signal with 44.1kHz.
                    This was latter changed to 48kHz, 96kHz and 192kHz.
                    I will not go into which sampling frequency is sufficient and which is necessary. We have options.

                    <br/>
                    The other property of the A/D and D/A converters is the resolution.
                    The STM32G4 provides converters with 12b resolution, but in audio application 16b or 24b is more common.
                    The <b>audible</b> difference of 24b or 32b audio compared to 16b is negligible, but it can give finer dynamic control.
                    Also, we should take into consideration the architecture of the processor, when choose the resolution of the converters.
                    The converters with higher resolutions are usually sold in separate IC-s, they are not implemented alongside the MCU.
                    <br/>
                    Finally, we have noise.
                    We don't want noise in our audio but we are surrounded by it, so we need to filter out what we can, and reduce what we can not filter out.
                    We can reduce the noise coupling by minimising the working area - instead of using separate A/D and D/A converters, we can use a CODEC which has everything in a single chip.
                    If we look at the available CODEC ICs we can see that a lot of manufacturers provide these neat little chips:
                    <ul>
                        <li>
                            Maxim Integrated (now part of Analog Devices) has the <a href="https://www.maximintegrated.com/en/products/parametric/search.html?fam=audiocodecs&295=OR%7CAudio%20CODEC">MAX98XXX</a> codecs, which have 2-3 A/D and 1-2 D/A converters.
                            They provide a resolution from 16b to 24b with sampling frequency from 8kHz to 96kHz.
                            The data transfer is done using Audio I2S or PCM/PDM interface.
                        </li>
                        <li>
                            Texas Instruments has the <a href="https://www.ti.com/audio-ic/converters/codec/products.html">TLV and PCM</a> CODECs.
                            These devices offer 3 - 12 analog inputs, 2 - 14 analog outputs with resolution in the range of 16b to 24b and sampling frequency in the range of 8kHz to 192kHz.
                        </li>
                        <li>
                            Analog Devices also has a <a href="https://www.analog.com/en/parametricsearch/11357#/">multitude</a> of CODECs with 2 to 4 channel (mono or stereo) A/D and D/A converters.
                            The resolution is in the range of 16b to 24b, the sampling frequency is in the range of 48kHz to 768kHz.
                        </li>
                    </ul>
                </p>

                <p>
                    We saw that there were multiple ways to interface an audio CODEC.
                    Let's have a short look what do they mean:
                    CODECs usually transmit and receive audio data using a so called serial audio interface, which has four lines - bit clock (bclk or sck),
                    frame select or left/right clock (fs, ws, lrclk), serial data in (SDin) and serial data out (SDout).
                    This configuration can be used for different protocols like <a href="https://www.allaboutcircuits.com/technical-articles/introduction-to-the-i2s-interface/">I2S</a> (Inter IC Sound),
                    LSB/MSB justified, <a href="https://stackoverflow.com/questions/32367619/i2s-and-pcm-format">PCM</a>(Pulse Code Modulated)/DSP,
                    <a href="https://www.eeweb.com/tdm-audio-interface-a-tutorial/">TDM</a>(Time Division Multiplexed) or AC'97 protocol.
                    So we need to read the manual of both the CODEC and the MCU peripheral to set the communication up correctly.
                    Read <a href="https://hackaday.com/2019/04/18/all-you-need-to-know-about-i2s/">this</a> post and the comments to learn about this amazing configuration.
                </p>
            </div>

            <div className="em__post-section">
                <h3>AD1938 audio CODEC:</h3>
                <p>
                    I've chosen the <a href="https://www.analog.com/media/en/technical-documentation/data-sheets/AD1938.pdf">AD1938</a> CODEC,
                    since I had two of them gathering dust in their original ESD package.
                    This little IC supports up to 24b 8kHz to 192kHz sampling rates.
                    It has four differential A/D converter inputs, and eight single-ended D/A converter outputs.
                    If the channel count would not be enough, one could daisy-chain a second device up to 16 channel inputs/outputs.
                    It has logarithmic volume control, software controllable click-less mute functionality.
                    The digital audio interface supports all of the above mentioned protocols.
                    We can setup the IC using SPI communication.
                    <br/>
                    I've designed a custom board with analog front and back-end for this circuit, which can be directly connected to any NUCLEO boards (given the proper peripheral wiring).
                    There is a 12.288MHz crystal oscillator on the board, so that we can create a clean master clock signal close to the IC.
                    I will talk more about the board in the DSP section when we will take a look at audio signal processing.
                    Until then, let it be a mostly black box :).
                    The AD1938 will provide both the bit clock and the frame sync clock signals, meaning that the CODEC will be the master.
                    The STM32 will be the slave, and we will need a synchronous and an async interface, so that we can keep the same clock signals even on the MCU side.
                </p>
            </div>

            <div className="em__post-section">
                <h3>SPI for codec configuration:</h3>
                <p>
                    Create a new project in the usual fashion with 168MHz system clock.
                    Enable an SPI port for communication (I've used SPI2) with rising edge polarity (CPOL = 1 ; HIGH),
                    and second edge phase (CPHA = 1 ; 2 EDGE). I've set the prescaler to 128 so that we can keep the frequency below 2MHz,
                    but I've tested it and even with 8 (21MB/s) it still worked flawlessly.
                </p>
                <img className="img_spi_mx_hal25" src={spi_mx} alt="spi_mx_hal25"/>
                <p>
                    Generate the project.
                    Download the configuration sources from <a href="https://gitlab.com/stm32_mcu_group/stm32_hal/25_sai.git">this</a> repo.
                    The header contains the CODEC device address and register addresses with every configuration bit.
                    The source file has inline functions for software chip select, functions for reading and writing registers and the initialisation routine.
                </p>
                <p>
                    In the example, I've enabled the external crystal and the PLL.
                    All of the D/A channels are enabled, and all eight data will be transferred in MSB oriented fashion on a single data line.
                    The D/A bit and frame clocks are set as slave (inputs), the data width is set to 24b (32b slot), normal data polarity, falling edge clock polarities,
                    48kHz sampling frequency.
                    The A/D has almost the same settings. 48kHz sampling, 24b MSB oriented data, normal data polarity and falling edge clock polarities.
                    The A/D interface was configured as clock master (both bit and frame), the frame clock is high when the odd channels are transferred and otherwise low
                    (this could be set to pulse, so that fclk gives a single pulse at the start of each frame).
                </p>

                <p>
                    In the main source file call the <i>AD1938_init()</i> function, and upload the firmware.
                    As things stand nothing should happen. You could scope the frame and bit clocks (shown below), but you wouldn't hear sounds, since we don't process them.
                    If you connect the digital input and output pins with a wire, you should get back the signal on the analog output that you fed to the analog input.
                </p>
                <img className="img_bclk_fs_hal25" src={bclk_fs} alt="bclk_fs_hal25"/>
                <img className="img_data_fs_hal25" src={data_fs} alt="data_fs_hal25"/>
            </div>
            <div className="em__post-section">
                <p>
                    The first image shows the frame select and bit clock signals, while the second image shows the data output with some random analog signal on the channels.
                </p>
            </div>

            <div className="em__post-section">
                <h3>SAI interface:</h3>
                <p>
                    Now for the star of the show: Multimedia.
                    We have two peripherals under the Multimedia section: I2S and SAI.
                    I2S could be used if we would only want to receive/transmit mono or stereo signals - in this case, the frame sync clock
                    becomes left/right clock, and we can send/receive a left and a right data frame using a single data line.
                    If we have more channels, we could add more data lines synchronised to a single bclk and lrclk, but we don't want many lines.

                    <br/>
                    Then we have the SAI peripheral, that can be configured to accept any audio protocol (including I2S).
                    We will use the TDM mode, so that one RX/TX pair is sufficient.
                    Set SAI A to Asynchronous slave - this will be the receiver in my case, and set SAI B to Synchronous slave - this will be the transmitter.
                </p>
                <img className="img_sai1_mx_hal25" src={sai1_mx} alt="sai1_mx_hal25"/>
                <p>
                    Set SAI A to slave receive with frame length of 256b, data size of 24b and slot size of 32b.
                    We have 24b data encapsulated into full word slots, and since the CODEC is set to 8 channels of data, this results in a 8 * 32b or 256b frame.
                    Set the mode to Stereo, so that we can use every frame, not only half frames.
                    Set to MSB First mode, and set the frame sync signal to half of the frame length (128b).
                    We did not set an offset in the setup, so put 0 in here, and we only need the first 4 slots of the frame (the rest are zeros according to the datasheet).
                </p>
                <Popup trigger={<img className="img_sai_a_mx_hal25 clickable_img" src={sai_a_mx} alt="sai_a_mx_hal25"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={sai_a_mx} alt="sai_a_mx_hal25" />
                    )}
                </Popup>
                <p>
                    Set SAI B to slave transmit, clock strobing to rising edge and the rest of the parameters should be the same as in the previous case.
                    Enable the SAI interrupts and regenerate the project.
                </p>
                <Popup trigger={<img className="img_sai_b_mx_hal25 clickable_img" src={sai_b_mx} alt="sai_b_mx_hal25"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={sai_b_mx} alt="sai_b_mx_hal25" />
                    )}
                </Popup>

                <p>
                    Let's write some firmware to this peripheral.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {sai_buffers}
                </SyntaxHighlighter>
                <p>
                    First, we need a buffer for the TX and RX data.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {sai_interrupts}
                </SyntaxHighlighter>
                <p>
                    After that, we can define the interrupt functions.
                    When we have a new received frame, we copy the first slot to the transmitter buffer,
                    and we can re-trigger the reception.
                    The transmitter re-triggers itself after completion.
                    This could be done more elegantly with the use of DMA, flags and data processing in the main - this is left as an exercise for the reader.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {sai_main}
                </SyntaxHighlighter>
                <p>
                    In the main function, we only need to initialize the SAI blocks and start the RX/TX routines.
                    Upload the firmware, and add an audio signal to the input 1 of the CODEC (remember that it must be differential),
                    and measure the output.
                </p>
                <img className="img_analog_io_hal25" src={analog_io} alt="analog_io_hal25"/>
                <p>
                    Here we have a 100Hz 1Vpp signal on the input (the scope shows 0.5Vpp since 50R signal generator and HighZ scope impedance are not good friends).
                    The output is an amplified (hard amplification using op-amps) sine wave with a bit of phase shift thanks to the anti-aliasing and anti-imaging filters.
                </p>

                <Popup trigger={<img className="img_logic_sai_hal25 clickable_img" src={logic_sai} alt="logic_sai_hal25"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={logic_sai} alt="logic_sai_hal25" />
                    )}
                </Popup>

                <p>
                    And here is a view of the digital data transfer.
                    We have the 4 A/D channels (the first one is the sine wave, the rest are noise).
                    On the output line, we can see 8 signals, since I've changed up things a bit for measurement purposes.
                    The first packet is the feed-through (delayed by one sample), the rest are constants for timing, slot and output analysis.
                </p>
            </div>

            <div className="em__post-navigation">

                <NavLink to="./../stm-hal-24">
                    <Button btnID={"leftBTN"} buttonSize="btn--medium"> Previous Post</Button>
                </NavLink>

                <NavLink to="./../stm-hal-26">
                    <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
                </NavLink>
            </div>
        </div>
    );
}

export default MCU_HAL25;