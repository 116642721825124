import React from "react";
import "./dsp_8.css";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";
import {MathJax, MathJaxContext} from "better-react-mathjax";

import kaiser_lp from "./kaiser_lp.png";
import kaiser_hp from "./kaiser_hp.png";
import kaiser_bp from "./kaiser_bp.png";
import lp_fir_imp from "./lp_fir_imp.jpg";
import hp_fir_imp from "./hp_fir_imp.jpg";
import bp_fir_imp from "./bp_fir_imp.jpg";



function ShowTex({string}){
    const config = {
        loader: { load: ["[tex]/html"]},
        tex: {packages: {"[+]": ["html"]},
            inlineMath: [["$", "$"]],
            displayMath: [["$$", "$$"]]
        }
    };

    return(
        <MathJaxContext config={config} version={3}>
            <MathJax dynamic inline>
                {string}
            </MathJax>
        </MathJaxContext>
    );
}
const fir_coeffs = `
#define LP_FIR
//#define HP_FIR
//#define BP_FIR

#define FILTER_TAP_NUM  1119

#ifdef LP_FIR
const float taps[FILTER_TAP_NUM] = {};
#endif
#ifdef HP_FIR
const float taps[FILTER_TAP_NUM] = {};
#endif
#ifdef BP_FIR
const float taps[FILTER_TAP_NUM] = {};
#endif
`;

const main_local_var = `
float filter_buffer[FILTER_TAP_NUM] = {0};
float accumulator;
`;

const main_conv = `
if(is_sample_ready){
    input = ((float)adc_value * 2.0f)/4095.0f - 1.0f;
    
    for(uint16_t i = FILTER_TAP_NUM-1; i > 0; i--)
        filter_buffer[i] = filter_buffer[i-1];
    filter_buffer[0] = input;
    
    accumulator = 0;
    for(uint16_t i = 0; i < FILTER_TAP_NUM; ++i)
        accumulator += taps[i]*filter_buffer[FILTER_TAP_NUM - 1 - i];
    output = accumulator;
    is_sample_ready = false;
}
`;


function DSP_8(){
   return(
       <div className="em__post">
           <div className="em__post-title">
               <h1>FIR filter implementation</h1>
           </div>
           <div className="em__post-section">
               <h3>Overview:</h3>
               <p>
                   In this tutorial, we will use Python to generate the FIR coefficients and implement the filters as MCU firmware.
                   We will use our programmed signal generator to test the filters.
               </p>
           </div>

           <div className="em__post-section">
               <h3>Filter coefficients:</h3>
               <p>
                   The task of the last tutorial was to generate three FIR filters (LP, HP, BP)
                   with sampling frequency 5000Hz, pass-band ripple 0.2dB, stop-band attenuation -40dB,
                   and transition band 10Hz. The center frequencies were 50Hz, 800Hz and [50, 500] Hz.
                   Below you can see the magnitude response of the filters.
               </p>
               <Popup trigger={<img className="img_kaiser_lp_dsp-8 clickable_img" src={kaiser_lp} alt="kaiser_lp_dsp-8"/>} modal nested>
                   {close => (
                       <img className="em__img_full" src={kaiser_lp} alt="kaiser_lp_dsp-8" />
                   )}
               </Popup>
               <Popup trigger={<img className="img_kaiser_hp_dsp-8 clickable_img" src={kaiser_hp} alt="kaiser_hp_dsp-8"/>} modal nested>
                   {close => (
                       <img className="em__img_full" src={kaiser_hp} alt="kaiser_hp_dsp-8" />
                   )}
               </Popup>
               <Popup trigger={<img className="img_kaiser_bp_dsp-8 clickable_img" src={kaiser_bp} alt="kaiser_bp_dsp-8"/>} modal nested>
                   {close => (
                       <img className="em__img_full" src={kaiser_bp} alt="kaiser_bp_dsp-8" />
                   )}
               </Popup>
           </div>

           <div className="em__post-section">
               <h3>The discrete-time convolution:</h3>
               <p>
                   So, we have the FIR filtering algorithm
                   <ShowTex string="$$y[n] = \sum_{l=0}^{N-1}h[l]x[n-l],$$"/>
                   which is nothing more than a discrete-time convolution with finite elements.
               </p>
               <p>
                   Create a new project with the usual setup, or clone the project from tutorial 6.
                   Create a header file to store the number of FIR taps and the coefficients.
               </p>
               <div className="em_unselectable">
               <SyntaxHighlighter language="c" style={AtomOneDark}>
                   {fir_coeffs}
               </SyntaxHighlighter>
               </div>
               <p>
                   Paste the coefficients generated by the Python script.
                   Next we need two variables.
                   One to store the input samples, and one to accumulate the products for the convolution.
               </p>
               <div className="em_unselectable">
               <SyntaxHighlighter language="c" style={AtomOneDark}>
                   {main_local_var}
               </SyntaxHighlighter>
               </div>
               <p>
                   Then, when a new sample arrives, we normalise it and push it into the FIFO buffer.
                   We can reset the accumulator, adn perform the convolution (remember, the input buffer must be flipped).
               </p>
               <div className="em_unselectable">
               <SyntaxHighlighter language="c" style={AtomOneDark}>
                   {main_conv}
               </SyntaxHighlighter>
               </div>
               <p>
                   Build and upload the firmware.
               </p>
               <Popup trigger={<img className="img_lp_fir_imp_dsp-8 clickable_img" src={lp_fir_imp} alt="lp_fir_imp_dsp-8"/>} modal nested>
                   {close => (
                       <img className="em__img_full" src={lp_fir_imp} alt="lp_fir_imp_dsp-8" />
                   )}
               </Popup>
               <Popup trigger={<img className="img_hp_fir_imp_dsp-8 clickable_img" src={hp_fir_imp} alt="hp_fir_imp_dsp-8"/>} modal nested>
                   {close => (
                       <img className="em__img_full" src={hp_fir_imp} alt="hp_fir_imp_dsp-8" />
                   )}
               </Popup>
               <Popup trigger={<img className="img_bp_fir_imp_dsp-8 clickable_img" src={bp_fir_imp} alt="bp_fir_imp_dsp-8"/>} modal nested>
                   {close => (
                       <img className="em__img_full" src={bp_fir_imp} alt="bp_fir_imp_dsp-8" />
                   )}
               </Popup>
           </div>
           <div className="em__post-section">
               <p>
                   You can see the effect of the filters on the figures above.
                   The high-frequency one is a bit muted since the anti-aliasing RC filter attenuates its value.
                   Other than that, the discretisation noise is present - it's visible especially on the band-pass example.
                   This occurs, since we only have samples, but the monitor shows it using linear interpolation. You could change it to step or spline.
                   Notice the constant phase shift of the output.
               </p>
           </div>

           <div className="em__post-section">
               <h3>Further work:</h3>
               <p>
                   <ol>
                       <li>
                           Implement the FIR filter function (convolution) with the use of fixed point math.
                           Compare the speed and memory allocation of the fixed and floating point implementations.
                       </li>
                       <li>
                           Presume that you want to keep 10Hz, 100Hz, 1000Hz components from a uniform white noise infested signal.
                           How would you synthesize a single FIR filter that will be adequate for the job?
                       </li>

                   </ol>
               </p>
           </div>


           <div className="em__post-navigation">

               <NavLink to="./../dsp-7">
                   <Button btnID={"leftBTN"} buttonSize="btn--medium"> Prev Post</Button>
               </NavLink>

               <NavLink to="./../dsp-9">
                   <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
               </NavLink>
           </div>
       </div>
   );
}

export default DSP_8;