import React from "react";
import "./mcu_hal5.css";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";

import lcd1 from "./lcd_1.jpg";
import lcd2 from "./lcd_2.jpg";
import lcd_prog_1 from "./LCD_prog_1.jpg";
import lcd3 from "./lcd_3.jpg";

const opcode=`
#define CLEAR_DISPLAY \t\t0x01

#define RETURN_HOME \t\t0x02

#define ENTRY_MODE_SET \t\t0x04
#define OPT_S \t\t\t0x01 \t// Shift entire display to right
#define OPT_INC \t\t0x02 \t// Cursor increment

#define DISPLAY_ON_OFF_CONTROL \t0x08
#define OPT_D \t\t\t0x04 \t// Turn on display
#define OPT_C \t\t\t0x02 \t// Turn on cursor
#define OPT_B \t\t\t0x01 \t// Turn on cursor blink

#define CURSOR_DISPLAY_SHIFT \t0x10 \t// Move and shift cursor
#define OPT_SC \t\t\t0x08
#define OPT_RL \t\t\t0x04

#define FUNCTION_SET \t\t0x20
#define OPT_DL \t\t\t0x10 \t// Set interface data length
#define OPT_N \t\t\t0x08 \t// Set number of display lines
#define OPT_F \t\t\t0x04 \t// Set alternate font
#define SETCGRAM_ADDR \t\t0x040
#define SET_DDRAM_ADDR \t\t0x80 \t// Set DDRAM address
`;

const delay=`
extern TIM_HandleTypeDef htim6;

static void delay(uint16_t us){
    __HAL_TIM_SET_COUNTER(&htim6, 0);
    while(__HAL_TIM_GET_COUNTER(&htim6) < us);
}
`;

const send2lcd = `
static void send_to_lcd(char data, int rs){
    HAL_GPIO_WritePin(LCD_RS_GPIO_Port, LCD_RS_Pin, rs);

    uint32_t odr = GPIOC->ODR;
    odr |= (0x0000000F & data);
    odr &= (0xFFFFFFF0 | data);
    GPIOC->ODR = odr;

    HAL_GPIO_WritePin(LCD_EN_GPIO_Port, LCD_EN_Pin, 1);
    delay(20);
    HAL_GPIO_WritePin(LCD_EN_GPIO_Port, LCD_EN_Pin, 0);
    delay(20);
}
`;

const sendcmd = `
void lcd_send_cmd(char cmd){
    char temp_to_send;

    temp_to_send = ((cmd >> 4) & 0x0F);
    send_to_lcd(temp_to_send, 0);

    temp_to_send = ((cmd) & 0x0F);
    send_to_lcd(temp_to_send, 0);
}
`;

const init=`
void lcd_init(void){
    HAL_Delay(50);
    lcd_send_cmd(0x33);
    HAL_Delay(5);
    lcd_send_cmd(0x32);
    HAL_Delay(1);
    lcd_send_cmd(FUNCTION_SET | OPT_N);
    HAL_Delay(10);
    lcd_send_cmd(CLEAR_DISPLAY);
    HAL_Delay(1);
    lcd_send_cmd(DISPLAY_ON_OFF_CONTROL | OPT_D);
}
`;

const sendstr=`
void lcd_send_string (char *str){
    while (*str) lcd_send_data (*str++);
}
`;

function MCU_HAL5(){
    return(
        <div className="em__post">
            <div className="em__post-title">
                <h1>16x2 LCD interface in 4b mode</h1>
            </div>
            <div className="em__post-section">
                <h3>Aim of this tutorial:</h3>
                <p>
                    In this tutorial we will combine the GPIOs and TIM periphery to create a frequency counter with physical output,
                    using the classic 16x2 Alphanumeric LCD.
                </p>
            </div>
            <div className="em__post-section">
                <h3>16x2 LCD:</h3>
                <p>
                    The 16x2 Alphanumeric LCD has that name because it can display 16 Alphanumeric characters in 2 rows.
                    There are many configurations available, like 8x1, 8x2, 10x2, 16x1, 16x2,
                    16x4, 20x4, 24x2, 40x4, but the most used one is the 16x2 for some reason.
                    These LCDs come in multiple flavors: The display type can be
                    TN (narrow view angle, low contrast, cheapest to manufacture).
                    STN (wide view angle, nice contrast, cheap to manufacture),
                    FSTN (better background color, best contrast, expensive to manufacture).
                    They can also be  positive (the background is lit, the active pixel is dark)
                    or negative (the background is dark, the active pixel is lit).
                    We can chose from a multitude of background colors: Yellow/Green, red, blue, white, green, amber, even without background illumination.
                    <a href="https://www.tme.eu/Document/0e5094927beb0f841dea92648aef9c36/Raystar_backlight%26color%20matching.JPG">Here</a>'s a table with some of the LCDs.
                </p>
                <img className="img_lcd1" src={lcd1} alt="lcd1" />
                <p>
                    On the display, each of the 16x2 = 32 characters are made out of 5*8 = 40 pixels, so we would have to work out the logic to operate 1280 pixels.
                    Luckily for us there's an interface IC on the back of these displays (like the HD44780), so our task is to create an interface driver.
                </p>
                <img className="img_lcd2" src={lcd2} alt="lcd2" />
                <p>
                    This display has 16 pins, which are shown in the following table:
                </p>

                <div className="em__post-section_tableContainer">
                    <table className="em__post-section_table">
                        <tr>
                            <th>Pin No.</th>
                            <th>Pin Name</th>
                            <th>Pin Description</th>
                        </tr>
                        <tr>
                            <th>1</th>
                            <td>VSS</td>
                            <td>Ground of the LCD</td>

                        </tr>
                        <tr>
                            <th>2</th>
                            <td>VDD</td>
                            <td>Positive supply of the LCD</td>

                        </tr>
                        <tr>
                            <th>3</th>
                            <td>VEE</td>
                            <td>voltage for LCD contrast. Connect to a POT from VSS to GND.</td>

                        </tr>
                        <tr>
                            <th>4</th>
                            <td>RS</td>
                            <td>Register select. Toggles between Command/Data register.</td>

                        </tr>
                        <tr>
                            <th>5</th>
                            <td>R/W</td>
                            <td>Toggles between Read/Write operation.</td>

                        </tr>
                        <tr>
                            <th>6</th>
                            <td>E</td>
                            <td>Must be held high to perform a read/write operation.</td>

                        </tr>
                        <tr>
                            <th>7 - 14</th>
                            <td>D0 - D7</td>
                            <td>8b parallel data/command pins.</td>

                        </tr>
                        <tr>
                            <th>15</th>
                            <td>LEDA</td>
                            <td>Backlight LED Anode positive supply.</td>

                        </tr>
                        <tr>
                            <th>16</th>
                            <td>LEDK</td>
                            <td>Backlight LED cathode, GND.</td>
                        </tr>
                    </table>
                </div>

                <p>
                    We can use the LCD in 8b mode, meaning we send/receive the data/command in single bytes or
                    in 4b mode in which we send/receive nibbles. The former uses all the data pins, while the latter uses
                    pins D4 - D7.
                </p>

                <p>
                    We will use the 4b parallel communication mode to reduce the number of connections required.
                    You can find the interface IC datasheet <a href="https://www.sparkfun.com/datasheets/LCD/HD44780.pdf">here</a>.
                    The following table shows the HEX code of some important instructions:
                </p>
            </div>

            <div className="em__post-section">
                <h3>LCD driver and init function:</h3>

                <Popup trigger={<img className="img_lcd_prog_1 clickable_img" src={lcd_prog_1} alt="lcd_prog_1"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={lcd_prog_1} alt="lcd_prog_1" />
                    )}
                </Popup>
                <br/>
                <br/>
                <p>
                    Create a new project and configure the clock and debug options as before.
                    We will use TIM2 in input capture mode for the frequency counter and some digital outputs for the LCD.
                </p>
                <p>
                    Activate TIM6 and set ip up for 1us prescaled timing (Prescaler: 170 for 170MHz, Counter period: 1000 but it does not matter, since we will read the CNT only).
                    Furthermore, create 6 Digital outputs (4 for data, two for EN/RS).
                    We will tie the R/W pin to ground because we only want to write deta -
                    the advantage is that we reduce the number of data lines by one, the disadvantage is that we must include a generous amount of delay,
                    since we can't probe for the completion of an operation.
                </p>
                <p>
                    It will be important latter on that the data/cmd nibble is set on adjacent logical pins (PC[0..3] in my case).
                </p>
                <p>
                    Lastly, set up TIM2 with Prescaler 0, full 32b scale Counter period, and enable the global interrupt, and generate the project.
                </p>
            </div>

            <div className="em__post-section">
                <p>
                    Create a header and source file for the LCD driver functions (eg. <i>lcd.h</i>, <i>lcd.c</i>) in the Inc and Src folders respectively.
                    Start in the source file by including the <i>lcd.h</i> and the <i>main.h</i>.
                    The former one will contain the callable function headers, while the latter contains some usefully definitions.
                </p>
                <p>
                    Next we need to define the most important opcodes for the LCD driver:
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {opcode}
                </SyntaxHighlighter>
                <p>
                    Then, we will create a precise delay function using the TIM6.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {delay}
                </SyntaxHighlighter>
                <p>
                    Since the TIM6 handle is only declared in the main, here we make an external reference.
                    In the function, first we clear the CNT, and then we poll the CNT register for the required amount of time in micro-seconds.
                </p>
                <p>
                    Next, let's tackle the task of sending a nibble to the LCD.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {send2lcd}
                </SyntaxHighlighter>
                <p>
                    At this stage we don't care if it's command or data, so we set the R/S output as required.
                    Now comes the part where it is important to have the pins in logical order.
                    If we would have been using some random pins with HAL functions, we would have to check every bit and set or clear it with an instruction.
                    That's four conditional operations and four I/O instructions, plus the filler instructions hidden in the functions.
                </p>
                <p>
                    In our case, we read the current logical state of the whole port C.
                    We set the data using logical OR instruction (masked to the lowest nibble).
                    Similarly, we can clear the data using logical AND instruction.
                    When we are done, we can rewrite the data to the GPIO output register.
                    This requires only four instruction, and the data appears on the outputs at the same time.
                    We could further optimize this operation, but that is outside the scope of the tutorial.
                </p>
                <p>
                    Lastly, we strobe the EN pin, which with some delay to signal the end of the transaction (20us according to the data sheet).
                </p>
                <p>
                    We can distinguish the command and data information with the state of the R/S pin.
                    The command requires low R/S, while the data requires high R/S.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {sendcmd}
                </SyntaxHighlighter>
                <p>
                    The snippet above shows the function for sending a command byte.
                    We send the low nibble and then the high nibble with R/S = 0.
                    The data sending function is done in similar fashion with RS = 1.
                </p>

                <p>
                    And finally, the init function:
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {init}
                </SyntaxHighlighter>
                <p>
                    The first two instructions are used to tell the driver IC that we intend to use it in 4b mode.
                    The next instruction sets our display to 16x2.
                    The fourth instruction is used to clear any residual data from the memory,
                    and the last instruction turns on the display without the annoying blinking cursor.
                    If you use the instruction with OPT_B and call it in the main, you should see a clear display with blinking cursor.
                    Try it out.
                </p>
            </div>

            <div className="em__post-section">
                <h3>String and position manipulation:</h3>
                <p>
                    We need three more functions to have a usable and complete alpha-numeric display driver.
                    First, it would be nice to have a stand-alone function to clear the display.
                    This can be done by sending the CLEAR_DISPLAY command followed by a 1-2 us delay.
                    Second, if we have 2 data lines (16x2) then we should be able to navigate those lines.
                    The columns in the first line (row) is accessed by sending the column number logically or-ed with 0x80,
                    while the columns in the second line is accessed by sending the column number logically or-ed with 0xC0.
                    Third, a string is sent by sending a chain of character data:
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {sendstr}
                </SyntaxHighlighter>
            </div>

            <div className="em__post-section">
                <h3>The main function:</h3>
                <p>
                    The main file consists of the same functions and interrupts as the main files in the previous tutorial.
                    The difference is that in this case, before starting TIM2, we start the base timer of TIM6,
                    then we issue an init and clear command for the LCD.
                    In the first line we could write something like <i>Freq. Counter</i> using sprintf and a 16 element wide char array.
                </p>
                <p>
                    In the infinite loop of the main function, we can write the result of the frequency count in the second line using the
                    <i>sprintf(buffer, "f = %lu", frequency);</i>. Oh, and let's add a 200ms delay just to be sure that the LCD and the driver IC can handle the speed.
                </p>
                <p>
                    Of course, we could write functions to create custom characters (eg. symbols for an audio spectrum analyser), but this is only an intro to the LCD driver functions.
                    For now, here's a picture of the counter where, the input is connected to a 1250Hz square-wave signal from a signal generator.
                </p>
                <img className="img_lcd3" src={lcd3} alt="img_lcd3"/>
                <p>
                    The files for the project generation, and the drivers can be found <a href="https://gitlab.com/stm32_mcu_group/stm32_hal/5_16x2lcd_driver.git">here</a>.
                </p>
            </div>


            <div className="em__post-navigation">

                <NavLink to="./../stm-hal-4">
                    <Button btnID={"leftBTN"} buttonSize="btn--medium"> Previous Post</Button>
                </NavLink>

                <NavLink to="./../stm-hal-6">
                    <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
                </NavLink>
            </div>
        </div>
    );
}

export default MCU_HAL5;