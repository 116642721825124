import React from "react";
import "./stm_rtos3.css";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";

import queue_mx from "./queue_mx.jpg";
import basic_queue_test from "./basic_queue_test.jpg";
import queue_struct_mx from "./queue_struct_mx.jpg";

const basic_tx = `
uint8_t message = 0;
const uint8_t text[] = "Sending data.\\r\\n";
/* Infinite loop */
for(;;){
    HAL_GPIO_WritePin(TA_GPIO_Port, TA_Pin, GPIO_PIN_RESET);
    HAL_UART_Transmit(&huart2, text, sizeof(text), 1);
    HAL_GPIO_WritePin(TA_GPIO_Port, TA_Pin, GPIO_PIN_SET);
    osMessageQueuePut(Queue1Handle, &message, 0, 200);
    if(++message > 9) message = 0;
    HAL_GPIO_WritePin(TA_GPIO_Port, TA_Pin, GPIO_PIN_RESET);
    osDelay(1000);
}
`;

const basic_rx = `
uint8_t result = 0;
uint8_t text1[] = "Receiving:  .\\r\\n ";
/* Infinite loop */
for(;;){
    HAL_GPIO_WritePin(TB_GPIO_Port, TB_Pin, GPIO_PIN_RESET);
    HAL_GPIO_WritePin(TB_GPIO_Port, TB_Pin, GPIO_PIN_SET);
    osMessageQueueGet(Queue1Handle, &result, NULL, 2000);
    text1[11] = result + '0';
    HAL_UART_Transmit(&huart2, text1, strlen(text1), 1);
    HAL_GPIO_WritePin(TB_GPIO_Port, TB_Pin, GPIO_PIN_RESET);
}
`;

const struct_def = `
typedef struct{
    uint8_t value;
    uint8_t source;
}myStruct;
`;

const struct_tx1 = `
const uint8_t text[] = "Sending data in S1.\\r\\n";
myStruct dataTX1;
dataTX1.source = '1';
dataTX1.value = 0;
/* Infinite loop */
for(;;){
    HAL_GPIO_WritePin(TA_GPIO_Port, TA_Pin, GPIO_PIN_RESET);
    HAL_UART_Transmit(&huart2, text, sizeof(text), 1);
    HAL_GPIO_WritePin(TA_GPIO_Port, TA_Pin, GPIO_PIN_SET);
    osMessageQueuePut(Queue1Handle, &dataTX1, 0, 200);
    if(++dataTX1.value > 9) dataTX1.value = 0;
    HAL_GPIO_WritePin(TA_GPIO_Port, TA_Pin, GPIO_PIN_RESET);
    osDelay(1000);
}
`;

const struct_rx = `
myStruct dataRX;
uint8_t text1[] = "Receiving: 0 from S0.\\r\\n ";
/* Infinite loop */
for(;;){
    HAL_GPIO_WritePin(TB_GPIO_Port, TB_Pin, GPIO_PIN_RESET);
    HAL_GPIO_WritePin(TB_GPIO_Port, TB_Pin, GPIO_PIN_SET);
    osMessageQueueGet(Queue1Handle, &dataRX, NULL, 2000);
    text1[11] = dataRX.value + '0';
    text1[19] = dataRX.source + '0';
    HAL_UART_Transmit(&huart2, text1, strlen(text1), 1);
    HAL_GPIO_WritePin(TB_GPIO_Port, TB_Pin, GPIO_PIN_RESET);
}
`;


function STM_RTOS3(){
    return(
        <div className="em__post">
            <div className="em__post-title">
                <h1>CMSIS Tasks</h1>
            </div>

            <div className="em__post-section">
                <h3>Aim of this tutorial:</h3>
                <p>
                    In this tutorial, we will use the queue objects as a thread-safe alternative to global variables.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Some notion about queues:</h3>
                <p>
                    In the exercise problem of the previous tutorial, we used global variables to communicate between the tasks.
                    Depending on the variable length and the execution frequency of the tasks some problems may arise:
                    <ul>
                        <li>If multiple tasks can write to the same global variable, the variable could be overwritten before the reader task could access it.</li>
                        <li>If the data width  is larger than the width of the data buss (eg. a 64b data in a processor with 32b data width),
                            the read/write operations are done in multiple steps. Part of the data could be overwritten with data from another task.</li>
                    </ul>
                    <br/>
                    One way to solve these problem is to use atomic operations (can be executed using a single instruction or they are uninterrupted).
                    Kernel objects or flags could be used to tell which part of the task is a critical section,
                    so that it can be executed without interruption - semaphores and mutexes can achieve this, but we will use them latter on.
                    A queue can be used to pass messages uninterrupted between tasks.
                </p>

                <p>
                    Queues are data transfer pipes configured as FIFO buffers by default, but the can be configured as LIFO too.
                    All data passed through a single queue must be of the same type declared during the creation of the queue -
                    this data can be a simple variable (message) or a structure (mail).
                    The length of the queue is specified at the declaration as the number of items which can be sent.
                </p>
                <p>
                    If a task wants to send data using a full queue, the state of the task will be changed to blocked.
                    Similarly, if a task wants to read data from an empty queue, it will be put into blocked state.
                    If multiple tasks are blocked waiting to send or receive data,
                    only the one with the highest priority will be unblocked.
                    In case that the blocked tasks are of equal priority, the longest waiting one gets unblocked.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Basic queue usage between two tasks:</h3>
                <p>
                    At first, let's create a project in which we have two tasks of equal priority, and a queue serves as data pipe.
                    T1 puts a single digit number into the queue every second, and increments the value of the number.
                    T2 gets the number from the queue with a timeout of 2 seconds and displays it using UART.
                </p>
                <p>
                    We can copy the project from the previous tutorial.
                    Open up the ioc file and go to Middleware/FREERTOS/Tasks and Queues: Create a new queue consisting of eight uint8_t elements.
                </p>
                <Popup trigger={<img className="img_queue_mx_rtos3 clickable_img" src={queue_mx} alt="queue_mx_rtos3"/>} modal nested>
                    {
                        close =>(
                            <img className="em__img_full" src={queue_mx} alt="queue_mx_rtos3"/>
                        )
                    }
                </Popup>
                <p>
                    Generate the project and open up the <i>app_freertos.c</i> source.
                    Under the Task declarations, you can see the <i>Queue1Handle</i> and the attribute, which only contains a name.
                    The queue is initialized before the tasks using the <i>osMessageQueueNew(8, sizeof(uint8_t), &Queue1_attributes)</i> in the init function.
                </p>
                <p>
                    Go to the Task1 implementation:
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {basic_tx}
                </SyntaxHighlighter>
                <p>
                    we need a variable to store the single digit number, and a string to display.
                    In the loop, I've placed some GPIO toggling, so that I can look at the timing using a logic analyzer.
                    First we send a string on UART so that we know that the Task 1 is being executed.
                    Then we can put the data in the queue using the <i>osMessageQueuePut(Queue1Handle, &message, 0, 200)</i> function -
                    here the first argument is the queue handle, the second one is the pointer to the message, next the priority and lastly the timeout.
                    After that we block the task for a second.
                    <br/>
                    Finally, the receiving Task2:
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {basic_rx}
                </SyntaxHighlighter>
                <p>
                    Similarly as before, we need a variable to store the received message, and a string for display.
                    We can retrieve an item from a queue using the <i>osMessageQueueGet(Queue1Handle, &result, NULL, 2000)</i> function -
                    where the first argument is the queue handle, the second one is the pointer to the storage variable.
                    Here the priority is not used, and I've specified a longer timeout of two seconds.
                    <br/>
                        <span className="em__post_warning">
                            Important note: you could redirect the <i>_write</i> function to use the printf in conjunction with the UART,
                            but when you add a variable into the function (e.g. <i>printf("message: %d", message)</i>),
                            the memory usage will greatly increase, so you must increase the stack allocation of the project,
                            and the stack of the task that uses it.
                        </span>
                    <br/>
                        Either way, build the project and upload the firmware.
                        Study how the sending and the reception works using the RTOS view in Debug mode.
                </p>
                <img className="img_basic_queue_test_rtos3" src={basic_queue_test} alt="basic_queue_test_rtos3"/>
                <p>
                    Remove the delay from the sender task.
                    Increase the priority of the sender task and check the behaviour.
                    Then reset the priority of the sender and increase the priority of the receiver task.
                    What happens to the queue and task execution?
                </p>

            </div>

            <div className="em__post-section">
                <h3>Sending structures over the queues:</h3>
                <p>
                    As I've mentioned in the beginning, queues can be used to send simple data or composite data (array, struct).
                    Let's use the previous project by adding another sender task, and updating the queue with a custom struct data.
                </p>
                <Popup trigger={<img className="img_queue_struct_mx_rtos3 clickable_img" src={queue_struct_mx} alt="queue_struct_mx_rtos3"/>} modal nested>
                    {
                        close =>(
                            <img className="em__img_full" src={queue_struct_mx} alt="queue_struct_mx_rtos3"/>
                        )
                    }
                </Popup>
                <p>
                    In the code, first we have to define our struct, which, in my case, was an underwhelming composition of two 8b unsigned values.
                    The first one to store a single digit number, the second to store the ID of the sender.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {struct_def}
                </SyntaxHighlighter>
                <p>
                    The sender behaves exactly as in the previous case. This time, we need to set two values before sending the data.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {struct_tx1}
                </SyntaxHighlighter>
                <p>
                    Create a similar program for the second sender, only change the source to 2.
                    <br/>
                    The receiver will "unpack" the data and display it on the COM port.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {struct_rx}
                </SyntaxHighlighter>
                <p>
                    Upload the firmware and check the operation on the COM port and in Debug mode.
                    <br/>
                    using this method, we can keep a single queue and have multiple senders and a single receiver.
                    The receiver gets a message where it is evidently shown where it came from.
                    The same can not be said about a scenario where there is a single sender and multiple receivers
                    - either the task with the higher priority will take the queue or the one which waited for longer.
                    This behaviour is non-deterministic.
                </p>

            </div>


            <div className="em__post-section">
                <p>
                    <b>Exercise problem:</b> Try to implement the problem stated in the previous tutorial with the use of queues.
                </p>
            </div>

            <div className="em__post-navigation">

                <NavLink to="./../stm-rtos-2">
                    <Button btnID={"leftBTN"} buttonSize="btn--medium"> Previous Post</Button>
                </NavLink>

                <NavLink to="./../stm-rtos-4">
                    <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
                </NavLink>
            </div>
        </div>
    );
}

export default STM_RTOS3;