import React from "react";
import "./stm_rtos4.css";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";

import bSem_nvic_mx from "./bSem_nvic_mx.jpg";
import bSem_sem_mx from "./bSem_sem_mx.jpg";
import bSem_task_mx from "./bSem_task_mx.jpg";
import cSem_task_mx from "./cSem_task_mx.jpg";
import cSem_sem_mx from "./cSem_sem_mx.jpg";

const bSem_task1 = `
const uint8_t string[] = "Task1 action\\r\\n";
/* Infinite loop */
for(;;){
  osDelay(2000);
  osSemaphoreRelease(bSem1Handle);
  HAL_GPIO_WritePin(TA_GPIO_Port, TA_Pin, GPIO_PIN_SET);
  HAL_UART_Transmit(&huart2, string, strlen((const char*)string), 100);
  HAL_GPIO_WritePin(TA_GPIO_Port, TA_Pin, GPIO_PIN_RESET);
}
`;

const bSem_task2 = `
const uint8_t string[] = "Task2 action\\r\\n";
/* Infinite loop */
for(;;){
  HAL_GPIO_WritePin(TB_GPIO_Port, TB_Pin, GPIO_PIN_SET);
  osSemaphoreAcquire(bSem1Handle, 4000);
  HAL_UART_Transmit(&huart2, string, strlen((const char*)string), 100);
  HAL_GPIO_WritePin(TB_GPIO_Port, TB_Pin, GPIO_PIN_RESET);
}
`;

const bSem_isr = `
void HAL_GPIO_EXTI_Callback(uint16_t GPIO_Pin){
    const uint8_t string[] = "IRQ action\\r\\n";

    HAL_GPIO_WritePin(TC_GPIO_Port, TC_Pin, GPIO_PIN_SET);
    HAL_UART_Transmit(&huart2, string, strlen((const char*)string), 100);
    osSemaphoreRelease(bSem1Handle);
    HAL_GPIO_WritePin(TC_GPIO_Port, TC_Pin, GPIO_PIN_RESET);
}
`;

const cSem_task1 = `
const uint8_t string[] = "Task1 action\\r\\n";
/* Infinite loop */
for(;;){
  osSemaphoreRelease(cSem1Handle);
  HAL_GPIO_WritePin(TA_GPIO_Port, TA_Pin, GPIO_PIN_SET);
  HAL_UART_Transmit(&huart2, string, strlen((const char*)string), 100);
  HAL_GPIO_WritePin(TA_GPIO_Port, TA_Pin, GPIO_PIN_RESET);
  osDelay(2000);
}
`;

const cSem_task2 = `
const uint8_t string[] = "Task2 action\\r\\n";
/* Infinite loop */
for(;;){
  osSemaphoreRelease(cSem1Handle);
  HAL_GPIO_WritePin(TB_GPIO_Port, TB_Pin, GPIO_PIN_SET);
  HAL_UART_Transmit(&huart2, string, strlen((const char*)string), 100);
  HAL_GPIO_WritePin(TB_GPIO_Port, TB_Pin, GPIO_PIN_RESET);
  osDelay(2000);
}
`;

const cSem_task3 = `
const uint8_t string[] = "Task3 action\\r\\n";
/* Infinite loop */
for(;;){
  osSemaphoreAcquire(cSem1Handle, 1000);
  osSemaphoreAcquire(cSem1Handle, 1000);
  HAL_GPIO_WritePin(TB_GPIO_Port, TB_Pin, GPIO_PIN_SET);
  HAL_UART_Transmit(&huart2, string, strlen((const char*)string), 100);
  HAL_GPIO_WritePin(TB_GPIO_Port, TB_Pin, GPIO_PIN_RESET);
}
`;

const cSem_isr = `
const uint8_t string[] = "IRQ action\\r\\n";

HAL_GPIO_WritePin(TC_GPIO_Port, TC_Pin, GPIO_PIN_SET);
HAL_UART_Transmit(&huart2, string, strlen((const char*)string), 100);
HAL_GPIO_WritePin(TC_GPIO_Port, TC_Pin, GPIO_PIN_RESET);
osSemaphoreRelease(cSem1Handle);
`;

function STM_RTOS4(){
    return(
        <div className="em__post">
            <div className="em__post-title">
                <h1>CMSIS Semaphores</h1>
            </div>

            <div className="em__post-section">
                <h3>Aim of this tutorial:</h3>
                <p>
                    In this tutorial we will use another object from the RTOS package: semaphores.
                    We will try out the different types of semaphores through examples.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Semaphores:</h3>
                <p>
                    Semaphores are queues with length 1 and size 0.
                    Their implementation is based on that of the queues.
                    They are used to synchronize tasks with other events in the systems, especially interrupts.
                    Waiting for a semaphore is equal to the <i>wait()</i> function - task is in blocked state.
                </p>
                <p>
                    There are four types of semaphores in FreeRTOS:
                    <ul>
                        <li><b>Binary</b>: it is a basic on-off guard mechanism</li>
                        <li><b>Counting</b>: it permits multiple give and take operations</li>
                        <li><b>Mutex</b>: token based mutual exclusion type semaphore</li>
                        <li><b>Recursive (re-entrant)</b>: when taken, it will only allow the code to take it again recursively,
                        A recursive semaphore can only be taken by another task if it was given back as many time as it was taken by the first task.</li>
                    </ul>
                </p>
                <p>
                    Give/turn on a semaphore can be done from any task or from interrupt routine.
                    Take/turn off can only be done from the task.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Binary Semaphores:</h3>
                <p>
                    Binary semaphores are used for both mutual exclusion and synchronisation purposes.
                    Think of a binary semaphore as a queue that can only hold one item.
                    The queue can therefore only be empty or full (hence binary).
                    Tasks and interrupts using the queue don't care what the queue holds - they only want to know if the queue is empty or full.
                    This mechanism can be exploited to synchronise (for example) a task with an interrupt.
                </p>
                <p>
                    Let's have a scenario, where we have tasks T1 and T2 and an ISR function,
                    and we want to make sure that T1 starts before T2; the ISR can also release the semaphore.
                    We will use the on-board button as an external interrupt source.
                </p>
                <p>
                    Open the project from the previous tutorial, it should contain the button and the respective interrupt enabled.
                    Just in case, check that PC13 is set as external interrupt source and that the respective interrupts
                    are turned on in the NVIC tab with a preemption priority of 5.
                </p>
                <Popup trigger={<img className="img_bSem_nvic_mx_rtos4 clickable_img" src={bSem_nvic_mx} alt="bSem_nvic_mx_rtos4"/>} modal nested>
                    {
                        close =>(
                            <img className="em__img_full" src={bSem_nvic_mx} alt="bSem_nvic_mx_rtos4"/>
                        )
                    }
                </Popup>
                <p>
                    Next, in Middleware/FREERTOS, remove the queue - we won't use it right now, and leave two tasks with a stack of 256 words.
                    You might have to increase the allocated heap under Config parameters / Memory management settings.
                </p>
                <Popup trigger={<img className="img_bSem_task_mx_rtos4 clickable_img" src={bSem_task_mx} alt="bSem_task_mx_rtos4"/>} modal nested>
                    {
                        close =>(
                            <img className="em__img_full" src={bSem_task_mx} alt="bSem_task_mx_rtos4"/>
                        )
                    }
                </Popup>
                <p>
                    Under Timers/Semaphores, add a new binary semaphore and generate the project.
                </p>
                <Popup trigger={<img className="img_bSem_sem_mx_rtos4 clickable_img" src={bSem_sem_mx} alt="bSem_sem_mx_rtos4"/>} modal nested>
                    {
                        close =>(
                            <img className="em__img_full" src={bSem_sem_mx} alt="bSem_sem_mx_rtos4"/>
                        )
                    }
                </Popup>

                <p>
                    After the project files are generated, open the <i>app_freertos.c</i> source.
                    you can see in the init function that the semaphore gets created before the tasks using the fucntion
                    <i>osSemaphoreNew(1, 0, &bSem1_attributes)</i>, where the first argument is the max count,
                    the second argument is the initial state and the last argument is the attribute which only contains the name.
                    If the initial state is set to 1, change it to 0 so that we can release the semaphore.
                </p>
                <p>
                    The Task1 is blocked for 2s, after which the semaphore is released, then the task action happens.
                    Since the semaphore is in taken state initially, Task1 can execute the process without being put into blocked state.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {bSem_task1}
                </SyntaxHighlighter>
                <p>
                    The Task2 tries to acquire the semaphore with a 4s timeout interval.
                    If the acquisition is successful, Task 2 can execute the rest of the operation.
                    Since the semaphore is taken initially, the task gets blocked.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {bSem_task2}
                </SyntaxHighlighter>
                <p>
                    If we push the button, the external interrupt handler function will be executed.
                    Here we release the semaphore, so that Task2 will be able to take it.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {bSem_isr}
                </SyntaxHighlighter>
                <p>
                    Build the project, upload the firmware and test the behaviour using the RTOS perspective in debug mode.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Counting Semaphores:</h3>
                <p>
                    Counting semaphores can be interpreted as queues with length greater than one,
                    where we are not interested in the data content, just whether the queue is empty or not.
                    <br/>
                    These semaphores are used mostly to count events (the event handler will give a semaphore at each
                    occurring event, while a handler task will take a semaphore each time it processes the event),
                    to manage resources (the count number shows the available resources, if there are free resources a task can take some,
                    if a task finishes with the resource, it gives the semaphore back).
                </p>
                <p>
                    Let's have a scenario, where we have 3 tasks of equal priority. T1 and T2 release semaphores, T3 waits for two tokens.
                </p>
                <p>
                    Open the ioc file, and add another task with normal priority.
                </p>
                <img className="img_cSem_task_mx_rtos4" src={cSem_task_mx} alt="cSem_task_mx_rtos4"/>
                <p>
                    Go to Timers and semaphores, remove the binary semaphore and add a counting one with 2 Counts, then generate the project.
                </p>
                <Popup trigger={<img className="img_cSem_sem_mx_rtos4 clickable_img" src={cSem_sem_mx} alt="cSem_sem_mx_rtos4"/>} modal nested>
                    {
                        close =>(
                            <img className="em__img_full" src={cSem_sem_mx} alt="cSem_sem_mx_rtos4"/>
                        )
                    }
                </Popup>
                <p>
                    The initialisation of  the counting semaphore is done with the same function as before.
                    This time though, the max count is two.
                    Set the initial count to zero, which means that there are no free tokens.
                    <br/>
                    Task1 releases a token at the start of the execution.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {cSem_task1}
                </SyntaxHighlighter>
                <p>
                    Task2 has the same behaviour as Task1.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {cSem_task2}
                </SyntaxHighlighter>
                <p>
                    Task3 takes two tokens from the semaphore.
                    If there are two tokens in the semaphore, the next instructions will be executed, otherwise Task3 gets blocked.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {cSem_task3}
                </SyntaxHighlighter>
                <p>
                    We release a token inside the interrupt routine.
                    This means that if we press the button after the execution of Task1 or Task2, Task3 can be executed.
                    Similarly, if we press the button twice after Task3 was executed, it will run again.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {cSem_isr}
                </SyntaxHighlighter>
                <p>
                    Upload the firmware and check the behaviour of the semaphore in the RTOS perspective of the Debug.
                </p>
            </div>

            <div className="em__post-navigation">

                <NavLink to="./../stm-rtos-3">
                    <Button btnID={"leftBTN"} buttonSize="btn--medium"> Previous Post</Button>
                </NavLink>

                <NavLink to="./../stm-rtos-5">
                    <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
                </NavLink>
            </div>
        </div>
    );
}

export default STM_RTOS4;