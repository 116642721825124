import React from "react";
import "./dsp_2.css";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";
import {MathJax, MathJaxContext} from "better-react-mathjax";

import pwm_dma_scheme_1 from "./pwm_dma_scheme1.jpg";
import mx_adc from "./mx_adc.jpg";
import mx_tim2 from "./mx_tim2.jpg";
import mx_tim3 from "./mx_tim3.jpg";
import mx_tim4_1 from "./mx_tim4_1.jpg";
import mx_tim4_2 from "./mx_tim4_2.jpg";
import monitor1 from "./monitor_1.jpg";
import monitor2 from "./monitor_2.jpg";
import monitor3 from "./monitor_3.jpg";

function ShowTex({string}){
    const config = {
        loader: { load: ["[tex]/html"]},
        tex: {packages: {"[+]": ["html"]},
            inlineMath: [["$", "$"]],
            displayMath: [["$$", "$$"]]
        }
    };

    return(
        <MathJaxContext config={config} version={3}>
            <MathJax dynamic inline>
                {string}
            </MathJax>
        </MathJaxContext>
    );
}

const sin_gen = `
import numpy as np
import matplotlib.pyplot as plt

N = 512
M = np.power(2, 10) - 1
n = np.linspace(0, 2*np.pi, N)
x = np.sin(n)
x = (x - np.min(x))/2*M
y = np.reshape(x, (N//16, 16))
np.savetxt('sin_table.txt', y, fmt="%d,", delimiter=" ")

plt.figure()
plt.plot(n, x)
plt.show()
`;

const defines = `
#define NUM_SAMPLE  512
#define F_TIM_CLK   100000000
#define F_SIGNAL    10
`;

const private_variables=`
const uint32_t sine_wave_LUT[NUM_SAMPLE] = {};

const uint32_t destination_address = (uint32_t) &(TIM2->CCR1);
const uint32_t TIM4_Ticks = F_TIM_CLK / (NUM_SAMPLE * F_SIGNAL);

extern DMA_HandleTypeDef hdma_tim4_ch1;

volatile bool is_sample_ready = false;
volatile uint16_t adc_value = 0;
`;

const adc_irq = `
void HAL_ADC_ConvCpltCallback(ADC_HandleTypeDef* hadc){
    adc_value = HAL_ADC_GetValue(hadc);
    is_sample_ready = true;
}
`;

const setup_periph = `
TIM4->ARR = TIM4_Ticks-1;
HAL_TIM_PWM_Start(&htim2, TIM_CHANNEL_1);
HAL_TIM_OC_Start(&htim4, TIM_CHANNEL_1);
HAL_DMA_Start_IT(&hdma_tim4_ch1, sine_wave_LUT, destination_address, NUM_SAMPLE);
__HAL_TIM_ENABLE_DMA(&htim4, TIM_DMA_CC1);

HAL_ADC_Start_IT(&hadc1);
HAL_TIM_Base_Start(&htim3);
`;

const loop = `
if(is_sample_ready){
    __asm("NOP");
    is_sample_ready = false;
}
`;


function DSP_2(){
   return(
       <div className="em__post">
           <div className="em__post-title">
               <h1>Signal loopback in STM32</h1>
           </div>
           <div className="em__post-section">
               <h3>Overview:</h3>
               <p>
                   In this tutorial, we will create an analog signal generator that we can use in the latter tutorials for frequency analysis and filter testing.
                   We will also initialize the sampling circuit so that we can concentrate only on the digital part.
                   The signal generation will be done with the help of the DMA so that the processor can work on more important tasks.
                   The data acquisition will be done with the help of interrupts.
                   The generated signal will have three components (<ShowTex string="$10Hz$"/>, <ShowTex string="$100Hz$"/>, <ShowTex string="$1000Hz$"/>).
               </p>
           </div>

           <div className="em__post-section">
               <h3>Theory:</h3>
               <p>
                   The STM32F411RE MCUs don't have explicit digital to analog converters, so we will generate the required signal using modulation techniques.
                   More specifically, we will use pulse width modulation.
                   Check <NavLink to="./../../micro-tut/stm-hal/stm-hal-4">this</NavLink> tutorial to catch up on PWM with STM32.
                   The only difference this time is that we don't want to pause the core every so often to change the TIM COMPARE register.
               </p>
               <h4>Signal generation 1 - A single sine wave:</h4>
               <img className="img_pwm_dma_scheme_1_dsp-2" src={pwm_dma_scheme_1} alt="pwm_dma_scheme_1_dsp-2" />
               <p>
                   The idea is as follows: Let's have a Look-up table (LUT) saved somewhere in Flash or RAM.
                   A timer module configured as Output Compare (OC) triggers the data transfer from the LUT to a second timer.
                   The second timer is configured as PWM output and the transferred data is the duty value.
                   <br/>
                   The full cycle of the PWM timer is set by the ARR register (16b). The resolution of the PWM signal is also given by this register.
                   The carrier frequency of the signal can be calculated using
                   <ShowTex string="$f_{pwm}=\frac{f_{sys}}{(PSC+1)(ARR+1)}$"/>, where <ShowTex string="$f_{sys}$"/> is the main timer frequency,
                   <i>PSC</i> is the prescaler value and <i>ARR</i> is the counter period / auto reload register.
                   <br/>
                   Theoretically we could have the full 16b resolution but with a clock frequency of 100MHz the carrier frequency would be maximum 1.5kHz.
                   If we want to modulate a signal with <ShowTex string="$f_{sig}$"/>, then the carrier frequency should be at least ten times that.
                   In the overview, we stated the maximum frequency as <ShowTex string="$f_{sig} = 1000Hz$"/>,
                   so the carrier frequency should be at least <ShowTex string="$10kHz$"/>, this would leave <ShowTex string="$ARR = 9999$"/>,
                   which is equivalent to a resolution of 13b.
                   Let's give a bit more bandwith, and set the carrier frequency to <ShowTex string="$50kHz$"/> with a resolution of 10b.
                   <br/>
                   Then there is the the OC.
                   If we want to create a base signal with frequency <ShowTex string="$f_{sig}$"/>, and we have a LUT with <ShowTex string="$N_s$"/> elements,
                   the trigger frequency should be <ShowTex string="$f_{trig} = N_s f_{sig}$"/>.
                   E.g. <ShowTex string="$f_{sig} = 10Hz$"/>, <ShowTex string="$N_s = 512$"/>, so <ShowTex string="$f_{trig} = 5120Hz$"/>.
               </p>
           </div>
           <div className="em__post-section">
               <p>
                   Let's start with a single sine wave with <ShowTex string="$f_{sig} = 10Hz$"/>.
                   We will use Python to generate the LUT. We need numpy for mathematical operations and matplotlib for data visualisation.
                   You can install these modules using <i>pip install numpy matplotlib</i> preferably in a virtual environment.
                   <div className="em_unselectable">
                   <SyntaxHighlighter language="python" style={AtomOneDark}>
                       {sin_gen}
                   </SyntaxHighlighter>
                   </div>
                   The provided code creates the sine wave table with 512 elements and resolution of 10b. With these values we can finally switch to the firmware generation.
               </p>
           </div>

           <div className="em__post-section">
               <h3>Firmware:</h3>
               <p>
                   Just like in the previous tutorial, create a new project with the NUCLEO-F411RE board.
                   Set the debug connection and the 100MHz system clock.
                   <br/>
                   First we set up TIM2 for PWM generation.
                   Connect the internal clock to the TIM, and enable a channel as PWM generation with output.
                   Leave the prescaler as 0 - we want the carrier to have maximum frequency.
                   Set the counter to 1023 - tis specifies the resolution of the duty cycle.
                   Enable the auto-reload preload because we will change the duty value often.
               </p>
               <Popup trigger={<img className="img_mx_tim2_dsp-2 clickable_img" src={mx_tim2} alt="mx_tim2_dsp-2"/>} modal nested>
                   {close => (
                       <img className="em__img_full" src={mx_tim2} alt="mx_tim2_dsp-2" />
                   )}
               </Popup>
               <p>
                   Next, configure TIM4 to start the data transfer from memory to the TIM2 CHx ARR.
                   Enable the Internal clock, and set channel 1 as output compare with no output - we don't need the trigger signal on external pins.
                   Set the counter period to any 16b number (I've used 100) - we will change this value manually in the code.
                   Enable the the auto-reload preload.
               </p>
               <Popup trigger={<img className="img_mx_tim4_1_dsp-2 clickable_img" src={mx_tim4_1} alt="mx_tim4_1_dsp-2"/>} modal nested>
                   {close => (
                       <img className="em__img_full" src={mx_tim4_1} alt="mx_tim4_1_dsp-2" />
                   )}
               </Popup>
               <p>
                   Add a DMA request for TIM4.
                   It's important that the output compare generates the request for a memory to peripheral data transfer.
                   Set the request priority to high so that it's counted as an interrupt priority.
                   Set the DMA request circular, this way the request can wire multiple times.
                   Set the data width to word since we are transferring data to a 32b peripheral register.
               </p>
               <img className="img_mx_tim4_2_dsp-2 " src={mx_tim4_2} alt="mx_tim4_2_dsp-2" />
               <p>
                   Now for the sampling side: enable TIM3 with internal clock source.
                   Set the counter period to 20000 (this sets the trigger to 5kHz).
                   Set the trigger event to Update event - this timer will trigger automatically the ADC conversion.
               </p>
               <Popup trigger={<img className="img_mx_tim3_dsp-2 clickable_img" src={mx_tim3} alt="mx_tim3_dsp-2"/>} modal nested>
                   {close => (
                       <img className="em__img_full" src={mx_tim3} alt="mx_tim3_dsp-2" />
                   )}
               </Popup>
               <p>
                   Enable an ADC channel (I've used ADC IN1).
                   Set the trigger source to Timer 3 Trigger Out event.
                   Increase the sampling time so that we can have more accurate results (see the MCU datasheet for recommended values).
               </p>
               <img className="img_mx_adc_dsp-2" src={mx_adc} alt="mx_adc_dsp-2"/>
               <p>
                   Enable the ADC interrupt and generate the project.
                   <br/>
                   In the main source file, define the sine LUT properties.
                   We know that the sample size is 512, the clock frequency is 100MHz and we want to create a 10Hz wave.
               </p>
               <div className="em_unselectable">
               <SyntaxHighlighter language="c" style={AtomOneDark}>
                   {defines}
               </SyntaxHighlighter>
               </div>
               <p>
                   Create a const variable for the LUT -
                   this variable will be placed into the FLASH memory,
                   copy the values generated by the Python scrip and load them into the LUT.
                   Set the destination address for the DMA copy to TIM2->CCR1 (this is the duty cycle register).
                   Set the trigger time for TIM 4 according to our previous calculations.
                   Finally, define a flag for adc sampling, and a variable to store the sample - make these values volatile,
                   so that the optimizer can know that these values will be modified outside the function scope.
               </p>
               <div className="em_unselectable">
               <SyntaxHighlighter language="c" style={AtomOneDark}>
                   {private_variables}
               </SyntaxHighlighter>
               </div>
               <p>
                   Create a minimal ADC IRQ callback.
                   Interrupt routines must contain the minimum code possible.
                   In our case, we store the sampled data, and we raise a flag so that the main can process the data latter on.
               </p>
               <div className="em_unselectable">
               <SyntaxHighlighter language="c" style={AtomOneDark}>
                   {adc_irq}
               </SyntaxHighlighter>
               </div>
               <p>
                   In the main function, in USER CODE 2 section initialize the timer and ADC peripheral.
                   Let's start with the signal generation:
                   First set the interval of the output compare of TIM4.
                   Start the PWM channel of TIM2.
                   Start the OC channel of TIM4.
                   Start the DMA transfer - the trigger is the OC channel of TIM4, adn we want to copy from the LUT to the CCRx register.
                   Enable the DMA transfer.
                   <br/>
                   Let's continue with the sampling:
                   Start the ADC in interrupt mode.
                   Enable the base TIM3 to trigger the ADC conversions.
               </p>
               <div className="em_unselectable">
               <SyntaxHighlighter language="c" style={AtomOneDark}>
                   {setup_periph}
               </SyntaxHighlighter>
               </div>
               <p>
                   In this tutorial, we will not process the sampled data, so in the main we can have a no operation instruction,
                   and reset the flag for correct operation.
               </p>
               <div className="em_unselectable">
               <SyntaxHighlighter language="c" style={AtomOneDark}>
                   {loop}
               </SyntaxHighlighter>
               </div>
               <p>
                   At this point, you should build and debug your code.
                   If you place a break point into the interrupt, that should be triggered.
               </p>
           </div>

           <div className="em__post-section">
               <h3>Monitor:</h3>
               <p>
                   The Monitor application is exactly the same as in the first tutorial.
                   Just make sure that the variable node is pointed to the correct elf file, and that you are monitoring the adc_value variable.
                   You should be able to reproduce the following graph:
               </p>
               <img className="img_monitor1_dsp-2" src={monitor1} alt="monitor1_dsp-2"/>
               <p>
                   This is the modulated signal with some added aliasing effect.
                   Use an analog low-pass filter to remove the carrier frequency and the aliasing.
                   I've created a simple RC filter with an 1k resistor and 100n capacitor.
               </p>
               <img className="img_monitor2_dsp-2" src={monitor2} alt="monitor2_dsp-2"/>
               <p>
                   Now we can clearly see the sine wave.
                   <br/>
                   <b>Task:</b> modify the Python script adn the MCU firmware in such a way that the generated signal
                   contains a 10Hz, 100Hz, 1kHz composite sine wave.
                   You should get something like:
               </p>
               <img className="img_monitor3_dsp-2" src={monitor3} alt="monitor3_dsp-2"/>
               <p>
                   We will use this composite signal in the upcoming tutorials to test our algorithms.
               </p>

           </div>


           <div className="em__post-navigation">

               <NavLink to="./../dsp-1">
                   <Button btnID={"leftBTN"} buttonSize="btn--medium"> Prev Post</Button>
               </NavLink>

               <NavLink to="./../dsp-3">
                   <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
               </NavLink>
           </div>
       </div>
   );
}

export default DSP_2;