import React from "react";
import "./mcu_bm2.css";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";

import make_result from "./make_result.jpg";

const linker_script = `
ENTRY(reset_handler)
_estack = ORIGIN(RAM) + LENGTH(RAM);
_Min_Heap_Size = 0x200; /* required amount of heap */
_Min_Stack_Size = 0x400; /* required amount of stack */

MEMORY
{
  CCMSRAM   (xrw)    : ORIGIN = 0x10000000,   LENGTH = 16K
  RAM       (xrw)    : ORIGIN = 0x20000000,   LENGTH = 112K
  FLASH     (rx)     : ORIGIN = 0x8000000,   LENGTH = 512K
}

SECTIONS
{
  /* The inerrupt vector table should be at the start of the flash*/
  .vector_table :
  {
    . = ALIGN(4);
    KEEP(*(.vector_table))
    . = ALIGN(4);
  } >FLASH

  /* The text contains the main program code*/
  .text :
  {
    . = ALIGN(4);
    *(.text)  
    *(.text*)
    *(.glue_7)
    *(.glue_7t)
    *(.eh_frame)

    KEEP(*(.init))
    KEEP(*(.fini))

    . = ALIGN(4);
    _etext = .;
  } >FLASH

  /* Read-only data, like constants, strings etc.*/
  .rodata :
  {
    . = ALIGN(4);
    *(.rodata)
    *(.rodata*)
    . = ALIGN(4);
  } >FLASH

  .ARM.extab   : {
    . = ALIGN(4);
    *(.ARM.extab* .gnu.linkonce.armextab.*)
    . = ALIGN(4);
  } >FLASH

  .ARM : {
    . = ALIGN(4);
    __exidx_start = .;
    *(.ARM.exidx*)
    __exidx_end = .;
    . = ALIGN(4);
  } >FLASH

  /*The data part must be loaded into RAM*/
  _sidata = LOADADDR(.data);

  /* RAM type memory*/
  .data :
  {
    . = ALIGN(4);
    _sdata = .;        /* create a global symbol at data start */
    *(.data)           /* .data sections */
    *(.data*)          /* .data* sections */
    *(.RamFunc)        /* .RamFunc sections */
    *(.RamFunc*)       /* .RamFunc* sections */
    . = ALIGN(4);
    _edata = .;        /* define a global symbol at data end */
  } >RAM

  _siccmsram = LOADADDR(.ccmsram);

  .ccmsram :
  {
    . = ALIGN(4);
    _sccmsram = .;       /* create a global symbol at ccmsram start */
    *(.ccmsram)
    *(.ccmsram*)

    . = ALIGN(4);
    _eccmsram = .;       /* create a global symbol at ccmsram end */
  } >CCMSRAM

  /* Data that is initialized to zero*/
  .bss :
  {
    . = ALIGN(4);
    _sbss = .;
    __bss_start__ = _sbss;
    *(.bss)
    *(.bss*)
    *(COMMON)
    . = ALIGN(4);
    _ebss = .;
    __bss_end__ = _ebss;
  } >RAM

  /* Dynamic allocation for heap/stack*/
  .dynamic_allocation :
  {
    . = ALIGN(8);
    PROVIDE ( end = . );
    PROVIDE ( _end = . );
    . = . + _Min_Heap_Size;
    . = . + _Min_Stack_Size;
    . = ALIGN(8);
  } >RAM

  /* Remove information from compiler libraries */
  /DISCARD/ :
  {
    libc.a ( * )
    libm.a ( * )
    libgcc.a ( * )
  }

  .ARM.attributes 0 : { *(.ARM.attributes) }
}
`;
const vector_table = `.syntax unified
.cpu cortex-m4
.fpu softvfp
.thumb

.global vtable
.global default_interrupt_handler

.type vtable,%object
.section .vector_table,"a",%progbits
.size vtable,.-vtable

vtable:
\t.word _estack
  \t.word reset_handler
  \t.word NMI_Handler
  \t.word HardFault_Handler
  \t.word\tMemManage_Handler
  \t.word\tBusFault_Handler
  \t.word\tUsageFault_Handler
  \t.word\t0
  \t.word\t0
  \t.word\t0
  \t.word\t0
  \t.word\tSVC_Handler
  \t.word\tDebugMon_Handler
  \t.word\t0
  \t.word\tPendSV_Handler
  \t.word\tSysTick_Handler
\t.word\tWWDG_IRQHandler            \t\t\t/* Window Watchdog interrupt       */
\t.word\tPVD_PVM_IRQHandler         \t\t\t/* PVD through EXTI line detection */
\t.word\tRTC_TAMP_CSS_LSE_IRQHandler\t\t\t/* RTC_TAMP_CSS_LSE                */
\t.word\tRTC_WKUP_IRQHandler        \t\t\t/* RTC Wakeup timer                */
\t.word\tFLASH_IRQHandler           \t\t\t/* FLASH                           */
\t.word\tRCC_IRQHandler             \t\t\t/* RCC global interrupt            */
\t.word\tEXTI0_IRQHandler           \t\t\t/* EXTI Line0 interrupt            */
\t.word\tEXTI1_IRQHandler           \t\t\t/* EXTI Line1 interrupt            */
\t.word\tEXTI2_IRQHandler           \t\t\t/* EXTI Line2 interrupt            */
\t.word\tEXTI3_IRQHandler           \t\t\t/* EXTI Line3 interrupt            */
\t.word\tEXTI4_IRQHandler           \t\t\t/* EXTI Line4 interrupt            */
\t.word\tDMA1_CH1_IRQHandler        \t\t\t/* DMA1 channel 1 interrupt        */
\t.word\tDMA1_CH2_IRQHandler        \t\t\t/* DMA1 channel 2 interrupt        */
\t.word\tDMA1_CH3_IRQHandler        \t\t\t/* DMA1 channel 3 interrupt        */
\t.word\tDMA1_CH4_IRQHandler        \t\t\t/* DMA1 channel 4 interrupt        */
\t.word\tDMA1_CH5_IRQHandler        \t\t\t/* DMA1 channel 5 interrupt        */
\t.word\tDMA1_CH6_IRQHandler        \t\t\t/* DMA1 channel 6 interrupt        */
\t.word\t0                          \t\t\t/* Reserved                        */
\t.word\tADC1_2_IRQHandler          \t\t\t/* ADC1 and ADC2 global interrupt  */
\t.word\tUSB_HP_IRQHandler          \t\t\t/* USB_HP                          */
\t.word\tUSB_LP_IRQHandler          \t\t\t/* USB_LP                          */
\t.word\tFDCAN1_IT0_IRQHandler      \t\t\t/* FDCAN1 interrupt 0              */
\t.word\tFDCAN1_IT1_IRQHandler      \t\t\t/* FDCAN1 interrupt 1              */
\t.word\tEXTI9_5_IRQHandler         \t\t\t/* EXTI9_5                         */
\t.word\tTIM1_BRK_TIM15_IRQHandler  \t\t\t/* TIM1_BRK_TIM15                  */
\t.word\tTIM1_UP_TIM16_IRQHandler   \t\t\t/* TIM1_UP_TIM16                   */
\t.word\tTIM1_TRG_COM_IRQHandler    \t\t\t/* TIM1_TRG_COM/                   */
\t.word\tTIM1_CC_IRQHandler         \t\t\t/* TIM1 capture compare interrupt  */
\t.word\tTIM2_IRQHandler            \t\t\t/* TIM2                            */
\t.word\tTIM3_IRQHandler            \t\t\t/* TIM3                            */
\t.word\tTIM4_IRQHandler            \t\t\t/* TIM4                            */
\t.word\tI2C1_EV_IRQHandler         \t\t\t/* I2C1_EV                         */
\t.word\tI2C1_ER_IRQHandler         \t\t\t/* I2C1_ER                         */
\t.word\tI2C2_EV_IRQHandler         \t\t\t/* I2C2_EV                         */
\t.word\tI2C2_ER_IRQHandler         \t\t\t/* I2C2_ER                         */
\t.word\tSPI1_IRQHandler            \t\t\t/* SPI1                            */
\t.word\tSPI2_IRQHandler            \t\t\t/* SPI2                            */
\t.word\tUSART1_IRQHandler          \t\t\t/* USART1                          */
\t.word\tUSART2_IRQHandler          \t\t\t/* USART2                          */
\t.word\tUSART3_IRQHandler          \t\t\t/* USART3                          */
\t.word\tEXTI15_10_IRQHandler       \t\t\t/* EXTI15_10                       */
\t.word\tRTC_ALARM_IRQHandler       \t\t\t/* RTC_ALARM                       */
\t.word\tUSBWakeUP_IRQHandler       \t\t\t/* USBWakeUP                       */
\t.word\tTIM8_BRK_IRQHandler        \t\t\t/* TIM8_BRK                        */
\t.word\tTIM8_UP_IRQHandler         \t\t\t/* TIM8_UP                         */
\t.word\tTIM8_TRG_COM_IRQHandler    \t\t\t/* TIM8_TRG_COM                    */
\t.word\tTIM8_CC_IRQHandler         \t\t\t/* TIM8_CC                         */
\t.word\tADC3_IRQHandler            \t\t\t/* ADC3                            */
\t.word\t0                          \t\t\t/* Reserved                        */
\t.word\tLPTIM1_IRQHandler          \t\t\t/* LPTIM1                          */
\t.word\t0                          \t\t\t/* Reserved                        */
\t.word\tSPI3_IRQHandler            \t\t\t/* SPI3                            */
\t.word\tUART4_IRQHandler           \t\t\t/* UART4                           */
\t.word\tUART5_IRQHandler           \t\t\t/* UART5                           */
\t.word\tTIM6_DACUNDER_IRQHandler   \t\t\t/* TIM6_DACUNDER                   */
\t.word\tTIM7_IRQHandler            \t\t\t/* TIM7                            */
\t.word\tDMA2_CH1_IRQHandler        \t\t\t/* DMA2_CH1                        */
\t.word\tDMA2_CH2_IRQHandler        \t\t\t/* DMA2_CH2                        */
\t.word\tDMA2_CH3_IRQHandler        \t\t\t/* DMA2_CH3                        */
\t.word\tDMA2_CH4_IRQHandler        \t\t\t/* DMA2_CH4                        */
\t.word\tDMA2_CH5_IRQHandler        \t\t\t/* DMA2_CH5                        */
\t.word\t0                          \t\t\t/* Reserved                        */
\t.word\t0                          \t\t\t/* Reserved                        */
\t.word\tUCPD1_IRQHandler           \t\t\t/* UCPD1                           */
\t.word\tCOMP1_2_3_IRQHandler       \t\t\t/* COMP1_2_3                       */
\t.word\tCOMP4_5_6_IRQHandler       \t\t\t/* COMP4_5_6                       */
\t.word\tCOMP7_IRQHandler           \t\t\t/* COMP7                           */
\t.word\t0                          \t\t\t/* Reserved                        */
\t.word\t0                          \t\t\t/* Reserved                        */
\t.word\t0                          \t\t\t/* Reserved                        */
\t.word\t0                          \t\t\t/* Reserved                        */
\t.word\t0                          \t\t\t/* Reserved                        */
\t.word\t0                          \t\t\t/* Reserved                        */
\t.word\t0                          \t\t\t/* Reserved                        */
\t.word\t0                          \t\t\t/* Reserved                        */
\t.word\tCRS_IRQHandler             \t\t\t/* CRS                             */
\t.word\tSAI_IRQHandler             \t\t\t/* SAI                             */
\t.word\t0                          \t\t\t/* Reserved                        */
\t.word\t0                          \t\t\t/* Reserved                        */
\t.word\t0                          \t\t\t/* Reserved                        */
\t.word\t0                          \t\t\t/* Reserved                        */
\t.word\tFPU_IRQHandler             \t\t\t/* Floating point unit interrupt   */
\t.word\t0                          \t\t\t/* Reserved                        */
\t.word\t0                          \t\t\t/* Reserved                        */
\t.word\t0                          \t\t\t/* Reserved                        */
\t.word\t0                          \t\t\t/* Reserved                        */
\t.word\t0                          \t\t\t/* Reserved                        */
\t.word\t0                          \t\t\t/* Reserved                        */
\t.word\t0                          \t\t\t/* Reserved                        */
\t.word\t0                          \t\t\t/* Reserved                        */
\t.word\tRNG_IRQHandler             \t\t\t/* RNG                             */
\t.word\tLPUART_IRQHandler          \t\t\t/* LPUART                          */
\t.word\tI2C3_EV_IRQHandler         \t\t\t/* I2C3_EV                         */
\t.word\tI2C3_ER_IRQHandler         \t\t\t/* I2C3_ER                         */
\t.word\tDMAMUX_OVR_IRQHandler      \t\t\t/* DMAMUX_OVR                      */
\t.word\t0                          \t\t\t/* Reserved                        */
\t.word\t0                          \t\t\t/* Reserved                        */
\t.word\tDMA2_CH6_IRQHandler        \t\t\t/* DMA2_CH6                        */
\t.word\t0                          \t\t\t/* Reserved                        */
\t.word\t0                          \t\t\t/* Reserved                        */
\t.word\tCordic_IRQHandler          \t\t\t/* Cordic                          */
\t.word\tFMAC_IRQHandler            \t\t\t/* FMAC                            */

/*******************************************************************************
*
* Provide weak aliases for each Exception handler to the default_interrupt_handler.
* As they are weak aliases, any function with the same name will override
* this definition.
*
*******************************************************************************/

\t.weak\tNMI_Handler
\t.thumb_set NMI_Handler,default_interrupt_handler

\t.weak\tHardFault_Handler
\t.thumb_set HardFault_Handler,default_interrupt_handler

\t.weak\tMemManage_Handler
\t.thumb_set MemManage_Handler,default_interrupt_handler

\t.weak\tBusFault_Handler
\t.thumb_set BusFault_Handler,default_interrupt_handler

\t.weak\tUsageFault_Handler
\t.thumb_set UsageFault_Handler,default_interrupt_handler

\t.weak\tSVC_Handler
\t.thumb_set SVC_Handler,default_interrupt_handler

\t.weak\tDebugMon_Handler
\t.thumb_set DebugMon_Handler,default_interrupt_handler

\t.weak\tPendSV_Handler
\t.thumb_set PendSV_Handler,default_interrupt_handler

\t.weak\tSysTick_Handler
\t.thumb_set SysTick_Handler,default_interrupt_handler

\t.weak\tWWDG_IRQHandler
\t.thumb_set WWDG_IRQHandler,default_interrupt_handler

\t.weak\tPVD_PVM_IRQHandler
\t.thumb_set PVD_PVM_IRQHandler,default_interrupt_handler

\t.weak\tRTC_TAMP_CSS_LSE_IRQHandler
\t.thumb_set RTC_TAMP_CSS_LSE_IRQHandler,default_interrupt_handler

\t.weak\tRTC_WKUP_IRQHandler
\t.thumb_set RTC_WKUP_IRQHandler,default_interrupt_handler

\t.weak\tFLASH_IRQHandler
\t.thumb_set FLASH_IRQHandler,default_interrupt_handler

\t.weak\tRCC_IRQHandler
\t.thumb_set RCC_IRQHandler,default_interrupt_handler

\t.weak\tEXTI0_IRQHandler
\t.thumb_set EXTI0_IRQHandler,default_interrupt_handler

\t.weak\tEXTI1_IRQHandler
\t.thumb_set EXTI1_IRQHandler,default_interrupt_handler

\t.weak\tEXTI2_IRQHandler
\t.thumb_set EXTI2_IRQHandler,default_interrupt_handler

\t.weak\tEXTI3_IRQHandler
\t.thumb_set EXTI3_IRQHandler,default_interrupt_handler

\t.weak\tEXTI4_IRQHandler
\t.thumb_set EXTI4_IRQHandler,default_interrupt_handler

\t.weak\tDMA1_CH1_IRQHandler
\t.thumb_set DMA1_CH1_IRQHandler,default_interrupt_handler

\t.weak\tDMA1_CH2_IRQHandler
\t.thumb_set DMA1_CH2_IRQHandler,default_interrupt_handler

\t.weak\tDMA1_CH3_IRQHandler
\t.thumb_set DMA1_CH3_IRQHandler,default_interrupt_handler

\t.weak\tDMA1_CH4_IRQHandler
\t.thumb_set DMA1_CH4_IRQHandler,default_interrupt_handler

\t.weak\tDMA1_CH5_IRQHandler
\t.thumb_set DMA1_CH5_IRQHandler,default_interrupt_handler

\t.weak\tDMA1_CH6_IRQHandler
\t.thumb_set DMA1_CH6_IRQHandler,default_interrupt_handler

\t.weak\tADC1_2_IRQHandler
\t.thumb_set ADC1_2_IRQHandler,default_interrupt_handler

\t.weak\tUSB_HP_IRQHandler
\t.thumb_set USB_HP_IRQHandler,default_interrupt_handler

\t.weak\tUSB_LP_IRQHandler
\t.thumb_set USB_LP_IRQHandler,default_interrupt_handler

\t.weak\tFDCAN1_IT0_IRQHandler
\t.thumb_set FDCAN1_IT0_IRQHandler,default_interrupt_handler

\t.weak\tFDCAN1_IT1_IRQHandler
\t.thumb_set FDCAN1_IT1_IRQHandler,default_interrupt_handler

\t.weak\tEXTI9_5_IRQHandler
\t.thumb_set EXTI9_5_IRQHandler,default_interrupt_handler

\t.weak\tTIM1_BRK_TIM15_IRQHandler
\t.thumb_set TIM1_BRK_TIM15_IRQHandler,default_interrupt_handler

\t.weak\tTIM1_UP_TIM16_IRQHandler
\t.thumb_set TIM1_UP_TIM16_IRQHandler,default_interrupt_handler

\t.weak\tTIM1_TRG_COM_IRQHandler
\t.thumb_set TIM1_TRG_COM_IRQHandler,default_interrupt_handler

\t.weak\tTIM1_CC_IRQHandler
\t.thumb_set TIM1_CC_IRQHandler,default_interrupt_handler

\t.weak\tTIM2_IRQHandler
\t.thumb_set TIM2_IRQHandler,default_interrupt_handler

\t.weak\tTIM3_IRQHandler
\t.thumb_set TIM3_IRQHandler,default_interrupt_handler

\t.weak\tTIM4_IRQHandler
\t.thumb_set TIM4_IRQHandler,default_interrupt_handler

\t.weak\tI2C1_EV_IRQHandler
\t.thumb_set I2C1_EV_IRQHandler,default_interrupt_handler

\t.weak\tI2C1_ER_IRQHandler
\t.thumb_set I2C1_ER_IRQHandler,default_interrupt_handler

\t.weak\tI2C2_EV_IRQHandler
\t.thumb_set I2C2_EV_IRQHandler,default_interrupt_handler

\t.weak\tI2C2_ER_IRQHandler
\t.thumb_set I2C2_ER_IRQHandler,default_interrupt_handler

\t.weak\tSPI1_IRQHandler
\t.thumb_set SPI1_IRQHandler,default_interrupt_handler

\t.weak\tSPI2_IRQHandler
\t.thumb_set SPI2_IRQHandler,default_interrupt_handler

\t.weak\tUSART1_IRQHandler
\t.thumb_set USART1_IRQHandler,default_interrupt_handler

\t.weak\tUSART2_IRQHandler
\t.thumb_set USART2_IRQHandler,default_interrupt_handler

\t.weak\tUSART3_IRQHandler
\t.thumb_set USART3_IRQHandler,default_interrupt_handler

\t.weak\tEXTI15_10_IRQHandler
\t.thumb_set EXTI15_10_IRQHandler,default_interrupt_handler

\t.weak\tRTC_ALARM_IRQHandler
\t.thumb_set RTC_ALARM_IRQHandler,default_interrupt_handler

\t.weak\tUSBWakeUP_IRQHandler
\t.thumb_set USBWakeUP_IRQHandler,default_interrupt_handler

\t.weak\tTIM8_BRK_IRQHandler
\t.thumb_set TIM8_BRK_IRQHandler,default_interrupt_handler

\t.weak\tTIM8_UP_IRQHandler
\t.thumb_set TIM8_UP_IRQHandler,default_interrupt_handler

\t.weak\tTIM8_TRG_COM_IRQHandler
\t.thumb_set TIM8_TRG_COM_IRQHandler,default_interrupt_handler

\t.weak\tTIM8_CC_IRQHandler
\t.thumb_set TIM8_CC_IRQHandler,default_interrupt_handler

\t.weak\tADC3_IRQHandler
\t.thumb_set ADC3_IRQHandler,default_interrupt_handler

\t.weak\tLPTIM1_IRQHandler
\t.thumb_set LPTIM1_IRQHandler,default_interrupt_handler

\t.weak\tSPI3_IRQHandler
\t.thumb_set SPI3_IRQHandler,default_interrupt_handler

\t.weak\tUART4_IRQHandler
\t.thumb_set UART4_IRQHandler,default_interrupt_handler

\t.weak\tUART5_IRQHandler
\t.thumb_set UART5_IRQHandler,default_interrupt_handler

\t.weak\tTIM6_DACUNDER_IRQHandler
\t.thumb_set TIM6_DACUNDER_IRQHandler,default_interrupt_handler

\t.weak\tTIM7_IRQHandler
\t.thumb_set TIM7_IRQHandler,default_interrupt_handler

\t.weak\tDMA2_CH1_IRQHandler
\t.thumb_set DMA2_CH1_IRQHandler,default_interrupt_handler

\t.weak\tDMA2_CH2_IRQHandler
\t.thumb_set DMA2_CH2_IRQHandler,default_interrupt_handler

\t.weak\tDMA2_CH3_IRQHandler
\t.thumb_set DMA2_CH3_IRQHandler,default_interrupt_handler

\t.weak\tDMA2_CH4_IRQHandler
\t.thumb_set DMA2_CH4_IRQHandler,default_interrupt_handler

\t.weak\tDMA2_CH5_IRQHandler
\t.thumb_set DMA2_CH5_IRQHandler,default_interrupt_handler

\t.weak\tUCPD1_IRQHandler
\t.thumb_set UCPD1_IRQHandler,default_interrupt_handler

\t.weak\tCOMP1_2_3_IRQHandler
\t.thumb_set COMP1_2_3_IRQHandler,default_interrupt_handler

\t.weak\tCOMP4_5_6_IRQHandler
\t.thumb_set COMP4_5_6_IRQHandler,default_interrupt_handler

\t.weak\tCOMP7_IRQHandler
\t.thumb_set COMP7_IRQHandler,default_interrupt_handler

\t.weak\tCRS_IRQHandler
\t.thumb_set CRS_IRQHandler,default_interrupt_handler

\t.weak\tSAI_IRQHandler
\t.thumb_set SAI_IRQHandler,default_interrupt_handler

\t.weak\tFPU_IRQHandler
\t.thumb_set FPU_IRQHandler,default_interrupt_handler

\t.weak\tRNG_IRQHandler
\t.thumb_set RNG_IRQHandler,default_interrupt_handler

\t.weak\tLPUART_IRQHandler
\t.thumb_set LPUART_IRQHandler,default_interrupt_handler

\t.weak\tI2C3_EV_IRQHandler
\t.thumb_set I2C3_EV_IRQHandler,default_interrupt_handler

\t.weak\tI2C3_ER_IRQHandler
\t.thumb_set I2C3_ER_IRQHandler,default_interrupt_handler

\t.weak\tDMAMUX_OVR_IRQHandler
\t.thumb_set DMAMUX_OVR_IRQHandler,default_interrupt_handler

\t.weak\tDMA2_CH6_IRQHandler
\t.thumb_set DMA2_CH6_IRQHandler,default_interrupt_handler

\t.weak\tCordic_IRQHandler
\t.thumb_set Cordic_IRQHandler,default_interrupt_handler

\t.weak\tFMAC_IRQHandler
\t.thumb_set FMAC_IRQHandler,default_interrupt_handler

\t.weak\tSystemInit

/*
 * A 'Default' interrupt handler. This is where interrupts
 * which are not otherwise configured will go.
 * It is an infinite loop, because...well, we weren't
 * expecting the interrupt, so what can we do?
 */

.section .text.default_interrupt_handler,"ax",%progbits
default_interrupt_handler:
    default_interrupt_loop:
        B default_interrupt_loop
.size default_interrupt_handler, .-default_interrupt_handler`;
const program_loader = `
.syntax unified
.cpu cortex-m4
.fpu softvfp
.thumb

.global reset_handler

.word _sidata
.word _sdata
.word _edata
.word _sbss
.word _ebss
.word _sccmsram
.word _eccmsram

.type reset_handler, %function
reset_handler:
    LDR r0, =_estack
    MOV sp, r0

    // Copy data from FLASH to RAM data init section
    MOVS r0, #0
    // Load the start and end addresses of the data section
    LDR r1, =_sdata
    LDR r2, =_edata
    LDR r3, =_sidata
    B copy_sidata_loop

    copy_sidata:
        // Offset the data init section by our copy progress.
        LDR r4, [r3, r0]
        // Copy the current word into data, and increment.
        STR r4, [r1, r0]
        ADDS r0, r0, #4

    copy_sidata_loop:
        // Copy the next word sidata -> data untill finished
        ADDS r4, r0, r1
        CMP r4, r2
        BCC copy_sidata

    // Fill bss section with zeros
    MOVS r0, #0
    LDR r1, =_sbss
    LDR r2, =_ebss
    B zero_bss_loop

    zero_bss:
        // Store a 0 and increment by a word.
        STR r0, [r1]
        ADDS r1, r1, #4

    zero_bss_loop:
        CMP r1, r2
        BCC zero_bss
   
    // Call static constructor if needed
    //BL __libc_init_array
    // Jump to the main program
    BL main

    final_loop:
        B final_loop

.size reset_handler, .-reset_handler
`;
const main_cpp = `
int main(void){
    volatile int val = 0;

    while(1){
        ++val;
    }
}
`;
const make_file = `
TARGET = main

# Define linker and architecture
LD_SCRIPT = STM32G491.ld 
MCU_CORE = cortex-m4

# Toolchain defs
TOOLCHAIN = /mnt/e/Projects/WSL/stm_barebone/gcc-arm-none-eabi
CC = $(TOOLCHAIN)/bin/arm-none-eabi-g++
AS = $(TOOLCHAIN)/bin/arm-none-eabi-as
LD = $(TOOLCHAIN)/bin/arm-none-eabi-ld
OC = $(TOOLCHAIN)/bin/arm-none-eabi-objcopy
OD = $(TOOLCHAIN)/bin/arm-none-eabi-objdump
OS = $(TOOLCHAIN)/bin/arm-none-eabi-size

# Assembly directives
ASFLAGS += -c
ASFLAGS += -O0
ASFLAGS += -g3
ASFLAGS += -mcpu=$(MCU_CORE)
ASFLAGS += -mthumb
ASFLAGS += -Wall
# (Set error messages to appear on a single line.)
ASFLAGS += -fmessage-length=0

# C/C++ compilation directives
CFLAGS += -mcpu=$(MCU_CORE)
CFLAGS += -mthumb
CFLAGS += -std=c++11
CFLAGS += -Wall
CFLAGS += -g3
CFLAGS += -Og
CFLAGS += -mfpu=fpv4-sp-d16
CFLAGS += -mfloat-abi=hard
# (Set error messages to appear on a single line.)
CFLAGS += -fmessage-length=0
# (Set system to ignore semihosted junk)
CFLAGS += --specs=nano.specs

# Linker directives.
LSCRIPT = ./$(LD_SCRIPT)
LFLAGS += -mcpu=$(MCU_CORE)
LFLAGS += -mthumb
LFLAGS += -Wall
LFLAGS += --specs=nano.specs
LFLAGS += -nostdlib
LFLAGS += -lgcc
LFLAGS += -T$(LSCRIPT)

VECT_TBL = ./vector_table.s
AS_SRC   = ./core.s
C_SRC    = ./main.cpp

OBJS =  $(VECT_TBL:.s=.o)
OBJS += $(AS_SRC:.s=.o)
OBJS += $(C_SRC:.cpp=.o)

.PHONY: all
all: $(TARGET).bin

%.o: %.s
\t$(CC) -x assembler-with-cpp $(ASFLAGS) $< -o $@

%.o: %.cpp
\t$(CC) -c $(CFLAGS) $(INCLUDE) $< -o $@

$(TARGET).elf: $(OBJS)
\t$(CC) $^ $(LFLAGS) -o $@

$(TARGET).bin: $(TARGET).elf
\t$(OC) -S -O binary $< $@
\t$(OS) $<

.PHONY: clean
clean:
\trm -f $(OBJS)
\trm -f $(TARGET).elf
`;
const debug_json = `
{
    "configurations": [
        {
            "name": "GDB",
\t        "type": "cortex-debug",
            "servertype": "openocd",
\t        "request": "launch",
\t        "cwd": "\${workspaceRoot}",
\t        "executable": "./1_setup_main/main.elf",
            "device": "STM32G491RE",
            "gdbPath": "./gcc-arm-none-eabi/bin/arm-none-eabi-gdb",
            "configFiles": [
                "interface/stlink.cfg",
                "target/stm32g4x.cfg"
            ],
        }
    ],
    "version": "2.0.0"
}
`;


function MCU_BM2(){
   return(
       <div className="em__post">
           <div className="em__post-title">
               <h1>Loading the Main function</h1>
           </div>
           <div className="em__post-section">
               <h3>Aim of this tutorial:</h3>
               <p>
                   In this tutorial we will continue the completion of the vector table, we will create a program loader for the C++ function.
                   We will also automate the building and debugging process.
               </p>
           </div>

           <div className="em__post-section">
               <h3>Completing the Linker Script:</h3>
               <p> Here's the completed linker which implements different sections for different data types.</p>
               <SyntaxHighlighter language="c" style={AtomOneDark}>
                   {linker_script}
               </SyntaxHighlighter>
               <p>
                   We have an entry point, which states where the program should start - in this case, it's the reset handler.
                   I've replaced the hardcoded end stack value with value read from the MEMORY property.
                   We can specify the minimum required heap and stack size.
                   If after linking there will not be enough free space to satisfy these values, an error will be thrown.
                   <br/>
                   The first section in the FLASH will be the vector table.
                   Next, we have the text section, which contains the program code and other ROM type memories.
                   There are some glue regions for arm to thumb and thumb to arm code.
                   etext defines the end of the code section.
                   <br/>
                   The rodata section contains constants and string in the FLASH.
                   The sidata symbolizes the start of the data section.
                   <br/>
                   The data section contains RAM type variables and functions.
                   After the data we have the CCM RAM, which is a fast access core coupled memory.
                   We will use this part for interrupts in the future.
                   <br/>
                   The bss section will contain zero initialized variables, and the remaining space is set for dynamics variable and function allocation.
               </p>
           </div>

           <div className="em__post-section">
               <h3>Completing the vector table:</h3>
               <p>
                   Next, we can build the vector table using the reference manual.
                   You can create this part as a continuation of the core.s file, or create a new/separate file for it.
                   I've created the vector_table.s file with the following content:
               </p>
               <SyntaxHighlighter language="armasm" style={AtomOneDark}>
                   {vector_table}
               </SyntaxHighlighter>
           </div>

           <div className="em__post-section">
               <h3>Loading the main function:</h3>
               <p>
                   Let's modify the core.s file so that we can finally use C++ functions.
                   The loading process should be:
                   <ol>
                       <li> Move the stack pointer to the end of the stack.</li>
                       <li> Copy the pre-initialized data into the .data RAM.</li>
                       <li> Set the .bss region to zeros.</li>
                       <li> Jump to the main function.</li>
                   </ol>
               </p>
               <SyntaxHighlighter language="armasm" style={AtomOneDark}>
                   {program_loader}
               </SyntaxHighlighter>
               <p>
                   The .word directive creates constants for our pre defined memory places.
                   The copy_sidata_loop takes care of the pre-initialization of the RAM.
                   The zero_bss_loop sets the required region to zero.
                   Finally, the program counter will jump to the main function.
                   If the main does not have an infinite loop (it should...) the program counter returns and enters to the final_loop part.
               </p>
               <p>
                   This time, the main function will be a simple one:
               </p>
               <SyntaxHighlighter language="c" style={AtomOneDark}>
                   {main_cpp}
               </SyntaxHighlighter>
               <p>
                   Now, we should create an object file for every source code, link them together and test it.
                   Or, we could create a Makefile that takes care of the long terminal commands.
               </p>
           </div>

           <div className="em__post-section">
               <h3>Creating the Makefile:</h3>
               <SyntaxHighlighter language="makefile" style={AtomOneDark}>
                   {make_file}
               </SyntaxHighlighter>
               <p>
                   Here we set the target file, linker, MCU architecture.
                   We need to define the path of our toolchain.
                   I've set up the assembler with no optimization (-O0), and maximum debugging information (-g3),
                   then I've set the C/C++ compiler to c++11 standard optimized for debugging (-Og, -g3).
                   I've also enabled the floating point unit.
                   <br/>
                   If we write the command <i>make</i>, the procedure will create the object files for the C/C++ and assembly files,
                   and create the ELF and binary files.
                   The <i> make clean</i> instruction will remove the created files.
               </p>
               <img className="img_make_result_bm_2" src={make_result} alt="make_result"/>
               <p>
                   And here we have the results of the make procedure.
                   It's interesting that the last command, arm-none-eabi-size, thinks that our heap and stack allocation is .bss region.
                   This occurs because we have a code fragment that is stored in flash and is copied to ram at runtime.
                   This sets code attribute for the data segment, and size believes that all of the data is text.
                   If we want to see the hole picture, we could execute <i>arm-none-eabi-size -A -t -x main.elf</i>,
                   which shows that the .bss size is 0 and we have dynamic allocation, since the variable is set inside the function.
               </p>
           </div>

           <div className="em__post-section">
               <h3>Automated debugging:</h3>
               <p>
                   In the previous tutorial we saw how to debug our code on the MCU using OpenOCD and GDB.
                   We can automate this process with the help of a debug configuration.
                   <b>Ctrl+Shift+D</b> brings out the run and debug perspective.
                   Here we can create a new debug configuration, which results in the creation of a <i>launch.json</i> file.
                   Copy and paste the following snippet.
               </p>
               <SyntaxHighlighter language="json" style={AtomOneDark}>
                   {debug_json}
               </SyntaxHighlighter>
               <p>
                   The name field specifies the name of the configuration - we will see GDB besides the debug button.
                   The type sets the debugger - in this case, we are using the cortex-debug extension.
                   The servertype specifies the server to which we must connect - this can be st-link, j-link or in this case openocd.
                   We must give the ELF file that we are debugging, the core type, and the location of the gdb.
                   The configfiles region sets the hardware source configurations as in the case of the manual .cfg file.
               </p>
               <p>
                   With everything set, try out the debug.
                   Insert a breakpoint, and check how the variable increases.
               </p>
               <p>
                   If you were wondering about the oscillator of the MCU, no worries.
                   The default is the internal 8MHz signal, so until we learn how to configure it, we can still work with a running processor.
                   But we already knew that, since the program was running.
               </p>
           </div>


           <div className="em__post-navigation">

               <NavLink to="./../stm-bm-1">
                   <Button btnID={"leftBTN"} buttonSize="btn--medium"> Previous Post</Button>
               </NavLink>

               <NavLink to="./../stm-bm-3">
                   <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
               </NavLink>
           </div>

       </div>
   );
}

export default MCU_BM2;