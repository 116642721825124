import React from "react";
import "./collection.css";
import {Tag} from "../../common";
import {useNavigate} from "react-router-dom";


const TutList = [
    {name:"1. Setup the programming environment", link: "./stm-bm-1"},
    {name:"2. Loading the Main function", link: "./stm-bm-2"},
    {name:"3. General purpose inputs and outputs", link: "./stm-bm-3"},
    {name:"4. External oscillator and PLL", link: "./stm-bm-4"},
    {name:"5. Functions in CCM region", link: "./stm-bm-5"}
]

function STM_BM(){

    const navigate = useNavigate();

    return(
        <div className="em__collection">
            <div className="em__collection-title">
                <h1 className="gradient__text">Microcontroller tutorials using register-level programming</h1>
            </div>

            <div className="em__collection-list">
                {
                    TutList.map((item) => (
                        <div >
                            <Tag title={item.name} description={item.description} onClick={() => navigate(item.link)}/>
                        </div>
                    ))
                }
            </div>

        </div>
    );
}

export default STM_BM;