import React from "react";
import "./mcu_hal26.css";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";

import usb_cdc_middle from "./usb_cdc_middle.jpg";
import usb_device_mx from "./usb_device_mx.jpg";
import usb_host_mx from "./usb_host_mx.jpg";
import usb_host_middle from "./cdc_host_middle.jpg";
import cdc_messages from "./cdc_messages.jpg";

const global_defs_device = `
uint8_t str_tx[32];
uint8_t i = 0;

volatile uint8_t str_rx[32];
volatile uint8_t rx_flag = 0;
`;

const button_callback_device = `
void HAL_GPIO_EXTI_Callback(uint16_t GPIO_Pin){
    sprintf((char*)str_tx, "Slave: Msg No. %d\\r\\n", i++);
    CDC_Transmit_FS(str_tx, strlen((char*)str_tx));
}
`;

const local_defs_device = `
    uint8_t text_block[5][24];
    uint8_t block_counter = 0;
    uint8_t ii, jj;
`;

const main_loop_device = `
if(rx_flag == 1){
      //There is data in the receiver buffer
      if(block_counter < 4){
          // Only add the new data
          for(ii = 0; ii < 24; ++ii){
              if(str_rx[ii] == '\\r')
                  break;
              text_block[block_counter][ii] = str_rx[ii];
          }
          for(jj = ii; jj < 24; ++jj)
              text_block[block_counter][ii] = 0;
          ++block_counter;
      }else{
          // Shift old data and add new
          for(uint8_t blk = 0; blk < 3; ++blk)
            for(ii = 0; ii < 24; ++ii){
                text_block[blk][ii] = text_block[blk+1][ii];
            }

          for(ii = 0; ii < 24; ++ii){
              if(str_rx[ii] == '\\r')
                  break;
              text_block[4][ii] = str_rx[ii];
          }
          for(jj = ii; jj < 24; ++jj)
              text_block[4][ii] = 0;
      }

      SSD1306_GotoXY(0, 0);
      SSD1306_Puts((char*)text_block[0], &font_7x10, SSD1306_COLOR_WHITE);
      SSD1306_GotoXY(0, 11);
      SSD1306_Puts((char*)text_block[1], &font_7x10, SSD1306_COLOR_WHITE);
      SSD1306_GotoXY(0, 22);
      SSD1306_Puts((char*)text_block[2], &font_7x10, SSD1306_COLOR_WHITE);
      SSD1306_GotoXY(0, 33);
      SSD1306_Puts((char*)text_block[3], &font_7x10, SSD1306_COLOR_WHITE);
      SSD1306_GotoXY(0, 44);
      SSD1306_Puts((char*)text_block[4], &font_7x10, SSD1306_COLOR_WHITE);
      SSD1306_UpdateScreen();

      rx_flag = 0;
  }   
`;

const cdc_if_extern_device = `
/* USER CODE BEGIN EXPORTED_VARIABLES */
extern volatile uint8_t str_rx[32];
extern volatile uint8_t rx_flag;
/* USER CODE END EXPORTED_VARIABLES */
`;

const cdc_if_receive_device = `
static int8_t CDC_Receive_FS(uint8_t* Buf, uint32_t *Len){
  /* USER CODE BEGIN 6 */
  //USBD_CDC_SetRxBuffer(&hUsbDeviceFS, &Buf[0]);
  strncpy((char*)str_rx, (char*)Buf, *Len);
  str_rx[*Len] = 0;
  rx_flag = 1;
  USBD_CDC_ReceivePacket(&hUsbDeviceFS);
  return (USBD_OK);
  /* USER CODE END 6 */
}
`;

const extern_var_host = `
extern ApplicationTypeDef Appli_state;
extern USBH_HandleTypeDef hUsbHostFS;
`;

const button_callback_host = `
void HAL_GPIO_EXTI_Callback(uint16_t GPIO_Pin){
    if(Appli_state == APPLICATION_READY){
        // We can send the message only if the device is ready
        sprintf((char*)str_tx, "Host: Msg No. %d\\r\\n", i++);
        USBH_CDC_Transmit(&hUsbHostFS, str_tx, strlen((char*)str_tx));
        USBH_CDC_Receive(&hUsbHostFS, (uint8_t*)str_rx, 32);

    }else{
        // There is no device to send message to
        err_flag = 1;
    }
}
`;

const error_host = `
if(err_flag == 1){
    SSD1306_Fill(SSD1306_COLOR_BLACK);
    SSD1306_GotoXY(0, 25);
    SSD1306_Puts("No slave connected!", &font_7x10, SSD1306_COLOR_WHITE);
    SSD1306_UpdateScreen();
    HAL_Delay(1000);
    SSD1306_Fill(SSD1306_COLOR_BLACK);
    continue;
}
`;

function MCU_HAL26(){
    return(
        <div className="em__post">
            <div className="em__post-title">
                <h1>USB virtual COM port host and device</h1>
            </div>

            <div className="em__post-section">
                <h3>Aim of this tutorial:</h3>
                <p>
                    In this tutorial, we will test the USB communication as virtual COM port (VCP).
                    First we will use the NUCLEO-G491RE board as USB VCP Device to exchange data with the PC.
                    Then we will connect the G491RE with a NUCLEO-F411RE configured as VCP Host.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Few words about USB:</h3>
                <p>
                    The Universal Serial Bus communication is a complex can of worms.
                    It's a hot-pluggable, serial, pooled, host centric communication.
                    Even at the lowest level (physical layer) complex procedures are implemented to facilitate high speed data integrity.
                    The output data is bit stuffed (added a '0' after six consecutive '1'),
                    then the data is non return to zero inverted (NRZI - a '0' data is encoded as a level switch, a '1' is encoded as a level keep).
                    There are pull-down resistors added on the host side of the data lines (+/-D), while the device adds pull-up resistors to +D or -D depending on
                    whether low speed or full speed data transfer is implemented.
                    <br/>
                    Furthermore, we have only 4 physical connections (2 data lines, supply and ground),
                    there can be multiple endpoints (EP) implemented (up to 16) in a single controller.
                    The transfer protocols are all started using synchronisation and PID bits while terminated with EOP bit.
                    We can distinguish token, data, start frame and acknowledge packets.

                    <br/>
                    Needless to say, that it would be a hard task to implement a modern USB data transfer from zero, so we will leave that to our friend, the HAL.
                    If you want to learn more about the USB standards and implementations, check out the <a href="https://www.usb.org/documents">USB
                    document library</a> and <a href="https://www.st.com/content/st_com/en/support/learning/stm32-education/stm32-moocs/STM32-USB-training.html">this</a> course.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Device setup:</h3>
                <p>
                    Create a new project for the NUCLEO-G491RE with the usual settings.
                    Set PC13 as EXTI and enable EXTI line [15:10] interrupt from NVIC so that can we can use it latter.
                    Enable I2C3 from Connectivity - we will connect an OLED display for the messages.
                    Enable USB Device FS - This MCU can only be configured as a device.
                    I've used a USB breakout board to have a connection: PA12 is D+, PA11 is D-, and choose a GND connection.
                    This time we will power both boards from the PC, so we won't need to connect the Vcc pin.
                    Also, the ID pin is only used in OTG mode, and the Shield should be connected to the metal chassis on the device (ven there is a metal chassis).
                </p>

                <Popup trigger={<img className="img_usb_device_mx_hal26 clickable_img" src={usb_device_mx} alt="usb_device_mx_hal26"/>} modal nested>
                    {
                        close =>(
                            <img className="em__img_full" src={usb_device_mx} alt="usb_device_mx_hal26"/>
                        )
                    }
                </Popup>

                <Popup trigger={<img className="img_usb_cdc_middle_hal26 clickable_img" src={usb_cdc_middle} alt="usb_cdc_middle_hal26"/>} modal nested>
                    {
                        close =>(
                            <img className="em__img_full" src={usb_cdc_middle} alt="usb_cdc_middle_hal26"/>
                        )
                    }
                </Popup>
            </div>

            <p>
                The USB middleware specifies the functionality of the device.
                The most important part is the descriptor which contains important information without which the Host can't enumerate the device.
                You can read more about USB descriptors <a href="https://www.microchipdeveloper.com/usb:descriptor">here</a>.
                Increase the minimum heap size to 1536 (0x600), and generate the project.
            </p>

            <div className="em__post-section">
                <h3>Device firmware:</h3>
                <p>
                    Import the SSD1306 driver files from the <NavLink to="./../stm-hal-11">I2C tutorial</NavLink> and include them in the main file.
                    Further more include the <i>usbd_cdc_if.h</i> which is the COM port interface header.
                </p>

                <p>
                    First, we need some buffers and flags.
                    <i>str_tx</i> and <i>str_rx</i> well hold the data for transmission and reception respectively.
                    <i>rx_flag</i> will be set when a new data packet is received.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {global_defs_device}
                </SyntaxHighlighter>
                <p>
                    We can create a string at every button press using the external interrupt callback function.
                    The <i>CDC_Transmit_FS</i> transmits an array with given size.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {button_callback_device}
                </SyntaxHighlighter>
                <p>
                    In order to have a neat(er) look, we will parse the incoming data based on the newline character, and store them in a 5 line text block.
                    This block will be updated in a FIFO fashion latter on.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {local_defs_device}
                </SyntaxHighlighter>
                <p>
                    If we have new data ready, we copy it until the '\r' character.
                    We can simply copy the data into the <i>text_block</i>.
                    If the text block is full, we will shift the data, and paste the new one at the end.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {main_loop_device}
                </SyntaxHighlighter>
                <p>
                    So, where is the data reception handled?
                    In the <i>USB_Device/App/usbd_cdc_if.c</i> file.
                    Browse around in that file to find out about other functionalities.
                    For now, we need to reference two external variables from the main source.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {cdc_if_extern_device}
                </SyntaxHighlighter>
                <p>
                    We need to modify the receiver function <i>CDC_Receive_FS</i>.
                    Copy the data from the local buffer to our buffer, and set the reception flag, so that we can process it in the main.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {cdc_if_receive_device}
                </SyntaxHighlighter>
                <p>
                    If everything is done correctly, when you connect the device to the PC,
                    it should enumerate as Virtual COM port with a given COMx  port number in Windows, or as ttyACMx or ttyUSBx in Linux systems.
                    If you write some text, that should appear on the OLED screen, while pushing the blue button should send a text from the MCU to the PC.
                </p>

            </div>

            <div className="em__post-section">
                <h3>Host setup:</h3>
                <p>
                    The STM32G MCUs can't act as USB Hosts, so I'm using an STM32F MCU for it.
                    Start up STM32CubeMX and create a new project based on the NUCLEO-F411RE board.
                    Enable the PC13 for external interrupt source (Blue button).
                    Enable I2C3 to communicate with the OLED screen, and enable the USB in <i>Host only</i> mode.
                    Finally, go to <i>Middleware/USB_HOST</i> section and enable the VCP Host.
                    Usually, the host should provide the power for the connected device (e.g. generate 5V with a charge pump).
                    Both of the boards will be powered from the PC, so I've set PA5 as output, and specified it at the Drive_VBUS_FS.
                    This way the on-board LED will show if there would be power provided to the host port.
                </p>
                <Popup trigger={<img className="img_usb_host_mx_hal26 clickable_img" src={usb_host_mx} alt="usb_host_mx_hal26"/>} modal nested>
                    {
                        close =>(
                            <img className="em__img_full" src={usb_host_mx} alt="usb_host_mx_hal26"/>
                        )
                    }
                </Popup>

                <Popup trigger={<img className="img_usb_host_middle_hal26 clickable_img" src={usb_host_middle} alt="usb_host_middle_hal26"/>} modal nested>
                    {
                        close =>(
                            <img className="em__img_full" src={usb_host_middle} alt="usb_host_middle_hal26"/>
                        )
                    }
                </Popup>
            </div>

            <div className="em__post-section">
                <p>
                    Increase the minimum heap size to 0x2000 and the minimum stack size to 0x1500.
                    Generate the project.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Host firmware:</h3>
                <p>
                    If you check the generated project, the main loop contains a strange function <i>MX_USB_HOST_Process()</i>.
                    This function must be called periodically so that changes in the connection/device can be managed.
                </p>
                <p>
                    Include the <i>usbh_cdc.h</i> header so that we can use the host CDC functions.
                    Copy the OLED driver files and include <i>ssd1306_oled.h</i> header.
                </p>
                <p>
                    Next, we need two external variables.
                    The <i>Appli_state</i> contains the state of the device (if connected).
                    The <i>hUsbHostFS</i> is the USB host handle which will be used to send and receive data.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {extern_var_host}
                </SyntaxHighlighter>
                <p>
                    Let's configure the button event.
                    First, check if a device is connected, enumerated and ready for use.
                    Send a message string, and enter into reception mode.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {button_callback_host}
                </SyntaxHighlighter>
                <p>
                    If the device is not ready, we will write it to the OLED display in the main loop.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {error_host}
                </SyntaxHighlighter>
                <p>
                    In order to display the received message, we need to handle the reception event.
                    If you open the <i>usbh_cdc.c</i> source file and search for <i>__weak</i> functions, you should find the
                    <i>USBH_CDC_ReceiveCallback(USBH_HandleTypeDef *phost)</i> function.

                    Copy this fucntion to the main after the extenal interrupt routine and copy the data reception from the device source.
                </p>

                <p>
                    Upload the built firmware and this time connect the two boards together using an USB cable.
                    I had to use an additional OTG cable so that everything could be matched up.
                    If you push the blue button on the host board, a string is sent to the device and the host enters in listening mode.
                    If you push the blue button on the device board, a string will be sent back to the host.
                    All of which should be displayed on the screens.
                </p>

                <img className="img_messages_hal26" src={cdc_messages} alt="messages_hal26"/>

                <p>
                    The source files can be found in <a href="https://gitlab.com/stm32_mcu_group/stm32_hal/26_usb_cdc.git">this</a> repo.
                </p>

            </div>




            <div className="em__post-navigation">

                <NavLink to="./../stm-hal-25">
                    <Button btnID={"leftBTN"} buttonSize="btn--medium"> Previous Post</Button>
                </NavLink>

                <NavLink to="./../stm-hal-27">
                    <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
                </NavLink>
            </div>
        </div>
    );
}

export default MCU_HAL26;