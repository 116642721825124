import React from "react";
import "./../posts.css";
import "./headamp.css"
import {MathJax, MathJaxContext} from "better-react-mathjax";
import amp_wire from "./amp_wire.jpg";
import bass from "./bass.jpg";
import board_pcb from "./board_pcb.jpg";
import bottom_pcb from "./bottom_pcb.jpg";
import freq_plot from "./freq_plot.jpg";
import preamp_wire from "./preamp_wire.jpg";
import schem_amp from "./schem_amp.jpg";
import schem_complete from "./schem_complete.jpg";
import top_pcb from "./top_pcb.jpg";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";

function ShowTex({string}){
    const config = {
        loader: { load: ["[tex]/html"]},
        tex: {packages: {"[+]": ["html"]},
            inlineMath: [["$", "$"]],
            displayMath: [["$$", "$$"]]
        }
    };

    return(
        <MathJaxContext config={config} version={3}>
        <MathJax dynamic inline>
            {string}
        </MathJax>
    </MathJaxContext>
    );
}


function HeadAmp(){

    return(
        <div className="em__post">
            <div className="em__post-title">
                <h1>Bass amplifier for headphones</h1>
            </div>
            <div className="em__post-section">
                <h3>Beginning</h3>
                <img className="img_bass" src={bass} alt="bass_guit" />
                <ul>
                    <li>
                        <b>Summary:</b> In this tutorial, we will create a simple solid state headphone amplifier which can be used
                        for amplification of passive instruments, and coupling them to low impedance loads (headsets, headphones).
                        This circuit will only use one Op-Amp and one class AB integrated power amplifier.
                    </li>

                    <li>
                        <b>Prerequisites:</b> To successfully build the amplifier and to understand the underlying
                        design principles the user should have some basic analogue electronics and signal processing knowledge.
                        The simulations were carried out in ISIS Proteus Design Suite 8.6, but any Spice program should suffice
                        (some examples: LTSpice - from Linear Technology can be downloaded from &nbsp;
                        <a href="https://www.analog.com/en/design-center/design-tools-and-calculators/ltspice-simulator.html">here</a>,
                        Java based simulator applet&nbsp;<a href="http://www.falstad.com/circuit/">online</a>,
                        National Instruments Multisim Live&nbsp;<a href="https://www.multisim.com/">here</a>).
                        The circuit was designed using Autodesk Eagle (can be downloaded from&nbsp;
                        <a href="https://www.autodesk.com/products/eagle/free-download">here</a>)
                        but other PCB designer software can be utilized such as&nbsp;
                         <a href="https://www.kicad.org/download/">KiCad</a>,&nbsp;
                        <a href="https://www.altium.com/">Altium</a>, etc.
                    </li>
                    <li>
                        <b>Why should we build a small amplifier, when a large one can be so much more satisfying?</b>
                        Only a few reasons: Weight - amps are quite heavy (My Warwick BlueCab 30 weighted 15kg).
                        Noise - amplifiers are loud (you don't say),  and maybe there are some periods when one would practice the instruments,
                        but one doesn't want to disturb neighbours or family. Fun - it's always fun to build something new.
                    </li>
                </ul>
            </div>
            <div className="em__post-section">
                <h3>Preamplifier circuit:</h3>
                <p>
                    The role of the preamplifier (or preamp) is to convert a weak signal into a stronger, noise tolerant signal,
                    which can be further processed by the power amplifier.
                    The preamp provides the voltage gain of the circuit, and the impedance matching - the input is a high impedance low current source,
                    low output impedance for the power amp. This circuit could be built with one or several transistors, but the quiescent point
                    must be set with multiple bias resistors. The voltage gain of OpAmps is larger, which means fewer stages can have the same effect.
                    For this circuit, I've used MC34071 precision OpAmps because I had them in my drawer, but cheaper MCP6001 or MCP601 alternatives can be utilized.
                </p>
                <Popup trigger={<img className="img_pream_schem clickable_img" src={schem_amp} alt="preamp" />} modal nested>
                    {close => (
                        <img className="em__img_full" src={schem_amp} alt="preamp" />
                    )}
                </Popup>

                <p>
                    The preamp circuit can be seen on the right.
                    We want to have a portable amplifier so that the power supply will be a small 9V battery.
                    The Op Amp will be used in inverting amplifier configuration (the phase response of the signal is of no importance in our case).
                    DC voltage must be removed from the input signal, so our Op Amp only has to amplify the AC (no accidental clipping) -
                    this is done with the C4. Since C4 and the volume potentiometer ( represented by R3 and R10) form a high-pass filter,
                    the cut-off frequency (-3dB gain) can be calculated with the formula <ShowTex string="$f_c=\frac{1}{2\pi RC}$"/>.
                    The battery will be used as a unipolar supply, so the Op Amps' positive input must be biased to half the supply voltage
                    with a voltage divider. Another DC filter capacitor is used at the output of the Op Amp (C12) to remove the bias DC voltage.
                </p>

                <p>
                    A good practice in electronics is to include bulk and decoupling capacitors on the supplies.
                    One electrolytic capacitor for the 9V supply, one for the bias voltage (they can have the same values),
                    and a 100nF ceramic capacitor as close to the Op Amps power pins as possible.
                </p>
                <p>
                    On the right image below I showed the AC sweep of the Preamplifier circuit.
                    The input was a pure sine wave function with 1mV p2p voltage, and 1.25mV DC offset.
                    The modified component was the potentiometer (by assigning values from 0 to 200k to the x in R3 and R10).
                    The frequency response of the circuit was plotted from 10Hz to 20kHz.
                    The image on the left side shows the prototype setup.
                    It's a messy board, with multiple wires collecting mains 50Hz noise and high-frequency static noises,
                    but the sound can be heard, so we don't have to debug the circuit with an oscilloscope, and we can start to build the Power amplifier.
                </p>
                <Popup trigger={<img className="img_pream_wire clickable_img" src={preamp_wire} alt="preamp_wire"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={preamp_wire} alt="preamp_wire"/>
                    )}
                </Popup>
                <Popup trigger={<img className="img_freq_plot clickable_img" src={freq_plot} alt="freq_plot"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={freq_plot} alt="freq_plot"/>
                    )}
                </Popup>

            </div>
            <div className="em__post-section">
                <h3>Power amp circuit:</h3>
                <p>
                    The role of the power amplifier, as its name suggests, is to increase the power of the input signal, which is done by increasing the current.
                    Power amplifiers can be designed in &nbsp;
                    <a href="https://www.maximintegrated.com/en/design/technical-documents/tutorials/5/5590.html">different classes</a>&nbsp;
                    (A, AB, B, or D for audio amplifiers, G, DG, H for other applications).
                    As in the previous stage, we could build an amplifier from discrete components (and maybe we will in the future), but specified ICs exist to ease the design procedure.
                </p>
                <p>
                    I've chosen the old &nbsp;<a href="https://www.ti.com/lit/ds/symlink/lm386.pdf">LM386</a>&nbsp; AB class linear amplifier for our circuit.
                    It's old technology, some may argue too old, but for cheap headphone applications, it will suffice.
                    Other option could be to use a second Op Amp stage, or  TDA2822.
                </p>
                <Popup trigger={<img className="img_schem_complete clickable_img" src={schem_complete} alt="schem_complete" />} modal nested>
                    {close => (
                        <img className="em__img_full" src={schem_complete} alt="schem_complete" />
                    )}
                </Popup>

                <p>
                    We can see the complete amplifier on the picture beside. Now we will focus only on the output part.
                    The LM386 datasheet provides useful information to build the circuit.
                    The positive input will be connected to the C12 output capacitor, thE negative input to ground.
                    Same as before the power pins must be decoupled with a 100nF ceramic capacitor.
                    The offset capacitor on pin 7 can be left unpopulated since we have C12 as DC filter.
                    The output electrolytic capacitor is also a DC filter, and its purpose is to protect the speakers.
                    R16 and C15 series RC circuit is used in parallel with the internal negative feedback to compensate for the headphones frequency response.
                </p>
                <p>
                    The gain is set to 20 by default, but it can be increased to 200 by placing a 10uF capacitor between pins 1 and 8.
                    Alternatively, the capacitor can be connected in series with a potentiometer for variable gain.
                    For my ears even a gain of 20 was too much, so I've added the R15 resistor after C12 which forms an
                    attenuator with the internal 50k resistance that can be described using the formula <ShowTex string="$U_{out} = \frac{50k}{50k + R_{15}}U_{in}$"/>.
                    Below I've posted a picture of the complete circuit on the proto board, and on the final PCB.
                </p>
                <div className="em__post-section__img">
                    <Popup trigger={<img className="img_amp_wire clickable_img" src={amp_wire} alt="amp_wire" />} modal nested>
                        {close => (
                            <img className="em__img_full" src={amp_wire} alt="amp_wire" />
                        )}
                    </Popup>
                    <Popup trigger={<img className="img_board_pcb clickable_img" src={board_pcb} alt="board_pcb" />} modal nested>
                        {close => (
                            <img className="em__img_full" src={board_pcb} alt="board_pcb_full" />
                        )}
                    </Popup>
                    <Popup trigger={<img className="img_bottom_pcb clickable_img" src={bottom_pcb} alt="bottom_pcb" />} modal nested>
                        {close => (
                            <img className="em__img_full" src={bottom_pcb} alt="bottom_pcb" />
                        )}
                    </Popup>
                    <Popup trigger={<img className="img_top_pcb clickable_img" src={top_pcb} alt="top_pcb" />} modal nested>
                        {close => (
                            <img className="em__img_full" src={top_pcb} alt="top_pcb" />
                        )}
                    </Popup>
                </div>

            </div>
            <div className="em__post-section">
                <p>
                    On a final note, the noises are barely hearable with the short signal path on the PCB.
                    Still, as a final improvement, a metal case or a plastic one with metal film shielding is required.
                    The four small vias visible on picture 2 and 4 are ground connections to this metal cover, which will act as a noise shield.
                    (The design procedure of such a cover will be posted here when I will have the time and resolve to cut and glue poly-carbonate).
                </p>
            </div>

            <div className="em__post-navigation">

                <NavLink to="./../..">
                    <Button btnID={"leftBTN"} buttonSize="btn--medium"> Projects</Button>
                </NavLink>

                <NavLink to="./../sus-lev">
                    <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
                </NavLink>
            </div>


        </div>

    );
}

export default HeadAmp;