import React from "react";
import "./stm_rtos6.css";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";

import mut_mx from "./mut_mx.jpg";
import mut_inv from "./mut_inv.jpg";
import mut_bsem from "./mut_bsem.jpg";

const mut_task1 = `
const uint8_t string[] = "Task1 action.\\r\\n";
/* Infinite loop */
for(;;){
  osDelay(2000);
  osMutexAcquire(mut1Handle, 1000);
  HAL_GPIO_WritePin(TA_GPIO_Port, TA_Pin, GPIO_PIN_SET);
  HAL_UART_Transmit(&huart2, string, strlen((const char*)string), 10);
  HAL_GPIO_WritePin(TA_GPIO_Port, TA_Pin, GPIO_PIN_RESET);
  osMutexRelease(mut1Handle);
}
`;
const mut_task2 = `
const uint8_t string[] = "Task2 action.\\r\\n";
/* Infinite loop */
for(;;){
  osDelay(2000);
  osMutexAcquire(mut1Handle, 1000);
  HAL_GPIO_WritePin(TA_GPIO_Port, TA_Pin, GPIO_PIN_SET);
  HAL_UART_Transmit(&huart2, string, strlen((const char*)string), 10);
  HAL_GPIO_WritePin(TA_GPIO_Port, TA_Pin, GPIO_PIN_RESET);
  osMutexRelease(mut1Handle);
}
`;

const ms_task1 = `
const uint8_t string[] = "Task1 action.\\r\\n";
uint8_t cnt = 0;
/* Infinite loop */
for(;;){
  osDelay(2000);
  osMutexAcquire(mut1Handle, osWaitForever);
  HAL_GPIO_WritePin(TA_GPIO_Port, TA_Pin, GPIO_PIN_SET);
  HAL_UART_Transmit(&huart2, string, strlen((const char*)string), 10);
  HAL_GPIO_WritePin(TA_GPIO_Port, TA_Pin, GPIO_PIN_RESET);
  if(3 == cnt++){
      osThreadSetPriority(Task1Handle, osPriorityLow);
  }
  osMutexRelease(mut1Handle);
}
`;

const ms_task3 = `
const uint8_t string[] = "Task3 action.\\r\\n";
/* Infinite loop */
for(;;){
  HAL_UART_Transmit(&huart2, string, strlen((const char*)string), 10);
  osDelay(2000);
}
`;


function STM_RTOS6(){
    return(
        <div className="em__post">
            <div className="em__post-title">
                <h1>CMSIS Mutexes</h1>
            </div>

            <div className="em__post-section">
                <h3>Aim of this tutorial:</h3>
                <p>
                    In this tutorial we will have a look at an important object for resource management in RTOS.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Resource management and blocking:</h3>
                <p>
                    When it is important that a code section is executed uninterrupted inside the task, we can use critical section markers.
                    The section starts with <i>taskENTER_CRITICAL()</i>, and ends with <i>taskEXIT_CRITICAL()</i>.
                    If we are waiting for an interrupt to occur and no task switching could be permitted,
                    we can use the <i>vTaskSuspendAll()</i> function.
                    The unblocking is done with <i>xTaskResumeAll()</i>.
                </p>
                <p>
                    There is a <b>Gatekeeper task</b>, which is a dedicated procedure to manage selected resources.
                    It has direct access and ownership of resources.
                    Other tasks can access protected resources indirectly through this task (e.g. when we redirect the std out to UART).
                    Most of the time the Gatekeeper task is blocked waiting for resource access requests.
                    It provides a clean method of mutual exclusion with no risk of priority inversion and deadlock.
                </p>
                <p>
                    Another resource manager object is the <b>mutex</b>,
                    which is a binary semaphore that includes a priority inheritance mechanism.
                    A mutex acts like a token that is used to guard a resource.
                    <ul>
                        <li> When a task wants to use the resource, it must take the token.</li>
                        <li> When the task has finished with the resource, it must give the token back so that other tasks could use it.</li>
                        <li> In case of recursive mutexes, the token should be given as many times as it was taken.</li>
                    </ul>
                    <br/>
                    Mutexes use the same API functions as the semaphores.
                    The priority inheritance means that if a high priority task is blocked while attempting to take the token that is held by a low priority task,
                    the priority of the holding task is temporarily raised to that of the blocked task.
                    If a task is deleted while holding a token, the mutex resource will be locked forever.
                    Mutex functions can't be called from interrupts.
                    <br/>
                    A deadlock occurs when two tasks are blocked and are waiting for a resource held by each other.
                    <br/>
                    Priority inversion occurs when a higher priority task is waiting for a lower priority task to give control of the token
                    and the low priority task is not able to execute.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Using mutexes:</h3>
                <p>
                    So far we've used the UART to send sign-of-life information from the task to the PC,
                    which worked out for us without problems.
                    If we want to send multiple messages from different threads, it would be nice if the sending thread would not be preempted in the middle of the operation.
                    We can solve this issue if we take a token before transmitting the message, and we give back the token afterwards.
                </p>
                <p>
                    Let's try it out.
                    Open a previously created project with two tasks of equal priority.
                    Under FreeRTOS/Mutexes tab, add a new mutex named mut1.
                </p>
                <Popup trigger={<img className="img_mut_mx_rtos6 clickable_img" src={mut_mx} alt="mut_mx_rtos6"/>} modal nested>
                    {
                        close =>(
                            <img className="em__img_full" src={mut_mx} alt="mut_mx_rtos6"/>
                        )
                    }
                </Popup>
                <p>
                    Generate the project, and open the <i>app_freertos.c</i> source file.
                    As in the case of semaphores, the mutex is initialised before the tasks.
                    Let's start with the logic of T1:
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {mut_task1}
                </SyntaxHighlighter>
                <p>
                    T1 is blocked for 2s, then it will try to take the mutex. If T1 can take the mutex in  1s,
                    it will execute the next commands, else the task will go into blocked state.
                    After a successful activity, T1 releases the acquired mutex.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {mut_task2}
                </SyntaxHighlighter>
                <p>
                    The same logic is applied to T2. This way we can be sure that the message sent will either consist of pure data from T1 or T2.
                </p>
                <p>
                    Test it out on the hardware in debug mode, with the help of the RTOS perspective.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Mutexes and semaphores:</h3>
                <p>
                    Now that we know how to use mutexes, let's check out what would happen if a task would change its priority.
                    Open the ioc file, and add a new task (Task3 or T3).
                    Also add a binary semaphore, which will be latter used as comparison with mutexes.
                    Re-Generate the project.
                </p>
                <p>
                    Then change the code of T1 so that in the third execution cycle it lowers its priority:
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {ms_task1}
                </SyntaxHighlighter>
                <p>
                    Leave T2 unchanged, and add the following code to the body of T3;
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {ms_task3}
                </SyntaxHighlighter>
                <p>
                    After the third cycle the priority of T1 is lowered, so only T2 and T3 will be executed.
                    This should mean that T3 could write to the UART, but T2 can't since the mutex is taken by T1.
                    Upload the firmware and debug the program:
                    <br/>
                    You should see that T1, T2, T3 are all showing sign of life for 4 turns, after that T2 and T3 shows sign of life.
                    This means that at cycle 3 the priority inheritance of T1 occurred so the mutex could be given back, and T2 took it.
                </p>
                <img className="img_mut_inv_rtos6" src={mut_inv} alt="mut_inv_rtos6"/>
                <p>
                    Now change the mutex usage to that of a binary semaphore using the functions <i>osSemaphoreAcquire</i> and
                    <i>osSemaphoreRelease</i> with the previously created semaphore handle (in my case bSem1Handle).
                </p>
                <img className="img_mut_bsem_rtos6" src={mut_bsem} alt="mut_bsem_rtos6"/>
                <p>
                    You can clearly see that the priority problem is not solved in the case of the binary semaphores.
                </p>
            </div>

            <div className="em__post-section">
                <p>
                    <b>Exercise problem:</b> Create a firmware with 5 tasks and a global circular buffer with depth of 5.
                    T1, T2, T3 are the producer tasks, and they write their respective task number into the buffer 3 times .
                    T4 and T5 read these values from the buffer.
                    Protect the buffer usage with the help of binary / counting semaphores and mutexes.
                    If you are successful, try replacing the semaphores with queues.
                </p>
            </div>



            <div className="em__post-navigation">

                <NavLink to="./../stm-rtos-5">
                    <Button btnID={"leftBTN"} buttonSize="btn--medium"> Previous Post</Button>
                </NavLink>

                <NavLink to="./../stm-rtos-7">
                    <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
                </NavLink>
            </div>
        </div>
    );
}

export default STM_RTOS6;