import React from "react";
import "./dsp_10.css";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";
import {MathJax, MathJaxContext} from "better-react-mathjax";

import iir_filter from "./iir_filter.png";
import iir_magnitude from "./iir_magnitude.png";
import iir_phase from "./iir_phase.png";
import iir_magnitude_same from "./iir_magnitude_same.png";


function ShowTex({string}){
    const config = {
        loader: { load: ["[tex]/html"]},
        tex: {packages: {"[+]": ["html"]},
            inlineMath: [["$", "$"]],
            displayMath: [["$$", "$$"]]
        }
    };

    return(
        <MathJaxContext config={config} version={3}>
            <MathJax dynamic inline>
                {string}
            </MathJax>
        </MathJaxContext>
    );
}

function DSP_10(){
   return(
       <div className="em__post">
           <div className="em__post-title">
               <h1>IIR filter design in Python</h1>
           </div>
           <div className="em__post-section">
               <h3>Overview:</h3>
               <p>
                   In this tutorial, we will learn how to design an IIR filter using analog filter prototypes and the bilinear (Tustin) transform.
                   The design and analysis will be done in Python using the signal processing tools from scipy.
               </p>
           </div>

           <div className="em__post-section">
               <h3>Theory:</h3>
               <p>
                   Remember the transfer function of the last filter, the exponential average filter
                   <ShowTex string="$$ H(z) = \frac{\alpha z}{z + (\alpha - 1)}.$$"/>
                   It was a causal system with region of convergence <ShowTex string="$\mathcal{C} = \{z \in \mathbb{C} \mid |z| > 1 - \alpha\}$"/>.
                   We can take the inverse Z transform of the transfer function to get the impulse response
                   <ShowTex string="$$ h[n] = \frac{1}{2\pi j} \oint_\mathcal{C} \frac{\alpha z^n}{z + (\alpha - 1)}\mathrm{d}z = \alpha (1 - \alpha)^n\quad \textrm{for } n = 0, 1, \dots$$"/>
                   This time a full impulse response is encoded into our difference equation (the magic of feed-back).
                   We don't need to truncate the impulse response, so these filters are called Infinite Impulse Response (IIR) filters.
               </p>
               <p>
                   The IIR filters are based on normalised analog filter prototypes.
                   These are low-pass filters with center frequency of 1 rad/s.
               </p>
               <ul>
                   <li>
                       <b>Butterworth filter:</b> It exhibits a nearly flat pass-band with no ripple, the rolloff is smooth and monotonic.
                       Every pole gives an additional 20dB/decade rolloff rate.
                       It has reasonably good phase response.
                   </li>
                   <li>
                       <b>Chebyshev I/II filter:</b> It has faster rolloff than an equivalent Butterworth filter, but it introduces ripples in the frequency response.
                       If the ripples are allowed in the pass-band the filter is type-1, if the ripples are allowed in the stop-band, then it's type-2.
                       The sharp transition means that the absolute errors are smaller and te execution speed is faster.
                       These filters have poor phase response.
                   </li>
                   <li>
                       <b>Elliptic filter:</b> It has the steepest cut-off slope of the four filter types.
                       The amplitude response has ripples both in pass-band and stop-band.
                       If the primary concern is to pass frequencies falling within a certain frequency band and reject frequencies outside that band,
                       regardless of phase shifts or ringing, the elliptic response will perform that function with the lowest-order filter.
                   </li>
                   <li>
                       <b>Bessel filter:</b> It has maximally flat magnitude and phase response, and nearly linear phase response in the pass-band.
                   </li>
               </ul>
               <p>
                   The order of the prototype filter is dictated by the prescribed ripple, attenuation and transition width.
                   If you have the low-pass filter prototype, the next step is to convert it into the LP, HP, BP, BS filter with the specified properties:
               </p>
               <table>
                   <tr>
                       <th> Filter type </th>
                       <th> Parameter </th>
                       <th> Mapping </th>
                   </tr>
                   <tr>
                       <th>Low-pass</th>
                       <th><ShowTex string="$$\omega_0$$"/> </th>
                       <th><ShowTex string="$$s \to \frac{s}{\omega_0}$$"/></th>
                   </tr>
                   <tr>
                       <th>High-pass</th>
                       <th><ShowTex string="$$\omega_0$$"/></th>
                       <th><ShowTex string="$$s \to \frac{\omega_0}{s}$$"/></th>
                   </tr>
                   <tr>
                       <th>Band-pass</th>
                       <th><ShowTex string="$$\omega_0, Q$$"/></th>
                       <th><ShowTex string="$$s \to Q\frac{s^2 + \omega_0^2}{\omega_0s}$$"/></th>
                   </tr>
                   <tr>
                       <th>Band-stop</th>
                       <th><ShowTex string="$$\omega_0, Q$$"/></th>
                       <th><ShowTex string="$$s \to \frac{\omega_0s}{Q(s^2 + \omega_0^2)}$$"/></th>
                   </tr>
               </table>
               <p>
                   Where <ShowTex string="$\omega_0$"/> is the center frequency.
                   In case of BP/BS filters, we have <ShowTex string="$\omega_L, \omega_H$"/> center frequencies,
                   and from these <ShowTex string="$\omega_0 = 0.5(\omega_L + \omega_H)$"/>, and the quality factor
                   <ShowTex string="$Q = \omega_0/(\omega_H - \omega_L)$"/>.
               </p>
               <p>
                   Now, that we have the configures analog filters, we need to transform them into discrete-time domain.
                   There are more ways to do it: <b>Impulse invariance</b> method -
                   it's good for high-pass filters, matches the impulse response of the analog and digital filter.
                   <b>Step invariance</b> method - it's good for low-pass filters, matches the step response of the analog and digital filters.
                   <b>Bilinear transform</b> (Tustin) method - conformal mapping that avoids anti-aliasing.
                   Stable filters designed with this method are guaranteed to be stable. Althoug it's straight-forward to implement, it introduces frequency warping.
                   <ShowTex string="$$ s = 2f_s \frac{z-1}{z+1} \implies j \omega_a = 2f_s \frac{\mathrm{e}^{j\omega_d}-1}{\mathrm{e}^{j\omega_d}+1}$$"/>
                   <ShowTex string="$$ j \omega_a = 2f_s \frac{\mathrm{e}^{\frac{j\omega_d}{2}}}{\mathrm{e}^{\frac{j\omega_d}{2}}}
                   \frac{\mathrm{e}^{\frac{j\omega_d}{2}}-\mathrm{e}^{-\frac{j\omega_d}{2}}}{\mathrm{e}^{\frac{j\omega_d}{2}}+\mathrm{e}^{-\frac{j\omega_d}{2}}}
                   = 2f_s \frac{2j\sin(\frac{\omega_d}{2})}{2\cos(\frac{\omega_d}{2})} = 2f_s j \tan(\frac{\omega_d}{2}),$$"/>
                   In other words, if we want to achieve a center frequency of <ShowTex string="$\omega_d$"/>,
                   the analog filter must be designed with a center frequency of
                   <ShowTex string="$$ \omega_a = 2f_s \tan(\frac{\omega_d}{2}).$$"/>
                   This method is called frequency pre-warping.
               </p>
               <p>
                   Finally, the discrete-time transfer function
                   <ShowTex string="$$ H(s) = \frac{\sum_{i=0}^{M-1}b_{i}z^{-i}}{1 + \sum_{i=1}^{N-1}a_iz^{-i}}$$"/>
                   is created and must be used.
                   Fortunately all this theory is squeezed into two python functions.
                   One to determine the order of the filter, and one to implement said filter.
               </p>
           </div>

           <div className="em__post-section">
               <h3>Simulation:</h3>
               <p>
                   <b>Task 1:</b> design a low-pass IIR filter for a system with sampling frequency of 1000Hz,
                   with the following properties - pass-band ripple 0.2dB, stop-band attenuation -40dB,
                   transition band 10Hz, center frequency 50Hz.
                   Try out every IIR filter type, compare the order to the order of the FIR filter from tutorial 7.
               </p>
               <p>
                   Use the following functions to generate the filter order using <i>scipy.signal.buttord</i>,
                   <i>scipy.signal.cheb1ord</i>, <i>scipy.signal.cheb2ord</i>, <i>scipy.signal.cheb3ord</i>.
                   Make sure that you are not designing an analog filter.
                   Then use the functions <i>scipy.signal.butter</i>, <i>scipy.signal.cheby1</i>,
                   <i>scipy.signal.cheby2</i>, <i>scipy.signal.ellip</i> to generate the coefficients.
                   I recommend specifying the output as <b>SOS</b>, which does not mean that we are in dire need of help.
                   It means Second Order Stages, and we will talk about them in the next tutorial.
                   Use <i>scipy.signal.sosfreqz</i> to get the frequency function and plot the magnitude/phase  response.
               </p>
               <img className="img_iir_magnitude_dsp-10" src={iir_magnitude} alt="iir_magnitude_dsp-10"/>
               <img className="img_iir_phase_dsp-10" src={iir_phase} alt="iir_phase_dsp-10"/>
           </div>

           <div className="em__post-section">
               <p>
                   The order of the generated filter should be 31, 11, 11, 6 for the Butterworth, Chebyshev I, Chebyshev II and Elliptic filters.
                   From the figures it is clear that the pass-band ripple and stop-ban attenuation fulfills the requirements.
               </p>
               <img className="img_iir_filter_dsp-10" src={iir_filter} alt="iir_filter_dsp-10"/>
               <p>
                   On the temporal diagram, we can see the filtered output. There is a nice representation of the delay introduced due to the size of the filters.
                   The last figure shows the magnitude response of the filters if we keep the order at the same value (N=5).
               </p>
               <img className="img_iir_magnitude_same_dsp-10" src={iir_magnitude_same} alt="iir_magnitude_same_dsp-10"/>
           </div>

           <div className="em__post-section">
               <h3>Further work:</h3>
               <p>
                   <ol>
                       <li>
                           Generate the second order stages for the following Elliptic IIR filters:
                           sampling frequency 5kHz, pass-band ripple 0.2dB, stop-band attenuation -40dB,
                           transition band 10Hz, LP center frequency 50Hz, BP frequencies 50Hz and 500Hz, HP center frequency 800 Hz.
                       </li>
                       <li>
                           Compare the order of the Elliptic filters to the order of Butterworth or Chebyshev I with equivalent parameters.
                       </li>
                       <li>
                           Check with <i>freqz</i> that your filters work as intended.
                       </li>

                   </ol>
               </p>
           </div>


           <div className="em__post-navigation">

               <NavLink to="./../dsp-9">
                   <Button btnID={"leftBTN"} buttonSize="btn--medium"> Prev Post</Button>
               </NavLink>

               <NavLink to="./../dsp-11">
                   <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
               </NavLink>
           </div>
       </div>
   );
}

export default DSP_10;