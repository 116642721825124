import React from "react";
import "./../posts.css";
import "./fpga_6.css";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";
import {MathJax, MathJaxContext} from "better-react-mathjax";
import avg_sine from "./avg_sine.png";
import circuit_1 from "./circuit-1.png";
import circuit_2 from "./circuit-2.png";
import filter_lp from "./filter_lp1.png";
import pwm1 from "./pwm1.jpg";
import pwm2 from "./pwm2.jpg";
import pwm1_sim from "./pwm1_sim.jpg";
import pwm_internals from "./pwm_internals.jpg";
import pwm_sine from "./pwm_sine1.png";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";

function ShowTex({string}){
    const config = {
        loader: { load: ["[tex]/html"]},
        tex: {packages: {"[+]": ["html"]},
            inlineMath: [["$", "$"]],
            displayMath: [["$$", "$$"]]
        }
    };

    return(
        <MathJaxContext config={config} version={3}>
            <MathJax dynamic inline>
                {string}
            </MathJax>
        </MathJaxContext>
    );
}

const pwmGen=`
module pwmGen(clk, rst_n, duty, pwm_out);
    input clk, rst_n;
    input[7:0] duty;
    output pwm_out;
    
    reg [7:0] cnt;
    
    always @(posedge clk) begin
        if (rst_n == 1)
            cnt <= 0;
        else
            cnt <= cnt + 1;
    end
    
    assign pwm_out = (duty > cnt);

endmodule`;

function FPGA_6(){
    return(
        <div className="em__post">
            <div className="em__post-title">
                <h1>DAC on an FPGA</h1>
            </div>
            <div className="em__post-section">
                <h3>Aim of this post:</h3>
                <p>
                    In this tutorial, we will design a pulse width modulated (PWM) signal generator which will serve as the base of a Digital to Analog converter.
                </p>
            </div>
            <div className="em__post-section">
                <h3>Prerequisites:</h3>
                <p>
                    Previous FPGA tutorials, and some analog electronics/filter design knowledge.
                </p>
            </div>
            <div className="em__post-section">
                <h3>DAC with PWM</h3>
                <p>
                    In a digital world with binary logic, we have only two voltage levels 0 or ground, and 1 or the supply voltage.
                    The question is how to create a somewhat continuous time and continuous amplitude signal with
                    a system that works in discrete time, and generates signals with discrete, binary amplitudes?
                </p>
                <p>
                    Let's consider a periodic pulse with a period <b><ShowTex string="$T$"/></b>, the high rail is <b><ShowTex string="$V_{dd}$"/></b>, the low rail is <b>0</b>,
                    and the on-time (high time) is <b><ShowTex string="$t_{on}$"/></b>.
                </p>
                <Popup trigger={<img className="img_pwm1 clickable_img" src={pwm1} alt="pwm1" />} modal nested>
                    {close => (
                        <img className="em__img_full" src={pwm1} alt="pwm1" />
                    )}
                </Popup>
                <p>
                    We can calculate the average of the periodic pulse by integrating the value on the required interval,
                    and by dividing the result by the interval length: <ShowTex string="$$x_{avg} = \frac{1}{t_f-t_0}\int_{t_0}^{t_f}x(t)\mathrm{d}t$$"/>
                </p>
                <p>
                    We only need to integrate the signal from 0 to <ShowTex string="$T$"/> since it's periodic,
                    and the second portion of the integral (in <ShowTex string="$[t_{on}, T]$"/> interval) is 0, so the simplified formula is:
                    <ShowTex string="$$x_{avg} = \frac{1}{T}\int_{0}^{t_{on}}x(t)V_{dd}\mathrm{d}t = \frac{V_{dd}t_{on}}{T}$$"/>
                </p>
                <p>
                    We can see that the average signal depends on the amplitude, the period, and the on-time.
                    The amplitude is fixed, the period could be variable, but it would introduce nonlinearities in the system
                    - and the implementation would require more complex hardware.
                    The on-time, on the other hand, can be variable, it creates a linear function, and it needs a simple device.
                    The <ShowTex string="$t_{on}/T$"/> fraction is the duty cycle of this PWM signal.
                </p>
                <p>
                    Now that we saw how we could create a variable signal by averaging periodic pulses,
                    we only need to implement said PWM generator, and the integrator.
                </p>
            </div>
            <div className="em__post-section">
                <h3>DAC requirements</h3>
                <p>
                    We want a DAC with a resolution of 8 bits, and with a maximum output frequency of 10kHz.
                </p>
                <p>
                    If <ShowTex string="$f_{out} = 10 kHz$"/>, the PWM frequency should be at least ten times faster.
                    We will set <ShowTex string="$f_{pwm} = 20 f_{out}$"/>.
                    This means that the counter must reach a full cycle in a <ShowTex string="$1/f_{pwm}$"/> period.
                    The clock frequency of our 8 bit counter can be calculated with <ShowTex string="$f_{cnt} = 256f_{pwm}$"/>,
                    which in our case, is <ShowTex string="$f_{cnt} = 51.2MHz$"/>.
                </p>
                <p>
                    The averager must pass the signal unmodified from the 0-10kHz interval, and cut everything from 10kHz upwards.
                </p>
            </div>
            <div className="em__post-section">
                <h3>The averager circuit</h3>
                <p>
                    Let's consider a simple series RC circuit, where the input voltage is parallel to the RC circuit,
                    and the output is the voltage across the capacitor.
                </p>
                <img className="img_circuit_1" src={circuit_1} alt="circuit_1" />
                <p>
                    The capacitor has a complex impedance of <ShowTex string="$1/sC$"/>  in s-domain,
                    so the relation between <ShowTex string="$U_{in}$"/>, and <ShowTex string="$U_{out}$"/> can be found by applying Kirchhoff's law to the complex circuit is
                    <ShowTex string="$(RCs + 1)U_{out} = U_{in}$"/>.
                </p>
                <p>
                    The time domain equivalent is: <ShowTex string="$$U_{out} = \frac{1}{RC}\int_{0}^t(U_{in}-U_{out})\mathrm{d}t$$"/>
                </p>
                <p>
                    This clearly shows that the circuit has an averaging/low-pass effect,
                    and we can set the cutting frequency by choosing the C capacitor, and matching the resistor from the formula
                    <ShowTex string="$R = 1/(2pif_cC)$" />.
                    If we want to increase the cutting steepness of the filter,
                    we could chain another RC circuit to the previous one, or we could add an inductor.
                </p>
                <img className="img_circuit_2" src={circuit_2} alt="circuit_2" />
                <p>
                    The inductor has a complex impedance of <ShowTex string="$sL$" /> in s-domain,
                    so the relation between Uin and <ShowTex string="$U_{out}$" /> can be found by applying Kirchhoff's law to the complex circuit:
                    <ShowTex string="$$(s^2 + \frac{R}{L}s + \frac{1}{LC})U_{out} = \frac{1}{LC}U_{in}$$"/>
                </p>
                <p>We can design the components based on this equation, by first choosing a capacitor (or inductance) value, setting the cutting frequency <ShowTex string="$f_c$"/>.
                    We can calculate the inductance (capacity)  with the formula <ShowTex string="$fc = 1/(2*pi*\sqrt{LC})$"/> .
                    We give a value for the resistor by choosing a damping ratio, and by using the formula <ShowTex string="$\xi = R*sqrt(C/L)/2$"/>.</p>
                <p>In our case <ShowTex string="$f_c = 10kHz$"/>, and the damping is <ShowTex string="$\xi = 1$"/>,
                    so the component values are <ShowTex string="$R = 14 \Omega$"/>, <ShowTex string="$L = 100 \mu H$"/>,
                    <ShowTex string="$C = 2.2 \mu F$"/>.
                    - Note the R contains the internal resistance of the inductor too (my inductor conveniently has a resistance of 4Ω).</p>
                <Popup trigger={<img className="img_filter_lp clickable_img" src={filter_lp} alt="filter_lp" />} modal nested>
                    {close => (
                        <img className="em__img_full" src={filter_lp} alt="filter_lp" />
                    )}
                </Popup>
                <p>I've created the filter circuit with the components mentioned above.
                    I've used a swept sine wave signal with an amplitude of 3.3V, and a frequency range from 1Hz to 500kHz with logarithmic sweep time.</p>
                <p>We can see on the oscilloscope figure that the output (orange) has a nice low-pass characteristic just as we wanted.</p>
            </div>

            <div className="em__post-section">
                <h3>The PWM design</h3>
                <Popup trigger={<img className="img_pwm2 clickable_img" src={pwm2} alt="pwm2" />} modal nested>
                    {close => (
                        <img className="em__img_full" src={pwm2} alt="pwm2" />
                    )}
                </Popup>
                <p>
                    For starters let's consider two analogue signals: a ramp signal from 0V to 1V,
                    and a saw-tooth wave with 0.5V amplitude, and a DC offset of 0.5V.
                    What would we get, if we would feed these signals into an analogue comparator?
                </p>

                <p>
                    We get the PWM signal by comparing the saw-tooth signal (-) to the ramp function (+), as the figure on the left shows.
                </p>
                <p>
                    In an FPGA we don't have analogue components, so the saw-tooth signal is replaced by an 8bit up-counter,
                    the ramp signal is replaced by a register which stores the duty cycle value, and the analogue comparator has a digital equivalent.
                </p>
                <p>
                    I've created the Verilog design of the 8bit PWM model (figure on the right).
                    This is the simplest PWM generator one can make.
                    I've set the clock to 1ns in the simulation, and I've changed the duty cycle 0x40, 0x80, 0xFA after 256ns (figure below text).
                </p>
            </div>
            <div className="em__post-section">
                <SyntaxHighlighter language="verilog" style={AtomOneDark}>
                    {pwmGen}
                </SyntaxHighlighter>
                <p>
                    And we encountered the first problem with the simple PWM generator:
                    glitching - we should be able to give a new duty value only when the counter overflows.
                </p>
                <p>
                    Let's try to remove these glitches from our design.
                </p>
                <img className="img_pwm1_sim" src={pwm1_sim} alt="pwm1_sim" />
                <p>
                    The glitch removal is simple, we use a register to store the duty cycle, and we only update the value, when the counter is reset.
                </p>
                <p>
                    We could add buttons, or rotary encoders to test different duty cycle values,
                    or we could create something more exciting — a sine wave with 8b depth.
                    The simplest way to create an arbitrary periodic signal is to use Look-up Tables (LUTs).
                    The LUT contains the data for the signal - this will be implemented as a ROM,
                    for which the address bus will be driven by a counter.
                </p>
                <p>
                    The top module will use the internal oscillator with 53.2MHz frequency.
                    It will contain a frequency divider to 100Hz for the sine wave module - this frequency will be divided further by the number of values in the LUT.
                    Finally, we can connect the output of the sine generator to the input of the PWM generator.
                    The image below shows the implemented design.
                </p>
                <Popup trigger={<img className="img_pwm_internals clickable_img" src={pwm_internals} alt="pwm_internals" />} modal nested>
                    {close => (
                        <img className="em__img_full" src={pwm_internals} alt="pwm_internals" />
                    )}
                </Popup>
                <p>If we take a look at the output of our design on the FPGA with an oscilloscope we can see:</p>
                <img className="img_pwm_sine" src={pwm_sine} alt="pwm_sine" />
                <p>Here we can see that the frequency is constant,
                    and the duty cycle changes in a periodic manner - this is expected.
                    We can also see some distortions in the signal,
                    but that is only an impedance matching problem.
                    Now we can connect the previously created second-order low-pass filter circuit.</p>
                <img className="img_avg_sine" src={avg_sine} alt="avg_sine" />
                <p>And the anticipated 8b sine signal appeared.
                    Looking at this image a few questions could arise:
                    Why is the half value section twice as long as the others?
                    Because I've included twice the value 128, once at the start, and once at the end.
                    Could we reduce distortion created by the discrete amplitude changes?
                    Yes, we could, by increasing the LUT values.</p>
                <p>Could we simplify this LUT while maintaining a decent signal shape?
                    We could. The sine wave has some nice properties, which enable us to use only a quarter LUT.
                    In that case, we need an up-down counter and a sign changer.
                    We could also use a CORDIC algorithm.</p>
                <p>Are there other implementations for generating analogue signals in FPGA?
                    There are.
                    One example is the sigma-delta or delta-sigma modulation.</p>
                <p>The example shown so far is uploaded to the <a href="https://gitlab.com/Csurleny/fpga-tutorial-files/-/tree/main/pwmDAC">git</a>.</p>
            </div>

            <div className="em__post-navigation">

                <NavLink to="./../fpga-tut-5">
                    <Button btnID={"leftBTN"} buttonSize="btn--medium"> Previous Post</Button>
                </NavLink>

                <NavLink to="./../fpga-tut-7">
                    <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
                </NavLink>
            </div>
        </div>
    );
}

export default FPGA_6;