import React from "react";
import "./mcu_hal7.css";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";

import timing from "./uart_timing.jpg";
import usart_hw from "./usart_hw_setup.jpg";
import pt_setup from "./putty_setup.jpg";
import pt_tx_1 from "./putty_tx_1.jpg";
import pt_tx_2 from "./putty_tx_2.jpg";

const tx_cmd = `
HAL_UART_Transmit(&huart2, tx_str, 17, 0xFFFF);
HAL_Delay(1000);
`;

const custom_write = `
/* USER CODE BEGIN 4 */
int _write(int fd, char *ptr, int len){
    HAL_UART_Transmit(&huart2, (uint8_t *) ptr, len, HAL_MAX_DELAY);
    return len;
}
/* USER CODE END 4 */
`;


function MCU_HAL7(){
    return(
        <div className="em__post">
            <div className="em__post-title">
                <h1>Universal Asynchronous Receiver/Transmitter - Transmitting data</h1>
            </div>
            <div className="em__post-section">
                <h3>Aim of this tutorial:</h3>
                <p>
                    Before we dwell into more complex projects, we should have a some means of communication between different MCUs or
                    between the PC and MCU.
                    Keeping this in mind, In the present tutorial we will learn to transmit data on a UART interface.
                </p>
            </div>

            <div className="em__post-section">
                <h3>UART / USART:</h3>
                <p>
                    The <b>U</b>niversal <b>A</b>synchronous <b>R</b>eceiver/<b>T</b>ransmitter is a peripheral that
                    converts an incoming data byte into outgoing serial bit stream and vice versa.
                </p>
                    <img className="img_timing_hal7" src={timing} alt="timing" />
                <p>
                    The communication starts as Idle (high) state, then a start (low) bit is asserted.
                    Then up to 9 data bits are transmitted depending on the number of data bits in the protocol setup.
                    8 data bits are the most widely used.
                    We have an optional parity bit - this can be disabled, set to Odd or Even parity (of the data frame).
                    Finally there can be one or two stop bits (high).
                </p>
                <p>
                    The speed of data transfer in UART communication is measured in Baud rate.
                    It is the number of distinct symbol changes (signaling events) per second.
                    Common Baud rates are 9600, 19200, 115200, 1.8432M Baud/s etc.
                    These numbers are remnants of the past from teletypes. As long as both the receiver and the transmitter are using the same speed,
                    or one of them is configures with auto-baud detection, only the system clock and the pre-scalers are the limit.
                </p>
                <p>
                    <b>USART</b> differs from UART by a single letter, which stands for Synchronous.
                    While the UART generates the clock internally, and synchronises with the data stream using the start bit transition,
                    the USART can run in synchronous mode, and the clock can be recovered from the data stream.
                    This allows the communication much higher data transfer speeds (~ 4MBaud/s).
                </p>
                <p>
                    Another difference is the number of format options.
                    The UART offers the change of data, parity and stop bits, while the USART can generate data for many different protocols
                    such as IrDA, RS-485, Modbus, LIN, etc, oh and it can also function as UART in asynchronous mode.
                    The UART handles low(er) speed communications with reduced energy consumption.
                </p>
                <p>
                    <span className="em__highlight"> <b>Important</b>: When connecting two devices with U(S)ART communication protocol,
                        the transmission line of one of the devices is the reception line of the other and vice versa.</span>
                </p>
            </div>
            <div className="em__post-section">
                <h3>Implementation:</h3>
                <p>
                    Create a new project in CLion using the STM32CubeMX configurator with the same basic setup as before.
                    Enable the USART2 communication interface from Connectivity by setting it to Asynchronous mode and
                    leaving everything else with the default parameters.
                </p>
                <Popup trigger={<img className="img_usart_hw_hal7 clickable_img" src={usart_hw} alt="usart_hw_hal7"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={usart_hw} alt="usart_hw_hal7" />
                    )}
                </Popup>

                <p>
                    We use the USART2 since the RX/TX pins on PA3/PA2 are connected to the on-board ST-Link programmer,
                    which implements a Virtual COM Port for easy USB communications.
                </p>

                <p>
                    In the <i>main.c</i> file create an array to store the string that we want to transmit:
                    <i>uint8_t tx_str[] = "Hello from MCU!\r\n"</i>, and in the main loop we can transmit this string with the following command:
                </p>

                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {tx_cmd}
                </SyntaxHighlighter>

                <p>
                    The transmit command requires the pointer to the communication module (huart2), the pointer to the string,
                    the size of the data (17 bytes), and the maximum time-out for the function.
                </p>

                <p>
                    After uploading the firmware, you should check the name of the Virtual COM Port.
                    In Windows this is done by opening the Device Manager, and under Ports( COM & LPT) there should be an
                    STMicroelectronics STLink Virtual COM Port (COMx). Mine is COM10.
                    In Linux you should list the devices under <i>/dev/</i> and look for something like <i>ttyACMx</i> or <i>ttyUSBx</i>.
                </p>
                <p>
                    Open up a COM port program such as Putty, set it to Serial with the proper COM port name (COM10 for me),
                    and speed (115200 if you left everything at default settings).
                </p>
                <img className="img_putty_setup_hal7" src={pt_setup} alt="putty_setup" />
                <p>
                    You should be able to see our message appearing every second.
                </p>
                <img className="img_putty_tx_1_hal7" src={pt_tx_1} alt="pt_tx_1" />

            </div>

            <div className="em__post-section">
                <h3>Binding printf:</h3>
                <p>
                    For the majority of embedded applications, the HAL_UART_Transmit function is sufficient.
                    In other cases where you would want to seamlessly transmit strings of variable sizes and with multiple variables embedded into them,
                    a clean option is to bind the <i>printf</i> function.
                </p>
                <p>
                    First, include the <i>stdio</i> so that you can use the printf.
                </p>
                <p>
                    Next implement a custom <i>_write</i> function:
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {custom_write}
                </SyntaxHighlighter>
                <p>
                    This function is defined as an <i>__attribute__ ((weak))</i> in the IO library meaning that if we define it, the program will use our definition.
                    Which is good, since we want tu specify the UART as the transfer media.
                </p>
                <p>
                    Now we can use printf as in any other C program.
                    create a counter inside the main function <i>uint16_t counter = 0;</i> and
                    replace the HAL Transmit with <i> printf("Messages sent to PC: %d\r\n", counter++);</i>.
                    You should see the message in Putty, which dynamically increases the array size as the counter value increases.
                </p>
                <img className="img_putty_tx_2_hal7" src={pt_tx_2} alt="pt_tx_2" />
                <p>
                    The UART TX source files are uploaded <a href="https://gitlab.com/stm32_mcu_group/stm32_hal/7_uart_tx.git">here</a>, and
                    in the next tutorial we will take a look at the different methods of data reception using UART.
                </p>
            </div>




            <div className="em__post-navigation">

                <NavLink to="./../stm-hal-6">
                    <Button btnID={"leftBTN"} buttonSize="btn--medium"> Previous Post</Button>
                </NavLink>

                <NavLink to="./../stm-hal-8">
                    <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
                </NavLink>
            </div>
        </div>
    );
}

export default MCU_HAL7;