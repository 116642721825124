import React from "react";
import "./card.css";
import {Button} from "../index";

function Card({title, description, imgURL, onClick}){
    return (
        <div className="card-container">
            <div className="image-container">
                <img src={imgURL} alt=""/>
            </div>
            <div className="card-content">
                <div className="card-title">
                    <h3>{title}</h3>
                </div>
                <div className="card-body">
                    <p>{description}</p>
                </div>
            </div>
            <div className="card-button">
                <Button onClick={onClick}>View more</Button>
            </div>

        </div>
    );
}

export default Card;