import React from "react";
import "./dsp_1.css";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";
import mx_setup_1 from "./mx_setup_1.jpg";
import mx_setup_2 from "./mx_setup_2.jpg";
import mx_setup_3 from "./mx_setup_3.jpg";
import monitor_setup_1 from "./monitor_setup_1.jpg";
import monitor_setup_2 from "./monitor_setup_2.jpg";
import monitor_setup_3 from "./monitor_setup_3.jpg";
import monitor_app from "./monitor_app.jpg";

const includes = `
#include "stdint.h"
#include "stdbool.h"
`;

const private_variables = `
uint16_t counter = 0;
volatile bool is_counting = false;
`;

const external_interrupt = `
void HAL_GPIO_EXTI_Callback(uint16_t GPIO_Pin){
    if(GPIO_Pin == B1_Pin){
        is_counting = !is_counting;
    }
}
`;

const while_loop = `
if(is_counting)
    ++counter;
if(counter == 100)
    counter = 0;
HAL_GPIO_TogglePin(LD2_GPIO_Port, LD2_Pin);
HAL_Delay(500);
`;

function DSP_1(){
   return(
       <div className="em__post">
           <div className="em__post-title">
               <h1>Environment setup</h1>
           </div>
           <div className="em__post-section">
               <h3>Aim of the series:</h3>
               <p>
                   Digital Signal Processing (<b>DSP</b>) is the use of computers or other specialized processors to
                   perform a variety of signal processing operations.
                   In this manner, the signals represent samples of continuous variable in time, space or frequency domain.
                   DSP applications include audio processing, sonar, radar or other sensor array processing,
                   spectral density estimation, digital image processing, data compression, signal coding, biomedical engineering etc.
                   <br/>
                   DSP can involve linear or nonlinear operations, and can be implemented in time, frequency or spacio-temporal domains.
                   In the first part of the series, we will talk about DSP using linear operations in either time or frequency domain.
                   The second part will contain advanced non-linear operations and mixed time-frequency domains.
               </p>
           </div>

           <div className="em__post-section">
               <h3>About the boards:</h3>
               <p>These tutorials will focus on using the hardware abstraction layer (HAL) provided by ST Micro,
                   so any board with an ARM Cortex M4F (the <b>F</b> is crucial for the floating point operations) MCU can be used with minimal or no modification.
                   In the first part I will mostly use a <a href="https://www.st.com/en/evaluation-tools/nucleo-f411re.html">NUCLEO-64 with STM32F411</a>, but
                   sometimes I will bring out the <a href="https://www.st.com/en/evaluation-tools/nucleo-g491re.html">NUCLEO-G491</a> to show the hardware acceleration of the algorithms.
               </p>
           </div>

           <div className="em__post-section">
               <h3>About the software:</h3>
               <p>
                   We will use the <a href="https://www.st.com/en/development-tools/stm32cubeide.html">STM32CubeIDE</a> to program the MCUs,
                   <a href="https://www.st.com/en/development-tools/stm32cubemonitor.html">STM32CubeMonitor</a> to visualise the register values.
                   We will also use Python (<a href="https://www.jetbrains.com/pycharm/">PyCharm</a>) to design and test some of the algorithms.
               </p>
           </div>

           <div className="em__post-section">
               <h3>Setting up a hello world program:</h3>
               <p>
                   Open up STM32CubeIDE and create a new project from <i>File/New/ STM32 Project</i>.
                   Go to <i>Board Selector</i>, Select NUCLEO-F411RE, give a name for the project.
                   Do not initialize the peripherals with default values.
                   <br/>
                   Disable the OSC32 on the pinout view.
                   In System Core/SYS enable the Trace Asynchronous SW for debug purposes.
                   In System Core/RCC set the HSE to bypass clock source - the clock is generated by the on-board programmer.
                   <Popup trigger={<img className="img_mx_setup_1_dsp-1 clickable_img" src={mx_setup_1} alt="mx_setup_1_dsp-1"/>} modal nested>
                       {close => (
                           <img className="em__img_full" src={mx_setup_1} alt="mx_setup_1_dsp-1" />
                       )}
                   </Popup>
                   <br/>
                   We already have the LED and Buttons configured as digital input and external interrupt.
                   In Clock Configuration set the PLL source mux to HSE, and set the HCLK to 100MHz.
                   <Popup trigger={<img className="img_mx_setup_2_dsp-1 clickable_img" src={mx_setup_2} alt="mx_setup_2_dsp-1"/>} modal nested>
                       {close => (
                           <img className="em__img_full" src={mx_setup_2} alt="mx_setup_2_dsp-1" />
                       )}
                   </Popup>

                   <br/>
                   <Popup trigger={<img className="img_mx_setup_3_dsp-1 clickable_img" src={mx_setup_3} alt="mx_setup_3_dsp-1"/>} modal nested>
                       {close => (
                           <img className="em__img_full" src={mx_setup_3} alt="mx_setup_3_dsp-1" />
                       )}
                   </Popup>
                   In Pinout & configuration / System / NVIC enable the EXTI line [15:10] interrupts (1).
                   In the Project Manager/ Code Generator check <i>Generate peripheral initialization as a pair of '.c/.h' files per peripheral</i>.
                   This will keep the project nice and tidy.
                   Now generate the project (2).
               </p>

               <p>
                   After generating the project, open the <i>main.c</i> source file.
                   We will use 8b, 16b, 32b signed and unsigned integers as well as booleans so we need to include the following two headers:
                   <div className="em_unselectable">


                   <SyntaxHighlighter language="c" style={AtomOneDark}>
                       {includes}
                   </SyntaxHighlighter>
                   </div>
                   We need a counter for debug purposes, and we also need a flag to start and stop counting.
                   <div className="em_unselectable">
                   <SyntaxHighlighter language="c" style={AtomOneDark}>
                       {private_variables}
                   </SyntaxHighlighter>
                   </div>
                   Next, we can handle the external interrupt:
                   <div className="em_unselectable">
                   <SyntaxHighlighter language="c" style={AtomOneDark}>
                       {external_interrupt}
                   </SyntaxHighlighter>
                   </div>
                   In this case, we don't need to check the source of the interrupt, since we only have one enabled, but it's good practice to do so.
                   Finally, in the main function, go to the super loop (while), and paste the following code:
                   <div className="em_unselectable">
                   <SyntaxHighlighter language="c" style={AtomOneDark}>
                       {while_loop}
                   </SyntaxHighlighter>
                   </div>
                   This is a simple code. The on-board LED is toggled with a period of 1s, while the counter is incremented if the counting flag is set.
                   The counter is reset if the value is 100.
               </p>

               <p>
                   Build and upload the code.
                   You should see the LED blinking.
               </p>
               <p>
                   Now let's move to the STM32CubeMonitor side. This is a Node-Red-based program with STMicro flavors.
                   Here, we need to connect to the programmer/debugger,
                   and we also need the elf file that we previously generated so that we can look at different memory regions.
               </p>
               <img className="img_monitor_setup_1_dsp-1" src={monitor_setup_1} alt="monitor_setup_1_dsp-1" />
                <p>
                    The light blue blocks (1) are regular button nodes with topics: start, stop, clear.
                    The system will know that these are used for starting and stopping the data acquisition and for clearing the graph.
                    <br/>
                    The next block (2) is the variable node. Inside, we need to create a new executable. Navigate to the Debug folder of your CubeIDE project,
                    and select the generated .elf file.
                </p>
               <img className="img_monitor_setup_2_dsp-1" src={monitor_setup_2} alt="monitor_setup_2_dsp-1" />
               <p>
                   If the .elf file is found, the node will display every global variable used (that is not optimised out).
                   For now, select the counter and the counting flag.
                   <br/>
                   Next, we need a processing node (3).
                   If you give the same group name as for the variable node, the monitored variables will be carried over.
                   <br/>
                   In order for us to be able to communicate with the hardware, we need an ACK IN (5) and ACK OUT (4) node.
                   Add a new probe to them. The ST-Link should be automatically recognized if you've connected the board.
               </p>
               <img className="img_monitor_setup_3_dsp-1" src={monitor_setup_3} alt="monitor_setup_3_dsp-1" />
               <p>
                   Finally, for display purposes, we need a chart node with the default 1s display duration.
                   Deploy the project and open the dashboard with the top-right buttons.
                   When you start the acquisition, you should see two constant 0 lines - this is not interesting.
                   Push the user button - the counting flag changes to 1, and the counter value starts to increase.
               </p>
               <img className="img_monitor_app_dsp-1" src={monitor_app} alt="monitor_app_dsp-1" />
               <p>
                   In this tutorial, we started a firmware project in STM32CubeIDE and we also created a monitoring application for the hardware.
                   We will use these tools intensively in future DSP tutorials.
                   We did not talk about Pycharm and python, since the workflow is easy, and we will discuss it on the go.
               </p>

           </div>


           <div className="em__post-navigation">

               <NavLink to="./..">
                   <Button btnID={"leftBTN"} buttonSize="btn--medium"> DSP Tutorials</Button>
               </NavLink>

               <NavLink to="./../dsp-2">
                   <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
               </NavLink>
           </div>
       </div>
   );
}

export default DSP_1;