import React from "react";
import "./mcu_hal6.css";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";
import {MathJax, MathJaxContext} from "better-react-mathjax";

import encoder_logic from "./encoder_logic.jpg";
import tim2 from "./tim2.jpg";
import tim3 from "./tim3.jpg";
import signal from "./encoder_output.jpg";
import pullup from  "./tim_pull_up.jpg";
import hardware from "./hard.jpg";
import count from "./count.jpg";

function ShowTex({string}){
    const config = {
        loader: { load: ["[tex]/html"]},
        tex: {packages: {"[+]": ["html"]},
            inlineMath: [["$", "$"]],
            displayMath: [["$$", "$$"]]
        }
    };

    return(
        <MathJaxContext config={config} version={3}>
            <MathJax dynamic inline>
                {string}
            </MathJax>
        </MathJaxContext>
    );
}

const enc_int = `
uint16_t counter = 0;
void HAL_TIM_IC_CaptureCallback(TIM_HandleTypeDef *htim){
    counter = __HAL_TIM_GET_COUNTER(&htim3);
    __HAL_TIM_SET_COMPARE(&htim2, TIM_CHANNEL_1,counter<<4);
}
`;

const enc_setup = `
HAL_TIM_Encoder_Start_IT(&htim3, TIM_CHANNEL_ALL);
HAL_TIM_PWM_Start(&htim2, TIM_CHANNEL_1);
`;

function MCU_HAL6(){
    return(
        <div className="em__post">
            <div className="em__post-title">
                <h1>Hardware timers - rotary encoder</h1>
            </div>
            <div className="em__post-section">
                <h3>Aim of this tutorial:</h3>
                <p>
                    In this tutorial we will set up a timer to read rotary encoder values and
                    to set the brightness of an LED based on the encoder information.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Encoders:</h3>
                <p>
                    First we need to look at what makes the encoders tick.
                    Encoders are electro-mechanical devices that are widely used in applications.
                    Encoders can be used as speed and position sensors in motor control applications,
                    for distance and length measurements, as an input device for the users of an interface.
                </p>
                <p>
                    In contrast to potentiometers, encoders do not have start and end limits,
                    so we can turn them indefinitely (or for as long as the mechanical wear lets us to do it).
                </p>
                <p>
                    If we take a look at the available <a href="https://duckduckgo.com/?q=encoder&t=newext&atb=v309-1&iax=images&ia=images">encoders</a>,
                    we can spot two major types: absolute and incremental encoders.
                    Furthermore, the encoding mechanism can be pure mechanical (two switches), magnetic (magnetic disc and Hall-effect sensors),
                    inductive (inductive sensor and disc with changing permeability),
                    capacitive (capacitive sensors and disc with changing permittivity),
                    optical (an LED illuminates a photo receptor, with an optical binary grading in the path),
                    and probably many more.
                </p>
                <img className="img_abs_enc" src="https://automation-insights.blog/wp-content/uploads/2015/09/opticalencoder.jpg" alt="img_absolute"/>
                <br/> <br/> <br/> <br/>
                <p>
                    The absolute encoder, as the name suggests, can tell the absolute position.
                    This is done using a clever encoding named <a href="https://en.wikipedia.org/wiki/Gray_code">Gray code</a>.
                </p>
                <p>
                    The image on the left shows the encoder disc of a <ShowTex string={"$10b$"}/> absolute encoder.
                    Here the resolution is <ShowTex string={"$10b$"}/> which translates to a resolution of <ShowTex string={"$360/1024 = 0.35^{\\circ}/bit$"}/>.
                    This also means that every ring needs a separate sensor.
                    We can see that the size and cost of such a device can go up fast.
                </p>
            </div>
            <div className="em__post-section">
                <p>
                    The alternative to absolute encoders are the rotary encoders, where we have two shifted gratings,
                    and we get the information of relative motion using only two sensors.
                    This reduces the price, but also removes the absolute information.
                </p>
                <p>
                    In order to tackle this problem, sometimes we create a zero position sensing.
                    This can be done using a limit switch or using a magnet and Hall-effect sensor.
                    At the start of the application (calibration phase) we rotate (push) the encoder until the switch is pressed or the magnet reaches the sensor.
                    This will be the zero position, and we will reference the encoder information to this point.
                </p>
            </div>
            <div className="em__post-section">
                <h3>Encoder Interface:</h3>
                <img className="img_encoder_logic" src={encoder_logic} alt="encoder_logic"/>
                <p>
                    In this tutorial, we will use an incremental encoder (quadrature encoder).
                    This encoder has two switches tied to a common signal (it will be ground in our case).
                    The switches require pull-up (or pull-down, if the common is tied to positive supply) resistors.
                    Each of the two channels provide a specific number of equally spaced pulses per revolution (N).
                    These pulses are 90º out of phase.
                </p>
                <p>
                    The direction of the motion is detected using the phase relationship of one channel leading or trailing the other channel.
                    Usually this would be done using jelly-bean logic circuits: let's consider a rising endge triggered D-latch with
                    Channel A connected to the clock and Channel B connected to the data.
                </p>
                <p>
                    If Channel B trails Channel A, then on every rising edge of the clock, the data will be low.
                    Next, if we use an up-down counter, where the direction is given by the D-latch while the clock being the same signal as the clock of the Latch,
                    we got ourselves an incremental counter interface.
                    Of course, this only gives the received number of pulses.
                    If we know the number of pulses per revolution and the time between consecutive readings, we can give the rotation speed.
                    If we have rotation speed, we can easily give the relative position by numerical integration.
                </p>
                <img className="img_counter_hal6" src={count} alt="counter_hal6"/>
                <p>
                    We can increase the resolution of the encoder interface in the STM32 by counting both the leading and trailing edges of the "clock" channel (x2).
                    We can further increase this resolution if we count these edges on both channels (x4).
                </p>
            </div>
            <div className="em__post-section">
                <h3>Implementation:</h3>
                <Popup trigger={<img className="img_tim2_hal6 clickable_img" src={tim2} alt="tim2"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={tim2} alt="tim2" />
                    )}
                </Popup>
                <p>
                    First, let's create a project as usual.
                    Only this time, we will change the PA5 pin from general purpose output to TIM2_CH1,
                    and set up TIM2 for PWM signal generation on channel 1.
                    Set the prescaler to 2 and the counter to 65535.
                    This serves two purposes: we could use the encoder count directly as duty cycle,
                    and the generated signal frequency is around 1300Hz, which is way above the dynamic threshold of the eye (and that of the diode too)
                    so that we won't see a flicker.
                </p>
                <Popup trigger={<img className="img_pull_hal6 clickable_img" src={pullup} alt="pull"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={pullup} alt="pull" />
                    )}
                </Popup>
                <p>
                    Next, we configure TIM3 for the encoder interface.
                    Select TIM3, and in the Combined Channels, set Encoder Mode (if our encoder would have a zero position output, we could set to Encoder + Index).
                    Leave the prescaler at 0, since we will use a "human-based propulsion" system which is slow enough.
                    Set the counter period to maximum (65535).
                    In the encoder mode, choose <i>Encoder Mode TI1 and TI2</i>, which will give a x4 resolution.
                    Polarity (the counting edge) can be left as default, we don't need prescaler division,
                    and we can enable a digital input filter of 3.
                    Finally, enable the TIM3 Global Interrupt.
                </p>
                <p>
                    Generate the project.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {enc_int}
                </SyntaxHighlighter>
                <p>
                    We need a variable to store the value of the encoder TIM.
                    Since the TIM has a 16b counter register, we can use a 16b unsigned integer also.
                    For this example, I've used the input compare capture callback interrupt, which is nice If we only want to read the encoder as a means for user input.
                    The timer will generate an interrupt at every change.
                    I've also multiplied the input value by 16 so the change in brightness would be more apparent.
                </p>
                <p>
                    If our aim was to read speed/position measurements, then we would need a basic timer with fixed interrupts,
                    and we would read the TIM3 counter register in the interrupt callback.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {enc_setup}
                </SyntaxHighlighter>
                <p>
                    Of course, we need to start the TIM modules.
                    Build the project and flash the program.
                    Connect CHA and CHB pins of the encoder to PA4 and PA5 of the MCU, and the middle pin to ground.
                    You should see that you can't change the brightness of the on-board LED.
                </p>
                <p>
                    Remember that we need the pull-up resistors on the CHA/CHB pins.
                    You could use two 10k resistors and connect them to Vcc (3.3V), or go to System Core/GPIO/TIM and activate the internal Pull-up resistors on the pins.
                </p>
                <Popup trigger={<img className="img_tim3_hal6 clickable_img" src={tim3} alt="tim3"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={tim3} alt="tim3" />
                    )}
                </Popup>
                <p>
                    This time you should see the expected result.
                    I've posted an image containing the waves on the encoder output, and an image with the ad-hoc hardware setup.
                    The source files can be found <a href="https://gitlab.com/stm32_mcu_group/stm32_hal/6_rotaryencoder.git">here</a>.
                </p>

                <img className="img_signal_hal6" src={signal} alt="signal"/>
                <br/>
                <img className="img_hardware_hal6" src={hardware} alt="hardware"/>
            </div>


            <div className="em__post-navigation">

                <NavLink to="./../stm-hal-5">
                    <Button btnID={"leftBTN"} buttonSize="btn--medium"> Previous Post</Button>
                </NavLink>

                <NavLink to="./../stm-hal-7">
                    <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
                </NavLink>
            </div>
        </div>
    );
}

export default MCU_HAL6;