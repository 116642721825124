import React from "react";
import "./../posts.css";
import "./fpga_8.css";
import LM8 from "./Mico8BlockDiagram.png";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";

const platform_top = `
\`include "../soc/lm8Test.v"
\`include "../../../Digits/SegmentLogic_verilog.v"

module platform1_top(
    input reset_n, 
    input uartSIN,
    output uartSOUT,
    output [6:0] Segment_out
);
    wire clk_w;
    OSCH OSCH_inst(.OSC(clk_w), .SEDSTDBY(), .STDBY(1'b0));
    defparam OSCH_inst.NOM_FREQ = "24.18";
    
    wire[3:0] d_bcd_w;
    
    SegmentLogic sl_inst(.din(d_bcd_w), .dout(Segment_out));
    
    lm8Test lm8Test_inst(
        .clk_i(clk_w),
        .reset_n(reset_n),
        .LEDPIO_OUT(d_bcd_w),
        .uartSIN(uartSIN),
        .uartSOUT(uartSOUT));
endmodule
`;

const text = `
    char *LED_MSG_1 = "-- LED TOGGLE TEST -- \\r\\n";
    char *LED_MSG_2 = "Press x to return to Main Menu \\r\\n";
    char *LED_MSG_3 = "Press 0 through 9 to toggle LEDs \\r\\n";
    char *LED_MSG_4 = ">";
`;

const loop = `
do{
    MicoUart_getC(pUart, &rcvdChar);
    if(rcvdChar == 'x')
        break;
    switch(rcvdChar){
        case '0': MICO_GPIO_WRITE_DATA_BYTE0(pGpio->base, 0x0); break;
        case '1': MICO_GPIO_WRITE_DATA_BYTE0(pGpio->base, 0x1); break;
        case '2': MICO_GPIO_WRITE_DATA_BYTE0(pGpio->base, 0x2); break;
        case '3': MICO_GPIO_WRITE_DATA_BYTE0(pGpio->base, 0x3); break;
        case '4': MICO_GPIO_WRITE_DATA_BYTE0(pGpio->base, 0x4); break;
        case '5': MICO_GPIO_WRITE_DATA_BYTE0(pGpio->base, 0x5); break;
        case '6': MICO_GPIO_WRITE_DATA_BYTE0(pGpio->base, 0x6); break;
        case '7': MICO_GPIO_WRITE_DATA_BYTE0(pGpio->base, 0x7); break;
        case '8': MICO_GPIO_WRITE_DATA_BYTE0(pGpio->base, 0x8); break;
        case '9': MICO_GPIO_WRITE_DATA_BYTE0(pGpio->base, 0x9); break;
        default: break;
    }
}while(1);
`;

function FPGA_8(){
    return(
      <div className="em__post">
          <div className="em__post-title">
              <h1>Soft processors</h1>
          </div>
          <div className="em__post-section">
              <h3>Aim of this post:</h3>
              <p>
                  In this tutorial, we will use the LM8 soft processor to display numbers received from UART terminal on a 7-Segment LED display.
              </p>
          </div>
          <div className="em__post-section">
              <h3>Prerequisites:</h3>
              <p>
                  Previous FPGA tutorials, especially Tut2.
              </p>
          </div>
          <div className="em__post-section">
              <h3>The LM8 soft processor</h3>
              <p>
                  There are essentially two ways to implement processors in ICs: Hard Core -
                  when the processor is etched in the silicon fabric (used for microcontrollers, DSCs, microprocessors, etc.),
                  and Soft Core - when the core is only a configuration of an FPGA fabric (Proprietary cores such as PicoBlaze,
                  MicroBlaze for Xilinx; NIOS, NIOS II for Intel/Altera; LM8, LM32 for Lattice.
                  There are open source cores such as Amber, BERI, AEMB etc.).
              </p>
              <p>
                  Hard Core processors can run at faster clock rates (> 1GHz) because the architecture is optimised,
                  and not bounded by fabric limits.
                  The downside is the fixed architecture - once etched, it can not be modified.
              </p>
              <img className="img_lm8" src={LM8} alt="lm8"/>
              <p>
                  Soft Core processors are limited by the speed of the fabric (&lt; 800 MHz).
                  Features can be tuned, added or removed as needed. The core can be multiplied, making it a multi-core processor.
              </p>
              <p>
                  The LM8 is an 8bit microcontroller,
                  which is ideal for our MachXO2 FPGA since it will leave a large part of the configurable logic
                  unpopulated for custom IP (uses less than 200 LUTs).
              </p>
              <p>
                  LM8 uses 18b wide instruction set, 32 general purpose registers.
              </p>
              <p>
                  A Wishbone Bus is implemented in the processor, so it is easy to interface the hardened peripherals (SPI, I2C, Timer, UART).
              </p>
          </div>
          <div className="em__post-section">
              <h3>Setting up the processor:</h3>
              <p>
                  We will use the Lattice Mico Systems program to configure the soft processor.
              </p>
              <ul>
                  <li>Open Lattice Diamond, and create a project named lm8_led with LCMXO2-1200HC-4SG32C.</li>
                  <li>Open Lattice Mico Systems (LMS).
                      <ul>
                      <li>In LMS choose LMS perspective and choose <i>File>New Platform</i>. </li>
                      <li>Choose the created folder as a workspace, using LM8 processor, Shared bus arbitration scheme,
                          25MHz board frequency. Set the FPGA as MachXO2 with all devices,
                          and use a blank platform template.</li>
                  </ul></li>
                  <li>Add the LM8 processor from the CPU category.
                  <ul>
                      <li>Set 32 general purpose registers.</li>
                      <li>Use the distributed RAM for internal storage.</li>
                      <li>Use a stack depth of 16.</li>
                      <li>Set the PROM size to 2048 (the max amount of assembly instructions).</li>
                      <li>Set the internal Scratch Pad size to 0x800 - this is a small memory zone for fast RW instructions.</li>
                  </ul></li>
              </ul>
              <p><i>Note:</i> We can use external volatile memory for the Scratch Pad, and external non-volatile memory for the ROM.</p>
              <p>In the MSB perspective, we should see the LM8 Core with the data port and scratch pad.
                  The black line with the outbound arrow indicates a master port in the Wishbone Bus.</p>
          </div>
          <div className="em__post-section">
              <h3>Peripheral components</h3>
              <p>
                  The first component will be a GPIO port for the 7 segment display,
                  for which we will use our previously written decoder module.
                  Double click on GPIO under the IO category.
              </p>
              <ul>
                  <li>Change the name to LED.</li>
                  <li>Change the data width to 4.</li>
                  <li>Change the Wishbone Bus width to 8.</li>
                  <li>Set the port type to outputs only.</li>
              </ul>
              <p> The second component will be a UART instance with default settings.</p>
              <p> In the MSB perspective, we should see some blue lines with inbound arrows, which are slave ports.
                  The master port can initiate, read and write data transaction,
                  while the slave can only reply if the transaction address corresponds.</p>
          </div>
          <div className="em__post-section">
              <h3>Addresses, priorities, and design rules of the peripherals</h3>
              <p>
                  First, we connect the peripherals to the Wishbone Bus by clicking the circles.
                  We need to generate an address for each peripheral, which can be done with <i>Platform Tools > Generate Address</i>.
                  Similarly, we must assign priorities for the peripheral interrupts with <i>Platform Tools > Generate IRQ</i>.
                  The last step is to check the design rules with <i>Platform Tools > Run DRC</i>.
                  If everything is done right, we should have 0 errors.
              </p>
          </div>
          <div className="em__post-section">
              <h3>Platform and Top-Level module</h3>
              <p>
                  We can generate the microcontroller platform with <i>Platform Tools > Run Generator</i>.
                  The generated platform may not be the top-level module in the design, so we need to create the actual top-level module.
                  Create a new file in platform1/soc folder, name it platform1_top.v, copy the following Verilog content, and import into Diamond.
              </p>
              <SyntaxHighlighter language="verilog" style={AtomOneDark}>
                  {platform_top}
              </SyntaxHighlighter>
              <p>Here platform1.v contains the architecture of the LM8 created above.
                  We have the UART input, and output and the Segment control signal outputs.
                  We set the internal oscillator to 24.18MHz to provide the clock signal for the processor.
                  We also create an instance of the BCD 2 Segment logic from the previous tutorial.
                  Finally, we create an instance of the processor, and we connect every required IO.</p>
          </div>
          <div className="em__post-section">
              <h3>Software application code</h3>
              <p>
                  Next, we will create the software application by using C/C++ in Lattice MicoSystem Software Project Environment.
                  This code will run on the processor.
                  Let's switch to the C/C++ perspective in LMS
              </p>
              <ul>
                  <li>File > New > Mico Managed Make C Project.</li>
                  <li>Choose &lt;...>/platform1/ as the project content location.</li>
                  <li>Choose &lt;...>/platform1/soc/platform1.msb as the target hardware MSB system.</li>
                  <li>Choose the UART test project template.</li>
              </ul>
              <p>
                  The UART test program creates an UART echo function, and an LED cycling function.
                  We will change the LED function.
              </p>
              <p>
                  First, let's change the displayed message.
                  The original function used the '0' as the escape character, but we want to display the numbers 0 ... 9,
                  so we will use 'x' as the escape character, and the message will look like:
              </p>
              <SyntaxHighlighter language="C" style={AtomOneDark}>
                  {text}
              </SyntaxHighlighter>
              <p>
                  Lastly, we will change the content of the infinite loop in the SM_Toggle function:
                  If we receive a numeric character, send the numeric value (hex) to the BCD decoder.
                  Writing to the GPIO is done with the MICO_GPIO_WRITE_DATA function, which expects a 32b value.
                  For our 4b wide GPIO we can use the MICO_GPIO_WRITE_DATA_BYTE0 macro as shown below:
              </p>
              <SyntaxHighlighter language="C" style={AtomOneDark}>
                  {loop}
              </SyntaxHighlighter>
          </div>
          <div className="em__post-section">
              <h3>Final tasks</h3>
              <ol>
                  <li> We need to build our C program with the Project > Build Project option.
                      The compiler generates an .elf (executable linked format) file,
                      which contains the LM8 instructions and the pre-initialized data.</li>
                  <li> We need to deploy the program to the LM8 by using the Tools > Software Deployment option.
                      Here we create a new launch configuration specifying the previously generated .elf file.
                      This will generate the required PROM and Scratch Pad memory files.</li>
                  <li> We load the generated memory files into the LM8.
                      In LMS perspective double-click on the processor, and add the initialisation files (prom_init.mem,
                      scratchpad_init.mem) for the PROM and Scratch Pad.</li>
                  <li> Generate the processor Verilog module with the included memory units by using
                      Platform Tools > Run Generate.</li>
                  <li> Configure the Diamond environment: Tools > Options > General - clear
                      the option "Copy file to Implementation's Source directory when adding existing file".
                      We don't want to copy the source files, only to reference them.
                      This way, any change in the sources is automatically loaded at implementation.</li>
                  <li> Synthesise the design.</li>
                  <li> Add the pin configuration in text mode (.lpf) or spreadsheet view.</li>
                  <li> Map the design.</li>
                  <li> Place and Route.</li>
                  <li> Generate JEDEC file.</li>
                  <li> Upload the generated file with the programmer.</li>
                  <li> If everything went according to plan we should be greeted by a simple text menu in a terminal program,
                      and we have the option to use a UART echo module, or to display numbers as shown in <a href="https://imgur.com/a/T8L8kVX">this</a> short video.</li>
              </ol>
              <p>
                  As always, the necessary files can be found <a href="https://gitlab.com/Csurleny/fpga-tutorial-files/-/tree/main/softCoreProc">here</a>.
              </p>
          </div>

          <div className="em__post-navigation">

              <NavLink to="./../fpga-tut-7">
                  <Button btnID={"leftBTN"} buttonSize="btn--medium"> Previous Post</Button>
              </NavLink>

              <NavLink to="./../fpga-tut-9">
                  <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
              </NavLink>
          </div>

      </div>
    );
}

export default FPGA_8;