import React from "react";
import "./mcu_hal16.css";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";
import {MathJax, MathJaxContext} from "better-react-mathjax";

import injected_channel from "./injected_timing.jpg";
import adc_channels from "./adc_channels.jpg";
import adc_conversions from "./adc_conversionss.jpg";
import adc_ranks from "./adc_ranks.jpg";
import final_pic from "./injected_pic.jpg";

function ShowTex({string}){
    const config = {
        loader: { load: ["[tex]/html"]},
        tex: {packages: {"[+]": ["html"]},
            inlineMath: [["$", "$"]],
            displayMath: [["$$", "$$"]]
        }
    };

    return(
        <MathJaxContext config={config} version={3}>
            <MathJax dynamic inline>
                {string}
            </MathJax>
        </MathJaxContext>
    );
}

const static_text = `
SSD1306_GotoXY(25, 0);
SSD1306_Puts("ADC Injected:", &font_7x10, SSD1306_COLOR_WHITE );
SSD1306_GotoXY(0, 10);
SSD1306_Puts("Pot:", &font_7x10, SSD1306_COLOR_WHITE );
SSD1306_GotoXY(0, 20);
SSD1306_Puts("Temp:", &font_7x10, SSD1306_COLOR_WHITE );
SSD1306_GotoXY(0, 30);
SSD1306_Puts("Vbat:", &font_7x10, SSD1306_COLOR_WHITE );
SSD1306_GotoXY(0, 40);
SSD1306_Puts("Vref:", &font_7x10, SSD1306_COLOR_WHITE );
SSD1306_UpdateScreen();
`;

const poll_injected = `
HAL_ADCEx_InjectedStart(&hadc1);
HAL_ADC_PollForConversion(&hadc1,100);
adc_val[0] = ((float)HAL_ADCEx_InjectedGetValue(&hadc1,ADC_INJECTED_RANK_1))*3.3/4096;
adc_val[1] = (float)HAL_ADCEx_InjectedGetValue(&hadc1,ADC_INJECTED_RANK_2))/4096;
adc_val[2] = ((float)HAL_ADCEx_InjectedGetValue(&hadc1,ADC_INJECTED_RANK_3))*3.3*3.0/4096;
adc_val[3] = ((float)HAL_ADCEx_InjectedGetValue(&hadc1,ADC_INJECTED_RANK_4))*3.3/4096;
`;

const stop_injected = `
sprintf(string, "%.1f V", adc_val[0]);
SSD1306_GotoXY(35, 10);
SSD1306_Puts(string, &font_7x10, SSD1306_COLOR_WHITE );
sprintf(string, "%.1f C", adc_val[1]);
SSD1306_GotoXY(42, 20);
SSD1306_Puts(string, &font_7x10, SSD1306_COLOR_WHITE );
sprintf(string, "%.1f V", adc_val[2]);
SSD1306_GotoXY(42, 30);
SSD1306_Puts(string, &font_7x10, SSD1306_COLOR_WHITE );
sprintf(string, "%.1f V", adc_val[3]);
SSD1306_GotoXY(42, 40);
SSD1306_Puts(string, &font_7x10, SSD1306_COLOR_WHITE );

HAL_ADCEx_InjectedStop(&hadc1);
SSD1306_UpdateScreen();
HAL_Delay(10);
`;

const cal_data = `
const uint16_t  TS_CAL1= *((uint16_t*) 0x1FFF75A8);
const uint16_t  TS_CAL2= *((uint16_t*) 0x1FFF75CA);
`;

const irq_injected = `
void HAL_ADCEx_InjectedConvCpltCallback(ADC_HandleTypeDef* hadc){
    adc_uint_val[0] = HAL_ADCEx_InjectedGetValue(&hadc1,ADC_INJECTED_RANK_1);
    adc_uint_val[1] = HAL_ADCEx_InjectedGetValue(&hadc1,ADC_INJECTED_RANK_2);
    adc_uint_val[2] = HAL_ADCEx_InjectedGetValue(&hadc1,ADC_INJECTED_RANK_3);
    adc_uint_val[3] = HAL_ADCEx_InjectedGetValue(&hadc1,ADC_INJECTED_RANK_4);
    adc_irq_flag = 1;
    HAL_ADCEx_InjectedStop_IT(&hadc1);
}
`;

const irq_main = `
if(adc_irq_flag){
    adc_val[0] = (float) (adc_uint_val[0]) * 3.3 /4096.0;
    adc_val[1] = AVG_SLOPE*(adc_uint_val[1] - TS_CAL1)+60.0;
    adc_val[2] = (float) (adc_uint_val[2]) * 3.3 * 3.0 /4096.0;
    adc_val[3] = (float) (adc_uint_val[3]) * 3.3 /4096.0;
    
    adc_irq_flag = 0;
    HAL_ADCEx_InjectedStart_IT(&hadc1);
}
`;


function MCU_HAL16(){
    return(
        <div className="em__post">
            <div className="em__post-title">
                <h1>ADC injected channels</h1>
            </div>

            <div className="em__post-section">
                <h3>Aim of this tutorial:</h3>
                <p>
                    In this tutorial we will sample and convert four ADC channels using injected mode.
                    One of the channels will be the external input from the potentiometer,
                    the others are the internal temperature sensor, internal reference voltage and the battery voltage.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Injected conversion mode:</h3>

                <p>
                    The injected mode can be used to synchronise conversions to an internal or external event.
                    The injected channels take priority over the regular channels.
                    If an injected trigger event occurs, the regular conversion is interrupted.
                </p>

                <img className="img_injected_channel_hal16" src={injected_channel} alt="injected_channel_hal16" />
                <p>
                    You can read more about the ADCs in application note <a href="https://www.st.com/resource/en/application_note/cd00258017-stm32s-adc-modes-and-their-applications-stmicroelectronics.pdf">AN3116</a>.
                    First, we will create a project using only injected channels in scan mode.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Project setup:</h3>
                <p>
                    Create a new project in CubeMX enabling the high speed oscillator and the debugger.
                    Enable the I2C from the Connectivity - we will display the ADC data on the OLED screen.
                    In Analog/ADC1 enable the IN1 channel in single-ended mode, scroll down, enable the Temperature sensor,
                    Vbat channel and Vrefint channel.
                </p>

                <Popup trigger={<img className="img_adc_channels_hal16 clickable_img" src={adc_channels} alt="adc_channels_hal16"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={adc_channels} alt="adc_channels_hal16" />
                    )}
                </Popup>
                <p>
                    Disable the regular conversion, enable the injected conversions, and increase the number of conversions to four.
                    After that four conversions should appear below the current configuration list.
                </p>
                <Popup trigger={<img className="img_adc_conversions_hal16 clickable_img" src={adc_conversions} alt="spi_adc_conversions_hal16"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={adc_conversions} alt="spi_adc_conversions_hal16" />
                    )}
                </Popup>
                <p>
                    Select the IN1 channel for Rank1 with a sampling time of 247.5 Cycles, the temperature sensor for the second
                    with a sampling time of 640.5 Cycles, the Vbat for the third with the same sampling time as before,
                    and finally the Vrefint with a sampling time of 247.5 Cycles. Check the <a href="https://www.st.com/resource/en/datasheet/stm32g491re.pdf">datasheet</a>
                    and the <a href="https://www.st.com/resource/en/reference_manual/dm00355726-stm32g4-series-advanced-arm-based-32-bit-mcus-stmicroelectronics.pdf">reference manual</a> to read more about the ADC conversion characteristics and timing requirements.
                </p>

                <Popup trigger={<img className="img_adc_ranks_hal16 clickable_img" src={adc_ranks} alt="adc_ranks_hal16"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={adc_ranks} alt="adc_ranks_hal16" />
                    )}
                </Popup>

                <p>
                    Generate the project, and let's start writing a simple firmware.
                </p>
            </div>

            <div className="em__post-section">
                <h3>The firmware:</h3>
                <p>
                    The first step is to import the SSD1306 driver and font files from a previous project.
                    These files are required so that the MCU can display text using the OLED display.
                    Calibrate the ADC and initialise the display.
                    Output a static text, that we won't update in the loop:
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {static_text}
                </SyntaxHighlighter>
                <p>
                    In the loop, start the injected acquisition, then poll for the conversion end.
                    When the conversion is finished, read the digital data and rescale it as required.
                    The external ADC linearly maps the voltage interval [0V, 3.3V] onto the digital interval [0b, 4095b].
                    So we need to multiply with 3.3 and divide by 4096. Same in the case of the reference voltage.
                    The battery voltage uses an internal divider by 3 so an additional multiplication is required there.
                    Finally, we don't know what to do with the internal temperature (for now).
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {poll_injected}
                </SyntaxHighlighter>
                <p>
                    After the conversion, we can stop the injected ADC, and we can print our data.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {stop_injected}
                </SyntaxHighlighter>
                <p>
                    The temperature sensor is a linear sensor (+/-1°C of linearity) with an average slope of 2.5 mV/°C
                    and 0.76V at 30°C according to the datasheet. The calibration is done at two temperature points:

                    <ul>
                        <li> The raw data acquired at 30°C (+/- 5°C) using a reference of 3V (+/- 10 mV) is stored in
                            the memory at address 0x1FFF75A8 - 0x1FFF75A9.</li>
                        <li> The raw data acquired at 130°C (+/- 5°C) using a reference of 3V (+/- 10 mV) is stored in
                            the memory at address 0x1FFF75CA - 0x1FFF75CB.</li>
                    </ul>
                    Using this information we can calculate the slope of the linear function as <ShowTex string="$m = \frac{T_1 - T_2}{V_1 - V_2} = \frac{100}{V_1 - V_2}$"/>,
                    where <ShowTex string="$V_1, V_2$"/> are the stored voltage values.
                    Next, we can calculate the temperature from the ADC value using the slope and point formula of the line <ShowTex string="$y = m(x-V_1) + T_1 = m(x-V_1) + 30$"/>.
                    So, we can get the calibration data as:
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {cal_data}
                </SyntaxHighlighter>

                <p>
                    Build the project, and upload the firmware to test it out. The first value should change as you rotate the potentiometer.
                    The second value is the die temperature, so expect it to be higher than the room temperature,
                    but you can check it it increases if you put a finger (preferably one that is still attached to a body) on the IC.
                    The third value is the Vbat, this is connected to 3.3V by default on the NUCLEO boards.
                    Finally, the Vref should be 1.2V according to the datasheet.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Enabling the interrupt:</h3>
                <p>
                    Let's try to optimise the stall time of the processor.
                    Open CubeMX, go to Analog/ADC1. At the <i>End of Conversion Selection</i> select <i>EOC flag at the end of all conversions</i>
                    so that the interrupt is generated when all four conversions are finished.
                    Speaking of interrupts, let's enable the interrupt in the NVIC tab.
                </p>
                <p>
                    Let's create two volatile variable to use in the interrupt and main context: <i>volatile uint16_t adc_uint_val[4]</i>,
                    <i>volatile uint8_t adc_irq_flag = 0</i>.
                    Next, move the functions that read the conversion results from the main function to the interrupt routine.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {irq_injected}
                </SyntaxHighlighter>
                <p>
                    When the data is ready, we can scale it and the display part remains the same as in the polling mode.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {irq_main}
                </SyntaxHighlighter>
                <p>
                    If everything vent well, you should only see difference in the processor throughout time.
                </p>
                <img className="img_final_pic_hal16" src={final_pic} alt="adc_final_pic_hal16"/>
                <p>
                    As an exercise, you could add two regular channels to the ADC (eg. IN1, IN2), leave the other three as injected channels.
                    Start the regular acquisition with interrupts, but at higher frequency than the injected channels.
                    Debug the project and check the execution priority.

                    Otherwise, the project files can be downloaded from <a href="https://gitlab.com/stm32_mcu_group/stm32_hal/16_adc_injected.git">here</a>.
                </p>
            </div>


            <div className="em__post-navigation">

                <NavLink to="./../stm-hal-15">
                    <Button btnID={"leftBTN"} buttonSize="btn--medium"> Previous Post</Button>
                </NavLink>

                <NavLink to="./../stm-hal-17">
                    <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
                </NavLink>
            </div>
        </div>
    );
}

export default MCU_HAL16;