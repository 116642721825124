import React from "react";
import "./../hobby.css";
import "./../collection.css";
import {Tag} from "../../../common";
import {useNavigate} from "react-router-dom";
import {useRef} from "react";

function WHITEHUB(){
    const navigate = useNavigate();


    const whitePeonyRef = useRef(null);
    const simpleDreamsRef = useRef(null);
    const silverNeedleRef = useRef(null);
    const shunMingDaoRef = useRef(null);
    const moonlightWhiteRef = useRef(null);
    const minFengOldRef = useRef(null);
    const menghaiLaoShuRef = useRef(null);
    const manGangGuShuRef = useRef(null);
    const guanYangWildRef = useRef(null);
    const fudingShouMeiRef = useRef(null);
    const elderWhiteRef = useRef(null);
    const chenNianShouMeiRef = useRef(null);
    const chaNongHaoRef = useRef(null);
    const BaoFengXiangJiRef = useRef(null);
    const manGangTeaFlowerRef = useRef(null);
    const lbcsmKTMRef = useRef(null);
    const xgygbKTMRef = useRef(null);

    const tealist = [
        {name:"1. White Peony (ML)", ref: whitePeonyRef},
        {name:"2. Simple Dreams (ML)", ref: simpleDreamsRef},
        {name:"3. Silver Needle (ML)", ref: silverNeedleRef},
        {name:"4. Shun Ming Dao Fuding (YS)", ref: shunMingDaoRef},
        {name:"5. Moonlight White (ML)", ref: moonlightWhiteRef},
        {name:"6. Min Feng old tree white (YS)", ref: minFengOldRef},
        {name:"7. Menghai Lao Shu Bai Cha (YS) ", ref: menghaiLaoShuRef},
        {name:"8. Man Gang Gu Shu Bai Cha (YS)", ref: manGangGuShuRef},
        {name:"9. Guan Yang Wild White (YS)", ref: guanYangWildRef},
        {name:"10. Elder white (ML)", ref: elderWhiteRef},
        {name:"11. Chen Nian Shou Mei (YS)", ref: chenNianShouMeiRef},
        {name:"12. Cha Nong Hao Meng Song (YS)", ref: chaNongHaoRef},
        {name:"13. Bao Feng Xiang Ji (YS)", ref: BaoFengXiangJiRef},
        {name:"14. Man Gang tea flowers (YS)", ref: manGangTeaFlowerRef},
        {name:"15. Fuding Shou Mei (YS)", ref: fudingShouMeiRef},
        {name:"16. Lao Bai Cha - Shou Mei (KTM)", ref: lbcsmKTMRef},
        {name:"17. XiaGuan Yue Guang Bai (KTM)", ref: xgygbKTMRef},
    ];

    return (
        <div className="em__post">
            <div className="em__post-section">
                <h3>About the history and production of white teas</h3>
                <p>
                    White tea traces its roots back to China's Fujian province, first cultivated over a thousand years ago.
                    According to legend, white tea was accidentally discovered during the Tang Dynasty when tea leaves were left to dry naturally in the sun.
                    This gentle processing method, involving minimal oxidation, led to the creation of white tea, named after the silver-white appearance of its young tea buds.
                    Of course, using only the buds was okay in a day and age where only the emperor was drinking it.
                    With the increased demand, producers are using different parts of the bushes, as we will see later on.
                </p>
                <p>
                    The process is simple: pick the leaves, dry them and ship them.
                    The problem is in the nuances - when the process is this simple, there is no room for errors or mistakes to be fixed.
                    White tea is made from the young tea buds and leaves of the Camellia Sinensis plant.
                    The production process emphasizes minimal oxidation, preserving the delicate and nuanced flavours of the tea.
                    Here are the key steps involved:
                </p>
                <ul>
                    <li>
                        <b>Plucking:</b>
                        The finest white tea is hand-plucked during a specific window when the tea buds are still covered in fine white hairs, indicating their optimal maturity.
                    </li>
                    <li>
                        <b>Withering:</b>
                        The tea buds and leaves are carefully spread out to wither naturally after plucking.
                        This process allows the moisture to evaporate slowly, resulting in slightly wilted leaves.
                    </li>
                    <li>
                        <b>Drying:</b> The withered tea leaves undergo a gentle drying process.
                        Traditionally, they are dried in the sun or air-dried indoors.
                        This step halts oxidation, locking in the tea's natural freshness and preserving its delicate flavours.
                        Modern white tea production utilizes moisture and temperature-controlled ovens
                        with forced ventilation to increase quality quantity and retain consistency.
                    </li>
                </ul>
                <p>
                    White tea comes in several varieties, each with unique characteristics. Here are a few well-known types:
                </p>
                <ol>
                    <li>
                        <b>Silver Needle (Baihao Yinzhen):</b>
                        Considered the most prized and revered white tea, Silver Needle is made from tea buds covered in silvery-white hairs.
                        It offers a subtle, delicate flavour with notes of honey and a lingering sweetness.
                    </li>
                    <li>
                        <b>White Peony (Bai Mudan):</b>
                        This tea consists of tea buds and young leaves.
                        It has a slightly stronger flavour profile than Silver Needle, with a balanced combination of floral and fruity notes.
                    </li>
                    <li>
                        <b>Tribute Eyebrow (Gong Mei):</b>
                        Consists of slightly larger and more mature leaves compared to other white teas.
                        It typically includes both tea buds and young leaves.
                        The leaves may vary in size and have a mix of green, brown, and silver hues.
                        Gong Mei offers a mild and delicate flavour profile with a pleasant balance of sweetness and earthiness.
                        It has a smooth and slightly fuller-bodied taste compared to more refined white teas like Silver Needle.
                        Gong Mei often possesses subtle floral and fruity notes and gentle earthy undertones.
                    </li>
                    <li>
                        <b>Longevity Eyebrow (Shou Mei):</b>
                        Made from the mature leaves of the tea plant, Longevity Eyebrow tea has a fuller-bodied flavour, displaying earthy, woody undertones with a mild sweetness.
                        Shou Mei has a more robust and bolder flavour compared to Gong Mei.
                        It has a robust and somewhat earthy taste with hints of sweetness.
                        Shou Mei's flavour profile tends to be more pronounced and can display woody or nutty notes.
                        The tea liquor of Shou Mei is typically deeper in colour compared to other white teas.
                    </li>
                </ol>
                <p>
                    When the tea dries out, it's ready for consumption.
                    Most white teas are rested briefly to dissipate the green aroma.
                </p>
                <p>
                    Since white tea is not fixed (the enzymes are not deactivated by heating), some moisture and time will do wonders with the tea.
                    Storing the tea in a more humid environment will allow it to age, making it sweeter and fruitier.
                    While Gong Mei is a type of white tea that can be aged, it is generally not aged as extensively as some other white teas like Silver Needle.
                    Its flavour and aroma may evolve with time, but it is often consumed when relatively fresh to enjoy its delicate flavours.
                    Due to its larger and more mature leaves, Shou Mei is often considered more suitable for ageing.
                    With proper storage conditions, Shou Mei can undergo post-fermentation over time, resulting in deeper and richer flavours.
                    Aged Shou Mei teas may develop complex earthy, nutty, and mellow profiles.
                </p>
            </div>

            <div className="em__collection">
                <div className="em__collection-title">
                    <h1 className="gradient__text">Sampled white teas:</h1>
                </div>

                <div className="em__collection-list">
                    {
                        tealist.map((item) => (
                            <div >
                                <Tag title={item.name} description={item.description} onClick={() => item.ref.current.scrollIntoView({ behavior: 'smooth' })}/>
                            </div>
                        ))
                    }
                </div>

                <div className="em__post-section">
                    <div id="1. White Peony (ML)" ref={whitePeonyRef}>
                        <h3>1. White Peony (ML)</h3>
                        <p>
                            Authentically sun-dried Fuding Mu Dan from 50-year-old bushes.
                            <br/>
                            Powerful green herbal taste with soy milk.
                            Balanced floral and fruity taste reminiscent of summer meadows.
                            Slightly astringent and has a sweet aftertaste.
                            <br/>
                            Green/Vegtal: 7/10, Floral: 6/10, Fruits: 6/10, Spices: 1/10, Wood/Earth: 1/10, Roast/Nuts: 1/10, Cream: 4/10, Mineral: 3/10.

                        </p>
                    </div>
                    <div id="2. Simple Dreams (ML)" ref={simpleDreamsRef}>
                        <h3>2. Simple Dreams (ML)</h3>
                        <p>
                            Semi-aged Fuding White from 50-year-old tea bushes picked in 2018.
                            <br/>
                            Fresh greens, meadow flowers.
                            Sweet and creamy, potent tea camouflaged as an easygoing one.
                            <br/>
                            Green/Vegtal: 4/10, Floral: 4/10, Fruits: 8/10, Spices: 8/10, Wood/Earth: 3/10, Roast/Nuts: 1/10, Cream: 7/10, Mineral: 5/10.
                        </p>
                    </div>
                    <div id="3. Silver Needle (ML)" ref={silverNeedleRef}>
                        <h3>3. Silver Needle (ML)</h3>
                        <p>
                            Early Spring Guangxi Silver Needle picked from over 70-year-old tea trees.
                            <br/>
                            Thick liqueur is full of floral scent and melon and honey taste.
                            Mild and mellow flavours of hay and buttery shortbread.
                            The last brews have a distinct tilia flower aroma.
                            <br/>
                            Green/Vegtal: 5/10, Floral: 8/10, Fruits: 8/10, Spices: 4/10, Wood/Earth: 1/10, Roast/Nuts: 1/10, Cream: 6/10, Mineral: 4/10.
                        </p>
                    </div>
                    <div id="4. Shun Ming Dao Fuding (YS)" ref={shunMingDaoRef}>
                        <h3>4. Shun Ming Dao Fuding (YS)</h3>
                        <p>
                            Shou Mei was harvested in the spring of 2013 and pressed into the cake in 2014.
                            <br/>
                            Raspberry, maple syrup, pine resin, dry grass, honey.
                            Slightly sour, slightly astringent, and sweet.
                            It has a strong fruity taste that transforms into a vanilla aftertaste.
                            Every infusion is strong.
                            <br/>
                            Green/Vegtal: 2/10, Floral: 4/10, Fruits: 9/10, Spices: 6/10, Wood/Earth: 1/10, Roast/Nuts: 1/10, Cream: 2/10, Mineral: 4/10.
                        </p>
                    </div>
                    <div id="5. Moonlight White (ML)" ref={moonlightWhiteRef}>
                        <h3>5. Moonlight White (ML)</h3>
                        <p>
                            Enigmatic White tea is made from 150-year-old Camellia Taliensis trees in Yunnan.
                            <br/>
                            Apricot, marzipan, cinnamon, cloves, slightly floral and slightly minty.
                            Maple syrup sweetness.
                            The texture is smooth.
                            Warming body sensation with cooling mouth feel.

                            <br/>
                            Green/Vegtal: 2/10, Floral: 6/10, Fruits: 6/10, Spices: 4/10, Wood/Earth: 2/10, Roast/Nuts: 1/10, Cream: 7/10, Mineral: 4/10.
                        </p>
                    </div>
                    <div id="6. Min Feng old tree white (YS)" ref={minFengOldRef}>
                        <h3>6. Min Feng old tree white (YS)</h3>
                        <p>
                            This is a pure assamica varietal first flush of spring 2019 pluck from 60-120 tea trees growing on Min Feng Mountain in Yong De county of Lincang.
                            Harvested in late March 2019.
                            <br/>
                            Mixed citrus zest, slight astringent, pine resin, and linden flowers.
                            <br/>
                            Green/Vegtal: 1/10, Floral: 3/10, Fruits: 6/10, Spices: 9/10, Wood/Earth: 2/10, Roast/Nuts: 1/10, Cream: 3/10, Mineral: 5/10.
                        </p>
                    </div>
                    <div id="7. Menghai Lao Shu Bai Cha (YS)" ref={menghaiLaoShuRef}>
                        <h3>7. Menghai Lao Shu Bai Cha (YS)</h3>
                        <p>
                            This is the first flush tea cake pressed from Spring 2015 harvested "Chang Ye Bai Hao."
                            Varietal white tea plants are growing in the Gelanghe area of Menghai County.
                            <br/>
                            Honey, smokey maple syrup, slight green leafy notes. Cinnamon, allspice, pine.
                            Strong tea with minimal astringency.
                            <br/>
                            Green/Vegtal: 3/10, Floral: 2/10, Fruits: 5/10, Spices: 8/10, Wood/Earth: 1/10, Roast/Nuts: 1/10, Cream: 3/10, Mineral: 5/10.
                        </p>
                    </div>
                    <div id="8. 2019 Man Gang Gu Shu Bai Cha (YS)" ref={manGangGuShuRef}>
                        <h3>8. 2019 Man Gang Gu Shu Bai Cha (YS)</h3>
                        <p>
                            This is an old arbour white tea cake made from Autumn 2019 tea harvested from 80-300-year-old tea trees in Man Gang village and then blended with tea flowers.
                            <br/>
                            The balanced taste between bright and earthy flavours.
                            Sweet, smooth, floral and berries with a bit of creamy aftertaste lingering on your palate.
                            The aroma has a strong note of pine resin, complemented by raisins, custard and forest floor.
                            The medium-bodied liquor displays several muted flavours, such as grass flowers, fermented cabbage, and cherry wood.
                            In the spicy, expansive aftertaste, I also detected a celery-like note.
                            <br/>
                            Green/Vegtal: 3/10, Floral: 7/10, Fruits: 6/10, Spices: 6/10, Wood/Earth: 3/10, Roast/Nuts: 1/10, Cream: 5/10, Mineral: 4/10.

                        </p>
                    </div>
                    <div id="9. Guan Yang Wild White (YS)" ref={guanYangWildRef}>
                        <h3>9. Guan Yang Wild White (YS)</h3>
                        <p>
                            This tea is harvested from untended bushes growing in the Guan Yang area of Fuding at an altitude of 900 meters.
                            In August 2015, harvested Gong Mei pluck white tea was aged for two years in Fuding before being pressed into this cake in 2017.
                            Stored in Fuding (Fujian) until early 2020.
                            <br/>
                            This is a nice smooth tea with slight sweet dandelion hints a couple of infusions in and turns slightly more floral in successive infusions.
                            It had the characteristic apricot and honey taste of aged white teas combined with fresh paper in a printing shop.
                            <br/>
                            Green/Vegtal: 1/10, Floral: 4/10, Fruits: 7/10, Spices: 5/10, Wood/Earth: 1/10, Roast/Nuts: 1/10, Cream: 7/10, Mineral: 4/10.
                        </p>
                    </div>
                    <div id="10. Elder white (ML)" ref={elderWhiteRef}>
                        <h3>10. Elder white (ML)</h3>
                        <p>
                            Elder White is a special bud tea made from the ancillary buds of wild tea trees growing on the Western border of Yunnan.
                            These tea trees (Assamica Dehongensis) can be used to make Yunnan Black and PuErh tea, but in late Winter, some of the ancillary buds are picked in small quantities.
                            The ancillary or lateral buds are the first shoots of the year, which will become stems. This is different to the terminal buds used in most tea production.
                            This is what white tea was supposed to be like back when it was invented, but the production is not maintainable since the new branches would grow from these buds.
                            <br/>
                            It has a powerful floral aroma.
                            The tea has some pine resin taste with ginger and galangal flavour.
                            It has a slight spiciness, which transforms into citrus-like sweetness at the end of brewing.
                            <br/>
                            Green/Vegtal: 1/10, Floral: 10/10, Fruits: 6/10, Spices: 7/10, Wood/Earth: 4/10, Roast/Nuts: 1/10, Cream: 2/10, Mineral: 4/10.
                        </p>
                    </div>
                    <div id="11. 2014 Chen Nian Shou Mei (YS)" ref={chenNianShouMeiRef}>
                        <h3>11. 2014 Chen Nian Shou Mei (YS)</h3>
                        <p>
                            This Shou Mei was aged as a loose-leaf tea for three years in Fuding and then for two years in Kunming before being pressed into a cake.
                            The result is a lightly aged but very clean Shou Mei that brews up an incredibly rich orange-hued tea soup.
                            <br/>
                            The leaves produced thick, oily brews with sweet honey, roasted chicory and melon flavour.
                            The leaves smelled like dark chocolate and apricots.
                            <br/>
                            Green/Vegtal: 1/10, Floral: 6/10, Fruits: 8/10, Spices: 8/10, Wood/Earth: 2/10, Roast/Nuts: 1/10, Cream: 2/10, Mineral: 4/10.
                        </p>
                    </div>
                    <div id="12. Cha Nong Hao Meng Song (YS)" ref={chaNongHaoRef}>
                        <h3>12. Cha Nong Hao Meng Song (YS)</h3>
                        <p>
                            In the spring of 2018, I harvested tea from pure assamica bushes growing in Meng Song village.
                            <br/>
                            Absolutely classic compressed white tea flavour profile.
                            The aroma of the dried leaves is low, but the tea is an aroma bomb.
                            Flavours of autumn, notes of currants and hay with vanilla-like sweetness.
                            <br/>
                            Green/Vegtal: 1/10, Floral: 4/10, Fruits: 6/10, Spices: 9/10, Wood/Earth: 4/10, Roast/Nuts: 1/10, Cream: 1/10, Mineral: 4/10.

                        </p>
                    </div>
                    <div id="13. Bao Feng Xiang Ji (YS)" ref={BaoFengXiangJiRef}>
                        <h3>13. Bao Feng Xiang Ji (YS)</h3>
                        <p>
                            This is entirely 2012 spring-harvested Gong Mei White Tea from the Fuding area of Fujian.
                            It was aged in a clean/dry storage condition until it was pressed on April 10th 2018 and then stored in Kunming thereafter.
                            <br/>
                            Strong and fragrant.
                            Astringency is nearly non-existent.
                            The first few infusions tasted like an average gong mei.
                            After that, the tea transformed into peaches, dates, sugar cane, and malt with a slight herbal undertone.
                            <br/>
                            Green/Vegtal: 3/10, Floral: 6/10, Fruits: 8/10, Spices: 6/10, Wood/Earth: 1/10, Roast/Nuts: 1/10, Cream: 3/10, Mineral: 4/10.
                        </p>
                    </div>
                    <div id="14. 2022 Man Gang tea flowers (YS)" ref={manGangTeaFlowerRef}>
                        <h3>14. 2022 Man Gang tea flowers (YS)</h3>
                        <p>
                            A lovely old arbour white tea cake made from Autumn 2022 tea harvested from 80-300-year-old tea trees in Man Gang village
                            and then blended with tea flowers from the same garden.
                            <br/>
                            The dry leaves smelled like honey, orchids and dried peaches.
                            After a quick rinse, the tea started to smell like maple syrup and baked strawberries.
                            The tea was pale gold, medium thick, oily and smooth.
                            It tasted like brown sugar, tilia honey and orange marmalade.
                            The empty teacup smelled like rose hip jam and rum raisins.
                            The aftertaste was pleasant citrus rind, floral with long-lasting warmth.
                            With 5g leaves in a 100ml gaiwan, the sessions lasted for 11 brews on average.
                            <br/>
                            Green/Vegtal: 2/10, Floral: 7/10, Fruits: 10/10, Spices: 6/10, Wood/Earth: 1/10, Roast/Nuts: 1/10, Cream: 4/10, Mineral: 1/10.
                        </p>
                    </div>
                    <div id="15. 2018 Fuding Shou Mei (YS)" ref={fudingShouMeiRef}>
                        <h3>15. 2018 Fuding Shou Mei (YS)</h3>
                        <p>
                            An ultra-smooth Shou Mei white tea, aged for one year before pressing into a cake in 2019.
                            <br/>
                            From the looks, this tea is on the greener side of aged white teas both in colour and taste.
                            Good mix of buds and larger leaves.
                            The dry leaves smelled like half-dried hay, and the yarrow infusion was mixed with resin and honey.
                            The wet leaves smelled like honey-cinnamon baked cookies, dried apricots and molasses.
                            The tea soup was golden and medium thick.
                            It had a very smooth texture but a complex and calming flavour: honey, sugar cane juice, peaches
                            with a hint of East-European herbal infusion notes.
                            It had a juicy and sweet, long-lasting aftertaste.
                            <br/>
                            I brewed 5g of tea in a 100ml gaiwan with boiling water.
                            A session usually lasted for 8+ brews, and the leaves that were left could be used for an additional boiled brew.
                            <br/>
                            Green/Vegtal: 4/10, Floral: 3/10, Fruits: 3/10, Spices: 9/10, Wood/Earth: 2/10, Roast/Nuts: 1/10, Cream: 1/10, Mineral: 4/10.
                        </p>
                    </div>
                    <div id="16. Lao Bai Cha - Shou Mei (KTM)" ref={lbcsmKTMRef}>
                        <h3>16. Lao Bai Cha - Shou Mei (KTM)</h3>
                        <p>
                            Crafted from premium "Shou Mei" leaves harvested from the Fuding tea region of Fujian Province,
                            this white tea cake embodies the essence of traditional Chinese tea culture.
                            The leaves were picked in the spring of 2016, a prime season for tea harvesting, ensuring a rich depth of flavour.
                            The leaves were then carefully aged and pressed into cake form in 2020, allowing the tea to develop a well-rounded aged flavour profile.
                            <br/>
                            This is one of the cheapest aged white teas that I've purchased, so I started my tasting session with almost no expectations.

                            The cake was lightly pressed, which is always a good thing for me. The prying is less accident-prone.
                            Either way, the dry leaves smelled like grapes and wild berries.
                            The leaves had a rich autumnal colour of swamp green, auburn, ebony and silver.
                            The rehydrated leaves took up a uniform mud-brown colour and smelled like strawberry jam, mangosteen and pine nuts.
                            The brew was medium thick, syrup-like, and had a caramel, honey, and apricot taste.
                            The cup smelled like magnolia and jasmine after the brews.
                            The mouth feel was sweet and slightly bitter like pomelo rind.
                            <br/>
                            I brewed 5g of tea in a 100ml gaiwan with boiling water.
                            A session usually lasted for 5-6 brews, and by the end, the leaves lost all their power.
                            This would place the tea on the weaker side of white tea, but for the price of this tea, I can't complain.
                            <br/>
                            Green/Vegtal: 2/10, Floral: 3/10, Fruits: 7/10, Spices: 8/10, Wood/Earth: 0/10, Roast/Nuts: 0/10, Cream: 2/10, Mineral: 1/10.
                        </p>
                    </div>
                    <div id="17. XiaGuan Yue Guang Bai (KTM)" ref={xgygbKTMRef}>
                        <h3>17. XiaGuan Yue Guang Bai (KTM)</h3>
                        <p>
                            This tea is crafted from large tea buds, known as "Da Bai Ya", harvested in the Jinggu tea region.
                            The large buds are adorned with multiple silver tea hairs.
                            <br/>
                            This is a well-crafted factory moonlight white tea.
                            The dry leaves smelled like camphor, tomato purée mint with silver buds and cream/plum leaves.
                            The wet leaves turned into a mix of swamp green and smelled like honey, caramel and cinnamon.
                            The tea soup was citrine, moving into gold with a light and oily texture.
                            The brew had notes of maple syrup, allspice, camphor and pine resin.
                            It had a juicy and sweet, cooling and slightly minty aftertaste.
                            <br/>
                            I brewed 5g of tea in a 100ml gaiwan with boiling water.
                            A session usually lasted for eight brews.
                            <br/>
                            Green/Vegtal: 2/10, Floral: 0/10, Fruits: 4/10, Spices: 9/10, Wood/Earth: 1/10, Roast/Nuts: 0/10, Cream: 1/10, Mineral: 5/10.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WHITEHUB;
