import React from "react";
import "./project.css";
import {useNavigate} from 'react-router-dom';
import {Card} from "../../common";
import analog from "./assets/analog.png";
import micro from "./assets/micro.png";
import fpga from "./assets/fpga.png";
import mpu from "./assets/mpu.png";
import dsp from "./assets/dsp.png";
import misc from "./assets/misc.png";

const ProjectList =[
    {name:"Analog/Digital Electronics",
        description:"Electronics projects and tutorials with minimal no circuit programming involved. Only the good hard stuff.",
        link: "./analog-digital-tut", imgURL: analog},
    {name:"Microcontrollers",
        description:"Electronics projects with microcontrollers, detailed how-to guides for different MCU families.",
        link: "./micro-tut", imgURL: micro},
    {name:"Digital Signal Processing",
        description:"Special microcontroller projects and tutorials involving hardened DSP accelerators.",
        link: "./dsp-tut", imgURL: dsp},
    {name:"Field Programmable Gate Arrays",
        description:"Tutorials and projects using FPGAs from different vendors, some cheaper, some more expensive, most in Verilog HDL.",
        link: "./fpga-tut", imgURL: fpga},
    {name:"Embedded Systems with Linux",
        description:"Want to try your luck with multi-core architectures and embedded operating systems? Look no further.",
        link: "./emb-tut", imgURL: mpu},
    {name:"Misc Projects",
        description:"Here are any other projects that I could not or did not want to put in any of the categories shown above.",
        link: "./misc-tut", imgURL: misc}
]

function Projects(){

    const navigate = useNavigate();

    return (
        <div className="em__projects">
            <div className="em__projects-title">
                <h1 className="gradient__text">Choose your poison:</h1>
            </div>

            <div className="em__projects-list">
                    {
                        ProjectList.map((item) => (
                            <div >
                                <Card title={item.name} description={item.description}
                                      imgURL={item.imgURL} onClick={() => navigate(item.link)}/>
                            </div>
                        ))
                    }
            </div>
        </div>
    );
}

export default Projects;

