import React from "react";
import "./mcu_bm5.css";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";

import memory_matrix from "./memory_matrix.jpg";

const linker_region = `CCMRAM  (xrw)   : ORIGIN = 0x10000000,  LENGTH = 16K`;
const ccm_placement = `
_siccmram = LOADADDR(.ccmram);  
.ccmram :  
{    
    . = ALIGN(4);    
    _sccmram = .;        
    *(.ccmram)         
    *(.ccmram*)    
    . = ALIGN(4);    
    _eccmram = .;        
} >CCMRAM AT> FLASH
`;

const ccm_copy_asm = `
    ldr r0, =_sccmram  
    ldr r1, =_eccmram  
    ldr r2, =_siccmram  
    movs r3, #0  
    b  LoopCopyCcmInit 
CopyCcmInit:  
    ldr r4, [r2, r3]  
    str r4, [r0, r3]  
    adds r3, r3, #4 
LoopCopyCcmInit:  
    adds r4, r0, r3  
    cmp r4, r1  
    bcc CopyCcmInit
`;

const ccm_function = `
void TIM6_DAC_IRQHandler(void) __attribute__((section (".ccmram")));
`;

const ccm_dump_list = `
.PHONY: dump
dump:
    $(OD) -h -S $(TARGET).elf > $(TARGET).list
`;

const listing = `
Sections:
Idx Name          Size      VMA       LMA       File off  Algn
  0 .isr_vector   000001d8  08000000  08000000  00010000  2**0
                  CONTENTS, ALLOC, LOAD, READONLY, DATA
  1 .ccmram       00000040  00000000  080001d8  00020000  2**3
                  CONTENTS, ALLOC, LOAD, READONLY, CODE
  2 .text         00001600  08000218  08000218  00020218  2**2
                  CONTENTS, ALLOC, LOAD, READONLY, CODE
  3 .rodata       00000000  08001818  08001818  00030004  2**0
                  CONTENTS, ALLOC, LOAD, DATA
  4 .ARM.extab    00000000  08001818  08001818  00030004  2**0
                  CONTENTS
  5 .ARM          00000000  08001818  08001818  00030004  2**0
                  CONTENTS
  6 .preinit_array 00000000  08001818  08001818  00030004  2**0
                  CONTENTS, ALLOC, LOAD, DATA
  7 .init_array   00000004  08001818  08001818  00021818  2**2
                  CONTENTS, ALLOC, LOAD, DATA
  8 .fini_array   00000004  0800181c  0800181c  0002181c  2**2
                  CONTENTS, ALLOC, LOAD, DATA
  9 .data         00000004  20000000  08001820  00030000  2**2
                  CONTENTS, ALLOC, LOAD, DATA
 10 .bss          0000001c  20000004  08001824  00030004  2**2
                  ALLOC
 11 ._user_heap_stack 00000600  20000020  08001824  00030020  2**0
                  ALLOC
 12 .ARM.attributes 00000030  00000000  00000000  00030004  2**0
                  CONTENTS, READONLY
 13 .debug_info   00006295  00000000  00000000  00030034  2**0
                  CONTENTS, READONLY, DEBUGGING, OCTETS
 14 .debug_abbrev 00001181  00000000  00000000  000362c9  2**0
                  CONTENTS, READONLY, DEBUGGING, OCTETS
 15 .debug_aranges 000005e0  00000000  00000000  00037450  2**3
                  CONTENTS, READONLY, DEBUGGING, OCTETS
 16 .debug_ranges 00000548  00000000  00000000  00037a30  2**3
                  CONTENTS, READONLY, DEBUGGING, OCTETS
 17 .debug_macro  00018cdb  00000000  00000000  00037f78  2**0
                  CONTENTS, READONLY, DEBUGGING, OCTETS
 18 .debug_line   0000493d  00000000  00000000  00050c53  2**0
                  CONTENTS, READONLY, DEBUGGING, OCTETS
 19 .debug_str    0008c060  00000000  00000000  00055590  2**0
                  CONTENTS, READONLY, DEBUGGING, OCTETS
 20 .comment      00000050  00000000  00000000  000e15f0  2**0
                  CONTENTS, READONLY
 21 .debug_frame  00001750  00000000  00000000  000e1640  2**2
                  CONTENTS, READONLY, DEBUGGING, OCTETS

Disassembly of section .ccmram:

00000000 \< TIM6_DAC_IRQHandler \>:
`;

function MCU_BM5(){
   return(
       <div className="em__post">
           <div className="em__post-title">
               <h1>Functions in CCM region</h1>
           </div>
           <div className="em__post-section">
               <h3>Aim of this tutorial:</h3>
               <p>
                   This is a shorter tutorial in which we will relocate some of our functions inside the
                   MCU for better performance.
                   We will take a look at the Core Coupled Memory (CCM) SRAM, modify the linker and boot assembly.
                   This way in C/C++ we can state the place of a function using simple attributes.
               </p>
           </div>
           <div className="em__post-section">
               <h3>The CCM SRAM:</h3>
               <p>
                   The CCM SRAM is tightly coupled to the ARM core to execute code at the maximum system clock frequency without any wait-state penalty.
                   This region is usually used for real-time and computation intensive routines like control loops and digital signal processing tasks.
               </p>
               <img className="img_memory_matrix_bm5" src={memory_matrix} alt="memory_matrix"/>
               <p>
                   When the code is located in CCM SRAM and the data is stored in regular SRAM, the M4 core is in the optimum Harvard mode.
                   A dedicated zero-wait-state memory is connected to the instruction and data bus that can perform at 1.25 DMIPS/MHz,
                   witch a deterministic performance of 213 DMIPS in STM32G4.
               </p>
               <p>
                   The STM32G491 has 16kB of CCM SRAM mapped at the address 0x10000000 that can be aliased at 0x20005800.
                   It has parity check, write and read protection, run-time erase feature.
                   The CCM SRAM can be accessed using DMA if mapped at 0x20005800.
               </p>
           </div>

           <div className="em__post-section">
               <h3>Code execution from CCM SRAM:</h3>
               <p>
                   The aim of this tutorial is to execute code from a memory region, not DMA transfer,
                   so we can map the CCM SRAM at the address 0x10000000.
                   As a matter of fact, this region is already mapped inside the linker, but we did not use it until now:
               </p>
               <SyntaxHighlighter language="makefile" style={AtomOneDark}>
                   {linker_region}
               </SyntaxHighlighter>
               <p>
                   Next, we can instruct the linker that functions with <i> ccmram </i> attribute must be placed in CCM SRAM.
                   The following instructions must be placed before the <i> .text</i> region.
               </p>
               <SyntaxHighlighter language="makefile" style={AtomOneDark}>
                   {ccm_placement}
               </SyntaxHighlighter>
               <p>
                   We have our memory region, we need to populate it in the core assembly.
               </p>
               <SyntaxHighlighter language="armasm" style={AtomOneDark}>
                   {ccm_copy_asm}
               </SyntaxHighlighter>
               <p>
                   Here we can use the start and end sections defined by the linker, then just as with FLASH to SRAM,
                   we can copy the data from FLASH to CCM SRAM using a loop.
                   Place this code segment before the <i>bss</i> segment.
               </p>
               <p>
                   Finally, let's say that we have a timer interrupt that we would want to place in the CCM region.
                   We can simply place an attribute after the function header like:
               </p>
               <SyntaxHighlighter language="c" style={AtomOneDark}>
                   {ccm_function}
               </SyntaxHighlighter>
               <p>
                   In my case, I only wrote a LED toggling inside the ISR, where the execution time difference is insignificant.
                   So, how can we make sure that our function is indeed in the CCM SRAM region?
                   The <i>make</i> instruction created the elf file and we can use that, to create a disassembly listing:
               </p>
               <SyntaxHighlighter language="makefile" style={AtomOneDark}>
                   {ccm_dump_list}
               </SyntaxHighlighter>
               <p>
                   Here the <i>OD</i> stands for arm-none-eabi-objdump, and the listing should contain the following snippet:
               </p>
               <SyntaxHighlighter language="text" style={AtomOneDark}>
                   {listing}
               </SyntaxHighlighter>
               <p>
                   Firstly, after the isr vector table, there is the ccmram section.
                   Secondly, the interrupt handler is contained in it.
               </p>
           </div>


           <div className="em__post-navigation">

               <NavLink to="./../stm-bm-4">
                   <Button btnID={"leftBTN"} buttonSize="btn--medium"> Previous Post</Button>
               </NavLink>

               <NavLink to="./..">
                   <Button btnID={"rightBTN"} buttonSize="btn--medium"> Projects</Button>
               </NavLink>
           </div>

       </div>
   );
}

export default MCU_BM5;