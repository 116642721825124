import React from "react";
import "./mcu_hal36.css";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";
import stm_memory from "../p_mcu_hal_29/stm_memory.png";

import stm32wb_schem from "./stm32wb_schem.png"
import ble_stack from "./ble_stack.jpg"
import ipcc_hsem from "./ipcc_hsem.jpg"
import rtc_wake from "./rtc_wake.jpg"
import ble_adv from "./ble_adv.jpg"
import ble_app_serv from "./ble_app_serv.jpg"
import ble_gatt from "./ble_gatt.jpg"
import ble_service_1 from "./ble_service_1.jpg"
import ble_service_2 from "./ble_service_2.jpg"

const main_source_1 = `
volatile uint8_t counter = 0;

void HAL_TIM_PeriodElapsedCallback(TIM_HandleTypeDef *htim){
    counter++;
}
`;

const custom_app_fun = `
extern volatile uint8_t timer_flag, counter;

void Custom_Mycharnotif_Send_Notification(void) /* Property Read */
{
  uint8_t updateflag = 0;

  /* USER CODE BEGIN Mycharnotif_UC_1*/
    updateflag = 1;
    UpdateCharData[0] = counter;
  /* USER CODE END Mycharnotif_UC_1*/

  if (updateflag != 0)
  {
    Custom_STM_App_Update_Char(CUSTOM_STM_MYCHARNOTIF, (uint8_t *)UpdateCharData);
  }

  /* USER CODE BEGIN Mycharnotif_UC_Last*/

  /* USER CODE END Mycharnotif_UC_Last*/
  return;
}
`;
function MCU_HAL36(){
    return(
        <div className="em__post">
            <div className="em__post-title">
                <h1>STM32WB BT Setup</h1>
            </div>

            <div className="em__post-section">
                <h3>Aim of this tutorial:</h3>
                <p>
                    This tutorial aims to create a base project using the STM32WB15 dual-core Bluetooth SoC that can pair with a smartphone.
                    We will program a blank SoC with the required radio firmware and receive and transmit data using the STs application.
                </p>
            </div>

            <div className="em__post-section">
                <h3>About the STM32WB:</h3>
                <p>
                    The STM32WB15 is an Ultra-low-power dual-core ARM SoC. It contains a Cortex-M4 core that runs at 64MHz -
                    this is the main microcontroller that will handle all the local tasks.
                    It also contains a Cortex-M0+ core running at 32MHz that is directly connected to the on-chip Bluetooth LE 5.3 radio interface.
                </p>

                <Popup trigger={<img className="img_stm32wb_schem_hal36 clickable_img" src={stm32wb_schem} alt="stm32wb_schem_hal36"/>} modal nested>
                    {
                        close =>(
                            <img className="em__img_full img_bg_hal36" src={stm32wb_schem} alt="stm32wb_schem_hal36"/>
                        )
                    }
                </Popup>

                <p>
                    This time, I don't own a Nucleo board.
                    I designed my own BT board with an antenna and filter for a remote humidity and temperature monitoring system.
                    The aim is not to go into the hardware intricacies (there were some peculiarities) but to configure an existing system.
                    The M0+ core would have been pre-programmed with the BLE stack if this was a Nucleo board.
                    Since I ordered fresh chips, I also needed to program that core.
                    <br/>
                    I will write a separate tutorial about the design and programming of the remote monitoring system in the future.
                    <br/>
                    So, we have a blank IC. We need a way to program it. We could use the USB DTF functionality if it would have a USB connection.
                    This IC does not implement the USB stack; I only added a COM port.
                    The other (proper) option is the SWD/JTAG programming, which requires an ST-Link programmer - this came pre-installed on the other dev board.

                    So, if you are using a new board, get a programmer (or Nucleo board), connect wires to the SWD port and follow along.
                </p>
            </div>

            <div className="em__post-section">
                <h3>FUS and BLE stack:</h3>
                <p>
                    There are prebuilt coprocessor binaries for the MC0+ that we need to upload.
                    Refer to <i>AN5270</i> application note that shows the application command interface and host command interface supported by the BLE stack.
                    ST created six binaries:
                    <ul>
                        <li><i>stm32wbxx_BLE_Stack_full_extended_fw.bin</i> - to be used for adverting and scanning, GAP Central or Peripheral and GATT Server or Client, supports up to 8 links</li>
                        <li><i>stm32wbxx_BLE_Stack_full_fw.bin</i> - to be used for GAP Central or Peripheral and GATT Server or Client, supports up to 8 links</li>
                        <li><i>stm32wbxx_BLE_Stack_light_fw.bin</i> - to be used for GAP Peripheral and GATT Server, supports up to 4 links as slave</li>
                        <li><i>stm32wbxx_BLE_HCILayer_fw.bin</i> - to be used for BLE Host Stack running on CM4 application processor</li>
                        <li><i>stm32wbxx_BLE_HCILayer_extended_fw.bin</i> - to be used for BLE Host Stack running on CM4 application processor</li>
                        <li><i>stm32wbxx_BLE_HCI_AdvScan_fw.bin</i> - to be used for advertising and scanning through HCI interface</li>
                    </ul>
                    There are also Zigbee stacks, but we will use the simple BLE stack.
                    Go to <a href="https://github.com/STMicroelectronics/STM32CubeWB/tree/master/Projects/STM32WB_Copro_Wireless_Binaries/STM32WB1x">this</a> GitHub repo, and download the
                    <i>BLE_Stack_full</i>, and the <i>FUS_fw</i> binaries.
                    Alternatively, these binaries are also present inside the STM32Cube local repository: C:\Users\your user name\STM32Cube\Repository\STM32Cube_FW_WB_Vx.xx.x\Projects\Corpo_Wireless_Binaries.
                </p>
                <Popup trigger={<img className="img_ble_stack_hal36 clickable_img" src={ble_stack} alt="ble_stack_hal36"/>} modal nested>
                    {
                        close =>(
                            <img className="em__img_full" src={ble_stack} alt="ble_stack_hal36"/>
                        )
                    }
                </Popup>
                <p>
                    Open <i>STM32CubeProgrammer</i> and connect to the STM32WB15 MCU.
                    Go to firmware upgrade services (FUS), and press Start FUS followed by Read FUS info.
                    Then, if the FUS is anything less than 1.1.1.0, you need to add the FUS binary path with the starting address specified inside the release note.
                    In my case, I got a new firmware, and neither the FUS nor the safe boot needed an upgrade.
                    <br/>
                    Add the file path and the new start address for the stack, which is 0x08021000 for the STM32WB1x.
                    Upload the binary, restart the FUS, and re-read the info to check if something is bricked.
                    <b>Warning:</b> Make sure that the FUS/Safeboot versions are from the same release as the BLE binaries; otherwise, the stack won't load properly.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Project setup:</h3>
                <p>
                    With the CoProcessor binary flashed, we can target the main CM4 processor.
                    Create a new project inside STM32CubeIDE and configure your clock - I added a 32MHz external crystal,
                    and I set the CM4 clock frequency to 64MHz.
                    Enable the LSE crystal - this is necessary for the low-energy mode of the BLE. Of course,
                    <a href="https://community.st.com/t5/stm32-mcus/configuring-stm32wb-for-bluetooth-le-without-an-lse-crystal/ta-p/49465">there is</a> a
                    configuration to achieve the same results without this cristal.
                    I have an LED connected to PE4, so I added that as low-speed output and enabled the SWD interface.
                    Enable the UART peripheral for communications with the PC.
                    Enable TIM2 and set it up for a 2s interrupt.
                    Enable the hardware semaphores (HSEM) required for the dual-core synchronisation.
                    Enable the inter-processor communication controller (IPCC) for shared memory data communication.
                </p>
                <Popup trigger={<img className="img_ipcc_hsem_hal36 clickable_img" src={ipcc_hsem} alt="ipcc_hsem_hal36"/>} modal nested>
                    {
                        close =>(
                            <img className="em__img_full" src={ipcc_hsem} alt="ipcc_hsem_hal36"/>
                        )
                    }
                </Popup>
                <p>
                    Then enable the RTC from the Timers and ensure it uses the 32.768kHz external crystal.
                    Use the Internal WakeUp functionality and the RTC wake-up interrupt.
                </p>
                <Popup trigger={<img className="img_rtc_wake_hal36 clickable_img" src={rtc_wake} alt="rtc_wake_hal36"/>} modal nested>
                    {
                        close =>(
                            <img className="em__img_full" src={rtc_wake} alt="rtc_wake_hal36"/>
                        )
                    }
                </Popup>
                <p>
                    Finally, enable the RF1 radio system to use the <i>WPAN</i> middleware.
                    For this device, the Thread and Zigbee options are unavailable, so we have an easy time to choose.
                    Inside the BLE application and services, make sure that you are using the full stack (or the light, if you uploaded the light stack to the CM0+),
                    disable the P2P server and enable the Custom Template.
                </p>
                <Popup trigger={<img className="img_ble_app_serv_hal36 clickable_img" src={ble_app_serv} alt="ble_app_serv_hal36"/>} modal nested>
                    {
                        close =>(
                            <img className="em__img_full" src={ble_app_serv} alt="ble_app_serv_hal36"/>
                        )
                    }
                </Popup>
                <p>
                    In the BLE Advertising window, include a complete local name,
                    and add a preferred name for your device - this will appear when the phone searches for nearby BT devices.
                </p>
                <Popup trigger={<img className="img_ble_adv_hal36 clickable_img" src={ble_adv} alt="ble_adv_hal36"/>} modal nested>
                    {
                        close =>(
                            <img className="em__img_full" src={ble_adv} alt="ble_adv_hal36"/>
                        )
                    }
                </Popup>
                <p>
                    Add a single service and name it inside the BLE GATT window - a new window will appear.
                    This window contains all the services that we want to implement.
                </p>
                <img className="img_ble_gatt_hal36" src={ble_gatt} alt="ble_gatt_hal36"/>
                <p>
                    Here, add 2 services. We will use one to send data from the phone, which will toggle our LED.
                    The other service will send notifications from the device to the phone.
                    I named the first one <i>myCharWrite</i>, and the configs are:
                </p>
                <Popup trigger={<img className="img_ble_service_1_hal36 clickable_img" src={ble_service_1} alt="ble_service_1_hal36"/>} modal nested>
                    {
                        close =>(
                            <img className="em__img_full" src={ble_service_1} alt="ble_service_1_hal36"/>
                        )
                    }
                </Popup>
                <p>
                    I named the second service <i>myCharNotif</i> - I have a good imagination. The properties are:
                </p>
                <Popup trigger={<img className="img_ble_service_2_hal36 clickable_img" src={ble_service_2} alt="ble_service_2_hal36"/>} modal nested>
                    {
                        close =>(
                            <img className="em__img_full" src={ble_service_2} alt="ble_service_2_hal36"/>
                        )
                    }
                </Popup>
                <p>
                    Generate the project and build it.
                    Without optimisations enabled, this "blank" project occupies 32% of FLASH (110 KB),
                    21% of RAM (9.52 KB) and 26% of the shared RAM (10 KB).
                    So... yeah. There is a price to pay for ease of configuration and hardware abstraction.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Firmware:</h3>

                <p>
                    Optimally, one would want the software layers to be as separated as possible for maintainability.
                    This time, though, we will write code in the main source file and the WPAN source for ease of implementation.
                </p>
                <p>
                    In the main source, create a volatile counter and configure the TIM interrupt callback.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {main_source_1}
                </SyntaxHighlighter>
                <p>
                    In the super loop, every time the counter reaches 10 call <i>Custom_Mycharnotif_Send_Notification()</i> function,
                    and zero the counter.
                </p>
                <p>
                    Don't forget to start the TIM using the function <i>HAL_TIM_Base_Start_IT(&htim2)</i>.
                    Now go to SMT32_WPAN/App/custom_stm.c, and include <i>main.h</i> so that we can use our defined peripherals.
                    Around line 192, we have the Service 1 GATT attribute, so if we toggle the LED here, it can be controlled by writing to this attribute.
                </p>
                <p>
                    Now, inside the <i>custom_app.c</i> we will define the notification procedure.
                    Search for the <i>Send_Notification</i> function.
                    Inside this function is an update flag, and when this flag is 1, the characteristic update notification is sent.
                </p>

                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {custom_app_fun}
                </SyntaxHighlighter>
                <p>
                    We declare the variables inside the main source so the compiler can see them as extern.
                </p>
                <p>
                    Upload the firmware.
                    On the Android side, download the STM BLE Toolbox app.
                    Turn on the scan, and you should see the board name on the list.
                    Connect to it.
                    Some services should appear.
                    We are interested in the last service, which has a long, code-like name.
                    Inside the details are two characteristics, the first of which is writable.
                    If you write a(ny) character, the LED should trigger.
                    The second characteristic contains the counter value, and if you enable the notification, a new value should appear every 20 seconds.
                </p>
                <p>
                    So, we created a rudimentary BLE firmware. This is way below a finished product, but it's A starting point.
                    You can search my specific MCU projects to see how to design a humidity and temperature logger from scratch in both hardware and software.
                </p>

            </div>




            <div className="em__post-navigation">

                <NavLink to="./../stm-hal-35">
                    <Button btnID={"leftBTN"} buttonSize="btn--medium"> Previous Post</Button>
                </NavLink>

                <NavLink to="./../stm-hal-37">
                    <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
                </NavLink>
            </div>

        </div>
    );
}

export default MCU_HAL36;