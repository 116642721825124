import React from "react";
import "./../posts.css";
import "./fpga_5.css"
import coded_fsm from "./coded_fsm.png";
import digital_signal from "./digital_signal.png";
import fsm from "./fsm.png";
import gate_level from "./gate_level.jpg";
import graph_fsm from "./graph_fsm.png";
import hierarchy from "./hierarchy.jpg";
import schema_block from "./schema_block.png";
import table from "./table.png";
import wave from "./wave.jpg";

import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";
import Popup from "reactjs-popup";

const codedFilter = `
module codedFilter(in, out, clk, rst);
    input in, clk, rst;
    output out;
    
    reg[1:0] state_reg, next_state_reg;
    
    always @(posedge clk)
        if(rst == 1'b1)
            state_reg <= 2'b00;
        else
            state_reg <= next_state_reg;
            
    always @(*) begin
        next_state_reg[0] <= in;
        next_state_reg[1] <= (state_reg[0] & state_reg[1]) | (in &(state_reg[0] | state_reg[1]));
    end
    
    assign out = state_reg[1];
endmodule`;

const oneHotFilter = `
module oneHotFilter(in, out, clk, rst);
    input in, clk, rst;
    output reg out;
    
    parameter S_A = 4'b0001, S_B = 4'b0010;
    parameter S_C = 4'b0100, S_D = 4'b1000;
    
    reg [3:0] state;
    
    always @(posedge clk)
        if(rst == 1'b1)
            state <= S_A;
        else
            case(state)
                S_A: 
                    if(in == 1'b1) 
                        state <= S_B;
                S_B: 
                    if(in == 1'b1)
                        state <= S_C;
                    else
                        state <= S_A;
                S_C:
                    if(in == 1'b0)
                        state <= S_D;
                S_D:
                    if(in == 1'b1)
                        state <= S_C;
                    else
                        state <= S_A;
            endcase
            
    always @(*)
        case(state)
            S_A: out = 1'b0;
            S_B: out = 1'b0;
            S_C: out = 1'b1;
            S_D: out = 1'b1;
        endcase     
endmodule
`;

function FPGA_5(){
    return(
        <div className="em__post">
            <div className="em__post-title">
                <h1>Simple Finite State Machine (FSM)</h1>
            </div>
            <div className="em__post-section">
                <h3>Aim of this post:</h3>
                <p>
                    In this tutorial we will learn the basic steps of creating a FSM with the help of a practical example.
                </p>
            </div>
            <div className="em__post-section">
                <h3>Prerequisites:</h3>
                <p>
                    We will use the hardware and software mentioned in the previous sections.
                    A little knowledge of digital electronics will be necessary for the implementation
                    - logic gates and Karnaugh-maps.
                </p>
            </div>
            <div className="em__post-section">
                <h3>The noise pulse eliminator</h3>
                <p>
                    The noise pulse eliminator is a digital circuit
                    which eliminates signal pulses which last for one clock period only.
                    The behaviour of such a device is shown below:
                </p>
                <img className="img_digital_signal clickable_img" src={digital_signal} alt="digital_signal"/>
            </div>
            <div className="em__post-section">
                <h3>Finite State Machines</h3>
                <p>
                    An FSM or automaton is an abstract machine, which can be in exactly one state from a set of a finite number of states.
                    The state transition occurs as a response to external inputs.
                    The image on the right shows a generic FSM. The Z blocks are registers with the SAME clock signal.

                    The dashed boxes are optional. We can connect inputs directly to the CL, only if they are synchronised to the system clock signal.
                    Otherwise, we must connect the inputs to a register.
                    The CL creates the next state based on the current state and the inputs.
                </p>
                <img className="img_schema_block" src={schema_block} alt="schema_block"/>
                <p>
                    Mealey FSM outputs depend on the current state and inputs,
                    so they are prone to glitches - the output can change in the middle of a clock cycle.
                    Moore FMS outputs depend only on the current state;
                    they don't change in the middle of a cycle - this is done with the help of an output register,
                    which introduces a one clock delay.
                </p>
                <p>
                    We design FSMs starting with a state machine diagram. We name the states with letters a,b,...
                    We are in a, when the input is zero. We wait for one, and we transit to b.
                    This could be a noise pulse, so we wait for one cycle, for the input to stay one.
                    If it is true, then we transit to state c. If the input goes back to zero, we go back to state a.
                </p>
                <p>
                    Similarly, in the case of state c, we detect an input of true 1 or noise pulse 0.
                    This means overall we have four states, and the FSM diagram looks like:
                </p>
                <img className="img_fsm" src={fsm} alt="fsm"/>
                <img className="img_table" src={table} alt="table"/>
                <p>
                    The next step is to encode the state names.
                    In our case, 2 bits are enough. a=00, b = 01, c = 11, d = 10.
                    We use the Gray code instead of normal binary numbers because this will ease our combination logic.
                    The truth table:
                </p>

                <p>
                    We can find the next state and the output logic with the help of the Karnaugh table. The results are:
                    NS1 = (S0&S1) | In&(S0 | S1),
                    NS0 = In
                    Out = S1
                </p>
            </div>
            <div className="em__post-section">
                <p>
                    We can write the filter logic based on the calculated logic functions.
                </p>
                <SyntaxHighlighter language="verilog" style={AtomOneDark}>
                    {codedFilter}
                </SyntaxHighlighter>
                <p>
                    This is the filter logic. We have two registers for the current and the next states,
                    and we update the states synchronously to a clock signal rising edge - even the reset is synchronous.
                    The next always block fires on any change of every used signal,
                    and only contains the logic expression for the next state.
                </p>
                <p>
                    If we synthesize the project, we can check the implemented hardware:
                </p>
                <div>
                    <img className="img_coded_fsm" src={coded_fsm} alt="coded_fsm"/>
                    <img className="img_graph_fsm" src={graph_fsm} alt="graph_fsm"/>
                </div>
            </div>
            <div className="em__post-section">
                <p>
                    The implemented design recognises the state machine and the output logic.
                </p>
            </div>
            <div className="em__post-section">
                <h3>One hot encoding</h3>
                <p>
                    We saw that the states could be represented by 2 bit wide registers,
                    but this representation requires a state logic implementation.
                    The one hot encoding is a solution to this problem.
                    We name the states a = 0001, b = 0010, c = 0100, d = 1000. We can see that we use 4bit wide register
                </p>
                <SyntaxHighlighter language="verilog" style={AtomOneDark}>
                    {oneHotFilter}
                </SyntaxHighlighter>
                <p>
                    Here we have the one hot implementation of the same logic.
                    We used a registered output, which only depends on the states.
                    We named the state parameters for writing convenience.
                    We can write the state transitions in this configuration in a case statement with if conditionals.
                </p>
                <p>
                    If we synthesise the logic we get the hierarchical schematic:
                </p>
                <Popup trigger={<img className="img_hierarcy em__highlight" src={hierarchy} alt="hierarchy"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={hierarchy} alt="hierarchy"/>
                    )}
                </Popup>
                <p>
                    The state machine (surprise surprise) is the same as before.
                    The gate level design is shown below.
                </p>
                <Popup trigger={<img className="img_gate_level em__highlight" src={gate_level} alt="gate_level"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={gate_level} alt="gate_level"/>
                    )}
                </Popup>

                <p>
                    The simulation confirms that our design indeed works as intended.
                    Two things to note: We can see that the output is delayed with one clock cycle compared to our desired output
                    - this is because we used a registered output Moore state machine.
                    If we take a look at the state block above, we can see that the reset is handled as an input,
                    and the actual reset pin is floating - this is because we used an active high reset,
                    while the FPGA fabric uses active low resets, so our reset is incorporated in the logic.
                </p>
                <Popup trigger={<img className="img_wave em__highlight" src={wave} alt="wave"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={wave} alt="wave"/>
                    )}
                </Popup>

            </div>

            <div className="em__post-navigation">

                <NavLink to="./../fpga-tut-4">
                    <Button btnID={"leftBTN"} buttonSize="btn--medium"> Previous Post</Button>
                </NavLink>

                <NavLink to="./../fpga-tut-6">
                    <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
                </NavLink>
            </div>
        </div>
    );
}

export default FPGA_5;