import React from "react";
import "./mcu_hal28.css";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";

import dev_usb_mx from "./dev_usb_mx.jpg";
import dev_usb_internal_build from "./dev_usb_internal_build.jpg";
import dev_usb_format from "./dev_usb_internal_format.jpg";
import dev_usb_sdcard_mx from "./dev_usb_sdcard_build.jpg";
import host_usb_mx from "./host_usb_mx.jpg";

const storage_mem_defs_dev = `
#define STORAGE_LUN_NBR                  1
#define STORAGE_BLK_NBR                  0xC6
#define STORAGE_BLK_SIZ                  0x200
`;

const internal_storage_dev = `
uint8_t internal_ram_storage[STORAGE_BLK_NBR*STORAGE_BLK_SIZ];
`;

const internal_read_dev = `
int8_t STORAGE_Read_FS(uint8_t lun, uint8_t *buf, uint32_t blk_addr, uint16_t blk_len){
  /* USER CODE BEGIN 6 */
  UNUSED(lun);
  memcpy(buf, &internal_ram_storage[blk_addr*STORAGE_BLK_SIZ], blk_len*STORAGE_BLK_SIZ);
  return (USBD_OK);
  /* USER CODE END 6 */
}`;

const internal_write_dev = `
int8_t STORAGE_Write_FS(uint8_t lun, uint8_t *buf, uint32_t blk_addr, uint16_t blk_len){
  /* USER CODE BEGIN 7 */
  UNUSED(lun);
  memcpy(&internal_ram_storage[blk_addr*STORAGE_BLK_SIZ], buf, blk_len*STORAGE_BLK_SIZ);
  return (USBD_OK);
  /* USER CODE END 7 */
}`;

const sdio_capacity_dev = `
int8_t STORAGE_GetCapacity_FS(uint8_t lun, uint32_t *block_num, uint16_t *block_size){
  /* USER CODE BEGIN 3 */
  UNUSED(lun);
#ifndef USE_INTERNAL_RAM
  HAL_SD_CardInfoTypeDef info;
  HAL_StatusTypeDef status;

  status = HAL_SD_GetCardInfo(&hsd, &info);
  *block_num = info.LogBlockNbr - 1;
  *block_size = info.LogBlockSize;
  return status;
#else
  *block_num  = STORAGE_BLK_NBR;
  *block_size = STORAGE_BLK_SIZ;
  return (USBD_OK);
#endif
  /* USER CODE END 3 */
}`;

const sdio_read_dev = `
int8_t STORAGE_Read_FS(uint8_t lun, uint8_t *buf, uint32_t blk_addr, uint16_t blk_len)
{
  /* USER CODE BEGIN 6 */
  UNUSED(lun);
#ifdef USE_INTERNAL_RAM
  memcpy(buf, &internal_ram_storage[blk_addr*STORAGE_BLK_SIZ], blk_len*STORAGE_BLK_SIZ);
  return (USBD_OK);
#else
  HAL_StatusTypeDef status;

  status = HAL_SD_ReadBlocks(&hsd, buf, blk_addr, blk_len, HAL_MAX_DELAY);
  while(HAL_SD_GetCardState(&hsd) != HAL_SD_CARD_TRANSFER);
  return status;
#endif
  /* USER CODE END 6 */
}`;

const sdio_write_dev = `
int8_t STORAGE_Write_FS(uint8_t lun, uint8_t *buf, uint32_t blk_addr, uint16_t blk_len){
  /* USER CODE BEGIN 7 */
  UNUSED(lun);
#ifdef USE_INTERNAL_RAM
  memcpy(&internal_ram_storage[blk_addr*STORAGE_BLK_SIZ], buf, blk_len*STORAGE_BLK_SIZ);
  return (USBD_OK);
#else
  HAL_StatusTypeDef status;

  status = HAL_SD_WriteBlocks(&hsd, buf, blk_addr, blk_len, HAL_MAX_DELAY);
  while(HAL_SD_GetCardState(&hsd) != HAL_SD_CARD_TRANSFER);
  return status;
#endif

  /* USER CODE END 7 */
}`;

const usb_host_disconnect = `
case HOST_USER_DISCONNECTION:
    Appli_state = APPLICATION_DISCONNECT;
    unmountUSB();
  break;
`;

const usb_host_active = `
case HOST_USER_CLASS_ACTIVE:
    Appli_state = APPLICATION_READY;
    mountUSB();
    checkUSBdetails();
    scanUSB("/");
    createFile("/sample1.txt");
    writeFile("/sample1.txt", "This data should be in the root directory.\\r\\n");

    createDir("/Folder1");
    createFile("/Folder1/f1_file1.txt");
    writeFile("/Folder1/f1_file1.txt", "This data should be in Folder1 file.\\r\\n");

    createDir("/Folder2");
    createDir("/Folder2/Folder3");
    createFile("/Folder2/Folder3/f2_f3_file2.txt");
    writeFile("/Folder2/Folder3/f2_f3_file2.txt", "This data should be in Folder2/Folder3 file\\r\\n Have a nice day UwU.\\r\\n");

    updateFile("/sample1.txt", "This updated data must be in second line of sample1.txt\\r\\n");
  break;
`;

const usb_text_dump = `
HW setup completed.
USB drive mounted successfully.
USB  Total Size: 7845888
USB Free Space: 7845844
File /ROOTFILE.txt
Dir DIR1
File /DIR1/DIR1FILE.txt
Dir DIR2
Dir SUBDIR1
File /DIR2/SUBDIR1/DIR2FILE.txt
File /sample.txt
/sample1.txt was created.
File /sample1.txt was closed successfully.
Opening file /sample1.txt to write data into it.
Write operation successful, closed file /sample1.txt.
/Folder1 was updated successfully.
/Folder1/f1_file1.txt was created.
File /Folder1/f1_file1.txt was closed successfully.
Opening file /Folder1/f1_file1.txt to write data into it.
Write operation successful, closed file /Folder1/f1_file1.txt.
/Folder2 was updated successfully.
/Folder2/Folder3 was updated successfully.
/Folder2/Folder3/f2_f3_file2.txt was created.
File /Folder2/Folder3/f2_f3_file2.txt was closed successfully.
Opening file /Folder2/Folder3/f2_f3_file2.txt to write data into it.
Write operation successful, closed file /Folder2/Folder3/f2_f3_file2.txt.
Opening file /sample1.txt to update the data.
/sample1.txt updated successfully.
File /sample1.txt was closed successfully.

`;

function MCU_HAL28(){
    return(
        <div className="em__post">
            <div className="em__post-title">
                <h1>USB mass storage class</h1>
            </div>

            <div className="em__post-section">
                <h3>Aim of this tutorial:</h3>
                <p>
                    In this tutorial, we will cover the mass storage class (MSC) usage of the STM32F4.
                    There will be two parts to this tutorial.
                    In the first part, we will enumerate the MCU as a mass storage class (MSC) device using its internal RAM,
                    then we will add an external flash using SDIO interface to reduce the RAM usage.
                    In the second part, we will configure the MCU as an MSC host so that we can attach USB flash drives to it.
                </p>
            </div>

            <div className="em__post-section">
                <h3>MSC device with internal RAM - setup:</h3>
                <p>
                    In this tutorial, I'll be using the NUCLEO-F411RE board since the G variant does not have SDIO peripheral and USB host capability.
                    First, create a new project with bypassing the high-speed clock and debug interface enabled.
                    In communication, enable the USB peripheral as device only.
                    Finally, go to middleware and select mass storage class.
                </p>
                <Popup trigger={<img className="img_dev_usb_mx_hal28 clickable_img" src={dev_usb_mx} alt="dev_usb_mx_hal28"/>} modal nested>
                    {
                        close =>(
                            <img className="em__img_full" src={dev_usb_mx} alt="dev_usb_mx_hal28"/>
                        )
                    }
                </Popup>

                <p>
                    Generate and build the project.
                    We can see from the build messages (or the linker script) that the STM32F411 has 128kB of RAM.
                    To be on the safer side, we will use 99kB for storage.
                </p>

            </div>

            <div className="em__post-section">
                <h3>MSC device with internal RAM - firmware:</h3>
                <p>
                    Go to <i>usbd_storage_if.c</i> in USB_DEVICE/App.
                    This is the MSC interface, where we must write our memory definitions and memory management functions.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {storage_mem_defs_dev}
                </SyntaxHighlighter>
                <p>
                    We want a 99kB usable storage space. If we multiply the number by two, we get 198, which is 0xC6.
                    The block size can be left as 0x200 or 512kB.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {internal_storage_dev}
                </SyntaxHighlighter>
                <p>
                    We need a global variable. This will be the memory buffer.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {internal_read_dev}
                </SyntaxHighlighter>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {internal_write_dev}
                </SyntaxHighlighter>
                <p>
                    We will use memcpy to save the data to/from our previously defined buffer.
                    Build the project, and check all the space that is wasted as storage.
                </p>
                <img className="img_dev_usb_internal_build_hal28" src={dev_usb_internal_build} alt="dev_usb_internal_build_hal28"/>
                <p>
                    Now connect the sub device port to the PC, and a new volume with Formatting request should appear.
                </p>
                <img className="img_dev_usb_format_hal28" src={dev_usb_format} alt="dev_dev_usb_format_hal28"/>
                <p>
                    Give a name to the drive and open it.
                    Create a file.
                    Now reset the device.
                    <br/>
                    You can see that the file is lost, even our formatted drive is gone.
                    This is another downside of using the internal RAM as storage,
                    so let's connect an SD card the same way as we did in the <NavLink to="./../stm-hal-24">SDIO tutorial</NavLink>.
                </p>
            </div>

            <div className="em__post-section">
                <h3>MSC device with external Flash</h3>
                <p>
                    Open the previously generated ioc file.
                    Under Connectivity, select SDIO and enable the 4b wide communication.
                    Set the clock divider to 5, and re generate the project.
                </p>
                <p>
                    We don't need the internal buffer anymore so delete our previously created global variable in <i>usbd_storage_if.c</i>.
                    While we're at it, let's modify some other functions.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {sdio_capacity_dev}
                </SyntaxHighlighter>
                <p>
                    In the case of the internal RAM storage, we knew exactly how much space we had.
                    If we use SD cards, we must read the storage space from the card controller using the GetCardInfo function.
                    The result will contain the block number and the block size of the attached device.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {sdio_read_dev}
                </SyntaxHighlighter>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {sdio_write_dev}
                </SyntaxHighlighter>
                <p>
                    The memcpy must be replaced with native block r/w operations in the respective read and write functions.
                    This operation could take some time, so I've put a card state pull in an infinite loop.
                    When the function returns, we know that the processes are finished.
                </p>
                <p>
                    Build the program, and watch as the used space goes from 80% to 10% (HAL libs of peripheral and the USB/SDIO stack).
                </p>
                <img className="img_dev_usb_sdcard_mx_hal28" src={dev_usb_sdcard_mx} alt="ddev_usb_sdcard_mx_hal28"/>
                <p>
                    The rest of the procedure is exactly the same as before.
                    The user can't distinguish whether we are using internal or external storage medium -
                    the only telling sign is that with an SD card, the data will persist after a power cycle :).
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {sdio_write_dev}
                </SyntaxHighlighter>

            </div>

            <div className="em__post-section">
                <h3>MSC host setup:</h3>
                <p>
                    Open the ioc file or create a new project for the NUCLEO-F411RE board.
                    Go to Connectivity, and enable the USART2 as asynchronous transmit and receive module,
                    so that we can send messages to the PC using the VPC on the ST-Link.
                    Configure the USB as Host only peripheral.
                    Under the Middleware, select <i>Mass Storage Host Class</i> - this will give us all the MSC Host functions.
                    Lastly, go to FATFS and select <i>USBDISC</i>, since we want to create/modify files ourselves, and it's easier to use the FAT system.
                </p>
                <Popup trigger={<img className="img_host_usb_mx_hal28 clickable_img" src={host_usb_mx} alt="host_usb_mx_hal28"/>} modal nested>
                    {
                        close =>(
                            <img className="em__img_full" src={host_usb_mx} alt="host_usb_mx_hal28"/>
                        )
                    }
                </Popup>
                <p>
                    Select 4096 maximum sector size, enable the exFAT support for those juicy modern Sticks.
                    Enable the support for long file names with static buffer.
                    For good measures I've set the minimum stack and heap size to 0x1000.
                    Generate the project.
                </p>
            </div>

            <div className="em__post-section">
                <h3>MSC host firmware:</h3>
                <p>
                    First and foremost, we need some file handling functions: mounting and unmounting the USB flash drive;
                    getting the total and free space on the drive; scanning for directories; formatting the drive;
                    creating, reading, writing, deleting and updating files; creating and deleting folders.
                    If these functions sound familiar, it's because we used them in the <NavLink to="./../stm-hal-24">SDIO tutorial</NavLink>.

                    Of course, there are minor differences, since now we are using USB instead of SDIO, but those are minor,
                    and the build errors will tell you what have to be changed.
                    If you would rather get the modified files, go to <a href="https://gitlab.com/stm32_mcu_group/stm32_hal/28_usb_msc.git">this</a> repo.
                </p>
                <p>
                    The main function can be left as is, we will add some additional code to the <i>usb_host.c</i>.
                    In particular, we are interested in the <i>USBH_UserProcess</i> function.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {usb_host_disconnect}
                </SyntaxHighlighter>
                <p>
                    In the case of disconnection, we must unmount the drive.
                </p>
                <p>
                    In the case of an active device, we can try out all the file handling functions.
                    First, we mount the drive, then we get the properties.
                    Next we create some files and folders.
                    Build the project and upload to the MCU.
                </p>
                <p>
                    Open a Serial viewer, and connect an USB Flash drive using an OTG cable
                    (Or directly, if you are working with a board that has a host connector).
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {usb_text_dump}
                </SyntaxHighlighter>
                <p>
                    Here is a text dump of the process.
                    If you now take out the drive and check the contents, it should contain the files and folders.
                </p>
                <p>
                    <a href="https://gitlab.com/stm32_mcu_group/stm32_hal/28_usb_msc.git">Here</a> are the source files for both the device and host projects.
                </p>
            </div>

            <div className="em__post-navigation">

                <NavLink to="./../stm-hal-27">
                    <Button btnID={"leftBTN"} buttonSize="btn--medium"> Previous Post</Button>
                </NavLink>

                <NavLink to="./../stm-hal-29">
                    <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
                </NavLink>
            </div>

        </div>
    );
}

export default MCU_HAL28;