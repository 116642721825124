import React from "react";
import "./mcu_hal8.css";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";

import uart_poll from "./uart_poll.jpg";
import uart_it from "./uart_it.jpg";
import uart_it_setup from "./uart_it_setup.jpg";
import uart_dma_setup from "./uart_dma_setup.jpg";

const uart_poll_var = `
uint8_t receive_buffer[64] = "";
uint16_t receive_buffer_len = 0;
uint8_t receive_char = 0;
`;
const uart_poll_prog = `
 HAL_UART_Receive(&huart2, &receive_char, 1, 10);
      if(receive_char != 0){
          receive_buffer[receive_buffer_len++] = receive_char;

          if(receive_char == '\\r'){
              printf("Received string: %s\\r\\n", receive_buffer);
              receive_buffer_len = 0;
          }

          receive_char = 0;
      }
`;
const uart_it_prog = `
void HAL_UART_RxCpltCallback(UART_HandleTypeDef *huart){
    HAL_UART_Transmit(huart, receiver_buffer, 12, 100);
    HAL_UART_Receive_IT(huart, receiver_buffer, 12);
}
`;

function MCU_HAL8(){
    return(
        <div className="em__post">
            <div className="em__post-title">
                <h1>Universal Asynchronous Receiver/Transmitter - Receiving data</h1>
            </div>
            <div className="em__post-section">
                <h3>Aim of this tutorial:</h3>
                <p>
                    In the previous tutorial, we've learnt how to send data to another MCU/CPU.
                    In this tutorial we'll have a look at the different options of receiving data.
                </p>
            </div>
            <div className="em__post-section">
                <h3>Implementation:</h3>
                <p>
                    We will start with the project from the previous tutorial.
                    We already have a basic core configuration and we set the  USART peripheral as asynchronous transmit and receive module.
                </p>
                <p>
                    Data received from human users differs from data generated by other processing units.
                    The processors have a fixed messaging system (e.g. 16B frame with start byte, 5B address, 8B data, checksum and stop bytes)
                    in contrast to the human-machine communication, where the messages can be of variable length,
                    logical units may or may not start with uppercase letters and usually are terminated with '\n' or '\r\n'.

                    Of course, there can be a parsing program that creates data frames, but that is outside the scope of this tutorial :).
                </p>
                <h4>RX ~ Polling method</h4>
                <p>
                    So, messages of variable length terminated by carriage return and new line... The simplest method is to poll the UART RX line,
                    check for a single received byte. If the byte is alphanumeric, store it in a buffer and increase the current location index.
                    If the byte is a new line character, echo it back, clear the puffer and set the index to 0.
                </p>
                <p>
                    First the variables, which are private to the main function:
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {uart_poll_var}
                </SyntaxHighlighter>
                <p>
                    Next, the implementation in the main loop:
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {uart_poll_prog}
                </SyntaxHighlighter>
                <p>
                    We poll the <i>UART2 RX</i> line for 1B of data with 10ms of time-out interval.
                    If we have a catch in this time window, we can put the data into the buffer while post-incrementing the index.
                    In my case, the last character is '\r' when pressing enter, so i'll check for that
                    (if this code won't send the data back, either change the new line sequence in the transmitter program, or change to '\n' in the source code).
                    If we have a complete line, we can echo the data back, and we must reset the receive buffer.
                    Don't forget to set the receiver byte to 0 after every read.
                </p>
                <img className="img_uart_poll_hal8" src={uart_poll} alt="uart_poll_hal8" />
                <p>
                    The polling method works, but the blocking function <i>HAL_UART_Receive</i> always pauses the execution of the main function.
                    This shortcoming is overcome in interrupt mode.
                </p>

                <h4>RX ~ Interrupts</h4>
                <p>
                    Enable the UART global interrupt under the NVIC tab:
                </p>
                <Popup trigger={<img className="img_uart_it_setup_hal8 clickable_img" src={uart_it_setup} alt="uart_it_setup_hal8"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={uart_it_setup} alt="uart_it_setup_hal8" />
                    )}
                </Popup>

                <p>
                    Create a global buffer <i> volatile uint8_t receiver_buffer[12] = "";</i> so that both the interrupt and the main functions can access it.
                </p>
                <p>
                    Start the data reception in the main function using <i>HAL_UART_Receive_IT(&huart2, receiver_buffer, 12);</i>.
                    You can see that I've decreased the data width because the interrupt will occur only when the specified amount of data is received,
                    and 64B data is a bit too much typing for my liking.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {uart_it_prog}
                </SyntaxHighlighter>
                <p>
                    When the interrupt fires, the data will be present in the specified buffer.
                    We can send it back to the PC, and we can re-enable the reception interrupt.
                    A more elegant way of handling this would be to only set a flag in the interrupt routine, and handle it in the main loop.
                    This way the interrupt execution time is minimal and we can use local buffers, but I digress. We have a nicer version with non-blocking implementation.
                </p>
                <img className="img_uart_it_hal8" src={uart_it} alt="uart_it_hal8" />

                <h4>RX ~ DMA</h4>

                <p>
                    Let's talk about the final way of handling UART data reception: using <b>D</b>irect <b>M</b>emory <b>A</b>ccess.
                    DMA is a hardware process that handles data transfer without processor intervention, events can happen behind the scene without interrupts.
                    Thus DMA is best suited for mass data transfer.
                </p>
                <p>
                    The DMA capabilities of the processor are to be considered:
                    some allow scatter-gather and may be suitable for working with the typical circular buffers used in
                    serial interface output and some may only be able to do linear buffer transfers,
                    requiring circular buffers to often be handled as multiple DMA transfers.
                </p>
                <p>
                    DMA on reception can be tricky because one doesn't know that there has been reception until a
                    complete DMA block transfer has completed (DMA end interrupt for example).
                    Im many UART reception cases the size of a data packet is not known in advance and so a fixed DMA
                    transfer size is on little use (if one byte is received from a terminal input but it is not known
                    that it is in the buffer it won't be handled by the software without it having to poll the buffer,
                    cancelling any DMA  overhead savings).
                </p>
                <p>
                    DMA reception is very efficient when combined with break detection on the UART input
                    (protocols that signal a complete block end by using a break (continuous '0').
                    The break detection interrupt can then be used to read out the complete data packet that was transferred by DMA.
                </p>

                <p>
                    From these considerations the DMA usage is not the best method for our program, but we will implement it just for the sake of completeness.
                </p>

                <Popup trigger={<img className="img_uart_dma_setup_hal8 clickable_img" src={uart_dma_setup} alt="uart_dma_setup_hal8"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={uart_dma_setup} alt="uart_dma_setup_hal8" />
                    )}
                </Popup>

                <p>
                    In the DMA Settings add a new DMA request for the RX line, set it to Normal mode with Byte sized Data Width.
                    Change the <i>HAL_UART_Receive_IT</i> to <i>HAL_UART_Receive_DMA</i> for the most basic but working program.
                </p>

                <p>
                    This completes our two part tutorial about UART communications.
                    The UART RX source files are uploaded <a href="https://gitlab.com/stm32_mcu_group/stm32_hal/8_uart_rx.git">here</a>.
                </p>


            </div>

            <div className="em__post-navigation">

                <NavLink to="./../stm-hal-7">
                    <Button btnID={"leftBTN"} buttonSize="btn--medium"> Previous Post</Button>
                </NavLink>

                <NavLink to="./../stm-hal-9">
                    <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
                </NavLink>
            </div>
        </div>
    );
}

export default MCU_HAL8;