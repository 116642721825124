import React from "react";
import "./ble_sensor.css";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";

import {MathJax, MathJaxContext} from "better-react-mathjax";

import schem from "./schematic.jpg";
import pcb_l1 from "./pcb_l1.jpg";
import pcb_l23 from "./pcb_l23.jpg";
import pcb_l4 from "./pcb_l4.jpg";

function ShowTex({string}){
    const config = {
        loader: { load: ["[tex]/html"]},
        tex: {packages: {"[+]": ["html"]},
            inlineMath: [["$", "$"]],
            displayMath: [["$$", "$$"]]
        }
    };

    return(
        <MathJaxContext config={config} version={3}>
            <MathJax dynamic inline>
                {string}
            </MathJax>
        </MathJaxContext>
    );
}

const custom_stm = `
/* USER CODE BEGIN CUSTOM_STM_Service_1_Char_1_ACI_GATT_ATTRIBUTE_MODIFIED_VSEVT_CODE */
Notification.Custom_Evt_Opcode = CUSTOM_STM_LSS_WRITE_EVT;
Notification.DataTransfered.pPayload = attribute_modified->Attr_Data;
Custom_STM_App_Notification(&Notification);
/* USER CODE END CUSTOM_STM_Service_1_Char_1_ACI_GATT_ATTRIBUTE_MODIFIED_VSEVT_CODE */
`;

const custom_app_pv=`
/* USER CODE BEGIN PV */
uint8_t is_connected = 0;
uint8_t is_notification_enabled = 0;
/* USER CODE END PV */
`;

const custom_app_pfp = `
/* USER CODE BEGIN PFP */
static void Custom_Sample(void);
/* USER CODE END PFP */
`;

const custom_stm_app_notification = `
void Custom_STM_App_Notification(Custom_STM_App_Notification_evt_t *pNotification){
    uint8_t payload[2];
    switch (pNotification->Custom_Evt_Opcode){
        case CUSTOM_STM_LSS_WRITE_EVT:
            HAL_GPIO_TogglePin(LED_GPIO_Port, LED_Pin);
            payload[0] = pNotification->DataTransfered.pPayload[0];
            payload[1] = pNotification->DataTransfered.pPayload[1];
            /* Write the setting parametrisation*/
            break;
        case CUSTOM_STM_TMEAS_NOTIFY_ENABLED_EVT:
            is_notification_enabled |= 0x01;
            break;
        case CUSTOM_STM_TMEAS_NOTIFY_DISABLED_EVT:
            is_notification_enabled &= ~(0x01);
            break;
        case CUSTOM_STM_HMEAS_NOTIFY_ENABLED_EVT:
            is_notification_enabled |= 0x02;
            break;
        case CUSTOM_STM_HMEAS_NOTIFY_DISABLED_EVT:
            is_notification_enabled &= ~(0x02);
            break;
        case CUSTOM_STM_NOTIFICATION_COMPLETE_EVT:
            break;
        default:
            break;
    }
    return;
}
`;

const custom_app_notification = `
void Custom_APP_Notification(Custom_App_ConnHandle_Not_evt_t *pNotification){
    switch (pNotification->Custom_Evt_Opcode){
        case CUSTOM_CONN_HANDLE_EVT :
            is_connected = 1;
            HW_TS_Stop(Custom_App_Context.TimerMeasurement_Id);
            HW_TS_Start(Custom_App_Context.TimerMeasurement_Id, CUSTOM_MEASUREMENT_INTERVAL);
            break;
        case CUSTOM_DISCON_HANDLE_EVT :
            is_connected = 0;
            HW_TS_Stop(Custom_App_Context.TimerMeasurement_Id);
            break;
        default:
            break;
    }
    return;
}
`;

const custom_app_init = `
void Custom_APP_Init(void){
    HW_TS_Create(CFG_TIM_PROC_ID_ISR, &(Custom_App_Context.TimerMeasurement_Id), hw_ts_Repeated, Custom_Sample);
    return;
}
`;

const custom_sample = `
float temp_float = 0;
uint16_t temp_i16 = 0;

static void Custom_Sample(void){
    if((is_notification_enabled & 0x01) == 0x01){
        // Temperature notification is enabled
        temp_float = htsGetTempF();
        temp_i16 = (uint16_t)(temp_float*100);
        UpdateCharData[0] = (uint8_t)((temp_i16 >> 8) & 0x00FF);
        UpdateCharData[1] = (uint8_t)(temp_i16 & 0x00FF);
        Custom_Tmeas_Update_Char();
    }
    
    if((is_notification_enabled & 0x02) == 0x02){
        // Humidity notification is enabled
        temp_float = htsGetHumF();
        temp_i16 = (uint16_t)(temp_float*100);
        UpdateCharData[0] = (uint8_t)((temp_i16 >> 8) & 0x00FF);
        UpdateCharData[1] = (uint8_t)(temp_i16 & 0x00FF);
        Custom_Hmeas_Update_Char();
    }
    
    UTIL_SEQ_SetTask( 1<<1, CFG_SCH_PRIO_0);
}
`;

const android_gradle = `
...
android {
    ...
    defaultConfig { ... }
    signingConfigs {
        release {
            if (project.hasProperty('MYAPP_RELEASE_STORE_FILE')) {
                storeFile file(MYAPP_RELEASE_STORE_FILE)
                storePassword MYAPP_RELEASE_STORE_PASSWORD
                keyAlias MYAPP_RELEASE_KEY_ALIAS
                keyPassword MYAPP_RELEASE_KEY_PASSWORD
            }
        }
    }
    buildTypes {
        release {
            ...
            signingConfig signingConfigs.release
        }
    }
}
...
`;

const gradle_properties = `
MYAPP_RELEASE_STORE_FILE=my-release-key.keystore
MYAPP_RELEASE_KEY_ALIAS=my-key-alias
MYAPP_RELEASE_STORE_PASSWORD=****
MYAPP_RELEASE_KEY_PASSWORD=****
`;

const key_gen = `
keytool -genkey -v -keystore my-release-key.keystore -alias my-key-alias -keyalg RSA -keysize 2048 -validity 10000
`;

const choco_node_jdk = `choco install -y nodejs-lts microsoft-openjdk17`;

const manifest = `
<uses-permission android:name="android.permission.INTERNET" />
<uses-permission android:name="android.permission.BLUETOOTH" />
<uses-permission android:name="android.permission.BLUETOOTH_ADMIN" />
<uses-permission android:name="android.permission.ACCESS_FINE_LOCATION" />
<uses-feature android:name="android.hardware.bluetooth_le" android:required="true" />
<uses-permission android:name="android.permission.BLUETOOTH_SCAN"/>
<uses-permission android:name="android.permission.BLUETOOTH_CONNECT"/>
<uses-permission android:name="android.permission.WRITE_EXTERNAL_STORAGE" />
<uses-permission android:name="android.permission.MANAGE_EXTERNAL_STORAGE" />
`;

function Ble_sensor(){
    return(
        <div className="em__post">
            <div className="em__post-title">
                <h1>BLE Temperature and Humidity sensor</h1>
            </div>
            <div className="em__post-section">
                <h3>Introduction:</h3>
                <p>
                    I love tea. Over the years, I've accumulated a small collection that needs to be kept at proper humidity and temperature levels.
                    Low humidity storage dries the tea out, which results in a bland-tasting brew.
                    High humidity and high temperatures are dangerous because they are breeding grounds for bacteria and fungi.
                    It is said that tea ages the best when the humidity is in the range of 50 - 75 % RH while the temperature is in the range of 21 - 24 °C (below 27°C).
                    <br/>
                    The tea stash is placed in a dark wooden container in my work room. The tea is just like me; we don't like direct Sunlight.
                    While my ideal temperature is 17 - 19°C, this is not ideal for the tea. This can be mitigated using a small heating element.
                    The bigger problem is the humidity, which fluctuates. I measured as low as 30% and as high as 80%.
                    <br />
                    There are humidity packs made for small containers (for cigars), but those packs become expensive when one needs them in larger spaces.
                    I found out on <a href="https://www.teaforum.org/viewtopic.php?t=177">a tea forum</a> that there is
                    an excellent buffer solution that keeps the RH of the air constant. This is called humidity fixed-point,
                    and there are extensive lists of salts and other soluble compounds and their humidity fixed-point values in function of temperature.
                    Some of these compounds (at 20°C): saturated NaCl solution - 75%, NH4Cl - 84%, NaOH - 9%, Na2CO3 - 43%.
                    If one mixes these buffers, the result is somewhat unexpected, complex, and counterintuitive; for example, a NaCl + NH4Cl mixture can give a fixed point of 69%.
                    <br/>
                    Long story short, a mix of table salt and plain sugar in a mass ratio of 25:6 produces a humidity fixed point of 70%.
                    I've tested this, and it just works. The problem is that when one opens the container, the atmosphere changes.
                    I want a device that monitors this change.
                    If it's drastic, I will build a tight control loop.
                    If not, then it is still a nice exercise.
                </p>

                <h3>
                    Problem formulation:
                </h3>

                <p>
                    I need a device that is capable of measuring the humidity and temperature and transmitting it while the container is closed.
                    And an application that logs the data.

                    This means that a battery-operated circuit with wireless data transfer is suitable.
                    One candidate would be the ESP32 with the BLE/WiFi radio, another would be the STM32WBx with BLE radio, and finally the NRF51832.
                    None of these ICs contain humidity sensors, so that will be an external IC.

                    The ESP gobbles power like a thirsty skunk, so it's out of the question.
                    The NRF is nice and power-efficient, but I don't have a programmer for it, and I'm not a fan of Zephyr.
                    So STM32WB15 it is. Their engineering support is negligible (at the time of writing) for this product, but I never back down from a challenge.
                </p>

                <ol>
                    <li>Hardware - I want to create custom hardware with BLE-enabled (not certified) capabilities.
                        This board should have a programming header, a virtual COM port for eventual debugging, a power LED and the temperature sensor</li>
                    <li>Firmware - should initialise the system and the sensor communication interface, perform periodic measurements, and abstract the BLE server stack and comm functionalities.
                        It should be able to receive commands from the client and notify the client when a new measurement is available.</li>
                    <li>Software - Android program that implements the BLE client functionalities. Scans the available BLE devices, connects to the sensor, and receives the measurements.</li>
                </ol>

            </div>
            <div className="em__post-section">
                <h3>Hardware design</h3>
                <p>
                    I have some spare single-cell batteries with a boost converter and USB port, so I will use those in my design.
                    Since these "banks" supply +5V, I opted for a linear LDO voltage converter with +3.3V output.
                    We need a USB connector; I went for the micro USB since I had them lying around.
                    Also, for the virtual COM port, I used a CH340E IC.
                    <br/>
                    The temperature and humidity sensor is an HTS221TR I2C sensor,
                    which, between the design and the writing of this post, became obsolete, so proceed accordingly
                    (the recommended alternative is SHT40-AD1B).
                    <br/>
                    I chose the STM32WB15CCU6 MCU since that was the smallest available.
                    It has a Cortex M4 processor for general-purpose tasks and a Cortex M0+ processor for BLE-specific tasks.
                    The memories are 320kB FLASH and 48kB SRAM. All in a nice UFQFPN48 package.
                    <br/>
                    The radio interface needs a matching network, a filter and an antenna.
                    According to the datasheet, the STM32 RF interface has 50Ω impedance.
                    The antenna (RFANT3216120A5T) requires a 50Ω feeding transmission line,
                    while the low-pass filter expects and provides the same impedance.

                    So, theoretically, we don't need an impedance-matching network.
                    Until we remember that at 2.5GHz, even a slight unevenness in the dielectric or copper strip or solder mask could create deviation.
                    So, I put a pi filter at the RF output, which also removes some of the unwanted harmonics,
                    but I will place the filter next to the antenna and omit the matching there.
                    <br/>
                    Care must be taken with the internal SMPS of the MCU.
                    According to the datasheet, some inductors and capacitors are required.
                    Oh, and don't forget about the decoupling capacitors.
                    Each supply pin needs a 100nF capacitor.
                    I placed a 4.7uF bulk capacitor at the beginning of the star configuration for the MCU supply,
                    1uF and 10nF capacitor combination for the analog rail, while 100nF and 100pF combination for the RF supply
                    <br/>
                    I wanted this board to serve as a general development platform, so I've broken out all the usable pins and the BOOT0 with debouncing and pull-down resistors.
                </p>
                <Popup trigger={<img className="img_schem_pr2 clickable_img" src={schem} alt="schem_pr2"/>} modal nested>
                    {
                        close =>(
                            <img className="em__img_full img_bg_pr2" src={schem} alt="schem_pr2"/>
                        )
                    }
                </Popup>
                <p>
                    You can see the schematic in the previous figure.
                    I placed a ferrite onto the Vbus, which should be neglected. Only place ferrite beads if you can prove that you need them.
                    The USB data line is drawn as a differential pair with a 90Ω impedance profile.
                    The LDO output has a PGOOD status LED - this can be omitted if you go for the lowest consumption.
                    I've colour-coded the power rails so that they are easily seen on the layout.
                    The crystal of the high-speed external oscillator does not have tuning capacitors since the MCU incorporates those.
                    Both crystals have a current-limiting resistor in front of them.
                    CON2 and CON3 are the connection pins.
                </p>
                <Popup trigger={<img className="img_pcb_l23_pr2 clickable_img" src={pcb_l23} alt="pcb_l23_pr2"/>} modal nested>
                    {
                        close =>(
                            <img className="em__img_full img_bg_pr2" src={pcb_l23} alt="pcb_l23_pr2"/>
                        )
                    }
                </Popup>
                <p>
                    I opted for a four-layer PCB design.
                    I used the inner two layers as ground planes, so the first and fourth layers are separated.
                    You can see that the small vias don't create islands in the plane and don't cut up large portions.
                    The ground plane is removed underneath the BLE antenna to not deform the radiation pattern and to not reduce the power.
                </p>
                <Popup trigger={<img className="img_pcb_l1_pr2 clickable_img" src={pcb_l1} alt="pcb_l1_pr2"/>} modal nested>
                    {
                        close =>(
                            <img className="em__img_full img_bg_pr2" src={pcb_l1} alt="schem_pr2"/>
                        )
                    }
                </Popup>
                <p>
                    I've routed most of the signals on the top layer.
                    Here, we have the input power and the USB differential lines as short as possible.
                    The crystals don't need ground breakup; just don't put tracks underneath them, and make their connections short, too.
                    The same goes for the SMPS connections.
                    The 50Ω impedance micro-strip of the RF section is created according to the manufacturers' recommendations.
                    Some people would place stitching vias parallel to this RF line, influencing the impedance profile.
                    I only stitched the right side of the ground plane to reflect any radiation that could travel in the inner core material.
                    For the trace termination, I set the teardrop shape so that there is no abrupt impedance change -
                    This effect is negligible for slow transient signals, but it's good practice on the RF side.
                </p>
                <Popup trigger={<img className="img_pcb_l4_pr2 clickable_img" src={pcb_l4} alt="pcb_l4_pr2"/>} modal nested>
                    {
                        close =>(
                            <img className="em__img_full img_bg_pr2" src={pcb_l4} alt="pcb_l4_pr2"/>
                        )
                    }
                </Popup>
                <p>
                    The bottom is reserved for the supply and the slow signals that did not fit onto the top layer.
                    Since this layer houses the power rails,
                    I placed the decoupling capacitors on this side to minimise the trace length of the supply pins.
                </p>
                <p>
                    This board can be made smaller. The crystals and other required passives can be left out using the internal oscillators.
                    You could choose a smaller debug header like the tag connect, but something less expensive and more open source - I'm not a fan of patents.
                    If you choose the STM32WB55 instead of the STM32WB15, then you can leave out the CH340 IC since the MCU has native USB support.
                    In hindsight, I would also place a low-powered non-volatile memory (like an I2C FRAM) so that we could save measurements and set the MCU to sleep.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Firmware design</h3>
                <p>
                    I used the STM32CubeIDE in conjunction with the STM32CubeProg to develop the firmware and flash the device.
                    Since this is a fresh, clean IC, the BLE stack and bootloader must be flashed.
                    Check out my <NavLink to="./../../micro-tut/stm-hal/stm-hal-36">BLE tutorial</NavLink> on this procedure.
                    Since I wrote the details, here I will only list the required changes for the project setup:
                </p>
                <ol>
                    <li>
                        Enable the serial wire debugging and the HSE and LSE in the system core using a crystal/ceramic resonator.
                        Enable the Interprocessor communication controller with interrupts. Enable the hardware semaphore.
                        Set PE4 as a low-speed digital output - this is the status LED.
                    </li>
                    <li>
                        Enable the RTC module with internal wake-up and interrupt through EXTI line 19.
                    </li>
                    <li>
                        Set the RTC clock MUX to LSE inside the clock configuration and the System clock MUX to HSE with PLL.
                        Set the CPU1 clock to 64MHz, and the CPU2 clock to 32MHz.
                        The SMPS clock should also use the HSE, and the frequency should be 8MHz.
                    </li>
                    <li>
                        Under connectivity, enable the I2C1 peripheral in standard speed mode.
                        Since I did not place pull-up resistors, I enabled the internal pull-ups on the SCL and SDA pins.
                        Enable the RF module and the USART1.
                    </li>
                    <li>
                        Under Middleware and Software packs, enable the STM32_WPAN BLE module.
                    </li>
                </ol>
            </div>
            <div className="em__post-section">
                <p>
                    In BLE applications and services, set the stack to match the one you uploaded to the CM0+ (in this case, the full stack).
                    Set the application to the Server profile, which only enables the custom template.
                </p>
                <p>
                    In configuration - I left the default number of hardware timers since I will use only one.
                    If you want to debug the BLE application, add DMA access to the USART, enable the CFG_HW_USART1_ENABLED, CFG_HW_USART_DMA_TX_SUPPORTED,
                    CFG_DEBUGGER_SUPPORTED with the required traces and enable the BLE_DBG_APP_EN.
                    You need to add additional function calls to the firmware later on.
                </p>
                <p>
                    In the BLE Advertising menu, enable your device's complete local name and name.
                    In the BLE, pairing parameters enable the fixed PIN if you want to create a more secure connection, but I did not use it.
                </p>
                <p>
                    Inside the BLE GATT, create 2 services. The first one will be for future setup implementation.
                    The second one will be used for the measurements.
                </p>
                <p>
                    The setting service (reduced UUID of 6910) only needs a single characteristic the client can write to.
                    I added this with a reduced UUID of 6911. The characteristic value length is 2 (for uint16_t) with constant length.
                    We need the CHAR_PROP_WRITE property enabled; the rest must be disabled.
                    We don't need any permissions; we need the GATT_NOTIFY_ATTRIBUTE_WRITE event so that the device is notified when the client writes some data.
                </p>
                <p>
                    The measurement service (reduced UUID of 6920) needs two characteristics. One for the temperature, one for the humidity.
                    These characteristics are clones of each other, differentiated only by name and UUID (temperature 6921, humidity 6922).
                    The characteristic value length is 2 (for uint16_t) with constant length - the sensors have 16b ADCs.
                    We need the CHAR_PROP_NOTIFY property enabled; the rest must be disabled.
                    We don't need any permissions or GATT events.
                </p>
                <p>
                    If you use the USART as a BLE, debug inside the Project Manager/Advanced Settings, do not generate a function call for USART1 and remove the visibility.
                    If CM0+ uses the peripheral, we don't want CM4 to mess it up.
                    Otherwise, simply generate the project.
                </p>
                <p>
                    The header and source files can be found <a href="https://gitlab.com/stm32_mcu_group/stm32_hal/ble-sensor.git">here</a>.
                    The ht221.h/ht221.c files contain the sensor driver.
                    The sensor configuration, calibration and value reading were done with the help of the IC datasheet.
                    The sensor is set to average 16 temperature and 32 humidity samples; disabled the DRDY pin since I did not route it on the board, 1Hz data rate.
                    Even though 1Hz is too fast for our application, the IC won't get another sample until we read the existing value.
                </p>
                <p>
                    In the main source, include the sensor header and call the sensor configuration.
                    In the <i>custom_stm.c</i> source, I needed to make a little modification just so that the received notification could be processed correctly.
                    Around line 247, there is the user section of CUSTOM_STM_Service_1_Char_1_ACI_GATT_ATTRIBUTE_MODIFIED_VSEVT_CODE.
                    Here we set the opcode to <i>CUSTOM_STM_LSS_WRITE_EVT</i>, the payload must be the <i>attribute_modified->Attr_Data</i>,
                    then the notification can be called.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {custom_stm}
                </SyntaxHighlighter>
                <p>
                    The change must happen inside the <i>custom_app</i> source.
                    Include the sensor header.
                    Add a new line to the <i>CUSTOM_APP_Context_t</i>: <i>uint8_t TimerMeasurement_Id;</i>
                    that will hold the timer ID that calls the measurement functions.
                    Paste the following definitions to the custom definitions: <i>#define CUSTOM_MEASUREMENT_INTERVAL   (60*1000*1000/CFG_TS_TICK_VAL) //the 60s</i>.
                    I created two private variables for flags if we need them:
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {custom_app_pv}
                </SyntaxHighlighter>
                <p>
                    The custom sample function will read the temperature and humidity values.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {custom_app_pfp}
                </SyntaxHighlighter>
                <p>
                    The <i>STM_App_Notification</i> function handles the received notification.
                    If the client sends data, we need to read the payload, which can be used to synchronise the BLE sensor RTC to that of the phone.
                    I will implement this functionality later.
                    Otherwise, the sensor data can be sent to the client if the client enables data notification.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {custom_stm_app_notification}
                </SyntaxHighlighter>
                <p>
                    If there is a connected device, the hardware timing can be started.
                    If the device disconnects, the timer is stopped.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {custom_app_notification}
                </SyntaxHighlighter>
                <p>
                    When the BLE application is booted, the timer is created in periodic mode, and the sampling function handle is given.
                    This handle will be called when the timer interval is reached.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {custom_app_init}
                </SyntaxHighlighter>
                <p>
                    This is the implemented sampling function.
                    The data will be read in float format if a notification is enabled.
                    I only needed the data with two fractions precision, so I converted it to an unsigned int and sent it to the client.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {custom_sample}
                </SyntaxHighlighter>
            </div>
            <p>
                <b>Notes: </b>
                <ul>
                    <li>If you want to display debug messages, first call <i>APPD_Init()</i>
                        inside the <i>MX_APPE_Init</i> of the <i>app_entry.c</i> file, then you can use <i>APP_DBG_MSG()</i> the same way as you would use a printf.</li>
                    <li>
                        If you want to create a single data notification, set the data length to 4,
                        and when <i>is_notification_enabled == 0x03</i> parse the two measurements inside a single 32b unsigned value and send it.
                        This is a nicer way than you will see on the software side.
                    </li>
                    <p>
                        If you want to create a low-power application, create a variable in the SRAM that retains value during sleep.
                        Every half hour, wake the MCU, take a measurement and set the MCU to sleep.
                        When you have 48 measurements, start the BLE advertisement and send the data when the client connects.
                        After receiving the data, clear the counter and set the MCU to sleep.
                    </p>
                </ul>
            </p>
            <div className="em__post-section">
                <h3>Software design:</h3>
                <p>
                    I wanted to create an Android program to log the measured data.
                    Back in my student years, I learnt Java, but I was not a fan of the environment and the language structure.
                    Then Kotlin appeared, which was way better, especially for someone like myself who uses Python and C mainly.
                    This time, though, I wanted to try out a higher abstraction - React Native - using ReactJS.
                </p>

                <p>
                    Let's set up the environment for Android native app development using React Native.
                    The procedure is explained in great detail <a href="https://reactnative.dev/docs/set-up-your-environment">here</a>.
                    I'm using Windows 11, so I'll go that route, but a WSL/Linux-based Docker Image can also be created as easily.
                    I have <i>Chocolatey</i> installed, so the following command installed NodeJS and the Java Development Kit (JDK).
                </p>
                <SyntaxHighlighter language="bash" style={AtomOneDark}>
                    {choco_node_jdk}
                </SyntaxHighlighter>
                <p>
                    Check that the programs are installed using <i>node --version</i> and <i> java -version</i>.
                    Then install <a href="https://developer.android.com/studio">Android Studio</a>, and make sure that the Android SDK and Android SDK Platform are checked.
                    I chose an Android Virtual Device, which I didn't need since I wanted to develop the application specifically for my phone.
                </p>
                <p>
                    Configure the <i>ANDROID_HOME</i> environment variable that points to the Android SDK directory.
                    Also, add the <i>Android\Sdk\platform-tools</i> to the user path variable.
                </p>
                <p>
                    I restarted it and then connected the phone.
                    You need developer options enabled and USB debugging enabled.
                    Then, test the connection using Android Debug Bridge (adb): <i>adb devices</i>, <i>adb usb attach</i> connects the device to the debug bridge.
                </p>
                <p>
                    Open a Terminal or PowerShell in a directory of choice, then install Yarn for quicker project build using <i>npm install -global yarn</i>.
                    Install react-native with <i>yarn global add react-native</i> and <i> yarn global add react-native-cli</i>.
                    We will also need some useful dependencies: <i>react-native-ble-plx</i>,  <i>react-native-base64</i>,
                    <i>react-native-device-info</i>, <i>react-native-fs</i>, <i>react-native-permissions</i>.
                    These can be added with the <i>npm install --save NAMEOFDEP</i> command.
                </p>
                <p>
                    Create and initialise a project using <i>npx react-native init YOURAPPNAME</i>.
                    If there are dependency problems, you can run <i>react-native link NAMEOFDEP</i>.
                    To debug and solve project problems, use <i>npz react-native doctor</i>,
                    and to debug the project on the phone, use <i>npx react-native run-android</i>.
                    Metro will start in a new Terminal, and the <b>r</b> key can be used to reload the app after some change.
                </p>
                <p>
                    Again, the GitLab repo contains the main files for this project.
                    An <i>App.tsx</i> is created for the base application.
                    I used this file to create the main dynamics and style of the application.
                    I created another file, <i>useBLE.tsx</i> for the BLE functionalities.
                    The BLE function creates an interface for permission requests, device scanning, connecting/disconnecting clients,
                    available device lists and measurement lists.
                </p>
                <p>
                    To use the BLE and to store the data, we need BLUETOOTH_SCAN, BLUETOOTH_CONNECT, ACCESS_FINE_LOCATION, WRITE_EXTERNAL_STORAGE.
                </p>
                <p>
                    Then, to reduce the number of displayed devices,
                    I used a filtering method to only show devices that contain the string 'Blu' - I named my sensor 'BluBallzSensor'.
                    The data streaming is done asynchronously, and when a notification is available, the device saves and displays the humidity or temperature.
                    In the current implementation, this poses a little problem.
                    Since I created two different characteristics for the sensor values, two different async functions read them and save them one at a time.
                    This could be done in a single function if I had flattened the values into a single uint32.
                </p>
                <p>
                    We need a key to build this app to release and use it without metro. Generate it with the following line
                </p>
                <SyntaxHighlighter language="bash" style={AtomOneDark}>
                    {key_gen}
                </SyntaxHighlighter>
                <p>
                    Now, inside <i>Android/app/src/main/AndroidManifest.xml</i> add the following permissions:
                </p>
                <SyntaxHighlighter language="xml" style={AtomOneDark}>
                    {manifest}
                </SyntaxHighlighter>
                <p>
                    In the <i>Android/app/build.gradle</i> find the Android section and add the following config:
                </p>
                <SyntaxHighlighter language="gradle" style={AtomOneDark}>
                    {android_gradle}
                </SyntaxHighlighter>
                <p>
                    In the <i>Android/gradle/gradle.properties</i> add the following lines:
                </p>
                <SyntaxHighlighter language="gradle" style={AtomOneDark}>
                    {gradle_properties}
                </SyntaxHighlighter>
                <p>
                    Finally, open a terminal/PowerShell into the Android directory, and execute <i>./gradlew assembleRelease</i>.
                    When the assembly is completed, there should be an apk inside <i>android/app/build/outputs/apk/release/</i>.
                </p>
            </div>


            <div className="em__post-navigation">

                <NavLink to="./../..">
                    <Button btnID={"leftBTN"} buttonSize="btn--medium"> Menu</Button>
                </NavLink>

                <NavLink to="./..">
                    <Button btnID={"rightBTN"} buttonSize="btn--medium"> Projects</Button>
                </NavLink>
            </div>
        </div>
    );
}

export default Ble_sensor;