import React from "react";
import "./collection.css";
import {Tag} from "../../common";
import {useNavigate} from "react-router-dom";


const TutList = [
    {name:"1. RTOS introduction, IDE setup", link: "./stm-rtos-1"},
    {name:"2. CMSIS OS v2 Tasks", link: "./stm-rtos-2"},
    {name:"3. CMSIS OS v2 Queues", link: "./stm-rtos-3"},
    {name:"4. CMSIS OS v2 Semaphores", link: "./stm-rtos-4"},
    {name:"5. CMSIS OS v2 Flags", link: "./stm-rtos-5"},
    {name:"6. CMSIS OS v2 Mutexes", link: "./stm-rtos-6"},
    {name:"7. CMSIS OS v2 Software Timers", link: "./stm-rtos-7"},
]

function STM_RTOS(){

    const navigate = useNavigate();

    return(
        <div className="em__collection">
            <div className="em__collection-title">
                <h1 className="gradient__text">Tutorials using STM32 and the factory provided CMSIS OS v2 RTOS</h1>
            </div>

            <div className="em__collection-list">
                {
                    TutList.map((item) => (
                        <div >
                            <Tag title={item.name} description={item.description} onClick={() => navigate(item.link)}/>
                        </div>
                    ))
                }
            </div>

        </div>
    );
}

export default STM_RTOS;