import React from "react";
import "./dsp_11.css";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";
import {MathJax, MathJaxContext} from "better-react-mathjax";

import IIR_DF1 from "./IIR_DF1.jpg";
import IIR_DF2 from "./IIR_DF2.jpg";
import IIR_TF1 from "./IIR_TF1.jpg";
import IIR_TF2 from "./IIR_TF2.jpg";
import iir_lpf from "./iir_lpf.jpg";
import iir_hpf from "./iir_hpf.jpg";
import iir_bpf from "./iir_bpf.jpg";


function ShowTex({string}){
    const config = {
        loader: { load: ["[tex]/html"]},
        tex: {packages: {"[+]": ["html"]},
            inlineMath: [["$", "$"]],
            displayMath: [["$$", "$$"]]
        }
    };

    return(
        <MathJaxContext config={config} version={3}>
            <MathJax dynamic inline>
                {string}
            </MathJax>
        </MathJaxContext>
    );
}

const iir_header = `
#define LPF
//#define HPF
//#define BPF

float iir_filter(float src);
`;

const iir_source_coeffs = `
#ifdef LPF
#define IIR_STAGE   P
const float coeffB[IIR_STAGE][3] = {
                                    {0, 0, 0},
                                    {0, 0, 0},
                                    {0, 0, 0}
                                    };
const float coeffA[IIR_STAGE][2] = {
                                    {0, 0},
                                    {0, 0}
                                    {0, 0}
                                    };
#endif
`;

const iir_source = `
static float w[IIR_STAGE][2] = {0};

static float biquad(float src, uint8_t k){
    float temp = coeffB[k][0]*src + w[k][0];
    w[k][0] = coeffB[k][1]*src - coeffA[k][0]*temp + w[k][1];
    w[k][1] = coeffB[k][2]*src - coeffA[k][1]*temp;
    return temp;
}

float iir_filter(float src){
    float temp = src;
    for(uint8_t i = 0; i < IIR_STAGE; ++i)
    temp = biquad(temp, i);
    return temp;
}
`;

function DSP_11(){
   return(
       <div className="em__post">
           <div className="em__post-title">
               <h1>IIR filter implementation</h1>
           </div>
           <div className="em__post-section">
               <h3>Overview:</h3>
               <p>
                   In this tutorial, we will learn about IIR filter topologies.
                   We will take the discrete-time transfer functions designed in the previous tutorial and implement it in firmware using
                   Transposed direct form I topology.
               </p>
           </div>

           <div className="em__post-section">
               <h3>Theory:</h3>
               <p>
                   Let's recall the general discrete-time transfer function of the IIR filter:
                   <ShowTex string="$$ H(s) = \frac{\sum_{i=0}^{M-1}b_{i}z^{-i}}{1 + \sum_{i=1}^{N-1}a_iz^{-i}} \quad \textrm{with ROC: } |z| > \max_{1\leq i \leq N}\{ |z_{\textrm{pole}_i}|\}.$$"/>
                   This is equivalent to the difference equation
                   <ShowTex string="$$ y[n] = b_0 x[n] + b_1x[n-1] - a_1y[n-1] + \dots + b_{M-1}x[n-M+1] - a_{M-1}y[n-M+1] + \dots -a_{N-1}y[n-M+1], \quad \textrm{for } N \geq M, $$"/>
                   with <ShowTex string="$x, y \in \mathbb{Z}_+ \to \mathbb{R}$"/> right-sided sequences.
                   For simplicity (and without loss of generality) let's fix N=M=2.
                   In this case, the difference equation is
                   <ShowTex string="$$ y[n] = b_0 x[n] + b_1x[n-1] - a_1y[n-1] + b_2x[n-2] - a_2y[n-2].$$"/>
                   We can implement this equation using two FIFO buffers with depth of 2 elements, 5 multiplications and 4 additions:
               </p>
               <img className="img_IIR_DF1_dsp-11" src={IIR_DF1} alt="IIR_DF1_dsp-11"/>
               <p>
                   From the figure, we can see that the input is fed into the all-zeros subsystem, then the result is fed to the all-poles subsystem.
                   This form is called Direct Form I - it was created directly from the difference equation.
                   This is beneficial, since the first subsystem can only attenuate the signal, while the second stage might amplify it - there will be no overflow or saturation.
                   On the downside, this configuration requires four buffers.
                   We can do better.
               </p>
               <p>
                   These sub-sections are linear systems connected in series. Linear systems can be interchanged, the input-to-output behaviour will not change:
               </p>
               <img className="img_IIR_DF2_dsp-11" src={IIR_DF2} alt="IIR_DF2_dsp-11"/>
               <p>
                   If we interchange the sub-sections, the four buffers can be merged into two - this form is called Direct Form II.
                   This is good, but now we've placed the all-poles sub-system at the front, which might introduce overflow.
                   Let's go back to the DF1 implementation.
                   If we transpose a linear system, the input-to-output response will not change.
                   This can be done by flipping the arrow direction, changing the sum blocks into nodes, and changing the nodes into sums:
               </p>
               <img className="img_IIR_TF1_dsp-11" src={IIR_TF1} alt="IIR_TF1_dsp-11"/>
               <p>
                   This form is called Transposed Direct Form I, and has both the downsides: four buffers and the all-pole system is on the input side.
                   But wait, there's more.
                   If we now change the order of the sub-systems we get the Transposed Direct Form II:
               </p>
               <img className="img_IIR_TF2_dsp-11" src={IIR_TF2} alt="IIR_TF2_dsp-11"/>
               <p>
                   And we have arrived to our final destination: a form that requires only two buffers,
                   the input is fed into the all-zeros sub-system and the output is taken from the all-poles sub-system.
                   Let's denote the buffers <ShowTex string="$w_1, w_2$"/>, then we can write the TDFII difference equations as
                   <ShowTex string="$$ \begin{cases} y[n] = b_0 x[n] + w_1[n-1] \\ w_1[n] = b_1 x[n] - a_1 y[n] + w_2[n-1] \\ w_2[n] = b_2 x[n] - a_2 y[n]\end{cases}.$$"/>
                   If we program the equation in this exact order, then we only need to save <ShowTex string="$w_1, w_2$"/> scalar values.
               </p>
               <p>
                   Truth to be said, most microprocessors don't care about halfing the buffers.
                   Modern MCUs have an abundance of RAM/Flash, on the other hand, if one would want to design a filter hardware (FPGA, ASIC),
                   then there might be some incentive to preserve the resources.
                   On the topic of overflow: if we are working with single or double precision floating point numbers, then that is of small concerns.
                   Then again, if we are implementing integer or fixed-point algorithms, with the increase of filter order, the overflow becomes more and more probable.
                   Then the first thing that must be done is to break the IIR filter into multiple second (or first) order sections (SOS) and cascade them.
                   Implement each section using the DFI or TDFII topology.
               </p>
           </div>

           <div className="em__post-section">
               <h3>Firmware:</h3>
               <p>
                   You should know the drill by now.
                   Create a new project and configure the signal generation.
                   Add a new header and source file to the project.
                   The header file will only contain definition for the filter types and the filter function header.
               </p>
               <div className="em_unselectable">
               <SyntaxHighlighter language="c" style={AtomOneDark}>
                   {iir_header}
               </SyntaxHighlighter>
               </div>
               <p>
                   The first thing to do in the source file, is to create the coefficients for the numerator and denominator.
                   We need one for LPF, HPF, BPF.
                   Copy out the number of stages and the coefficients from the SOS systems generated in python.
                   Remember, not to copy the first 1 in the denominator coefficients.
               </p>
               <div className="em_unselectable">
               <SyntaxHighlighter language="c" style={AtomOneDark}>
                   {iir_source_coeffs}
               </SyntaxHighlighter>
               </div>
               <p>
                   Next, we declare our two buffers per stage.
                   We create a static function to perform the filter operation on a single stage,
                   then we can call this function as many times as there are stages.
               </p>
               <div className="em_unselectable">
               <SyntaxHighlighter language="c" style={AtomOneDark}>
                   {iir_source}
               </SyntaxHighlighter>
               </div>
               <p>
                   The main source is the usual business.
                   Start every peripheral, set flag when the ADC has a new sample, in the super loop process this flag.
                   Normalise the data, filter it and copy the value into the output variable.
               </p>
           </div>

           <div className="em__post-section">
               <h3>Data visualisation:</h3>
               <p>
                   The Monitor program is the same one that we used in the FIR testing tutorial.
               </p>
               <img className="img_iir_lpf_dsp-11" src={iir_lpf} alt="iir_lpf_dsp-11"/>
               <img className="img_iir_bpf_dsp-11" src={iir_bpf} alt="iir_bpf_dsp-11"/>
               <img className="img_iir_hpf_dsp-11" src={iir_hpf} alt="iir_hpf_dsp-11"/>
           </div>
           <div className="em__post-section">
               <p>
                   We can see the filters working on the images above.
                   Since the order of the IIR filter is lower than that of the FIR filter, the phase lag introduced is also lower.
               </p>
           </div>

           <div className="em__post-section">
               <h3>Further work:</h3>
               <p>
                   <ol>
                       <li>
                           Rewrite the Elliptic IIR filter code with the use of fixed point representation.
                       </li>
                       <li>
                           Create a 4 band graphic equalizer with the following properties: the sampling frequency is 2kHz,
                           the frequency bands are equidistant in logarithmic space. The filters are Butterworth LP - BP - BP - HP.
                           The user should be able to set the gain of each channel in the Monitor from -5dB to 5dB.

                       </li>

                   </ol>
               </p>
           </div>


           <div className="em__post-navigation">

               <NavLink to="./../dsp-10">
                   <Button btnID={"leftBTN"} buttonSize="btn--medium"> Prev Post</Button>
               </NavLink>

               <NavLink to="./../dsp-12">
                   <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
               </NavLink>
           </div>
       </div>
   );
}

export default DSP_11;