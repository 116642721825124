import React from "react";
import "./mcu_hal18.css";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";

import dac_hard from "./dac_hard.jpg";
import dac_setup from "./dac_setup.jpg";
import dac_scope from "./dac_poll.jpg";

const button_irq = `
void HAL_GPIO_EXTI_Callback(uint16_t GPIO_Pin){
    if(GPIO_Pin == B1_Pin){
        if(!dac_dir){
            dac_reg += 200;
        }
        if(dac_dir){
            dac_reg -= 200;
        }

        if((!dac_dir && dac_reg >=4000) || (dac_dir && dac_reg <= 2000))
            dac_dir ^= 1;
    }
}
`;

const dac_out = `
HAL_DAC_SetValue(&hdac1, DAC_CHANNEL_1, DAC_ALIGN_12B_R, dac_reg);
HAL_DAC_Start(&hdac1, DAC_CHANNEL_1);
HAL_Delay(10);
`;


function MCU_HAL18(){
    return(
        <div className="em__post">
            <div className="em__post-title">
                <h1>DAC regular channel</h1>
            </div>

            <div className="em__post-section">
                <h3>Aim of this tutorial:</h3>
                <p>
                    In the previous tutorials, we used the ADC to sample continuous time analog signal and transform them into digital signals.
                    In this tutorial we will learn how to generate analog signals using the digital to analog converter peripheral.
                    We will test the application using an LED so that we can see the values change in real time.
                </p>
            </div>

            <div className="em__post-section">
                <h3>DACs in general:</h3>
                <p>
                    In electronics digital to analog converters (DACs) are systems that convert discrete-time and discrete-amplitude signals
                    into continuous-time and continuous-amplitude signals.
                    They are the practical realisation of the zero-order hold mathematical model.
                </p>
                <p>
                    There are different types of DACs. The most used ones are:
                    <ul>
                        <li>
                            PWM-based DACs - create a modulated signal, and filter the result with a low-pass analog filter.
                            This way the output voltage will be proportional to the duty cycle of the PWM signal.
                            This method only requires a timer and an RC network (it can be improved by using active filters).
                            You can read more about PWM and PWM DAC <a href="https://en.wikipedia.org/wiki/Pulse-width_modulation">here</a>.
                        </li>
                        <li>
                            binary weighted DACs - contain an electrical element as a weighting component for each bit of data.
                            These weights make the inputs have powers-of-two value, which are summed up.
                            These DACs are the fastest but they are also the least accurate.
                            There are switched resistor, current source, capacitor and R-2R DACs.
                            You can read more about binary weighted DACs <a href="https://circuitsgeek.com/tutorials/binary-weighted-dac/">here</a> and <a href="https://www.analog.com/media/en/training-seminars/tutorials/MT-015.pdf">here</a>.
                        </li>
                        <li>
                            Oversampling DACs - or delta-sigma DACs use a pulse density conversion with oversampling.
                            High speeds (> 100ksps) and high resolutions (>24b) are attainable using these DACs, similarly to the ADC counterpart.
                        </li>
                    </ul>
                </p>
            </div>

            <div className="em__post-section">
                <h3>DACs in the STM32:</h3>
                <p>
                    The STM32G4 has two DAC interfaces that are configurable to 8b or 12b wide operation.
                    Each DAC has two output channels which can be independently or simultaneously updated.
                    The DAC interfaces can be synchronised.
                    The DAC conversion can be triggered by software or by external trigger sources.
                    DMA capability is linked for each channel to reduce bus and processor activity.
                    The outputs of the DACs can be configured to be external (buffered or unbuffered) or internal (to on-chip peripherals).
                    Sample and hold circuit is added for low-power operations.

                </p>

                <Popup trigger={<img className="img_dac_hard_hal18 clickable_img" src={dac_hard} alt="dac_hard_hal18"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={dac_hard} alt="dac_hard_hal18" />
                    )}
                </Popup>

            </div>

            <div className="em__post-section">
                <h3>Code driven DAC implementation:</h3>
                <p>
                    Create a new project and set the usual external crystal and debugger connections.
                    Set OUT1 in Analog/DAC1 to Connected to external pin only.
                    Enable the output buffer since we wouldn't like if the output voltage would change depending on the load resistance.
                    Also set the Trigger to Software trigger.
                    Finally generate the project.
                </p>

                <Popup trigger={<img className="img_dac_setup_hal18 clickable_img" src={dac_setup} alt="dac_setup_hal18"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={dac_setup} alt="dac_setup_hal18" />
                    )}
                </Popup>

                <p>
                    Connect CN7/32 (PA4) with a cable to a breadboard. There hook up a resistor in series with a LED.
                    I used a 100R resistor with a red LED.
                </p>
                <p>
                    Let's declare two global variables: <i>uint16_t dac_reg</i> and <i>uint8_t dac_dir</i>.
                    Set the first one to 2000 (corresponds to ~1.6V, the forward voltage of the diode is 1.7V), and the last one to 0.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {button_irq}
                </SyntaxHighlighter>
                <p>
                    The board has a user button.
                    We want to increment the DAC reg with evey press of the button by 200 until the value reaches 4000,
                    then decrement it until it reaches 2000.
                    This could be done in the main loop, or using the external interrupt callback.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {dac_out}
                </SyntaxHighlighter>
                <p>
                    In the main loop, update the DAC with the current value using <i>HAL_DAC_SetValue</i>.
                    The function expects the DAC handle, the channel that we want to use, the data resolution and alignment and the data itself.
                    Output the set value using the function <i>HAL_DAC_Start</i>, which needs the DAC handle and the channel number.
                </p>
                <img className="img_dac_scope_hal18" src={dac_scope} alt="dac_scope_hal18"/>
                <p>
                    If everything went well, you should see the LED turning on, starting to get brighter, then dimmer with every push of the button.
                    Alternatively, you could increase the value in the main loop, and delay the loop so that the output changes without user interaction.
                    Either way the image above shows the DAC in action, and the source code can be found <a href="https://gitlab.com/stm32_mcu_group/stm32_hal/18_dac_regular.git">here</a>.
                </p>
            </div>

            <div className="em__post-navigation">

                <NavLink to="./../stm-hal-17">
                    <Button btnID={"leftBTN"} buttonSize="btn--medium"> Previous Post</Button>
                </NavLink>

                <NavLink to="./../stm-hal-19">
                    <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
                </NavLink>
            </div>
        </div>
    );
}

export default MCU_HAL18;