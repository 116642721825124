import React from "react";
import "./../posts.css";
import "./fpga_4.css";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import SyntaxHighlighter from 'react-syntax-highlighter';
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import adder from "./adder.jpg";
import block_adder from "./block_adder.png";
import full_adder from "./full_adder.png";
import sim from "./sim.png";

const adder_1b = `
module adder_1b(a, b, c_in, sum, cout);
    input a, b, c_in;
    output sum, c_out;
    
    assign {c_out, sum} = c_in + a + b;
endmodule`;

const fulladder =`
module fullAdder #(parameter DATA_WIDTH = 8)(
    input[DATA_WIDTH-1:0] data1, 
    input[DATA_WIDTH-1:0] data2,
    output[DATA_WIDTH:0] result);
    
    wire[DATA_WIDTH-1:0] carry_wire;
    wire[DATA_WIDTH-1:0] sum_wire;
    
    genvar k;
    generate
        for(k=0; k < DATA_WIDTH; k = k + 1) begin
            if(k == 0)
                adder_1b instance(
                    .a(data1[k]), .b(data2[k]),
                    .c_in(1'b0), .sum(sum_wire[k]),
                    .c_out(carry_wire[k]));
            else
                adder_1b instance(
                    .a(data1[k]), .b(data2[k]),
                    .c_in(carry_wire[k-1]), .sum(sum_wire[k]),
                    .c_out(carry_wire[k]));
        end
    endgenerate
    
    assign result = {carry_wire[7], sum_wire};
endmodule
`;

const stimulus = `
module stimulus;
    reg [7:0] A, B;
    wire [8:0] SUM;
    
    fullAdder #(.DATA_WIDTH(8)) fullAdder_instance(
            .data1(A),
            .data2(B),
            .result(SUM));
            
    initial begin
        $monitor($time, "A= %b, B= %b --- SUM = %b \n", A, B, SUM);
    end
    
    initial begin
        A = 8'd0; B = 8'd0;
        #5 A = 8'd3; B = 8'd4;
        #5 A = 8'd2; B = 8'd5;
        #5 A = 8'd9; B = 8'd9;
        #5 A = 8'd10; B = 8'd15;
        #5 A = 8'd10; B = 8'd5;
    end
endmodule
`;

function FPGA_4(){
    return(
        <div className="em__post">
            <div className="em__post-title">
                <h1>HDL Peculiarities</h1>
            </div>
            <div className="em__post-section">
                <h3>Aim of this post:</h3>
                <p>
                    In this tutorial we will learn some important Verilog instruction by creating a 8b full adder logic.
                </p>
            </div>
            <div className="em__post-section">
                <h3>Prerequisites:</h3>
                <p>
                    In this section we will only use the Sigasi Studio to create the Verilog project,
                    Diamond to synthesise, Active HDL for simulation, and Synplify for the RTL diagram view.
                </p>
            </div>
            <div className="em__post-section">
                <h3>1b full adder logic</h3>
                <p>
                    An adder is a digital circuit that creates the sum of two digital numbers.
                    A full adder circuit takes into account the carry bit from a previous operation, and the logic level design looks is shown below:
                </p>
                <img className="img_adder" src={adder} alt="adder"/>
                <p>
                    We could design this module using logic expressions, but this time let us use the arithmetic method:
                </p>
                <SyntaxHighlighter language="verilog" style={AtomOneDark}>
                    {adder_1b}
                </SyntaxHighlighter>
                <p>
                    We have two 1b number inputs, and a carry input bit.
                    On the output side we have the sum bit, and the carry out bit.
                    We could store the sum of the three input bits in a two bits wide temporary register, or use the concatenation
                    <span className="em__highlight">{`("{}")`}</span>
                    &nbsp;and put the result directly into the output bits ( order maters in the case of concatenation).
                </p>
                <p>
                    We can use this concatenation for other weird and wonderful things like:
                    If we have a 1b and 8b input variable, and we would like to store them in a 16b variable with 1s in between them,
                    we could do it as <span className="em__highlight">assign data3 = {`{data1, {7{1'b1}}, {data2[7:0]}}`}</span>.
                </p>
                <p>
                    Now back to our adder...
                </p>
            </div>
            <div className="em__post-section">
                <h3>Parametric full adder module</h3>
                <p>
                    We created a 1b full adder module.
                    Now we would like to use it for a parametrised n-bit wide unsigned integer addition.
                    In this case, we must daisy-chain n 1b adder modules, setting the carry input of the first one to zero.
                    If we know n, and if n is small, we can brute-force our way and create n instances of the module, or we could use the generate operation.
                </p>
                <SyntaxHighlighter language="verilog" style={AtomOneDark}>
                    {fulladder}
                </SyntaxHighlighter>
                <p>
                    This is the parametric full adder module. We use the hash symbol # to define parameters in a module.
                    The inputs have a width of n bits, while the output has a width of n+1 bits (with the carry bit).
                    We use wires to interconnect the carry bits of each 1b adder module.
                    Similarly, the sum wire will connect the individual sum results to the output variable.
                </p>
                <p>
                    The generate instruction will create instances defined in for cycles, and if-else conditionals. We need a variable to count the instances - genvar type.

                    In our example we have a cycle, in which the first instance is different than the rest -
                    the carry in is set to zero. the input data of the instances are the individial bits of the n bit wide operands.
                    The outputs are the sum and carry wires.
                    For k>0 every carry in bit is connected to the previous carry out bit.
                </p>
                <p>
                    The output is the concatenation of the last carry out, and the sum wire.
                    The image below shows the RTL design view of the full adder (8b parameter):
                </p>
                <img className="img_full_adder" src={full_adder} alt="full_adder"/>
                <p>
                    One adder instance:
                </p>
                <img className="img_block_adder" src={block_adder} alt="block_adder"/>
            </div>
            <div className="em__post-section">
                <h3>Behavioral analysis</h3>
                <p>
                    We saw that our design was successfully synthesised.
                    No syntax errors were found.
                    The next step is to check the behaviour of our adder module.
                    For this we will create a simulation module in Verilog.
                </p>
                <SyntaxHighlighter language="verilog" style={AtomOneDark}>
                    {stimulus}
                </SyntaxHighlighter>
                <p>
                    The above shown module is the simulation module. This module doesn't need any inputs or outputs.
                    The input operands are defined as registers, and the output as a wire, since the output doesn't have to retain it's value.
                    We create an adder instance with 8b data width, and we connect the inputs and outputs.
                    We use a <span className="em__highlight">$monitor</span>&nbsp; to write the operands and the result to the Log window.
                </p>
                <p>
                    Next we give values to the input operands with 5ps time delay between them.
                    We will get messages in the console such as
                    <i>"# KERNEL:5 A= 00000011, B= 00000100 --- SUM = 000000111"</i> due to the monitor instruction.
                </p>
                <img className="img_sim" src={sim} alt="sim"/>}
                <p>
                    We have successfully created a parametrised full adder, as the simulation result shows.
                </p>
                <p>
                    Note: Diamond will complain if you try to map this 8b adder module.
                    The FPGA in the QFN32 package doesn't have enough free i/o pins to fully map out 2 x 8b inputs, and a 9b output.
                </p>
                <p>
                    The project files are uploaded <a href="https://gitlab.com/Csurleny/fpga-tutorial-files/-/tree/main/miscVerilog">here</a>, as always.
                </p>
            </div>


            <div className="em__post-navigation">

                <NavLink to="./../fpga-tut-3">
                    <Button btnID={"leftBTN"} buttonSize="btn--medium"> Previous Post</Button>
                </NavLink>

                <NavLink to="./../fpga-tut-5">
                    <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
                </NavLink>
            </div>
        </div>
    );
}

export default FPGA_4;

