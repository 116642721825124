import React from "react";
import "./dsp_12.css";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";
import {MathJax, MathJaxContext} from "better-react-mathjax";


function ShowTex({string}){
    const config = {
        loader: { load: ["[tex]/html"]},
        tex: {packages: {"[+]": ["html"]},
            inlineMath: [["$", "$"]],
            displayMath: [["$$", "$$"]]
        }
    };

    return(
        <MathJaxContext config={config} version={3}>
            <MathJax dynamic inline>
                {string}
            </MathJax>
        </MathJaxContext>
    );
}

function DSP_12(){
    return(
        <div className="em__post">
            <div className="em__post-title">
                <h1>IIR filter implementation</h1>
            </div>
            <div className="em__post-section">
                <h3>Overview:</h3>
                <p>
                    In this tutorial, we will learn the design and implementation techniques of instruction/resource-optimised FIR filters
                    called half-band FIR filters.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Theory:</h3>

            </div>

            <div className="em__post-section">
                <h3>Firmware:</h3>
                <p>
                    You should know the drill by now.
                    Create a new project and configure the signal generation.
                    Add a new header and source file to the project.
                    The header file will only contain definitions for the filter types and the filter function header.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {}
                </SyntaxHighlighter>
                <p>
                    The first thing to do in the source file is to create the coefficients for the numerator and denominator.
                    We need one for LPF, HPF, BPF.
                    Copy out the number of stages and the coefficients from the SOS systems generated in Python.
                    Remember, do not copy the first 1 in the denominator coefficients.
                </p>
                <p>
                    Next, we declare our two buffers per stage.
                    We create a static function to perform the filter operation on a single stage; then, we can call this function as many times as there are stages.
                </p>
                <p>
                    The main source is the usual business.
                    Start every peripheral, set a flag when the ADC has a new sample, and process this flag in the super loop.
                    Normalise the data, filter it and copy the value into the output variable.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Data visualisation:</h3>
                <p>
                    The Monitor program is the same one that we used in the FIR testing tutorial.
                </p>
            </div>
            <div className="em__post-section">
                <p>
                    We can see the filters working on the images above.
                    Since the order of the IIR filter is lower than that of the FIR filter, the phase lag introduced is also lower.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Further work:</h3>
                <p>
                    <ol>
                        <li>
                            Rewrite the Elliptic IIR filter code using a fixed point representation.
                        </li>
                        <li>
                            Create a four-band graphic equaliser with the following properties: the sampling frequency is 2kHz,
                            and the frequency bands are equidistant in logarithmic space. The filters are Butterworth LP - BP - BP - HP.
                            The user should be able to set the gain of each channel in the Monitor from -5dB to 5 dB.

                        </li>

                    </ol>
                </p>
            </div>


            <div className="em__post-navigation">

                <NavLink to="./../dsp-10">
                    <Button btnID={"leftBTN"} buttonSize="btn--medium"> Prev Post</Button>
                </NavLink>

                <NavLink to="./../dsp-13">
                    <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
                </NavLink>
            </div>
        </div>
    );
}

export default DSP_12;