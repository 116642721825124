import React from "react";
import "./../hobby.css";
import "./../collection.css";
import {Tag} from "../../../common";
import {useNavigate} from "react-router-dom";
import {useRef} from "react";


function WHITEHUB(){
    const navigate = useNavigate();
    const fengQingYSRef = useRef(null);
    const yiShanPPRef = useRef(null);
    const jingguWildYSRef = useRef(null);
    const purpleKick1YSRef = useRef(null);
    const purpleNeedleBlack1YSRef = useRef(null);

    const tealist = [
        {name:"1. Feng qing ye sheng (YS)", ref:fengQingYSRef},
        {name:"2. Yi shan purple bud (YS)", ref:yiShanPPRef},
        {name:"3. Jinggu wild tree purple moonlight (YS)", ref:jingguWildYSRef},
        {name:"4. 2021 Purple kick (YS)", ref:purpleKick1YSRef},
        {name:"5. Purple needle black tea of Jingmai (YS)", ref:purpleNeedleBlack1YSRef},

    ];

    return (
        <div className="em__post">
            <div className="em__post-section">
                <h3>About the history and production of purple teas</h3>
                <p>
                    I was thinking long and hard about what to do about purple teas.
                    If we look at the processing methods, these teas should go into white, black, raw and ripe categories.
                    But the thing is, compared to other teas in those classes, the taste is different.
                    <br/>
                    There are three major purple teas:
                </p>
                <p>
                    Wild purple or Ye Sheng - Assamica Dehongensis is a very bitter tea, but it evolves into a complex sweet-sour plum-like tea after ageing.
                    Originally, it was sold as loose sheng, but nowadays, producers create black and white teas from the raw material.
                    <br/>
                    The second tea is a purple bud or Zhi Cha, a naturally mutated version of the Assamica.
                    The third tea is Zi Juan, or purple beauty, which is a man-made cultivar.
                </p>
                <p>
                    The unique purple pigmentation is attributed to the presence of anthocyanins, antioxidants that infuse the tea leaves with their distinctive hue.
                    This is the result of a response of the tea to tough natural conditions such as bug bites, cold, or strong sunlight.
                    <br/>
                    The infusion of purple tea reveals a cup adorned with a striking purple hue and a complex flavour profile.
                    Enthusiasts may encounter notes of stone fruit, floral undertones, and a gentle astringency, creating an indulgent and novel experience for the discerning tea palate.
                </p>
            </div>

            <div className="em__collection">
                <div className="em__collection-title">
                    <h1 className="gradient__text">Sampled white teas:</h1>
                </div>

                <div className="em__collection-list">
                    {
                        tealist.map((item) => (
                            <div >
                                <Tag title={item.name} description={item.description} onClick={() => item.ref.current.scrollIntoView({ behavior: 'smooth' })}/>
                            </div>
                        ))
                    }
                </div>

                <div className="em__post-section">
                    <div id="1. Feng qing ye sheng (YS)" ref={fengQingYSRef}>
                        <h3>1. Feng Qing Ye Sheng (YS)</h3>
                        <p>
                            This expertly fermented black tea was crafted using a wild tree purple leaf varietal from the Feng Qing area of Lincang prefecture.
                            This wild tree varietal grows wild in the mountainous areas west of Feng Qing township near the Da Si village at an altitude of 2000-2200 meters.

                            <br/>
                            The tea material consists of long, twisted cinnamon-brown leaves with a purple tinge; they smell like wild berries.
                            The rinsed leaves have a uniform chestnut colour with occasional khaki greens, and the smell is like raspberries, cherries, and chocolate with a hint of rose.
                            The tea soup is medium and refreshing, with a dry orange colour and pink tinge.
                            The tea tastes like grape, mandarin oranges, lilacs and hops.
                            The characteristic Yunnan chocolate notes are almost missing. Instead, this tea has floral and fruity notes in the foreground.
                            The empty cup smells like maple syrup and dry plums.
                            The tea leaves a floral and sweet muscatel flavour with hints of black cardamom.

                            I used 4.5g of leaf in a 100ml gaiwan with boiling water.
                            On average, a session lasted for seven brews.
                            <br/>
                            Green/Vegtal: 0/10, Floral: 8/10, Fruits: 9/10, Spices: 5/10, Wood/Earth: 1/10, Roast/Nuts: 2/10, Cream: 0/10, Mineral: 2/10.
                        </p>
                    </div>
                    <div id="2. Yi shan purple bud (YS)" ref={yiShanPPRef}>
                        <h3>2. Yi Shan purple bud (YS)</h3>
                        <p>
                            Spring 2007 harvest of 1 leaf / 1 bud and two leaf / 1 bud purple tea.
                            Over seven years in Jinggu town's sub-tropical conditions have given this tea a smooth, flowery, and thick taste.
                            The oils of the tea have literally "sweated out" onto the paper wrapper (staining it).
                            It is very nicely aged tea with strong cha qi, but it retains a unique vegetal taste/feeling that only purple tea can impart.
                            <br/>
                            The dry tea leaves are auburn and plum, and the smell is reminiscent of mulberry and oak tree bark.
                            The rinsed leaves combine khaki, plum and chestnut brown, and the smell is of tamarind and pine resin.
                            The tea soup is vibrant rose-gold, thick and oily.
                            The brew tastes like rose hip jam and tamarind paste with hints of ginger and tobacco.
                            There is no astringency, no bitterness present.
                            Slight minerality with plum compote notes.
                            The empty cup smells like allspice with a floral undertone.
                            The tea leaves a long-lasting sweet aftertaste with citrus-like acidity.
                            This is my favourite purple tea as of 2023.

                            I used 4g of leaf in a 100ml gaiwan with boiling water.
                            On average, a session lasted for 12+ brews.
                            <br/>
                            Green/Vegtal: 2/10, Floral: 5/10, Fruits: 7/10, Spices: 8/10, Wood/Earth: 3/10, Roast/Nuts: 0/10, Cream: 1/10, Mineral: 6/10.
                        </p>
                    </div>
                    <div id="3. Jinggu wild tree purple moonlight (YS)" ref={jingguWildYSRef}>
                        <h3>3. Jinggu wild tree purple moonlight (YS)</h3>
                        <p>
                            The tea was pressed into a waffle-shaped cake and made from Wild Tree Purple varietal tea (ye sheng cha) growing in Jinggu County.
                            The tea grows wild and is only harvested in March.
                            The tea was processed using the same technique used for Yue Guang Bai; the tea wilts briefly before being put into a long wind tunnel tube,
                            where the action of air movement gradually halts the oxidation of the tea.
                            <br/>
                            The dry tea material comprises khaki and purple leaves with pale yellow buds. The smell is of caramel apples and bitters.
                            The rinsed leaves look like autumn duff and smell like molasses, sour plum, and cinchona bark.
                            The tea soup is medium and oily, with a clear amber colour.
                            The brew tastes like apricots, brown sugar, raisins, and pomelo bitters.
                            The empty cup smelled like fragrant honey.
                            The aftertaste was sweet and sour with some smoky notes.

                            I used 5g of leaf in a 100ml gaiwan with boiling water.
                            On average, a session lasted for 11 brews.
                            <br/>
                            Green/Vegtal: 1/10, Floral: 8/10, Fruits: 6/10, Spices: 8/10, Wood/Earth: 0/10, Roast/Nuts: 1/10, Cream: 2/10, Mineral: 4/10.
                        </p>
                    </div>
                    <div id="4. 2021 Purple kick (YS)" ref={purpleKick1YSRef}>
                        <h3>4. 2021 Purple kick (YS)</h3>
                        <p>
                            This is a Ye Sheng tea processed as a raw sheng.
                            Its potency in cha qi arises from its unadulterated nature.
                            It is naturally bug-repellent and grows wild in the forests of Yunnan at an altitude of 1600-2200 meters.
                            Harvested in the spring of 2021 from Dehong County, China.
                            <br/>
                            This one is a strange tea.
                            This tea convinced me that cake acclimation and clay truly affect the taste of the soup.
                            So I had this tea, and after a week of storage, I brewed some in a gaiwan.
                            It became a slightly aromatic yellow brew of bitterness.
                            I couldn't finish the session. I've tried brewing 3g/120ml, which worked somewhat.

                            Then, someone mentioned using a clay pot because it can take away the bitterness.
                            I only have a DIY red clay teapot, which made a difference in taste.
                            It became sweet and mellow, with a slight citrus zest.

                            After a short rest (~ 1.5 months), I made another brew.
                            The difference was astonishing. I've used 5g leaf material in 100ml gaiwan with almost boiling water (~95C).

                            The leaves smelled like cherries, tamarind and pomelo rind.

                            The tea soup texture was thick and bright.
                            It tasted like citrus rind, grapefruit, cherry cough drops, plums and bitter gourd.
                            This time, the bitterness was nice.
                            The aftertaste was sweet and cooling, and the grapefruits were long-lasting.

                            The empty cup smelled like burnt caramel.

                            My final verdict is that the name "Purple Kick" is fitting.
                            If you are impatient, the tea will kick you in the stomach. If you want a tea to drink as soon as it arrives, maybe choose something more aged.
                            I could infuse this tea consistently for 8-10 brews; then the taste rolled off. The 13th brew was slightly flavoured water.
                            On the other hand, if you can leave it to rest a bit, it can be a nice daily drinker, especially for after-breakfast hot brews.
                            In my whole tea-head career, this and gyokuro were the teas that made me learn the most.

                            I usually buy the tea to drink "now" and not to age it. So I don't think that I will buy this one in the near future.

                            I used 5g of leaf in a 100ml gaiwan with boiling water.
                            On average, a session lasted for 10+ brews.
                            <br/>
                            Green/Vegtal: 3/10, Floral: 5/10, Fruits: 8/10, Spices: 8/10, Wood/Earth: 2/10, Roast/Nuts: 4/10, Cream: 7/10, Mineral: 9/10.
                        </p>
                    </div>
                    <div id="5. Purple needle black tea of Jingmai (YS)" ref={purpleNeedleBlack1YSRef}>
                        <h3>5. Purple needle black tea of Jingmai (YS)</h3>
                        <p>
                            Purple leaf "Zi Cha" was harvested in Late April from the Jing Mai mountainous area in Lancang County of Simao.
                            The fresh purple leaves were then processed into a flat needle-style tea.
                            This is a purple tea processed in black tea style.
                            <br/>
                            This tea has long, slender, dark chocolate-like needles that become slender and twisted when wet.
                            The leaves smelled like roasted squash and cherries, and there was some hint of smoke in a heated gaiwan.
                            The wet leaves smelled like dark cherries, sweet potatoes, tomato bullion and currants.
                            The tea soup was smooth and light with a red-golden colour.
                            The brew tasted like sweet apple purée, yoghurt, cherry cough drops and elderberry fruits.
                            The empty cup had cocoa and cherry notes.
                            The tea left poppy seed, dried apples and cherry notes in the mouth with sweet aftertaste.

                            I used 4g of leaf in a 100ml gaiwan with 95C water.
                            On average, a session lasted for six brews.
                            <br/>
                            Green/Vegtal: 0/10, Floral: 5/10, Fruits: 9/10, Spices: 4/10, Wood/Earth: 2/10, Roast/Nuts: 1/10, Cream: 0/10, Mineral: 3/10.
                        </p>
                    </div>
                </div>



            </div>
        </div>
    );
}

export default WHITEHUB;
