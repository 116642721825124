import React from "react";
import "./stm_rtos5.css";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";

const ev_flag_task1 = `
const uint8_t string[] = "Task1 action.\\r\\n";
/* Infinite loop */
for(;;){
  osEventFlagsWait(eFlag, 0x03, osFlagsWaitAll, osWaitForever);
  HAL_GPIO_WritePin(TA_GPIO_Port, TA_Pin, GPIO_PIN_SET);
  HAL_UART_Transmit(&huart2, string, strlen((const char*)string), 1);
  HAL_GPIO_WritePin(TA_GPIO_Port, TA_Pin, GPIO_PIN_RESET);
}
`;
const ev_flag_task2 = `
const uint8_t string[] = "Task2 action.\\r\\n";
/* Infinite loop */
for(;;){
  osEventFlagsSet(eFlag, 0x01);
  HAL_GPIO_WritePin(TB_GPIO_Port, TB_Pin, GPIO_PIN_SET);
  HAL_UART_Transmit(&huart2, string, strlen((const char*)string), 1);
  HAL_GPIO_WritePin(TB_GPIO_Port, TB_Pin, GPIO_PIN_RESET);
  osDelay(3000);
}
`;
const ev_flag_task3 = `
const uint8_t string[] = "Task3 action.\\r\\n";
/* Infinite loop */
for(;;){
  osEventFlagsWait(eFlag, 0x03, osFlagsWaitAll, osWaitForever);
  HAL_GPIO_WritePin(TA_GPIO_Port, TA_Pin, GPIO_PIN_SET);
  HAL_UART_Transmit(&huart2, string, strlen((const char*)string), 1);
  HAL_GPIO_WritePin(TA_GPIO_Port, TA_Pin, GPIO_PIN_RESET);
}
`;

const ev_flag_isr = `
void HAL_GPIO_EXTI_Callback(uint16_t GPIO_Pin){
    const uint8_t string[] = "ISR action.\\r\\n";
    osEventFlagsSet(eFlag, 0x02);
    HAL_UART_Transmit(&huart2, string, strlen((const char*)string), 1);
}
`;

function STM_RTOS5(){
    return(
        <div className="em__post">
            <div className="em__post-title">
                <h1>CMSIS Event and Thread Flags</h1>
            </div>

            <div className="em__post-section">
                <h3>Aim of this tutorial:</h3>
                <p>
                    In this tutorial we will take a look at two important parts of the RTOS middleware:
                    event flags (or event groups in FreeRTOS), and thread flags (or direct task notifications in FreeRTOS).
                </p>
            </div>

            <div className="em__post-section">
                <h3>Event flags:</h3>
                <p>
                    Event flags are RTOS constructs that allow tasks to wait in blocked state for a combination of one or more events to occur.
                    They unblock all the tasks that are waiting for the same events.
                    Event flags allow to reduce RAM usage by replacing semaphores.
                    They allow to use broadcasting mechanism within the RTOS.
                    Event flags can be set and cleared from other tasks or interrupt service routines.
                </p>
                <p>
                    Event flags can be set as 32b or 16b structure, where the 8 MSBs are used as control byte mask.
                    In this structure, bit 1 is set if we want to clear flags on exit, bit 2 is unblocked due to bit set,
                    bit 3 is wait for all bits.
                </p>
                <p>
                    Let's look at an example where we have tasks T1, T2, T3 with normal priority and an interrupt source.
                    T1 will wait for the occurrence of the interrupt and T2 action (the order does not matter).
                    Latter on we will give a purpose for T3 too.
                </p>
                <p>
                    Open a previous project, and check that in the Tasks tab there are 3 tasks with equal priority.
                    Go to events and add a new event (I named mine eFlags), then generate the project.

                </p>
                <p>
                    T1 must wait for a combination of T2 and ISR flags. Let T2 set flag 0 and the ISR set flag 1 ie. 0x01 and 0x02 respectively.
                    This means that T1 should check for 0x03, which is done using the function <i>osEventFlagsWait</i>.
                    The first argument is the flag, the second the flag state and the third is the timeout value.
                    In our case, T1 is blocked until exactly 0x03 is seen (there is the osFlagsWaitAny option, which unblocks the task if any of the specified flags is set).
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {ev_flag_task1}
                </SyntaxHighlighter>
                <p>
                    T2 sets the 0 flag (0x01), fulfills some task action and gets blocked for 3 seconds.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {ev_flag_task2}
                </SyntaxHighlighter>
                <p>
                    Then, the ISR sets flag 1 (0x02) and shows that the routine was executed by sending a string to the COM port.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {ev_flag_isr}
                </SyntaxHighlighter>
                <p>
                    Build the project and upload the firmware.
                    Start the debugger and check the behaviour of our code.
                    You should see T1 immediately going into blocked state. T2 will post a message every 3 seconds, and
                    when you press the button, the ISR gets executed, and right after that, T1 gets unblocked and gets executed.
                </p>
                <p>
                    Now, let's check if we can unblock two tasks using the same flags.
                    Copy the body of T1 and paste it into T3 (change the string).
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {ev_flag_task3}
                </SyntaxHighlighter>
                <p>
                    Build the project and test the code.
                    <br/>
                    Something strange happens: after each push of a button only one thread gets executed. First T3, then T1, then T3 and so on.
                    This is not how the description of the flags was stated.
                    <br/>
                    In debug mode, hover over the flag, and the address value should be displayed (mine was 0x20001320).
                    In Memory View sou should see that after every <i>osEventFlagsWait</i>, the flag value is reset.
                    press Ctrl+Click on the function to go to its implementation, where you should see that it uses the
                    <i>xEventGroupWaitBits</i> function from FreeRTOS.
                    This function has an argument <i>xClearOnExit</i>, which clears our flags if we did not state explicitly to not do so ( with osFlagsNoClear).
                    <br/>
                    Go back to the source file, and in T1, change the option flags to <i>osFlagsWaitAll | osFlagsNoClear</i>,
                    this way if the conditions are true, T1 is executed, but the event flags will remain.
                    Similarly change the option flags in T3, and at the end of the task insert the function <i>osEventFlagsClear(eFlagHandle, 0x11)</i>,
                    which clears the event flags.
                </p>

                <p>
                    Now, you should be able to execute T1 and T3 with a single push of a button.
                </p>

            </div>

            <div className="em__post-section">
                <h3>Thread flags:</h3>
                <p>
                    Each task has a 31b notification register (MSB is not used).
                    A thread flag or task notification is an event sent directly to a task that can unblock the receiving task.
                    Thread flags can be used in place of a queue, binary/counting Semaphore or event group.
                    Thread flags use less RAM and are 45% faster than binary semaphores, but can only be used to notify one task at a time.
                    The task that waits for notification is set to blocked state, but the sending task can't wait in blocked state for completion.
                </p>
                <p>
                    In the previous project, delete T3 and the event flag.
                    In T1, change <i>osEventFlagsWait</i> to <i>osThreadFlagsWait</i>, the parameters are the same - we want to wait forever for all flags.
                </p>
                <p>
                    In T2, delete <i>osEventFlagsSet</i>, and add <i>osThreadFlagsSet(Task1Handle, 0x02)</i> - here the flag handle is the task handle.
                    Similarly, in the external interrupt routine change the old event flag function to the
                    thread flag function: <i>osThreadFlagsSet(Task1Handle, 0x01)</i>.
                </p>
                <p>
                    Upload the firmware and test it out.
                </p>
            </div>

            <div className="em__post-navigation">

                <NavLink to="./../stm-rtos-4">
                    <Button btnID={"leftBTN"} buttonSize="btn--medium"> Previous Post</Button>
                </NavLink>

                <NavLink to="./../stm-rtos-6">
                    <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
                </NavLink>
            </div>
        </div>
    );
}

export default STM_RTOS5;