import React from "react";
import "./mcu_hal13.css";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";

import spi_dev from "./spi_dev.jpg";
import spi_set from "./spi_set.jpg";
import spi_cube from "./spi_cube.jpg";
import spi_com from "./spi_com.jpg";


const variables = `
const uint8_t status_rd = 0x05;
const uint8_t ram_write = 0x02;
const uint8_t ram_read = 0x03;
const uint8_t status_wr = 0x01;

uint8_t status_rx = 0, status_tx;
uint8_t write_byte = 0, read_byte = 0;
uint8_t ram_addr [2] = {0x00, 0x00};

uint8_t ram_seq_tx [4] = {0xde, 0xad, 0xbe, 0xef};
uint8_t ram_seq_rx [4] = {0x00};
`;

const status_rd = `
HAL_GPIO_WritePin(CSn_GPIO_Port, CSn_Pin, GPIO_PIN_RESET);
HAL_SPI_Transmit(&hspi2, (uint8_t *)&status_rd, 1, HAL_MAX_DELAY);
HAL_SPI_Receive(&hspi2, &status_rx, 1, HAL_MAX_DELAY);
HAL_GPIO_WritePin(CSn_GPIO_Port, CSn_Pin, GPIO_PIN_SET);
`;

const status_wr = `
HAL_GPIO_WritePin(CSn_GPIO_Port, CSn_Pin, GPIO_PIN_RESET);
HAL_SPI_Transmit(&hspi2, (uint8_t *)&status_wr, 1, HAL_MAX_DELAY);
HAL_SPI_Transmit(&hspi2, &status_tx, 1, HAL_MAX_DELAY);
HAL_GPIO_WritePin(CSn_GPIO_Port, CSn_Pin, GPIO_PIN_SET);
`;

const read_write_byte=`
write_byte = 0x69;
printf("Writing single byte: %#02x to address %#04x\\r\\n", write_byte, (((uint16_t)(ram_addr[0])<<8) + ram_addr[1]) );
HAL_GPIO_WritePin(CSn_GPIO_Port, CSn_Pin, GPIO_PIN_RESET);
HAL_SPI_Transmit(&hspi2, (uint8_t *) &ram_write, 1, HAL_MAX_DELAY);
HAL_SPI_Transmit(&hspi2, ram_addr, 2, HAL_MAX_DELAY);
HAL_SPI_Transmit(&hspi2, &write_byte, 1, HAL_MAX_DELAY);
HAL_GPIO_WritePin(CSn_GPIO_Port, CSn_Pin, GPIO_PIN_SET);

HAL_GPIO_WritePin(CSn_GPIO_Port, CSn_Pin, GPIO_PIN_RESET);
HAL_SPI_Transmit(&hspi2, (uint8_t *) &ram_read, 1, HAL_MAX_DELAY);
HAL_SPI_Transmit(&hspi2, ram_addr, 2, HAL_MAX_DELAY);
HAL_SPI_Receive(&hspi2, &read_byte, 1, HAL_MAX_DELAY);
HAL_GPIO_WritePin(CSn_GPIO_Port, CSn_Pin, GPIO_PIN_SET);
printf("Reading single byte: %#02x from address %#04x\\r\\n", read_byte, (((uint16_t)(ram_addr[0])<<8) + ram_addr[1]) );
`;

const sequential = `
status_tx = 0x41; // MSB - 01 sequential, LSB 1 - disable HOLD
printf("Writing STATUS: %#02x\\r\\n", status_tx );
HAL_GPIO_WritePin(CSn_GPIO_Port, CSn_Pin, GPIO_PIN_RESET);
HAL_SPI_Transmit(&hspi2, (uint8_t *)&status_wr, 1, HAL_MAX_DELAY);
HAL_SPI_Transmit(&hspi2, &status_tx, 1, HAL_MAX_DELAY);
HAL_GPIO_WritePin(CSn_GPIO_Port, CSn_Pin, GPIO_PIN_SET);

HAL_GPIO_WritePin(CSn_GPIO_Port, CSn_Pin, GPIO_PIN_RESET);
HAL_SPI_Transmit(&hspi2, (uint8_t *)&status_rd, 1, HAL_MAX_DELAY);
HAL_SPI_Receive(&hspi2, &status_rx, 1, HAL_MAX_DELAY);
HAL_GPIO_WritePin(CSn_GPIO_Port, CSn_Pin, GPIO_PIN_SET);

printf("Current STATUS: %#02x\\r\\n", status_rx );

ram_addr[0] = 0x00;
ram_addr[1] = 0x11;
uint32_t temp = ((((((uint32_t)ram_seq_tx[0] << 8) + ram_seq_tx[1]) << 8) + ram_seq_tx[2]) << 8) + ram_seq_tx[3];
printf("Writing sequential: %#08x to address %#04x\\r\\n", temp, (((uint16_t)(ram_addr[0])<<8) + ram_addr[1]) );
HAL_GPIO_WritePin(CSn_GPIO_Port, CSn_Pin, GPIO_PIN_RESET);
HAL_SPI_Transmit(&hspi2, (uint8_t *) &ram_write, 1, HAL_MAX_DELAY);
HAL_SPI_Transmit(&hspi2, ram_addr, 2, HAL_MAX_DELAY);
HAL_SPI_Transmit(&hspi2, ram_seq_tx, 4, HAL_MAX_DELAY);
HAL_GPIO_WritePin(CSn_GPIO_Port, CSn_Pin, GPIO_PIN_SET);

HAL_GPIO_WritePin(CSn_GPIO_Port, CSn_Pin, GPIO_PIN_RESET);
HAL_SPI_Transmit(&hspi2, (uint8_t *) &ram_read, 1, HAL_MAX_DELAY);
HAL_SPI_Transmit(&hspi2, ram_addr, 2, HAL_MAX_DELAY);
HAL_SPI_Receive(&hspi2, ram_seq_rx, 4, HAL_MAX_DELAY);
HAL_GPIO_WritePin(CSn_GPIO_Port, CSn_Pin, GPIO_PIN_SET);
temp = ((((((uint32_t)ram_seq_rx[0] << 8) + ram_seq_rx[1]) << 8) + ram_seq_rx[2]) << 8) + ram_seq_rx[3];
printf("Reading sequential: %#08x from address %#04x\\r\\n", temp, (((uint16_t)(ram_addr[0])<<8) + ram_addr[1]) );
`;

function MCU_HAL13(){
    return(
        <div className="em__post">
            <div className="em__post-title">
                <h1>SPI master - SRAM interface</h1>
            </div>

            <div className="em__post-section">
                <h3>Aim of this tutorial:</h3>
                <p>
                    In this tutorial, we will learn about the <b>S</b>erial <b>P</b>eripheral <b>I</b>nterface.
                    In particular, we will discuss how to use the MCU as SPI master, and also,
                    we will use an external SRAM IC to test the SPI communication.

                </p>
            </div>

            <div className="em__post-section">
                <h3>Few notions about the SPI communication:</h3>
                <p>
                    SPI is a communication bus typically used for communication between an MCU and other devices such as
                    ADC, DAC, CODEC, STC, LCD, external memory units etc.
                    The SPI was originally developed to provide a full-duplex communication in embedded applications.
                </p>
                <img className="img_spi_dev_hal13" src={spi_dev} alt="spi_dev_hal13"/>
                <p>
                    The most simple SPI communication contains four lines:
                    <ul>
                        <li> <b>SCK (SCLK)</b>: serial clock signal generated by the master.</li>
                        <li> <b>MOSI</b>: master output slave input (serial out from master).</li>
                        <li> <b>MISO</b>: master input slave output (serial in from master).</li>
                        <li> <b>SS (CS)</b>: slave select or chip select.</li>
                    </ul>
                    The SS is an active-low signal. The master chooses which slave to talk to and pulls down the SS to that slave.
                    The data is initiated by the master by generating a clock signal to shift the data out from the master,
                    and to shift the data in at the same time.
                    In contrast to the I2C the lines of the SPI communication increases with each added slave (each slave has a separate SS).
                    There are four possible SPI configurations based on the polarity of the clock and the clock edge on which the data is sampled:
                </p>
                <img className="img_spi_set_hal13" src={spi_set} alt="spi_set_hal13"/>
                <p>
                    The polarity and phase are named CPOL and CPHA respectively.
                    CPHA determines whether the data latching occurs on the leading or trailing edge of the serial clock signal.
                    CPOL determines whether the idle state is HIGH to LOW or LOW to HIGH.
                </p>
            </div>

            <div className="em__post-section">
                <h3>STM32 SPI peripheral:</h3>
                <p>
                    The SPI interface in the STM32 can be configured as full-duplex or half-duplex ,
                    receive only or transmit only, master or slave.
                    The full-duplex mode uses separate MOSI and MISO lines.
                    The half-duplex mode combines MOSI and MISO into a single line using a tri-state buffer.
                    In this mode, the slave waits for the master to send a fixed number of bytes, then returns a fixed number of bites on the same line.
                    The receive/transmit-only modes omit the MOSI/MISO line respectively.
                    The data-frame can be 8b or 16b wide.
                    The STM32 permits multi-master mode, hardware or software SS pin (the hardware SS uses an open-drain configuration),
                    MSB- or LSB-first data shifting, fault, overrun, CRC error flags with dedicated rx, tx and error interrupt capability.
                    The communication speed can be set to a value up to 40Mb/s.
                </p>

                <p>
                    As with any other communication, the SPI rx/tx modes can be configured with polling, interrupt or DMA modes.
                    The easiest way is the polling method, but it's the most ineffective because the CPU will be in a "busy-waiting"
                    state most of the time.
                    The Interrupt method signals the CPU when the data is done/ready so that the CPU can service the request.
                    Most of the time using the interrupt method is advised, however,
                    in some time critical application the polling method is recommended in order to have a fully deterministic execution.
                    If we can create data packets, we could use the DMA method, which will enable us to operate the SPI at its maximum speed.
                    It also frees the CPU from peripheral-to-memory data transfers.
                </p>
            </div>

            <div className="em__post-section">
                <h3>23K640 SPI SRAM:</h3>
                <p>
                    In the case of the current tutorial, we will use the <a href="https://ww1.microchip.com/downloads/en/DeviceDoc/22126E.pdf">23k640</a> Serial SRAM.
                    The 23k640 is a 64kb SPI low-power Serial SRAM in 8192 x 8b organisation.
                    We need to power it with a supply of 2.7 - 3.6V (the 3.3V supply from the NUCLEO board).
                    The datasheet says that the maximum communication frequency is 20MHz, but the limiting factor will be the capacitance and crosstalk of the wires.
                    The SRAM can operate in byte mode, page (32b) mode or sequential mode.

                    The communication can be paused via the HOLD pin (active low pin, which we will disable latter on).
                </p>
                <p>
                    According to Figures 1-2 and 1-3 from the datasheet,
                    we can communicate with the device with SPI in CPHA = 0, CPOL = 0 mode.
                </p>
                <p>
                    There is a STATUS register.
                    We can read the content of the STATUS register using the 0x05 command,
                    and we can write to the register using the 0x01 command.
                    The memory read command is 0x03, while the memory write command is 0x02.
                    The STATUS register is detailed in section 2.5/2.6 of the datasheet.
                    The first two MSB dictate the memory operation mode: 0b00 - byte mode:
                    we can read/write a single byte to the given address(13b) with a transfer;
                    0b10 - page mode: 1024 pages of 32b, the operation is limited to a single page, but the word count is incremented automatically;
                    0b01 - sequential mode: after the address is specified, the address counter is incremented automatically with each consecutive byte;
                    0b11 - reserved.
                </p>
                <p>
                    So, put the SRAM IC into a breadboard. Connect pin1 (SS) to CN10/24, pin2 (MISO) to CN10/28,
                    pin4 to a GND, pin5 (MOSI) to CN10/26, pin6 (SCK) to CN10/30, pin7 and pin8 to +3.3V and leave pin3 unconnected.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Peripheral configuration:</h3>
                <p>
                    Create a new project with the usual settings.
                    Enable the USART2 peripheral.
                    In Connectivity/SPI2 enable the SPI in Full-Duplex Master mode.
                    Set the data size to 8b, MSB first mode, and set a prescaler such that the Baud rate is less than 2Mb/s.
                    Set CPOL=Low, CPHA=1 Edge.
                </p>
                <Popup trigger={<img className="img_spi_cube_hal13 clickable_img" src={spi_cube} alt="spi_cube_hal13"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={spi_cube} alt="spi_cube_hal13" />
                    )}
                </Popup>
                <p>
                    Since this is a basic example, we will implement an SPI master using the polling method, so no IRQ or DMA.
                    Finally let's configure PB1 as the SS line.
                    Left click on PB1 pin and select GPIO-Output.
                    In System Core/GPIO rename PB1 to something meaningful like CSn (chip select with negative polarity).
                     Generate the project.
                </p>
            </div>

            <div className="em__post-section">
                <h3>The MCU firmware:</h3>
                <p>Include <i>stdio.h</i>, and copy the <i>_write</i> function so that we can redirect the printf.</p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {variables}
                </SyntaxHighlighter>
                <p>
                    The opcodes are declared as constants.
                    <i>status_rx</i> and <i>status_tx</i> will be used to read and write the status register.
                    <i>write_byte</i> and <i>read_byte</i> will be used to test the byte mode,
                    while <i>ram_seq_tx</i> and <i>ram_seq_rx</i> will be used to test the sequential mode.
                    <i>ram_addr</i> will store the address of the data to be written or read.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {status_rd}
                </SyntaxHighlighter>
                <p>
                    The beginning of an SPI transaction is to activate the slave by pulling SS to LOW.
                    Next we can transmit the OPCODE of reading from the STATUS register, followed by the receiving instruction.
                    Finally we can pull SS HIGH telling the slave that the communication is finished.
                    The following code snippet writes the STATUS register.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {status_wr}
                </SyntaxHighlighter>
                <p>Let's write/read a single byte of information to/from address 0x00:</p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {read_write_byte}
                </SyntaxHighlighter>
                <p>
                    As a last test, let's write the data 0xDEADBEEF to address 0x11.
                    First we should change the addressing mode to sequential, then we can write our data in four chunks with only one address byte.
                    The reading will be similar.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {sequential}
                </SyntaxHighlighter>

                <p>
                    If everything went well, you should see the following text on a serial COM port:
                </p>
                <img className="img_spi_com_hal13" src={spi_com} alt="spi_com_hal13"/>
                <p> The source code can be found in <a href="https://gitlab.com/stm32_mcu_group/stm32_hal/13_spi_sram.git">this</a> repo.</p>
            </div>




            <div className="em__post-navigation">

                <NavLink to="./../stm-hal-12">
                    <Button btnID={"leftBTN"} buttonSize="btn--medium"> Previous Post</Button>
                </NavLink>

                <NavLink to="./../stm-hal-14">
                    <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
                </NavLink>
            </div>
        </div>
    );
}

export default MCU_HAL13;