import React from "react";
import "./../hobby.css";
import "./../collection.css";
import {Tag} from "../../../common";
import {useNavigate} from "react-router-dom";
import {useRef} from "react";

function SHOUHUB(){
    const navigate = useNavigate();
    const milkFloatNomadRef = useRef(null);
    const blackYunnanTuoRef = useRef(null);
    const buLangChaTouYS_1Ref = useRef(null);
    const gmWildArborYSRef = useRef(null);
    const HTHgongtingYSRef = useRef(null);
    const cozyRipeYS1Ref = useRef(null);
    const mengkuRipeFLRef = useRef(null);
    const AlchemyRipeYSRef = useRef(null);
    const cozyRipeYS2Ref = useRef(null);
    const goldenMelonRipeYSRef = useRef(null);
    const greenMarkYSRef = useRef(null);
    const laoChaTouKTMRef = useRef(null);
    const daYiHongYuKTMRef = useRef(null);
    const daLongShanFLRef  = useRef(null);
    const dayihongyuKTMRef = useRef(null);

    const tealist = [
        {name:"1. Milk float nomad (ML)", ref:milkFloatNomadRef},
        {name:"2. Black Yunnan tuo cha 96 (ML)", ref:blackYunnanTuoRef},
        {name:"3. 2019 Bu Lang cha tou (YS)", ref:buLangChaTouYS_1Ref},
        {name:"4. Green Miracle wild arbour (YS)", ref:gmWildArborYSRef},
        {name:"5. 2019 Heng Tong Hao Gong Ting tribute (YS)", ref:HTHgongtingYSRef},
        {name:"6. 2020 Cozy ripe cake (YS)", ref:cozyRipeYS1Ref},
        {name:"7. Mengku ripe pu-erh (FL)", ref:mengkuRipeFLRef},
        {name:"8. 2022 Alchemy ripe cake (YS)", ref:AlchemyRipeYSRef},
        {name:"9. 2023 Cozy ripe pu-erh (YS)", ref:cozyRipeYS2Ref},
        {name:"10. 15 years aged golden melon ripe (YS)", ref:goldenMelonRipeYSRef},
        {name:"11. Green Mark ripe raw (YS)", ref:greenMarkYSRef},
        {name:"12. Lao Cha tou blend (KTM)", ref:laoChaTouKTMRef},
        {name:"13. DaYi Hong Yu (KTM)", ref:daYiHongYuKTMRef},
        {name:"14. 2022 Da Long Shan Ripe (FL)", ref:daLongShanFLRef},
        {name:"15. 2023 DaYi Hong Yu (KTM)", ref:dayihongyuKTMRef},
    ];

    return (
        <div className="em__post">
            <div className="em__post-section">
                <h3>About the history and production of shou teas</h3>
                <p>
                    Amidst the mist-shrouded mountains of Yunnan, a remarkable tea unfolds its dark allure — Shou Cha.
                    Known for its earthy richness and deep complexity, Shou Pu'er, often referred to as "ripe" or "cooked" pu'er,
                    stands as a testament to the ingenuity of tea artisans and the transformative power of fermentation.
                    <br/>
                    Shou pu'er's history began in the 1970s as an answer to the demand for an aged pu'er experience without the lengthy natural fermentation process of sheng.
                    Tea masters in Yunnan developed a technique known as "wo dui", or wet piling, to accelerate the fermentation,
                    mimicking the ageing process and transforming raw leaves into a dark, robust tea within a few weeks.
                </p>
                <p>
                    The production process is as follows: pick the leaves, pile and ferment them, dry and compress them, and age them for further development.
                </p>
                <ul>
                    <li>
                        <b>Picking:</b>
                        Just like sheng tea, shou tea starts its journey in the high-altitude tea gardens of Yunnan, where the leaves of Camellia sinensis var. assamica are carefully cultivated.
                        The ideal time for harvest is early spring, ensuring the plucking of tender leaves and buds that will contribute to the tea's complex flavour.
                    </li>
                    <li>
                        <b>Piling and fermentation:</b>
                        The defining step in shou tea production is the wet piling process.
                        After withering and rolling, the leaves are heaped into a pile and kept moist.
                        This environment encourages microbial activity, initiating a controlled fermentation.
                        Over weeks or months, the tea undergoes a transformation, turning from green to dark brown, and the flavours mature, losing the astringency associated with young sheng tea.
                    </li>
                    <li>
                        <b>Drying and compression:</b>
                        Once the desired fermentation level is achieved, the leaves are dried to halt the process.
                        Shou tea can be left as loose leaves, but it is often compressed into cakes, bricks, or nests.
                        This compression not only aids in storage and ageing but also influences the rate at which the tea evolves over time.
                    </li>
                </ul>
                <p>
                    Shou tea offers a distinct and comforting flavour profile.
                    Unlike its sheng counterpart, it lacks the astringency (although some tea can retain a bit of it) and brightness of youth.
                    Instead, it boasts a velvety, smooth texture with earthy, woody, and sometimes sweet notes.
                    With ageing, the tea continues to evolve, developing nuances reminiscent of aged wood, dried fruits, and a unique richness.
                    <br/>
                    While I am a firm believer that there are no bad teas most of the time, in the case of shou tea,
                    depending on the resting time after the wet piling process, there can be some quite nasty ones.
                    If the tea is not rested enough, it has a strong, funky smell-taste of stale fish and rotten wood.
                    It's the teamasters' job to make sure we have a nice kind of ferment instead of the fishy kind.
                    If we want savoury seafood flavour, we buy fish sauce, not tea.
                    <br/>
                    Shou tea shares many health benefits with sheng, including potential digestive and metabolic advantages.
                    The post-fermentation process is believed to break down tea compounds, resulting in the formation of unique
                    bioactive substances that contribute to the tea's distinct character and potential health effects.

                </p>

            </div>

            <div className="em__collection">
                <div className="em__collection-title">
                    <h1 className="gradient__text">Sampled white teas:</h1>
                </div>

                <div className="em__collection-list">
                    {
                        tealist.map((item) => (
                            <div >
                                <Tag title={item.name} description={item.description} onClick={() => item.ref.current.scrollIntoView({ behavior: 'smooth' })}/>
                            </div>
                        ))
                    }
                </div>

                <div className="em__post-section">
                    <div id="1. Milk float nomad (ML)" ref={milkFloatNomadRef}>
                        <h3>1. Milk float nomad (ML)</h3>
                        <p>
                            Gong ting ripe tea pressed into 250g cake.
                            Gong ting teas are more bud-heavy and contain fewer older leaves.
                            The tea is from the Da Ye Zhong cultivar, harvested in spring 2010 from Bulang, Yunnan, China.
                            <br/>
                            The tea material consists of lightly pressed small brown leaves and orange buds with a hazelnut cream smell.
                            After a quick rinse with boiling water, the leaves turned mahogany, and they started to smell like custard and dark chocolate.
                            The tea soup was medium-thick, with a coffee colour and maroon tinge.
                            The brew tasted like biscuits, caramel and old wood.
                            The aftertaste was cooling and sweet.
                            The empty cup smelled like chocolate brownies.

                            I used 5g of leaf in a 100ml gaiwan with boiling water.
                            On average, a session lasted for 10+ brews.
                            <br/>
                            Green/Vegtal: 0/10, Floral: 3/10, Fruits: 3/10, Spices: 6/10, Wood/Earth: 7/10, Roast/Nuts: 3/10, Cream: 6/10, Mineral: 6/10.
                        </p>
                    </div>
                    <div id="2. Black Yunnan tuo cha 96 (ML)" ref={blackYunnanTuoRef}>
                        <h3>2. Black Yunnan tuo cha 96 (ML)</h3>
                        <p>
                            Tuo cha is a smaller compressed tea in comparison to full cakes.
                            They usually change from 7g to 100g.
                            Most of the tunes contain lower-grade leaf material and broken leaves with bitter and/or fishy taste.
                            This tuo is a nice daily drinker.
                            The leaf is from the Da Ye Zhong cultivar, harvested in spring 1996 from Fengqing, Yunnan, China.
                            The weight of the tuos is between 6g and 7.5g on average, and this tea withstands the harshest of brews and just keeps on giving.
                            <br/>
                            The leaves are highly compressed into the tuo shape; they are auburn coloured, with a smoked toffee smell.
                            After a rinse, the leaves uncompressed and release an amazing smell of toffee and charred wood.
                            The tea soup is medium thick, silky and has a mahogany brown colour.
                            The brew tastes like vanilla cake, incense and antique wood.
                            The empty cup smells like loquat syrup after it brews.
                            The aftertaste is creamy and sweet, with an ever-present old-wood scent.

                            I used 5g of leaf in a 100ml gaiwan with boiling water.
                            On average, a session lasted for 10+ brews.
                            <br/>
                            Green/Vegtal: 0/10, Floral: 3/10, Fruits: 3/10, Spices: 6/10, Wood/Earth: 7/10, Roast/Nuts: 3/10, Cream: 6/10, Mineral: 6/10.
                        </p>
                    </div>
                    <div id="3. 2019 Bu Lang cha tou (YS)" ref={buLangChaTouYS_1Ref}>
                        <h3>3. 2019 Bu Lang cha tou (YS)</h3>
                        <p>
                            Cha Tou is a kind of tea nugget that forms naturally from the pressures of compression and heat that occur during the fermentation process.
                            Typically, during the fermentation process, there is a pile of tea about 1 meter high to make ripe pu-erh.
                            It is kept wet to allow the fermentation process, and the pile is turned every few days to allow for an even degree of fermentation,
                            moving the tea from the bottom of the pile (where it is hotter and wetter) to the top of the pile where it is cooler and drier.
                            The cha tou are the leaves that ball up and get stuck together.

                            The leaf was harvested from the Bu Lang area of Menghai County (Xishuangbanna, Yunnan) in 2018 and wet piled in 2019.

                            <br/>
                            The leaves are highly compressed into cedar and amber-coloured nuggets, which smell like raisins and dried figs.
                            After a rinse, the leaves start to loosen up, and the smell changes to gingerbread, sourdough and antique wood.
                            The tea liquor is thick, creamy, and smooth, with a dried orange and rusty colour.
                            The brew tastes like wild cherries, raisins, sweet musk and sandalwood.
                            The empty cup smells like molasses and fresh croissants.
                            The mouth feeling is dry and has a cooling sensation.

                            I used 5g leaf (or there about; I did not break nuggets to get the exact amount) in a 100ml gaiwan with boiling water.
                            On average, a session lasted for 18+ brews.
                            I could not over-brew the tea. First, it was fruity and medium thick, then it became more spicy (vanilla cocoa, wood) and thick.
                            The final notes were of cocoa and cherry.
                            <br/>
                            Green/Vegtal: 0/10, Floral: 3/10, Fruits: 7/10, Spices: 9/10, Wood/Earth: 10/10, Roast/Nuts: 4/10, Cream: 8/10, Mineral: 5/10.
                        </p>
                    </div>
                    <div id="4. Green Miracle wild arbour (YS)" ref={gmWildArborYSRef}>
                        <h3>4. Green Miracle wild arbor (YS)</h3>
                        <p>
                            A wild arbour sourced Ripe Pu-erh tea from two first flushes of Spring 2019 harvested wet-pile batches and pressed into this lovely 357-gram cake.
                            Tea leaves from 50 to 70-year-old tea trees were harvested in April 2019 from Lincang and then wet-piled through the summer.
                            The tea was then aged in loose form for a few months before pressing this year.

                            <br/>
                            The cake was lightly pressed; it had deep purple and chocolate brown leaves and dark golden buds.
                            The smell of the pried leaf was of dark cherries and molasses.
                            After a rinse, the leaves became uniform mahogany brown and smelled like whole grain biscuits, honey and vanilla.
                            The tea liquor is medium thick with a purple-tinged dark orange colour.
                            The brew tasted like slightly old wood mixed with marzipan and rye bread.
                            Slightly bitter, slightly sweet.
                            The empty cup smells ever so slightly dark cane sugar and citrus zest.
                            The mouth finish was sweet and coated, slightly bitter aftertaste.

                            I used 6g leaf in a 100ml gaiwan with boiling water.
                            On average, a session lasted for 10+ brews.
                            <br/>
                            Green/Vegtal: 0/10, Floral: 1/10, Fruits: 3/10, Spices: 8/10, Wood/Earth: 10/10, Roast/Nuts: 2/10, Cream: 8/10, Mineral: 7/10.
                        </p>
                    </div>
                    <div id="5. 2019 Heng Tong Hao Gong Ting tribute (YS)" ref={HTHgongtingYSRef}>
                        <h3>5. 2019 Heng Tong Hao Gong Ting tribute (YS)</h3>
                        <p>
                            This is a gong ting grade tea cake made by Menghai area producer Heng Tong Hao.
                            Pressed from semi-aged gong-ting grade ripe tea leaves (2016 leaf material), this tea cake offers a thick and sweet dried fruit taste with a smooth mouth feel.
                            At the time of offering this (August 2019) tea cake, it is aged enough that there is no real noticeable wet pile taste at all!
                            <br/>
                            This is a tighter-pressed cake, so more careful prying is required.
                            The dry leaves are pecan and hickory coloured with pumpkin buds, and the smell is of burnt caramel, cider, and soy sauce.
                            The wet leaves turn to deep mocha and uber colour with ancient wood and a strong milky smell, with sweet and sour undertones.
                            The tea soup is thick, oily, and smooth, with a dark black coffee colour.
                            The brew tastes like chestnut cream, wheat bread and cocoa.
                            The empty cup smells like maple syrup, and the tea leaves a soft and sweet aftertaste with a warming and calming effect.

                            I used 5g of leaf in a 100ml gaiwan with boiling water.
                            On average, the sessions lasted for 12+ brews.
                            <br/>
                            Green/Vegtal: 0/10, Floral: 0/10, Fruits: 2/10, Spices: 6/10, Wood/Earth: 10/10, Roast/Nuts: 6/10, Cream: 8/10, Mineral: 4/10.
                        </p>
                    </div>
                    <div id="6. 2020 Cozy ripe cake (YS)" ref={cozyRipeYS1Ref}>
                        <h3>6. 2020 Cozy ripe cake (YS)</h3>
                        <p>
                            This is a blended ripe tea that uses cha tou as a heavy material.
                            <br/>
                            The dry leaves are deep chestnut coloured with squash buds, and the smell is of cherries, old wood, and vanilla.
                            The wet leaves turn to a hickory and walnut colour with a butter biscuit and a cinnamon and muscovado smell.
                            The tea soup is thick, coated and has an orange-tinged brown colour.
                            The brew tastes like pomegranate, dark toast, orange rind and brown sugar.
                            The empty cup smells like sour cherries and old wood, and the tea has a long-lasting sweet aftertaste.

                            I used 5g of leaf in a 100ml gaiwan with boiling water.
                            On average, the sessions lasted for 10+ brews.
                            <br/>
                            Green/Vegtal: 0/10, Floral: 0/10, Fruits: 6/10, Spices: 5/10, Wood/Earth: 8/10, Roast/Nuts: 5/10, Cream: 5/10, Mineral: 1/10.
                        </p>
                    </div>
                    <div id="7. Mengku ripe pu-erh (FL)" ref={mengkuRipeFLRef}>
                        <h3>7. Mengku ripe pu-erh (FL)</h3>
                        <p>
                            This tea was fermented in Menghai in 2019 under the supervision of Liu Yang, who specializes in ripe pu-erh production.
                            He used high-quality leaves from the western part of the Mengku area. The tea was aged in cake form until 2023.
                            <br/>
                            The dry tea material consists of auburn and caramel leaves with a generous amount of orange buds.
                            The dry leaves smell like ancient wood and nutmeg.
                            The wet leaves turn to uniform chocolate brown and smell like dark roast coffee and cocoa malt biscuits.
                            The tea soup is thick, oily and smooth with a deep mahogany colour.
                            The brew tastes like aged rum, raisins, chocolate, and ancient wood with a slight sourness.
                            The empty cup smelled like cocoa nibs and molasses.
                            The tea left a deep wood aftertaste with a lingering sweetness.

                            I used 5g of leaf in a 100ml gaiwan with boiling water.
                            On average, the sessions lasted for 8+ brews.
                            <br/>
                            Green/Vegtal: 0/10, Floral: 1/10, Fruits: 4/10, Spices: 6/10, Wood/Earth: 10/10, Roast/Nuts: 4/10, Cream: 5/10, Mineral: 3/10.
                        </p>
                    </div>
                    <div id="8. 2022 Alchemy ripe cake (YS)" ref={AlchemyRipeYSRef}>
                        <h3>8. 2022 Alchemy ripe cake (YS)</h3>
                        <p>
                            This tea is a 2022 ripe blend of various loos tea from Mengku and Menghai with cha tou.
                            This tea was one of my favourite ripe cakes, so much so that I had to buy a second one.
                            <br/>
                            The dry tea material consists of chocolate brown leaves, orange buds and black nuggets.
                            The dry leaves smell like antique woods and rum.
                            The wet leaves are Oreo brown with a malty biscuit, cocoa and hazelnut smell.
                            The tea soup is thick and creamy with dark rum and chocolate liquor colour.
                            The brew tastes like yeast, old wood, and molasses with a slight metallic undertone.
                            The empty cup smelled like chocolate milk.
                            The aftertaste was of dark chocolate with juicy sweetness.

                            I used 5g of leaf in a 100ml gaiwan with boiling water.
                            On average, the sessions lasted for 12+ brews.
                            <br/>
                            Green/Vegtal: 0/10, Floral: 1/10, Fruits: 1/10, Spices: 3/10, Wood/Earth: 10/10, Roast/Nuts: 8/10, Cream: 9/10, Mineral: 5/10.
                        </p>
                    </div>
                    <div id="9. 2023 Cozy ripe pu-erh (YS)" ref={cozyRipeYS2Ref}>
                        <h3>9. 2023 Cozy ripe pu-erh (YS)</h3>
                        <p>
                            Another ripe blend uses cha tou as a heavy material.
                            The blend contains various leaf materials that were wet piled in 2022 from Ma Wei mountains (west of Pu'Er city).
                            The cha tou is a 2018 organic tea material.
                            <br/>
                            The dry tea material consists of auburn leaves and orange buds.
                            The dry leaves smell like old wood, mushrooms and craft chocolate.
                            The wet leaves smell like malty biscuits, autumn forest floor and milk sugar.
                            The tea soup is medium thick with a deep amber-ruby colour.
                            The brew tastes like malty bread, cocoa nibs, toasted sesame, and sourdough with yeasty herbal sweetness.
                            The empty cup smelled like chocolate fudge.
                            The aftertaste was full of lingering sweetness and minerality.

                            I used 5g of leaf in a 100ml gaiwan with boiling water.
                            On average, the sessions lasted for eight brews.
                            <br/>
                            Green/Vegtal: 0/10, Floral: 0/10, Fruits: 2/10, Spices: 4/10, Wood/Earth: 9/10, Roast/Nuts: 9/10, Cream: 6/10, Mineral: 7/10.
                        </p>
                    </div>
                    <div id="10. 15 years aged golden melon ripe (YS)" ref={goldenMelonRipeYSRef}>
                        <h3>10. 15 years aged golden melon ripe (YS)</h3>
                        <p>
                            The tea tuos were pressed in 2005 by a small Menghai factory using Gong Ting, Te Ji grade 1 and 3 leaf material.
                            The tea was aged in Kunming for 12 years in relatively dry conditions.
                            The tuos are roughly 22g each, so one might want to break them before brewing (not pry, break).
                            <br/>
                            The dry tea material consists of broken walnut brown leaves (Initially intact).
                            The dry leaves smell like fermented black beans, cocoa nibs, and coffee.
                            The wet leaves turned dark brown, almost black and smelled like old wood, tamarind, and dark chocolate spread.
                            The tea soup is thick and coated and has a dark orange, murky brown colour.
                            The brew tastes like cocoa, rum, forest berries, and slightly mineral with medicinal bitterness.
                            The empty cup smelled like cocoa.
                            The aftertaste was full of dark chocolate notes and lingering stevia sweetness.

                            I used 5g of leaf in a 100ml gaiwan with boiling water.
                            On average, the sessions lasted for eight brews.
                            <br/>
                            Green/Vegtal: 0/10, Floral: 0/10, Fruits: 2/10, Spices: 4/10, Wood/Earth: 10/10, Roast/Nuts: 9/10, Cream: 10/10, Mineral: 5/10.
                        </p>
                    </div>
                    <div id="11. Green Mark ripe raw (YS)" ref={greenMarkYSRef}>
                        <h3>11. Green Mark ripe raw (YS)</h3>
                        <p>
                            This is a strange blended pu'er.
                            There is a sheng and shou component in it.
                            It's not so different from ripe pu-erh tea, but there is an additional layer of texture that enhances the overall taste/complexity of the tea but never detracts from it.
                            The raw tea is a 2016 harvested wild pu'erh from Huang Shan, near Da Hu Sai, Mengku, Lincang.
                            The ripe tea is from the same area but is made of 2015 material.
                            <br/>
                            The dry tea material contains clay-colored buds and hickory-like ripe leaves, together with grey buds and khaki raw leaves.
                            The site says that it's predominantly ripe tea. From the sample, I have the feeling that this number is around 80-85%.
                            The smell of the heated leaves was of dark chocolate and bitter almonds.
                            The wet leaves smelled like ancient wood, vanilla and cornel berries.
                            The tea has a medium texture, is smooth, and has a coating.
                            I've brewed around ten infusions with it, and the first six were like a peculiar ripe tea:
                            Brown sugar, crème brûlée, and citrus zest with the usual ancient wood taste.
                            It also had a nice dandelion stem bitterness, which transformed into a long-lasting sweet aftertaste.
                            After the 6th infusion, the tea soup started to clear out. I guess that the ripe power mostly ran out.
                            This time, the tea had the taste of a semi-young purple tea.
                            Plums, malt, whole grain bars, medicinal bitterness and some residual yeas-like taste.
                            Overall, I loved this ever-changing experience.
                            If I ever was in a state where I could not decide whether to drink raw or ripe, this tea would be pretty high on the list.

                            I used 6.5g of leaf in a 100ml gaiwan with boiling water.
                            On average, the sessions lasted for eight brews.
                            <br/>
                            Green/Vegtal: 2/10, Floral: 2/10, Fruits: 7/10, Spices: 5/10, Wood/Earth: 4/10, Roast/Nuts: 3/10, Cream: 5/10, Mineral: 7/10.
                        </p>
                    </div>
                    <div id="12. Lao Cha tou blend (KTM)" ref={laoChaTouKTMRef}>
                        <h3>12. Lao Cha tou blend (KTM)</h3>
                        <p>
                            This blend follows a specific recipe, primarily using tea leaves from the Meng Hai tea region,
                            with additional selections from the Mo Jiang area of the Pu Er region and the Lin Cang region.
                            <br/>
                            The tea material consists of walnut-coloured nuggets that have some burgundy spots and smell like old wood and vanilla.
                            The wet leaves are viscous umber and chocolate colour with roast mushroom, musty cellar and burnt sugar smell.
                            The liquor was ruby-tinged ebony even at the first infusion, with a brothy, medium thickness.
                            The brew tasted like cacao nibs, rie bread toast, spent coffee grounds, allspice and dates.
                            The smell of the empty cup was caramel and molasses.
                            The tea left a mineral aftertaste and lingering sweetness.

                            I used around 7g of nuggets in a 100ml gaiwan with boiling water.
                            On average, the sessions lasted for 16+ brews.
                            <br/>
                            Green/Vegtal: 0/10, Floral: 0/10, Fruits: 3/10, Spices: 7/10, Wood/Earth: 9/10, Roast/Nuts: 2/10, Cream: 5/10, Mineral: 3/10.
                        </p>
                    </div>
                    <div id="13. DaYi Hong Yu (KTM)" ref={daYiHongYuKTMRef}>
                        <h3>13. DaYi Hong Yu (KTM)</h3>
                        <p>
                            "Hong Yu" is a series designed for the daily consummation of shou puerh tea from the DaYi Menghai tea factory
                            that was stored in Guangzhou

                            <br/>
                            The tea material consists of large chocolate leaves with a purple tinge and orange buds that smell like chestnut and old wood.
                            After a rinse, the leaves turned a uniform ebony and smelled like wet cardboard and sweet molasses.
                            The liquor was thick and coated with deep copper and ruby-tinged colour.
                            The brew tasted like burnt brandy, pine resin, and cocoa nibs with a mineral and a slightly effervescent feel.
                            The smell of the empty cup was reminiscent of chestnut purée.
                            The tea left a long-lasting mineral and woody aftertaste with a slight sweetness.

                            I need to get my hands on a full cake for further testings/tastings.

                            I used around 7g of nuggets in a 100ml gaiwan with boiling water.
                            On average, the sessions lasted for 11+ brews.
                            <br/>
                            Green/Vegtal: 0/10, Floral: 0/10, Fruits: 3/10, Spices: 2/10, Wood/Earth: 9/10, Roast/Nuts: 7/10, Cream: 6/10, Mineral: 6/10.
                        </p>
                    </div>
                    <div id="14. 2022 Da Long Shan Ripe (FL)" ref={daLongShanFLRef}>
                        <h3>14. 2022 Da Long Shan Ripe (FL)</h3>
                        <p>
                            A tea with a classic Menghai taste.
                            The tea material was harvested from Da Long Shan and fermented in the winter of 2022.
                            The heavy fermentation gave a very dark tea soup and chocolate fragrance.
                            <br/>
                            The tea material consists of chocolate and hazelnut medium-sized leaves and orange buds.
                            I would not call this tea gong ting, but the abundance of buds gives a nice view.
                            The dry leaves smell like nutmeg, mascarpone and old wood.
                            After a rinse, the leaves turned uniform dark chocolate and smelled like rum, cocoa and yeast.
                            The liquor was thick, creamy, and almost oily with a deep, opaque pecan colour.
                            The brew tasted like cream, cacao, and raisins; it was bitter and sweet with clay minerality.
                            The smell of the empty cup was rum and vanilla.
                            The tea left a long-lasting mineral and herbal bitter-sweet aftertaste.

                            I used around 6g of nuggets in a 100ml gaiwan with boiling water.
                            On average, the sessions lasted for 12 brews.
                            <br/>
                            Green/Vegtal: 0/10, Floral: 0/10, Fruits: 2/10, Spices: 7/10, Wood/Earth: 10/10, Roast/Nuts: 3/10, Cream: 8/10, Mineral: 7/10.
                        </p>
                    </div>
                    <div id="15. 2023 DaYi Hong Yu (KTM)" ref={dayihongyuKTMRef}>
                        <h3>15. 2023 DaYi Hong Yu (KTM)</h3>
                        <p>
                            "Hong Yu" is a series designed for the daily consumption of shou puerh tea.
                            Thick, mellow and smooth in taste.
                            Caramel-like sweetness.
                            Unique shou tea fragrance.
                            Soothing tea flavour.
                            Guangzhou Natural Storage.
                            <br/>
                            This cake was a tightly pressed factory cake, which is not my favourite cake since oftentimes I stab myself prying chunks off.
                            The tea cake has mahogany and plum leaves with orange buds. The smell of the dry leaves is cacao nibs, coffee, and hay with sweet undertones.
                            The wet leaves took up a uniform dark chocolate colour and smelled like choco milk, cream and raspberry.
                            The liquor was dark chestnut, thick and syrupy.
                            The brew tasted like bitter chocolate and raspberry jam, full of sweetness and minerality.
                            Latter brews were woody with hints of berries.
                            The empty cup smelled of honey and old, decomposing wood.
                            The aftertaste was sweet and bitter with hints of sourness.

                            For its price of $16 (as of 2023), this ripe tea is one of the best ripes out there, well over its price range.
                            <br/>
                            I used around 6g of tea in a 100ml gaiwan with boiling water.
                            On average, the sessions lasted for 10+ brews.
                            <br/>
                            Green/Vegtal: 0/10, Floral: 0/10, Fruits: 6/10, Spices: 6/10, Wood/Earth: 3/10, Roast/Nuts: 0/10, Cream: 2/10, Mineral: 4/10.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SHOUHUB;
