import React from "react";
//import {NavLink} from "react-router-dom";
import "./teaching.css";

function Teaching(){
    return (
        <div>
            Lectures
        </div>
    );
}

export default Teaching;

