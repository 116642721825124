import React from 'react';
import './header.css';
import matrix_anim from './../assets/matrix_anim.svg';

function Header(){
    return(
        <div className="em__header section__padding" id="home">
            <div className="em__header-content">
                <h1 className="gradient__text">
                    Let's learn something new together with the Embedded Musician
                </h1>
                <p>
                    This is the personal website of the Embedded Musician, where you can find tutorials, blogs, hacks,
                    lectures and lab notes about a multitude of fileds in computer science, control systems and
                    embedded engineering.
                </p>
            </div>
            <div className="em__header-image">
                <img src={matrix_anim} alt="matrix_anim"/>
            </div>
        </div>
    );
}

export default Header;
