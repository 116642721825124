import React from "react";
import "./mcu_hal12.css";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";
import {MathJax, MathJaxContext} from "better-react-mathjax";

import stm_prog from "./stm_prog.jpg";
import i2c_slave_setup from "./i2c_slave_setup.jpg";

function ShowTex({string}){
    const config = {
        loader: { load: ["[tex]/html"]},
        tex: {packages: {"[+]": ["html"]},
            inlineMath: [["$", "$"]],
            displayMath: [["$$", "$$"]]
        }
    };

    return(
        <MathJaxContext config={config} version={3}>
            <MathJax dynamic inline>
                {string}
            </MathJax>
        </MathJaxContext>
    );
}

const i2c_buffers = `
#define TXBUFFERSIZE                      4
#define RXBUFFERSIZE                      TXBUFFERSIZE

uint8_t aTxBuffer[4] = {0xDE, 0xAD, 0xBE, 0xEF};
uint8_t aRxBuffer[RXBUFFERSIZE] = {0};
`;

const i2c_slave_rxtx = `
 if(HAL_I2C_Slave_Receive_IT(&hi2c1, aRxBuffer, RXBUFFERSIZE) != HAL_OK)
      Error_Handler();
  while(HAL_I2C_GetState(&hi2c1) != HAL_I2C_STATE_READY){}

  if(HAL_I2C_Slave_Transmit_IT(&hi2c1, aTxBuffer, TXBUFFERSIZE) != HAL_OK)
      Error_Handler();
  while(HAL_I2C_GetState(&hi2c1) != HAL_I2C_STATE_READY){};
`;

const buffer_cmp = `
static uint16_t BufferCMP(uint8_t *pBuff1, uint8_t *pBuff2, uint16_t buffLen){
    while(buffLen--){
        if(*pBuff1 != *pBuff2)
            return buffLen;
        pBuff1++;
        pBuff2++;
    }
    return 0;
}
`;

const buffer_cmp_usage = `
  if(BufferCMP(aTxBuffer, aRxBuffer, RXBUFFERSIZE))
      Error_Handler();
`;

const error_handler = `
void Error_Handler(void)
{
  __disable_irq();
  while (1)
  {
      HAL_GPIO_TogglePin(LD2_GPIO_Port, LD2_Pin);
      HAL_Delay(1000);
  }
}
`;

const i2c_master_tx = `
 while(HAL_GPIO_ReadPin(B1_GPIO_Port, B1_Pin) != GPIO_PIN_SET){}
  HAL_Delay(50);
  while(HAL_GPIO_ReadPin(B1_GPIO_Port, B1_Pin) != GPIO_PIN_RESET){}

  do{
      if(HAL_I2C_Master_Transmit_IT(&hi2c1, 0xd2, aTxBuffer, TXBUFFERSIZE) != HAL_OK)
          Error_Handler();

      while(HAL_I2C_GetState(&hi2c1) != HAL_I2C_STATE_READY){}

  }while(HAL_I2C_GetError(&hi2c1) == HAL_I2C_ERROR_AF);
`;

const i2c_master_rx = `
while(HAL_GPIO_ReadPin(B1_GPIO_Port, B1_Pin) != GPIO_PIN_SET){}
  HAL_Delay(50);
  while(HAL_GPIO_ReadPin(B1_GPIO_Port, B1_Pin) != GPIO_PIN_RESET){}

  do{
    if(HAL_I2C_Master_Receive_IT(&hi2c1, 0xd2, aRxBuffer, RXBUFFERSIZE) != HAL_OK)
        Error_Handler();

    while(HAL_I2C_GetState(&hi2c1) != HAL_I2C_STATE_READY){}

  }while(HAL_I2C_GetError(&hi2c1) == HAL_I2C_ERROR_AF);
`;


function MCU_HAL12(){
    return(
        <div className="em__post">
            <div className="em__post-title">
                <h1>I2C Master & Slave - Board to Board communication</h1>
            </div>

            <div className="em__post-section">
                <h3>Aim of this tutorial:</h3>
                <p>
                    In this tutorial, we will use two NUCLEO-G491RE boards for data transmission and reception.

                </p>
            </div>

            <div className="em__post-section">
                <h3>Physical and logical setup:</h3>
                <p>
                    Obviously, we need two STM32 boards. I will be using two NUCLEO-G491RE boards for this purpose,
                    but as long as we intend to use the HAL library, there shouldn't be any major differences between different STM32 ICs.
                    The I2C bus is located on the pins 3 and 5 on the header CN10.
                </p>
                <p>
                    Theoretically we could power the boards from the USB power, and there would also be a GND connection for the I2C reference,
                    but this would create a huge ground loop which would pick up all the EM noise.
                    The master board will supply the power, so connect pin 20 from CN7 from each board (common GND),
                    and pin 18 from CN7 (+5V Source from master board) to pin 22 from CN7 (Vin for slave board).
                    Also move the jumper on JP5 from the 4th position to the 3rd position to switch supplies.
                </p>
                <p>
                    We could create a single project for the master and slave using <i>#define</i> and <i>#ifdef</i> directives, but it would be a pain to program and debug the MCUs.
                    For this reason, start the <b>STM32CubeProgrammer</b>, set to ST-LINK mode, and note down the serial number of the on-board programmer.
                    My boards have the serial numbers: 002C00483438510F34313939 for the master, 0032004F3438510F34313939 for the slave.
                    We will use these numbers to distinguish the boards when we need to program them.
                </p>
                <Popup trigger={<img className="img_stm_prog_hal12 clickable_img" src={stm_prog} alt="stm_prog_hal12"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={stm_prog} alt="stm_prog_hal12" />
                    )}
                </Popup>
            </div>

            <div className="em__post-section">
                <h3>Project Setup (Slave):</h3>
                <p>
                    Create a new project in CLion using the default board setup with external oscillator.
                    Enable the I2C from the Connectivity category.
                    Use standard speed mode (you could try the fast or fast plus, theoretically the wire parasitics should not have an influence on signal integrity up to 2MHz).
                    Specify an address in the Slave Features/Primary slave address. I've used 0x69 here.
                    Also, enable the I2C interrupts.
                </p>
                <Popup trigger={<img className="img_i2c_slave_setup_hal12 clickable_img" src={i2c_slave_setup} alt="i2c_slave_setup_hal12"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={i2c_slave_setup} alt="i2c_slave_setup_hal12" />
                    )}
                </Popup>
                <p>
                    Create the project and copy the cfg file as usual.
                    So far, we did not have to modify the cfg file, since there was only one board used.
                    Open the cfg, and paste the line <i>hla_serial xxxxxxxxxxxxxxxxxxxxxxxx</i>, where the x is the 24 character long serial number of the slave device.
                    This way OpenOCD knows which board should be used.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Firmware (Slave):</h3>
                <p>
                    Let's define a receive and transmit buffer size.
                    Here I'm using 4 bytes for both of them.
                    Also, we should create the buffers.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {i2c_buffers}
                </SyntaxHighlighter>

                <p>
                    We want to test the reception and the transmission, so first we listen to the master using and interrupt based receiving routine.
                    Next, when the I2C is ready we will transmit our data to the master.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {i2c_slave_rxtx}
                </SyntaxHighlighter>
                <p>
                    Just to be sure that the data exchange is successfully, we can create a compare function that we should execute after the transmission.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {buffer_cmp}
                </SyntaxHighlighter>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {buffer_cmp_usage}
                </SyntaxHighlighter>

                <p>
                    We can check the data, process it or handle the i2c errors with the help of the interrupt routines.
                    The <i>HAL_I2C_SlaveTxCpltCallback</i> is called when a TX operation is completed,
                    this routine could be used to change the data pointer in case of a block transfer operation.
                    The <i>HAL_I2C_SlaveRxCpltCallback</i> is called when an RX operation is completed,
                    this routine could be used to make decision based on the request of the master.
                    We can check if the master is referring to the current slave with the <i>HAL_I2C_AddrCallback</i> function.
                    If there is an error in the I2C communication, we could use the function <i>HAL_I2C_ErrorCallback</i>.
                </p>

                <p>
                    For this example, I've only toggled the on-board LED in the completion callbacks.
                    In the error callback, I've called the <i>Error_Handler</i>, which flashed the LED with a period of 2s.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {error_handler}
                </SyntaxHighlighter>
                <p>
                    Finally, if everything went well, the program should enter the main loop, where I toggle the LED with a period of 1s.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Project Setup (Master):</h3>
                <p>
                    The setup for the master is pretty similar to the setup of the slave.
                    Create a new project in CLion using the default board setup with external oscillator.
                    Enable the I2C from the Connectivity category.
                    Use the same speed as in the case of the slave.
                    Here we don't need a slave address, but we need the interrupts.
                </p>
                <p>
                    From the last tutorial we know that the I2C is an open drain communication, so we need some pull-up resistors.
                    In System Core/GPIO/I2C enable the pull-up resistor for both SCL and SDA pins.
                    Generate the project.
                </p>
                <p>
                    After the project is created, open the cfg file and add specify the serial number of the on-board programmer.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Firmware (master):</h3>
                <p>
                    In the main.c file we can create the same buffers as in the case of the slave (Hopefully the data did not dilate while travelling in the wire).
                </p>
                <p>
                    The transmission is done after we push the user button.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {i2c_master_tx}
                </SyntaxHighlighter>
                <p>
                    When the transmission is over, the mcu can wait for another button event, and it can start to receive data.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {i2c_master_rx}
                </SyntaxHighlighter>
                <p>
                    The remainder part is the exact copy of the slave.
                    When the reception is complete, we compare our data with the received one.
                    If it matches the expectation, we can toggle the LED with a period of 1s.
                    Otherwise the error handler will toggle the LED with a period of 2s.
                </p>
                <p>
                    In case of the master, we have 3 important interrupt routines:
                    <i>HAL_I2C_MasterTxCpltCallback</i>, <i>HAL_I2C_MasterRxCpltCallback</i>, <i>HAL_I2C_ErrorCallback</i>.
                    Since the master device doesn't have an address, and it doesn't have to listen on the line, these interrupts are not implemented.
                </p>
            </div>

            <div className="em__post-section">
                <h3>The test:</h3>
                <p>
                    After everything is connected and programmed, we can start the test.
                    The boards are configured:
                </p>
                <ul>
                    <li> The slave is waiting for data, the master is waiting for a button event. Push the button.</li>
                    <li>If the data transmission is successful, the LEDs should light up both on the master and on the slave.</li>
                    <li>The slave is ready to send, the master is waiting for a button event. Push the button.</li>
                    <li>If the data transmission is successful, the LEDs should turn off for a brief moment.</li>
                    <li>If the RX/TX data matches, then the LEDs should blink with a period of 1s, otherwise the blink period should be 2s.</li>
                </ul>
                <p>
                    The source files for this tutorial can be found in <a href="https://gitlab.com/stm32_mcu_group/stm32_hal/12_i2c_master_slave">this</a> repo.
                </p>
            </div>


            <div className="em__post-navigation">

                <NavLink to="./../stm-hal-11">
                    <Button btnID={"leftBTN"} buttonSize="btn--medium"> Previous Post</Button>
                </NavLink>

                <NavLink to="./../stm-hal-13">
                    <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
                </NavLink>
            </div>
        </div>
    );
}

export default MCU_HAL12;