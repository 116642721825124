import React from "react";
import "./../posts.css";
import "./fpga_1.css";
import fpga_ax from "./fpga_ax.jpg";
import fpga_prog from "./fpga_prog.jpg";
import program_soft from "./program_soft.jpg";
import {Button} from "../../../../common";
import {NavLink} from "react-router-dom";


function FPGA_1(){
    return(
        <div className="em__post">
            <div className="em__post-title">
                <h1>Hands on a tiny and affordable FPGA board</h1>
            </div>
            <div className="em__post-section">
                <h3>Aim of this post:</h3>
                <p>
                    This page will be more of a first impression kind of page than a tutorial.
                    We will look at a cheap but powerful FPGA board, which has fully open source hardware.
                </p>
            </div>
            <div className="em__post-section">
                <h3>A short intro for FPGAs:</h3>
                <p>
                    These strange devices called CPLDs (Complex Programmable Logic Devices),
                    and FPGAs (Field Programmable Gate Arrays) are different than the more known microcontrollers.
                    The later ones have fixed hardware, and the user creates a firmware that runs on the chip, which tells how the chip will use its resources.
                    The former is like a blank canvas, where the user has to create the hardware and optionally the software too.
                    There is no processor - until you design one.
                </p>
                <p>
                    The FPGA has certain hardware-level flexibility, which allows the user to reconfigure the hardware rather than replace it.
                </p>
                <p>
                    The downside is the high cost of such chips, even higher cost of development boards -
                    since FPGAs usually need multiple power rails, matched signal paths and multiple layers for signal integrity,
                    and external memory units, oh, and these pesky devices come in a not so user-friendly BGA packages.
                    And the worst of the downsides is the steep learning curve of the design language and method of thinking.
                </p>
                <p>
                    So far the reader might think that the downside far outweighs the upside, but here comes the TinyFPGA board.
                </p>
            </div>
            <div className="em__post-section">
                <h3>
                    About the Tiny FPGA boards
                </h3>
                <p>
                    So far I've been working with Basys2, Basys3, and ZYBO development boards from
                    Digilent (using Spartan3, Artix-7 and Zynq-7000 chips from Xilinx), Waxwing board from NumatoLabs (using Spartan6 from Xilinx).
                    These are all powerful FPGA boards and have two things in common:
                    their high price range, and the fact that I never managed to use the fabric to the full capacity.
                </p>
                <img className="img_fpga_ax" src={fpga_ax} alt="fpga_ax" />
                <p>
                    And finally we arrived to the&nbsp;<a href="https://tinyfpga.com/">TinyFPGA</a>&nbsp;boards, which have fully open source hardware design.
                    These boards incorporate FPGAs from&nbsp;<a href="https://www.latticesemi.com/">Lattice Semiconductor</a>.
                    The A1 and A2 boards have MachXO2-256 and MachXO2-1200, the B board has an ICE40LP8K and the EX board has (will have?) an LFE5U-25F FPGA chip.
                    The upside of these FPGA chips is that the Mach and ICE ones also come in QFN packages, which can be hand soldered.
                    Another upside is that these chips have an internal flash, internal oscillator and require fewer voltage rails, which further simplify the board design process.
                </p>

                <p>
                    While the A boards must be programmed with a JTAG programmer (which incidentally is made with a low-cost PIC16LF1455,
                    and it is released as open source hardware and firmware), the B devices are pre programmed with a USB bootloader.
                </p>

                <p>
                    Here you can see the TinyFPGA A2 board, which truely lives up to its name.
                    Every component is on the top side of a 3.05 cm x 1.8 cm PCB.
                    The FPGA has 1280 Look Up Tables (LUTs), 10kb of distributed RAM, 64kb of ERB SRAM,
                    64kb FLASH, 18 I/O, 1 hard SPI, 2 hard I2C, 1 PLL, 1 hard Timer/Counter IP, all with WishBone interface.
                    The datasheet for the MachXO2 devices can be found&nbsp;
                    <a href="https://www.latticesemi.com/~/media/LatticeSemi/Documents/DataSheets/MachXO23/MachXO2FamilyDataSheet.pdf?document_id=38834">here</a>.
                    This particular board also comes with some bad looking lead-free solder joints, but that is more of a user (I was in a hurry) error than a provider one.
                </p>
                <img className="img_fpga_prog" src={fpga_prog} alt="fpga_prog" />
                <p>And here is the programmer, which is a little beauty in itself.</p>
            </div>
            <div className="em__post-section">
                <h3>FPGA testing</h3>
                <p>First I've downloaded and installed the Lattice Diamond development environment with service pack 2 (I also requested a free license).
                    After that, I've downloaded the programmer application from the git-hub repository, which is a simple python program with a GUI.
                    Finally, I've downloaded a LED blinking project to test the FPGA and the programmer.</p>
                <p>When I connected the USB programmer, Windows automatically recognized it and installed the required drivers.</p>
                <p>The Demo project used the internal oscillator as a clock source for a 24b up counter module.
                    The last 3 bits of the counter module were fanned out to 3 LEDs (an RGB LED in my case).
                    Diamond successfully synthesized and routed the design, and finally, it produced the JEDEC file
                    (this programmer uses the JEDEC file instead of the bitstream file). </p>
                <img className="img_program_soft" src={program_soft} alt="program_soft" />
                <p>The programmer application easily recognized the TinyFPGA programmer in COM7 port.
                    The file uploading/programming step was fast, and successful. </p>
                </div>

            <div className="em__post-section">
                <p><a href="https://imgur.com/gallery/mYZTWR6">Here</a>&nbsp; we can see the FPGA driving the RGB LED (There are other things on the breadboard, which will become relevant in the upcoming tutorials).</p>
                <p><i>Final notes:</i> All in all these small Lattice FPGAs are ideal for people who are getting started with FPGA design, or for people who are in need of a cheap glue logic source.
                    The small PCB (breadboard friendly) with minimal components and cost further improves the possibilities.
                    In the upcoming tutorials, we will see, that this little beast can also shine in more complex tasks such as in need of DSP/Control HW acceleration, or soft processing.</p>

            </div>
            <div className="em__post-navigation">

                <NavLink to="./..">
                    <Button btnID={"leftBTN"} buttonSize="btn--medium"> Projects</Button>
                </NavLink>

                <NavLink to="./../fpga-tut-2">
                    <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
                </NavLink>
            </div>
        </div>
    );
}

export default FPGA_1;