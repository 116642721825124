import React from "react";
import "./../posts.css";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";

function FPGA_2(){
    return(
        <div className="em__post">
            <div className="em__post-title">
                <h1>First steps in FPGA development</h1>
            </div>
            <div className="em__post-section">
                <h3>Aim of this post:</h3>
                <p>
                    This page will serve as a prerequisite page for general FPGA development hardware and software environment setup.
                </p>
            </div>
            <div className="em__post-section">
                <h3>Hardware setup:</h3>
                <p>
                    The most important part of the setup is an FPGA development board (duh), with a breadboard.
                    Next, a programmer is needed to configure the internal structure of the IC.
                    The most basic way to program an FPGA when debugging is to use a Joint (not that joint) Test Action Group (JTAG) programmer.
                    If the FPGA has an external FLASH memory, that can be programmed through SPI communication, and the FPGA will boot from the FLASH.
                    There are other options such as using an eMMC flash, SD card, USB, or Ethernet but each of these options requires a pre-programmed soft or hard boot-loader IP.
                    Finally (optionally) we would need a logic analyser for external signal debugging, and a JTAG debugger for internal signal debugging.
                </p>
            </div>
            <div className="em__post-section">
                <h3>Software setup:</h3>
                <p>
                    The first thing we need is a text editor, preferably something more advanced than Notepad.&nbsp;
                    <a href="https://www.sublimetext.com/">Sublime Text</a> &nbsp;is a viable option, since it has syntax highlight function, and it's cross-platform.
                    Another great option is &nbsp;<a href="https://atom.io/">Atom</a>, which is somewhat new, but it's evolving fast.
                    Finally, if we want to use the editor for FPGA/SoC development, then there is &nbsp;<a href="https://www.sigasi.com/">Sigasi Studio</a>.
                    It has syntax highlighting option, auto complete functionality, function navigation, refactoring, etc.
                    It's a nice and complete IDE for (System)Verilog and VHDL.
                </p>
                <p>
                    Small trivia: I usually try to avoid using the editors provided by any kind of FPGA manufacturer.
                    For some reason their IDEs always lack in responsiveness, are mostly bug infested, and look like they are stuck in the late 90s.
                    Just give me a command line interface and let me have my own modern editors (and no, Schematic modes are not better).
                </p>
                <p>
                    Still, download their IDE, because we will need the toolchain for project synthesis.
                    I will mostly use Lattice FPGAs, but the projects can be tested on any other FPGAS (if it fits, it will be good).
                </p>
                <p>
                    For software simulations, we can use ActiveHDL or ModelSim.
                </p>
            </div>
            <div className="em__post-section">
                <h3>My setup for the Tiny FPGA board:</h3>
                <p>
                    I will use the TinyFPGA A2 board in a breadboard, with it's programmer.
                    For internal debugging I will be using an FT2232 based board.
                    For external debugging I'll use an EDUX1002A oscilloscope and a Tenma UTG1005A function generator if needed.
                    I will write the code in Verilog HDL in Sigasi Studio, and synthesize the loadable files with Lattice Diamond with it's ActiveHDL simulator.
                    I will mention other specific software usages on the go, in the case of more advanced tutorials.
                </p>
            </div>

            <div className="em__post-navigation">

                <NavLink to="./../fpga-tut-1">
                    <Button btnID={"leftBTN"} buttonSize="btn--medium"> Previous Post</Button>
                </NavLink>

                <NavLink to="./../fpga-tut-3">
                    <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
                </NavLink>
            </div>

        </div>
    );
}

export default FPGA_2;