import React from "react";
import "./mcu_hal27.css";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";

import usb_hid_dev_mx from "./usb_hid_dev_mx.jpg";

const device_global = `
volatile uint8_t button_flag = 0;
uint8_t buffer[4];

extern USBD_HandleTypeDef hUsbDeviceFS;
`;

const device_bt_isr = `
void HAL_GPIO_EXTI_Callback(uint16_t GPIO_Pin){
    if(GPIO_Pin == BT1_Pin)
        button_flag |= 0x01;
    if(GPIO_Pin == BT2_Pin)
        button_flag |= 0x02;
    if(GPIO_Pin == BT3_Pin)
        button_flag |= 0x04;
    if(GPIO_Pin == BT4_Pin)
        button_flag |= 0x08;
    if(GPIO_Pin == BT5_Pin)
        button_flag |= 0x10;
}
`;

const device_locals = `
int8_t cursor_x = 0, cursor_y = 0, button_m = 0;
`;

const device_loop = `
if(button_flag != 0){
  SSD1306_GotoXY(5,25);
  switch (button_flag) {
      case 0x01:
          // here we increase mouse X position
          SSD1306_Puts("R button pressed.", &font_7x10, SSD1306_COLOR_WHITE);
          cursor_x = 10;
          cursor_y = 0;
          button_m = 0;
          break;
      case 0x02:
          // here we decrease mouse Y position
          SSD1306_Puts("D button pressed.", &font_7x10, SSD1306_COLOR_WHITE);
          cursor_x = 0;
          cursor_y = 10;
          button_m = 0;
          break;
      case 0x04:
          // here we decrease mouse X position
          SSD1306_Puts("L button pressed.", &font_7x10, SSD1306_COLOR_WHITE);
          cursor_x = -10;
          cursor_y = 0;
          button_m = 0;
          break;
      case 0x08:
          // here we increase mouse Y position
          SSD1306_Puts("U button pressed.", &font_7x10, SSD1306_COLOR_WHITE);
          cursor_x = 0;
          cursor_y = -10;
          button_m = 0;
          break;
      case 0x10:
          // here we create LMB button event
          SSD1306_Puts("M button pressed.", &font_7x10, SSD1306_COLOR_WHITE);
          cursor_x = 0;
          cursor_y = 0;
          button_m = 1;
          break;
      default:
          // some dumb error.......
          SSD1306_Puts("0 button pressed.", &font_7x10, SSD1306_COLOR_WHITE);
          break;
  }
  SSD1306_UpdateScreen();
  buffer[0] = button_m;
  buffer[1] = cursor_x;
  buffer[2] = cursor_y;
  buffer[3] = 0;
  USBD_HID_SendReport(&hUsbDeviceFS, buffer, 4);


  HAL_Delay(500);
  SSD1306_Fill(SSD1306_COLOR_BLACK);
  SSD1306_UpdateScreen();
  button_flag = 0;
}
`;

const host_global = `
int32_t uart_length = 0;
uint8_t uart_tx_buffer[100];
extern HID_MOUSE_Info_TypeDef mouse_info;
`;

const host_callBack = `
void USBH_HID_EventCallback(USBH_HandleTypeDef *phost){
    HID_KEYBD_Info_TypeDef *keybd_info;
    uint8_t keycode;
    HID_HandleTypeDef *HID_Handle = (HID_HandleTypeDef*) phost->pActiveClass->pData;

    if(HID_Handle->Init == USBH_HID_KeybdInit){
        keybd_info = USBH_HID_GetKeybdInfo(phost);
        keycode = USBH_HID_GetASCIICode(keybd_info);
        uart_length = sprintf((char*)uart_tx_buffer, "Key pressed: 0x%x\\r\\n", keycode);
    }else if(HID_Handle->Init == USBH_HID_MouseInit){
        USBH_HID_GetMouseInfo(phost);
        uart_length = sprintf((char*)uart_tx_buffer, "Mouse x=0x%x, y=0x%x, bt1=0x%x, bt2=0x%x, bt3=0x%x\\r\\n",
                              mouse_info.x, mouse_info.y, mouse_info.buttons[0], mouse_info.buttons[1],
                              mouse_info.buttons[2]);

    }
    HAL_UART_Transmit(&huart2, uart_tx_buffer, uart_length, 1000);

}
`;


function MCU_HAL27(){
    return(
        <div className="em__post">
            <div className="em__post-title">
                <h1>USB human interface device</h1>
            </div>

            <div className="em__post-section">
                <h3>Aim of this tutorial:</h3>
                <p>
                    In this tutorial, we will continue our jurney in the lands of the USB with the Human Interface Device (HID) class.
                    We will configure the NUCLEO-G491RE board to act as a mouse (HID device) so that we can move the cursor on the pc.
                    We will also try out the host functionality with the NUCLEO-F411RE by connecting a mouse to its makeshift host USB port.
                </p>
            </div>

            <div className="em__post-section">
                <h3>About the USB HID:</h3>
                <p>
                    USB human interface device (HID) class is another part of the USB specification for computer peripherals.
                    It contains devices such as mice, keyboards, game controllers, scanners, bluetooth/WiFi modules etc.
                    While the CDC/VCP was based on bulk data transfer, HID is based on interrupt data transfer.
                    This means that the device only prepares the data that must be sent and
                    the data transfer only occurs when the host generates an interrupt.
                    This limits the data transfer speed to ~64kB/s.
                    The packet size is fixed (it is described in the descriptor file).
                    <a href="https://www.usb.org/hid">Here</a> you can learn more about the USB HID class.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Device configuration:</h3>
                <p>
                    Create a new project for the NUCLEO-G491RE board with debug ports enabled.
                    I'm using I2C3 to show button status texts on an OLED screen,
                    also I enabled 5 external interrupt sources that will be triggered by buttons.
                    <br/>
                    In the Connectivity section, enable the USB FS device with default settings.
                    In the Middleware section, enable the USB device as human interface device class and leave the default settings.
                </p>
                <Popup trigger={<img className="img_usb_hid_dev_mx_hal27 clickable_img" src={usb_hid_dev_mx} alt="usb_hid_dev_mx_hal27"/>} modal nested>
                    {
                        close =>(
                            <img className="em__img_full" src={usb_hid_dev_mx} alt="usb_hid_dev_mx_hal27"/>
                        )
                    }
                </Popup>
                <p>
                    This time we don't need to increase the heap and stack size, so just generate the project.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Device firmware:</h3>
                <p>
                    First, go to  <i>USB_Device/App/usbd_desc.c</i>.
                    This is the device descriptor file.
                    Here you can find the vendor id, product id, the device and manufacturer name strings.
                    The generated HID device will be a mouse, but if you would like to change this,
                    the <i>USBD_HID_DeviceDesc</i> array needs to be changed according to your specifications.
                    Either way, go to the main source and include <i>usbd_hid.h</i> file so that we can use the read and write functions.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {device_global}
                </SyntaxHighlighter>
                <p>
                    Here we have some global variables for the button even, mouse data buffer and the USB device handle.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {device_bt_isr}
                </SyntaxHighlighter>
                <p>
                    In the external interrupt routine we can set a unique value based on the button that got pushed.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {device_locals}
                </SyntaxHighlighter>
                <p>
                    Here we use some local variables to translate movement and button states.
                    The motion of the mouse is encoded as an 8bit signed relative value to the previous state.
                    A positive value means that the mouse moved to right or down,
                    while a negative value means that it moved left or up depending on whether the x or y values are non-zero.
                    As for the button variable, 1 means that the left button is pressed, 2 means the right button is pressed,
                    4 indicates middle mouse button.
                    You can learn more about the data protocol <a href="https://wiki.osdev.org/USB_Human_Interface_Devices">here</a>.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {device_loop}
                </SyntaxHighlighter>
                <p>
                    Finally, we can store the values in the buffer, where the first value is the button state, then the x movement and y movement.
                    The last value is 0 now.
                    Although we send the data using the <i>USBD_HID_SendReport</i>, this is just preparing the data for when the host creates an interrupt.
                </p>
                <p>
                    Build the project, upload the firmware.
                    If you press the buttons, the mouse should move on the screen.
                    In my example, I've used a static 10 as magnitude, so that resulted in a slow motion.
                    Feel free to modify this value, or even create other HID devices (Keyboard, or IRQ based generic data transfer).
                </p>
            </div>

            <div className="em__post-section">
                <h3>Host configuration:</h3>
                <p>
                    This time we will create a HID host.
                    Since the MCU does not have "unlimited resources" as the PC,
                    we can't really set up the host to be able to recognize all HID devices,
                    but a keyboard or a mouse will do for now.
                </p>
                <p>
                    Create a new project for the NUCLEO-F411RE board.
                    Enable the USART2 so that we can send information to the PC using the VCP -
                    this time an OLED screen would not be wide enough :).
                    Enable the <i>USB_OTG_FS</i> and set it to <i>Host only</i> mode.
                    Finally, go to <i>Middleware/USB_HOST</i> and set it to HID host class.
                    I've increased the heap and stack sizes both to 0x2000 just to be on the safe side.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Host firmware:</h3>
                <p>
                    After the generation of the project, let's go to the main source.
                    Include <i>usbh_hid.h</i>, which contains the necessary function headers for the host data processing.
                </p>
                <p>
                    If we take a look at the main loop, we can see the host process function getting called periodically.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {host_global}
                </SyntaxHighlighter>
                <p>
                    we need a variable to store the incoming data and the length of the data.
                    Moreover, we need a device information variable.
                    If you go to the <i>usbh_hid.c</i> source and search for the <i>__weak</i> keyword,
                    you can see all the callback functions that can be implemented.
                    <br/>
                    While we are here, we need to do a few modifications:
                    in the function <i>USBH_HID_ClassRequest</i>, under the <i>HID_REQ_SET_PROTOCOL</i> case, change the
                    <i>classReqStatus == USBH_OK</i> to <i>classReqStatus != USBH_BUSY</i>;
                    Similarly in the <i>USBH_HID_Process</i> function under the <i>HID_IDLE</i> case, change <i>status == USBH_OK</i>
                    to <i>status != USBH_BUSY</i>.
                    This way we only check if the device is ready for the communication, and we are not checking for the most rigorous setup.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {host_callBack}
                </SyntaxHighlighter>
                <p>
                    Let's go back to the main source and implement the HID event callback function.
                    Here we make a simple choice:
                    If there is a keyboard connected, we get the pressed button and send it using UART.
                    If we have a mouse connected, we can get the pose and button information.
                </p>
                <p>
                    Build and upload the firmware.
                    This time, the device (mouse) needs a suply voltage, so connect the +5V from the board to the VBUS pin of the USB breakout board.
                    After you connect the mouse and open a COM port terminal, you should see some data flow:
                </p>
                <p>
                    <i>Mouse x=0x0, y=0xfe, bt1=0x0, bt2=0x0, bt3=0x0</i>&nbsp;
                    <i>Mouse x=0x1, y=0xfc, bt1=0x0, bt2=0x0, bt3=0x0</i>&nbsp;
                    <i>Mouse x=0x0, y=0x0, bt1=0x0, bt2=0x1, bt3=0x0</i>&nbsp;
                </p>
                <p>
                    Which shows the relative movement and the button events.
                    The device and host source files can be found in <a href="https://gitlab.com/stm32_mcu_group/stm32_hal/27_usb_hid.git">this</a> repo.
                </p>
            </div>


            <div className="em__post-navigation">

                <NavLink to="./../stm-hal-26">
                    <Button btnID={"leftBTN"} buttonSize="btn--medium"> Previous Post</Button>
                </NavLink>

                <NavLink to="./../stm-hal-28">
                    <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
                </NavLink>
            </div>
        </div>
    );
}

export default MCU_HAL27;