import React from "react";
import "./collection.css";
import {Card} from "../../common";
import {useNavigate} from "react-router-dom";
import {fpga_1_profile, fpga_3_profile, fpga_4_profile, fpga_5_profile, fpga_6_profile, fpga_7_profile,
        fpga_8_profile, fpga_9_profile, fpga_10_profile} from "./Posts";


const ProjectList = [
    {name:"1. What a cute and tiny FPGA", imgURL:fpga_1_profile,
        link: "./fpga-tut-1"},
    {name:"2. First steps in FPGA development", imgURL: fpga_1_profile,
        link: "./fpga-tut-2"},
    {name:"3. A box with many gates", imgURL: fpga_3_profile,
        link: "./fpga-tut-3"},
    {name:"4. HDL peculiarities", imgURL: fpga_4_profile,
        link: "./fpga-tut-4"},
    {name:"5. Simple Finite State Machine", imgURL: fpga_5_profile,
        link: "./fpga-tut-5"},
    {name:"6. DAC on an FPGA?", imgURL: fpga_6_profile,
        link: "./fpga-tut-6"},
    {name:"7. Delta-Sigma modulators", imgURL: fpga_7_profile,
     link: "./fpga-tut-7"},
    {name:"8. Soft processors", imgURL: fpga_8_profile,
        link: "./fpga-tut-8"},
    {name:"9. FPGA as hardware accelerator", imgURL: fpga_9_profile,
        link: "./fpga-tut-9"},
    {name:"10. Two more affordable FPGA boards", imgURL: fpga_10_profile,
        link: "./fpga-tut-10"},
    /*{name:"11. RISC-V on FPGA", imgURL: "",
        link: "./fpga-tut-11"},
      {name:"12. Ethernet access for FPGA", imgURL: "",
        link: "./fpga-tut-12"},
    {name:"13. FPGA and DSP - Filter accelerator", imgURL: "",
        link: "./fpga-tut-13"},
    {name:"14. FPGA and control - Fuzzy accelerator", imgURL: "",
        link: "./fpga-tut-14"},
    {name:"15. FPGA and AI - Neural Network", imgURL: "",
        link: "./fpga-tut-15"},*/
]

function FPGA(){

    const navigate = useNavigate();

    return(
        <div className="em__collection">
            <div className="em__collection-title">
                <h1 className="gradient__text">Programmable Digital Logic (CPLD / FPGA)</h1>
            </div>

            <div className="em__collection-list">
                {
                    ProjectList.map((item) => (
                        <div >
                            <Card title={item.name} description={item.description}
                                  imgURL={item.imgURL} onClick={() => navigate(item.link)}/>
                        </div>
                    ))
                }
            </div>

        </div>
    );
}

export default FPGA;