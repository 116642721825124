import React from "react";
import "./dsp_6.css";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";
import {MathJax, MathJaxContext} from "better-react-mathjax";
import average_magnitude from "./average_magnitude.png";
import average_phase from "./average_phase.png";
import average_n_9 from "./average_n_9.jpg";
import average_n_131 from "./average_n_131.jpg";


function ShowTex({string}){
    const config = {
        loader: { load: ["[tex]/html"]},
        tex: {packages: {"[+]": ["html"]},
            inlineMath: [["$", "$"]],
            displayMath: [["$$", "$$"]]
        }
    };

    return(
        <MathJaxContext config={config} version={3}>
            <MathJax dynamic inline>
                {string}
            </MathJax>
        </MathJaxContext>
    );
}

const python_sim = `
from scipy import signal as sg
from matplotlib import pyplot as plt
import numpy as np


fs = 5000
b1 = .5*np.ones((2, 1))

w, H1 = sg.freqz(b1, a=1, worN=5000, fs=fs)
angles1 = np.unwrap(np.angle(H1))

plt.figure()
plt.title("Moving average filter frequency response (gain)")
plt.plot(w, 20*np.log10(np.abs(H1)))
plt.ylabel('Amplitude [dB]')
plt.xlabel('Frequency [rad/sample]')
plt.ylim([-100, 5])
plt.legend(['N=2'])
plt.grid(True)

plt.figure()
plt.title("Moving average filter frequency response (phase shift)")
plt.plot(w, angles1)
plt.ylabel('Phase shift [rad]')
plt.xlabel('Frequency [rad/sample]')
plt.ylim([-20, 2])
plt.legend(['N=2'])
plt.grid(True)

plt.show()
`;

const main_defines = `
#define FILTER_TAP  9

float input = 0, output = 0;
float accumulator;
const float gain = 1.0f / (float)FILTER_TAP;
`;

const main_loop = `
if(is_sample_ready){
    input = ((float)adc_value * 2.0f)/4095.0f - 1.0f;

    // Add new data to FIFO
    for(uint16_t i = FILTER_TAP-1; i > 0; i--)
        filter_buffer[i] = filter_buffer[i-1];
    filter_buffer[0] = input;
    
    // Average filter
    accumulator = 0;
    for(uint16_t i = 0; i < FILTER_TAP; ++i)
        accumulator += gain*filter_buffer[i];
    output = accumulator;
    
    is_sample_ready = false;
}
`;

function DSP_6(){
   return(
       <div className="em__post">
           <div className="em__post-title">
               <h1>Moving average filter</h1>
           </div>
           <div className="em__post-section">
               <h3>Overview:</h3>
               <p>
                   In this tutorial, we will implement the most simple non recursive filter, the moving average filter.
                   We will use the configured and external signal generator to try out the filter.
               </p>
           </div>

           <div className="em__post-section">
               <h3>Theory:</h3>
               <p>
                   The moving average filter consists of a FIFO type data buffer of depth N.
                   the result is the sum of the values stored in the buffer divided by the number of elements
                   <ShowTex string="$$y_N[n] = \frac{1}{N}\sum_{l=0}^{N-1}x[n-l].$$"/>
                   We can study the frequency response of the filter with the help of the DTFT
                   <ShowTex string="$$Y_N(z) = \frac{1}{N}\sum_{n=-\infty}^{\infty}\sum_{l=0}^{N-1}z^{-l} z^{-n}X(z),$$"/>
                   which yields to the discrete-time transfer function
                   <ShowTex string="$$H(z) = \frac{Y_N(z)}{X(z)} = \frac{1}{N}\sum_{n=-\infty}^{\infty}\sum_{l=0}^{N-1}z^{-l} z^{-n} = \frac{1}{N} \frac{1-z^{-N}}{1-z^{-1}}.$$"/>
                   We can substitute <ShowTex string="$z = \mathrm{e}^{-j\omega}$"/> and we arrive to the frequency function
                   <ShowTex string="$$H(\omega) = \frac{1}{N} \frac{1-\mathrm{e}^{-j\omega N}}{1-\mathrm{e}^{-j\omega}}.$$"/>
                   The magnitude response is given by <ShowTex string="$a(\omega) = 20 \lg | H(\omega)|$"/>,
                   while the phase response is given by <ShowTex string="$\varphi(\omega) = \arg H(\omega)$"/>.
                   Since it would be a bit involving to study the frequency response in the general sense, we will calculate the responses for N=2.
                   For other values we will use python to simulate the response.
               </p>
               <p>
                   If N = 2, we have a transfer function <ShowTex string="$H(\omega) = \frac{1}{2}(1 + z^{-1})$"/>.
                   Substitute <ShowTex string="$z = \mathrm{e}^{-j\omega}$"/> and factor out <ShowTex string="$\mathrm{e}^{-\frac{1}{2}j\omega}$"/>:
                   <ShowTex string="$$H(\omega) = \frac{1}{2}\mathrm{e}^{-\frac{1}{2}j\omega}
                   (\mathrm{e}^{\frac{1}{2}j\omega}+\mathrm{e}^{-\frac{1}{2}j\omega}) = \frac{1}{2}
                   \mathrm{e}^{-\frac{1}{2}j\omega}\cos(\frac{1}{2}\omega).$$"/>
                   Since the cosine is real and the complex exponential has unit magnitude,
                   the magnitude response of the system is <ShowTex string="$a(\omega) = 20 \lg | \cos(\frac{1}{2}\omega) |$"/>,
                   while the phase response  is <ShowTex string="$\varphi(\omega) = \frac{1}{2}\omega$"/> with some added jumps when the cosine changes sign.
                   Here <ShowTex string="$\omega \in [0, \pi]$"/> is the discrete frequency range, where <ShowTex string="$\omega = \pi$"/> is the Nyquist frequency.
               </p>
           </div>
           <div className="em__post-section">
               <h3>Simulation:</h3>
               <p>
                   Here's a basic python script to simulate the frequency response of the N=2 moving average filter:
               </p>
               <div className="em_unselectable">
               <SyntaxHighlighter language="py" style={AtomOneDark}>
                   {python_sim}
               </SyntaxHighlighter>
               </div>
               <p>
                   We are using the <i>freqz</i> function to generate the discrete-time frequency function of the filter.
                   The phase shift can be calculated using the <i>angle</i> function or alternatively using the <i>atan2</i> with the real and imaginary parts.

                   Try to plot out the frequency response for different N values (e.g. 7, 50, 120).
               </p>
               <img className="img_average_magnitude_dsp-6 " src={average_magnitude} alt="average_magnitude_dsp-6"/>
               <img className="img_average_phase_dsp-6" src={average_phase} alt="average_phase_dsp-6"/>
           </div>
           <div className="em__post-section">
               <p>
                   We can see that if N = 2, the filter has a gain of -10dB (x0.3) at 2000Hz.
                   That is not the most selective filter.
                   Since all of these filters attenuate the high-frequency components, we call them low-pass filters.
                   There are some high attenuation points (notches) in the frequency response
                   e.g. in the case of N=5, those are at 1000Hz and 2000Hz.
               </p>
           </div>
           <div className="em__post-section">
               <h3>Firmware:</h3>
               <p>
                   Create a new project based on the 2nd tutorial.
                   Implement a signal generator that outputs a composite sine wave of frequencies 10Hz, 100Hz, 1000Hz.
                   Set up the project for sample-based signal processing.
               </p>
               <p>
                   First we need to define the number of samples that we want to average.
                   It's a good idea to define a global input and output variable so that we can compare them in the monitor.
                   I've also defined a float constant for the gain.
               </p>
               <div className="em_unselectable">
               <SyntaxHighlighter language="c" style={AtomOneDark}>
                   {main_defines}
               </SyntaxHighlighter>
               </div>
               <p>
                   When we have a new data sample, we normalise the input, we push it into the FIFO buffer, accumulate the samples and divide by the number of samples.
                   When we are working fixed point fractional numbers, there could be a chance for overflow inside the accumulation,
                   so it would be a better idea to divide each sample with the number of samples (taps) BEFORE the accumulation.
               </p>
               <div className="em_unselectable">
               <SyntaxHighlighter language="c" style={AtomOneDark}>
                   {main_loop}
               </SyntaxHighlighter>
               </div>
               <p>
                   Build and upload the firmware.
                   What result do are expecting from an N=9 moving average filter?
               </p>
           </div>

           <div className="em__post-section">
               <h3>Data visualisation in the Monitor:</h3>
               <p>
                   Start the STM32CubeMonitor, point to the generated elf file and start monitoring the input and output.
               </p>
               <img className="img_average_n_9_dsp-6 " src={average_n_9} alt="average_n_9_dsp-6"/>
               <p>
                   Well, this is underwhelming.
                   It's almost as if the average filter has no effect on the input signal -
                   this shouldn't be a surprise if you studied the frequency response of the filter.
                   Try increasing the number of averages (e.g. N = 131):
               </p>
               <img className="img_average_n_131_dsp-6" src={average_n_131} alt="average_n_131_dsp-6"/>
               <p>
                   It's much better, but there's still some residual high and middle frequency component present.
                   <br/>
                   The <b>b</b> terms in the python script and the gain in the C file creates the impulse response of the filter.
                   In our case the impulse response is
                   <ShowTex string="$$ h[n] = \begin{cases}
                   \frac{1}{N}, \quad \textrm{if } n = 0, 1, \cdots, N-1 \\
                   0, \quad otherwise \end{cases}.$$"/>
                   This impulse response has finite number of non-zero elements, so it's also called a <b>FIR</b> (Finite Impulse Response) filter.
                   In the next tutorial we will learn a method to design a somewhat more capable filter.
               </p>
           </div>

           <div className="em__post-section">
               <h3>Further work:</h3>
               <p>
                   <ol>
                       <li>
                           How do you explain the notches in the frequency response?
                       </li>
                       <li>
                           How should we design the moving average filter to leave only the lowest frequency component from our 10Hz, 100Hz, 1000Hz composite signal?
                       </li>
                       <li>
                           Can the moving average filter be implemented using a recursive difference equation?
                       </li>
                       <li>
                           Design the high-pass counterpart of the moving average filter (referred to as moving difference filter).
                       </li>

                   </ol>
               </p>
           </div>


           <div className="em__post-navigation">

               <NavLink to="./../dsp-5">
                   <Button btnID={"leftBTN"} buttonSize="btn--medium"> Prev Post</Button>
               </NavLink>

               <NavLink to="./../dsp-7">
                   <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
               </NavLink>
           </div>
       </div>
   );
}

export default DSP_6;