import React from "react";
import "./mcu_hal3.css";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";

import tim1 from "./tim1.jpg";
import tim2 from "./tim2.jpg";
import tim3 from "./tim3.jpg";
import tim4 from "./tim4.jpg";
import tim_block from "./tim_block.jpg";

const button_irq = `
if(GPIO_Pin == BT1_Pin) {
    if(!led_mode)
        HAL_TIM_Base_Start_IT(&htim6);
    else
        HAL_TIM_Base_Stop_IT(&htim6);
    led_mode ^= 1;
}
`;
const tim_irq = `
void HAL_TIM_PeriodElapsedCallback(TIM_HandleTypeDef *htim){
    HAL_GPIO_TogglePin(LD2_GPIO_Port, LD2_Pin);
}
`;

function MCU_HAL3(){
    return(
        <div className="em__post">
            <div className="em__post-title">
                <h1>Hardware timers - introduction to hardware timing (TIM)</h1>
            </div>
            <div className="em__post-section">
                <h3>Aim of this tutorial:</h3>
                <p>
                    In this tutorial we will start to discuss about an important topic in MCU programming, timing.
                    We will learn how to configure a timer, how to start it and how to generate interrupts.
                </p>
            </div>
            <div className="em__post-section">
                <h3>Timers in STM32:</h3>
                <p>
                    In the previous tutorial we created a program which blinked an LED with a given period.
                    The periodicity was given by a function called <i>HAL_Delay()</i>.
                    This function uses the internal System Tick Time (SysTick) which generates interrupt requests on a regular 1ms basis.
                </p>
                <p>
                    Besides the SysTick, there are basic timers (TIM6/7), general purpose timers (TIM2/3/4/5, TIM15/16/17),
                    advanced-control timers (TIM1/8/20).
                </p>
                <p>
                    The basic timers are used for time-base generation, and to trigger analog-to-digital or digital-to-analog converters.
                    These timers do not have connections to I/O pins.
                </p>
                <p>
                    The general purpose timers are 16b or 32b timers which may be used to measure pulse length of input signals (input capture),
                    generating output waveforms (output compare and PWM) and system timing.
                    They have four configurable channels.
                    The pulse lengths and waveform periods can be as short as a few microseconds or as long as several milliseconds.
                    These timers are completely independent, do not share resources, and they can be synchronised.
                </p>
                <p>
                    The advanced-control timers for 3-phase PWM generation.
                </p>
                <p>
                    There is a low-power timer (LPTIM) which introduces a flexible clock scheme that provides the needed functionalities and
                    performance, while minimizing the power consumption.
                </p>
                <p>
                    Finally, there is an independent Real-Time Clock (RTC) that provides a time-of-day clock/calendar with programmable alarm interrupts,
                    and also manages the low-power wake-up procedures.
                </p>

                <p>
                    Besides the RTC, every timer can be used as a basic timer, so let's have a look at that option first.
                </p>
            </div>
            <div className="em__post-section">
                <h3>Choosing the clock source:</h3>
                <p>
                    Every timer needs a clock source.
                    Let's create a new project, and set up the clock source for the timers.
                    Go to <i>System Core/RCC</i> and set the High Speed Clock (HSE) to Crystal/Ceramic resonator.
                    This enables the external oscillator pins.
                </p>
                <Popup trigger={<img className="img_tim1 clickable_img" src={tim1} alt="tim1"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={tim1} alt="tim1" />
                    )}
                </Popup>
                <p>
                    After this, go to the <i>Clock Configuration</i> panel, where you will be greeted by a complex clock
                    management GUI.
                    Here, we are only interested in the main clock.
                </p>
                <Popup trigger={<img className="img_tim2 clickable_img" src={tim2} alt="tim2"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={tim2} alt="tim2" />
                    )}
                </Popup>
                <p>
                    On the right side, there is a blue box with the input frequency.
                    This is the frequency of our external crystal, which is conveniently pre set to 24MHz.
                    The Phase Locked Loop (PLL) will lock onto this signal and synthesize the required clock signal using one of the signals harmonics.
                    Make sure that the PLL Source Mux is set to HSE not the internal one.
                    Set the System Clock Mux to PLLCLK in order to use the synthesized signal.
                </p>
                <p>
                    If you type in a value in one of the boxes and hit enter, the program will try to find a suitable configuration, which generates the required clock signal.
                    Sometimes this fails, then you must specify the pre-scaler, post-scaler and multiplier values manually.
                    If we divide the input frequency by 6 we will have a clock with 4MHz frequency.
                    Multiplying this by 85 gives 340MHz, and finally dividing this by 2 results in the 170MHz HCLK signal, which is the maximum clock signal that the core can run on.
                </p>
                <p>
                    There are multiple sources on the left hand side. These are the clock sources for the internal power converter, core, memory, timers and different periphery.
                    Some of them can operate at lower speeds (Cortex System Timer and APBx Timers).
                </p>
            </div>
            <div className="em__post-section">
                <h3>Basic timers:</h3>
                <Popup trigger={<img className="img_tim_block clickable_img" src={tim_block} alt="tim_block"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={tim_block} alt="tim_block" />
                    )}
                </Popup>
                <p>
                    I've attached an image with the block diagram of the basic timer.
                    The source clock goes into the trigger controller and then into the prescaler.
                    The new clock signal, with divided frequency increments a counter register (CNT).
                    The auto-reload register (ARR) contains a predefined value, which is compared to the value of the CNT.
                    If the ARR is equal to the CNT, then an event/interrupt is generated.
                </p>




                <Popup trigger={<img className="img_tim3 clickable_img" src={tim3} alt="tim3"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={tim3} alt="tim3" />
                    )}
                </Popup>
                <p>
                    Let's configure a basic timer.
                </p>
                <p>
                    Go to <i>Pinout & Configuration/Timers/TIM6</i>.
                    Activate the timer.
                    There is a <i>One Pulse Mode</i>, which allows the counter to be started in response to a stimulus
                    (only internal, since basic timers have no external inputs), and to generate a pulse with pre-programmed length and delay.
                    We don't need this functionality, so leave it unchecked.
                </p>
                <p>
                    In <i>Parameter Settings</i> we have the following properties:
                </p>
                <ul>
                    <li>Prescaler: divides the frequency of the source clock (16b wide register).</li>
                    <li>
                        Counter Mode: Here there is usually an option to create an up counter (register value increases) or
                        a down counter (register value decreases). There is only up-counter option for basic timer.
                    </li>
                    <li>
                        Dithering: if enabled, the ARR register is slightly changed over 16 counting periods with predefined patterns.
                        This results in a 16-fold resolution increase.
                    </li>
                    <li>
                        Counter Period: the value that gets loaded into the ARR. If the CNT register reaches this value,
                        an interrupt or event can be generated (16b wide register).
                    </li>
                    <li>Auto-reload preload: enables or disables the buffering of the ARR register.</li>
                    <li>
                        Trigger Event Selection: what happens if the CNT reaches the value specified in the ARR.
                        This can be a starter event for other periphery or simply reset the counter.
                    </li>
                </ul>

                <p>
                    First and foremost, we would want to generate a repetitive interrupt with a period of 1s.
                    This means that with a source frequency of 170MHz we would need to count up to 170000000.
                    This is way beyond the capabilities of a 16b counter, so we will prescale the clock.
                </p>
                <p>
                    Let's set the prescaler to 17000 - 1 (since we are counting from 0), which results in a clock signal with
                    10kHz frequency - much more manageable.
                </p>
                <p>
                    Finally, set the counter period to 10000-1 so that we get the 1s timing interval.
                </p>
                <p>
                    Technically timers can operate in polling mode, meaning that we start the timer,
                    and we periodically check if the CNT reached the ARR, but that wastes precious cycles in the main function,
                    and there is no guarantee that we check the event at the exact time that it happens.
                </p>
                <p>
                    With this idea in mind, go to the NVIC tab and enable the TIM6 global interrupt, and generate the project.
                </p>
                <Popup trigger={<img className="img_tim4 clickable_img" src={tim4} alt="tim4"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={tim4} alt="tim4" />
                    )}
                </Popup>

            </div>
            <div className="em__post-section">
                <h3>The source code:</h3>
                <p>
                    We have two interrupts: a button generated EXTI and a TIM generated Period Elapsed Interrupt.
                    You can copy the syntax of the EXTI from the previous tutorial.
                    We need a variable, which will only be used in the EXTi, so the volatile type can be omitted.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {button_irq}
                </SyntaxHighlighter>
                <p>
                    Here we start the timer at one button event, and stop the timer at the next one.
                    You can find the name of the TIM interrupt by opening to the interrupt header,
                    and navigating through the functions as in the previous tutorial.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {tim_irq}
                </SyntaxHighlighter>
                <p>
                    Build the project and upload the code.
                    You can see that the program is working by pressing the user button.
                    The LED starts flashing with a period of 2s (1s on, 1s off).
                    You can turn off the timer with the next push of the button.
                    The full project can be found <a href="https://gitlab.com/stm32_mcu_group/stm32_hal/3_tim_basic.git">here</a>.
                </p>
            </div>

            <div className="em__post-navigation">

                <NavLink to="./../stm-hal-2">
                    <Button btnID={"leftBTN"} buttonSize="btn--medium"> Previous Post</Button>
                </NavLink>

                <NavLink to="./../stm-hal-4">
                    <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
                </NavLink>
            </div>
        </div>
    );
}

export default MCU_HAL3;