import React from "react";
import "./../hobby.css";
import "./../collection.css";
import {Tag} from "../../../common";
import {useNavigate} from "react-router-dom";
import {useRef} from "react";



function OOLONGHUB(){
    const navigate = useNavigate();
    const sourSapRef = useRef(null);
    const rubyGabaRef = useRef(null);
    const royalPeachRef = useRef(null);
    const dongFangMeiRenRef = useRef(null);
    const daHongPaoRef = useRef(null);
    const jasmineJadeRef = useRef(null);
    const goldenFlowerRef = useRef(null);
    const honeyOrchidRef = useRef(null);
    const chouShiRef = useRef(null);
    const empressRef = useRef(null);
    const huaXiangRef = useRef(null);
    const eightImmortalsRef = useRef(null);
    const dsoolRef = useRef(null);
    const kdsaRef = useRef(null);
    const miLanNNSRef = useRef(null);
    const dchpRef = useRef(null);
    const qingXiangRef = useRef(null);
    const bakedGoodsRef = useRef(null);
    const wuDongRef = useRef(null);
    const ametGabaRef = useRef(null);
    const alishanCreamRef = useRef(null);
    const benshanRef = useRef(null);
    const maoXieRef = useRef(null);
    const laohuangRef = useRef(null);


    const tealist = [
        {name:"1. Sour Sap (ML)", ref:sourSapRef},
        {name:"2. Ruby Gaba (ML)", ref:rubyGabaRef},
        {name:"3. Royal Peach Orchid (ML)", ref:royalPeachRef},
        {name:"4. Dong Fang Mei Ren (NNS)", ref:dongFangMeiRenRef},
        {name:"5. Da Hong Pao (NNS)", ref:daHongPaoRef},
        {name:"6. Jasmine jade oolong (ML)", ref:jasmineJadeRef},
        {name:"7. Golden flower tie guan yin (ML) ", ref:goldenFlowerRef},
        {name:"8. Honey orchid Mi Lan Xiang (YS)", ref:honeyOrchidRef},
        {name:"9. Chou Shi king of duck shit (YS)", ref:chouShiRef},
        {name:"10. Empress oolong (ML)", ref:empressRef},
        {name:"11. Hua xiang da hong pao (YS)", ref:huaXiangRef},
        {name:"12. Eight Immortals (ML)", ref:eightImmortalsRef},
        {name:"13. Duck shit oolong (ML)", ref:dsoolRef},
        {name:"14. Mi Lan Xiang (NNS)", ref:miLanNNSRef},
        {name:"15. Dan cong huang pian (NNS)", ref:dchpRef},
        {name:"16. Qing xiang tie guan yin (NNS)", ref:qingXiangRef},
        {name:"17. Baked goods (ML)", ref:bakedGoodsRef},
        {name:"18. Wu dong chou shi dan cong (YS)", ref:wuDongRef},
        {name:"19. King of duck shit aroma (YS)", ref: kdsaRef},
        {name:"20. Amethyst GABA (ML)", ref:ametGabaRef},
        {name:"21. Alishan cream (ML)", ref:alishanCreamRef},
        {name:"22. Medium roast Ben Shan oolong (YS)", ref:benshanRef},
        {name:"23. Anxi mao xie oolong (YS)", ref:maoXieRef},
        {name:"24. Lao Cong Mi Lan Xiang (KTM)", ref:laohuangRef},
    ];

    return (
        <div className="em__post">
            <div className="em__post-section">
                <h3>About the history and production of oolong teas</h3>
                <p>
                    Oolong tea, with its nuanced flavours and exquisite aromas, occupies a unique space in the world of tea.
                    Originating in China and embraced by various cultures, oolong tea embodies the delicate balance between green and black teas.
                    With so much room for variation in the processing, oolong tea is a whole spectrum within the spectrum of teas.
                    <br/>
                    The roots of oolong tea trace back to the Fujian province in China during the Ming Dynasty (1368-1644 CE).
                    The name "oolong" translates to "Black Dragon" in Chinese, capturing the tea's partially oxidised nature and the mythical aura surrounding its discovery.
                    <br/>
                    Oolong tea is primarily made from the leaves of the Camellia sinensis plant.
                    The harvest timing, the tea gardens' altitude, and the specific tea cultivars employed contribute to the diverse array of oolong teas.
                    The most revered oolongs often come from regions with rich terroirs, such as Fujian, Taiwan, and Guangdong.
                    The process involves the following steps: picking, withering, bruising and oxidation, fixation, rolling, shaping and roasting.
                </p>
                <ul>
                    <li>
                        <b>Plucking:</b>
                        The picking style for oolongs is usually medium and larger leaves.
                        These more developed leaves have a robust quality that withstands the many processes of tea making.
                    </li>
                    <li>
                        <b>Withering:</b>
                        After plucking, the tea leaves undergo withering, a crucial step where they are spread out to lose moisture.
                        This initiates the natural oxidation process, where the leaves absorb oxygen from the air.
                        The duration of withering influences the final flavour profile of the tea.
                    </li>
                    <li>
                        <b>Bruising and oxidation:</b>
                        The leaves are bruised or shaken to promote oxidation, but not to the extent seen in black tea.
                        Oolong teas typically have an oxidation level ranging from 10% to 70%, resulting in a spectrum of flavours from floral and green to rich and roasted.
                    </li>
                    <li>
                        <b>Fixation:</b>
                        To halt oxidation at the desired level, the leaves are heated through pan-firing (common in China) or tumbling in baskets (common in Taiwan).
                        Fixation sets the flavour and locks in the distinctive characteristics of the tea.
                    </li>
                    <li>
                        <b>Rolling and shaping:</b>
                        Oolong leaves are often rolled or twisted, imparting unique shapes and enhancing the tea's complexity.
                        The tightness of the roll affects the tea's appearance, aroma, and the number of infusions it can yield.
                    </li>
                    <li>
                        <b>Roasting:</b>
                        Some oolongs, especially those from Taiwan, undergo roasting, which imparts a smoky or toasty flavour.
                        Roasting is a skilled art, requiring the tea master to balance the heat to achieve the desired aroma and taste.
                    </li>
                </ul>
                <p>
                    Oolong teas come in various styles, from the floral and lightly oxidised Tie Guan Yin to the dark and roasted Wuyi Rock Oolongs.
                    Each type reflects the local traditions, climate, and the mastery of the tea artisan.
                    I've listed the most popular oolong styles and their distinguishing characteristics below, but this is by no means the only and the absolute arrangement:
                </p>
                <ol>
                    <li>
                        <b>Tie Guan Yin (Iron Goddess of Mercy):</b>
                        An iconic oolong tea originates from China's Fujian and Guangdong provinces but is most famously associated with Anxi in Fujian.
                        This tea is crafted from the leaves of a specific tea cultivar known for its floral aroma and exquisite taste.
                        Tie Guan Yin is characterised by a medium oxidation level, offering a harmonious blend of floral notes, creamy undertones, and a lingering sweetness.
                        The leaves are often tightly rolled, expanding gracefully during brewing and releasing a fragrant infusion that captivates the senses.
                    </li>
                    <li>
                        <b>Wuyi Yancha (Wuyi Rock Oolong):</b>
                        A distinguished class of oolong tea cultivated in the rocky terrains of the Wuyi Mountains in Fujian, China.
                        The name "Yancha" translates to "rock tea," and these teas are known for their unique mineral-rich terroir.
                        Grown among the cliffs, the tea bushes absorb the essence of the rocky landscape, imparting a complex and robust flavour.
                        Wuyi Yancha includes various cultivars such as Da Hong Pao (Big Red Robe) and Shui Jin Gui (Golden Water Turtle),
                        each expresses a spectrum of flavours, from floral and fruity to deeply roasted and earthy, with a distinctive mineral finish.
                    </li>
                    <li>
                        <b>Taiwan Oolong:</b>
                        Taiwanese oolongs encompass diverse teas, with the island's unique climate and terroir contributing to their distinctive character.
                        From the high-mountain oolongs of Alishan to the rich and roasted Dong Ding (not Ding Dong) oolongs,
                        Taiwan produces oolong teas that cater to various preferences.
                        Taiwan oolongs are known for their floral and fruity aromas, with flavours ranging from buttery and creamy to more complex, roasted profiles.
                        The island's tea artisans employ traditional and innovative techniques, making Taiwan a haven for oolong enthusiasts seeking a vast and exciting selection.
                    </li>
                    <li>
                        <b>Fenghuang Dancong:</b>
                        A family of single-bush oolong teas cultivated in the Fenghuang (Phoenix) Mountain in Guangdong, China.
                        Each Dancong tea is named after its unique aroma, often mimicking the fragrance of flowers, fruits, or spices.
                        The processing of Fenghuang Dancong is meticulous, allowing the leaves to express a wide variety of flavours, from honey and orchids to almonds and cinnamon.
                        These teas are renowned for their longevity, with the leaves capable of providing multiple infusions, each unveiling a different facet of the tea's complexity.
                        Fenghuang Dancong is a testament to the artistry of crafting nuanced and aromatic oolongs.
                    </li>
                </ol>
                <p>
                    Since oolong teas are partially oxidised, they can be kept for a long time and can also be aged.
                </p>
            </div>

            <div className="em__collection">
                <div className="em__collection-title">
                    <h1 className="gradient__text">Sampled white teas:</h1>
                </div>

                <div className="em__collection-list">
                    {
                        tealist.map((item) => (
                            <div >
                                <Tag title={item.name} description={item.description} onClick={() => item.ref.current.scrollIntoView({ behavior: 'smooth' })}/>
                            </div>
                        ))
                    }
                </div>

                <div className="em__post-section">
                    <div id="1. Sour Sap (ML)" ref={sourSapRef}>
                        <h3>1. Sour Sap (ML)</h3>
                        <p>
                            This tea is a sour Tie Guan Yin, created using the Tuo Suan style.
                            The tea consists of leaves up to third and fourth picking; it was harvested in May from Xiaguan, Anxi, Fujian, China.

                            <br/>
                            The tea leaves were ball rolled with green algae colour and smelled like yoghurt and pineapples.
                            After a rinse, the leaves had a sour cream and magnolia smell.
                            The tea soup was medium and soft with a citrine colour.
                            The tea tasted like green mango, sour cream, and cucumber peel with lovely floral notes.
                            It was creamy and refreshing.
                            The aftertaste was juicy and bright, with citrus rind notes.
                            I brewed 4g of tea in a 100ml gaiwan with boiling water.
                            A session usually lasted for seven brews.
                            <br/>
                            Green/Vegtal: 8/10, Floral: 8/10, Fruits: 7/10, Spices: 2/10, Wood/Earth: 1/10, Roast/Nuts: 0/10, Cream: 7/10, Mineral: 6/10.
                        </p>
                    </div>
                    <div id="2. Ruby Gaba (ML)" ref={rubyGabaRef}>
                        <h3>2. Ruby Gaba (ML)</h3>
                        <p>
                            This roasted oolong is made using the Taiwanese Ruby 18 tea cultivar, usually used for black tea production.
                            It's called a GABA tea because the leaves contain at least 150mg of GABA per 100g of material.
                            This is achieved by altering the atmosphere between plain air and nitrogen-enriched air during oxidation.
                            The tea was harvested in spring from Nantou, Taiwan.


                            <br/>
                            The tea leaves were ball-rolled copper brown with a nice shine and smelled like charcoal and roasted pine nuts.
                            The rehydrated leaves smell like dark soy sauce, coffee and roasted coconut.
                            The tea soup is medium thick and burnt orange.
                            It has a rich, smoky taste, toasted rye bread, sun-dried tomatoes, and dark chocolate notes with slight sweetness and minerality.
                            The mouth finish is astringent that transforms into juicy sweetness.
                            I brewed 5g of tea in a 100ml gaiwan with boiling water.
                            A session usually lasted for 10+ brews.
                            <br/>
                            Green/Vegtal: 2/10, Floral: 5/10, Fruits: 6/10, Spices: 7/10, Wood/Earth: 6/10, Roast/Nuts: 8/10, Cream: 1/10, Mineral: 7/10.
                        </p>
                    </div>
                    <div id="3. Royal Peach Orchid (ML)" ref={royalPeachRef}>
                        <h3>3. Royal Peach Orchid (ML)</h3>
                        <p>
                            Because of the genetic variation and the rich diversity of the vegetation,
                            true Dan Cong Phoenix Oolong has a large variety of flavour profiles.
                            Tea farmers have tried to define them in groups: pomelo flower, magnolia, ginger flower, etc.
                            This tea has a Honey Orchid aroma (Mi Lan Xiang) and is made from semi-wild trees that are approximately 65 years old.
                            This tea was harvested in March from Da An village, Guangdong, China.

                            <br/>
                            The tea consists of medium leaves up to third picking with brown colour and caramel, baked orange smell.
                            After a quick rinse, the leaves smelled like honey-baked peaches, pine resin and orange candy.
                            The tea soup is medium thick and golden brown.
                            The brew is insanely aromatic.
                            Honey, citrus, peaches, ripe pears.
                            The liquor is thick and sweet with mineral notes.
                            The best tea by far for someone who is getting into oolongs.
                            The mouth finish is soft and dry, moving into a sweet aftertaste.

                            I brewed 5g of tea in a 100ml gaiwan with 95C water.
                            A session usually lasted for eight brews.
                            <br/>
                            Green/Vegtal: 2/10, Floral: 5/10, Fruits: 6/10, Spices: 7/10, Wood/Earth: 6/10, Roast/Nuts: 8/10, Cream: 1/10, Mineral: 7/10.
                        </p>
                    </div>
                    <div id="4. Dong Fang Mei Ren (NNS)" ref={dongFangMeiRenRef}>
                        <h3>4. Dong Fang Mei Ren (NNS)</h3>
                        <p>
                            This lauded Taiwanese oolong, frequently known as Peng Feng Cha or Bai Hao Oolong, is legendary in reputation,
                            despite only having developed within the last century.
                            The terpenes in the growing leaves provoked by the bites of leafhoppers are enhanced by careful oxidation to create a resinous,
                            almost piney aroma and honeyed sweetness.
                            The tea was harvested in May from Emei, Hsinchu, Taiwan.
                            <br/>
                            The dry leaves have an autumn colour scheme - buds and young leaves.
                            The dry tea smells like cherry and saffron candy.
                            The rinsed leaves smell like rose, chrysanthemum and liquorice candy.
                            The tea soup is thick and smooth with a crystal clear amber colour.
                            It tastes like pine resin, sandalwood and apricot compote. It has a sweet aftertaste with a mineral grip.

                            I used 4g of leaf in a 100ml gaiwan with 90C water.
                            On average, a session lasted for eight brews.
                            <br/>
                            Green/Vegtal: 2/10, Floral: 9/10, Fruits: 6/10, Spices: 9/10, Wood/Earth: 2/10, Roast/Nuts: 1/10, Cream: 2/10, Mineral: 5/10.
                        </p>
                    </div>
                    <div id="5. Da Hong Pao (NNS)" ref={daHongPaoRef}>
                        <h3>5. Da Hong Pao (NNS)</h3>
                        <p>
                            A well-known Wuyi Yan Cha (rock oolong).
                            A classic Da Hong Pao harvested in 2019 from Dashuikeng, Wuyishan, Fujian, China.
                            <br/>
                            Large plum and maroon-coloured leaves that smell like rum raisins and roasted peanuts.
                            The rinsed leaves smell like dark bread and cherries.
                            The tea soup is orange-brown, medium thick and oily.
                            It tastes like fruity sweet oat biscuits, peach and cherry compote with strong minerality.
                            The empty gong dao bei smelled like hazelnut and pine resin.
                            The tea left a juicy and smoky sweet aftertaste in the mouth.

                            I used 3g of leaf in a 100ml gaiwan with 90C water.
                            On average, a session lasted for five brews.
                            <br/>
                            Green/Vegtal: 1/10, Floral: 2/10, Fruits: 8/10, Spices: 6/10, Wood/Earth: 4/10, Roast/Nuts: 4/10, Cream: 1/10, Mineral: 6/10.
                        </p>
                    </div>
                    <div id="6. Jasmine jade oolong (ML)" ref={jasmineJadeRef}>
                        <h3>6. Jasmine jade oolong (ML)</h3>
                        <p>
                            This is a scented oolong tea that uses Ben Shan ball rolled oolong as a base and is scented with natural jasmine flowers.
                            It was picked in May from Anxi, Fujian, China.
                            <br/>
                            The tea consists of buds and one or two leaves rolled into balls.
                            The dry leaves are matt blue-green and smell like pineapple, strawberries and tomato wines.
                            After a quick rinse, the floral scent is released - jasmine syrup and pineapple nectar.
                            The tea soup is medium with a coating texture and looks like green grape juice.
                            The brew tastes like fresh grass, cantaloupe, jasmine and green bitters.
                            The empty cup smells like apricots and jasmine.
                            The aftertaste was dry, but it turned into a juicy sweetness.

                            I used 5g of leaf in a 100ml gaiwan with boiling water.
                            On average, a session lasted for six brews.
                            <br/>
                            Green/Vegtal: 6/10, Floral: 9/10, Fruits: 7/10, Spices: 2/10, Wood/Earth: 1/10, Roast/Nuts: 0/10, Cream: 4/10, Mineral: 3/10.
                        </p>
                    </div>
                    <div id="7. Golden flower tie guan yin (ML) " ref={goldenFlowerRef}>
                        <h3>7. Golden flower tie guan yin (ML)</h3>
                        <p>
                            The Huang Dan cultivar is sometimes called Huang Jin Gui (Yellow Golden Osmanthus) and
                            is an early sprouting tea cultivar used to make Tie Guan Yin and other teas.
                            It is a very fragrant variety with a slightly more golden-yellow appearance
                            compared with Greener cultivars of Tie Guan Yin.
                            This tea was picked from Gan De, Anxi, Fujian, China in May.
                            <br/>
                            The tea consists of picking up to four leaves and rolling the ball.
                            The dry leaves are khaki and golden and smell like milk pudding and rhubarb.
                            After a quick rinse, it smelled like apricot yoghurt and magnolia.
                            The tea soup is medium and bright lime green.
                            The brew tastes like butter-fried peas, orache, and sorrel soup, with pomelo rind and floral aroma.
                            There is a hint of mineral note at the end.

                            I used 5g of leaf in a 100ml gaiwan with boiling water.
                            On average, a session lasted for seven brews.
                            <br/>
                            Green/Vegtal: 8/10, Floral: 9/10, Fruits: 7/10, Spices: 2/10, Wood/Earth: 0/10, Roast/Nuts: 3/10, Cream: 5/10, Mineral: 5/10.
                        </p>
                    </div>
                    <div id="8. Honey orchid Mi Lan Xiang (YS)" ref={honeyOrchidRef}>
                        <h3>8. Honey orchid Mi Lan Xiang (YS)</h3>
                        <p>
                            Another Mi Lan Xiang tea. I just can't get enough of them.
                            Bai Ye varietal is used and was expertly processed over a month to give it a special thick, sweet, floral (orchid) aroma.
                            The leaves are larger and broader than many other varietals, and the finished dry leaf is a deep brown.
                            The brewed leaves are also more brown (and less green) than most other Dan Cong oolongs.
                            This higher degree of oxidation due to roasting brings out the delicious honey and orchid taste.
                            The tea was picked from Zhongshan, Wu Dong, Guangdong, China in April.
                            <br/>
                            The tea consists of long, twirled leaves in a mix of plum, khaki, and chocolate brown.
                            The dry leaves had a smell of peaches and nectarines that turned more floral - jasmine orchids when I rinsed them.
                            The tea soup is medium thick, almost oily, with a pale gold color.
                            The tea tasted like brown sugar and peach compote and had the fragrance of mid-spring.
                            It's easy to over-brew; although it still has the flora aroma, the tea tastes bitter.
                            The aftertaste was sweet and peachy with menthol-like notes.

                            I used 5g of leaf in a 100ml gaiwan with 95C water.
                            On average, a session lasted for eight brews.
                            <br/>
                            Green/Vegtal: 0/10, Floral: 8/10, Fruits: 9/10, Spices: 2/10, Wood/Earth: 0/10, Roast/Nuts: 2/10, Cream: 3/10, Mineral: 3/10.
                        </p>
                    </div>
                    <div id="9. Chou Shi king of duck shit (YS)" ref={chouShiRef}>
                        <h3>9. Chou Shi King of duck shit (YS)</h3>
                        <p>
                            This is a strange oolong. The leaf material is a Ping Keng Tou (Duck Shit) Dan Cong,
                            which is usually processed as a more oxidised and roasted tea.
                            Instead, the leaves were dehydrated early on to halt the oxidation.
                            Chou Shi is a new style of processing Dan Cong that shares some similarities to Anxi Tie Guan Yin.
                            The tea is picked, and before it can wilt, it is fried to start the kill-green process in motion; the tea is then rolled briefly by hand and then put in a special dehydrator to stop the wilting process entirely.
                            The result is a very green and very aromatic dan cong.
                            The tea also has a very sweet taste, with some vegetal almost Tie Guan Yin-like feeling
                            <br/>
                            The tea consists of long, twirled, vibrant green leaves.
                            The dry leaves had little to no smell (faint buttered spinach), but as soon as I rinsed them, an amazing smell bomb arose from the cup.
                            The wet leaves smelled like jasmine, sour sop, butter, steamed spinach and orchids, lilacs and Japanese plums.
                            The texture of the tea was medium thick, lubricating and smooth with a bright citrine colour.

                            The tea tasted rich: green apple, steamed greens, jasmine, and coconut water.
                            There were hints of wild berries with medium-strong minerality, and they left a sort of tropical fruit with a lingering sweet aftertaste.
                            After drinking, the gong dao bei smelled like charred corn, jasmine, butter, and boiled peanuts.

                            The first few infusions had a strong Tie Guan Yin taste, while the latter shifted towards the classic Ya Shi characteristics.

                            I used 5g of leaf in a 100ml gaiwan with boiling water.
                            On average, a session lasted for ten brews.
                            <br/>
                            Green/Vegtal: 8/10, Floral: 8/10, Fruits: 9/10, Spices: 2/10, Wood/Earth: 0/10, Roast/Nuts: 0/10, Cream: 7/10, Mineral: 3/10.
                        </p>
                    </div>
                    <div id="10. Empress oolong (ML)" ref={empressRef}>
                        <h3>10. Empress Oolong (ML)</h3>
                        <p>
                            This pure Qi Dan cultivar-based Da Hong Pao has a more pronounced taste than those made from Shui Xian and Rou Gui blends.
                            It was picked in May from Wuji, Fujian, China.
                            <br/>
                            The tea consists of dark brown medium leaves that smell like dried cherries and rum.
                            After a quick rinse, it smelled like peaches and dark-baked bread.
                            The tea soup is medium and vibrant orange-brown.
                            The brew has an amazing caramel charcoal aroma.
                            Roast hazelnuts, tobacco, wild cherry, bourbon, and apricot taste with a hint of acidity.
                            It has a sweet and juicy mangosteen-like aftertaste.
                            I used 5g of leaf in a 100ml gaiwan with boiling water.
                            On average, a session lasted for 9+ brews.
                            <br/>
                            Green/Vegtal: 1/10, Floral: 3/10, Fruits: 8/10, Spices: 8/10, Wood/Earth: 6/10, Roast/Nuts: 5/10, Cream: 2/10, Mineral: 8/10.
                        </p>
                    </div>
                    <div id="11. Hua xiang da hong pao (YS)" ref={huaXiangRef}>
                        <h3>11. Hua xiang da hong pao (YS)</h3>
                        <p>
                            A premium lightly processed Da Hong Pao from Wu Yi Shan.
                            "Hua Xiang" means flower aroma.
                            It's a unique light processing method that brings out a flower aroma in the tea when brewed.
                            <br/>
                            The tea consists of large, twisted chestnut and auburn leaves with occasional mossy green spots.
                            The dry leaves smelled like burnt sweet corn and linden honey.
                            After rinsing it, sour cherry, burnt toast, dark bread, and mangosteen.
                            The tea soup is vibrant amber, medium thick and slightly dry.

                            The brew tasted like pine resin and vanilla and was extremely mineral.
                            Honey, cranberry with dark cherries.
                            There is astringency and some slight sour aftertaste notes.
                            The juice finish has a pine aroma and a slightly sour, sweet aftertaste.

                            I used 5g of leaf in a 100ml gaiwan with boiling water.
                            On average, a session lasted for nine brews.
                            <br/>
                            Green/Vegtal: 1/10, Floral: 7/10, Fruits: 8/10, Spices: 8/10, Wood/Earth: 1/10, Roast/Nuts: 3/10, Cream: 2/10, Mineral: 2/10.
                        </p>
                    </div>
                    <div id="12. Eight Immortals (ML)" ref={eightImmortalsRef}>
                        <h3>12. Eight Immortals (ML)</h3>
                        <p>
                            Eight Immortals is a lesser-produced but highly loved cultivar of Phoenix Oolong (aka Dan Cong Oolong).
                            It is often listed as the number one Dan Cong aroma by the farmers in that area because
                            it has a very deep, rich, and more 'adult' taste than the other Dan Congs out there.
                            The tea is made from the Ba Xiang cultivar, it was picked in April from Wudong, Guangdong, China.
                            <br/>
                            The tea consists of raisin brown medium leaves that smell like apricot jam and peaches.
                            After rinsing, it smelled like magnolia, chestnut and varnish.
                            The tea soup is orange-coloured with a medium texture.
                            The brew is fruity (Ripe papaya, honey, and apricot) and floral (honeysuckle, magnolia, and slight jasmine).
                            A hint of minerality, slightly toasty, slightly bitter.
                            The mouth finish is dry with juicy-sweet aftertaste.

                            I used 5g of leaf in a 100ml gaiwan with 95C water.
                            On average, a session lasted for eight brews.
                            <br/>
                            Green/Vegtal: 0/10, Floral: 9/10, Fruits: 8/10, Spices: 6/10, Wood/Earth: 6/10, Roast/Nuts: 5/10, Cream: 4/10, Mineral: 6/10.
                        </p>
                    </div>
                    <div id="13. Duck shit oolong (ML)" ref={dsoolRef}>
                        <h3>13. Duck shit oolong (ML)</h3>
                        <p>
                            There are dozens of Dan Cong cultivars with unique flavour profiles.
                            The duck shit oolong is one of them - contrary to other Dan Congs, this time, the name does not mean the taste profile.
                            The tea was harvested in April from Peng Ken Tou village, Guangdong, China.
                            <br/>
                            The tea consists of twisted brown leaves that smell like baked cherry and frangipane.
                            After rinsing, it smelled like almonds and lotus flowers.
                            The tea soup is a light rose gold colour with a medium and oily texture.
                            The brew tastes like salted butter and smoky maple syrup with slight minerality.
                            Elderflower and honeysuckle were used at first infusions, and cherry and raspberries were used later.
                            The mouth finish is a deep mineral moving to a custard juiciness.

                            I used 5g of leaf in a 100ml gaiwan with 95C water.
                            On average, a session lasted for eight brews.
                            <br/>
                            Green/Vegtal: 1/10, Floral: 7/10, Fruits: 8/10, Spices: 3/10, Wood/Earth: 4/10, Roast/Nuts: 4/10, Cream: 8/10, Mineral: 9/10.
                        </p>
                    </div>
                    <div id="14. Mi Lan Xiang (NNS)" ref={miLanNNSRef}>
                        <h3>14. Mi Lan Xiang (NNS)</h3>
                        <p>
                            A stalwart member of the Dancong family, Milan Xiang can be both familiar and changeling,
                            with different treatments producing different complexions. This one sits happily in the middle.
                            The tea was harvested in March from Wudong, Fenghuang, Guangdong, China.

                            <br/>
                            The tea consists of medium-sized black and plum leaves with some green spots.
                            The smell reminds me of smoky caramel and over-ripe peaches.
                            The rinsed leaves changed to a purple and khaki combination; the smell was of pine resin, cane sugar, and nectarine compote.
                            The tea liquor was medium thick and oily with a tinged gold colour.
                            It tasted sweet and spicy with wisteria honey, apricot meringue and pine notes.
                            The empty cup smelled like candy floss, and the tea left a sweet aftertaste with a slight dry mouthfeel.

                            I used 4g of leaf in a 100ml gaiwan with boiling water.
                            On average, a session lasted for 11 brews on average.
                            <br/>
                            Green/Vegtal: 1/10, Floral: 10/10, Fruits: 9/10, Spices: 8/10, Wood/Earth: 0/10, Roast/Nuts: 1/10, Cream: 1/10, Mineral: 5/10.
                        </p>
                    </div>
                    <div id="15. Dan cong huang pian (NNS)" ref={dchpRef}>
                        <h3>15. Dan cong huang pian (NNS)</h3>
                        <p>
                            The tea farmers usually retain these large and wild-looking leaves and twigs for their consumption,
                            less sightly and intensely flavoured as they are, but they are glad to share this rough cut for a much more affordable price.
                            The Fenghuang Dancong-style processing it has gone through means it shares a hint of the same stone fruit-mineral-cream character as its siblings,
                            in this case, it is closest to yellow plum, but a higher proportion of twigs gives it a most pronounced roasted aroma, with the subtly sweet aftertaste of liquorice root.
                            The tea was harvested in April from Wudong, Fenghuang, Chaozhou, Guangdong, China.

                            <br/>
                            The tea consists of prune, khaki-coloredd leave,s and royal yellow stems that smelled like burnt caramel, cherr,y and peach nectar.
                            After a quick rinse, the leaves turned glossy green and khaki, with orchid, mango, and black cherry smell.
                            The tea soup was soft, smooth and coated with a pale gold.
                            The mouth taste was slightly mineral, fruity with notes of apricot, orchids, and honeysuckle.
                            The empty cup smelled like almonds and apricots, while the tea had a sweet, slightly bitter, pollen-like aftertaste.

                            I used 3g of leaf in a 100ml gaiwan with boiling water.
                            On average, a session lasted for eight brews.
                            <br/>
                            Green/Vegtal: 3/10, Floral: 10/10, Fruits: 8/10, Spices: 4/10, Wood/Earth: 1/10, Roast/Nuts: 4/10, Cream: 4/10, Mineral: 6/10.
                        </p>
                    </div>
                    <div id="16. Qing xiang tie guan yin (NNS)" ref={qingXiangRef}>
                        <h3>16. Qing Xiang Tie Guan Yin (NNS)</h3>
                        <p>
                            Unlike most other types of tea, the autumn harvest of the "Iron Goddess of Mercy" is capable of producing heady aromas.
                            This one has been processed in the increasingly popular Qing Xiang style, lightly oxidised and barely roasted for a fresh taste and bright green colour.
                            The tea was harvested from Longjuanxiang, Anxi, Quanzhou, Fujian, China in October.
                            <br/>
                            The rolled leaves had a green algae colour and a floral and sweet smell with creamy buttery notes.
                            The wet leaves smelled like rie bread toast, buttered sweet peas.
                            The tea liquor was medium thick, coated with clear, yellow diamond colour and green undertones.
                            The tea tasted like mascarpone, magnolia, wisteria and steamed spinach.
                            The empty cup smelled of whipped cream and orange zest.
                            The aftertaste was sweet with lingering citrus notes.

                            I used 4g of leaf in a 100ml gaiwan with boiling water.
                            On average, a session lasted for 10+ brews.
                            <br/>
                            Green/Vegtal: 8/10, Floral: 9/10, Fruits: 2/10, Spices: 2/10, Wood/Earth: 1/10, Roast/Nuts: 0/10, Cream: 7/10, Mineral: 5/10.
                        </p>
                    </div>
                    <div id="17. Baked goods (ML)" ref={bakedGoodsRef}>
                        <h3>17. Baked goods (ML)</h3>
                        <p>
                            A modern style Tie Guan Yin made from the Huang Dan cultivar, baked for over 9 hours of Longan wood charcoal for rich, smooth, aromatic tea.
                            It was picked in April from Xiping, Anxi, Fujian, China.
                            <br/>
                            The rolled leaves were currant brown and smelled like butter cookies, tobacco and rum.
                            The wet leaves smelled like cherry syrup.
                            The tea liquor is thick and soft, with a golden whisky colour.
                            The tea soup tasted like rye bread toast, tropical fruits, and wild cherries.
                            It had a long-lasting sweetness. Extremely fruity and floral.
                            The aftertaste was sweet and juicy.

                            I used 5g of leaf in a 100ml gaiwan with 95C water.
                            On average, a session lasted for eight brews.
                            <br/>
                            Green/Vegtal: 1/10, Floral: 5/10, Fruits: 6/10, Spices: 4/10, Wood/Earth: 6/10, Roast/Nuts: 8/10, Cream: 5/10, Mineral: 4/10.
                        </p>
                    </div>
                    <div id="18. Wu dong chou shi dan cong (YS)" ref={wuDongRef}>
                        <h3>18. Wu dong chou shi dan cong (YS)</h3>
                        <p>
                            This is another strange, dehydrated Dan Congs that retained the green notes.
                            The tea is picked, and before it can wilt, it is fried to start the kill-green process in motion; the tea is then rolled briefly by hand and then put in a special dehydrator to stop the wilting process entirely.
                            The result is a very green and very aromatic dan cong.
                            The tea is also very sweet, with some vegetal, almost Tie Guan Yin-like feeling.
                            <br/>
                            The tea is very similar to the previous Chou Shi one, but it's more floral in this case.
                            Like a meadow with hay, dandelions, gardenias.
                            It's a bit different and greener with lesser bracing citrus than other DanCong, but it's great differently.
                            The liquor is very dense and has a dark yellow/green colour.
                            The mouthfeel is silky with a hint of allround astringency.
                            As for the taste, it is very complex and strong but remains balanced nevertheless.
                            It starts with the floral and savoury notes, with slight bitterness quickly kicking in, only to give way to the fruity and sweeter notes in the finish.
                            The aftertaste thus starts fairly sweet, but over time, it becomes slightly more vegetal.

                            I used 4g of leaf in a 100ml gaiwan with 95C water.
                            On average, a session lasted for eight brews.
                            <br/>
                            Green/Vegtal: 7/10, Floral: 8/10, Fruits: 4/10, Spices: 5/10, Wood/Earth: 0/10, Roast/Nuts: 1/10, Cream: 7/10, Mineral: 3/10.
                        </p>
                    </div>
                    <div id="19. King of duck shit aroma (YS)" ref={ kdsaRef}>
                        <h3>19. King of duck shit aroma (YS)</h3>
                        <p>
                            Ya Shi Xiang Dan Cong is a rare Dan Cong varietal grown in and around Ping Keng Tou village in the Phoenix Mountains outside Chaozhou in Guangdong Province.
                            The tea bushes from which our King of Duck Shit Aroma are more than 80 years old growing naturally.
                            The tea is lightly oxidised, and the leaves are still mostly green.
                            <br/>
                            The dry leaves are chocolate brown and purple with green stripes that turn dark chocolate and forest green when brewed.
                            The leaves smell like baked cornbread, cherry, and amaretto.
                            After a quick rinse, the cherry becomes more pronounced, with elderberry jam, menthol and caramel notes.
                            The tea soup is pale gold with a pink hue and a light coating texture.
                            The tea soup, contrary to the name, tastes like rock sugar, black cherry, and rye biscuit, with a mineral, spicy and sweet aftertaste.
                            The empty cup smells of European blueberry jam.

                            I used 5g of leaf in a 100ml gaiwan with boiling water.
                            On average, a session lasted for eight brews.
                            <br/>
                            Green/Vegtal: 1/10, Floral: 3/10, Fruits: 8/10, Spices: 9/10, Wood/Earth: 4/10, Roast/Nuts: 2/10, Cream: 2/10, Mineral: 7/10.
                        </p>
                    </div>
                    <div id="20. Amethyst GABA (ML)" ref={ametGabaRef}>
                        <h3>20. Amethyst GABA (ML)</h3>
                        <p>
                            This is another GABA tea made from leaves of the Fo Shou cultivar.
                            It was picked in Spring from Alishan, Chiayi, Taiwan.
                            <br/>
                            The dry leaves are black-grey coloured and tightly rolled with a nutty and scorched smell.
                            After a quick rinse, the liquorice and tarry aroma became apparent.
                            The tea soup is dark with an almost purple haze; it's smooth and soft.
                            The tea soup tasted like burnt caramel, black cardamom, coffee, cocoa powder, and molasses.
                            It is slightly mineral and slightly acidic.
                            Dried cherry and brandy aftertaste.
                            Easily my favourite GABA Oolongs.

                            I used 6g of leaf in a 100ml gaiwan with boiling water.
                            On average, a session lasted for 9+ brews.
                            <br/>
                            Green/Vegtal: 0/10, Floral: 1/10, Fruits: 6/10, Spices: 8/10, Wood/Earth: 7/10, Roast/Nuts: 10/10, Cream: 3/10, Mineral: 5/10.
                        </p>
                    </div>
                    <div id="21. Alishan cream (ML)" ref={alishanCreamRef}>
                        <h3>21. Alishan cream (ML)</h3>
                        <p>
                            This is a genuine, pure Jin Xuan cultivar tea, often imitated as an artificially scented 'Milk Oolong'.
                            This cultivar (also known as Taiwan cultivar 12) is relatively modern and was released by the Taiwanese Tea Research and Extension (TRES) in the 1980s.
                            It is grown throughout Taiwan and is renowned for its milky/creamy taste due to being naturally rich in lactones.
                            It was picked in Spring from Alishan, Chiayi, Taiwan.
                            <br/>
                            The dry leaves are seaweed green-coloured and tightly rolled with a barley and malted milk smell.
                            After a quick rinse, the smell changed to toasted pound cake.
                            The tea soup is medium thick with a light green colour.
                            The tea soup tasted like salty buttered spinach, evaporated milk, slight Mediterranean herbs,
                            creamy papaya, slightly sweet and ever so slightly astringent.
                            The aftertaste was buttery with a light citrus zest grip.
                            I used 5g of leaf in a 100ml gaiwan with boiling water.
                            On average, a session lasted for 8+ brews.
                            <br/>
                            Green/Vegtal: 5/10, Floral: 4/10, Fruits: 3/10, Spices: 3/10, Wood/Earth: 1/10, Roast/Nuts: 4/10, Cream: 8/10, Mineral: 5/10.
                        </p>
                    </div>
                    <div id="22. Medium roast Ben Shan oolong (YS)" ref={benshanRef}>
                        <h3>22. Medium roast Ben Shan oolong (YS)</h3>
                        <p>
                            Anxi-grown Ben Shan Oolong was roasted for 10 hours at 60C.
                            This heavier roasting technique brings out the aroma and softens the green edge of this Ben Shan oolong.
                            With its coarser (thicker) leaves, Ben Shan takes well to longer roasting.
                            The roasting process allows this tea (if kept sealed) to age wonderfully.
                            <br/>
                            The tea consists of nutmeg and dirt-coloured, ball-rolled leaves that unroll and have a glossy tar and walnut colour when brewed.
                            The dry leaves smelled like red bean paste, caramel, mineral water and charred wood.
                            After a quick rinse, the leaves smelled like almonds, wild cherries, and molasses.
                            The tea colour was pale gold, medium thick and slightly oily.
                            The tea soup tasted like pine resin, bitter almonds, roasted walnuts, cherries, and tea rose.
                            Even the empty cup smelled like burned sugar and bitter almonds.
                            The mouth finish was mineral and sweet with a long-lasting resin taste.
                            I used 4g of leaf in a 100ml gaiwan with boiling water.
                            On average, a session lasted for eight strong brews.
                            <br/>
                            Green/Vegtal: 0/10, Floral: 5/10, Fruits: 9/10, Spices: 2/10, Wood/Earth: 6/10, Roast/Nuts: 3/10, Cream: 0/10, Mineral: 7/10.
                        </p>
                    </div>
                    <div id="23. Anxi mao xie oolong (YS)" ref={maoXieRef}>
                        <h3>23. Anxi mao xie oolong (YS)</h3>
                        <p>
                            Mao Xie, aka "Hairy Crab", is a type of Anxi oolong tea that grows in many places in Anxi County of Fujian.
                            Mao Xie means "Hair of the Crab" and refers to the hairs on the tea leaves that break off when brewed and float on the top of your cup.
                            Mao Xie has a thicker and sweeter taste than its more floral counterpart, Tie Guan Yin.

                            <br/>
                            The tea consists of kelp and lime-green ball-rolled leaves that smell like fresh-cut grass and butter.
                            After a quick rinse, the leaves turned dark green and smelled like sorrel, steamed spinach and citrus zest.
                            The tea colour brew was medium thick with citrine colour.
                            The tea soup had mineral, floral and green notes. It was sweet, with steamed vegetables and pomelo. The sourness was almost missing.
                            Even the empty cup smelled like fresh jasmine.
                            The mouth finish was mineral pomelo zest with a cooling aftertaste.
                            I used 4g of leaf in a 100ml gaiwan with boiling water.
                            On average, a session lasted for 5-6 brews.

                            <br/>
                            Green/Vegtal: 6/10, Floral: 7/10, Fruits: 2/10, Spices: 0/10, Wood/Earth: 0/10, Roast/Nuts: 0/10, Cream: 3/10, Mineral: 5/10.

                            This tea was not among my favourites. Yes, it's a solid daily drinker, but I chose other teas when I had other options.
                            Usually, a 50g pouch does not last a year in my stash, but this one did.
                            So I did the unthinkable. I roasted the last 10g of it, and holy...
                            The process was as follows: I spread the tea on a baking tray in an oven at 90C. I left it for 20 minutes.
                            Then, every 15 minutes, I increased the temperature to 10C while stirring the leaves every 5 minutes.
                            The last temperature value was 145C, and after that, I took out the tea and left it in a cloth bag until morning.

                            This seemingly average tea became one of my favourite roasted oolongs. The green notes vanished.
                            It retained the floral and mineral notes, with added cherry, roasted rye bread and charcoal notes.
                            Also, I could brew 8-10 times on average, which was strange, but I can't complain.
                            Overall, I would've roasted it sooner if I knew how this turned out.
                        </p>
                    </div>
                    <div id="24. Lao Cong Mi Lan Xiang (KTM)" ref={laohuangRef}>
                        <h3>24. Lao Cong Mi Lan Xiang (KTM)</h3>
                        <p>
                            A 2024 harvest Mi Lan Xiang DanCong from old tea trees.
                            The leaves were heavily roasted, increasing the aromatic profile and reducing any lingering green characteristics.
                            This exceptional Dancong oolong variety hails from the Fenghuang (Phoenix) mountains of Chaozhou,
                            Guangdong province — an area steeped in a tea production legacy dating back approximately 1,000 years.

                            <br/>
                            The tea consists of long and twisted plum and chocolate-coloured leaves that smell like peaches, orchids and brown sugar.
                            After a quick rinse, the leaves turned forest green with plum and auburn spots and the smell of plum and lilacs.
                            The brew was medium thick and oily with a pale gold colour.
                            The tea soup tasted like peach and nectarine with hints of ripe papaya and pine resin.
                            Even the empty cup smelled like orchids, lilacs and resin.
                            The mouth finish had a sweet papaya taste with slight grapefruit rind bitterness.
                            I used 5g of leaf in a 100ml gaiwan with boiling water.
                            On average, a session lasted for 5-6 brews.

                            <br/>
                            Green/Vegtal: 1/10, Floral: 7/10, Fruits: 5/10, Spices: 4/10, Wood/Earth: 0/10, Roast/Nuts: 0/10, Cream: 3/10, Mineral: 3/10.
                        </p>
                    </div>
                </div>



            </div>
        </div>
    );
}

export default OOLONGHUB;
