import React from "react";
import "./mcu_hal9.css";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";
import {MathJax, MathJaxContext} from "better-react-mathjax";

import pfun from "./project_function.jpg";
import iw_schem from "./IWDGT_schem.jpg";
import iw_setup from "./IWDGT_setup.jpg";
import sw_schem from "./SWWDGT_schem.jpg";
import sw_setup from "./SWWDGT_setup.jpg";
import sw_win from "./SWWDGT_window.jpg";
import dog from "./dog.png"
import sw_res from "./SWWDGT_result.jpg";
import iw_res from "./IWDGT_result.jpg";

function ShowTex({string}){
    const config = {
        loader: { load: ["[tex]/html"]},
        tex: {packages: {"[+]": ["html"]},
            inlineMath: [["$", "$"]],
            displayMath: [["$$", "$$"]]
        }
    };

    return(
        <MathJaxContext config={config} version={3}>
            <MathJax dynamic inline>
                {string}
            </MathJax>
        </MathJaxContext>
    );
}

const wwdg_irq = `
void HAL_WWDG_EarlyWakeupCallback(WWDG_HandleTypeDef *hwwdg_x){
    HAL_GPIO_WritePin(LD2_GPIO_Port, LD2_Pin, GPIO_PIN_SET);
    //printf("WWDT Interrupt executed\\r\\n");
}
`;

const wwdg_setup = `
#ifdef USE_WWDT
  printf("Starting WWDT example...\\r\\n");
  MX_WWDG_Init();

    for(uint16_t i = 0; i < 600; ++i){
        // Normal operation
        if(i % 100 == 0)
            printf("Refreshing the WWDT timer.\\r\\n");
        HAL_Delay(8);
        HAL_WWDG_Refresh(&hwwdg);
    }
    printf("Entering while(1), early WWDT refresh. \\r\\n");
#endif
`;

const wwdg_loop = `
#ifdef USE_WWDT
      HAL_Delay(30);
      HAL_WWDG_Refresh(&hwwdg);
      ++counter;
      if(counter%10 ==0){
          printf("This is the %d. cycle\\r\\n", counter);
      }
#endif
`;

const iwdg_setup = `
#ifdef USE_IWDT
  printf("Starting IWDT example...\\r\\n");
  MX_IWDG_Init();


  for(uint8_t i = 0; i < 5; ++i){
    // Normal operation
    printf("Refreshing the IWDT timer.\\r\\n");
    HAL_Delay(1000);
    HAL_IWDG_Refresh(&hiwdg);
  }
  printf("Entering while(1), no IWDT refresh. \\r\\n");
#endif
`;

const iwdg_loop = `
printf("This is the %d. cycle\\r\\n", counter++);
      HAL_Delay(1000);
`;


function MCU_HAL9(){
    return(
        <div className="em__post">
            <div className="em__post-title">
                <h1> Watchdog Timers</h1>
            </div>

            <div className="em__post-section">
                <h3>Aim of this tutorial:</h3>
                <p>
                    In this tutorial we will have a look at two of the most important peripherals that can help a processor recover from malfunctions.
                    The Independent WatchDog (IWDG) and the System Window WatchDog (WWDG) Timers.
                </p>
            </div>

            <div className="em__post-section">
                <h3>WatchDog timers:</h3>
                <img className="img_dog_hal9" src={dog} alt="dog_hal9" />
                <br/>
                <p>
                    According to Murphy's law: If something can go wrong, it will go wrong.
                    This is especially true for embedded systems.
                    Apart from hardware faults (which can impact the firmware) any design could have some unexpected
                    conditions that create abnormal device behaviour.
                    Just imagine a time-critical scenario: the MCU goes into a locked state mid flight in an drone.
                    The drone either walls or crashes into something and then falls.
                </p>
                <p>
                    A watchdog (WDG) timer is a special timer module that helps the MCU to recover from malfunction.
                    The WDG counts down from an initial value to zero, and when it reaches the end, it resets the processor.
                    This timer can be reset by a special register, so that in normal operating condition it would never reach zero.
                    Consequently, if it reaches zero, we can be sure that it is because of a failure which potentially could be solved by resetting the MCU.
                </p>
                <p>
                    The source of this WDG can be derived from the system clock or some other independent clock source.
                    Some WDG modules implement a refresh window. If we reset the timer outside this specified interval, the reset event occurs.
                    Some WDG modules offer interrupt service routine before the reset so that the user can save critical variables into backup registers.
                </p>
            </div>

            <div className="em__post-section">
                <h3>System Window Watchdog Timer (WWDG):</h3>
                <p>
                    Create a new project for the NUCLEO-G491RE board (or your own board).
                    Get the system clock using the external crystal,
                    enable the Trace Async Debug and configure the USART2 peripheral.
                </p>
                <Popup trigger={<img className="img_sw_schem_hal9 clickable_img" src={sw_schem} alt="sw_schem_hal9"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={sw_schem} alt="sw_schem_hal9" />
                    )}
                </Popup>
                <p>
                    The WWDG uses the system clock source with a fixed and a programmable prescaler.
                    This means that it can achieve precise timing, but when the clock system fails this WDG will not be able to reset the MCU.
                    The WWDG offers refresh window and interrupt event.
                </p>

                <p>
                    To set up the WWDG, go to the System Core, select WWDG and enable it by clicking the Activated box.
                    The frequency of the system clock is 170MHz.
                    After the first prescaler we have <ShowTex string="$170MHz / 4096 \approx 41.5kHz$"/>.
                    The second prescaler is tricky. In the data sheet and in register programming we are setting the power of the division, but in CubeMX we set the division itself.
                    If we set the prescaler to 8 we get a counter frequency of approximately <ShowTex string="$5kHz$"/>, which translates to a clock period of  <ShowTex string="$193\mu s$"/>.
                </p>
                <Popup trigger={<img className="img_sw_win_hal9 clickable_img" src={sw_win} alt="sw_win_hal9"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={sw_win} alt="sw_win_hal9" />
                    )}
                </Popup>
                <p>
                    <b>The window:</b> we have a window value and the free running down-counter value.
                    Let's leave the counter at 127 (max) and the window at 94.
                    The time for the WWDG to count from 127 to 63(0x3F) is <ShowTex string="$12.34 ms$"/>.
                    We can't refresh the timer until it reaches 94 i.e. only after <ShowTex string="$6.36 ms$"/>.
                    So the refresh is allowd after 6.36ms and before 12.34ms.
                </p>
                <p> Enable the early wake up interrupt, and the window watchdog interrupt (from NVIC).</p>

                <Popup trigger={<img className="img_sw_setup_hal9 clickable_img" src={sw_setup} alt="sw_setup_hal9"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={sw_setup} alt="sw_setup_hal9" />
                    )}
                </Popup>

                <p>
                    Before we generate the project we need to set one more thing.
                    We will want to test another WDG module, so it would be nice if we could initialize only the module that we need.
                    For this reason, go to the Project Manager tab, and Advanced Settings.
                </p>
                <p>
                    Here you can see the drivers for all the configured modules.
                    In the Generated Function Calls panel under Do Not Generate Function Call, select the MX_WWDG_Init.
                    This way we can call it whenever and wherever we want it.
                </p>
                <img className="img_pfun_hal9" src={pfun} alt="pfun_hal9"/>
                <p>
                    Generate the project.
                </p>
                <p>
                    Define the use of WWDG with something like <i>#define USE_WWDG</i>.
                    Iclude the standard IO library and copy the write function from the previous tutorial so that we can use the printf.
                </p>
                <p>
                    Let's implement the watchdog interrupt:
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {wwdg_irq}
                </SyntaxHighlighter>
                <p>
                    I've started with a printf statement, but that was too long, so only the first two characters were sent before the system was reset.
                    Instead I opted for toggling the on-board LED. Of course this presumes that we reset the LED at the start of the main program.
                    Normally this Interrupt is used to store only the most vital information into the backup register, which is a fast operation.
                </p>
                <p>
                    Next, the WWDG setup in the main function:
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {wwdg_setup}
                </SyntaxHighlighter>
                <p>
                    Here I've initialised the WWDG, and created a loop to test the window capability.
                    The 8ms delay is between 6.36ms and 12.34ms, so it should work.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {wwdg_loop}
                </SyntaxHighlighter>
                <p>
                    We should test what happens when we don't pet the dog in the given interval. Will it bark?
                    I mean, we are not refreshing the WWDG in the right time period (I've tested the 4ms and 30ms values).
                </p>
                <img className="img_sw_res_hal9" src={sw_res} alt="sw_res_hal9"/>
                <p>
                    We can see on PuTTY that the firmware started, and that the first part in the for loop runs successfully, but then the system is reset.
                    On the board we can see the flashing LED, which means that the IRQ is served.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Independent Watchdog Timer (IWDG):</h3>
                <p>
                    What happens if we willingly or unwillingly stop the system clock?
                    With our current setup, the system will hang, and we have to manually reset it.
                    But here comes the IWDG to the rescue.
                </p>
                <Popup trigger={<img className="img_iw_schem_hal9 clickable_img" src={iw_schem} alt="iw_schem_hal9"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={iw_schem} alt="iw_schem_hal9" />
                    )}
                </Popup>
                <p>
                    The IWDG is a 12b timer (in contrast to the previous 7b one), which is clocked from an independent internal low power clock,
                    thus being active even if the main clock fails.
                    This module does not offer interrupt, only a prescaler, reload value and window value.
                    This time around, we won't use the window (we will make it be equal to the prescaler).
                </p>
                <p>
                    Enable the module by checking the Activated box in System Core, IWDG tab.
                    Set the prescaler to 256 and the down-counter reload value to 4095.
                </p>
                <Popup trigger={<img className="img_iw_setup_hal9 clickable_img" src={iw_setup} alt="iw_setup_hal9"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={iw_setup} alt="iw_setup_hal9" />
                    )}
                </Popup>
                <p>
                    The IWDG clock frequency is <ShowTex string="$32 kHz$"/>, which after division becomes <ShowTex string="$125 Hz$"/> or <ShowTex string="$8 ms$"/> period.
                    This means that we have <ShowTex string="$8ms \cdot 4096 = 32.77s$"/> to reset the timer.
                </p>
                <p>
                    Like before, check the MX_IWDG_Init under Do Not Generate Function Call tab in the Project Manager and re generate the project.
                    uder the previous define create another one, <i>#define USE_IWDG</i> and comment out the old one.
                </p>
                <p>
                    In the main function, start the IWDG, and test the correct operation in a loop with finite elements:
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {iwdg_setup}
                </SyntaxHighlighter>
                <p>
                    Here I've used 1s delays, and our dog should be happy with that.
                    Next, I've created a counter and I've displayed it's value in the main loop
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {iwdg_loop}
                </SyntaxHighlighter>
                <p>
                    If we test this code, we will be greeted by the following messages:
                </p>
                <img className="img_iw_res_hal9" src={iw_res} alt="iw_res_hal9"/>
                <p>Which shows a correct operation.</p>
                <p> You can find the source files for this tutorial <a href="https://gitlab.com/stm32_mcu_group/stm32_hal/9_wdt.git">here</a>.</p>
            </div>



            <div className="em__post-navigation">

                <NavLink to="./../stm-hal-8">
                    <Button btnID={"leftBTN"} buttonSize="btn--medium"> Previous Post</Button>
                </NavLink>

                <NavLink to="./../stm-hal-10">
                    <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
                </NavLink>
            </div>
        </div>
    );
}

export default MCU_HAL9;