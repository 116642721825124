import React from "react";
import "./mcu_hal4.css";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";
import {MathJax, MathJaxContext} from "better-react-mathjax";

import g_tim_schem from "./g_tim_schem.jpg";
import ic1 from "./input_capture1.jpg";
import ic2 from "./input_capture2.jpg";
import ic3 from "./input_capture3.jpg";
import oc1 from "./output_compare1.jpg";
import oc2 from "./output_compare2.jpg";
import pwm1 from "./pwm1.jpg";
import pwm2 from "./pwm2.jpg";

const ic_vars = `
typedef enum{
    IDLE = 0,
    DONE
}measurementState;
measurementState state = IDLE;
volatile uint8_t measurements_done = 0;
volatile uint32_t val_T1 = 0, val_T2 = 0;
volatile uint16_t val_T2_OVC = 0;
volatile uint32_t ticks, frequency;
`;

const ic1_irq = `
void HAL_TIM_IC_CaptureCallback(TIM_HandleTypeDef* htim){
    if(state == IDLE && measurements_done == 0){
        val_T1 = __HAL_TIM_GET_COMPARE(htim, TIM_CHANNEL_1);
        val_T2_OVC = 0;
        state = DONE;
    }else if(state == DONE){
        val_T2 = __HAL_TIM_GET_COMPARE(htim, TIM_CHANNEL_1);
        state = IDLE;
        measurements_done = 1;
    }
}
`;
const ic2_irq = `
void HAL_TIM_PeriodElapsedCallback(TIM_HandleTypeDef* htim){
    if(htim->Instance == TIM2)
        if(measurements_done == 0)
            ++val_T2_OVC;
}
`;

const pwm_irq = `
void HAL_GPIO_EXTI_Callback(uint16_t GPIO_Pin){
    pwm_duty += 2048;
    if(pwm_duty >= 65535)
        pwm_duty = 0;
    __HAL_TIM_SET_COMPARE(&htim4, TIM_CHANNEL_1, pwm_duty);
}
`;

const main_code = `
if(measurements_done == 1){
          ticks = (val_T2 + (val_T2_OVC * 4294967295)) - val_T1;
          frequency = (uint32_t)(F_CLK/ticks);
          measurements_done = 0;
      }
`;

function ShowTex({string}){
    const config = {
        loader: { load: ["[tex]/html"]},
        tex: {packages: {"[+]": ["html"]},
            inlineMath: [["$", "$"]],
            displayMath: [["$$", "$$"]]
        }
    };

    return(
        <MathJaxContext config={config} version={3}>
            <MathJax dynamic inline>
                {string}
            </MathJax>
        </MathJaxContext>
    );
}


function MCU_HAL4(){
    return(
        <div className="em__post">
            <div className="em__post-title">
                <h1>Hardware timers - Capture, Compare and PWM (CCP)</h1>
            </div>
            <div className="em__post-section">
                <h3>Aim of this tutorial:</h3>
                <p>
                    In this tutorial we will check out the general-purpose timers, starting with the single channel capabilities.
                    These are the input capture, output compare and the pulse width modulation.
                </p>
            </div>
            <div className="em__post-section">
                <h3>General-purpose timers:</h3>
                <Popup trigger={<img className="img_g_tim_schem clickable_img" src={g_tim_schem} alt="g_tim_schem"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={g_tim_schem} alt="g_tim_schem" />
                    )}
                </Popup>
                <p>
                    I've attached an image with the block diagram of the general-purpose timer.
                    The source clock is fed into the trigger controller, and then into the prescaler.
                    The new and divided clock signal increments/decrements the counter register (CNT).
                </p>
                <p>
                    There is the Auto-reload register (ARR) for the basic timer functionality,
                    and then there are four separate and independent timer channels with inputs and outputs.

                    We can chose an external pin or an internal register as the <b>input capture</b> source.
                    This value can be prescaled and captured into a Capture/Compare register (CCR) at specific intervals.

                    We can chose an external pin as the <b>output capture</b> source.
                    We can generate some events on the specified pins when the CNT register reaches the value specified in the
                    CCR.
                </p>
            </div>
            <div className="em__post-section">
                <h3>Input Capture :</h3>
                <p>
                    In input capture mode, the CCRx is used to latch the CNT register after an event is detected on the IC signal.
                    When this capture occurs an interrupt (CCxIF) or a direct memory access (DMA) request can be created.
                    If a capture occurs when the CCxIF flag is high, the over capture flag (CCxOF) is set.
                </p>

                <h5>Task:</h5>
                <p>
                    Create a square wave frequency counter using an external input capture pin.
                    Use the live register viewer to read the frequency.
                </p>
                <img className="img_ic1" src={ic1} alt="ic1"/>
                <p>
                    This task requires a simple state machine to track our timer readings (IDLE, DONE).
                    In IDLE state we can read the time stamp of the first rising edge of the input signal, and switch to DONE state.
                    In DONE state we read the time stamp of the second rising edge.
                    We can calculate the frequency of the input signal using the two time stamps with the <ShowTex string="$f = f_{TIM}/(T_2 - T_1)$"/>.
                </p>
                <p>
                    Usually we like to see positive frequencies, which is the case if <ShowTex string="$T_2 > T_1$"/>.
                    The CCR register may overflow in cases of slow input signals, which would result in an incorrect, negative reading.
                    We can track this overflow, and rectify our result.
                    First we need a variable which is zero by default, and we set it to one after the second CCR reading.
                    Meanwhile, whe check the TIM for period elapsed interrupt.
                    If the measurement is not done, but the period elapsed, it means that an overflow occurred, so we increase an overflow counter.
                </p>
                <p>
                    Finally, the frequency formula using the overflow counter is <ShowTex string="$f = f_{TIM}/(T_2  + (max_{uint}*ovf - T_1))$"/>.
                    Here <ShowTex string="$f_{TIM}$"/> is the source clock of the timer after the prescaler,
                    <ShowTex string="$ovf$"/> is the overflow counter and <ShowTex string="$max_{uint}$"/> is the maximum value of the counter.
                </p>

                <p>
                    Let's start coding.
                    First create a project with the NUCLEO-G491RE board, with Trace Async SW Debug,
                    24MHz external Crystal Oscillator and 160MHz internal clock similarly to the previous tutorials.
                </p>
                <p>
                    We will use CH1 of TIM2 for input capture.
                </p>
                <Popup trigger={<img className="img_ic2 clickable_img" src={ic2} alt="ic2"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={ic2} alt="ic2" />
                    )}
                </Popup>
                <br/>
                <br/>
                <p>
                    Use the Internal Clock as the TIM2 Clock source.
                    Leave the Prescaler at 0 so that we will work with 170MHz clock signal.
                    Also, leave the Counter Period at maximum (latter we will set <ShowTex string="$max_{uint} = 4294967295$"/>).
                </p>
                <p>
                    Enable Channel 1, and set it to Input capture direct mode.
                    Leave everything with the default value.
                    The input capture event is triggered on rising edge, no prescaler and no filtering.
                    We are interested in the raw data this time.
                </p>
                <p>
                    When finished, generate the project and let's get to coding.
                </p>
            </div>
            <div className="em__post-section">
                <p>
                    We need the state definitions, the state variable.
                    A variable to check if the measurement is completed, two variables to save the input capture time stamps,
                    A variable to check the overflow, and a few more to calculate the frequency.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {ic_vars}
                </SyntaxHighlighter>
                <p>
                    When a new IC event is detected, the <i>HAL_TIM_IC_CaptureCallback</i> interrupt function is served.
                    If it's the first measurement, then we capture the time stamp, and reset the overflow counter.
                    If it's the second measurement, then we capture the second time stamp and set the completion flag.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {ic1_irq}
                </SyntaxHighlighter>
                <p>
                    We need to increment the overflow counter in the <i>HAL_TIM_PeriodEllapsedCallback</i>, similarly to the previous tutorial.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {ic2_irq}
                </SyntaxHighlighter>
                <p>
                    In the main function first we need to start the base timer <i>HAL_TIM_Base_Start_IT(&htim2)</i> and the input capture
                    <i>HAL_TIM_IC_Start_IT(&htim2, TIM_CHANNEL_1)</i>.
                </p>
                <p>
                    Finally, we can calculate the frequency in the main loop when there is a valid data present.
                    We are checking the value of the <i>measurement_done</i> flag.
                    If it's set, we can calculate the tick difference between the two time stamps, taking into consideration the overflow.
                    We can give the measured frequency by knowing the frequency of the source clock (170 MHz).
                </p>

                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {main_code}
                </SyntaxHighlighter>
                <p>
                    And here's an example, where I attached a loose wire into the IC pin, and I put it near a mains supply line.
                    The 50Hz is picked up in an instant.
                </p>
                <Popup trigger={<img className="img_ic3 clickable_img" src={ic3} alt="ic3"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={ic3} alt="ic3" />
                    )}
                </Popup>
                <p>
                    More testing will be done, when we learn how to display this data in a more user friendly manner.
                </p>
            </div>
            <div className="em__post-section">
                <h3>Output Compare:</h3>
                <p>
                    In output compare mode, the CCRx is compared to the increasing/decreasing CNT register.
                    When the contents of the two registers match, an event can be generated.
                    We can use this event to generate an internal timing signal, or to set/clear/toggle an external pin.
                </p>

                <h5>Task:</h5>
                <p>
                    Create a timer with a period of 2s.
                    Light up four LEDs in sequence using four OC pins at quarter, half three quarters an full count values.
                </p>
                <Popup trigger={<img className="img_oc1 clickable_img" src={oc1} alt="oc1"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={oc1} alt="oc1" />
                    )}
                </Popup>

                <p>
                    We will configure TIM3 for this task.
                </p>
                <p>
                    First, let's scale down the frequency of the source clock.
                    If we use 42500 as the prescaler, we get a frequency of 4000Hz.
                    That is more manageable.
                </p>
                <p>
                    Enable all four channels as Output Compare CHx. Set the mode of every channel to Toggle on match,
                    disable the preload and set CH polarity to high.
                </p>
                <p>
                    Next, set the Pulse value to 1000, 2000, 3000, 4000, which means that when the CNT reaches the specified values,
                    the CH1, CH2, CH3, CH4 outputs will toggle respectively.
                </p>
            </div>
            <div>
                <p>
                    In the main function configure the output compare channels using the <i>HAL_TIM_OC_Start(&htim3, TIM_CHANNEL_x)</i> command,
                    where x is 1, 2, 3, 4.
                    Then start the TIM module itself using <i>HAL_TIM_Base_Start(&htim3)</i>.
                </p>
                <p>
                    After building and uploading the code, you should be greeted with sequential led lighting show.
                    For better capture purposes I've used a Saleae Logic analyser with Logic 2 software, and here are the generated signals:
                </p>
                <Popup trigger={<img className="img_oc2 clickable_img" src={oc2} alt="oc2"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={oc2} alt="oc2" />
                    )}
                </Popup>
                <p>
                    It can be seen that the toggle occurs every second on each channel, and the time delay between the channels is around 250ms.
                </p>
            </div>
            <div className="em__post-section">
                <h3>Pulse width modulation (PWM):</h3>
                <p>
                    The PWM mode allows to generate a signal with a frequency determined by the value of the ARR,
                    and a duty cycle determined by the value of CCR.
                </p>

                <h5>Task:</h5>
                <p>
                    Design a dimmable LED circuit where the duty is increased when a button is pushed.
                </p>
            </div>
            <div className="em__post-section">
                <p>
                    We will use the on-board button configured as external interrupt, and the first channel of TIM4 as PWM output.
                </p>
                <Popup trigger={<img className="img_pwm1_hal4 clickable_img" src={pwm1} alt="pwm1"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={pwm1} alt="pwm1" />
                    )}
                </Popup>
                <p>
                    Select TIM4 and choose Internal Clock as clock source.
                    Set Channel 1 as PWM Generation CH1.
                </p>
                <p>
                    Set the prescaler to 5 and the Counter Period to 65535. This will give us a duty cycle with 16b depth,
                    and a PWM frequency of around 520Hz, which is more than enough so that we don't see light flicker on the LED.
                </p>
                <p>
                    In the PWM Generation window, set the mode to PWM 1 - In upcounting channel, the output is active as long as CNT &lt; CCR else inactive.
                    In downcounting channel, the output is inactive as long as CNT &gt; CCR else active.
                    In PWM 2 mode the signal is inverted.
                </p>
                <p>
                    Set the Pulse to 0 - this is the duty cycle register.
                    Set the Output compare preload to Enabled, since we want to change the duty quite often.
                    Disable the fast mode - 500 Hz is not considered fast.
                    Set the CH Polarity to High, so that the active state means a logic <b>1</b> on the output.
                </p>
                <p>
                    (Re)Generate the project.
                </p>
                <p>
                    use a global variable for the duty cycle - this can be done without the volatile tag, since we will only use it in the interrupt.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {pwm_irq}
                </SyntaxHighlighter>
                <p>
                    At every push of the user button we will increment the duty variable by a predefined value and copy this value to the CCR of the TIM4 CH1.
                </p>

                <p>
                    Finally, in the main function start the PWM with the function <i>HAL_TIM_PWM_Start(&htim4, TIM_CHANNEL_1)</i>.
                    I've captured the output of the TIM in four different states. After 1, 8, 16 and 24 button pushes.
                </p>
            </div>

            <div className="em__post-section">
                <Popup trigger={<img className="img_pwm2_hal4 clickable_img" src={pwm2} alt="pwm2"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={pwm2} alt="pwm2" />
                    )}
                </Popup>
                <p>
                    Of course, this only scratches the surface of the PWM capabilities, not to mention that of the general purpose timers.
                    I encourage you to read the IC manual and try out these features.
                    Until then, the source code can be downloaded from <a href="https://gitlab.com/stm32_mcu_group/stm32_hal/4_tim_general">here</a>.
                </p>
            </div>



            <div className="em__post-navigation">

                <NavLink to="./../stm-hal-3">
                    <Button btnID={"leftBTN"} buttonSize="btn--medium"> Previous Post</Button>
                </NavLink>

                <NavLink to="./../stm-hal-5">
                    <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
                </NavLink>
            </div>
        </div>
    );
}

export default MCU_HAL4;