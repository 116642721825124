import React from "react";
import "./../hobby.css";
import "./../collection.css";
import {Tag} from "../../../common";
import {useNavigate} from "react-router-dom";
import {useRef} from "react";


function WHITEHUB(){
    const navigate = useNavigate();
    const yg20Ref = useRef(null);
    const cyycRef = useRef(null);
    const mwakYSRef = useRef(null);
    const yg21MLRef = useRef(null);
    const yg22MLRef = useRef(null);
    const bdmrYSRef = useRef(null);
    const mmsc13YSRef = useRef(null);
    const ogjMLRef = useRef(null);
    const mpsMLRef = useRef(null);
    const hthjYSRef = useRef(null);
    const cnnpMQMICYSRef = useRef(null);
    const dddMLRef = useRef(null);
    const pgg06YSRef = useRef(null);
    const gzzywmYSRef = useRef(null);
    const jyf1FLRef = useRef(null);
    const gzznmYSRef = useRef(null);
    const yptywcYSRef = useRef(null);
    const sir22YSRef = useRef(null);
    const cttxtaKTMRef = useRef(null);
    const mwakKTMRef = useRef(null);
    const mkdsmcKTMRef = useRef(null);
    const fzh23FLRef = useRef(null);
    const mls19YSRef = useRef(null);
    const jm1FLRef = useRef(null);
    const jg1FLRef = useRef(null);
    const jyf2FLRef = useRef(null);
    const tjtcKTMRef = useRef(null);
    const bmbzcKTMRef = useRef(null);
    const nhjmgsKTMRef = useRef(null);

    const tealist = [
        {name:"1. Young gushu 2020 (ML)", ref: yg20Ref},
        {name:"2. Chen Yun Yuan cha (YS)", ref: cyycRef},
        {name:"3. 2016 Mengku Wild arbour king (YS)", ref: mwakYSRef},
        {name:"4. Young gushu 2021 (ML)", ref: yg21MLRef},
        {name:"5. 2019 Mo Lie Shan (YS)", ref: mls19YSRef},
        {name:"6. Young gushu 2022 (ML)", ref: yg22MLRef},
        {name:"7. Ba Da mountain raw (YS)", ref: bdmrYSRef},
        {name:"8. 2013 Mengku Mu shu cha (YS) ", ref: mmsc13YSRef},
        {name:"9. Office Gossip juice (ML)", ref: ogjMLRef},
        {name:"10. Mom and pop shop (ML)", ref: mpsMLRef},
        {name:"11. 2019 Heng Tong Hao Jingmai honey aroma (YS)", ref: hthjYSRef},
        {name:"12. 2003 CNNP Mengsong qiao mu iron cake (YS)", ref: cnnpMQMICYSRef},
        {name:"13. Day drinking diva (ML)", ref: dddMLRef},
        {name:"14. 2006 Pasha gushu Guangdong aged tea (YS)", ref: pgg06YSRef},
        {name:"15. 2017 Guan zi zai Yi Wu mountain tea (YS)", ref: gzzywmYSRef},
        {name:"16. Jingmai Yellow flakes (FL)", ref: jyf1FLRef},
        {name:"17. 2017 Guan zi zai Nannuo mountain tea (YS)", ref: gzznmYSRef},
        {name:"18. 2007 YiPinTang Yi Wu Zhi Chun tea (YS)", ref: yptywcYSRef},
        {name:"19. Jingmai Miyun (FL)", ref: jm1FLRef},
        {name:"20. Jingmai Gulan (FL)", ref: jg1FLRef},
        {name:"21. 2022 Silver Impression cake (YS)", ref: sir22YSRef},
        {name:"22. 2006 ChangTai Tian Xia Tong An (KTM)", ref: cttxtaKTMRef },
        {name:"23. 2021 MengKu RongShi Qiao Mu Wang (KTM)", ref: mwakKTMRef},
        {name:"24. 2021 Spring Meng Ku Da Shu Mao Cha (KTM)", ref: mkdsmcKTMRef},
        {name:"25. 2023 Fa Zhan He (FL)", ref: fzh23FLRef},
        {name:"26. Jingmai Yellow flakes (FL)", ref: jyf2FLRef},
        {name:"27. Xiaguan Te Ji Tuo Cha (KTM)", ref: tjtcKTMRef},
        {name:"28. BaoMing Ba Zi Cha (KTM)", ref: bmbzcKTMRef},
        {name:"29. NaHong Jing Mai Gu Shu (KTM)", ref: nhjmgsKTMRef},

    ];

    return (
        <div className="em__post">
            <div className="em__post-section">
                <h3>About the history and production of raw pu'er teas</h3>
                <p>
                    Often referred to as the "raw" or "green" variety of pu'er tea, sheng pu'er is a living testament to the ancient art of tea crafting.
                    Sheng Pu'er's history can be traced back over a millennium to the mountains of Yunnan, China.
                    Evolving from the tea bricks that were originally compressed for ease of transportation along the ancient Tea Horse Road,
                    sheng tea gained prominence for its ability to age and transform over time, gaining complexity and depth.
                    Although some people regard this tea as green tea (especially the younger ones),
                    there is undeniable evidence of post-fermentation in contrast to other green teas, which only get stale.
                </p>
                <p>
                    The process is simple: picking, withering, sun-drying, rolling and shaping and natural ageing.
                </p>
                <ul>
                    <li>
                        <b>Plucking:</b>
                        The journey of sheng tea begins in the high-altitude tea gardens of Yunnan, where the leaves of the broad-leafed Camellia Sinensis var. assamica are meticulously cultivated.
                        Timing is crucial in the harvest, as the young leaves and buds, often comprising the top two leaves and a bud, are plucked during the early spring to ensure optimal flavour.
                    </li>
                    <li>
                        <b>Withering and Sun-drying:</b>
                        After harvesting, the leaves undergo a brief withering process to reduce moisture.
                        Unlike its shou (ripe) counterpart, sheng tea is then sun-dried, initiating minimal oxidation.
                        This step, unique to sheng pu'er, allows the leaves to retain their natural green hue and preserves the potential for further ageing.
                        Of course, there are new types that have developed that are more oxidised - called reddened sheng.
                    </li>
                    <li>
                        <b>Rolling and shaping:</b>
                        The withered leaves are gently rolled to shape them into the characteristic form of sheng cakes or loose-leaf tuos (nests).
                        This step ensures that the leaves are compacted for ageing yet retain the space needed for airflow to facilitate fermentation.
                    </li>
                    <li>
                        <b>Natural fermentation and aging:</b>
                        The hallmark of sheng pu'er is its natural fermentation process.
                        Stored in a cool, dark, and humid environment, the tea undergoes slow microbial fermentation.
                        This transformation is a dynamic interplay of time, microbes, and environmental conditions, resulting in a tea that evolves with age,
                        developing unique flavours, aromas, and textures.
                    </li>
                </ul>
                <p>
                    Young Sheng tea, known as "mao cha", is often vibrant, with a lively, astringent character, floral and grassy notes, and hints of bitterness.
                    As it ages, the flavour profile mellows, revealing complexities such as honey sweetness, fruity undertones, and the prized "aged" aroma.
                    <br/>
                    There are some regions that produce strong teas; others are more floral, fruity, and mild.
                    All these flavour profiles can be changed or strengthened by the ageing process.
                    The age of the tree also changes the taste and, consequently, the price of the tea.
                    And here lies the opportunity of lying for a better price (just as in the case of wine).
                    Without proper tree analysis, there is no real method of knowing the age of the tree.
                    Also, the tree may be gushu (old leaf) tree, but the leaves are at most 1-2 years old.
                    <br/>
                    I'm not a tea collector.
                    I'm buying to drink, and enjoy the tea. So, I would rather buy from an unknown location (it tends to sell for cheaper), and most of the time, it's as good as the well-known ones.
                    Also, I want to taste the tea, not the storage, so I go for younger teas most of the time.
                    That being said, I would not refuse a sample or two of aged Lao Man'E or Lao Ban Zhang tea :).
                </p>
            </div>

            <div className="em__collection">
                <div className="em__collection-title">
                    <h1 className="gradient__text">Sampled white teas:</h1>
                </div>

                <div className="em__collection-list">
                    {
                        tealist.map((item) => (
                            <div >
                                <Tag title={item.name} description={item.description} onClick={() => item.ref.current.scrollIntoView({ behavior: 'smooth' })}/>
                            </div>
                        ))
                    }
                </div>

                <div className="em__post-section">
                    <div id="1. Young gushu 2020 (ML)" ref={yg20Ref}>
                        <h3>1. Young Gushu 2020 (ML)</h3>
                        <p>
                            This tea is supposedly from ancient tea trees in Bang Dong Da Xue Shan, Yunnan, China.
                            A bud and up to three leaves were harvested in Spring 2020.
                            The tea was stored for a short time as mao cha.

                            <br/>
                            It has a melon and banana cream taste with minimal astringency and sweetness, which goes well with the jasmine, honey, and grass aroma of the wet leaves.
                            I used around 5g worth of tea in a 100ml gaiwan with boiling water.
                            On average, the sessions lasted for ten brews.
                            <br/>
                            Green/Vegtal: 8/10, Floral: 5/10, Fruits: 8/10, Spices: 4/10, Wood/Earth: 1/10, Roast/Nuts: 1/10, Cream: 6/10, Mineral: 8/10.
                        </p>
                    </div>
                    <div id="2. Chen Yun Yuan cha (YS)" ref={cyycRef}>
                        <h3>2. Chen Yun Yuan cha (YS)</h3>
                        <p>
                            This sheng is composed of Spring 2008 material from Lincang.
                            The mao cha was aged in Dali and pressed into the cake in 2011.
                            The strong astringency of its youth has dissipated slightly,
                            leaving the drinker to enjoy sweetness, pungent cha qi, and some hints of flower aroma.

                            <br/>
                            The tea material consists of forest green and brown leaves with silver buds and smells like sweet apricots and pine resin.
                            After a rinse, the leaves turned to a khaki colour with multiple shades of green.
                            The smell of the wet leaves was reminiscent of green peppercorns, honey, pine and tobacco.
                            The liquor was thick and soft with sandy brown, pale orange, translucent.
                            The brew tasted like honey, minerals, and tropical flowers. Slightly astringent, slightly sweet.
                            The smell of the empty cup was caramelised honey.
                            The tea left a long-lasting pine flavour and dryness.


                            I used around 4.5g worth of tea in a 100ml gaiwan with boiling water.
                            On average, the sessions lasted for 9+ brews.
                            <br/>
                            Green/Vegtal: 2/10, Floral: 2/10, Fruits: 6/10, Spices: 8/10, Wood/Earth: 0/10, Roast/Nuts: 0/10, Cream: 1/10, Mineral: 5/10.
                        </p>
                    </div>
                    <div id="3. 2016 Mengku Wild arbor king (YS)" ref={mwakYSRef}>
                        <h3>3. 2016 Mengku Wild arbor king (YS)</h3>
                        <p>
                            Wild Arbor King was first introduced in 2006 by Shuangjiang Mengku Tea Factory,
                            and it quickly became the most popular pu'er in Mengku.
                            This tea contains entirely the first flush material of spring 2016.

                            <br/>
                            The tea material consists of mustard green leaves, some with purple tinge and gold buds, and
                            smell like raisins, sandalwood, pine resin, and sweetened figs.
                            After a rinse, the leaves turned to a khaki and olive colour.
                            The smell of the wet leaves was reminiscent of dried cherries, tobacco, mixed citrus juice, and pine.
                            The liquor was thick, oily and mineral with a light butterscotch colour.
                            The brew tasted like a sweet musk, ripe plum, dried cherry, tobacco, orchids, and honey, with a slight bitterness.
                            The smell of the empty cup was cashew, caramel, and pine resin.
                            The tea left a tingly dryness, citrus and floral sweetness in the mouth.

                            I used around 5g worth of tea in a 100ml gaiwan with boiling water.
                            On average, the sessions lasted for 14 brews.
                            This is one of my favourite shengs of all time. Ever since I tasted this, I've been looking forward to buying a Wild Arbor King blend.
                            <br/>
                            Green/Vegtal: 2/10, Floral: 1/10, Fruits: 9/10, Spices: 9/10, Wood/Earth: 0/10, Roast/Nuts: 0/10, Cream: 0/10, Mineral: 5/10.
                        </p>
                    </div>
                    <div id="4. Young gushu 2021 (ML)" ref={yg21MLRef}>
                        <h3>4. Young Gushu 2021 (ML)</h3>
                        <p>
                            This tea is supposedly from ancient tea trees in Ba Da, Yunnan, China.
                            A bud and up to three leaves were harvested in Spring 2021.
                            The tea was stored for a short time as mao cha.

                            <br/>
                            Complex citrus, ripe pear and honey aroma.
                            Linden flower, lime, chestnut, kiwi, cut grass flavour with slight mineral and dry aftertaste.
                            The tea is thick and very potent.


                            I used around 5g worth of tea in a 100ml gaiwan with boiling water.
                            On average, the sessions lasted for ten brews.
                            <br/>
                            Green/Vegtal: 5/10, Floral: 6/10, Fruits: 5/10, Spices: 2/10, Wood/Earth: 0/10, Roast/Nuts: 7/10, Cream: 7/10, Mineral: 8/10.
                        </p>
                    </div>
                    <div id="5. 2019 Mo Lie Shan (YS)" ref={mls19YSRef}>
                        <h3>5. 2019 Mo Lie Shan (YS)</h3>
                        <p>
                            Mo Lie Shan is a mountain area south of Bing Dao in MengKu.
                            This tea is made from 70 to 120-year-old tea tree material picked in April of 2019.

                            <br/>
                            Khaki and forest green leaves, silver buds that smell like mangosteen and apricots.
                            After a rinse, the leaves turned uniform forest green with banana essence and a walnut ice cream smell.
                            The liquor was light and coated with lime-tinged pale gold colour.
                            The brew tasted like poached pears and cantaloupe with a slight mineral and astringent taste.
                            The smell of the empty cup was reminiscent of sweet soy sauce and boiled peanuts.
                            The tea had a juicy, lingering, sweet aftertaste.

                            I used around 7g worth of nuggets in a 100ml gaiwan with boiling water.
                            On average, the sessions lasted for 11+ brews.
                            <br/>
                            Green/Vegtal: 0/10, Floral: 0/10, Fruits: 3/10, Spices: 2/10, Wood/Earth: 9/10, Roast/Nuts: 7/10, Cream: 6/10, Mineral: 6/10.
                        </p>
                    </div>
                    <div id="6. Young gushu 2022 (ML)" ref={yg22MLRef}>
                        <h3>6. Young Gushu 2022 (ML)</h3>
                        <p>
                            This tea is supposedly from ancient tea trees in Li Da village, Lincang, Yunnan, China.
                            A bud and up to three leaves were harvested in Spring 2022.
                            The tea was stored for a short time as mao cha.

                            <br/>
                            Pine honey, ripe banana, custard pudding.
                            Extremely floral, slightly mineral with a long-lasting sweet aftertaste.


                            I used around 5g worth of tea in a 100ml gaiwan with boiling water.
                            On average, the sessions lasted for 11 brews.
                            <br/>
                            Green/Vegtal: 7/10, Floral: 7/10, Fruits: 9/10, Spices: 7/10, Wood/Earth: 3/10, Roast/Nuts: 1/10, Cream: 5/10, Mineral: 7/10.
                        </p>
                    </div>
                    <div id="7. Ba Da mountain raw (YS)" ref={bdmrYSRef}>
                        <h3>7. Ba Da mountain raw (YS)</h3>
                        <p>
                            Ba Da Mountain's First Flush of Spring 2015 tea leaves were picked and processed into mao cha.
                            (loose-leaf raw pu-erh tea) and then aged for five years in Menghai County of Xishuangbanna before being pressed in March 2020.
                            The storage condition in Menghai was clean with a moderate amount of humidity (wetter than Kunming, but not as wet as Guangdong)
                            giving the tea a nice rounded, lightly aged taste.

                            <br/>
                            The dry leaves had moss green leaves and silver buds with some cream and purple tinge.
                            The dry leaves smelled like musk melon, honey and raisins.
                            The wet leaves smelled like fresh kiwi, elderflower honey, pine resin and citrus zest.
                            The pale gold liquor was medium thick, brothy and coated.
                            The brew tasted like kiwi purée, peanut soup and poached pears.
                            It was astringent, a bit bitter and had medium minerality.
                            The mouth finish was like pumpkin cream soup miked with pine forest air with a dry mouth feel.
                            The empty cup smelled like sugar cane and linden syrup.

                            I used around 6g worth of leaf in a 100ml gaiwan with boiling water.
                            On average, the sessions lasted for 14 consistent brews.

                            One of the memorable sheng teas.
                            <br/>
                            Green/Vegtal: 1/10, Floral: 3/10, Fruits: 6/10, Spices: 7/10, Wood/Earth: 0/10, Roast/Nuts: 3/10, Cream: 4/10, Mineral: 6/10.
                        </p>
                    </div>
                    <div id="8. 2013 Mengku Mu shu cha (YS) " ref={mmsc13YSRef}>
                        <h3>8. 2013 Mengku Mu shu cha (YS)</h3>
                        <p>
                            Mu Shu Cha, or "Mother Tree Tea", is made from a blend of Bing Dao wild arbour tea leaves and other Mengku area tea leaves from Spring 2013.
                            Mu Shu Cha has been one of Mengku's premium standards since its first production in 2005.

                            <br/>
                            The dry tea consists of khaki, plum and cream medium and large leaves that smell like candy and grass.
                            The wet leaves turn khaki and have a citrus-like and tobacco-like smell.
                            The golden tea liquor has a medium-thick, oily texture.
                            The brew had some initial brothy, savoury notes.
                            This turned into a potent, sweet liquor with apricot nectar, pine needle taste and a bit of astringency.

                            This was a nice daily drinker tea that looked soft but could pack a punch in the long run.
                            The empty cup smelled like citrus rind and sugar cane.
                            The brew left a juicy, lingering, sweet aftertaste in the mouth.

                            I used around 5g worth of leaf in a 100ml gaiwan with boiling water.
                            On average, the sessions lasted for 13 brews.

                            This was a 500g tea cake that went for $60 (at the time), and I would gladly buy another whole cake again.
                            <br/>
                            Green/Vegtal: 2/10, Floral: 4/10, Fruits: 6/10, Spices: 6/10, Wood/Earth: 2/10, Roast/Nuts: 0/10, Cream: 3/10, Mineral: 5/10.
                        </p>
                    </div>
                    <div id="9. Office Gossip juice (ML)" ref={ogjMLRef}>
                        <h3>9. Office Gossip Juice (ML)</h3>
                        <p>
                            This is a huang pian tea made from old (allegedly gu shu) leaves.
                            Huang pian (yellow leaves) are the larger leaves or bruised leaves that turn yellow during the processing.
                            These leaves are separated and usually preserved for the farmers.
                            Huang pian tea has a taste similar to that of the young leaves, but it's more mineral, lighter, and brighter.

                            This tea was made in the spring of 2019 in Yiwu, Yunnan, China.

                            <br/>
                            The dry tea consists of brown and green medium and large leaves that smell like mangosteen.
                            The wet leaves turn khaki and smell like lime zest and fresh morning air.
                            The rose golden tea liquor has a medium texture.
                            The brew had a light green taste, with a hint of lime and whortleberry leaves.
                            Minimally astringent.
                            Nice, soft tea for gloomy mornings or afternoons.
                            The empty cup smelled like custard, and the tea left a dry and sweet juicy mouth feel.

                            I used around 5g worth of leaf in a 100ml gaiwan with boiling water.
                            On average, the sessions lasted for ten brews.

                            It was a good tea, but considering that it was a fresh huang pian, there was no reason for it to be as expensive as it was (gu shu or not).
                            <br/>
                            Green/Vegtal: 6/10, Floral: 6/10, Fruits: 6/10, Spices: 2/10, Wood/Earth: 1/10, Roast/Nuts: 2/10, Cream: 5/10, Mineral: 6/10.
                        </p>
                    </div>
                    <div id="10. Mom and pop shop (ML)" ref={mpsMLRef}>
                        <h3>10. Mom and pop shop (ML)</h3>
                        <p>
                            This is another huang pian tea made from old (allegedly gu shu) leaves.
                            This tea was made in the spring of 2021 in Yiwu, Yunnan, China.

                            <br/>
                            The dry tea consists of khaki, prune and tan medium and large leaves that smell like caramel and persimmon.
                            The wet leaves turn crocodile green and smell like citrus rind and sorrel.
                            The peach gold tea liquor has a medium and syrupy texture.
                            The brew had slightly sweet and acidic aged whortleberry leaves to taste.
                            It was smooth and mineral.
                            The empty cup smelled like cooked mango, and the tea left a dry mouth with minty, cooling juiciness.

                            I used around 5g worth of leaf in a 100ml gaiwan with boiling water.
                            On average, the sessions lasted for ten brews.

                            As the previous huang pian, it was a good tea, but not in the daily drinking price range.
                            <br/>
                            Green/Vegtal: 6/10, Floral: 6/10, Fruits: 6/10, Spices: 2/10, Wood/Earth: 1/10, Roast/Nuts: 2/10, Cream: 5/10, Mineral: 6/10.
                        </p>
                    </div>
                    <div id="11. 2019 Heng Tong Hao Jingmai honey aroma (YS)" ref={hthjYSRef}>
                        <h3>11. 2019 Heng Tong Hao Jingmai honey aroma (YS)</h3>
                        <p>
                            This is a 2019 pressing of 2017 Spring tea leaves harvested from Jingmai mountain area tea bushes.
                            The two years of ageing as loose leaf mao cha in Menghai has softened and warmed up this lovely tea, giving it a honey aroma and sweet taste.

                            <br/>
                            The dry tea consists of khaki, plum and cream medium and large leaves that smell like peach nectar and sweet potato.
                            The wet leaves turn bright citrus green with red spots and have a floral and cherry smell.
                            The pale golden tea liquor has a thick, viscous texture.
                            The brew, as its name suggests, was sweet like honey.
                            It had notes of nougat, pineapple, honeysuckle and marzipan aroma.
                            The brews were potent but without any noticeable astringency.

                            The empty cup smelled like maple syrup and peach compote.
                            The brew had a long-lasting sweet-sour aftertaste.

                            I used around 5g worth of leaf in a 100ml gaiwan with boiling water.
                            On average, the sessions lasted for 12 brews.

                            This was a 500g tea cake that went for $60 (at the time), and I would gladly buy another whole cake again.
                            <br/>
                            Green/Vegtal: 0/10, Floral: 2/10, Fruits: 8/10, Spices: 4/10, Wood/Earth: 0/10, Roast/Nuts: 0/10, Cream: 2/10, Mineral: 4/10.
                        </p>
                    </div>
                    <div id="12. 2003 CNNP Mengsong qiao mu iron cake (YS)" ref={cnnpMQMICYSRef}>
                        <h3>12. 2003 CNNP Mengsong qiao mu iron cake (YS)</h3>
                        <p>
                            A small Menghai area tea factory probably produced this cake under the CNNP (Zhong cha) label.
                            Likely not a licensed CNNP production.
                            Stored in Menghai since 2003, this has aged nicely with some wetter storage notes.
                            Overall, the storage condition was very clean, and the cake had a high level of aroma, as well as
                            that characteristic of the Mengsong flower, which was bitter and astringent.

                            <br/>
                            The dry material consists of plum and cream leaves with silver buds.
                            The dry leaves smelled like resin and tobacco.
                            The wet leaves smelled like peaches, musky cellar and squash purée.
                            The liquor was dark brown and gold, and the texture was thick and viscous.
                            The brew tasted like nectarine, smoky citrus zest and some sort of old, musky cellar.
                            This would've been all nice, but it was astringent and sour (or both?) as hell.
                            I had to shorten the brews, and it got to a point where the water went into gaiwan,
                            and the liquid was poured out almost immediately to be bearable.

                            The empty cup smelled like peaches.
                            The tea left a sour and drying aftertaste that turned into herbal sweetness.

                            I used around 5g worth of leaf in a 100ml gaiwan with boiling water.
                            On average, the sessions lasted for 13 consistent brews, but for me, the infusions started to be bearable after five or so.

                            <br/>
                            Green/Vegtal: 5/10, Floral: 5/10, Fruits: 7/10, Spices: 5/10, Wood/Earth: 3/10, Roast/Nuts: 1/10, Cream: 1/10, Mineral: 9/10.
                        </p>
                    </div>
                    <div id="13. Day drinking diva (ML)" ref={dddMLRef}>
                        <h3>13. Day drinking diva (ML)</h3>
                        <p>
                            This was a spring 2021 harvest blend from He Kai and Nan Nuo's old trees.
                            The first one is renowned for deep mineral and potent teas, while the second has a fruity character.

                            <br/>
                            The dry material consists of young dark green and brown leaves with a lot of fur covering silver buds.
                            The dry leaves smelled like kiwi, sweet potato puree, and vanilla panna cotta.
                            The wet leaves were extremely spicy, and the bay leaf was allspiced with a hint of sweet thyme.
                            The light gold liquor had a pinkish undertone, and the texture was thick, mineral and oily.
                            The brew had mineral notes with overflowing spiciness. Ginger wine, rosemary, baked pears, and juniper berries with a hint of smoke.
                            The empty cup smelled like agave nectar and apple purée.
                            The tea left an extremely mineral aftertaste, medium astringency and long-lasting sweetness.

                            I used around 5g worth of leaf in a 100ml gaiwan with boiling water.
                            On average, the sessions lasted for ten consistent brews.

                            <br/>
                            Green/Vegtal: 0/10, Floral: 3/10, Fruits: 7/10, Spices: 7/10, Wood/Earth: 6/10, Roast/Nuts: 0/10, Cream: 0/10, Mineral: 9/10.
                        </p>
                    </div>
                    <div id="14. 2006 Pasha gushu Guangdong aged tea (YS)" ref={pgg06YSRef}>
                        <h3>14. 2006 Pasha gushu Guangdong aged tea (YS)</h3>
                        <p>
                            This is a spring 2006 Pasha Mountain raw pu-erh tea cake that has been aged
                            in Guangdong dry storage conditions for about 11 years.
                            The taste is smooth and mellow, with a good body and strong mouthfeel.

                            <br/>
                            The dry leaves had crocodile green and plum large leaves with some mustard-coloured buds and some stems.
                            The smell was reminiscent of some sweet and savoury dishes, maybe apricots and hay.
                            The wet leaves smelled like moss, mushrooms, poached peaches and nectarines.
                            The deep gold liquor was medium thick and oil-like.
                            The brew had notes of apricot compote, green tobacco and kiwi with strong minerality and astringency.

                            I used around 5g worth of leaf in a 100ml gaiwan with boiling water.
                            On average, the sessions lasted for 13 infusions.
                            <br/>
                            Green/Vegtal: 4/10, Floral: 2/10, Fruits: 8/10, Spices: 2/10, Wood/Earth: 3/10, Roast/Nuts: 0/10, Cream: 0/10, Mineral: 9/10.
                        </p>
                    </div>
                    <div id="15. 2017 Guan zi zai Yi Wu mountain tea (YS)" ref={gzzywmYSRef}>
                        <h3>15. 2017 Guan zi zai Yi Wu mountain tea (YS)</h3>
                        <p>
                            Guan Zi Zai is a small Xishuangbanna-based tea producer that specialises in
                            Banna teas include Yi Wu, Nan Nuo, Mengsong, Bu Lang, Pa Sha, and others.
                            This Yi Wu production is composed of the first flush of spring 2017 tea leaves harvested from
                            plantation bushes growing in the Yi Wu mountains.
                            It's not a single village tea but rather a blend of teas from one general area (Yi Wu).

                            <br/>
                            The dry material had mustard green, plum and khaki medium leaves with cream buds.
                            The smell was floral, like a meadow in spring.
                            The wet leaves looked like pickled mustard greens and smelled like hay and linden flowers.
                            The tea soup was medium thick, and coating with a pale gold color.
                            The brew had notes of linden flower, mulberry, sweet nectar, agave syrup and medium astringency.

                            I used around 5g worth of leaf in a 100ml gaiwan with boiling water.
                            On average, the sessions lasted for nine infusions.
                            <br/>
                            Green/Vegtal: 0/10, Floral: 10/10, Fruits: 4/10, Spices: 3/10, Wood/Earth: 0/10, Roast/Nuts: 0/10, Cream: 3/10, Mineral: 7/10.
                        </p>
                    </div>
                    <div id="16. Jingmai Yellow flakes (FL)" ref={jyf1FLRef}>
                        <h3>16. Jingmai Yellow flakes (FL)</h3>
                        <p>
                            This huang pian cake is pressed from spring and autumn ancient tea garden and plantation material in a 50:50 ratio.
                            Yellow flakes are tea leaves which were too mature when picked and take a distinctive aspect through the processing.
                            They become apparent once the tea is dry.
                            They are typically sorted out of the tea because of their different look; they also taste different from typical leaves.
                            They tend to brew out sweet and gentle, without much bitterness and punch.

                            <br/>
                            The dry leaves had swamp green and yellow leaves.
                            When I opened the wrapper, I smelled meadow flowers and forest berries with that slight iron oxide.
                            The wet leaves smelled like European blueberries and moss.
                            The pale gold liquor was medium thick and brothy.
                            It had a nice mineral taste with citrus and apple puree notes, and the aftertaste was like that of fallen oak leaves in late summer.
                            (yeah, I tasted that as an idiot kid).

                            I used around 6g worth of leaf in a 100ml gaiwan with boiling water.
                            On average, the sessions lasted for nine consistent brews.

                            I gladly buy this cake. It has all the floral and spicy characteristics of old tea trees
                            but lacks the strong punch, so it's ideal for mornings before breakfast or late afternoon sessions.
                            <br/>
                            Green/Vegtal: 1/10, Floral: 7/10, Fruits: 5/10, Spices: 3/10, Wood/Earth: 1/10, Roast/Nuts: 1/10, Cream: 3/10, Mineral: 10/10.
                        </p>
                    </div>
                    <div id="17. 2017 Guan zi zai Nannuo mountain tea (YS)" ref={gzznmYSRef}>
                        <p>
                            Sed euismod, felis eget dictum ultricies, nisi nibh euismod nisl,
                        </p>
                    </div>
                    <div id="18. 2007 YiPinTang Yi Wu Zhi Chun tea (YS)" ref={yptywcYSRef}>
                        <h3>18. 2007 YiPinTang Yi Wu Zhi Chun tea (YS)</h3>
                        <p>
                            This tea cake is composed of spring 2007 harvested Yi Wu Mountain area tea leaves and aged in
                            Jinghong (Xishuangbanna) for ten years before being brought to Kunming in 2017.
                            This is a delicious semi-aged cake that tastes more aged than its years while being very clean and retaining its original Yi Wu character.
                            The tea brews up an orange-red tea soup that is viscous and sweet at first taste.
                            The tea also has some mineral, dried fruit and spice characters and shows many sides during a typical gong fu session.

                            At least according to its description.

                            <br/>
                            The cake came in a wrapper that had bug bites and some tea oil stains - which is totally OK.
                            The enzymatic browning had its way with the leaves.
                            There is not a single spot of green in them.
                            The tea contains khaki and auburn leaves that smell like a rainy day, sugar cane and molasses.
                            The rinsed leaves are rusty coloured and smell like citrus peel and candied peaches.
                            The tea soup is medium thick, brothy and bright with a pink-tinged tangerine peel colour.
                            The brew had a mellow taste of plum compote and peach cream soup with an extreme mineral back-note and some mushrooms in the background.
                            The aftertaste was slightly sour/zesty, mineral and floral.

                            I used 5.5g of leaf in a 100ml gaiwan with boiling water.
                            I managed to get ten brews from this abusive brewing.
                            By the end of the session, my room smelled like an oak forest before/during rain: that vegetation, mushroomy, rainy goodness.

                            Overall, for the price of $40/cake + VAT, I think this is quite a nice tea. It's mellow,
                            It's a jack of all trades and a master of none kind of tea (aside from the mineral taste, there wasn't another major quality that stood out).
                            I'm more of a floral, sweet Mengku kind of guy, but from time to time, these teas have been refreshing, too.

                            Oh, and I tried this tea as soon as it arrived.
                            It was awful: straight apple cider with all that astringent and mineral bonus.
                            So if you buy it, leave it for at least 2–3 weeks.
                            <br/>
                            Green/Vegtal: 1/10, Floral: 2/10, Fruits: 5/10, Spices: 5/10, Wood/Earth: 3/10, Roast/Nuts: 2/10, Cream: 4/10, Mineral: 8/10.
                        </p>
                    </div>
                    <div id="19. Jingmai Miyun (FL)" ref={jm1FLRef}>
                        <h3>19. Jingmai Miyun (FL)</h3>
                        <p>
                            This tea is a blend of high-altitude natural tea gardens from Jingmai Mountain.
                            The tea is composed of blended leaves from the Liu Dui and Da Ping Zhang gardens.
                            The soup has a good thickness and a certain bite that calls for another cup at every sip.
                            It has a moderate bitterness and astringency, which you can bring out if you decide to brew the tea hard.

                            <br/>
                            The dry material contains plum and khaki leaves and silver buds that smell like sweet grapes and citrus rind.
                            The wet leaves have a deep forest colour with some mustard green in them. All with the smell of tamarind, pomelo and plums.
                            The tea soup was thick with a cloudy pale gold colour.
                            The brew tasted like plums and molasses, fruity, sweet, and sour, with hints of savoury and astringent notes.
                            The empty cup smelled like plum and apricot jam, and the tea left a sweet and juicy aftertaste.

                            I used 5g worth of leaf in a 100ml gaiwan with boiling water.
                            On average, the sessions lasted for ten brews.

                            <br/>
                            Green/Vegtal: 5/10, Floral: 9/10, Fruits: 7/10, Spices: 4/10, Wood/Earth: 0/10, Roast/Nuts: 0/10, Cream: 2/10, Mineral: 8/10.
                        </p>
                    </div>
                    <div id="20. Jingmai Gulan (FL)" ref={jg1FLRef}>
                        <h3>20. Jingmai Gulan (FL)</h3>
                        <p>
                            This tea is a blend of five gardens: Ai Ban, Nuo Gu Wan, Weng Bo, He Huan and Guang Jing.
                            All are located on the Da Ping Zhang plateau in Jingmai.

                            <br/>
                            The dry leaves had mossy green and blue-green leaves with grey-yellow hairy buds,
                            the smell was of grapes, cherries and sweet-sour sauce.
                            The wet leaves had a uniform cream-green colour and smelled like tamarind and prune compote.
                            The tea soup was thick and brothy with a translucent gold colour.
                            The brew was potent, astringent, green, sweet and sour, reminiscent of meadow flowers.
                            The empty cup smelled like a spring flower bomb.
                            The brew left a juicy and sweet aftertaste.

                            I used 5g worth of leaf in a 100ml gaiwan with boiling water.
                            On average, the sessions lasted for eight brews.
                            <br/>
                            Green/Vegtal: 5/10, Floral: 9/10, Fruits: 8/10, Spices: 5/10, Wood/Earth: 1/10, Roast/Nuts: 1/10, Cream: 2/10, Mineral: 9/10.
                        </p>
                    </div>
                    <div id="21. 2022 Silver Impression cake (YS)" ref={sir22YSRef}>
                        <h3>21. 2022 Silver Impression Cake (YS)</h3>
                        <p>
                            This impression cake is a blend of Spring and Autumn harvests from the Lincang, Simao, and Menghai tea gardens.
                            The impression cakes were created as an alternative to a Xiaguan or Da Yi 7542.
                            <br/>
                            The dry material has plum and olive green coloured leaves with silver buds,
                            the smell was of apricot cream soup, honey with minty undertones.
                            The wet leaves have a uniform khaki colour and smell like dewy meadows, sherry and tamarind.
                            The tea soup was medium thick and brothy with a pale yellow colour and lime tinge.
                            The brew was mineral, vegetal broth-like, slightly bitter, slightly sweet with citrus peel notes.
                            The empty cup smelled like honey, hay and melons.
                            The mouthfeel is astringent and transforms into a long-lasting sweetness.

                            I used 5g worth of leaf in a 100ml gaiwan with boiling water.
                            On average, the sessions lasted for ten brews.
                            <br/>
                            Green/Vegtal: 6/10, Floral: 4/10, Fruits: 3/10, Spices: 5/10, Wood/Earth: 0/10, Roast/Nuts: 1/10, Cream: 1/10, Mineral: 7/10.
                        </p>
                    </div>
                    <div id="22. 2006 ChangTai Tian Xia Tong An (KTM)" ref={cttxtaKTMRef }>
                        <h3>22. 2006 ChangTai Tian Xia Tong An (KTM)</h3>
                        <p>
                            This tea is distinguished by its well-aged profile, encapsulated in a medium-tight brick form.
                            The tea bricks were stored in Guangzhou.
                            <br/>
                            The dry material has chestnut leaves and cream-coloured buds; the smell was of cream and peaches.
                            The wet leaves have a khaki and kelp green colour and smell like sweet-sour tamarind paste with petrichor and dirt back notes.
                            The tea soup was medium thick and brothy with a vibrant orange colour.
                            The brew was mineral, it had notes of green peppers, smoked prosciutto, citrus zest and dirt.
                            The empty cup smelled like tamarind and peach nectar.
                            The mouthfeel is refreshing and cooling with a citrus rind aftertaste.

                            I used 7g worth of leaf in a 100ml gaiwan with boiling water.
                            On average, the sessions lasted for six brews.
                            <br/>
                            Green/Vegtal: 2/10, Floral: 3/10, Fruits: 0/10, Spices: 7/10, Wood/Earth: 2/10, Roast/Nuts: 0/10, Cream: 4/10, Mineral: 10/10.
                        </p>
                    </div>
                    <div id="23. 2021 MengKu RongShi Qiao Mu Wang (KTM)" ref={mwakKTMRef}>
                        <h3>23. 2021 MengKu RongShi Qiao Mu Wang (KTM)</h3>
                        <p>
                            "Arbor King" is a new series designed to appreciate the tea taste of the Meng Ku tea region according to its refined recipe in the 2006 year.
                            From then on, this series has been recognised as another benchmark tea in the puerh tea world.
                            Selected high-quality tea leaves from arbour tea trees aged more than 100 years from several tea mountains in the Meng Ku tea region.
                            This is my second whole cake purchase of the Qiao Mu Wang (Arbor King) teas, and I had high expectations
                            <br/>
                            The dry material has khaki and plum medium and large leaves and fuzzy cream buds,
                            the smell was of peaches and fried nori.
                            The wet leaves turned into a pickle-green colour and smelled like raisins and tamarind.
                            The tea soup was medium and brothy with a blonde to pale butterscotch colour.
                            The tea brew tasted like a sour sop, poached pears, and candied apricots with minimal but ever-present astringency.
                            The empty cup had the fresh green smell of sorrel paired with poached pairs.
                            The tea left a pomelo zesty, minty cooling mouth finish, and the astringency turned into a sweet aftertaste.

                            I used 6g worth of leaf in a 100ml gaiwan with boiling water.
                            On average, the sessions lasted for nine brews.

                            Overall, this is a nice tea. I was not disappointed. All the essential MengKu flavours that I love and crave were present.
                            Of course, this tea had more bite and more vegetal notes compared to the previous one, but that one aged for five years, while this one was for two years.
                            So, in 2 years, I will have a similarly mild and beautiful tea if I manage not to drink it.
                            <br/>
                            Green/Vegtal: 2/10, Floral: 4/10, Fruits: 9/10, Spices: 1/10, Wood/Earth: 0/10, Roast/Nuts: 0/10, Cream: 0/10, Mineral: 4/10.
                        </p>
                    </div>
                    <div id="24. 2021 Spring Meng Ku Da Shu Mao Cha (KTM)" ref={mkdsmcKTMRef}>
                        <h3>24. 2021 Spring Meng Ku Da Shu Mao Cha (KTM)</h3>
                        <p>
                            This tea was sourced directly from esteemed tea farmers in the Mengku region and harvested from trees that are over a century old.
                            <br/>
                            The dry material has khaki leaves and cream buds with intact fur,
                            and the smell is of honey, musk melons, and pears.
                            The wet leaves turned to deep forest green and smelled like steamed Brussels sprouts, pear compote and durian.
                            The tea soup was medium thick and brothy with eggnog, almost sepia translucent colour.
                            The brew was mineral, with camphor notes, tamarind, and liquorice brew. It was strong but not astringent.
                            The empty cup smelled like brown sugar, mashed raspberry and lemon rind.
                            The mouthfeel has minty coolness with long-lasting juicy sweetness.

                            I used 5g worth of leaf in a 100ml gaiwan with boiling water.
                            On average, the sessions lasted for ten brews.
                            <br/>
                            Green/Vegtal: 3/10, Floral: 4/10, Fruits: 6/10, Spices: 8/10, Wood/Earth: 0/10, Roast/Nuts: 0/10, Cream: 1/10, Mineral: 5/10.
                        </p>
                    </div>
                    <div id="25. 2023 Fa Zhan He (FL)" ref={fzh23FLRef}>
                        <h3>25. 2023 Fa Zhan He (FL)</h3>
                        <p>
                            This tea was a spring pressing of ecological plantation material from Fa Tang village near Fa Zhan He town close to Jingmai mountain.
                            The tea is grown between 1900m and 2100m altitudes.
                            The low temperatures allow for easy management without the use of pesticides because the pest and disease pressure is low under such a climate.
                            The drawback to such a high altitude is the low yield.
                            <br/>
                            The dry material has prune and khaki leaves with hairy cream buds,
                            and the smell is of tamarind, apricot, and grapes.
                            The wet leaves turned to different shades of swamp green and smelled like Sorell, tobacco and orache.
                            The tea soup was thin with mineral grip, with a pale citrine colour.
                            The brew tasted green and brothy with notes of prickly pears, star-fruits, and dried Mediterranean spice mix.
                            It had a nice balance of savoury, astringent and sweetness.
                            The empty cup smelled like peaches and oregano.
                            The mouth finish was sweet, mineral and slightly bitter.

                            I used 6g worth of leaf in a 100ml gaiwan with boiling water.
                            On average, the sessions lasted for eight brews.
                            <br/>
                            Green/Vegtal: 5/10, Floral: 0/10, Fruits: 2/10, Spices: 7/10, Wood/Earth: 0/10, Roast/Nuts: 0/10, Cream: 2/10, Mineral: 9/10.
                        </p>
                    </div>
                    <div id="26. Jingmai Yellow flakes (FL)" ref={jyf2FLRef}>
                        <h3>26. Jingmai Yellow flakes (FL)</h3>
                        <p>
                            This is my second purchase of a newly pressed huang pian cake.

                            <br/>
                            The looks of the tea leaves are almost the same as in the previous iteration.
                            The smell and taste had differences.
                            The dry leaves smelled like camphor and nougat.
                            The rinsed leaves smelled like pomelo and tamarind sauce.
                            The tea soup was medium thick and brothy with a hazy gold colour.
                            The brew tasted like Chinese pears and pomelo rind; it was mineral and slightly astringent.
                            The empty cup smelled like sugar cane and rhubarb.
                            The mouth feel was sweet, herbal, and tonic.

                            I used 6g worth of leaf in a 100ml gaiwan with boiling water.
                            On average, the sessions lasted for 12 brews.

                            I guess this cake had more old tree material since the taste was more spicy than floral.
                            If someone asks me about an ideal huang pian, this is one of them.
                            It has a nice flavour, and an attractive price for it to be considered a daily drinker.
                            <br/>
                            Green/Vegtal: 2/10, Floral: 2/10, Fruits: 6/10, Spices: 4/10, Wood/Earth: 0/10, Roast/Nuts: 0/10, Cream: 1/10, Mineral: 8/10.
                        </p>
                    </div>
                    <div id="27. Xiaguan Te Ji Tuo Cha (KTM)" ref={tjtcKTMRef}>
                        <h3>27. Xiaguan Te Ji Tuo Cha (KTM)</h3>
                        <p>
                            XiaGuan "Te Ji" (Special Grade) Tuo, a 100g Puerh Sheng Cha (Raw Tea), is an exceptional series that began in 2003 with the aim of surpassing the JiaJi tuo cha in quality.
                            The "Te Ji," meaning "Special Grade", utilizes tea materials of a superior grade compared to those in the JiaJi series.

                            <br/>
                            The dry cake was comprised of olive green and plum leaves with silver buds,
                            and smelled like smoky ham, camphor and caramel.
                            The rinsed leaves turned to a mustard green colour and smelled like smoked brown sugar and tamarind.
                            The tea soup was medium and smooth, with a pale, translucent gold colour.
                            The brew tasted like broth on a camp cauldron, slightly sour, slightly sweet, ripe plum, green peppercorn with lots of minerality.
                            The empty cup smelled like kale and nougat.
                            The mouth feel was sweet and savoury, slightly mouthwatering astringency.

                            I used 5g worth of leaf in a 100ml gaiwan with boiling water.
                            On average, the sessions lasted for seven brews.

                            Overall, this tea is a strange one. It's like a low-pass filter for flavour.
                            It feels like there needs to be some high notes, like sourness and fruity, but it does not come through.
                            <br/>
                            Green/Vegtal: 6/10, Floral: 0/10, Fruits: 2/10, Spices: 4/10, Wood/Earth: 0/10, Roast/Nuts: 2/10, Cream: 0/10, Mineral: 8/10.
                        </p>
                    </div>
                    <div id="28. BaoMing Ba Zi Cha (KTM)" ref={bmbzcKTMRef}>
                        <h3>28. BaoMing Ba Zi Cha (KTM)</h3>
                        <p>
                            “Ba Zi Cha,” “Ba Ba Cha,” “Sao Ba Cha,” or “Chang Tiao Cha” all refer to the same type of tea,
                            notable for its long twigs and leaves, which can reach up to around 10cm in length.
                            This tea is generally produced in the Lin Cang tea region of Yun Nan province.
                            The processing involves withering, killing green (heat treatment to stop oxidation), and rolling.
                            After these steps, each twig or thread is straightened before being naturally dried under the sun.
                            The raw materials for this tea come from old trees in Yong De Snow Mountain, located in the Lin Cang tea region.
                            These twigs are tight and long, with leaves and multiple tea hairs attached.

                            <br/>
                            The dry tea material consisted of large plum and copper leaves and brown stems. It smelled like old cellars, and dried mushrooms and had a peculiar herbal sweetness.
                            The rinsed leaves turned to a uniform hicory colour and smelled like peach schnapps and whisky.
                            The tea soup was medium and brothy, with a deep gold colour.
                            The brew tasted like salty caramel, pine bark, savoury mushroom stock with a slight hint of old chimney soot.
                            The empty cup smelled like pomelo rind and burnt sugar.
                            The mouth feel had a long-lasting herbal sweetness and warming sensation.

                            I used 5g of leaf in a 100ml gaiwan with boiling water.
                            On average, the sessions lasted for nine brews.

                            This is yet another strange tea. It's not green by any means. It has an aged taste; it's sweet, but it's also savoury and slightly smokey.
                            <br/>
                            Green/Vegtal: 0/10, Floral: 0/10, Fruits: 6/10, Spices: 6/10, Wood/Earth: 4/10, Roast/Nuts: 1/10, Cream: 3/10, Mineral: 0/10.
                        </p>
                    </div>
                    <div id="29. NaHong Jing Mai Gu Shu (KTM)" ref={nhjmgsKTMRef}>
                        <h3>29. NaHong Jing Mai Gu Shu (KTM)</h3>
                        <p>
                            This is an Old Tree, also called Arbor Tree tea cake from Jing Mai, that has been naturally stored in Guangzhou.

                            <br/>
                            The dry cake was comprised of chocolate and plum leaves with caramel and rusty buds,
                            and smelled like cocoa and yoghurt.
                            A quick rinse revealed ebony leaves that smelled like cacao, plums, cinnamon, allspice and lilies.
                            The tea soup was medium and oily, with a deep copper colour.
                            The brew was smooth. It tasted like pine resin, papaya, and dates.
                            The empty cup smelled like raisins and persimmons.
                            The mouth feel was sweet, like honeysuckle and had a cuban rum aftertaste.

                            I used 5g of leaf in a 100ml gaiwan with boiling water.
                            On average, the sessions lasted for nine brews.

                            I'll definitely buy more of this tea.
                            <br/>
                            Green/Vegtal: 0/10, Floral: 3/10, Fruits: 6/10, Spices: 6/10, Wood/Earth: 0/10, Roast/Nuts: 0/10, Cream: 4/10, Mineral: 1/10.
                        </p>
                    </div>

                </div>



            </div>
        </div>
    );
}

export default WHITEHUB;
