import React from "react";
import "./mcu_hal11.css";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";
import {MathJax, MathJaxContext} from "better-react-mathjax";

import i2c_internal from "./i2c_internal.jpg";
import i2c_protocol from "./i2c_protocol.jpg";
import i2c_lcd from "./lcd.jpg";
import i2c_setup from "./i2c_setup.jpg";
import i2c_sniff_logic from "./i2c_sniff_logic.jpg";
import i2c_display from "./display.jpg";

function ShowTex({string}){
    const config = {
        loader: { load: ["[tex]/html"]},
        tex: {packages: {"[+]": ["html"]},
            inlineMath: [["$", "$"]],
            displayMath: [["$$", "$$"]]
        }
    };

    return(
        <MathJaxContext config={config} version={3}>
            <MathJax dynamic inline>
                {string}
            </MathJax>
        </MathJaxContext>
    );
}


const i2c_sniffer = `
  printf("Starting I2C Scanning: \\r\\n");
  for(i=1; i<128; i++){
   ret = HAL_I2C_IsDeviceReady(&hi2c1, (uint16_t)(i<<1), 3, 5);
    if (ret != HAL_OK){
        printf(" - ");
    }
    else{
        printf("0x%X", i);
    }
  }
  printf("Done! \\r\\n\\r\\n");
`;

const i2c_static_data = `
extern I2C_HandleTypeDef hi2c1;

static inline void ssd1306_WriteCommand(uint8_t command){
    HAL_I2C_Mem_Write(&hi2c1, SSD1306_I2C_ADDR, 0x00, 1, &command, 1, HAL_MAX_DELAY);
}

static inline void ssd1306_WriteData(uint8_t data){
    HAL_I2C_Mem_Write(&hi2c1, SSD1306_I2C_ADDR, 0x40, 1, &data, 1, HAL_MAX_DELAY);
}

static uint8_t  SSD1306_Buffer[SSD1306_WIDTH * SSD1306_HEIGHT / 8];

typedef struct{
    uint16_t currentX;
    uint16_t currentY;
    uint8_t inverted;
    uint8_t initialized;
} SSD1306_t;

static SSD1306_t SSD1306;
`;


function MCU_HAL11(){
    return(
        <div className="em__post">
            <div className="em__post-title">
                <h1>I2C Master - OLED driver</h1>
            </div>

            <div className="em__post-section">
                <h3>Aim of this tutorial:</h3>
                <p>
                    In this tutorial, we will take a look at the Inter Integrated Communication (and the equivalent
                    two wire) bus.
                    We will use the HAL functions to create a driver for an OLED display with SSD1306 chip.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Few notion about the I2C bus:</h3>
                <p>
                    The I2C or IIC is a synchronous, multi-master/multi-slave, packet switched, single-ended, serial communication bus
                    invented by Philips Semiconductors - <a href="https://en.wikipedia.org/wiki/I%C2%B2C">Wikipedia</a>.

                    Like UART, I2C uses two communication wires: a serial data line (SDA) and a serial clock line (SCL).
                    Unlike UART the only one line is used to transfer and receive data.
                    The clock is always generated by (one of) the master(s).
                    The data transfer speed of the I2C communication is 100kb/s, 400kb/s, 3.4Mb/s, 5Mb/s in standard,
                    fast, high speed and ultra-fast modes respectively.
                </p>
                <img className="img_i2c_internal_hal11" src={i2c_internal} alt="img_i2c_internal_hal11"/>
                <p>
                    The I2C line theoretically supports infinitely many masters and 112 or 1024 slaves (depending on the addressing )on the same line.
                    The I2C driver uses an open-drain configuration, which requires external or internal pull-up resistors.
                    This combined with the capacitance of the lines decreases those theoretical numbers.
                </p>
                <p>
                    The I2C data transmission works in messages, and each message is broken up into frames.
                    Each message has a start condition, an address frame and a bit that shows whether a read or write operation will be performed.
                    One or more data frames and a stop condition.
                    Each address and data frame is accompanied by an ACK/NACK bit.
                </p>
                <ul>
                    <li>
                        <b>Start condition</b> is when the SDA switches from H to L before the SCL switches to H from L.
                    </li>
                    <li>
                        <b>Stop condition</b> is when the SDA switches from L to H after the SCL switches to L from H.
                    </li>
                    <li>
                        <b>Address frame</b> a 7b or 10b sequence that identifies the slave device.
                    </li>
                    <li>
                        <b>Read / Write bit</b> a single bit that specifies that the master is sending data or requesting data from the slave.
                    </li>
                    <li>
                        <b>ACK / NACK bit</b> each frame is followed by this bit. If a frame was successfully sent, the ACK bit is sent to the receiving device.
                    </li>
                </ul>

                <img className="img_i2c_protocol_hal11" src={i2c_protocol} alt="img_i2c_protocol_hal11"/>

                <p>
                    It can be seen that creating a driver for such data transmission is non trivial task.
                    Since the purpose of this tutorial is to implement the communication using HAL libraries, we won't dive into the register configurations
                    of the I2C peripheral (that will be the scope of a bare-metal program),
                    rather we will have a look at an I2C slave device, and try to communicate with it using HAL functions.
                </p>
            </div>

            <div className="em__post-section">
                <h3>OLED with SSD1306 driver:</h3>
                <p>
                    In this tutorial, we will take a look at the Inter Integrated Communication (and the equivalent
                    two wire) bus.
                    We will use the HAL functions to create a driver for an <a href="https://www.ebay.com/itm/163594917364">OLED display</a> with SSD1306 chip.
                </p>
                <img className="img_i2c_lcd_hal11" src={i2c_lcd} alt="img_i2c_lcd_hal11"/>
                <p>
                    The <a href="https://cdn-shop.adafruit.com/datasheets/SSD1306.pdf">SSD1306</a> is a single-chip CMOS
                    common cathode OLED/PLED driver for displays with 128 segments and 64 commons.
                    The IC includes contrast control, oscillator, display RAM to reduce the number of components and power consumption.
                    The main MCU can communicate with the SSD1306 using 6800/8000 compatible Parallel Interface, I2C or SPI.
                </p>
                <p>
                    The LCD has 4 pins. Vcc is the 3.3V Supply, GND is ground and SCL/SDA are the I2C communication lines.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Project setup:</h3>

                <p>
                    Create a new project for the STM32 MCU with the usual clock and debug settings.
                    Enable the USART, and copy the <i>_write</i> function into the main.c file.

                </p>
                <Popup trigger={<img className="img_i2c_setup_hal11 clickable_img" src={i2c_setup} alt="i2c_setup_hal11"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={i2c_setup} alt="i2c_setup_hal11" />
                    )}
                </Popup>
                <p>
                    Before we start to write the driver functions, let's sniff out the I2C address of our device.
                    Connect the hardware to the required pins.
                    Navigate to the main.c file, and paste the following snippet.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {i2c_sniffer}
                </SyntaxHighlighter>
                <p>
                    Here we are sending short messages with only address and read bit, and we are checking the ACK/NACK bit.
                    We know that only one device is on the I2C line, so the address of the lcd is the one with the ACK bit.

                </p>
                <Popup trigger={<img className="img_i2c_sniff_logic_hal11 clickable_img" src={i2c_sniff_logic} alt="i2c_sniff_logic_hal11"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={i2c_sniff_logic} alt="i2c_sniff_logic_hal11" />
                    )}
                </Popup>
                <p>
                    The results can be seen in a Serial COM terminal or with the help of a logic analyser.
                    The read address is 0x3C, so by shifting it to the right we have the address without r/w bit: 0x78.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Creating the driver:</h3>
                <p>
                    Download the driver header and source files from <a href="https://gitlab.com/stm32_mcu_group/stm32_hal/11_i2c_oled.git">here</a>, and move them into the Core/Inc and Core/Src folders respectively.

                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {i2c_static_data}
                </SyntaxHighlighter>
                <p>
                    The I2C peripheral is already defined so we need to export it using <i>extern</i> keyword.
                    <i>SSD1306_I2C_ADDR</i> is a define for 0x78.
                    In the data sheet we can see that memory address 0x00 corresponds to command instructions,
                    while 0x40 corresponds to data instructions.
                    Furthermore, we can pass one byte of information (command / data) with maximum time-out delay.
                </p>
                <p>
                    We have a local buffer for the full screen information (width: 128 x height: 64 / data_width: 8).
                    We can modify this buffer at any time, and we will only issue a screen update when the full picture is complete.
                    This way we can reduce the communication and waiting time.
                </p>
                <p>
                    The <i>SSD1306_t</i> variable contains setup flags (white on black or black on white) and current pointer information.
                </p>
                <p> Let's take a quick look at the different driver functions:</p>
                <ul>
                    <li>
                        The init function set's up the display: Page addressing mode, output scan direction, RAM content,
                        display clock frequency, com voltage value, enable the DC-DC converter.
                        Finally the display is turned on after the setup.
                        At this point, if you are using the display for the first time, a random mess should apear on the screen.
                        Next we fill the screen buffer with the background color and we can update the screen.
                    </li>
                    <li>
                        The screen updater function sends the screen buffer data to the OLED IC row-by-row.
                    </li>
                    <li>
                        The inverter function negates the content of the screen buffer.
                    </li>
                    <li>
                        The DrawPixel function expects a position (x,y) and a color.
                        The position must be within the ranges of the OLED, so we check it first.
                        If (x,y) are given, we need to set a value in the screen buffer at the location <ShowTex string="$x + (y/8)width$"/>.
                        If we are displaying a normal image, the value is <ShowTex string="$|= 1 << (y \% 8)$"/>,
                        or the inverse <ShowTex string="$\&= ~(1 << (y \% 8))$"/> in case of inverted display.
                    </li>
                </ul>
                <p>
                    With the basic functions done, we can continue to write the text display functions.
                    For this purpose, we need a look-up table for the fonts.
                    People have already created font sources out of which I've included a 7x10, 11x18 and 16x26 font LUT.
                </p>
                <ul>
                    <li>
                        The Putc function inserts the value of the required font from the specified LUT into the screen buffer at the current position.
                        Lastly it increments the X position by the font width.
                    </li>
                    <li>
                        The Puts function calls the previously written Putc function for every character in the given string.
                    </li>
                </ul>
                <p>
                    Lastly, there are some of the most basic graphic functions: drawLine - which interpolates between the given starting and finish points using the drawPixel function,
                    drawRectangle - uses the drawLine function four times to create a rectangle with given starting point, width and height,
                    drawTriangle - uses the drawLine function three times between the three given points,
                    drawCircle - uses the drawPixel function to create a circle based on the implementation of the equidistant points,
                    and the filled counterparts of the rectangle, triangle and circle functions.
                </p>
                <img className="img_i2c_oled_hal11" src={i2c_display} alt="i2c_oled_hal11"/>
                <p>
                    You can now test these functions in the main source.
                    An example is given in the Git repo, which prints out hello world, strikes it through with a line,
                    and prints an unfilled rectangle under the text.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Further development:</h3>
                <p>
                    This basic driver is enough to display simple information on the OLED,
                    however if we would like to use it for more advanced graphic animation like reactive gauges, sliders etc.
                    we could optimize the driver with the use of DMA transfer.
                    The setup consists only of fixed commands, so that could be stored in an array.
                    Similarly, the screen buffer is an array, so we could use the I2C in DMA mode which transfers the data from memory without the use of CPU recources.
                    <a href="https://www.fatalerrors.org/a/stm32-hal-hardware-iic-dma-control-oled.html">Here</a> is an implementation of it.
                </p>
            </div>


            <div className="em__post-navigation">

                <NavLink to="./../stm-hal-10">
                    <Button btnID={"leftBTN"} buttonSize="btn--medium"> Previous Post</Button>
                </NavLink>

                <NavLink to="./../stm-hal-12">
                    <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
                </NavLink>
            </div>
        </div>
    );
}

export default MCU_HAL11;