import React from "react";
import "./home.css";
import "./../../global.css";
import "./../../index.css";
import Header from "./header/Header";
import Whatem from "./WhatEM/Whatem";
import Blogprev from "./BlogPrew/Blogprev";

function Home(){
    return (
        <div className="em__home">
            <div className="gradient__bg">
                <Header/>
            </div>
            <div/>
            <Whatem/>
            <Blogprev/>
        </div>
    );
}

export default Home;

