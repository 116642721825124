import React from "react";
import "./../hobby.css";
import "./../collection.css";
import {Tag} from "../../../common";
import {useNavigate} from "react-router-dom";
import {useRef} from "react";


function DARKHUB(){
    const navigate = useNavigate();
    const chaYuLinQLCRef = useRef(null);
    const threeCranesLBRef = useRef(null);
    const xiangYiKTMRef = useRef(null);
    const jingWeiKTMRef = useRef(null);
    const baiShaXiKTMRef = useRef(null);

    const tealist = [
        {name:"1. Cha Yu Lin qian liang cha (ys)", ref: chaYuLinQLCRef},
        {name:"2. Three Cranes liu bao (YS)", ref: threeCranesLBRef},
        {name:"3. 2011 XiangYi Fu Tea (KTM)", ref: xiangYiKTMRef},
        {name:"4. 2017 JingWei Fu Tea (KTM)", ref: jingWeiKTMRef},
        {name:"5. 2010 BaiShaXi Fu Tea (KTM)", ref: baiShaXiKTMRef},

    ];
    return (
        <div className="em__post">
            <div className="em__post-section">
                <h3>About the history and production of dark teas</h3>
                <p>
                    In the vast landscape of Chinese teas, Hei Cha, or dark tea, emerges as a category steeped in history and unique in its fermentation process.
                    Originating from the remote corners of China, Hei Cha is a testament to the art of fermentation and the cultural richness of the regions where it thrives.
                    In this exploration, we embark on a journey through time, tracing the historical roots and unravelling the intricate production procedures that bestow upon
                    Hei Cha is a distinctive character.
                    <br/>
                    The history of Hei Cha dates back over a millennium, with its roots embedded in the Yunnan province of China.
                    Initially, it was created for the practical purpose of facilitating the long-distance trade of tea.
                    Compressed into bricks or cakes, Hei Cha endured arduous journeys and underwent a natural fermentation process during transport.
                    <br/>
                    Hei Cha holds deep cultural significance, particularly in Hunan, Sichuan, and Yunnan regions.
                    Embraced by nomadic communities and ancient trade routes, this tea became a staple for its unique flavour, ability to age and transform over time,
                    and symbolic role in Chinese hospitality and ceremonies.
                    <br/>
                    The production of Hei Cha involves withering and piling, key steps that differentiate it from other teas.
                    After the leaves are plucked, they are spread out to wither, reducing moisture content. Subsequently,
                    the leaves undergo a controlled fermentation process, and they are piled and allowed to age.
                    This process, known as "wo dui" in Chinese, encourages microbial activity, leading to Hei Cha's dark colour and distinctive aroma.
                </p>
                <p>
                    Hei Cha encompasses a variety of teas, including Pu-erh, Liu Bao, Fu Zhuan, and others.
                    Pu-erh, in particular, is further divided into raw (Sheng) and ripe (Shou) pu'er,
                    each undergoing unique ageing processes that result in different flavour profiles.
                    I will leave Sheng and Shou for their page, so excluding these two, there are the main dark tea types:
                </p>
                <ol>
                    <li>
                        <b>Silver Needle (Baihao Yinzhen):</b>
                        Considered the most prized and revered white tea, Silver Needle is made from tea buds covered in silvery-white hairs.
                        It offers a subtle, delicate flavour with notes of honey and a lingering sweetness.
                    </li>
                    <li>
                        <b>Liu Bao:</b>
                        Liu Bao, a dark tea with roots tracing back to Guangxi province in China, is celebrated for its unique processing and ageing characteristics.
                        Named after the town where it originated, Liu Bao tea undergoes a post-fermentation process, giving it a distinctive earthy flavour and a reddish-brown liquor.
                        Typically compressed into basket-shaped forms, Liu Bao leaves are aged to perfection, resulting in a tea that offers a complex profile with notes of wood, mushrooms, and a characteristic mellow sweetness.
                        Its ability to mature and evolve has earned Liu Bao a special place among dark tea enthusiasts and collectors.
                    </li>
                    <li>
                        <b>Fu Zhuan:</b>
                        Hailing from Hunan province, Fu Zhuan, or "Golden Brick," is a compressed dark tea recognized for its unique appearance and flavour.
                        The tea is often pressed into large bricks, which can feature an inner layer of golden flowers, adding a touch of visual intrigue.
                        Fu Zhuan undergoes a fermentation process that gives it a distinctively rich and earthy taste with hints of medicinal herbs.
                        This tea is highly regarded for its potential health benefits, and its robust character makes it a favourite among those who appreciate the boldness of dark teas.
                    </li>
                    <li>
                        <b>Qing Zhuan:</b>
                        Qing Zhuan, known as "Green Brick," is a dark tea produced in Shaanxi province.
                        Its uniqueness lies in its lighter fermentation compared to other dark teas, resulting in a tea that retains more of the green characteristics of raw leaves.
                        Qing Zhuan is compressed into rectangular bricks and unfolds into a reddish-brown infusion with a refreshing taste that carries floral and grassy notes.
                        This tea, often enjoyed for its more accessible and milder flavour profile, is a testament to the diversity within the dark tea category.
                    </li>
                    <li>
                        <b>Liang Cha:</b>
                        A type of dark tea from Anhua County.
                        The wet piled tea is steamed and compressed into a long column (shi liang, bai liang, qian liang are 365g, 3650g and 36500g tea columns respectively).
                        Once compressed, this tea log is dried in the sun and cured indoors for months.
                        This way, the tea can be aged for decades or centuries.
                        The taste is spicy, sweet, and thick, with hints of brewer's yeast and mushrooms.
                    </li>
                </ol>
                <p>
                    Since most of these teas are compressed, a rougher brewing method is required.
                    While other teas could be brewed in a cup with boiled water for tens of seconds, hei-cha can be boiled without making an unbearable bitter abomination.
                    If you fancy a more savoury brew, try boiling the tea with a pinch of salt and adding butter.
                </p>
            </div>

            <div className="em__collection">
                <div className="em__collection-title">
                    <h1 className="gradient__text">Sampled white teas:</h1>
                </div>

                <div className="em__collection-list">
                    {
                        tealist.map((item) => (
                            <div >
                                <Tag title={item.name} description={item.description} onClick={() => item.ref.current.scrollIntoView({ behavior: 'smooth' })}/>
                            </div>
                        ))
                    }
                </div>

                <div className="em__post-section">
                    <div id="1. Cha Yu Lin qian liang cha (ys)" ref={chaYuLinQLCRef}>
                        <h3>1. Cha Yu Lin qian liang cha (ys)</h3>
                        <p>
                            This is a "Cha Yu Lin" production from 2010 Spring tea leaves.
                            Pressed into a huge 36 kilogram column called "Qian Liang Cha" (aka 1000 catty tea) and then aged for eight years.
                            <br/>
                            The tea is highly compressed, so watch out for prying it apart.
                            The leaves are large and walnut-coloured, with some stems mixed in and full of golden flower fungi.
                            The leaves smelled like peat and mushroom, extremely mineral, with a slight molassy note.
                            Usually, I give a quick rinse and start brewing, but this tea did not want to open up.
                            I needed 2 10s log pre-brews with almost no taste until the tea leaves opened.
                            But from then on, the tea could not be killed or over-brewed.
                            The tea soup was... soupy, pale gold, and oily.
                            It had an immense earthy flavour, cane sugar, molasses, and roast porcini, and was slightly metallic with yeasty notes.
                            I used 4g of leaf in a 100ml gaiwan with boiling water.
                            On average, a session lasted for 15+ strong brews.
                            <br/>
                            I also tested the tea using a more abusive method: 12g of leaf in 1l water boiled for 7 minutes with a pinch of salt and butter.
                            At first, I did not like the sound of it, but the taste was amazing.
                            The mineral, flavorful, thick tea with its mushroomy goodness goes well salted and buttered up.
                            <br/>
                            Green/Vegtal: 0/10, Floral: 0/10, Fruits: 0/10, Spices: 8/10, Wood/Earth: 10/10, Roast/Nuts: 8/10, Cream: 0/10, Mineral: 7/10.
                        </p>
                    </div>
                    <div id="2. Three Cranes liu bao (YS)" ref={threeCranesLBRef}>
                        <h3>2. Three Cranes liu bao (YS)</h3>
                        <p>
                            This is a 2015 harvested and piled tea blended and packaged in 2018.
                            It's a blend of lightly wet piled and heavier piled grade 2 leaves.
                            The contrast between the two differently processed leaves, with the advantage of already being semi-aged, makes this a balanced and pleasurable Liu Bao.
                            It was produced in Wuzhou, Guangxi, China.
                            <br/>
                            The tea leaves are dark chocolate-coloured, curled with a ruby tinge.
                            The dry leaves smell like boiled beetroots, musky cellar and black rock sugar.
                            After a quick rinse, the opened leaves smelled like wild mushrooms, rie bread and peat.
                            The tea soup was deep copper orange with a smooth, thick, creamy texture.
                            It tasted like boiled root vegetables, old wood, and mouldy cellar with hints of molassy sweetness.
                            The empty cup smelled like smoky wood, new concrete and mould.
                            The mouth finish is like a sweetened mushroom soup.

                            I used 5g of leaf in a 100ml gaiwan with boiling water.
                            On average, a session lasted for ten brews.
                            <br/>
                            Green/Vegtal: 1/10, Floral: 1/10, Fruits: 2/10, Spices: 3/10, Wood/Earth: 9/10, Roast/Nuts: 7/10, Cream: 3/10, Mineral: 5/10.
                        </p>
                    </div>
                    <div id="3. 2011 XiangYi Fu Tea (KTM)" ref={xiangYiKTMRef}>
                        <h3>3. 2011 XiangYi Fu Tea (KTM)</h3>
                        <p>
                            This is a 2011 XiangYi Fu brick tea called "Te Zhi Li Pin" (Specially made gift) from Hunan.
                            <br/>
                            The tea leaves are betel nut coloured.
                            The dry leaves smell like cane sugar, mushroom cream soup, and tamarind.
                            After a quick rinse, the opened leaves revealed a chocolate-coloured mulch-like rough texture
                            that smelled like caramel, mouldy corn, allspice and ground coffee.
                            The tea soup was deep gold with a medium and brothy texture.
                            It tasted like allspice berries, juniper, damp cellar, and smoked whiskey with a slight metallic and mineral hint.
                            The empty cup smelled like sweet caramel and apple bark smoke.
                            The mouth finish was slightly astringent, with hints of citrus sourness and a cooling sensation.

                            I used 5g of leaf in a 100ml gaiwan with boiling water.
                            On average, a session lasted for seven brews.
                            <br/>
                            Green/Vegtal: 0/10, Floral: 0/10, Fruits: 3/10, Spices: 6/10, Wood/Earth: 5/10, Roast/Nuts: 4/10, Cream: 1/10, Mineral: 9/10.
                        </p>
                    </div>
                    <div id="4. 2017 JingWei Fu Tea (KTM)" ref={jingWeiKTMRef}>
                        <h3>4. 2017 JingWei Fu Tea (KTM)</h3>
                        <p>
                            JingWei Qi Zi Fu tea is selected from high-quality leaves grown in the tea plantations of the Qin Ling Mountains,
                            an area recognized for its nationally preserved natural environments.
                            The tea threads are tightly rolled, demonstrating neatness and an overall excellent appearance,
                            indicative of the meticulous care in its processing.
                            A notable feature of this tea is the abundant presence of "Jin Hua" (Golden Flower or Eurotium Cristatum),
                            a natural occurrence that enriches the tea's quality and value.

                            <br/>
                            The tea leaves are plum and khaki coloured and are tightly compressed with brown twigs, but I could easily pry them apart because of the ageing.
                            The dry leaves smell like smoked ham and maple syrup.
                            After a quick rinse, the leaves revealed ebony and chocolate broken material with a raspberry and black currant smell.
                            The tea soup was cloudy gold and had an oily medium thick texture.
                            It tasted like whisky barrels, black chokeberries with a savoury and sweet feeling.
                            The empty cup smelled like smoked lemon zest.
                            The mouth finish had an allspice and a sour aftertaste.

                            I used 5g of leaf in a 100ml gaiwan with boiling water.
                            On average, a session lasted for seven brews.
                            <br/>
                            Green/Vegtal: 0/10, Floral: 0/10, Fruits: 3/10, Spices: 4/10, Wood/Earth: 5/10, Roast/Nuts: 2/10, Cream: 0/10, Mineral: 7/10.
                        </p>
                    </div>
                    <div id="5. 2010 BaiShaXi Fu Tea (KTM)" ref={baiShaXiKTMRef}>
                        <h3>5. 2010 BaiShaXi Fu Tea (KTM)</h3>
                        <p>
                            This tea is carefully made from selected high-quality dark tea leaves from the Anhua tea region, known for its excellence in tea production.
                            The tea has a nearly dark brown colour, yet it is marked by a distinct brightness, reflecting its refined quality and the careful ageing process it has undergone.
                            A subtle herbal-like flavour characterizes this tea, a result of its extended evolution and maturation over time.

                            <br/>
                            The tea material consists of swamp-green leaves and hay-coloured twigs.
                            The dry leaves smell like bay leaves, mushrooms and cane sugar.
                            After a quick rinse, the tea turned into hichory and chocolate-coloured mulch that smelled like the forest after an autumnal rain.
                            The tea soup was deep copper with a thick and viscous texture.
                            It had a deep woody, medicinal sweetness, like liquorice and aniseed, slightly fungal, slightly smoky.
                            The empty cup smelled like beetroot and fennel.
                            The mouth finish is sweet with fungal funk and a cooling sensation.

                            I used 5g of leaf in a 100ml gaiwan with boiling water.
                            On average, a session lasted for ten brews.
                            <br/>
                            Green/Vegtal: 0/10, Floral: 0/10, Fruits: 1/10, Spices: 8/10, Wood/Earth: 6/10, Roast/Nuts: 0/10, Cream: 1/10, Mineral: 7/10.
                        </p>
                    </div>
                </div>



            </div>
        </div>
    );
}

export default DARKHUB;
