import React from "react";
import "./collection.css";
import {Tag} from "../../common";
import {useNavigate} from "react-router-dom";
import {} from "./Posts";


const TutList = [
    {name:"STM32 with HAL", imgURL:"", link: "./stm-hal"},
    {name:"STM32 CMSIS OS v2", imgURL: "", link: "./stm-rtos"},
    {name:"STM32 register level", imgURL: "", link: "./stm-bm"}
]
const ProjList = [
    {name:"DIY reflow oven", imgURL:"", link: "./rfl-oven"},
    {name:"BLE Temperature and Humidity sensor", imgURL:"", link: "./ble-sensor"}
]

function Micros(){

    const navigate = useNavigate();

    return(
        <div className="em__collection">
            <div className="em__collection-title">
                <h1 className="gradient__text">Microcontroller tutorials from bare metal to hardware abstractions</h1>
            </div>

            <div className="em__collection-list">
                {
                    TutList.map((item) => (
                        <div >
                            <Tag title={item.name} description={item.description} onClick={() => navigate(item.link)}/>
                        </div>
                    ))
                }
            </div>
            <div className="em__collection-title">
                <h1 className="gradient__text">Single MCU projects</h1>
            </div>
            <div className="em__collection-list">
                {
                    ProjList.map((item) => (
                        <div >
                            <Tag title={item.name} description={item.description} onClick={() => navigate(item.link)}/>
                        </div>
                    ))
                }
            </div>

        </div>
    );
}

export default Micros;