import React from "react";
import {NavLink} from "react-router-dom";
import "./about.css";

function About(){
    return (
        <div className="em__resume section__margin">
            <div className="gradient__text em__resume-title">
                <h1>Resumé of the Embedded Musician</h1>
            </div>
            <div className="em__resume-body">
                <div className="em__resume-body__pInfo">
                    <h2>
                        Profile:
                    </h2>
                    <div className="em__resume-body__pInfo-List">
                        <h3> Name:</h3> <p> FEHÉR Áron, Ph.D., eng.</p>
                        <h3> Place and date of birth:</h3> <p> Marosvásárhely (Târgu-Mureş), RO, 1992.07.29</p>
                        <h3> Nationality:</h3> <p> Hungarian</p>
                        <h3> Citizenship:</h3> <p> Romanian, Hungarian</p>
                        <h3> Address (work):</h3> <p> 540485 Târgu-Mureş, O.p. 9, C.p. 4</p>
                    </div>

                </div>
                <div className="em__resume-body__edForm">
                    <h2>
                        Education and formation:
                    </h2>
                    <ul>
                        <li>1999 - 2007: Fogarasi Sámuel general school (middle and elementary) in Vámosgálfalva (Găneşti)</li>
                        <li>2007 - 2009: Bolyai Farkas Theoretical High School mathematics and informatics in Marosvásárhely</li>
                        <li>2009 - 2011: Bolyai Farkas Theoretical High School science profile in Marosvásárhely</li>
                        <li>2011 - 2015: BsC student at the Sapientia Hungarian University of Transylvania, Automation and applied informatics profile </li>
                        <li>2015 - 2017: MsC student at the Sapientia Hungarian University of Transylvania, Intelligent Control Systems profile</li>
                        <li>2017 - 2022: PhD student at the Faculty of Information Technology, University of Pannonia, Veszprém, Hungary </li>
                    </ul>
                    <ul>
                        <li>2021: Level I Psycho-Pedagogical Training</li>
                    </ul>
                </div>
                <div className="em__resume-body__disTopic">
                    <h2>
                        Dissertation topics:
                    </h2>
                    <ul>
                        <li>BsC Thesis: Guitar sound effects on dsPIC</li>
                        <li>MsC Thesis: Modeling and control of bounded hybrid systems in power electronics</li>
                        <li>PhD Thesis: Asymptotic characterisation of dynamic linear systems with small time delays</li>
                    </ul>
                </div>
                <div className="em__resume-body__reInt">
                    <h2>
                        Research Interests:
                    </h2>
                    <ul>
                        <li> modelling and control of hybrid, nonlinear and time-delay systems</li>
                        <li> biological signal and image processing</li>
                        <li> analog and digital signal processing</li>
                    </ul>
                </div>
                <div className="em__resume-body__reGroup">
                    <h2>
                        Research Groups:
                    </h2>
                    <ul>
                        <li> 2017 - present:<a href="https://ms.sapientia.ro/en/research/research-groups/department-of-electrical-engineering/narc">
                            NACS</a> - a research group which focuses on networked control systems</li>
                    </ul>
                </div>

                <div className="em__resume-body__award">
                    <h2>
                        Projects:
                    </h2>
                    <ul>
                        <li> 2021: Ericsson BME 5G - The aim of the project was to use 2 robotic manipulators and perform a synchronised work piece assembly.
                            Two Universal Robots were used (UR3 and UR3e) to perform this operation. A conveyor belt was used to transport the work pieces.
                            When the desired piece was detected (using ArUCo markers), the ideal pickup position is estimated and the arms pick up the pieces.
                            When both arms have a work piece, the assembly process is perfomed, under which both arms are moving using decentralised synchronisation techniques.
                        </li>
                        <li> 2023: RoNo Cooperation for Virtual Manufacturing (UiT The Arctic University of Norway, Campus Narvik) -
                            The aim of the project was to create a Python-based environment that enabled the control and monitoring of an UR10e robot from the cloud using OPC-UA interface.
                            A local bridge was developed that used the UR-RTDE interface and translates the robot states to the OPC-UA specific packages.
                            This interface sends the data to the cloud and receives cloud instructions that are transformed back to UR instructions and control the robot.
                            Another python script was developed for the cloud data processing of OPC-UA packets.
                            This script was deployed in a cloud virtual machine, where a Node-Red GUI was also hosted for better data visualization.
                        </li>
                        <li> 2023: RoNo Cooperation for Virtual Manufacturing (UiT The Arctic University of Norway, Campus Narvik) -
                            The continuation of the previous project. The aim was to create a faster environment.
                            The data transmission was replaced using ZMQ, new video feeds were added to the system using ZMQ video streaming.
                            The Node-Red interface was replaced with a Flask UI and Python back-end.
                        </li>
                        <li> 2024: RoNo Cooperation for Virtual Manufacturing (UiT The Arctic University of Norway, Campus Narvik) -
                            The continuation of the previous project. The aim was to further distribute the components, and make it more secure using Azure infrastructure.
                            An Azure IoT Hub was created, where the UR-RTDE bridge was configured as an IoT device.
                            The incoming data was processed using Azure Stream Analytics module, that could intervene in case of quick anomaly detections.
                            An Azure function was used to funnel the data from Stream Analytics into an SQL database.
                            An Azure Web App was created to host the previous Flask UI. The data waas transferred from the database to this UI using an Azure Data Explorer,
                            and another Azure function was used to send instructions to the robot through the IoT Hub.
                        </li>
                    </ul>
                </div>

                <div className="em__resume-body__pub">
                    <h2>
                        Publications:
                    </h2>
                    <ul>
                        <li> May 2017: Denoising ECG signals by applying discrete wavelet transform - OPTIM-ACEMP Joint Conference</li>
                        <li> Oct 2017: Modelling and Control of Bounded Hybrid Systems in Power Electronics - MACRo Conference</li>
                        <li> Jun 2018: Approximation-based transient behavior analysis of multi-agent systems with delay - 14th IFAC workshop on Time Delay Systems</li>
                        <li> Jun 2019: Comparison of the approximation methods for time-delay systems: application to multi-agent systems</li>
                        <li> Sep 2019: Transient Behaviour Analysis and Design for Platoons with Communication Delay - 15th IFAC workshop on Time Delay Systems</li>
                        <li> Sep 2019: Approximation of a Linear Autonomous Differential Equation with Small Delay - Symmetry 11(10)</li>
                        <li> Nov 2019: Testbed for Mobile Robot Platoon Control System Evaluation - CINTI/MACRo</li>
                        <li> May 2020: Laboratory exercise guide book for Control Theory - Scientia</li>
                        <li> Jul 2020: Asymptotically ordinary linear Volterra difference equations with infinite delay - Applied Mathematics and Computation 386</li>
                        <li> Mar 2021: Control of a Class of Distributed Delay Systems - Systems and Control Letters 149(8):104882</li>
                        <li> Oct 2021: Approximation and observer design for a class of continuous time-delay systems - ICSTCC </li>
                        <li> Nov 2021: Approximation of discrete-time systems with delay: application to multi-agent systems - CINTI</li>
                        <li> Jun 2022: Asymptotic characterisation of dynamic linear systems with small time delay - University of Pannonia</li>
                        <li> Nov 2022: Smartphone–controlled industrial robots: Design and user performance evaluation - CINTI-MACRo</li>
                        <li> Oct 2023: Approximation Based H∞ Control of Linear Systems with State Delays - ICCAS</li>
                    </ul>
                    <p><a href="https://scholar.google.com/citations?user=kCM___MAAAAJ&hl=en&oi=ao">Google Scholar profile</a></p>
                </div>
                <div className="em__resume-body__award">
                    <h2>
                        Contests and awards:
                    </h2>
                    <ul>
                        <li> 2006: regional physics olimpics 2nd place</li>
                        <li> 2007: regional physics olimpics 1st place, Raluca Rapan national chemistry contest
                            3rd place, Öveges József national physics contest 2nd place</li>
                        <li> 2008: Special award in regional finals of the Hungarian Mathematics contest,
                            Vermes Miklós international physics contest 2nd place,
                            Hevesi György regional chemistry contest 2nd place, regional physics olimpics 3rd place</li>
                        <li> 2009: Schwartz Lajos memorial contest 2nd place,
                            Vermes Miklós international physics contest 3rd place,
                            regional Hungarian Mathematics Contest 3rd place,
                            Hevesi György regional chemistry contest 3rd place</li>
                        <li> 2010: Participated in the regional finals of the Hungarian Mathematics contest,
                            Adolf Haimovici national mathematics contest - 3rd place</li>
                        <li> 2011: Special award in regional finals of the Hungarian Mathematics contest,
                            Adolf Haimovici regional mathematics contest - 1st place</li>
                        <li> 2013: Digilent Design Contest 2nd price in European stage
                            and innovation price in Shanghai- GFX - Processor</li>
                        <li> 2014: TDK 1st price in Temesvár - 12 lead ECG estimation
                            with reduced lead measurements</li>
                        <li> 2015: Digilent Design Contest 2nd place in European stage-
                            Embedded ECG Data Acquisition System</li>
                    </ul>
                </div>

                <div className="em__resume-body__work">
                    <h2>
                        Work:
                    </h2>
                    <ul>
                        <li> 2016 - 2019: lab technician at the robotics and control systems laboratory,
                            Department of Electrical Engineering, Sapientia Hungarian University of Transylvania</li>
                        <li>2019 - 2023: assistant lecturer at the Department of Electrical Engineering,
                            Sapientia Hungarian University of Transylvania</li>
                        <li>
                            2023 - present: assistant professor at the Department of Electrical Engineering,
                            Sapientia Hungarian University of Transylvania
                        </li>
                    </ul>
                </div>
                <div className="em__resume-body__lang">
                    <h2>
                        Languages:
                    </h2>
                    <ul>
                        <li> Hungarian - mother tongue</li>
                        <li>English - proficient user</li>
                        <li> Romanian - proficient user</li>
                        <li>Japanese - basic user</li>
                    </ul>
                </div>
                <div className="em__resume-body__comp">
                    <h2>
                        Computer skills:
                    </h2>
                    <ul>
                        <li> Operating systems: Windows, Linux, Android</li>
                        <li> Programming Languages: Python, C/C++/C#, Rust, Matlab/Simulink, Java, Kotlin, F#, LUA script</li>
                        <li> Controller programming: Microchip PIC/dsPIC, Texas Instruments MSP430/C55x,
                            STMicroelectronics MCU/MPU or other MIPS/ARM based units in C/C++ or ASM</li>
                        <li> Digital hardware design in Verilog, System Verilog and HLS C
                            (hands on experience in Xilinx and Lattice FPGAs)</li>
                        <li> PCB design in Autodesk Eagle and Altium Designer</li>
                        <li> Multimedia: Photoshop, 3dsMax, Cubase, Unreal Engine</li>
                        <li> Applications: Common Windows/Android applications, LaTex</li>
                        <li> Web design: basic HTML, CSS, JavaScript, React JS knowledge</li>
                    </ul>
                </div>
                <div className="em__resume-body__hobby">
                    <h2>
                        Hobbies:
                    </h2>
                    <p>Cycling, hiking, yoga, meditation, playing the bass and cooking. Read more about my hobbies at
                        the <NavLink to="/hobby">Hobby</NavLink> section.</p>
                </div>
            </div>
        </div>
    );
}

export default About;

