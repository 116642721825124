import React from "react";
import "./../hobby.css";
import "./../collection.css";
import {Tag} from "../../../common";
import {useNavigate} from "react-router-dom";

const tealist = [
    {name:"1. White Tea", link: "./tea-hub-white"},
    {name:"2. Green Tea", link: "./tea-hub-green"},
    {name:"3. Yellow Tea", link: "./tea-hub-yellow"},
    {name:"4. Oolong Tea", link: "./tea-hub-oolong"},
    {name:"5. Black Tea", link: "./tea-hub-black"},
    {name:"6. Hei cha", link: "./tea-hub-hei"},
    {name:"7. Sheng Puerh Tea", link: "./tea-hub-sheng"},
    {name:"8. Shou Puerh Tea", link: "./tea-hub-shou"},
    {name:"9. Purple Tea", link: "./tea-hub-purple"}
];

function TEAHUB(){
    const navigate = useNavigate();
    return (
        <div className="em__post">
            <div className="em__post-section">
                <h3>A brief review of my past tea experience</h3>
                <p>
                I was born in a region where "tea" was well known and consumed daily.
                There was a caveat, though.
                Every plant part steeped/boiled in hot water (such as elderflowers, mint, bilberry leaves, lemon peel, St. John's wort, and tea leaves) was considered tea.
                When I was a bit older, I've learnt that there are tisanes and the only proper tea is the brew made from the Camellia sinensis, which came in nice little teabags.
                For the child me, these were bitter as hell, and it was refreshing when the pleasant added aromas, the milk and sugar could mask this taste.
                <br/>
                Sometime after this, I was gifted a caddy of spiced whole-leaf tea.
                It was the Christmas blend from Twinings of London.
                I was amazed at how different the brew was, so I got curious.
                If whole-leaf tea is better than fannings in a bag, there may be more to the tea than the teabags in the supermarket could offer.
                <br/>
                My next logical step was to browse eBay for whole-leaf teas.
                During this time, I've had some pleasant experiences: smoky lapsang souchong and keemun,
                but most of the time, the brew was not so enjoyable: too bitter sencha,
                Taiwanese ginseng oolong that was too astringent/sour, and some compressed mini teas that tasted like bitter dirt water straight from the barn.
                I've started to learn even more about different tea types and different tea packaging forms.
                I've also dwelled deeper into speciality tea stores.
                <br/>
                Enough about my starting experience with tea. Let's talk a bit about the history of tea and the tea plants.
                </p>
                <p>
                    Tea is not just a beverage; it is an art form, a symbol of tradition, and a window into the rich cultural heritage of different regions.
                    Among the wide varieties of tea available, Chinese tea stands out for its exquisite flavours, captivating aromas, and historical significance.
                    From delicate green to robust black teas, China offers a vast spectrum of tea types that have enthralled tea enthusiasts worldwide.
                </p>
                <p>
                    Chinese tea has a history that spans thousands of years, dating back to ancient times.
                    Legend has it that Emperor Shennong (Jiang Shinian) considered the "Divine Farmer" discovered tea accidentally around 2737 BCE when tea leaves fell into his boiling water.
                    Since then, tea cultivation and consumption have become deeply ingrained in Chinese culture, giving rise to various tea traditions and ceremonies.
                </p>
                <p>
                    China boasts diverse climatic conditions and geographical landscapes, each contributing to the unique characteristics of its teas.
                    Here are some renowned tea-producing regions in China and the teas they are known for:
                </p>
                <ul>
                    <li>
                        <b>Anhui Province:</b> Keemun black tea is famous for its wine-like flavours and aroma, and the Hei Cha which is a particular post-fermented tea.
                    </li>
                    <li>
                        <b>Fujian Province:</b> The birthplace of white tea, including the highly prized Silver Needle and Bai Mu Dan.
                        Fujian is known for its oolong teas, such as Tie Guan Yin and Da Hong Pao.
                    </li>
                    <li>
                        <b>Zhejiang Province:</b> Known for its green teas, most notably Longjing (Dragon Well) tea, famous for its delicate, chestnut-like flavours and beautiful flat leaves.
                    </li>
                    <li>
                        <b>Yunnan Province:</b> Celebrated for its Pu-erh tea, a fermented tea prized for its earthy, complex flavours.
                    </li>
                </ul>
                <p>
                    Chinese tea preparation involves careful attention to detail and bringing out the best flavours and aromas.
                    Every step is crucial, from selecting suitable tea leaves to mastering the brewing techniques.
                    Gongfu Cha, a traditional Chinese tea ceremony, exemplifies this art.
                    Tea is prepared in small clay teapots or porcelain gaiwans (cup with a lid) and served in tiny cups, allowing for multiple infusions and savouring the evolving flavours.
                </p>
                <p>
                    Besides its delightful taste and aroma, Chinese tea offers numerous health benefits.
                    Green tea, for example, is rich in antioxidants and is <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2855614/">believed</a> to
                    boost metabolism and promote overall well-being.
                    Pu-erh tea is revered for its <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4818050/">potential</a> cholesterol-lowering effects and digestive benefits.
                    Moreover, tea has long been associated with social bonding, hospitality, and spiritual practices in Chinese culture, making it an integral part of everyday life.
                </p>
                <p>
                    Appreciating Chinese tea goes beyond the act of drinking.
                    It involves engaging all the senses, from observing the tea leaves' appearance to inhaling the delicate fragrance and experiencing the tea's texture on the palate.
                    Many tea enthusiasts collect teaware, such as Yixing clay teapots and delicate porcelain cups, adding an aesthetic dimension to the tea-drinking experience.
                </p>
                <p>
                    Chinese tea is a treasure trove of flavours, traditions, and cultural significance.
                    Its diverse range of teas, steeped in history, offers a remarkable sensory journey for tea lovers around the globe.
                    Whether you're sipping a delicate green tea or relishing the complexity of Pu-erh, Chinese tea opens a gateway to a world of flavours,
                    providing a glimpse into the deep-rooted heritage of a nation that cherishes the art of tea.
                </p>
                <p>
                    While China holds a prominent place in the world of tea, numerous countries across the globe boast their captivating tea traditions and unique tea types.
                </p>
                <ul>
                    <li>
                        <b>India:</b> Known as the second-largest tea producer globally, India offers a diverse range of teas.
                        Darjeeling tea, grown in the picturesque Himalayan region, is revered for its delicate floral aroma and muscatel flavours.
                        With its robust and malty profile, Assam tea is a popular choice for breakfast teas.
                        Additionally, India is renowned for its masala chai, a flavorful blend of black tea, milk, and spices.
                        Then there is the Kashmiri Kahwa, which is also a spiced tea using green tea simmered for a long time with basic salt to get a pink color.
                    </li>
                    <li>
                        <b>Japan:</b> Tea holds deep cultural significance in Japan, where the revered Japanese tea ceremony, known as Chanoyu or Sado, takes place.
                        Matcha, a finely ground powdered green tea, is central to this ceremony.
                        It offers vibrant green colour, a unique umami taste, and a wealth of antioxidants.
                        Sencha and Gyokuro are other popular Japanese green teas, each exhibiting distinct flavour profiles.
                        There are also lesser known but still relevant Japanese teas like the wakoucha, and goishicha.
                    </li>
                    <li>
                        <b>Sri Lanka:</b> Formerly known as Ceylon, Sri Lanka is celebrated for its exquisite Ceylon tea.
                        Grown in high-altitude tea estates, Ceylon tea encompasses a wide range of flavours and strengths, from delicate and citrusy high-grown teas to full-bodied and robust low-grown teas.
                        Sri Lanka's tea heritage and scenic tea plantations make it a destination for tea enthusiasts.
                    </li>
                    <li>
                        <b>Taiwan:</b> Renowned for its high-quality oolong teas, Taiwan offers an array of teas with exceptional craftsmanship.
                        Dong Ding (not to be confused with Ding Dong) oolong, Alishan oolong, and Oriental Beauty are notable Taiwanese
                        teas known for their complex flavours, floral notes, and smooth textures.
                        Taiwan's tea culture is deeply rooted, and tea appreciation is often accompanied by Gongfu-style brewing.
                    </li>
                    <li>
                        <b>Morocco:</b> In Moroccan culture, tea holds great significance, with the traditional Moroccan mint tea symbolising hospitality and friendship.
                        This aromatic tea combines Chinese gunpowder green tea with fresh mint leaves and sugar, creating a delightful and refreshing infusion enjoyed throughout the country.
                    </li>
                    <li>
                        <b>Turkey:</b> Turkish tea, or çay, is a staple in Turkish culture and social gatherings.
                        Typically prepared using black tea leaves brewed in a double teapot called a çaydanlık, Turkish tea is served in small tulip-shaped glasses.
                        The tea is often enjoyed strongly and sometimes sweetened with sugar cubes to balance the bold flavours.
                    </li>
                    <p>
                        Based on processing methods, tea can be divided into the following categories: white, green, yellow, oolong, red(black), post fermented teas (sheng, shou and hei cha).
                        I've made a different category for purple tea, which can be made into white, black or post fermented teas, but this tea has such a different taste profile that it deserves a separate class.
                    </p>
                </ul>
            </div>

            <div className="em__collection">
                <div className="em__collection-title">
                    <h1 className="gradient__text">Some of the teas that I've tasted:</h1>
                </div>

                <div className="em__collection-list">
                    {
                        tealist.map((item) => (
                            <div >
                                <Tag title={item.name} description={item.description} onClick={() => navigate(item.link)}/>
                            </div>
                        ))
                    }
                </div>

            </div>
        </div>
    );
}

export default TEAHUB;

