import React from "react";
import "./dsp_7.css";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";
import {MathJax, MathJaxContext} from "better-react-mathjax";
import fir_own_filter from "./fir_own_filter.png";
import fir_own_magnitude from "./fir_own_magnitude.png";
import fir_own_phase from "./fir_own_phase.png";
import fir_kaiser_filter from "./fir_kaiser_filter.png";
import fir_kaiser_magnitude from "./fir_kaiser_magnitude.png";
import fir_kaiser_phase from "./fir_kaiser_phase.png";


function ShowTex({string}){
    const config = {
        loader: { load: ["[tex]/html"]},
        tex: {packages: {"[+]": ["html"]},
            inlineMath: [["$", "$"]],
            displayMath: [["$$", "$$"]]
        }
    };

    return(
        <MathJaxContext config={config} version={3}>
            <MathJax dynamic inline>
                {string}
            </MathJax>
        </MathJaxContext>
    );
}

const fir_own_design = `
sampling_frequency = 1000           # Hz
pass_band_ripple = 0.2              # dB
stop_band_attenuation = -40         # dB
transition_band = 10                # Hz
center_frequency = 50               # Hz

linear_ripple = 10**(pass_band_ripple/20)
linear_attenuation = 10**(stop_band_attenuation/20)
NUM_FIR_TAP = -(2*sampling_frequency/3/transition_band) * np.log10(10*linear_attenuation*linear_ripple) 
NUM_FIR_TAP = np.ceil(NUM_FIR_TAP)
if NUM_FIR_TAP % 2 == 0:
    NUM_FIR_TAP = NUM_FIR_TAP + 1

print(f'N = {NUM_FIR_TAP}')

normalised_center_frequency = 2 * np.pi * center_frequency / sampling_frequency
print(f'w0 = {normalised_center_frequency}')

h = np.zeros((int(NUM_FIR_TAP)))
for n in range(int(NUM_FIR_TAP)):
    if n == (int(NUM_FIR_TAP) - 1) / 2:
        h[n] = normalised_center_frequency / np.pi
    else:
        h[n] = np.sin(normalised_center_frequency*(n - (NUM_FIR_TAP - 1) / 2))/(np.pi*(n - (NUM_FIR_TAP - 1) / 2))

w = np.zeros((int(NUM_FIR_TAP)))
for n in range(int(NUM_FIR_TAP)):
    if n < (int(NUM_FIR_TAP) - 1) / 2:
        w[n] = 2 * n / (NUM_FIR_TAP - 1)
    else:
        w[n] = -2 * n / (NUM_FIR_TAP - 1) + 2

h = np.multiply(h, w)

w, H = sg.freqz(h, 1, worN=5000, fs=sampling_frequency)
phi = np.unwrap(np.angle(H))

plt.figure()
plt.plot(w, 20*np.log10(np.abs(H)))
plt.grid(True)
plt.figure()
plt.plot(w, phi)
plt.grid(True)

t = np.arange(0, 1, 1/sampling_frequency)
x = np.sin(2*np.pi*t) + np.sin(200*np.pi*t) + np.sin(500*np.pi*t)
y = sg.lfilter(h, 1, x)

plt.figure()
plt.plot(t, x)
plt.plot(t, y)

plt.show()
`;

const fir_kaiser_design = `
sampling_frequency = 1000  # Hz
pass_band_ripple = 0.2  # dB
stop_band_attenuation = -40  # dB
transition_band = 10  # Hz
center_frequency = 50  # Hz

NUM_FIR_TAP, beta = sg.kaiserord(-stop_band_attenuation, transition_band/(0.5*sampling_frequency))
print(f'N = {NUM_FIR_TAP}, beta = {beta}')
b = sg.firwin(
    numtaps=NUM_FIR_TAP,
    cutoff=center_frequency,
    width=transition_band,
    window=('kaiser', beta),
    fs=sampling_frequency)

w, h = sg.freqz(b, 1, worN=8000, fs=sampling_frequency)
phi = np.unwrap(np.angle(h))

plt.figure()
plt.plot(w, 20 * np.log10(np.abs(h)))
plt.xlabel("frequency (Hz)")
plt.ylabel("magnitude (dB)")
plt.grid(True)
plt.show()

plt.figure()
plt.plot(w, phi)
plt.xlabel("frequency (Hz)")
plt.ylabel("phase shift (rad)")
plt.grid(True)
plt.show()

t = np.arange(0, 1, 1/sampling_frequency)
x = np.sin(2 * np.pi * t) + np.sin(200 * np.pi * t) + np.sin(500 * np.pi * t)
y = sg.lfilter(b, 1, x)

plt.figure()
plt.plot(t, x)
plt.plot(t, y)
plt.grid(True)
plt.xlabel("Time (s)")
plt.ylabel("Amplitude (1)")
plt.legend(['original', 'filtered'])
plt.show()
`;

function DSP_7(){
   return(
       <div className="em__post">
           <div className="em__post-title">
               <h1>FIR filter design in Python</h1>
           </div>
           <div className="em__post-section">
               <h3>Overview:</h3>
               <p>
                   In this tutorial, we will learn how to design an FIR filter using DTFT and the windowing method.
                   The design and analysis will be done in Python using the signal processing tools from scipy.
               </p>
           </div>

           <div className="em__post-section">
               <h3>Theory:</h3>
               <p>
                   First, let's understand what filtering really means.
                   In the theoretical part we will only study the low-pass filters, but every other type can be treated in the same way.
                   We have a discrete signal with temporal representation <ShowTex string="$x: \mathbb{Z} \to \mathbb{R}$"/>,
                   and spectral representation <ShowTex string="$X: \mathbb{R} \to \mathbb{C}$"/>, where <ShowTex string="$X(\omega) = DTFT\{x[n]\}$"/>.
               </p>
               <p>
                   An ideal low-pass filter should pass the components of the input signal from DC upt to a given <ShowTex string="$\omega_0$"/> cutting frequency,
                   and block out all the components from the cutting frequency up to the nyquist frequency (<ShowTex string="$\pi$"/>).
                   Taking into account that the spectral range of a sampled signal resides in the symmetrical domain <ShowTex string="$[-\pi, \pi]$"/>,
                   the filtering procedure is nothing more than a point-wise multiplication in the spectral domain
                   <ShowTex string="$$Y(\omega) = H(\omega)X(\omega),$$"/>
                   with a window function
                   <ShowTex string="$$H(\omega) = \begin{cases} 1, \quad \textrm{if } \omega \in [-\omega_0, \omega_0] \\ 0, \quad \textrm{otherwise}\end{cases}.$$"/>
               </p>
               <p>
                   If we use the DTFT mapping, we have
                   <ShowTex string="$$Y(\omega) = \sum_{k=-\infty}^{\infty} h[k]\mathrm{e}^{-j\omega k} \sum_{n=-\infty}^{\infty} x[n]\mathrm{e}^{-j\omega n}.$$"/>
                   We can shift the input signal without changing the spectral content
                   <ShowTex string="$$Y(\omega) = \sum_{k=-\infty}^{\infty} h[k]\mathrm{e}^{-j\omega k} \sum_{n=-\infty}^{\infty} x[n-k]\mathrm{e}^{-j\omega (n-k)}.$$"/>
                   Then, we can multiply the second sum with the outside terms and change the summing order
                   <ShowTex string="$$Y(\omega) = \sum_{n=-\infty}^{\infty} \underbrace{\sum_{k=-\infty}^{\infty} h[k]x[n-k]}_{h[n]*x[n]}\mathrm{e}^{-j\omega n}.$$"/>
                   In the current form, we can spot the convolution.
                   Finally, apply the IDTFT to this equation, and we have the temporal counterpart of the filter
                   <ShowTex string="$$y[n] = h[n]*x[n],$$"/>
                   where <ShowTex string="$h[n] = IDFT(H(\omega))$"/> is the impulse response of the ideal filter.
                   This way, we don't have to apply the DFT or FFT at each new input sample, we need to calculate the impulse response only once, and then use it for convolution.
               </p>
               <p>
                   From the previous definition of <ShowTex string="$H(\omega)$"/> we have
                   <ShowTex string="$$h[n] = \frac{1}{2\pi} \int_{-\pi}^{\pi} H(\omega) \mathrm{e}^{j\omega n} \mathrm{d}\omega
                    = \frac{1}{2\pi} \int_{-\omega_0}^{\omega_0}\mathrm{e}^{j\omega n} \mathrm{d}\omega
                    = \left. \frac{\mathrm{e}^{j\omega n}}{2\pi j n}\right|_{\omega = -\omega_0}^{\omega_0}
                    = \frac{\sin(\omega_0 n)}{\pi n}.$$"/>
                   This is the impulse response of the ideal low-pass filter.
                   Depending how the sampling is done, there are 4 types of linear phase FIR filters:
               </p>
           </div>
           <div className="em__post-section__centerList">
               <p>
                   <ul>
                       <li>
                           <b>Type-1 FIR: </b> N is odd <ShowTex string="$h[n]$"/> is of even parity.
                           Most versatile linear phase filter.
                           It can be used for low-pass, high-pass, band-pass, band-stop applications.
                       </li>
                       <li>
                           <b>Type-2 FIR: </b> N is even <ShowTex string="$h[n]$"/> is of even parity.
                           It can't be used for the design of high-pass filters.
                       </li>
                       <li>
                           <b>Type-3 FIR: </b> N is odd <ShowTex string="$h[n]$"/> is of odd parity.
                           It can't be used for low-pass or high-pass filter design.
                           It will introduce a <ShowTex string="$\pi/2$"/> phase shift.
                       </li>
                       <li>
                           <b>Type-4 FIR: </b> N is odd <ShowTex string="$h[n]$"/> is of odd parity.
                           It can't be used for low-pass filter design .
                           It will introduce a <ShowTex string="$\pi/2$"/> phase shift.
                       </li>
                   </ul>
               </p>

           </div>
           <div className="em__post-section">

               <p>
                   Let's stick to Type-1 FIR filters.
                   The only problem is that it's not finite, <ShowTex string="$n \in \mathbb{Z}$"/>.
                   We can truncate it on the interval <ShowTex string="$n \in [-\frac{N-1}{2}, \frac{N-1}{2}-1] \cap \mathbb{Z}$"/> to preserve the symmetry
                   (requirement for linear phase), but this will create a zero phase anti-causal filter and since we don't have samples from the future, we need to shift the impulse response
                   <ShowTex string="$$ h[n] = \frac{\sin\Big[\omega_0 \Big(n - \frac{N-1}{2}\Big)\Big]}{\pi \Big(n - \frac{N-1}{2}\Big)}$$"/>
               </p>
               <p>
                   This impulse could be used with some caveats.
                   For one, check the value for <ShowTex string="$ n = \frac{N-1}{2}$"/>:
                   <ShowTex string="$$ \lim_{n\to \frac{N-1}{2}} h[n] = \frac{0}{0},$$"/>
                   which is undefined, but we can use the <i>l'Hôpital</i> rule
                   <ShowTex string="$$ \lim_{n\to \frac{N-1}{2}} h[n] = \lim_{n\to \frac{N-1}{2}}\frac{\omega_0\cos\Big[\omega_0 \Big(n -
                   \frac{N-1}{2}\Big)\Big]}{\pi } = \frac{\omega_0}{\pi}.$$"/>
               </p>
               <p>
                   The final problem is the truncation. Sure, we created a finite impulse response, but we've also created discontinuities.
                   The discontinuities create a phenomenon called <a href="https://en.wikipedia.org/wiki/Gibbs_phenomenon">Gibb's effect</a>.
                   The truncation can be explained as a multiplication by a rectangular window function in temporal domain.
                   We can decrease the spectral ringing by choosing a different window, a window that is 1 at the center, 0 (or close to 0) at the ends,
                   and it monotonically decreases outwards.
                   There are a multitude of <a href="https://en.wikipedia.org/wiki/Window_function">window functions</a> like the triangle, von Hann, Hamming, Kaiser, etc.
               </p>
               <p>
                   After the window is generated, the new filter impulse response will be the dot product of the initial filter impulse and the window function.
               </p>
               <p>
                   There are other FIR filter design methods (least squares and minimax optimisation)
                   and other FIR filter types (minimum phase) but this much information is enough for us to get started with.
               </p>
               <p>
                   One final thought. We were talking about this magic <b>N</b> number, but how do we choose it?
                   Each filter type has its own formula (see <a href="https://docs.scipy.org/doc/scipy/reference/generated/scipy.signal.kaiserord.html">kaiserord</a>),
                   but a rough estimate for it can be done using
                   <ShowTex string="$$ N \approx -\frac{4\pi}{3\Delta \omega} \lg(10 \delta_1 \delta_2), $$"/>
                   where <ShowTex string="$\Delta \omega$"/> is the normalised transition frequency, <ShowTex string="$\delta_1, \delta_2$"/>
                   are the pass-band ripple and stop-band attenuation respectively in linear scale.
               </p>
           </div>
           <div className="em__post-section">
               <h3>Simulation:</h3>
               <p>
                   The python simulation will be broken into two parts: first, we will design a low-pass filter by explicit application of the theoretical knowledge.
                   Second, we will use the tools given by the scipy.signal module - since we want to ease our work, not increase it.
               </p>
               <p>
                   <b>Task:</b> Design a Type-1 low-pass FIR filter for a system with sampling frequency of 1000Hz,
                   with the following properties - pass-band ripple 0.2dB, stop-band attenuation -40dB, transition band 10Hz,
                   center frequency 50Hz.
                   Apply a triangle window to the FIR filter.
               </p>
               <div className="em_unselectable">
               <SyntaxHighlighter language="py" style={AtomOneDark}>
                   {fir_own_design}
               </SyntaxHighlighter>
               </div>
               <p>
                   Here we have the parameters.
                   We calculate the required taps, which in this case happens to be N = 67 (I made sure that N is odd to be of Type-1).
                   Next, we can compute the impulse response using the sinc function and the explicit center value.
                   We can compute the triangle window, which is a piece-wise linear function 0 at the end and 1 in the middle.
                   The input signal is generated as the usual triple combined sine waves.
                   We can use the <i>lfilter</i> function to apply the filter. In the case of FIR filters, there is no feedback, so the denominator is 1.
               </p>
               <img className="img_fir_own_magnitude_dsp-7" src={fir_own_magnitude} alt="fir_own_magnitude_dsp-7"/>
               <img className="img_fir_own_phase_dsp-7" src={fir_own_phase} alt="fir_own_phase_dsp-7"/>
           </div>
           <div className="em__post-section">
               <img className="img_fir_own_filter_dsp-7" src={fir_own_filter} alt="fir_own_filter_dsp-7"/>
               <p>
                   An N=225 tap FIR filter was designed.
                   This is much better than our previous moving average filter - although, the stop-band attenuation is not quite right,
                   Now let's try to design the same FIR filter but with Kaiser window, using the built-in functions.
               </p>
               <div className="em_unselectable">
               <SyntaxHighlighter language="py" style={AtomOneDark}>
                   {fir_kaiser_design}
               </SyntaxHighlighter>
               </div>
               <img className="img_fir_kaiser_magnitude_dsp-7" src={fir_kaiser_magnitude} alt="fir_kaiser_magnitude_dsp-7"/>
               <img className="img_fir_kaiser_phase_dsp-7" src={fir_kaiser_phase} alt="fir_kaiser_phase_dsp-7"/>
           </div>
           <div className="em__post-section">
               <img className="img_fir_kaiser_filter_dsp-7" src={fir_kaiser_filter} alt="fir_kaiser_filter_dsp-7"/>
               <p>
                   Now not only the pass-band ripple, but the stop-band attenuation is also fulfilled.
                   one thing that you can notice is that there is transient time when the output is not valid.
                   This transient time is introduced because we've shifted the impulse response by (N-1)/2 - which results in a constant phase shift.
               </p>
           </div>

           <div className="em__post-section">
               <h3>Further work:</h3>
               <p>
                   <ol>
                       <li>
                           On paper, derive the impulse response of the high-pass (center frequency <ShowTex string="$\omega_0$"/>),
                           band-pass and band-stop (low and high frequencies <ShowTex string="$\omega_L, \omega_H$"/>) filters.
                       </li>
                       <li>
                           Write your own function to perform the sample based filtering.
                       </li>
                       <li>
                           Generate the impulse response for the following Type-1 FIR filters: sampling frequency 5kHz,
                           pass-band ripple 0.2dB, stop-band attenuation -40dB, transition band 10Hz, LP center frequency 50Hz,
                           BP frequencies 50Hz and 500Hz, HP center frequency 800 Hz.
                       </li>
                       <li>
                           Check with <i>freqz</i> that your filters work as intended.
                       </li>

                   </ol>
               </p>
           </div>


           <div className="em__post-navigation">

               <NavLink to="./../dsp-6">
                   <Button btnID={"leftBTN"} buttonSize="btn--medium"> Prev Post</Button>
               </NavLink>

               <NavLink to="./../dsp-8">
                   <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
               </NavLink>
           </div>
       </div>
   );
}

export default DSP_7;