import React from "react";
import "./stm_rtos1.css";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";

import coop_sched from "./coop_sched.jpg";
import multi_task from "./multi_task.jpg";
import preempt_sched from "./preempt_sched.jpg";
import rtos_program_memory from "./rtos_program_memory.jpg";
import scheduler from "./scheduler.jpg";
import simple_program_memory from "./simple_program_memory.jpg";
import super_loop from "./super_loop.jpg";
import task_states from "./task_states.jpg";
import mcu_pinout_mx from "./mcu_pinout_mx.jpg";
import rtos_heap_usage_mx from "./rtos_heap_usage_mx.jpg";
import rtos_kernel_mx from "./rtos_kernel_mx.jpg";
import rtos_mm_mx from "./rtos_mm_mx.jpg";
import default_task_mx from "./default_task_mx.jpg";

const st_link = "https://www.st.com/content/st_com/en/support/learning/stm32-education/stm32-moocs/freertos-common-microcontroller-software-interface-standard-osv2.html";

const simple_code = `
static uint8_t global_var1 = 5;
uint8_t global_var2;

void foo(uint8_t bar){
    static uint8_t local_var1 = 0;
    uint8_t idx;
    uint8_t *local_dynamic;
    
    local_dynamic = (uint8_t*) malloc(bar * sizeof(uint8_t);
    /*...
        ...
            ...*/
    free(local_dynamic);
}
`;

const main_os = `
osKernelInitialize(); 
MX_FREERTOS_Init();
osKernelStart();`;

const task_handle = `
osThreadId_t defaultTaskHandle;
const osThreadAttr_t defaultTask_attributes = {
  .name = "defaultTask",
  .priority = (osPriority_t) osPriorityNormal,
  .stack_size = 128 * 4
};`;

const task_creation = `defaultTaskHandle = osThreadNew(StartDefaultTask, NULL, &defaultTask_attributes);`;

const task_callback = `
void StartDefaultTask(void *argument)
{
  /* USER CODE BEGIN StartDefaultTask */
  HAL_GPIO_WritePin(LD2_GPIO_Port, LD2_Pin, GPIO_PIN_RESET);
  /* Infinite loop */
  for(;;)
  {
    HAL_GPIO_TogglePin(LD2_GPIO_Port, LD2_Pin);
    osDelay(500);
  }
  /* USER CODE END StartDefaultTask */
}`;

const cmakelist_hfloat = `
add_compile_definitions(ARM_MATH_CM4;ARM_MATH_MATRIX_CHECK;ARM_MATH_ROUNDING)
add_compile_options(-mfloat-abi=hard -mfpu=fpv4-sp-d16)
add_link_options(-mfloat-abi=hard -mfpu=fpv4-sp-d16)
`;


function STM_RTOS1(){
    return(
        <div className="em__post">
            <div className="em__post-title">
                <h1>RTOS introduction, IDE setup</h1>
            </div>

            <div className="em__post-section">
                <h3>Aim of this tutorial:</h3>
                <p>
                    This tutorial will be mostly about the introdutory theory of Real-Time Operating Systems (RTOS),
                    specifically the CMSIS OS v2, which is the ST flavored overlay of the Free RTOS.
                    We will also create a base project, which we will use latter on in this tutorial series.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Code execution and operating systems:</h3>
                <p>
                    An operating system (OS) is responsible for the scheduling of program tasks, managing the system resources,
                    device drivers.
                    In case of a general purpose OS, the human interaction is the highest priority and the execution is highly non-deterministic.
                    This non-deterministic nature is unwanted in case of a real-time problem.
                    We are talking about <b>real-time systems</b>, when a job has a deadline and it must be finished before the given deadline.
                    A hard real-time process will give incorrect results if the time constraint is not met (air traffic, medical, switch-mode supply devices),
                    meanwhile, a soft real-time process gives degraded results if the time constraint is not met (multimedia data transfer or computer game programs).

                    <br/>
                    Based on these notions a real-time OS (RTOS) offers mostly the same features as the general purpose OS
                    with precise timing, some basic drivers (BlueTooth, WiFi, LCD etc.) or in some case they only have a simple scheduler.
                </p>
                <img className="img_super_loop_rtos1" src={super_loop} alt="super_loop_rtos1"/>
                <img className="img_multi_task_rtos1" src={multi_task} alt="multi_task_rtos1"/>
            </div>
            <div className="em__post-section">
                <p>
                    On the left figure, we have the classical super loop code structure.
                    The MCU starts up, the boot sequence initializes the memory regions,
                    and jumps the program counter to the main program section.
                    The main starts with system initialisations (clock, debug, interrupt and other peripheral),
                    then the program enters the infinite loop (super loop) which contains some tasks to be executed.
                    The task are executed sequentially, they may or may not contain conditional operations which could skip a whole task execution.
                    This together with an interrupt request which is generated based on external events weakens the determinism.
                    <br/>
                    On the right figure, we have a system capable of parallel task execution.
                    Although the boot and setup sequences are the same as before,
                    here every task can be independently tuned and executed (if they do not need a shared resource).
                    <br/>
                    The super loop model is easier to debug, it saves cycles and memory usage.
                    If there are strict deadlines for some task, that can be executed with the help of the ISR (1ms or less)
                    or with the help of custom hardware (100ns or less).
                    There is no option for concurrency, and blocking functions stall or slow the loop.
                    <br/>
                    The parallel task model is only possible on a multi-core setup, so we need some compromises between these models for our processors with a single core.
                </p>
                <p>
                    Before we dive further, there are three essential keywords:
                    <b>task</b> - a set of instructions that are loaded into the memory, basically any work that we want to be done using the CPU;
                    <b>thread</b> - a part of the CPU execution with separate program counter and memory stack;
                    <b>process</b>- an instance of a program that is executed (one or more threads that needs to execute tasks).
                    In FreeRTOS, the task and thread notions is used interchangeably.
                    We will only work with a version of RTOS that is designed for single core processors (they can execute a single process),
                    for other use-cases, loop up the terms symmetric multi-processing (SMP) or asymmetric multi processing (AMP).
                    These are architectures with more than one cores of the same (e.g. ARM Cortex M0/M0) or different (e.g. ARM Cortex M7/M4) respectively.
                </p>

                <p>
                    We will be using CMSIS v2 OS, which is a layer above the FreeRTOS, so if you are familiar with the latter, some function names will be different.
                    Check <a href={st_link}>this</a> resource from STMicroelectronics to have a better understanding of the differences.
                </p>
            </div>

            <div className="em__post-section">
                <h3>RTOS components and ideas:</h3>
                <p>
                    The RTOS consists of the following basic blocks:
                    <ul>
                        <li>
                            <i>Scheduler</i> - manages the tasks and the context switching.
                        </li>
                        <li>
                            <i>Tasks</i> - a mini program with own setup, endless loop parts and memory sections.
                        </li>
                        <li>
                            <i>Queues</i> - memory structure to pass information between two tasks or a task and interrupt routine.
                        </li>
                        <li>
                            <i>Semaphores</i> - a special queue with length 1 and data size 0 for task synchronization.
                        </li>
                        <li>
                            <i>Flags</i> - event or thread flags for fast task state manipulation.
                        </li>
                        <li>
                            <i>Mutexes</i> - a semaphore with token(s) (binary semaphore with priority inheritance) for resource management.
                        </li>
                        <li>
                            <i>Software Timers</i> - timers based on the main(system) timer that allow non-blocking callbacks to be executed at certain times.
                        </li>
                        <li>
                            <i>Hooks</i> - callback functions for fault handling.
                        </li>
                    </ul>
                    Here, I was willingly vague, but don't worry (be happy), we will take a look at every point in this list soon (TM).
                </p>
            </div>

            <div className="em__post-section">
                <h3>Task Scheduling:</h3>
                <p>
                    As stated in the previous section, we would like to implement a system with concurrent task execution, but we only have a single core.
                    So we will make the execution "look" like concurrent, by dividing the execution time into small segments.
                </p>
                <img className="img_scheduler_rtos1" src={scheduler} alt="scheduler_rtos1"/>
                <p>
                    Here, we can see a simple time-sliced scheduling.
                    Every vertical slice has a predefined length, which is 1ms in most cases.
                    The horizontal slices are the different priority slots.
                    At the start of every time slice, the operating system calls the scheduler,
                    which chooses a single task to run from a list of available tasks.
                    <br/>
                    Let's look at a scenario, where we have three tasks: T1 with low priority that will execute a delay in the second time slice,
                    T2 and T3 with high priority starting from the fifth slice.
                    Since there is only one available task, the scheduler chooses it and it starts executing for a time slice.
                    The same action happens at the start of the second slice, but T1 calls the delay function.
                    Since there are no other tasks, the Idle task is executed for a single slice after which T1 can be executed.
                    From the fifth slice T2 and T3 are available with higher priority, so the scheduler will start them alternating.
                    <br/>
                    A hardware interrupt will always have higher priority then any RTOS tasks, meaning that with a certain setting
                    the currently running task will yield for the interrupt routine.
                    An ISR will never be interrupted by a task, ant it may be preempted by another ISR only when nested interrupts are enabled.
                    After the ISR is done, the execution of the last task will be resumed.
                    <br/>
                </p>
            </div>

            <div className="em__post-section">
                <p>
                    So... what are the task states, and how does the scheduler choose between tasks?
                </p>
                <img className="img_task_states_rtos1" src={task_states} alt="task_states_rtos1"/>
                <p>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    Here we can see the states of an RTOS task.
                    After creation, a task automatically enters the <i>ready</i> state, which means that it is ready to run at any time.
                    A task in ready state can only be chosen by the scheduler if there are no higher priority tasks in the ready state.
                    If the scheduler chose a task to run, the task enters the <i>run</i> state, and it will remain in that state while the processor is used.
                    The scheduler can put a task into run or ready state at the start of every time slice.
                    <br/>
                    Some API functions can move the state of the task into <i>blocked</i>.
                    These functions can be delays, or functions that wait for queues and semaphores.
                    Blocked tasks can't be chosen by the scheduler.
                    The unblocking event must first occur so that the state changes back to ready.
                    <br/>
                    Tasks can be suspended using the vTaskSuspend() function.
                    A task can be suspended from its own call, or from another task.
                    This changes the state to <i>suspended</i>, and the task can't be run by the scheduler.
                    To put a suspended task back into ready state, the vTaskResume function must be called.
                </p>
            </div>
            <div className="em__post-section">
                <p>
                    The scheduler implements a round robin algorithm to select a task from the ready queue.
                    Each task is given a time slot to process a given job.
                    Once a task is executed and the time slot expires, the task gets preempted and another task gets executed.
                    Context switching is used to save the states, and internal variables of the preemted task.
                    The algorithm is starvation-free, since all tasks get a fair share of CPU usage.
                    The task are executed based on priority, task with the same priority are chosen based on the time of arrival.
                </p>
                <p>
                    I've mentioned the preemption of tasks before.
                    There are two basic types of task scheduling: cooperative and preemptive.
                    In case of the cooperative scheduling:
                    <ul>
                        <li>there are no time slice preemption,</li>
                        <li>tasks are not preempted with higher priority tasks,</li>
                        <li>the context switches only when a running task goes into blocked state (after osDelay),
                            or after it goes into ready state (osThreadYield) or it is put into suspend mode.
                        </li>
                    </ul>
                    In case of the preemptive scheduling:
                    <ul>
                        <li>tasks with the same priority share CPU time,</li>
                        <li>context is switched when the time slice is passed, a higher priority task comes,
                            the state of the task changes into blocked or ready state.
                        </li>
                    </ul>
                    There are some hybrid scheduling methods, like the cooperative with preemption by interrupt, where
                    the time slicing still does not exist, but the interrupt can trigger a context switching.
                </p>
                <img className="img_preempt_sched_rtos1" src={preempt_sched} alt="preempt_sched_rtos1"/>
                <img className="img_coop_sched_rtos1" src={coop_sched} alt="coop_sched_rtos1"/>
            </div>

            <div className="em__post-section">
                <p>
                    On the left, we have the cooperative scheduling (with interrupt preemption) of tasks T1, T2 and T3 with the same priority.
                    We can see that the time slices are not switching context (T1, T2, T3 are in ready state).
                    The supervisor call (SVC) starts the scheduler, which starts the first task (T1 is in run state, T2 and T3 are in ready state).
                    T1 is executed first, finishes the task and yields.
                    Then a pendable service (PendSV) is asserted.
                    This triggers a context switch and the T2 task is executed (T2 is in run state, T3 and T1 are in ready state).
                    T2 executes a delay instruction, which blocks the task for the specified amount of time (T3 adn T1 ready, T2 blocked).
                    T3 gets executed (T3 run, T1 ready, T2 blocked) but shortly an interrupt request is asserted, so the states get saved, the context switches.
                    After the ISR finished, T3 is run again.
                </p>
                <p>
                    On the right, we have a preemptive scheduling of tasks T1, T2, T3 with the same normal priority and T4 (blocked, waiting for an event) task with a higher priority.
                    Similarly as before SVC starts the scheduler (T1, T2, T3 are ready, T4 is blocked), which starts T1 (T1 run, T2 and T3 ready, T4 blocked).
                    After the time slice is over, sysTick generates context switch, T2 is started (T2 run, T3 and T1 ready, T4 blocked).
                    T2 finishes the work and yields, so T3 is started (T3 run, T1 and T2 ready, T4 blocked).
                    The time slice is over, sysTick generates context switch, T1 is started (T1 run, T2 and T3 ready, T4 blocked).
                    Here an event unlocks the higher priority tasks, so a context switch occurs, T4 gest executed (T4 run, T1, T2 and T3 ready).
                    T4 finished and gets blocked by a delay instruction, context switching occurs.
                    Since this slice was dedicated to T1, T1 will be run (T1 run, T2 and T3 ready, T4 blocked).
                </p>
                <p>
                    We can give priorities to tasks.
                    The priority 0 never gets executed.
                    The Idle task has priority 1, The low priority is 8.
                    There are Low1, Low2, ... Low7 priority task, which corresponds to a priority level of 9, 10, ... 15.
                    BelowNormal is 16, BelowNormal1...7 are 17...23. The Normal priority is 24, the AboveNormal is 32,
                    the High is 40, the RealTime is 48 and the ISR is 56 (there are numbered priorities in between).
                </p>
                <p>
                    When the context switching occurs, the process stack pointer (PSP),
                    the program counter and the local variables will be saved.
                    If the processor used the floating point unit, additional registers must be pushed to the stack (and popped afterwards).
                </p>
            </div>

            <div className="em__post-section">
                <h3>Memory management:</h3>
                <p>
                    Before we have a look at how the RTOS manages the memory, let's have a look at a simple program:
                    <SyntaxHighlighter language="c" style={AtomOneDark}>
                        {simple_code}
                    </SyntaxHighlighter>
                </p>
                <p>
                    We are not interested in the behavior of it, rather what part gets stored where in the memory.
                </p>
                <img className="img_simple_program_memory_rtos1" src={simple_program_memory} alt="simple_program_memory_rtos1"/>
                <p>
                    <br/>
                    <br/>
                    There are two global variables and a local static variable, which will be stored in the static part of the RAM.
                    <br/>
                    The function parameters, local variables and pointers are pushed in the stack part of the RAM.
                    The stack is a LIFO (last in first out) system, so that adding and removing data is easier.
                    After the function call is over, data can be popped or discarded from the stack.
                    Usually stack is allocated at compilation time, but the stack can also grow (see recursive functions).
                    <br/>
                    The dynamic memory allocation is done in the heap by the user.
                    One must free the allocated memory after use, otherwise the heap could grow indefinitely causing a memory leak.
                    The heap and stack could run into each other causing other malfunctions.
                    <br/>
                    The stack and heap placement are architecture dependent, but they are on the opposite side of the RAM block.
                </p>
            </div>
            <div className="em__post-section">
                <p>Now let's take a look at memory allocation in case of the RTOS.</p>
                <img className="img_rtos_program_memory_rtos1" src={rtos_program_memory} alt="rtos_program_memory_rtos1"/>
                <p>
                    Here, I've left out the static memory, since that is fixed.
                    The lower part of the RAM is used as stack for the main application and the interrupt services.
                    The top part is used for heap, where the topmost part is reserved for main application.
                    The minimum heap and stack allocation of the main application can be specified for the compiler outside of RTOS.
                    <br/>
                    The RTOS application is organised in a heap memory after the mai heap area.
                    This area incorporates well defined and separated regions for tasks queues etc.
                    <br/>
                    A task memory region consists of a task control block (TCB) 88B, and a private stack which can be predefined (e.g 128B).
                    The queue memory region consists of the queue control block (QCB) 84B, and the queue storage area.
                </p>
                <p>
                    We can see how even the simplest programs can take up much memory if we use an RTOS.
                    Further more, there are five allocation schemes for the heap.
                    <ul>
                        <li>
                            <i>heap_1</i>: is the simplest allocation scheeme.
                            It is deterministic, the memory must be allocated at startup,
                            and does not permit to free the memory.
                            This method is less useful, since FreeRTOS and CMSIS OS allows for static memory allocation.
                        </li>
                        <li>
                            <i>heap_2</i>: permits memory to be freed,
                            uses best fit algorithm for new allocations,
                            but does not combine free blocks.
                            It is considered a legacy scheme, and heap_4 is the preferred method.
                        </li>
                        <li>
                            <i>heap_3</i>: creates a thread-safe wrapper for the classic malloc and free functions that
                            are supplied with the compiler.
                            It requires the linker to set up a heap, and it will increase the size of the RTOS kernel.
                        </li>
                        <li>
                            <i>heap_4</i>: uses first fit algorithm for memory allocation, combines free, adjacent memory blocks.
                            Includes absolute address placement options.
                            The heap is implemented in a linked list form: N B of heap is allocated as 8 + N + n, where
                            N is the required space and n is the padding to 8B alignment e.g. a prescribed 52B heap should be allocated as 8 + 52 = 60,
                            so 64B will be consumed.
                        </li>
                        <li>
                            <i>heap_5</i>: behaves as heap_4 with a single major difference.
                            It allows the memory region to span across multiple non-adjacent (non-contiguous) memory regions e.g.
                            SRAM1, SRAM2 in case of the STM32.
                            When heap_5 scheme is used, it is essential to explicitly initialize the memory using PortDefineHeapRegion
                            before any RTOS object creation.
                        </li>
                    </ul>
                </p>
            </div>

            <div className="em__post-section">
                <h3>First RTOS project:</h3>
                <p>
                    After all this dry theory, let's create an overkill blinking project, by stripping everything from the CMSIS OS.
                    We will use this project in the upcoming tutorials.
                    Similarly to the HAL tutorials, I will be using CLion together with STM32CubeMX.
                    <br/>
                    Create a new project with your preferred board, I'm using a NUCLEO-G491RE.
                </p>
                <Popup trigger={<img className="img_mcu_pinout_mx_rtos1 clickable_img" src={mcu_pinout_mx} alt="mcu_pinout_mx_rtos1"/>} modal nested>
                    {
                        close =>(
                            <img className="em__img_full" src={mcu_pinout_mx} alt="mcu_pinout_mx_rtos1"/>
                        )
                    }
                </Popup>
                <p>
                    I've enabled the high-speed external crystal interface,
                    I've set the system clock to 170MHz and I've enabled the debug pins.
                    We have a single push button, an LED and UART communications enabled.
                    Further more, I've added PC9 as event out and PC8 as a digital output so that we can measure timing in he future.
                    Set the output speed of PC8/PC9 to very high.
                </p>
                <p>
                    Go to Middleware and enable FreeRTOS by setting <i>CMSIS_V2</i> as the interface.
                    Quite a few parameters will appear in the configuration block.
                    This time, we will only look at a few of them.
                </p>
                <Popup trigger={<img className="img_rtos_kernel_mx_rtos1 clickable_img" src={rtos_kernel_mx} alt="rtos_kernel_mx_rtos1"/>} modal nested>
                    {
                        close =>(
                            <img className="em__img_full" src={rtos_kernel_mx} alt="rtos_kernel_mx_rtos1"/>
                        )
                    }
                </Popup>
                <p>
                    Under Config parameters/Kernel settings, we can see the time slice (tick) rate of 1kHz,
                    the minimal stack size of 128 Word (256B) for a task.
                    We are using preemptive scheduling by default, some basic mutexes and semaphores are enabled bhy default.
                    We can check the heap allocation scheme under memory management.
                </p>
                <Popup trigger={<img className="img_rtos_mm_mx_rtos1 clickable_img" src={rtos_mm_mx} alt="rtos_mm_mx_rtos1"/>} modal nested>
                    {
                        close =>(
                            <img className="em__img_full" src={rtos_mm_mx} alt="rtos_mm_mx_rtos1"/>
                        )
                    }
                </Popup>
                <p>
                    It uses the heap_4 scheme, and 3072B is allocated to the heap.
                    Open up the Tasks and Queues section.
                    Here you can see a single task created by default - double click on it.
                </p>
                <Popup trigger={<img className="img_default_task_mx_rtos1 clickable_img" src={default_task_mx} alt="default_task_mx_rtos1"/>} modal nested>
                    {
                        close =>(
                            <img className="em__img_full" src={default_task_mx} alt="default_task_mx_rtos1"/>
                        )
                    }
                </Popup>
                <p>
                    It has a name, normal priority, the minimum stack size, and the name of the function that gets executed.
                    Now go to the FreeRTOS heap usage section:
                </p>
                <Popup trigger={<img className="img_rtos_heap_usage_mx_rtos1 clickable_img" src={rtos_heap_usage_mx} alt="rtos_heap_usage_mx_rtos1"/>} modal nested>
                    {
                        close =>(
                            <img className="em__img_full" src={rtos_heap_usage_mx} alt="rtos_heap_usage_mx_rtos1"/>
                        )
                    }
                </Popup>
                <p>
                    You can see that a single task uses 632B from the Heap.
                    Generate the project.
                    There may be a warning to enable reentrant new lib - but we'll ignore that for now.
                </p>
            </div>

            <div className="em__post-section">
                <p>
                    We are interested in three files: <i>FreeRTOSConfig.h</i> - this header contains the configurations that we've just made;
                    <i>AppFreeRTOS.c</i> - the source file for the RTOS components, init functions, tasks, queues etc;
                    <i>main.c</i> - the main source where the MCU gets configured and the RTOS is started.

                    <br/>
                    If we have a look at the main function, we have three additional function calls after the peripheral initialisation.
                </p>

                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {main_os}
                </SyntaxHighlighter>
                <p>
                    <i>osKernelInitialize()</i> sets up the FreeRTOS objects.
                    <i>MX_FREERTOS_Init()</i> is an init function from AppFreeRTOS.c, and it creates the defined tasks, queues etc.
                    <i>osKernelStart()</i> starts the scheduler, and after this the task codes will be executed, we won't enter the main loop of the main function.
                    If we open the AppFreeRTOS.c source, the first object is the default task handle.
                </p>

                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {task_handle}
                </SyntaxHighlighter>
                <p>
                    It contains the name, priority and the stack size.
                    Further down, in MX_FREERTOS_Init, we have the osThreadNew function that creates our
                    task using the handle and the entry function name.
                </p>

                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {task_creation}
                </SyntaxHighlighter>
                <p>
                    The entry function has two sections.
                    The section before the infinite loop is executed only once after the creation of the task.
                    Here I cleared the LED output.
                    I've placed a toggle instruction and an osDelay, which blocks the execution for 500ms.
                </p>

                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {task_callback}
                </SyntaxHighlighter>
                <p>
                    If you build the project, some errors will rise. The STM32G4 has a floating point unit,
                    so the RTOS tries to save the float register context, but it can't since the FPU is not enabled by default.
                    <br/>
                    Open the CMakeList.txt and uncomment the following lines to enable the FPU
                </p>

                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {cmakelist_hfloat}
                </SyntaxHighlighter>

                <p>
                    After building and uploading, you should see a blinking LED that was made using an overkill method which used up 8% of RAM and 4% of Flash.
                </p>
                <p>
                    Finally, let's talk about debugging an RTOS project.
                    This could be done the usual way with only breakpoints, but Clion provides RTOS Integration for the Debugger.
                    Go to Settings/Embedded Development/RTOS Integration and enable it.
                    Choose FreeRTOS, since that is the base, not Zephyr.

                    <br/>
                    Now, when you debug the project, two new windows appear: FreeRTOS Objects - this contains the tasks and all their information such as events, priority, state,
                    queues, timers etc.; FreeRTOS Heap - this shows all the blocks in the heap, the allocation, address range etc.

                    Test it out, see what happens when the scheduler is started, when the task is executed and when the task is blocked by the delay function.
                    <br/>
                    The code can be downloaded from <a href = "https://gitlab.com/stm32_mcu_group/stm32_rtos/1_rtos_startup.git"> here</a>.

                </p>
            </div>

            <div className="em__post-navigation">

                <NavLink to="./..">
                    <Button btnID={"leftBTN"} buttonSize="btn--medium"> Micro Projects</Button>
                </NavLink>

                <NavLink to="./../stm-rtos-2">
                    <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
                </NavLink>
            </div>
        </div>
    );
}

export default STM_RTOS1;