import React from "react";
import "./collection.css";
import {Tag} from "../../common";
import {useNavigate} from "react-router-dom";


const TutList1 = [
    {name:"1. DSP environment setup", link: "./dsp-1"},
    {name:"2. Signal loopback in STM32", link: "./dsp-2"},
    {name:"3. Fixed point vs floating point arithmetic", link: "./dsp-3"},
    {name:"4. The discrete Fourier transform", link: "./dsp-4"},
    {name:"5. The Radix-2 Fast Fourier Transform", link: "./dsp-5"},
    {name:"6. Moving average filter", link: "./dsp-6"},
    {name:"7. FIR filter design in Python", link: "./dsp-7"},
    {name:"8. FIR filter implementation", link: "./dsp-8"},
    {name:"9. Exponential moving average filter", link: "./dsp-9"},
    {name:"10. IIR filter design in Python", link: "./dsp-10"},
    {name:"11. IIR filter implementation", link: "./dsp-11"},

    //{name:"12. Half-band FIR filter design", link: "./dsp-12"},
    //{name:"12. Optimal FIR filter design", link: ""}
    //{name:"12. All-pass IIR filter design", link: ""}

    //{name:"12. Attitude estimation - sensor bring-up", link: "./dsp-12"},
    //{name:"13. Complementary filtering", link: "./dsp-13"},
    //{name:"14. Extended Kalman filter for attitude estimation", link: ./dsp-14"},
    //{name:"15. Sensor fusion using Mahony and Madgwick filters", link: ./dsp-15"},

    //{name:"18. Wavelets - continuous and discrete wavelet transform in python", link: ./dsp-18"},
    //{name:"19. Discrete Wavelet Transform implementation", link: ./dsp-19"},
    //{name:"20. Discretized Continuous wavelet transform implementation", link: ./dsp-20"},

    //{name:"21. The Hilbert transform", link: ./dsp-21"},
    //{name:"22. Independent component analysis", link: ./dsp-22"},
    //{name:"23. Principal component analysis", link: ./dsp-23"},
    //{name:"24. The Hilbert-Huang transform", link: ./dsp-24"},npm

]

const TutList2 = [
    {name:"1. Environment setup and requirements", link: "."},
    {name:"2. Audio equalizer", link: "."},
    {name:"3. Auto-wah and phaser", link: "."},
    {name:"4. Vibrato", link: "."},
    {name:"5. Comb filter effects", link: "."},
    {name:"6. Distortion effects", link: "."},
    {name:"7. Convolution-based effects", link: "."},
    {name:"8. Dynamic compressor", link: "."},
    {name:"8. Pitch shift", link: "."},
    {name:"9. Pitch detection", link: "."}

]

function STM_BM(){

    const navigate = useNavigate();

    return(
        <div className="em__collection">
            <div className="em__collection-title">
                <h1 className="gradient__text">Application oriented digital signal processing from the basics to advanced topics</h1>
            </div>

            <div className="em__collection-list">
                {
                    TutList1.map((item) => (
                        <div >
                            <Tag title={item.name} description={item.description} onClick={() => navigate(item.link)}/>
                        </div>
                    ))
                }
            </div>

            <div className="em__collection-title">
                <h1 className="gradient__text">Audio signal processing in Python</h1>
            </div>

            <div className="em__collection-list">
                {
                    TutList2.map((item) => (
                        <div >
                            <Tag title={item.name} description={item.description} onClick={() => navigate(item.link)}/>
                        </div>
                    ))
                }
            </div>

        </div>
    );
}

export default STM_BM;