import React, {useState}  from 'react';
import {NavLink, useLocation} from "react-router-dom";
import {RiMenu3Line, RiCloseLine} from "react-icons/all";
import "./navbar.css";
import "./../../index.css";
import "./../../global.css";
import logo from "./../assets/logo.svg";

const Menu = ({PageName}) => (
    <>
        <p><NavLink to="/">Home</NavLink> </p>
        {PageName === "/" ?
            <p><a href="#whatEM">Who is EM?</a></p> :
            <p><NavLink to="/about">Who is EM?</NavLink> </p>}
        <p><NavLink to="/teaching">Teaching</NavLink> </p>
        <p><NavLink to="/projects">Projects</NavLink> </p>
        <p><NavLink to="/hobby">Hobby</NavLink> </p>
    </>
)


function Navbar(){

    const [toggleMenu, setToggleMenu] = useState(false);
    const currentPath = useLocation().pathname;

    return (
        <div className="em__navbar">
            <div className="em__navbar-links">
                <div className="em__navbar-links_logo">
                    <img src={logo} alt="logo"/>
                </div>
                <div className="em__navbar-links_container">
                    <Menu PageName={currentPath}/>
                </div>
            </div>
            <div className="em__navbar-contact">
                <a href="#footer">Contact me</a>
            </div>
            <div className="em__navbar-menu">
                {toggleMenu
                    ?<RiCloseLine color="white" size={27} onClick={()=> setToggleMenu(false)}/>
                    :<RiMenu3Line color="white" size={27} onClick={()=> setToggleMenu(true)}/>
                }
                {toggleMenu && (
                    <div className="em__navbar-menu_container scale-up-center">
                        <div className="em__navbar-menu_container-links">
                            <Menu PageName={currentPath}/>
                            <div className="em__navbar-menu_container-contact">
                                <a href="#footer">Contact me</a>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>

    );
}

export default Navbar;

