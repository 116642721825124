import React from "react";
import "./dsp_4.css";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";
import {MathJax, MathJaxContext} from "better-react-mathjax";

import logic_explicit_twiddle from "./logic_explicit_twiddle.jpg";
import logic_lut_twiddle from "./logic_lut_twiddle.jpg";
import spectrum_gui from "./spectrum_gui.jpg";
import spectrum_monitor from "./spectrum_monitor.jpg";

function ShowTex({string}){
    const config = {
        loader: { load: ["[tex]/html"]},
        tex: {packages: {"[+]": ["html"]},
            inlineMath: [["$", "$"]],
            displayMath: [["$$", "$$"]]
        }
    };

    return(
        <MathJaxContext config={config} version={3}>
            <MathJax dynamic inline>
                {string}
            </MathJax>
        </MathJaxContext>
    );
}

const dft_header = `
#include "stdint.h"
#define N_TIME 32
#define N_FREQ  N_TIME/2

void setData(float src);
void dft();
void ift();
void magnitude();
void getResult(float *dstV);
`;

const cplx_struct = `
typedef struct{
    float re;
    float im;
}cplx;

cplx input[N_TIME];
cplx output[N_TIME];
float mag_out[N_FREQ];
`;

const set_data = `
void setData(float src){
    for(uint16_t i = N_TIME; i > 0; i--){
        input[i].im = input[i-1].im;
        input[i].re = input[i-1].re;
    }
    input[0].im = 0.0f;
    input[0].re = src;
}
`;

const dft_func = `
void dtf(){
    cplx temp, mul;
    for(uint16_t i = 0; i < N_TIME; ++i){
        cplx sum = {0, 0};
        for(uint16_t j = 0; j < N_TIME; ++j){
            temp = twiddle(i,j);
            mul = cplx_mul(temp, input[j]);
            sum.im += mul.im;
            sum.re += mul.re;
        }
        output[i].re = sum.re;
        output[i].im = sum.im;
    }
}
`;

const magnitude = `
void magnitude(){
    for(uint16_t i = 0; i < N_FREQ; ++i){
        mag_out[i] = (output[i].re * output[i].re) + (output[i].im * output[i].im);
        mag_out[i] = sqrtf(mag_out[i]);
    }
}
`;

const get_result = `
void getResult(float *dstV){
    for(uint16_t i = 0; i < N_FREQ; ++i){
        dstV[i] = mag_out[i];
    }
}
`;

const adc_interrupt = `
void HAL_ADC_ConvCpltCallback(ADC_HandleTypeDef* hadc){
    HAL_GPIO_WritePin(D1_GPIO_Port, D1_Pin, GPIO_PIN_SET);
    adc_value = HAL_ADC_GetValue(hadc);
    is_sample_ready = true;
    HAL_GPIO_WritePin(D1_GPIO_Port, D1_Pin, GPIO_PIN_RESET);
}
`;

const main_loop = `
if(is_sample_ready){
    HAL_GPIO_WritePin(D0_GPIO_Port, D0_Pin, GPIO_PIN_SET);
    float temp = (float)adc_value * 2.0f / 4095.0f - 1.0f;
    setData(temp);
    dft();
    magnitude();
    getResult(result);
    HAL_GPIO_WritePin(D0_GPIO_Port, D0_Pin, GPIO_PIN_RESET);
    is_sample_ready = false;
}
`;

const monitor_function1 = `
var str=msg.payload.variablename;
var part = str.substring(
    str.indexOf("[") + 1, 
    str.indexOf("]"));

var idx=parseInt(part);

global.set("globalArr["+idx+"]", msg.payload.variabledata[0].y);
return msg;
`;

const monitor_function2 = `
var tmpArr = [];
for (var i=0;i<16;i++)
{
tmpArr.push({"x": i, "y": global.get("globalArr")[i]});
}

msg.payload=[{
    "series": ["signal"],
    "data": [tmpArr],
    "labels": [""]
}];
return msg;
`;

function DSP_4(){
   return(
       <div className="em__post">
           <div className="em__post-title">
               <h1>The discrete Fourier transform</h1>
           </div>
           <div className="em__post-section">
               <h3>Overview:</h3>
               <p>
                   In this tutorial, we will start the frequency analysis of discrete time signals.
                   We will implement the Discrete Fourier Transform (DFT) algorithm.
                   The algorithm will be used in conjunction with out previous signal generator and sampler.
                   Measurements will be done to check the execution time, and ideas will be given to increase computational efficiency.
               </p>
           </div>

           <div className="em__post-section">
               <h3>Theory:</h3>
               <p>
                   Fourier analysis is the mathematical process of decomposing a function into oscillatory components.
                   It has many scientific applications in physics, partial differential equations, signal processing, image processing etc.
                   The analysis operator is linear, unitary (<a href="https://en.wikipedia.org/wiki/Pontryagin_duality">Pontryagin duality</a> or
                   <a href="https://en.wikipedia.org/wiki/Plancherel_theorem"> Parseval-Plancherel identity</a>) and it has proper normalisation.
                   The transforms are invertible. The exponential functions are eigenfunctions of differentiation.
                   It transforms the convolution into multiplication and the multiplication into convolution.
                   <br/>
                   There are four variants for the Fourier analysis:
                   <table>
                       <tr>
                           <th> Name of Mapping </th>
                           <th> Source function </th>
                           <th> Resulting function </th>
                           <th> Synthesis</th>
                           <th> Analysis</th>
                       </tr>
                       <tr>
                           <th>Fourier Transform (FT)</th>
                           <th>continuous, nonperiodic</th>
                           <th>continuous, nonperiodic</th>
                           <th><ShowTex string="$$x(t) = \int_{-\infty}^\infty X(\omega) \mathrm{e}^{j \omega t} \mathrm{d}\omega $$"/> </th>
                           <th><ShowTex string="$$X(\omega) = \frac{1}{2\pi} \int_{-\infty}^\infty x(t) \mathrm{e}^{-j \omega t} \mathrm{d}t $$"/> </th>

                       </tr>
                       <tr>
                           <th>Fourier Series (FS)</th>
                           <th>continuous, periodic</th>
                           <th>discrete, nonperiodic</th>
                           <th><ShowTex string="$$x(t) = \sum_{k=-\infty}^\infty X[k] \mathrm{e}^{2\pi j k \frac{t}{T}}$$"/> </th>
                           <th><ShowTex string="$$X[k] = \frac{1}{T}\int_0^T x(t) \mathrm{e}^{-2\pi j k \frac{t}{T}} \mathrm{d}t$$"/> </th>
                       </tr>
                       <tr>
                           <th>Discrete Time Fourier Transform (DTFT)</th>
                           <th>discrete, nonperiodic</th>
                           <th>continuous, periodic</th>
                           <th><ShowTex string="$$x[n] = \frac{1}{2\pi}\int_0^{2\pi} X(\omega) \mathrm{e}^{j\omega n} \mathrm{d} \omega$$"/> </th>
                           <th><ShowTex string="$$X(\omega) = \sum_{n=-\infty}^\infty x[n] \mathrm{e}^{j\omega n} $$"/> </th>
                       </tr>
                       <tr>
                           <th>Discrete Fourier Transform (DFT)</th>
                           <th>discrete, periodic</th>
                           <th>discrete, periodic</th>
                           <th><ShowTex string="$$x[n] =\frac{1}{N} \sum_{k=0}^{N-1} X[k] \mathrm{e}^{2\pi j\frac{kn}{N}}$$"/> </th>
                           <th><ShowTex string="$$X[k] = \sum_{n=0}^{N-1} x[n] \mathrm{e}^{-2\pi j\frac{kn}{N}}$$"/> </th>
                       </tr>

                   </table>
                   Out of all these mapping tools, the first two are of no interest since the source function is a continuous one.
                   After sampling and quantization, we will have a portion of a discrete function, which could be periodic or not.
                   This fact would suggest that we should use the DTFT, but we would have two problems:
                   The result of the DTFT is continuous, and we can't have that on an MCU.
                   The sum spans the whole integer set. If we presume that before operation the value of the measured signal is zero,
                   it would still span the set of natural number, which is infinitely long.
                   <br/>
                   It's better to say that we store a finite number (N) of samples, and we presume that the signal has the same period (N).
                   This way we can use the DFT, the input is discrete, and the output is discrete.
               </p>
               <p>
                   The exponential term <ShowTex string="$$W_{N}^{nk} = \mathrm{e}^{-2\pi j\frac{kn}{N}}$$" /> is called the twiddle factor.
                   It's a complex number that we need to calculate for every time/frequency sample.
                   If we use the Euler convention, <ShowTex string="$$W_{N}^{nk} = \cos(2\pi\frac{kn}{N}) - j \sin(2\pi\frac{kn}{N}). $$"/>
                   Since the MCU compiler does not know complex numbers, we will need to define those.
                   We will need an inline function to calculate the twiddle factor.
                   <br/>
                   Let's look at the complex multiplication. If we have values <ShowTex string="$ z_1, z_2 \in \mathbb{C}$"/> such that
                   <ShowTex string="$ z_1 = a_1 + b_1 j$"/>, and <ShowTex string="$z_2 = a_2 + b_2 j$"/>, then <ShowTex string="$ z = z_1 z_2 $"/> is given as
                   <ShowTex string="$$ z = a + bj = (a_1a_2 - b_1b_2) + (a_1b_2 + a_2b_1)j.$$"/>.
                   With this identity, we can calculate the complex multiplications inside the sum.
                   The result of the DFT is a complex array consisting of N elements.
                   The spectrum can be given by taking the absolute value of the result:
                   <ShowTex string="$$ A[k] = |X[k]| = \sqrt{\Re (X[k])^2 + \Im (X[k])^2}. $$"/>
                   This way we have <b>N</b> magnitude values for the signal sampled at <ShowTex string="$f_s$"/> sampling frequency.
                   This means that each frequency bin <b>k</b> (<ShowTex string="$k = 0, 1, \dots, N-1$"/>) contains the
                   energy of the <ShowTex string="$[k\frac{f_s}{N}, (k-1)\frac{f_s}{N})$"/> frequency components.
                   But according to the Nyquist-Shannon theorem the highest valid frequency component is at <ShowTex string="$f_s/2$"/>,
                   so we only need to save half of the spectrum (<ShowTex string="$k = 0, 1, \dots, \frac{N}{2}-1$"/>).
                   <br/>
                   The IDFT is nearly identical to the DFT.
                   The twiddle factor is the complex conjugate
                   <ShowTex string="$$ z = a + bj \implies \overline{z} = a - bj  ,$$"/>
                   While the result of the sum must be divided by the number of samples.

               </p>
           </div>
           <div className="em__post-section">
               <h3>Firmware:</h3>
               <p>
                   Create a new project in the same fashion as in part 2.
                   This time configure two pins as digital output - we will use them for timing purposes.
                   The main steps of the firmware can be summarized as:
                   <div className="em__post-section__centerList">
                       <ol>
                           <li> Definitions: sample number, complex type</li>
                           <li> Buffer arrays: input (complex), output (complex), magnitude (float)</li>
                           <li> Inline complex arithmetic functions: conjugate, addition, subtraction, multiplication</li>
                           <li> A function for sample storing and normalisation</li>
                           <li> DFT function</li>
                           <li> Magnitude calculation function</li>
                       </ol>
                   </div>
               </p>
               <p>
                   Create a new header and source pair for the DFT.
               </p>
               <div className="em_unselectable">
               <SyntaxHighlighter language="c" style={AtomOneDark}>
                   {dft_header}
               </SyntaxHighlighter>
               </div>
               <p>
                   Here I've defined a 32 sample DFT with the function headers.
               </p>
               <div className="em_unselectable">
               <SyntaxHighlighter language="c" style={AtomOneDark}>
                   {cplx_struct}
               </SyntaxHighlighter>
               </div>
               <p>
                   In the source, we have the complex type, the input, the output and the magnitude arrays.
               </p>
               <div className="em_unselectable">
               <SyntaxHighlighter language="c" style={AtomOneDark}>
                   {set_data}
               </SyntaxHighlighter>
               </div>
               <p>
                   The <i>setData</i> function receives a normalised float, and pushes into the complex input FIFO array.
               </p>
               <div className="em_unselectable">
               <SyntaxHighlighter language="c" style={AtomOneDark}>
                   {dft_func}
               </SyntaxHighlighter>
               </div>
               <p>
                   The <i>dtf</i> function calculates the DTF of the saved input values.
                   Here we have two new functions: <i>twiddle</i>, <i>cplx_mul</i>.
                   Implement the twiddle complex exponential and complex multiplication using the previously shown formulas.
               </p>
               <div className="em_unselectable">
               <SyntaxHighlighter language="c" style={AtomOneDark}>
                   {magnitude}
               </SyntaxHighlighter>
               </div>
               <p>
                   The <i>magnitude</i> function calculates the absolute value of the complex output, but only for half of the samples.
                   Don't forget to use the float variant of the math functions (cosf - float, cos - double).
               </p>
               <div className="em_unselectable">
               <SyntaxHighlighter language="c" style={AtomOneDark}>
                   {get_result}
               </SyntaxHighlighter>
               </div>
               <p>
                   Finally, the <i>getResult</i> function returns the resulting magnitude array.
               </p>
               <div className="em_unselectable">
               <SyntaxHighlighter language="c" style={AtomOneDark}>
                   {adc_interrupt}
               </SyntaxHighlighter>
               </div>
               <p>
                   In the ADC interrupt callback we only want to save the acquired sample.
                   I've placed some GPIO writes so that we can check the execution frequency.
               </p>
               <div className="em_unselectable">
               <SyntaxHighlighter language="c" style={AtomOneDark}>
                   {main_loop}
               </SyntaxHighlighter>
               </div>
               <p>
                   In the main loop, we check for the availability of a new sample.
                   If we have one, we normalise the value to the interval <ShowTex string="$[-1, 1]$"/>.
                   Then we can push our data into the input FIFO, calculate the complex array and the magnitude.
                   Finally, we copy the result to a global float array for further analysis.
                   Here I've also inserted some GPIO writes for timing.
               </p>
           </div>

           <div className="em__post-section">
               <h3>Timing and slight optimisations:</h3>
               <p>
                   Upload the firmware and capture the behaviour of the above mentioned GPIOS with oscilloscope or logic analyser.

               </p>
               <img className="img_logic_explicit_twiddle_dsp-4" src={logic_explicit_twiddle} alt="logic_explicit_twiddle_dsp-4"/>
               <p>
                   Uh, Houston, we've had a problem.
                   The first trace is the interrupt, which was configured to fire with a frequency of 1kHz (or 5kHz in your case).
                   The second trace is the execution time of the DFT.
                   Our algorithm is so complex - pun intended, that it effectively reduces the sampling frequency by 5.
                   <br/>
                   If we look back at the implementation, we calculate the twiddle factors for every time and frequency sample.
                   But we know these values from the start, so we could create an array, pre load the values in them (either from python and store it in FLASH,
                   either inside the firmware before the main loop and store it in RAM). This way we will have a large but quickly accessible look-up table of twiddle factors.
               </p>
               <img className="img_logic_lut_twiddle_dsp-4" src={logic_lut_twiddle} alt="logic_lut_twiddle_dsp-4"/>
               <p>
                   Would you look at that. We reduced the computation time from above 4.2ms to  below 300us.
                   Now we can visualize the data that we sampled.
               </p>
           </div>
           <div className="em__post-section">
               <h3>Data visualisation in the Monitor:</h3>
               <p>
                   Open STM32CubeMonitor, create a new flow with the basic elements from the previous tutorials.
                   This time we want to monitor multiple values in time, so it will be a bit more involved.
               </p>
               <img className="img_spectrum_monitor_dsp-4" src={spectrum_monitor} alt="spectrum_monitor_dsp-4"/>
               <p>
                   Firstly, import the .elf file (1) and select each individual array elements from the result float array.
                   Next we create a custom function (2) block to copy the values from the individual array payloads into a global array:
               </p>
               <div className="em_unselectable">
               <SyntaxHighlighter language="js" style={AtomOneDark}>
                   {monitor_function1}
               </SyntaxHighlighter>
               </div>
               <p>
                   The arrays are ordered in alphanumeric order, which means that if we have more than 9 values, the 9th one will be the last one.
                   Insert a switch node (3) that checks if the last payload is received (<i>payload.variablename == result[9]</i>).
                   Then add another custom function block (4) that will build the dataset required for displaying discrete spectrum using line chart.
               </p>
               <div className="em_unselectable">
               <SyntaxHighlighter language="js" style={AtomOneDark}>
                   {monitor_function2}
               </SyntaxHighlighter>
               </div>
               <p>
                   This way, we have the data array ready to be displayed.
                   Add a chart (5 - this is the default chart component, not from STM).
                   Set the type to Line Chart, display 16 points and interpolate using step method (forward Euler).

                   Deploy and Start the acquisition on the dashboard panel:
               </p>
               <Popup trigger={<img className="img_spectrum_gui_dsp-4 clickable_img" src={spectrum_gui} alt="spectrum_gui_dsp-4"/>} modal nested>
                   {close => (
                       <img className="em__img_full" src={spectrum_gui} alt="spectrum_gui_dsp-4" />
                   )}
               </Popup>
               <p>
                   On this image, you can see the spectrum of an 50Hz sine wave.
                   The frequency bin is <ShowTex string="$\delta_f = f_s/N = 31.25Hz$"/>.
                   The second bin contains components with frequencies ranging from 31.25Hz up to 62.5Hz, so this is a correct behaviour.
                   There are some other artifacts due to sampling and the numerical methods but it's negligible.

               </p>


           </div>

           <div className="em__post-section">
               <h3>Further work:</h3>

               <p>
                   <ol>
                       <li>
                           Change the source code in such a way that it calculates the DFT for N = 64 and 128.
                           What's the memory usage, what's the execution time?
                       </li>
                       <li>
                           Implement the IDFT function and test if it.
                       </li>
                       <li>
                           Check out the values of the twiddle factors.
                           Are all of them unique? Is there some repetition or other pattern?
                       </li>
                       <li>
                           Transcribe the whole DFT source to use 31b fixed-point values in the range <ShowTex string="$[-1, 1)$"/>.
                           Compare the float and fixed DFT execution time for N = 32.
                       </li>

                   </ol>
               </p>
           </div>


           <div className="em__post-navigation">

               <NavLink to="./../dsp-3">
                   <Button btnID={"leftBTN"} buttonSize="btn--medium"> Prev Post</Button>
               </NavLink>

               <NavLink to="./../dsp-5">
                   <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
               </NavLink>
           </div>
       </div>
   );
}

export default DSP_4;