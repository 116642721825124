import React from "react";
import "./../hobby.css";
import "./../collection.css";
import {Tag} from "../../../common";
import {useNavigate} from "react-router-dom";
import {useRef} from "react";

function BLACKHUB(){
    const navigate = useNavigate();

    const asamiyaBlackRef = useRef(null);
    const goldenBudRef = useRef(null);
    const longanLapsangRef = useRef(null);
    const ffdRef = useRef(null);
    const qimenBlackRef = useRef(null);
    const poppyRollRef = useRef(null);
    const rubyBlackRef = useRef(null);
    const souchongRef = useRef(null);
    const blackGoldRef = useRef(null);
    const wuLiangRef = useRef(null);
    const yingdeHongRef = useRef(null);
    const jinsiDianHongRef = useRef(null);
    const hmralbYSRef = useRef(null);

    const tealist = [
        {name:"1. Asamiya black (ML)", ref: asamiyaBlackRef},
        {name:"2. Golden Bud (ML)", ref: goldenBudRef},
        {name:"3. Longan Lapsang (ML)", ref: longanLapsangRef},
        {name:"4. First flush Darjeeling (NNS)", ref: ffdRef},
        {name:"5. Premium qimen black tea (YS)", ref: qimenBlackRef},
        {name:"6. Poppy roll black (ML) ", ref: poppyRollRef},
        {name:"7. Yingde Hong Cha (NNS)", ref: yingdeHongRef},
        {name:"8. Ruby black (ML)", ref: rubyBlackRef},
        {name:"9. Jinsi Dian Hong (NNS)", ref: jinsiDianHongRef},
        {name:"10. Souchang liquor (ML)", ref: souchongRef},
        {name:"11. Black gold Bi Luo Chun (YS)", ref: blackGoldRef},
        {name:"12. Yi mei ren Wu Liang black (YS)", ref: wuLiangRef},
        {name:"13. High mountain red Ai Lao black (YS)", ref: hmralbYSRef},
    ];

    return (
        <div className="em__post">
            <div className="em__post-section">
                <h3>About the history and production of black teas</h3>
                <p>
                    The story of black tea unfolds in the misty hills of China's Yunnan province and the sprawling tea estates of India.
                    While China was the birthplace of tea, it was in India, particularly in Assam and Darjeeling,
                    that the techniques for producing black tea were refined during the British colonial era.
                    Western people call this tea black because of the colour of the leaves, while in Asia, most of the time, it's referred to as red tea, from the colour of the tea soup.
                </p>
                <p>
                    The process involves picking, withering, rolling, oxidation, drying and sorting.
                </p>
                <ul>
                    <li>
                        <b>Plucking:</b>
                        The journey of black tea begins in the tea gardens, where Camellia Sinensis (Assamica, Yun Kang, Assamica Kuntze) plants are meticulously tended.
                        The timing of harvest, known as "flush," is critical.
                        The young leaves and buds, often comprising the top two leaves and a bud, are plucked by hand or machine, depending on the estate and tea type.
                    </li>
                    <li>
                        <b>Withering:</b>
                        Once harvested, the leaves are spread out to wither, allowing them to lose moisture and become supple.
                        This crucial step prepares the leaves for the subsequent stages of processing.
                    </li>
                    <li>
                        <b>Rolling:</b>
                        The withered leaves undergo rolling, breaking down the cell walls and initiating oxidation (enzymatic browning is a more correct term).
                        In black tea production, oxidation is a key factor distinguishing it from other types.
                    </li>
                    <li>
                        <b>Oxidation:</b>
                        Unlike green tea, where oxidation is minimal, black tea leaves can oxidise fully.
                        This transformative process, also called enzymatic browning, gives black tea its characteristic dark colour, robust flavour, and malty or fruity notes.
                    </li>
                    <li>
                        <b>Drying:</b> To halt the oxidation at the desired level, the leaves are subjected to heat through firing, drying, or even smoking.
                        This final step ensures that the unique flavours and aromas developed during oxidation are preserved.
                    </li>
                </ul>
                <p>
                    After drying, the tea is sorted based on leaf size and quality. The larger, whole leaves often end up in higher-grade teas,
                    while broken leaves or fannings are used in lower-grade blends. This grading process impacts the tea's overall appearance and brewing characteristics.
                    The world of black tea is diverse, featuring renowned varieties such as Assam, Darjeeling, Ceylon, Keemun, and Earl Grey.
                    Each type reflects the distinct terroir of its origin and the unique characteristics of its processing method.
                    Here are a few well-known types:
                </p>
                <ol>
                    <li>
                        <b>Golden Gleeming Eyebrow (Jin Jun Mei):</b>
                        Made from the buds of the tea plant (the higher the bud ration - the higher the price), showcasing a golden appearance.
                        The tightly curled, downy buds unfold during brewing, releasing a bold and complex flavour profile.
                        Expect honey, chocolate, and malt notes accompanied by a lingering sweetness.
                        This rare and sought-after tea is a testament to the meticulous skill involved in its production.
                    </li>
                    <li>
                        <b>Lapsang Souchong (Zheng Shan Xiao Zhong):</b>
                        A distinctive black tea originating from the Wuyi Mountains in China's Fujian province.
                        Lapsang Souchong's unique processing method sets it apart, where the leaves are smoke-dried over pinewood (or Longan) fires.
                        This imparts a robust smokiness to the tea, evoking images of pine forests.
                        Beyond the bold, smoky flavour, Zheng Shan Xiao Zhong offers a malty sweetness and hints of fruitiness, creating a truly memorable and aromatic tea experience.
                    </li>
                    <li>
                        <b>Sayama Kaori:</b>
                        Sayama Kaori is a black tea from the Sayama region in Saitama, Japan.
                        Unlike traditional Japanese green teas, Sayama Kaori undergoes a unique processing method that is more akin to black tea.
                        The leaves are withered, rolled, oxidised, and dried, resulting in a dark amber liquor with a distinctly Japanese twist.
                        The flavour profile is well-balanced, offering a harmonious combination of malty sweetness and floral notes.
                        Sayama Kaori represents a fascinating fusion of Japanese tea craftsmanship with the boldness of black tea.
                    </li>
                    <li>
                        <b>Ruby 18:</b>
                        Ruby 18, originating from Taiwan, is a cultivar of the Assam tea plant and is celebrated for its vibrant red infusion and rich, full-bodied flavour.
                        "Ruby 18" refers to the tea plant's scientific classification.
                        This tea undergoes a careful oxidation process, producing a coppery red liquor with brilliant clarity.
                        Ruby 18 is known for its sweet, honey-like notes, accompanied by a pleasant maltiness and a subtle hint of mint.
                        This unique combination makes Ruby 18 a standout black tea, representing the innovation and excellence of Taiwanese tea cultivation.
                    </li>
                </ol>
                <p>
                    When the tea dries out, it's ready for consumption.
                    Since black tea is an (almost)fully oxidised tea, it can be stored for a long time without losing its aroma or flavour.
                </p>

            </div>

            <div className="em__collection">
                <div className="em__collection-title">
                    <h1 className="gradient__text">Sampled white teas:</h1>
                </div>

                <div className="em__collection-list">
                    {
                        tealist.map((item) => (
                            <div >
                                <Tag title={item.name} description={item.description} onClick={() => item.ref.current.scrollIntoView({ behavior: 'smooth' })}/>
                            </div>
                        ))
                    }
                </div>

                <div className="em__post-section">
                    <div id="1. Asamiya black (ML)" ref={asamiyaBlackRef}>
                        <h3>1. Asamiya black (ML)</h3>
                        <p>
                            This is a Japanese black tea (wakoucha).
                            This tea was made in Shiga prefecture - one of Japan's oldest and original tea-growing areas.
                            The altitude is relatively high, providing excellent conditions for flavourful tea.
                            The tea was aged for a year to soften and mellow the Assam-like flavour.
                            <br/>
                            The tea smelled like brown sugar, coriander and green apples.
                            The liquor was medium thick and clear, with a maple syrup-like colour.
                            The tea tasted like lemon zest, cacao, and blackberry. It was slightly tart and slightly sweet.
                            I brewed 5g of tea in a 100ml gaiwan with 95C water.
                            A session usually lasted for five brews.
                            <br/>
                            Green/Vegtal: 2/10, Floral: 3/10, Fruits: 4/10, Spices: 5/10, Wood/Earth: 2/10, Roast/Nuts: 1/10, Cream: 3/10, Mineral: 4/10.
                        </p>
                    </div>
                    <div id="2. Golden Bud (ML)" ref={goldenBudRef}>
                        <h3>2. Golden Bud (ML)</h3>
                        <p>
                            Dianhong is a type of black tea made predominantly with the buds of the Assamica tea varieties in Yunnan province.
                            They produce light, malty, smooth black teas with warm and sweet aromas.
                            The tea was harvested in March from Fengqing, Yunnan, China.
                            <br/>
                            The tea had predominantly golden and copper buds, a few young leaves, and a fine sheen of hairs.
                            The dry leaves smelled like cacao and hay.
                            The wet leaves smelled like rye bread, blackcurrant.
                            The tea soup was dark amber, thick and clean.
                            It tasted like intense dark chocolate, malt and molasses.
                            It was lighter than most black teas.
                            It had a soft, slightly minty aftertaste.
                            <br/>
                            I brewed 5g of tea in a 100ml gaiwan with 95C water.
                            A session usually lasted for seven brews.
                            <br/>
                            Green/Vegtal: 0/10, Floral: 6/10, Fruits: 2/10, Spices: 6/10, Wood/Earth: 3/10, Roast/Nuts: 5/10, Cream: 4/10, Mineral: 3/10.
                        </p>
                    </div>
                    <div id="3. Longan Lapsang (ML)" ref={longanLapsangRef}>
                        <h3>3. Longan Lapsang (ML)</h3>
                        <p>
                            This is a Zhengshan Xiaozhong black tea harvested in May from Tong Mu, Fujian, China.
                            The Longan wood smoke adds a sweet and fruity note to the tea, and the smoke is warming,
                            comforting and enticing rather than harsh, acrid and overpowering.

                            <br/>
                            The leaves smell like milk chocolate, allspice, camphor and a wood campfire.
                            The tea soup was rich, medium thick, and dark amber.
                            It tasted like dark toffee, smoked apple and baked papaya with a gripping spicy aftertaste.
                            I brewed 5g of tea in a 100ml gaiwan with 95C water.
                            A session usually lasts for 7-8 brews.
                            <br/>
                            Green/Vegtal: 0/10, Floral: 1/10, Fruits: 8/10, Spices: 9/10, Wood/Earth: 4/10, Roast/Nuts: 6/10, Cream: 3/10, Mineral: 2/10.
                        </p>
                    </div>
                    <div id="4. First flush Darjeeling (NNS)" ref={ffdRef}>
                        <h3>4. First flush Darjeeling (NNS)</h3>
                        <p>
                            Darjeeling is known as the Champagne of the tea world. Both in taste and methodology.
                            You can have a tea made similarly, but if it's not produced in Darjeeling, you can't name it as such.
                            The tea was the first flush harvest of grade (Special Finest Tippy Golden Flowery Orange Pekoe, Highest Quality - SFTGFOP1)
                            from Risheehat Tea Garden, Darjeeling, West Bengal, India.
                            This tea has many flushes: First-Flush tea is plucked from February to April when the tea bush mostly has buds that are full of essential oils.
                            This flush is the least oxidised, light and floral.
                            The Second or Summer Flush is plucked from May to June. This flush undergoes a more intense rolling and oxidation process with extended firing periods.
                            There is a Monsoon (from July to October) Flush and an Autumn (from October to November) Flush.
                            The former yields a somewhat less complex, bolder flavoured tea, which is ideal for iced tea,
                            while the latter is more nutty, rich, and smooth.
                            <br/>
                            The tea had a brilliant mix of avocado, kelp and orange colour whole and broken leaves with silver buds,
                            which turned into forest camo and orange tinge colours when steeped.
                            The dry leaves smelled like rose hip, vanilla, molasses, honey and cocoa.
                            After a quick rinse, this was changed into nutmeg, cocoa, and yarrow.
                            The tea soup was pale gold, medium thick, and smooth.
                            It tasted like orange zest, magnolia, turmeric, green pepper, elder flowers and apricot.
                            This was one of the few teas in which each and every taste/smell profile had a strong presence.
                            The empty cup smelled like dark sugar syrup, and the aftertaste was sweet and citrus-like.
                            I brewed 3g of tea in a 100ml gaiwan at 90C.
                            A session usually lasted for four brews, on the lower side of my usual black tea brews, but it was worth it.
                            <br/>
                            Green/Vegtal: 1/10, Floral: 9/10, Fruits: 9/10, Spices: 9/10, Wood/Earth: 1/10, Roast/Nuts: 1/10, Cream: 3/10, Mineral: 2/10.
                        </p>
                    </div>
                    <div id="5. Premium qimen black tea (YS)" ref={qimenBlackRef}>
                        <h3>5. Premium qimen black tea (YS)</h3>
                        <p>
                            Qimen (Keemun) black tea is harvested in Huangshan, Anhui, China.
                            Qimen is one of China's most famous black tea varieties, derived from a Huangshan Mao Feng varietal tea.

                            <br/>
                            The tea consists of delicate black leaves and copper buds.
                            The dry leaves smelled like cocoa and malty bread.
                            The wet leaves had the smell of brown sugar and meadow flowers.
                            The tea soup was golden and had a light thickness.
                            It had a very smooth texture and tasted like cocoa, black cardamom, and toasted rye bread with toned-down notes of chrysanthemum.
                            The sweet and floral notes were present even in the aftertaste.
                            I brewed 4g of tea in a 100ml gaiwan with 90C water.
                            A session usually lasted for seven brews.
                            <br/>
                            Green/Vegtal: 0/10, Floral: 5/10, Fruits: 2/10, Spices: 8/10, Wood/Earth: 1/10, Roast/Nuts: 1/10, Cream: 1/10, Mineral: 5/10.
                        </p>
                    </div>
                    <div id="6. Poppy roll black (ML) " ref={poppyRollRef}>
                        <h3>6. Poppy roll black (ML) </h3>
                        <p>
                            This is a delightful black tea harvested in April from Tong Mu Xing Cun, Fujian, China.
                            It is a more modern take on the Lapsang Souchong tea, with no smoking involved.
                            This practice was started in response to the Jin Jun Mei trend, resulting in a lovely, clear-tasting black tea.
                            <br/>
                            The leaves are young, dark purple ones, which turn into chestnut brown after brewing.
                            The tea leaves smell of poppy seed, apricot, cocoa and malt loaf.
                            The tea soup is medium thick with a gold caramel colour.
                            One of the lightest, brightest black teas.
                            It has a strong mineral and sweet taste; the poppy seeds are present as malt, raw cashew, cocoa nib, and spring flowers.
                            The aftertaste is slightly sweet and chocolatey, with a cooling sensation in the mouth.
                            <br/>
                            I brewed 5g of tea in a 100ml gaiwan with 95C water.
                            A session usually lasted for eight brews.
                            <br/>
                            Green/Vegtal: 1/10, Floral: 7/10, Fruits: 6/10, Spices: 4/10, Wood/Earth: 2/10, Roast/Nuts: 6/10, Cream: 2/10, Mineral: 7/10.
                        </p>
                    </div>
                    <div id="7. Yingde Hong Cha (NNS)" ref={yingdeHongRef}>
                        <h3>7. Yingde Hong Cha (NNS)</h3>
                        <p>
                            Originating from a small farm in Guangdong, it uses a cultivar developed in Yingde's
                            tea research institute during the 1950s with large-leafed assamica plants from Yunnan.
                            The cultivar is called Ying Hong Jiu Hao No. 9.
                            <br/>
                            The tea consists primarily of gold and pecan twisted young leaves.
                            The leaves smelled of malt biscuits, which, when rehydrated, had dark chocolate, molasses, and medicinal smell.
                            The tea soup was soft and coating with dark resin colour.
                            The tea tasted light and fresh with notes of cherry and apricot with sweet and bitter background notes.

                            I brewed 3g of tea in a 100ml gaiwan with 90C water.
                            A session usually lasted for six brews.
                            <br/>
                            Green/Vegtal: 1/10, Floral: 5/10, Fruits: 6/10, Spices: 3/10, Wood/Earth: 1/10, Roast/Nuts: 2/10, Cream: 1/10, Mineral: 3/10.
                        </p>
                    </div>
                    <div id="8. Ruby black (ML)" ref={rubyBlackRef}>
                        <h3>8. Ruby black (ML)</h3>
                        <p>
                            This unique tea is from the Sun Moon Lake in Taiwan and is among the most unique Black teas.
                            It is made from a special tea cultivar called Ruby 18, which was developed by the Taiwanese Tea Research people (TTRES).
                            They started in 1962 but only completed in 1999 by cross-breeding the Taiwanese Chin Xin leaf with the Assam leaf.
                            <br/>
                            The tea leaves are large and black with ruby undertone, which smell like lilacs, cocoa and eucalyptus
                            that transform into camphor, wintergreen and cinnamon smell after a quick rinse.
                            The tea soup is light, refreshing and smooth with an oxidised sherry colour.
                            The tea tasted of jasmine lilac with a hint of caramel and rum.
                            The tea has a tomato puree smell/taste in the background.
                            Strong and unique tea. Easily one of my favourite black teas.
                            I brewed 5g of tea in a 100ml gaiwan with 90C water.
                            A session usually lasted for seven brews.
                            <br/>
                            Green/Vegtal: 3/10, Floral: 8/10, Fruits: 7/10, Spices: 9/10, Wood/Earth: 5/10, Roast/Nuts: 4/10, Cream: 2/10, Mineral: 6/10.
                        </p>
                    </div>
                    <div id="9. Jinsi Dian Hong (NNS)" ref={jinsiDianHongRef}>
                        <h3>9. Jinsi Dian Hong (NNS)</h3>
                        <p>
                            This is a Dian Hong tea from Pu'er, Yunnan, China.
                            The cultivar is Yuncang No. 10.
                            <br/>
                            The tea mainly comprises coiled golden buds with black stripes that turn rusty brown after brewing.
                            The dry leaves smelled like burnt toast and cacao.
                            The wet leaves smelled like roast chicory roots, full of sweet and bitter notes.
                            The tea soup was light and smooth with a deep marigold colour.
                            The tea tastes like dark chocolate and malt biscuits with a hint of bitter taste and sourness.
                            There is no astringency present.
                            The aftertaste is juicy and ever so slightly sweet.
                            I brewed 4g of tea in a 100ml gaiwan with 90C water.
                            A session usually lasted for five brews.
                            <br/>
                            Green/Vegtal: 0/10, Floral: 4/10, Fruits: 3/10, Spices: 5/10, Wood/Earth: 2/10, Roast/Nuts: 8/10, Cream: 4/10, Mineral: 3/10.
                        </p>
                    </div>
                    <div id="10. Souchang liquor (ML)" ref={souchongRef}>
                        <h3>10. Souchang liquor (ML)</h3>
                        <p>
                            This is a clean black tea from Zhengshan, Fujian, China, without smoking.
                            <br/>
                            The tea leaves smelled like plums, cocoa and apple skins.
                            The tea soup was medium thick with an orange caramel colour.
                            The tea tasted like charred vegetables and dark chocolate.
                            There is a hint of apple wood and the sourness of wild cherries.
                            The aftertaste is long, juicy, and minty sweetness.

                            I brewed 4g of tea in a 100ml gaiwan with 90C water.
                            A session usually lasted for seven brews.
                            <br/>
                            Green/Vegtal: 0/10, Floral: 2/10, Fruits: 6/10, Spices: 5/10, Wood/Earth: 4/10, Roast/Nuts: 8/10, Cream: 4/10, Mineral: 3/10.
                        </p>
                    </div>
                    <div id="11. Black gold Bi Luo Chun (YS)" ref={blackGoldRef}>
                        <h3>11. Black gold Bi Luo Chun (YS)</h3>
                        <p>
                            A classic Yunnan black tea is grown in Ning'er, Simao, China.
                            This has been picked as one leaf to 1 bud set and processed carefully by rolling the tea into pellets.
                            Like most Yunnan black teas, the tea benefits from short-term ageing and develops a malty, sweet taste and lovely fragrance with a hint of chocolate and flowers.
                            <br/>
                            The tea contains golden hairy buds and small chocolate leaves.
                            The leaves smelled like cocoa, cherries and malted biscuits.
                            The tea soup was golden and medium thick.
                            The tea was mellow and smooth with wheat malt, dark chocolate, and dried cherry notes.
                            The aftertaste is sweet with a long-lasting cocoa taste.
                            I brewed 4g of tea in a 100ml gaiwan with 95C water.
                            A session usually lasted for 8+ brews; the leaves left could be used for an additional boiled brew.
                            <br/>
                            Green/Vegtal: 0/10, Floral: 4/10, Fruits: 7/10, Spices: 8/10, Wood/Earth: 5/10, Roast/Nuts: 4/10, Cream: 2/10, Mineral: 4/10.
                        </p>
                    </div>
                    <div id="12. Yi mei ren Wu Liang black (YS)" ref={wuLiangRef}>
                        <h3>12. Yi mei ren Wu Liang black (YS)</h3>
                        <p>
                            This tea is named "Yi Mei Ren" as it is made from Wu Liang Mountain material,
                            an area inhabited primarily by Yi Minority people, which bears similarities to oolong and black tea's fragrance and taste.
                            Yunnan large-leaf varietal material is used, and the tea is wilted and fermented like black tea, but for a more extended period with several intervals of vigorously shaking the leaves.
                            This promotes more thorough wilting/fermentation and leads to its darker colour.
                            <br/>
                            The tea consists of long, twisted mulberry-coloured leaves with grey and orange stripes that smell like oranges and tomato purée.
                            The wet leaves have an amber colour with seaweed green spots and smell like pine bark, black cherries and sugar cane.
                            The tea soup is medium-light and easy to drink with an orange colour and butterscotch hue.
                            The tea has a cocoa taste with tones of anise, dark cherries and wild berries, and high minerality.
                            The aftertaste is sweet and camphor-like.
                            <br/>
                            I brewed 4g of tea in a 100ml gaiwan with 95C water.
                            A session usually lasted for nine brews.
                            <br/>
                            Green/Vegtal: 0/10, Floral: 4/10, Fruits: 9/10, Spices: 2/10, Wood/Earth: 2/10, Roast/Nuts: 0/10, Cream: 0/10, Mineral: 6/10.
                        </p>
                    </div>
                    <div id="13. High mountain red Ai Lao black (YS)" ref={hmralbYSRef}>
                        <h3>13. High mountain red Ai Lao black (YS)</h3>
                        <p>
                            High mountain tea is grown at 2000 meters on Ai Lao Shan in the Zhenyuan area of Simao.
                            Picked and processed only from the first flush of spring, this black tea is lightly oxidized and processed similarly to Taiwanese black tea or Wu Yi Rock tea.
                            There is still a greenish tinge left on the leaves. The brewed tea is rich and thick with hints of dried Longan fruit and a protracted mouth feeling.
                            <br/>
                            The dry leaves had chestnut and cocoa colour with wild cherry and pine smell.
                            After rehydration, the leaves turned chocolate and rust colour and smelled like plum, sour cherry, and coffee.
                            The liquor had a resin gold colour, and it had a light and bright texture.
                            The tea brew tasted like cherry drops, oregano, thyme and cocoa butter.
                            The aftertaste was minty, cooling and slightly rum-like.
                            <br/>
                            I brewed 4g of tea in a 100ml gaiwan with 95C water.
                            A session usually lasted for seven brews.
                            <br/>
                            Green/Vegtal: 0/10, Floral: 3/10, Fruits: 5/10, Spices: 7/10, Wood/Earth: 0/10, Roast/Nuts: 0/10, Cream: 0/10, Mineral: 2/10.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BLACKHUB;
