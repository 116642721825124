import React from "react";
import "./mcu_hal2.css";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";

import gpio from "./gpio.jpg";
import mx1 from "./mx1.jpg"
import mx2 from "./mx2.jpg"
import mx3 from "./mx3.jpg"
import mx4 from "./mx4.jpg"
import mx5 from "./mx5.jpg"

const variable = `
uint16_t i = 0;
`;

const blinky1 = `
if(HAL_GPIO_ReadPin(BT1_GPIO_Port, BT1_Pin) == GPIO_PIN_SET){
    i += 100;
    if(i == 1100)
        i = 100;
}
HAL_GPIO_TogglePin(LD2_GPIO_Port, LD2_Pin);
HAL_Delay(i);   
`;


function MCU_HAL2(){
   return(
       <div className="em__post">
           <div className="em__post-title">
               <h1>General Purpose Inputs and Outputs (GPIO)</h1>
           </div>
           <div className="em__post-section">
               <h3>Aim of this tutorial:</h3>
               <p>
                   In this tutorial we will take a brief look at the internal structure of the GPIOs.
                   We will configure a single pin as digital output and flash an LED.
                   We will use a momentary switch (button) and a digital input to change the flashing frequency.
                   Finally we will optimize the input reading using an external interrupt.
               </p>

           </div>

           <div className="em__post-section">
               <h3>The GPIO structure</h3>
               <Popup trigger={<img className="img_gpio clickable_img" src={gpio} alt="gpio"/>} modal nested>
                   {close => (
                       <img className="em__img_full" src={gpio} alt="gpio" />
                   )}
               </Popup>
               <p>Let's study the GPIO structure of this STM32 MCU.
                   Every pin has a protection/clamping diode, where the voltage limit could be the <i>VddIO</i> for basic
                   pins, or a potential specific to five volt toleran I/Os in case of five volt tolerant pins.
                   These diodes can also be used to check if the MCU is properly soldered to the board if the pins are not accessible.
                   Every GPIO has programmable pull-up and pull-down resistors, but those can not be activated simultaneously.
                   We can see that the pin is connected to an optional Schmitt trigger and then to the input data register.
                   This Schmitt trigger is used to reduce digital noise, so it's logical that the analog connection does not use it.
               </p>
               <p>
                   The output part is a bit complicated.
                   The analog output is directly connected to the pin, while there is a specialised driver circuit to drive the pins in digital mode.
                   We can configure the digital output as push-pull if we use the same supply voltage as the MCU, or open-drain if there is voltage difference.
               </p>

           </div>

           <div className="em__post-section">
               <h3>Peripheral setup:</h3>
               <p>
                   Create a new project, or open up the one from the previous tutorial.
                   Open the <i>.ioc</i> file, go to <i>Pinout & Configuration</i>.
               </p>
           </div>

           <div className="em__post-section">
               <Popup trigger={<img className="img_mx1 clickable_img" src={mx1} alt="mx1"/>} modal nested>
                   {close => (
                       <img className="em__img_full" src={mx1} alt="mx1" />
                   )}
               </Popup>
               <p>Open up Clion and create a new project from <i>File/New/Project...</i>, then chose the STM32Cube project with a preferred path.</p>
               <p>
                   This part of the GUI helps us to configure the periphery and middlewares.
                   The left column contains the properties, in this tutorial we will only use the <i>System Core</i>.
                   The right panel shows the MCU pinout.
               </p>
               <p>
                   The cream color shows the power pins, the light green is the positive reference voltage for the ADC.
                   Paper Pins show that those pins are locked by the user (in our case, this was done by ST with the default peripheral setup).
                   Yellow means that the locked pin hosts disabled periphery.
                   Here, we won't use the external oscillators, or the LPUART.
                   Green means that the pins are locked to an enabled periphery.
               </p>
               <Popup trigger={<img className="img_mx2 clickable_img" src={mx2} alt="mx2"/>} modal nested>
                   {close => (
                       <img className="em__img_full" src={mx2} alt="mx2" />
                   )}
               </Popup>
               <p>
                   Go to <i>System Core/SYS</i>. Here we want to configure the debug option for the MCU.
                   There are two major debug protocols: SWD and JTAG.
                   The former uses two data pins for debug and programming, the latter uses at least four data pins for
                   programming, debugging and boundary scan.
               </p>
               <p>
                   There is an extra Serial Wire Output (SWO) pin for instrumentation trace.
                   This can be configured to stream both hardware and software events, live variable and registry checkup.
                   TO enable SWO with SWD, select <i>Trace Asynchronous SW</i> as the debug option.
               </p>
               <p>
                   Furthermore, if you are bothered by the unused yellow pins, just left-click on them and select the reset state.
                   This removes the lock.
               </p>
           </div>

           <div className="em__post-section">
               <img className="img_mx4" src={mx4} alt="mx4"/>
               <p>
                   The board has two configured pins.
                   One input for the button and one output for the LED.
                   Let's rename them to our liking.
                   Go to <i>System Core/GPIO</i>, where under the GPIO tab we can find the PA5 and PC13 pins.
               </p>
               <Popup trigger={<img className="img_mx3 clickable_img" src={mx3} alt="mx3"/>} modal nested>
                   {close => (
                       <img className="em__img_full" src={mx3} alt="mx3" />
                   )}
               </Popup>
               <p>
                   Here we can set the properties for the inputs and outputs.
                   We can give the initial state of the output (low), the mode (push-pull).
                   We could set pull-up or pull-down resistors, maximum speed (low in this case), and a user label.
                   Change this label to <i>LD2</i>.
               </p>
               <p>
                   An input pin has only two options, the resistors and the user label.
               </p>
               <p>
                   In our case, PC13 is set as an external interrupt pin, which is a fancier version of the input.
                   As the name suggest, this pin can interrupt the program if an event occurs on the pin.
                   The event (mode) can be a rising edge, a falling edge or both.
                   Similarly to the input, we can use pull-up/-down resistors and we can give a label.
                   Let's name it <i>BT1</i>.
               </p>
               <p>
                   Lastly, an external interrupt pin is just an input pin if we do not enable the interrupt itself.
                   This is already done for us, but when we want to enable GPIO interrupt we can do it from the NVIC tab
                   in <i>System Core/GPIO</i> or from <i>System Core/NVIC</i>.
                   Here, since we are using EXTI 13, we need to use the EXTI line [15:10],
                   which enables the external interrupts from 10 to 15.
               </p>
               <Popup trigger={<img className="img_mx5 clickable_img" src={mx5} alt="mx5"/>} modal nested>
                   {close => (
                       <img className="em__img_full" src={mx5} alt="mx5" />
                   )}
               </Popup>
           </div>

           <div className="em__post-section">
               <h3>Program and debug</h3>
                <p>After the code generation and the creation of the .cfg file, we can finally test the GPIOs.</p>
               <p>Between the <i>USER CODE BEGIN 1</i> and <i>USER CODE END 1</i> create a variable</p>
               <SyntaxHighlighter language="c" style={AtomOneDark}>
                   {variable}
               </SyntaxHighlighter>
               <p>
                   This will be the delay in milliseconds.
               </p>
               <p>
                   Next, in the main infinite loop write the following code:
               </p>
               <SyntaxHighlighter language="c" style={AtomOneDark}>
                   {blinky1}
               </SyntaxHighlighter>
               <p>
                   <i>HAL_GPIO_ReadPin</i> is the read function for the input.
                   The first argument is the port, the second argument is the pin on the port.
                   We can only read a single port with this instruction.
                   We could use the <i>HAL_GPIO_WritePin</i> to write to a digital output,
                   by giving the previous two settings and the state of the pin <i>GPIO_PIN_SET</i> or <i>GPIO_PIN_RESET</i>.
                   We could also toggle the output, which essentially reads the state and negates it with the help of the function
                   <i>HAL_GPIO_TogglePin</i>.
               </p>
               <p>
                   The <i>HAL_Delay</i> halts the mcu for the time specified by the user (in milliseconds).
                   In this case, we are using an internal oscillator, but we will talk about that in the next tutorial.
                   For now, build and upload the project.
                   The LED should flash, and the flashing should slow down with every push of the button.
                   After the tenth push everything is set back to the original timing.
               </p>
               <p>
                   Finally, let's talk about external interrupts.
                   Those are separate code regions, which are executed only when an event happens.
                   In our case the push of a button, since the interrupt was set to rising edge trigger.
                   We need to create a specific function handle to catch this interrupt.
               </p>
               <p>
                   Go to <i>Core/Inc/stm32g4xx_it.h</i>. This header contains all of the interrupt related function handles.
                   There is an <i>EXTI15_10_IRQHandler</i>, so push Ctrl, and click on it, to go to the source.
                   This open up the fucntion in <i>Core/Src/stm32g4xx_it.c</i>. Another function named <i>HAL_GPIO_EXTI_IRQHandler</i> is
                   called, navigate to the implementation with Ctr+Click.
                   This open up <i>stm32g4xx_hal_gpio.c</i> in which the function calls the final function <i>__weak void HAL_GPIO_EXTI_Callback(uint16_t GPIO_Pin)</i>.
               </p>
               <p>
                   This multi function calling is one of the downsides of using a HAL, but hey, at least now we know which function is executed.
                   The <i>__weak</i> descriptor means that if we define the function somewhere else, the linker will take that function and replace the weak one.
               </p>

               <p>
                   So, first we need to make the <i>i</i> global and volatile between <i>USER CODE BEGIN PV</i> and <i>USER CODE END PV</i>.
                   Next, between <i>USER CODE BEGIN 0</i> and <i>USER CODE END 0</i> place the interrupt callback <span className="em__highlight">without the <i>__weak</i> directive</span>.
                   This interrupt routine will check if the button is pressed, if so then increment <i>i</i> by 100, if <i>i</i> is greater than 1100 set it to 100.
                </p>
               <p>
                   The main loop will only have the toggle function and the delay with variable <i>i</i>.
                   Build and upload the code, which should work the same way as before.
                   You can also debug the program by placing breakpoints and checking the debug option in the top right strip.
               </p>
               <p><b>Remarks:</b> </p>
               <ol>
                   <li>
                       The volatile command tels the compiler that the variable can change at any time without any task given by the source code.
                       Indeed, if the compiler checks the main loop first it sees a variable with value 100, which never changes in the code, so it could optimize it out by
                       using a constant in the flash memory.
                       Similarly, if the interrupt context is evaluated first, the <i>i</i> is incremented, but the value is never used in this context, so it could be removed.
                       With volatile, the compiler won't optimize out this variable.
                   </li>
                   <li>
                       We could write the interrupt routine without checking for button state in this example, since we only have one interrupt source.
                       If we would be using more than one EXTI lines from 10:15, then it would be mandatory to check which line was the cause of the interrupt,
                       since every line has the same callback function.
                   </li>
                   <p>
                       The project code can be found <a href="https://gitlab.com/stm32_mcu_group/stm32_hal/2_gpio">here</a>.
                   </p>
               </ol>
           </div>


           <div className="em__post-navigation">

               <NavLink to="./../stm-hal-1">
                   <Button btnID={"leftBTN"} buttonSize="btn--medium"> Previous Post</Button>
               </NavLink>

               <NavLink to="./../stm-hal-3">
                   <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
               </NavLink>
           </div>

       </div>
   );
}

export default MCU_HAL2;