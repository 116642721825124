import React from "react";
import "./hobby.css";
import "./collection.css";
import {Tag} from "../../common";
import {useNavigate} from "react-router-dom";

const hobby_list = [
    {name:"Journey into true tea", link: "./tea-hub"},
];

function Hobby(){
    const navigate = useNavigate();
    return (
        <div className="em__collection">
            <div className="em__collection-title">
                <h1 className="gradient__text">Blog-like experience about some hobby topics</h1>
            </div>

            <div className="em__collection-list">
                {
                    hobby_list.map((item) => (
                        <div >
                            <Tag title={item.name} description={item.description} onClick={() => navigate(item.link)}/>
                        </div>
                    ))
                }
            </div>

        </div>
    );
}

export default Hobby;

