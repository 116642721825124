import React from "react";
import "./../hobby.css";
import "./../collection.css";
import {Tag} from "../../../common";
import {useNavigate} from "react-router-dom";
import {useRef} from "react";

function YELLOWHUB(){
    const navigate = useNavigate();

    const mengdingHuangCha = useRef(null);
    const xiangYa = useRef(null);

    const tealist = [
        {name:"1. Mengding huang cha (NNS)", ref: mengdingHuangCha},
        {name:"2. Xiang ya (NNS)", ref: xiangYa},
    ];

    return (
        <div className="em__post">
            <div className="em__post-section">
                <h3>About the history and production of yellow teas</h3>
                <p>
                    Nestled among the diverse tapestry of teas, yellow tea stands out as a rare and refined gem.
                    With a history dating back centuries, yellow tea carries an air of sophistication and a nuanced flavour profile.
                    <br/>
                    Yellow tea is deeply embedded in ancient China, with historical records suggesting its consumption as early as the Tang Dynasty (618-907 CE).
                    Initially crafted as a tribute tea for emperors, yellow tea gained prominence due to its intricate processing and delicate taste.
                </p>
                <p>
                    The production of yellow tea is a labour-intensive and time-consuming process that sets it apart from other tea types.
                    After plucking (a bud, or a bud and one or two young leaves), the leaves undergo similar initial processing to green tea—withering,
                    fixing (pan-frying or steaming, but for a shorter time and on lower temperatures), and shaping.
                    However, what distinguishes yellow tea is an additional step known as "men huan" or "sealing yellow".
                    During this stage, while the leaves are still warm, the tea is covered with cloth or paper, allowing the release and reabsorption of moisture for a slight oxidation.
                    This gives yellow tea its distinctive yellowish hue and a unique flavour profile.
                    This procedure is sometimes repeated for further yellowing, and it is finished with charcoal roasting at low temperatures.

                </p>

                <p>
                    There are three main yellow teas from different parts of China:
                </p>
                <ol>
                    <li>
                        <b>Jun Shan Yin Zhen:</b>
                        Jun Shan Yin Zhen, often called Silver Needle, is a rare and highly prized yellow tea from the Jun Shan island in China's Hunan province.
                        Composed exclusively of young, tender buds, this tea undergoes a meticulous production process, distinguishing it among the rarest yellow teas.
                        The long, slender silver needles, covered in downy fuzz, unfurl during brewing, releasing a delicate and refined infusion.
                        Known for its sweet, floral aroma and nuanced flavour profile with notes of apricot and chestnut, Jun Shan Yin Zhen embodies elegance in the world of yellow tea.
                        It is not impossible to obtain this tea, but the amount is tiny, and the prices are insane,
                        however, as one goes further from Jun Shan, while technically we can't name it the same tea, the quality can be as good as the original, and the price is much lower.
                    </li>
                    <li>
                        <b>Meng Ding Huang Ya:</b>
                        Hailing from the mist-shrouded mountains of Meng Ding in Sichuan, Meng Ding Huang Ya, or Meng Ding Yellow Bud,
                        is a yellow tea known for its distinct yellow-green leaves and a rich, mellow taste. Harvested in early spring, the tea consists of tender buds and leaves,
                        showcasing the quality of the region's tea craftsmanship.
                        The leaves undergo a unique processing method involving initial steps akin to green tea production, followed by careful oxidation.
                        The result is a cup of Meng Ding Huang Ya that exudes a delightful sweetness, a hint of chestnut, and a lingering floral aftertaste.
                    </li>
                    <li>
                        <b>Huo Shan Huang Ya:</b>
                        Huo Shan Huang Ya, or Yellow Buds from Huo Mountain, is a traditional yellow tea from Huoshan County in Anhui Province, China.
                        Crafted from the delicate buds and leaves of the tea plant,
                        this tea is renowned for its vibrant yellow liquor and a flavour profile that marries the freshness of green tea with the complexity of yellow tea.
                        The leaves undergo an intricate processing method involving a short oxidation period and a unique "sealing yellow" step, contributing to its distinctive characteristics.
                        Huo Shan Huang Ya is celebrated for its sweet, chestnut-like notes and smooth, velvety texture, making it a cherished gem among yellow tea enthusiasts.
                    </li>

                </ol>
                <p>
                    The flavour of yellow tea is characterized by a mellow and smooth profile, often with a pronounced sweetness and a lack of the grassy astringency found in green tea.
                    The oxidation and "sealing yellow" processes impart a subtle complexity, unveiling floral, fruity, and sometimes nutty notes.
                    The resulting brew is a golden elixir that reflects the care and craftsmanship invested in its production.
                    <br/>
                    Historically reserved for imperial courts, yellow tea has deep cultural significance in China.
                    Its intricate production process and association with nobility contribute to its reputation as a tea of refined taste and elegance.
                    Due to its elaborate processing and the need for precise conditions during oxidation, yellow tea is considered more challenging to produce than other tea types.
                    This, coupled with a relatively low yield, contributes to the rarity and exclusivity of yellow tea.
                    <br/>
                    While yellow tea experienced a decline in popularity during certain periods, interest has been resurgent recently.
                    Discerning tea enthusiasts and connoisseurs are rediscovering and appreciating each cup of yellow tea's nuanced flavours and cultural heritage.

                </p>
            </div>

            <div className="em__collection">
                <div className="em__collection-title">
                    <h1 className="gradient__text">Sampled white teas:</h1>
                </div>

                <div className="em__collection-list">
                    {
                        tealist.map((item) => (
                            <div >
                                <Tag title={item.name} description={item.description} onClick={() => item.ref.current.scrollIntoView({ behavior: 'smooth' })}/>
                            </div>
                        ))
                    }
                </div>

                <div className="em__post-section">
                    <div id="1. Mengding huang cha (NNS)" ref={mengdingHuangCha}>
                        <h3>1. Mengding huang cha (NNS)</h3>
                        <p>
                            Mengding Huang Cha is a beautiful and delicate yellow tea, a slight derivation from the usual single bud; this version has one bud and two leaves, making it unique.
                            Harvested in April 2018 from Mingshan Ya'an Sichuan.
                            <br/>
                            The dried leaves smelled like roasted pumpkin seeds, barley and honey.
                            After a quick rinse, the tea smelled like spinach and tamarind paste.
                            The tea soup was pale yellow, thick and brothy.
                            It tasted like sweet corn, candy floss and avocado puree.
                            The aftertaste was slightly floral with juicy sweetness.
                            I used 3g of leaf in 100ml gaiwan, with 85C water. On average, I could brew seven times.
                            <br/>
                            Green/Vegtal: 7/10, Floral: 7/10, Fruits: 4/10, Spices: 4/10, Wood/Earth: 1/10, Roast/Nuts: 3/10, Cream: 5/10, Mineral: 2/10.
                        </p>
                    </div>
                    <div id="2. Xiang ya (NNS)" ref={xiangYa}>
                        <h3>2. Xiang ya (NNS)</h3>
                        <p>
                            A yellow tea with unusual character, its yellow-green liquor yields the softness of a vegetable soufflé.
                            Though a little-known style from Sichuan, Xiang Ya surprises with a viscous, milky mouthfeel,
                            which coats the mouth with a smooth vegetality while remaining refreshing.
                            Harvested in mid-March 2021 from Mingshan, Ya'an, Sichuan.
                            <br/>
                            The dried leaves smelled like steamed spinach and magnolia.
                            After a quick rinse, the tea smelled like steamed green beans, butter, and turmeric.
                            The tea soup was clear, pale, golden, medium-thick, and brothy.
                            It tasted like clean vegetable stock, turmeric and cucumber.
                            The aftertaste was orange rind with a lingering sweetness.
                            I used 3g of leaf in 100ml gaiwan, with 85C water. On average, I could brew six times.
                            <br/>
                            Green/Vegtal: 9/10, Floral: 7/10, Fruits: 4/10, Spices: 2/10, Wood/Earth: 2/10, Roast/Nuts: 4/10, Cream: 2/10, Mineral: 4/10.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default YELLOWHUB;
