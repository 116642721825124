import React from "react";
import "./footer.css";
import "./../../index.css";
import "./../../global.css"

function Footer(){
    return (
        <div className="em__footer section__padding" id="footer">
            <div className="em__footer-slogan">
                <h1 className="gradient__text">
                    Innovating multiple projects at a time.
                </h1>
            </div>
            <div className="em__footer-heading">
                <h1>
                    Contact info:
                </h1>
            </div>
            <div className="em__footer-contact">
                <p>Email:</p>
                <a href="mailto:fehera@ms.sapientia.ro">fehera@ms.sapientia.ro</a>
                <p>Office Tel:</p>
                <a href="tel:+40265-208.170">+40265-208.170</a>
                <p>Fax:</p>
                <p>+40265-206.211</p>
                <p>Mobile: </p>
                <p> Use Email first :)</p>
                <p>Campus:</p>
                <p>Sighisoarei street, 1C, <br/>Corunca, Mures, RO</p>
                <p>Postal address:</p>
                <p>540485, Corunca, <br/>O.P. 9, C.P. 4</p>
            </div>
            <div className="em__footer-copyright">
                <p>
                    © Copyright 2021-2024 Embedded Musician
                </p>
            </div>
        </div>
    );
}

export default Footer;

