import React from "react";
import "./mcu_bm1.css";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";

import usb_bus from "./usb_bus.jpg";
import vsc_setup from "./vsc_setup.jpg";
import mmap from "./mmap.jpg";
import gdb_terminal from "./gdb_terminal.jpg";

const linker_script = `
_estack = 0x2001C000;

MEMORY
{
    CCMRAM  (xrw)   : ORIGIN = 0x10000000,  LENGTH = 16K
    RAM     (xrw)   : ORIGIN = 0x20000000,  LENGTH = 112K
    FLASH   (rx)    : ORIGIN = 0x80000000,  LENGTH = 512K
}
`;

const basic_assembly = `
.syntax unified
.cpu cortex-m4
.fpu softvfp
.thumb

.global vtable
.global reset_handler

.type vtable, %object
vtable:
    .word _estack
    .word reset_handler
.size vtable, .-vtable

.type reset_handler, %function
reset_handler:
    LDR r0, =_estack
    MOV sp, r0

    LDR r7, =0xDEADBEEF
    MOVS r0, #0
    loop:
        ADDS r0, r0, #1
        B loop
.size reset_handler, .-reset_handler
`;

const ocd_cfg = `
source [find interface/stlink.cfg]

transport select hla_swd

source [find target/stm32g4x.cfg]

reset_config srst_only srst_nogate
`;

function MCU_BM1(){
   return(
       <div className="em__post">
           <div className="em__post-title">
               <h1>Environment setup, first steps</h1>
           </div>
           <div className="em__post-section">
               <h3>Backstory and aim of the series:</h3>
               <p>
                   In the previous tutorial series, we've learnt how to use develop, debug,
                   and program firmware for some STM32 MCU using some integrated development environment (IDE) and hardware abstraction layer.
                   For the most part, we used the STM32G491 MCU, so in this series we will stick with it.
                   <br/>
                   We generated the core of the project using the STM32CubeMX GUI environment, and used CLion to develop firmware for different applications.
                   We saw that the HAL library created somewhat of a bloated code, which is OK for prototyping, but what if we need more space, more speed or both?
                   One could use the LL abstraction layer, which is lower(ish) level, less bloat, and the procedure would be nearly the same as in the case of HAL.
                   <br/>
                   I've opted for a different path.
                   In this tutorial series, we will begin our journey from the datasheet, create our own setup,
                   linker script, make file and hardware abstraction using C/C++.
               </p>
           </div>

           <div className="em__post-section">
               <h3>About the board and programs:</h3>
               <p>
                   I will be using the NUCLEO-G491RE board so that the tutorials can be made as a parallel to the HAL ones,
                   and also because the MCU has some nice memory and peripheral options that we can test using the bare-metal option.
               </p>
               <p>
                   I will write the firmware using <a href="https://code.visualstudio.com/"> Visual Studio Code</a> remotely
                   on Ubuntu 22.04.1 on <a href="https://en.wikipedia.org/wiki/Windows_Subsystem_for_Linux">Windows Subsystem for Linux (WSL)</a>.
                   Alternatively, you could use any text editor (Atom, JetBrains, Sublime, nano, Vim), any Linux distro, virtual machine or docker.
                   Even the bare Windows is good, but it may require additional steps.
               </p>

           </div>

           <div className="em__post-section">
               <h3> Environment setup:</h3>
               <p>
                   Let's presume that we have a "clean" Windows OS, with no WSL.
                   Open Windows 10 <b>Settings</b> app, click on <b>Programs and Features</b> under the
                   <b> Related Settings</b> section on the right pane.
                   Under <b>Programs and Features</b>, click <b>Turn Windows features on or off</b>.
                   Scroll down and enable <b>Windows Subsystem for Linux</b>.
                   Save changes and restart the OS.
               </p>
               <p>
                   After WSL is installed, open Windows Power Shell and check the WSL version using <i> wsl --version</i>.
                   If the WSL Kernel version is below <b>5.10.60.1</b>, you need to update it using <i> wsl --update</i> with administrator privileges.
                   If the update is done, you should restart WSL by executing <i>wsl --shutdown</i>.
               </p>
               <p>
                   The core setup is done for Linux install, but we need an additional functionality so that we can program our MCU from within the Linux subsystem.
                   <a href="https://github.com/dorssel/usbipd-win"> usbipd-win</a> is a nice program to share locally
                   connected USB devices to other machines ( including Hyper-V and WSL 2 ).
                   Inside the Power Shell, execute <i>winget install usbipd</i> to install all the necessary tools.
                   <br/>
                   As a final tool test, execute <i>usbipd list</i>.
                   This should print all the connected USB devices.
                   My list looks something like:
                   <img className="img_usb_bus" src={usb_bus} alt="usb_bus"/>
                   Disregard the shared state of the ST-Link. We will set that latter on.
               </p>
               <p>
                   Open the <b>Microsoft store</b> app, search for Ubuntu.
                   I've chosen v22.04.1, then click <b>Get</b>.
                   Once it is installed, launch it from the Windows search bar.
                   Create a user name, password.
                   Update the system using <i>sudo apt update && sudo apt upgrade</i>.
                   We need SSH server on Ubuntu so that VSCode can connect to it
                   - <a href="https://www.simplified.guide/ubuntu/install-ssh-server">here</a>'s a nice guide on how to enable everything.
                   If you want a customizable terminal,
                   you could install <a href="https://en.wikipedia.org/wiki/Windows_Terminal">Windows Terminal</a> or <a href="https://www.solarwinds.com/free-tools/solar-putty">Solar PuTTy</a>,
                   but in VSCode we will have terminal windows.
               </p>

               <p>
                   Now, onto VSCode. I'm using some plugins/extensions to make the development more enjoyable: Atom One Dark Theme, indent rainbow, rainbow brackets.
                   You will need the C/C++ IntelliSense, debugging and code browsing; Cortex-Debug installed locally and on the WSL.
                   Now, to connect to Ubuntu, we need to set the remote development.
                   There are many tutorials how to do it, so I'll give you <a href="https://code.visualstudio.com/docs/remote/ssh">one</a> to not regurgitate already good information.
               </p>
               <Popup trigger={<img className="img_vsc_setup clickable_img" src={vsc_setup} alt="vsc_setup"/>} modal nested>
                   {close => (
                       <img className="em__img_full" src={vsc_setup} alt="vsc_setup" />
                   )}
               </Popup>
               <p>
                   If everything went right, hou should see that VSCode is connected to the Ubuntu (1).
                   Here you will have the option to graphically manipulate the remote files (2).
                   You will also gain access to the remote terminal (3,4). I like to use two terminals to be able to see processes in parallel.
                   Also, I'm using fish instead of bash because it's more sympathetic to me.
                   <br/>
                   Final part of the first setup: we need to share the connected ST-Link.
                   Go to the Power Shell and execute <i>ipconfig</i>.
                   There should be a vEthernet(WSL) adapter, note down the IP address (in my case it's 172.29.192.1).
                   Next, from the previous usbipd listing we know the BUSID of the ST-Link device (mine was 1-1).
                   From Power Shell execute <i>usbipd wsl attach --busid 1-1</i>, which will connect it to the Ubuntu system.
                   If you have more than one systems, you'll need to specify the distro name.
                   After this first attachment, the STATE of the USB device will be shared,
                   and you can attach it on the WSL side using the command <i>usbip attach -r 172.29.192.1 --busid=1-1</i> as root or sudo.
               </p>
               <p>
                   Operating System -- Check, editor -- check, device -- check. The last things that we need are the compiler and the debugger.
                   Since we will write code to an ARM device, we will need ARM GCC.
                   Download the latest compiler/assembler from ARM using wget:
                   <i> wget https://developer.arm.com/-/media/Files/downloads/gnu-rm/10.3-2021.10/gcc-arm-none-eabi-10.3-2021.10-x86_64-linux.tar.bz2</i>.
                   Then decompress it to a close location <i>tar -xf gcc-arm-none-eabi-10.3-2021.10-x86_64-linux.tar.bz2</i> and rename it to something more usable.
                   For debugging, we'll need GDB, but that is provided in the previously mentioned VSCode extension.
               </p>
           </div>

           <div className="em__post-section">
               <h3> First bare-metal program:</h3>
               <p>
                   Here we will write the bare minimum code, to run on our MCU.
                   First let's look at the stages of code compilation pipeline:
                   <ol>
                       <li>
                           <b>Preprocessing:</b> In a C program, we include libraries, define macros, conditional compilations.
                           These are known as directives.
                           During the preprocessing, the preprocessing directives are replaced with their original values.
                       </li>
                       <li>
                           <b>Compilation:</b> This phase provides the architecture dependent (unique) assembly code.
                           Here, the compiler checks for syntax errors, and if there are no errors, it creates an intermediate assembly code for every C file (.s ,.a, .asm).
                       </li>
                       <li>
                           <b>Assembly:</b> In this stage, an assembler is used to translate the intermediate code into object code.
                           Every assembly file is translated into one object file (.o) which is written in low-level machine code.
                           This file is relocatable and it is not directly executable in the sense that it is not yet mapped to any memory location.
                       </li>
                       <li>
                           <b>Linking:</b> The linker performs two tasks: resolution and relocation of symbols.
                           The processor can understand the instruction in the .o file, but some parts could be out of order or missing.
                           The program must be completed, rearranged and stitched together.
                           The linker will organize the object files in such a way that functions in some parts can call functions from other parts.
                           Here, library objects will be added to the code (like puts in case of a print program).
                           Of course, the stitching is done in accordance with the used architecture.
                           There are precise locations(addressees) for the registers, FLASH, RAM, periphery. The constants are put in different place than the variables.
                           The variables that have initial value are separated from the ones that don't have initial value.
                           The interrupts and exceptions have fixed addresses etc.
                           All of this is specified inside data tables and linker scripts.
                       </li>
                   </ol>
                   Is this confusing yet? Oh and then there are the different types of code optimisations at different stages of the compilation pipeline.
               </p>
               <p>
                   Let's take it one step at a time.
                   In this tutorial, we will create a basic linker script and a rudimentary assembly code to test out the working principle.
               </p>
               <Popup trigger={<img className="img_mmap clickable_img" src={mmap} alt="mmap"/>} modal nested>
                   {close => (
                       <img className="em__img_full" src={mmap} alt="mmap" />
                   )}
               </Popup>
               <p>
                   First we will take a look at the RM0440 reference manual.
                   On page 82, we have the memory map of the STM32G4:
                   <br/>
                   Our STM32G491 has 112KB SRAM organized as
                   <ul>
                       <li> 80KB SRAM1 at 0x20000000</li>
                       <li> 16KB SRAM2 at 0x20014000</li>
                       <li> 16KB CCM SRAM at 0x1000000 and mapped et the end of SRAM2 (0x20018000)</li>
                   </ul>
                   It also has 512KB of FLASH starting from 0x80000000.
               </p>
           </div>
           <div className="em__post-section">
               <p>
                   Since the MCU has 112K (0x1C000) RAM starting from 0x20000000,
                   the end of the program stack (estack) is at (0x2001C000).
                   We need to write this information to the linker script, together with the RAM and FLASH partitions:
               </p>
               <SyntaxHighlighter language="c" style={AtomOneDark}>
                   {linker_script}
               </SyntaxHighlighter>
               <p>
                   The MEMORY block tells the linker how much memory the chip has.
                   The FLASH is marked read-only and executable (rx) since the program will reside there,
                   and we don't need to modify it at runtime.
                   We mark the RAMs as read/write and executable (xrw), since we should be able to change the value of the variables there.
                   In the upcoming tutorial, we will partition the memory, but for now this will suffice.
                   Save the file as STM32G491.ld.
               </p>
               <p>
                   The final part is the program code.
                   The MCUs have some nice hardware interrupt functionalities.
                   When some conditions are fulfilled, the program counter can automatically jump to the interrupt function,
                   and jump back when the function is served.
                   This comes with additional complications.
                   We need to write a vector table to show where the program counter should jump when each interrupt occurs.
                   Thankfully, most interrupt sources are disabled by default, so we can ignore them for now.
                   There is only one interrupt that is always active, the <b>reset handler</b>.
                   The reset handler is a function that is run when the system is power cycled or started.
               </p>
               <p>
                   Lets create a new assembly file named <i>core.s</i>.
               </p>
               <SyntaxHighlighter language="armasm" style={AtomOneDark}>
                   {basic_assembly}
               </SyntaxHighlighter>
               <p>
                   The first four lines tell the compiler what instructions should expect.
                   The instructions starting with '.' are assembler directives.
                   We signal the use of ARM/Thumb assembly syntax
                   (read more about it <a href="https://developer.arm.com/documentation">here</a> and <a href="https://sourceware.org/binutils/docs/as/ARM_002dInstruction_002dSet.html#ARM_002dInstruction_002dSet">here</a>).
                   The STM32G4 contains an ARM M4 core.
                   We will not use the floating point unit.
                   The <i>.thumb</i> directive tells the compiler that the following section uses Thumb instruction set.
               </p>
               <p>
                   The <i>.global</i> directives ensure that the labels are available to other files.
                   The vector table will have only two entries: the end of the stack address and the address of the reset handler.
                   The <i>.word</i> directive places a 4B value into the program. The compiler will replace this with the corresponding address value.
                   <br/>
                   The reset handler will contain a simple code that can be easily debugged.
                   First, the stack pointer is reset.
                   Next we will load the literal (constant) <i>0xDEADBEEF</i> into R7 using the LDR command.
                   Similarly, we load 0 to R0.
                   Finally, we create a loop label, we increment tha value of R0 by 1, save it in R0 and jump back to the loop label.
               </p>

               <p>
                   Now that we have the linker and the program code, we can compile it using the ARM toolchain.
                   Execute the instruction <i>arm-none-eabi-gcc -x assembler-with-cpp -c -O0 -mcpu=cortex-m4 -mthumb -Wall core.s -o core.o</i> inside VSCode terminal.
                   Here, <i>-O0</i> tells the compiler that we don't want any optimisation. This should create the <i>core.o</i> object file.
                   <br/>
                   Next, execute <i>arm-none-eabi-gcc core.o -mcpu=cortex-m4 -mthumb -Wall --specs=nosys.specs -nostdlib -lgcc -T./STM32G491.ld -o main.elf</i>.
                   Here, we create the ELF (executable and Linkable Format) file.
                   You can check the program organisation using the <i>nm</i> command: <i>arm-none-eabi-nm main.elf</i>.
                   The vtable should be at the start of the program memory (0x08000000) so that the MCU won't be confused about the execution.
               </p>
               <p>
                   For debugging purposes we need the OpenOCD, which you should be familiar with from the HAL tutorials.
                   If not, install it using <i>sudo apt-get install openocd</i>.
                   Create a cfg file for the board <i>stm32g491.cfg</i>:
               </p>
               <SyntaxHighlighter language="c" style={AtomOneDark}>
                   {ocd_cfg}
               </SyntaxHighlighter>
           </div>

           <div className="em__post-section">
               <h3> Upload and debug: </h3>
               <p>
                   We arrived at the point where we can test out the code.
                   We did not program any GPIOs (yet), so we will have to check the value of registers R0 and R7.
                   <br/>
                   If you have the board plugged in, and attached to WSL, start openOCD using the command <i>openocd -f stm32g491.cfg</i>.
                   The terminal should print out that OpenOCD is ready and it is listening on port 3333. The debug LED should also be toggling.
                   On a different terminal, execute <i>arm-none-eabi-gdb main.elf</i>, then <i>target extended-remote :3333</i>.
                   The GDB should stop at a FLASH line, while OpenOCD accepts GDB on tcp/3333, and prints out the device ID and memory size.
                   <br/>
                   The <i>load</i> instruction will load the ELF file, and jump to 0x08000000.
                   <i>i r</i> writes the content of the available registers, we are interested in R0 and R7 (still garbage).
                   Execute the <i>continue</i> instruction and after some time <i>Ctrl + C</i> to stop.
                   If you execute the register information instruction, R7 should contain a 0xDEADBEEF,
                   while R0 should be a number that increases with successive continue and stop instructions.
               </p>
               <img className="img_gdb_terminal" src={gdb_terminal} alt="gdb_terminal"/>
                <p>
                    In the next tutorial, we will create the full vector table, the linker section, and we will automate the building and debugging process.
                </p>
           </div>

           <div className="em__post-navigation">

               <NavLink to="./..">
                   <Button btnID={"leftBTN"} buttonSize="btn--medium"> Micro Projects</Button>
               </NavLink>

               <NavLink to="./../stm-bm-2">
                   <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
               </NavLink>
           </div>
       </div>
   );
}

export default MCU_BM1;