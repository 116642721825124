import React from "react";
import "./mcu_hal32.css";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";

import mpu_config from "./mpu_config.jpg";

const data_declaration = `
#include "string.h"

uint8_t TxBuf[1024];
uint8_t RxBuf[1024];
uint16_t idx = 1;
`;
const data_usage = `
memset(TxBuf, idx++, 1024);
memcpy(RxBuf, TxBuf, 1024);    
HAL_Delay(1000);
`;

const data_attribute = `
__attribute__ ((section(".buffer"), used)) uint8_t TxBuf[1024];
__attribute__ ((section(".buffer"), used)) uint8_t RxBuf[1024];
`;

const single_ram_linker = `
.buffer(NOLOAD) :
{
    . = ALIGN (1);
} >RAM_D2 
`;

const absolute_ram_linker = `
.buffer(NOLOAD) :
{
    . = ALIGN (1);
    . = ABSOLUTE (0x30000020);
    *(.TxBuffer)
    . = ABSOLUTE (0x30000420);
    *(.RxBuffer)
} >RAM_D2 
`;

const dma_problem = `
memset(TxBuf, idx++, 1024);
HAL_DMA_Start(&hdma_memtomem_dma1_stream0, (uint32_t) TxBuf,(uint32_t) RxBuf, 1024);
HAL_Delay(100);
HAL_DMA_Abort(&hdma_memtomem_dma1_stream0);
HAL_Delay(900);
`;

const soft_dma = `
memset(TxBuf, idx++, 1024);
SCB_CleanDCache_by_Addr((uint32_t *) TxBuf, 1024);
HAL_DMA_Start(&hdma_memtomem_dma1_stream0, (uint32_t) TxBuf,(uint32_t) RxBuf, 1024);
SCB_InvalidateDCache_by_Addr((uint32_t *) RxBuf, 1024);
HAL_Delay(100);
HAL_DMA_Abort(&hdma_memtomem_dma1_stream0);
HAL_Delay(900);
`;

function MCU_HAL32(){
    return(
        <div className="em__post">
            <div className="em__post-title">
                <h1>Memory Protection Unit (MPU)</h1>
            </div>

            <div className="em__post-section">
                <h3>Aim of this tutorial:</h3>
                <p>
                    In this tutorial, we will learn how to assign variables to explicit memory locations using the linker script.
                    We will test DMA operations while caching the data and instructions.
                    Finally we will learn soft and hard methods for memory protection.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Memory regions and the importance of protection:</h3>
                <p>
                    One of the main roles of the CPU is to perform memory operations: read or write data, execute instructions.
                    As the MCUs advance, we have more and more memory regions (both internal and external).
                    The memory setup for a few well-known MCUs:
                    <ul>
                        <li>the <i>STM32F103</i> has 20KB SRAM accessible with the Dbus, 128kB of Flash accessible by both Dbus and Ibus, and has an additional 512B SRAM accessible on the APB1.</li>
                        <li>the <i>STM32F446</i> has internal FLASH (512kB) with Icode and Dcode accelerator, 128kB of SRAM (112kB SRAM1 + 16kB SRAM2),
                            and FMC external memory controller</li>
                        <li>the <i>STM32G491</i> has internal FLASH (512kB) with Icode and Dcode accelerator, memory mapped QSPI, 122kB of SRAM (16kB CCM SRAM, 80 kB SRAM1, 16kB SRAM2)</li>
                        <li>the <i>STM32H745</i> has 2x 1MB of FLASH, FMC, QSPI and SDMMC that are memory mappable, 512kB AXI SRAM, 352kB of SRAM (128kB SRAM1, 128kB SRAM2, 32kB SRAM3, 64kB SRAM4),
                            4kB Backup SRAM.
                        </li>
                    </ul>
                    DMA access is limited in the core and tightly coupled memory regions, so maybe we would like to specify where some variables are located.
                </p>
                <p>
                    The role of the memory protection unit is to prevent unallocated region access, create privileged access, and provide attribute control over memory regions.
                    It also monitors memory transaction (including instruction fetches). Rule violations can trigger fault exceptions.
                </p>
                <p>
                    MPU is a hardware that has important role in speculative memory access, DMA operations.
                    The <i>speculative memory access</i> is done by the CPU to reduce wait cycles.
                    It fetches data or instructions ahead of time - which can be a bad thing if that data changes over time, or points to an invalid location.
                    This results in a hardware fault like "Break at address 0xDEADBEEF with no debug information available or outside program code".
                    There are speculative reads - from normal memory region ahead of time, even if the data is unavailable.
                    This is the case if an external device cannot be accessed.
                    0xDFFFFFFF - 0x60000000 region is available as external device/external RAM regions, regardless if there is and external device attached or not,
                    so we must block the access to the read of these regions.
                    There are speculative fetches - the CPU grabs instructions in advance whether it needs or not.
                    There are speculative cache linefills - if an information is cacheable, the CPU reads an entire cache line into the cache slot.
                    This data might change, and the cache memory will not reflect the change, but we can mark this region as uncacheable.
                </p>
                <p>
                    DMA issues are similar to those of the speculative accesses.
                    DMA can't work with caheable regions, and needs data coherency.
                    There is a software workaround of invalidating cache, but it's easier to mark the region as no cacheable.
                </p>

                <p>
                    In cortex M7 devices, there are three types of memories: normal, device and strongly ordered.
                    In normal region, the CPU can reorder the transactions for efficiency and speculative reads.
                    All RAMs are normal type by default.
                    The device or strongly ordered memory regions require the preservation of transaction order.
                    The device memory is bufferable - the MCU registers are of device memory type.
                    The strongly ordered region is not bufferable - like external NAND memories.
                </p>

                <p>
                    The MPU uses four attributes:
                    shareable - must be used if multiple masters want to access a region, strongly ordered memory is always shareable;
                    cacheable - the instruction and data caches can be used;
                    bufferable - the write instruction can be performed latter, while the execution of another instruction precedes it;
                    execute never (XN) - in this region, instructions are not supposed to be executed, the instructions are stored and executed from the RAM.
                    This is an important attribute when the FLASH is relocated to an external memory (like QSPI).
                    A shareable and cacheable region only permits instruction caching.
                </p>

            </div>

            <div className="em__post-section">
                <h3>Project setup:</h3>
                <p>
                    Start STM32CubeIDE and create a new project.
                    This time, we are not interested in the peripherals.
                    Set the system clock to 400 MHz, enable the debugger, the instruction and data caches.
                    In System Core, DMA add a MemToMem DMA transfer with Bite Width, single burst size, normal mode and fifo enabled.
                    Generate the project.
                </p>

                <h3>Explicit memory allocation:</h3>
                <p>
                    In the CM7 main file, first comment out anything related to HSEM.
                    We are not interested in the CM4, so we will just let it be - alternatively, you could disable the CM4 boot using STM32CubeProg.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {data_declaration}
                </SyntaxHighlighter>
                <p>
                    Create two buffers and a counter.
                    Include the header that contains the <i>memset</i> handle.
                    In the super loop paste the following code:
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {data_usage}
                </SyntaxHighlighter>
                <p>
                    This is a simple memory operation.
                    We allocate a memory zone, then we write some values, then we copy the values to another zone.
                    Build the code and debug it.
                    In the build analyzer, you can see that both our buffers are placed in the D1 RAM, <i>.bss</i> section.
                    Fortunately, in this case the D1 RAM can be accessed by the DMA, but in other MCUs this data would be placed in the DTCRAM, that cannot be accessed by the DMA.
                </p>
                <p>
                    Let's place these buffers in the D2 RAM.
                    Add the following attributes to the declarations:
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {data_attribute}
                </SyntaxHighlighter>
                <p>
                    Then, we need to define the <i>.buffer</i> section in the linker script.
                    In the <b>STM32H745ZITX.ld</b>, we can see that the RAM_D2 is by default readable, writable and executable.
                    This region starts from 0x30000000, and has a length of 288KB.
                    Add the folowing lines after the heap and stack section:
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {single_ram_linker}
                </SyntaxHighlighter>
                <p>
                    This means that we defined the buffer section at the D2 RAM location with 1B alignment.
                    If you build and upload the code, you can see that the  program works the same way, but the data resides at the start of the D2 RAM.
                    Now, if you want to explicitly specify the starting locations, we can create regions using absolute addresses:
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {absolute_ram_linker}
                </SyntaxHighlighter>
                <p>
                    Here, we created the TxBuffer at 0x30000020, while the RxBuffer is at 0x30000420.
                    Change the attributes in the main source, and debug the code.
                </p>

            </div>

            <div className="em__post-section">
                <h3>DMA memory to memory transfer:</h3>
                <p>
                    Let's replace the memcpy with DMA transfer.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {dma_problem}
                </SyntaxHighlighter>
                <p>
                    Build and debug the program.
                    Here, we set the TxBuffer, which is executed correctly, but the RxBuf values never change.
                    This is the problem that arises from data caching.
                    <br/>
                    The software solution is to clean the data cache after each TxBuffer write, and invalidate the data cache after each DMA transaction.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {soft_dma}
                </SyntaxHighlighter>
                <p>
                    Test the code, and check if the problem is solved.
                    Now let's do the same using MPU.
                    Open up the ioc file and go to the Cortex M7 settings.
                </p>
                <Popup trigger={<img className="img_mpu_config_hal32 clickable_img" src={mpu_config} alt="mpu_config_hal32"/>} modal nested>
                    {
                        close =>(
                            <img className="em__img_full" src={mpu_config} alt="mpu_config_hal32"/>
                        )
                    }
                </Popup>
                <p>
                    Enable the MPU control with background region and privileged access only.
                    There are multiple regions that we can configure.
                    Our Tx buffer starts on address 0x30000020 and spans 1kB, while the Rx buffer starts on the address 0x30000420.
                    We could dedicate region 0 to the TxBuffer and region 1 to the RxBuffer, both with a length of 1kB, or we could use a single region.
                    I opted for the latter. In this case, the buffers has a total size of 2.03kB, for which I used the smallest value that incorporated this (4kB).
                    We permit all accesses, disable the instructions, since we store data here, and disable the cacheable options for the DMA.
                    Regenerate the project.
                </p>
                <p>
                    From the source file, remove the Clean and Invalidate functions.
                    Build and debug the code.
                    This time, that the memory is not cached, the data transaction occurs as it should.
                </p>
            </div>

            <div className="em__post-navigation">

                <NavLink to="./../stm-hal-31">
                    <Button btnID={"leftBTN"} buttonSize="btn--medium"> Previous Post</Button>
                </NavLink>

                <NavLink to="./../stm-hal-33">
                    <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
                </NavLink>
            </div>

        </div>
    );
}

export default MCU_HAL32;