import React from "react";
import "./mcu_hal33.css";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";

import eth_periph from "./eth_periph.jpg"
import mpu_setup from "./mpu_setup.jpg"
import lwip_general from "./lwip_general.jpg"
import lwip_platform from "./lwip_platforml.jpg"
import lwip_key from "./lwip_key.jpg"

const ethif_pool = `
/* USER CODE BEGIN 2 */
#if defined ( __ICCARM__ ) /*!< IAR Compiler */
#pragma location = 0x30040200
extern u8_t memp_memory_RX_POOL_base[];

#elif defined ( __CC_ARM )  /* MDK ARM Compiler */
__attribute__((at(0x30040200)) extern u8_t memp_memory_RX_POOL_base[];

#elif defined ( __GNUC__ ) /* GNU Compiler */
__attribute__((section(".Rx_PoolSection"))) extern u8_t memp_memory_RX_POOL_base[];

#endif
/* USER CODE END 2 */
`;

const multi_def = `
/* USER CODE BEGIN 1 /
#undef LWIP_PROVIDE_ERRNO
#define LWIP_ERRNO_STDINCLUDE
/ USER CODE END 1 */
`;

const linker_section = `
.lwip_sec (NOLOAD) :
  {
    . = ABSOLUTE(0x30040000);
    *(.RxDecripSection) 
    
    . = ABSOLUTE(0x30040060);
    *(.TxDecripSection)
    
    . = ABSOLUTE(0x30040200);
    *(.Rx_PoolSection)  
  } >RAM_D2
`;


function MCU_HAL33(){
    return(
        <div className="em__post">
            <div className="em__post-title">
                <h1>Ethernet with LwIP</h1>
            </div>

            <div className="em__post-section">
                <h3>Aim of this tutorial:</h3>
                <p>
                    In this tutorial, we will configure the ethernet peripheral with the CM7 of the STM32H745 to handle data transfers.
                    To achieve a fast response, we will use I/D caches with MPU.
                    We will use the LwIP middleware with a static IP address on the communication side.
                    We will test the configured board by pinging it.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Project setup:</h3>
                <p>
                    I wanted to use the CM7 for the ethernet because I thought the beefier core was more suitable for this task.
                    As it turns out, the CM4 is more straightforward to configure, there is no need for memory protection, and even the middleware works out of the box.
                    So obviously, I went the more challenging route - but I will point out the differences in the settings.
                </p>
                <p>
                    Start STM32CubeIDE and create a new project.
                    Set the system clock to 400 MHz and enable the debugger, the instruction and data caches.
                    Enable the UART for the CM7, and enable the ethernet for both cores, with CM7 being the initializer (If you use CM4, just select that single context).
                    Ensure that <b>RMII</b> is selected using the same Nucleo board.
                    Under GPIO settings, set the ethernet pin maximum speed to <b>very high</b>.
                </p>
                <Popup trigger={<img className="img_eth_periph_hal33 clickable_img" src={eth_periph} alt="eth_periph_hal33"/>} modal nested>
                    {
                        close =>(
                            <img className="em__img_full" src={eth_periph} alt="eth_periph_hal33"/>
                        )
                    }
                </Popup>

                <p>
                    Configure the memory protection unit (skip this step for the CM4) to disable speculative access and set up the LwIP heap and RX/TX descriptors.
                    It is advised to use a memory region for the ethernet tasks that is not common with the CM4 memory, so I opted for RAM D2 (0x30000000).
                    <ul>
                        <li>
                            Enable the MPU in the background region for privileged access only.
                        </li>
                        <li>
                            We will use region 0 to disable speculative access to unused memory regions.
                            The base address is 0, the size is 4GB, the SubRegion is 0x87, 0 level TEX field, and disable all access permissions.
                            Furthermore, it only enables shareability.
                        </li>
                        <li>
                            We will use region 1 for the LwIP heap. The base address is 0x30020000, with a 128KB size.
                            We need a TEX field of level 1 with permitted access but disable instruction, shearability, cacheability and bufferability.
                            Since only the LwIP will have access here, we don't need to share it. The heap must always be strongly ordered, so there is no buffering or caching.
                            Also, we don't want the program execution here.
                        </li>
                        <li>
                            Finally, region 2 will be used for the descriptors.
                            This zone will start from 0x30040000, and the size is 512B.
                            This zone will be a multi-master access zone, so permit all accesses, disable the instruction access, and the caching.
                            The descriptors can be buffered, but the data must be coherent and sharable.
                        </li>
                    </ul>
                </p>

                <Popup trigger={<img className="img_mpu_setup_hal33 clickable_img" src={mpu_setup} alt="mpu_setup_hal33"/>} modal nested>
                    {
                        close =>(
                            <img className="em__img_full" src={mpu_setup} alt="mpu_setup_hal33"/>
                        )
                    }
                </Popup>
            </div>

            <div className="em__post-section">
                <h3>LwIP middleware:</h3>

                <p>
                    From Middleware and Software Packs, add LwIP, which provides a lightweight TCP/IP stack for the MCU.
                    I tried to enable this pack only for the CM7, but I couldn't do it for some reason.
                    Maybe it's a bug or some unknown feature.
                    For some reason, STMicroelectronics pushes more and more AzureRtOS with ThreadX and NetXDuo, but so far, I'm not a fan of those components.
                    <br/>
                    So... enable both CM7 and CM4 contexts and set CM7 as the initializer.
                </p>


                <Popup trigger={<img className="img_lwip_general_hal33 clickable_img" src={lwip_general} alt="lwip_general_hal33"/>} modal nested>
                    {
                        close =>(
                            <img className="em__img_full" src={lwip_general} alt="lwip_general_hal33"/>
                        )
                    }
                </Popup>

                <p>
                    In the general settings, disable DHCP. We don't want to deal with dynamic IP address assignation.
                    Give an IP address that is within the range of your local network.
                    Set the netmask and the gateway (this should be the address of your router).
                </p>

                <Popup trigger={<img className="img_lwip_platform_hal33 clickable_img" src={lwip_platform} alt="lwip_platform_hal33"/>} modal nested>
                    {
                        close =>(
                            <img className="em__img_full" src={lwip_platform} alt="lwip_platform_hal33"/>
                        )
                    }
                </Popup>

                <p>
                    In the platform settings, choose LAN8742 driver.
                </p>

                <Popup trigger={<img className="img_lwip_key_hal33 clickable_img" src={lwip_key} alt="lwip_key_hal33"/>} modal nested>
                    {
                        close =>(
                            <img className="em__img_full" src={lwip_key} alt="lwip_key_hal33"/>
                        )
                    }
                </Popup>

                <p>
                    In Key options, configure the heap memory size. 10kB should be enough, but I used all the SRAM D2 just to be on the safe side.
                    Set the heap pointer to 0x30020000, and enable the LWIP_NETIF_LINK_CALLBACK to detect cable plugging.
                    Finally, generate the project.
                </p>

            </div>

            <div className="em__post-section">
                <h3>Linker script and firmware:</h3>
                <p>
                    Open up the <i>ethernetif.c</i> source and paste the definition of the receiver pool address:
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {ethif_pool}
                </SyntaxHighlighter>
                <p>
                    In <i>lwipopts.h</i>, add the following define to suppress multiple definitions compilation error:
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {multi_def}
                </SyntaxHighlighter>
                <p>
                    Then, in the GCC preprocessor, define symbols and add the <b>DATA_IN_D2_SRAM</b>.
                    This is needed for the LwIP sources.
                </p>

                <p>
                    In the FLASH linker script, add the following section definition after the user stack heap:
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {linker_section}
                </SyntaxHighlighter>
                <p>
                    In the main source file, comment out the HSEM-related parts and add the <i>MX_LWIP_Process()</i> function inside the superloop.
                    This function checks if a packet is ready to be read, handles timeouts and checks the state of the ethernet link.

                    <br/>
                    Build the firmware and upload it.
                    Connect your board to a switch, and try to ping the address you set at the static IP configuration.
                    If you connected the board directly to the PC, additional IP configuration must be done on the PC side.
                </p>
            </div>

            <div className="em__post-navigation">

                <NavLink to="./../stm-hal-32">
                    <Button btnID={"leftBTN"} buttonSize="btn--medium"> Previous Post</Button>
                </NavLink>

                <NavLink to="./../stm-hal-34">
                    <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
                </NavLink>
            </div>

        </div>
    );
}

export default MCU_HAL33;