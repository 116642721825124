import React from "react";
import "./collection.css";
import {Card} from "../../common";
import {useNavigate} from "react-router-dom";
import {headamp_profile, maglev_profile} from "./Posts";

const ProjectList = [
    {name:"Headphone amp for bass guitar", imgURL: headamp_profile,
        link: "./head-bass"},
    {name:"Suspended electromagnetic levitation", imgURL: maglev_profile,
        link: "./sus-lev"}
]

function AnalogDigital(){

    const navigate = useNavigate();
    console.log("We arrived here!");

    return(
      <div className="em__collection">
          <div className="em__collection-title">
              <h1 className="gradient__text">Analog and digital electronics</h1>
          </div>

          <div className="em__collection-list">
              {
                  ProjectList.map((item) => (
                      <div >
                          <Card title={item.name} description={item.description}
                                imgURL={item.imgURL} onClick={() => navigate(item.link)}/>
                      </div>
                  ))
              }
          </div>

      </div>
    );
}

export default AnalogDigital;