import React from "react";
import "./mcu_bm4.css";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";

import stm_rcc_top from "./stm_rcc_top.jpg";
import {MathJax, MathJaxContext} from "better-react-mathjax";

function ShowTex({string}){
    const config = {
        loader: { load: ["[tex]/html"]},
        tex: {packages: {"[+]": ["html"]},
            inlineMath: [["$", "$"]],
            displayMath: [["$$", "$$"]]
        }
    };

    return(
        <MathJaxContext config={config} version={3}>
            <MathJax dynamic inline>
                {string}
            </MathJax>
        </MathJaxContext>
    );
}

const dsys_header = `
namespace driver{
    class dSYS{
        private:
            const uint32_t _system_clock;
            
            void APBCLK_Enable(void);
            void NVIC_SetPriorityGrouping(uint32_t PriorityGroup);
            void disableBatPull(void);
            void SysClockConf(void);
            void SysTickConf(uint32_t sys_clock, uint32_t tick);

        public:
            dSYS(uint32_t sys_clk);
            void delay_ms(uint32_t delay);
    };
}
`;

const dsys_defs = `
#define SET_BIT(REG, BIT)     ((REG) |= (BIT))
#define CLEAR_BIT(REG, BIT)   ((REG) &= ~(BIT))
#define READ_BIT(REG, BIT)    ((REG) & (BIT))
#define CLEAR_REG(REG)        ((REG) = (0x0))
#define WRITE_REG(REG, VAL)   ((REG) = (VAL))
#define READ_REG(REG)         ((REG))
#define MODIFY_REG(REG, CLEARMASK, SETMASK)  WRITE_REG((REG), (((READ_REG(REG)) & (~(CLEARMASK))) | (SETMASK)))
#define POSITION_VAL(VAL)     (__CLZ(__RBIT(VAL)))
`;

const dsys_addr_offs = `
static constexpr uint32_t RCC_AHB2_ADDRESS = 0x40021000U;
static constexpr uint32_t PWR_ADDRESS = 0x40007000U;
static constexpr uint32_t SBC_ADDRESS = 0xE000ED00UL;
static constexpr uint32_t FLASH_R_BASE_ADDRESS = 0x40022000U;
static constexpr uint32_t SYSTICK_ADDRESS = 0xE000E010UL;

static constexpr uint32_t PLLCFGR_OFFSET = 0x0C;

static constexpr uint32_t APB2ENR_OFFSET = 0x60;
static constexpr uint32_t APB1ENR_OFFSET = 0x58;

static constexpr uint32_t CR1_OFFSET = 0x00;
static constexpr uint32_t CR3_OFFSET = 0x08;
static constexpr uint32_t CR5_OFFSET = 0x80;
static constexpr uint32_t SR2_OFFSET = 0x14;

static constexpr uint32_t AIRCR_OFFSET = 0x00C;
static constexpr uint32_t ACR_OFFSET = 0x00;
static constexpr uint32_t RCCCFGR_OFFSET = 0x08;

static constexpr uint32_t SYSTICK_LOAD_OFFSET = 0x004;
static constexpr uint32_t SYSTICK_CTRL_OFFSET = 0x000;
static constexpr uint32_t SYSTICK_VAL_OFFSET = 0x008;
static constexpr uint32_t SYSTICK_CALIB_OFFSET = 0x00C;
`;

const dsys_cons = `
dSYS::dSYS(uint32_t sys_clk, PRIORITY_GROUP PriorityGroup):_system_clock(sys_clk){
    APBCLK_Enable();
    disableBatPull();
    SysClockConf();
    SysTickConf(_system_clock, 1000U);
};
`;

const dsys_apb_clock = `
void dSYS::APBCLK_Enable(void){
    uint32_t temp_reg;
    SET_BIT(*((volatile uint32_t*)(RCC_AHB2_ADDRESS + APB2ENR_OFFSET)), 0x1UL);
    temp_reg = READ_BIT(*((volatile uint32_t*)(RCC_AHB2_ADDRESS + APB2ENR_OFFSET)), 0x1UL);
    (void)temp_reg;
    SET_BIT(*((volatile uint32_t*)(RCC_AHB2_ADDRESS + APB1ENR_OFFSET)), (0x1UL << 28U));
    temp_reg = READ_BIT(*((volatile uint32_t*)(RCC_AHB2_ADDRESS + APB1ENR_OFFSET)), (0x1UL << 28U));
    (void)temp_reg;
}
`;

const dsys_dead_bat = `
void dSYS::disableBatPull(void){
    SET_BIT(*((volatile uint32_t*)(PWR_ADDRESS + CR3_OFFSET)), (0x1UL << (14U)));
}
`;

const dsys_clk_conf = `
void dSYS::SysClockConf(void){
    uint32_t temp_reg;
    // set flash latency
    MODIFY_REG(*((volatile uint32_t*)(FLASH_R_BASE_ADDRESS + ACR_OFFSET)), 0xFUL, 0x00000004U);
    // wait untill the value is set
    do{
        temp_reg = (uint32_t) (READ_BIT(*((volatile uint32_t*)(FLASH_R_BASE_ADDRESS + ACR_OFFSET)), (0xFUL << 0U)));
    }while(temp_reg != 0x00000004U);
    // enable boost 1 mode
    CLEAR_BIT(*((volatile uint32_t*)(PWR_ADDRESS + CR5_OFFSET)), (0x1UL << 8U));
    // enable HSE
    SET_BIT(*((volatile uint32_t*)(RCC_AHB2_ADDRESS + CR1_OFFSET)), (0x1UL << 16U));
    // wait untill HSE is ready
    do{
        temp_reg = (uint32_t) (READ_BIT(*((volatile uint32_t*)(RCC_AHB2_ADDRESS + CR1_OFFSET)), 0x00020000));
    }while(temp_reg != 0x00020000);
    // set the PLL
    MODIFY_REG(*((volatile uint32_t*)(RCC_AHB2_ADDRESS + PLLCFGR_OFFSET)), 0x06007FF3, 0x00000053 | (85 << 8U));
    // enable the PLL domain
    SET_BIT(*((volatile uint32_t*)(RCC_AHB2_ADDRESS + PLLCFGR_OFFSET)), 0x01000000);
    // enable the PLL
    SET_BIT(*((volatile uint32_t*)(RCC_AHB2_ADDRESS + CR1_OFFSET)), 0x01000000);
    // wait untill PLL is ready
    do{
        temp_reg = (uint32_t) (READ_BIT(*((volatile uint32_t*)(RCC_AHB2_ADDRESS + CR1_OFFSET)), 0x02000000));
    }while(temp_reg != 0x02000000);

    // set system clock source to PLL
    MODIFY_REG(*((volatile uint32_t*)(RCC_AHB2_ADDRESS + RCCCFGR_OFFSET)), 0x3UL, 0x00000003U);
    // set AHB clock prescaler
    MODIFY_REG(*((volatile uint32_t*)(RCC_AHB2_ADDRESS + RCCCFGR_OFFSET)), 0x000000F0, 0x00000080U);
    // wait untill the source is ready
    do{
        temp_reg = (uint32_t) (READ_BIT(*((volatile uint32_t*)(RCC_AHB2_ADDRESS + RCCCFGR_OFFSET)), 0x0000000CU));
    }while(temp_reg != 0x0000000CU);

    // 1us transition
    for (uint32_t i = (170 >> 1); i !=0; i--);

    // set AHB clock prescaler -- not divided
    MODIFY_REG(*((volatile uint32_t*)(RCC_AHB2_ADDRESS + RCCCFGR_OFFSET)), 0x000000F0, 0);
    // set APB1 prescaler -- not divided
    MODIFY_REG(*((volatile uint32_t*)(RCC_AHB2_ADDRESS + RCCCFGR_OFFSET)), 0x00000700, 0);
    // set APB2 prescaler -- not divided
    MODIFY_REG(*((volatile uint32_t*)(RCC_AHB2_ADDRESS + RCCCFGR_OFFSET)), 0x00003800, 0);
}
`;

const dsys_tick_conf = `
void dSYS::SysTickConf(uint32_t sys_clock, uint32_t tick){
    WRITE_REG(*((volatile uint32_t*)(SYSTICK_ADDRESS + SYSTICK_LOAD_OFFSET)), (uint32_t)((sys_clock/tick) - 1UL));
    WRITE_REG(*((volatile uint32_t*)(SYSTICK_ADDRESS + SYSTICK_VAL_OFFSET)), 0);
    WRITE_REG(*((volatile uint32_t*)(SYSTICK_ADDRESS + SYSTICK_CTRL_OFFSET)), (1UL << 2U) | 1UL);
}
`;

const dsys_delay = `
void dSYS::delay_ms(uint32_t delay){
    volatile uint32_t temp_reg = *((volatile uint32_t*)(SYSTICK_ADDRESS + SYSTICK_CTRL_OFFSET));
    uint32_t temp_delay;
    ((void) temp_reg);
    temp_delay = delay;
    if(temp_delay < 0xFFFFFFFFU)
        temp_delay++;
    while(temp_delay != 0U)
        if((*((volatile uint32_t*)(SYSTICK_ADDRESS + SYSTICK_CTRL_OFFSET)) & (1UL << 16U)) != 0U)
        temp_delay--;
}
`;

const dsys_main = `
#include "./../inc/dSYS.hpp"
#include "./../inc/dGPIO.hpp"

using namespace driver;


int main(void){

    dSYS system = dSYS(170000000,PRIORITY_GROUP::GROUP_4);
    dGPIOp PA5 = dGPIOp(GPIO_PORT::GPIOA, 5, GPIO_MODE::OUTPUT, GPIO_PULL::NONE, GPIO_SPEED::LOW);

    while(1){
        system.delay_ms(1000);
        PA5.toggle();
    }
}
`;

function MCU_BM4(){
   return(
       <div className="em__post">
           <div className="em__post-title">
               <h1>General purpose inputs and outputs</h1>
           </div>
           <div className="em__post-section">
               <h3>Aim of this tutorial:</h3>
               <p>
                   In the previous tutorials we brushed over the configuration of the oscillator.
                   This was not a problem for the STM32 MCUs, since they have internal oscillators,
                   and default values for the PLLs and internal voltage regulators.

                   <br />
                   This is not the case for every MCU, so it's a good practice to configure the clock and power source before any other peripheral.
                   In this tutorial, we will implement a class to configure the internal voltage regulator, to switch to the HSE source,
                   and configure the PLL so that it generates a precise 170MHz clock.
                   We will also configure the sysTick timer for millisecond delay routines.
               </p>
           </div>
           <div className="em__post-section">
               <h3>High Speed Clock block:</h3>
               <p>
                   The first thing to do is to
                   consult <a href="https://www.st.com/resource/en/reference_manual/dm00355726-stm32g4-series-advanced-arm-based-32-bit-mcus-stmicroelectronics.pdf">the datasheet</a>.
                   From page 278 we have the Reset and Clock Control (RCC) section.
               </p>
               <Popup trigger={<img className="img_stm_rcc_top_bm4 clickable_img" src={stm_rcc_top} alt="stm_rcc_top"/>} modal nested>
                   {close => (
                       <img className="em__img_full" src={stm_rcc_top} alt="stm_rcc_top" />
                   )}
               </Popup>
               <p>
                   We are interested in configuring the <b>SYSCLK</b>.
                   This is the clock signal source for almost every component inside the IC (except the independent watch-dog
                   and the real-time clock).
                   The SYSCLK can be derrived from a number of sources through the <b>Clock Source Control</b> multiplexer:
                   We have the <b>High Speed Internal</b> (HSI) clock set to 16MHz - this is used by the MCU by default;
                   We have the <b>High Speed External</b> (HSE) source; or we can get a scaled clock signal from
                   the <b> Phase Locked Loop</b> (PLL) module.
                   The input of the PLL can be set to either HSI or HSE.
               </p>
               <p>
                   The HSE can be configured as bypass source (if we have an external clock signal in the range 4-48MHz),
                   in which case only the input pin is used, the output can be configured as GPIO.
                   The other possibility is to set it up for a crystal in which case both pins will be used.
               </p>
               <p>
                   The NUCLEO-G491RE board has a 24MHz crystal connected to the OSC pins,
                   and we want the core to run at the maximum frequency (170MHz).
                   For this, we need to set the HSE to crystal mode.
                   We must set up the PLL to generate the upscaled clock signal from HSE,
                   and then derive the clock source from the PLL.
               </p>
               <p>
                   Of course, the clock speed is in close relation with the supply voltage.
                   The Core voltage determines the operating mode of the system.
                   In Range 1 Boost mode, the Core voltage is 1.28V, the HSE and PLL has no limitations,
                   the FLASH memory access time is kept to minimum and it is possible to read and write the FLASH at runtime.
                   In Range 1 Normal mode, the Core voltage is 1.2V, the HSE has no limitation, the PLL can only output a 150MHz signal at best,
                   while the FLASH characteristics remain the same.
                   In Range 2 mode, the HSE only accepts signals with frequencies up to 26MHz, the PLL can only down-scale this signal,
                   the FLASH access times are increased, and the write and erase operations are disabled.
               </p>
               <p>
                   So we also need to set the product voltage range to Range 1 Boost.
                   Now let's concentrate on the PLL block.
                   We have a HSE of 24MHz, but the input after division must be kept in the range <ShowTex string="$[2.66, 16]$"/> MHz,
                   so we can set the M pre-divider to 6 - this will create a 4MHz signal.
                   Next we have the frequency multiplier which can create a signal in the range <ShowTex string="$[96, 344]$"/> MHz,
                   so by setting the N to 85, we generate a signal with 340MHz frequency which is good.
                   Finally we have the post-divider which can create a signal in the range <ShowTex string="$[8, 170]$"/> MHz,
                   if we set it to 2, we achieved our goal of 170MHz.
                   Note that this is not a unique solution, this is only one possible solution.
                   There are some additional dividers after the SYSCLK (such as for the Cortex system timer, APB1/2 peripheral and APB1/2 timers)
                   but those can be left at default 1.
               </p>
           </div>

           <div className="em__post-section">
               <h3>Creating the firmware:</h3>
               <p>
                   Let's code this beast.
                   First create a new class in the driver namespace - dSYS with a single private property, some private methods and two public methods.
               </p>
               <SyntaxHighlighter language="c" style={AtomOneDark}>
                   {dsys_header}
               </SyntaxHighlighter>
               <p>
                   The first public one is the constructor, which only has a single argument, the system clock frequency, and a delay method.
                   In the system driver source, first we should create some helper defines:
               </p>
               <SyntaxHighlighter language="c" style={AtomOneDark}>
                   {dsys_defs}
               </SyntaxHighlighter>
               <p>
                   Then we need some register addresses and bit offsets/masks from the datasheet:
               </p>
               <SyntaxHighlighter language="c" style={AtomOneDark}>
                   {dsys_addr_offs}
               </SyntaxHighlighter>
               <p>
                   Let's move to the constructor:
               </p>
               <SyntaxHighlighter language="c" style={AtomOneDark}>
                   {dsys_cons}
               </SyntaxHighlighter>
               <p>
                   The idea is to enable the APB clock for the system.
                   Then we can disable the pull-up for the battery since we do not need the USB-C PD.
                   Then we can configure the clock scheme and wait for the correct bring-up.
                   Finally we can set the SysTick timer to 1ms ticks - this is the usual setup for a ms resolution delay.
                   Let's get through the methods one by one.
               </p>
               <p>
                   First we enable the peripheral clocks APB2 and APB1 with a single instruction delay after setting the bits.
               </p>
               <SyntaxHighlighter language="c" style={AtomOneDark}>
                   {dsys_apb_clock}
               </SyntaxHighlighter>
               <p>
                   This is the instruction to disable the dead battery internal pull-up resistor:
               </p>
               <SyntaxHighlighter language="c" style={AtomOneDark}>
                   {dsys_dead_bat}
               </SyntaxHighlighter>
               <p>
                   Next we can configure the clock and PLL scheme, which should be mostly self explanatory after a datasheet read.
               </p>
               <SyntaxHighlighter language="c" style={AtomOneDark}>
                   {dsys_clk_conf}
               </SyntaxHighlighter>
               <p>
                   The FLASH latency is set - memory can be accessed at around 24MHz, but we want to operate the core at 170MHz.
                   This latency instruction tells the core to slow down when accessing FLASH memory.
                   For 24-48MHz 1 wait state is enough; for 48 - 72 MHz at least 2 wait states are required ... 170MHz requires at least 4.
                   Then we can configure the Vcore so that the maximum core frequency can be utilized.
                   We enable the HSE oscillator, we configure the PLL pre/post dividers and the multiplier.
                   Then we can choose the PLL source as the HSE and enable the PLL.
                   When the PLL is ready, we can switch the system clock source to it.
                   The 1us transition is recommended by the datasheet after which we set every other divider to unity.
               </p>
               <p>
                   As for the sysTick timer:
               </p>
               <SyntaxHighlighter language="c" style={AtomOneDark}>
                   {dsys_tick_conf}
               </SyntaxHighlighter>
               <p>
                   The first instruction sets the reload register.
                   The second instruction clears the tick counter.
                   The last instruction sets the source as the core clock adn enables the timer.
                   Note that you can configure almost any TIM peripheral to be used as the sysTick according to the datasheet.
               </p>
               <p>
                   And finally the delay:
               </p>
               <SyntaxHighlighter language="c" style={AtomOneDark}>
                   {dsys_delay}
               </SyntaxHighlighter>
               <p>
                   The first read clears count flag.
                   Then we must insert a period to guarantee a minimum wait.
                   The last part is only a loop in which we decrease the value of an internal register.
                   Since the tick period is 1ms, and the register contains the delay in ms, when the register is zero, the function can return.
               </p>
               <p>
                   And here we have a simple main method:
               </p>
               <SyntaxHighlighter language="c" style={AtomOneDark}>
                   {dsys_main}
               </SyntaxHighlighter>
               <p>
                   I've used the previously created GPIO class so that I can measure the timing, which was exactly 1s.
               </p>
               <p>
                   Note that this system setup is a really crude one.
                   You could improve it by adding pre and post divider and multiplier arguments to the constructor.
                   You could also create a method which switches the clock sources from the main.
                   But that is all left to your imagination.
               </p>

           </div>


           <div className="em__post-navigation">

               <NavLink to="./../stm-bm-3">
                   <Button btnID={"leftBTN"} buttonSize="btn--medium"> Previous Post</Button>
               </NavLink>

               <NavLink to="./../stm-bm-5">
                   <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
               </NavLink>
           </div>

       </div>
   );
}

export default MCU_BM4;