import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {Navbar, Footer} from "./common";
import {Home, About, Hobby, Projects, Teaching} from "./pages";
import {AnalogDigital, HeadAmp, MagLev} from "./pages";
import {FPGA, FPGA_1, FPGA_2, FPGA_3, FPGA_4, FPGA_5, FPGA_6, FPGA_7, FPGA_8, FPGA_9, FPGA_10} from "./pages";
import {Micros, STM_HAL, OVEN, STM_RTOS, STM_BM, BLE_SENSOR} from "./pages";
import {MCU_HAL_1, MCU_HAL_2, MCU_HAL_3, MCU_HAL_4, MCU_HAL_5, MCU_HAL_6, MCU_HAL_7, MCU_HAL_8, MCU_HAL_9,
MCU_HAL_10, MCU_HAL_11, MCU_HAL_12, MCU_HAL_13, MCU_HAL_14, MCU_HAL_15, MCU_HAL_16, MCU_HAL_17, MCU_HAL_18,
MCU_HAL_19, MCU_HAL_20, MCU_HAL_21, MCU_HAL_22, MCU_HAL_23, MCU_HAL_24, MCU_HAL_25, MCU_HAL_26, MCU_HAL_27,
MCU_HAL_28, MCU_HAL_29, MCU_HAL_30, MCU_HAL_31, MCU_HAL_32, MCU_HAL_33, MCU_HAL_34, MCU_HAL_35, MCU_HAL_36} from "./pages";
import {STM_RTOS_1, STM_RTOS_2, STM_RTOS_3, STM_RTOS_4, STM_RTOS_5, STM_RTOS_6, STM_RTOS_7} from "./pages";
import {MCU_BM_1, MCU_BM_2, MCU_BM_3, MCU_BM_4, MCU_BM_5} from "./pages";

import {DSP_PROJ, DSP_1, DSP_2, DSP_3, DSP_4, DSP_5, DSP_6, DSP_7, DSP_8, DSP_9, DSP_10, DSP_11, DSP_12} from "./pages";

import {TEAHUB, WHITEHUB, BLACKHUB, GREENHUB, OOLONGHUB, PURPLEHUB, SHENGHUB, SHOUHUB, YELLOWHUB, HEIHUB} from "./pages";

import ScrollToTop from "./ScrollToTop";

ReactDOM.render(
    <Router>
        <ScrollToTop>
        <div className="gradient__bg">
            <Navbar/>
        </div>
        <Routes>
            <Route exact path="/" element={<Home/>}/>
            <Route exact path="/about" element={<About/>}/>
            <Route exact path="/projects" element={<Projects/>}/>

            <Route exact path="/projects/analog-digital-tut" element={<AnalogDigital/>}/>
            <Route exact path="/projects/analog-digital-tut/head-bass" element={<HeadAmp/>}/>
            <Route exact path="/projects/analog-digital-tut/sus-lev" element={<MagLev/>}/>
            <Route exact path="/projects/micro-tut" element={<Micros/>}/>
            <Route exact path="/projects/micro-tut/rfl-oven" element={<OVEN/>}/>
            <Route exact path="/projects/micro-tut/ble-sensor" element={<BLE_SENSOR/>}/>

            <Route exact path="/projects/micro-tut/stm-hal" element={<STM_HAL/>}/>
            <Route exact path="/projects/micro-tut/stm-hal/stm-hal-1" element={<MCU_HAL_1/>}/>
            <Route exact path="/projects/micro-tut/stm-hal/stm-hal-2" element={<MCU_HAL_2/>}/>
            <Route exact path="/projects/micro-tut/stm-hal/stm-hal-3" element={<MCU_HAL_3/>}/>
            <Route exact path="/projects/micro-tut/stm-hal/stm-hal-4" element={<MCU_HAL_4/>}/>
            <Route exact path="/projects/micro-tut/stm-hal/stm-hal-5" element={<MCU_HAL_5/>}/>
            <Route exact path="/projects/micro-tut/stm-hal/stm-hal-6" element={<MCU_HAL_6/>}/>
            <Route exact path="/projects/micro-tut/stm-hal/stm-hal-7" element={<MCU_HAL_7/>}/>
            <Route exact path="/projects/micro-tut/stm-hal/stm-hal-8" element={<MCU_HAL_8/>}/>
            <Route exact path="/projects/micro-tut/stm-hal/stm-hal-9" element={<MCU_HAL_9/>}/>
            <Route exact path="/projects/micro-tut/stm-hal/stm-hal-10" element={<MCU_HAL_10/>}/>
            <Route exact path="/projects/micro-tut/stm-hal/stm-hal-11" element={<MCU_HAL_11/>}/>
            <Route exact path="/projects/micro-tut/stm-hal/stm-hal-12" element={<MCU_HAL_12/>}/>
            <Route exact path="/projects/micro-tut/stm-hal/stm-hal-13" element={<MCU_HAL_13/>}/>
            <Route exact path="/projects/micro-tut/stm-hal/stm-hal-14" element={<MCU_HAL_14/>}/>
            <Route exact path="/projects/micro-tut/stm-hal/stm-hal-15" element={<MCU_HAL_15/>}/>
            <Route exact path="/projects/micro-tut/stm-hal/stm-hal-16" element={<MCU_HAL_16/>}/>
            <Route exact path="/projects/micro-tut/stm-hal/stm-hal-17" element={<MCU_HAL_17/>}/>
            <Route exact path="/projects/micro-tut/stm-hal/stm-hal-18" element={<MCU_HAL_18/>}/>
            <Route exact path="/projects/micro-tut/stm-hal/stm-hal-19" element={<MCU_HAL_19/>}/>
            <Route exact path="/projects/micro-tut/stm-hal/stm-hal-20" element={<MCU_HAL_20/>}/>
            <Route exact path="/projects/micro-tut/stm-hal/stm-hal-21" element={<MCU_HAL_21/>}/>
            <Route exact path="/projects/micro-tut/stm-hal/stm-hal-22" element={<MCU_HAL_22/>}/>
            <Route exact path="/projects/micro-tut/stm-hal/stm-hal-23" element={<MCU_HAL_23/>}/>
            <Route exact path="/projects/micro-tut/stm-hal/stm-hal-24" element={<MCU_HAL_24/>}/>
            <Route exact path="/projects/micro-tut/stm-hal/stm-hal-25" element={<MCU_HAL_25/>}/>
            <Route exact path="/projects/micro-tut/stm-hal/stm-hal-26" element={<MCU_HAL_26/>}/>
            <Route exact path="/projects/micro-tut/stm-hal/stm-hal-27" element={<MCU_HAL_27/>}/>
            <Route exact path="/projects/micro-tut/stm-hal/stm-hal-28" element={<MCU_HAL_28/>}/>
            <Route exact path="/projects/micro-tut/stm-hal/stm-hal-29" element={<MCU_HAL_29/>}/>
            <Route exact path="/projects/micro-tut/stm-hal/stm-hal-30" element={<MCU_HAL_30/>}/>
            <Route exact path="/projects/micro-tut/stm-hal/stm-hal-31" element={<MCU_HAL_31/>}/>
            <Route exact path="/projects/micro-tut/stm-hal/stm-hal-32" element={<MCU_HAL_32/>}/>
            <Route exact path="/projects/micro-tut/stm-hal/stm-hal-33" element={<MCU_HAL_33/>}/>
            <Route exact path="/projects/micro-tut/stm-hal/stm-hal-34" element={<MCU_HAL_34/>}/>
            <Route exact path="/projects/micro-tut/stm-hal/stm-hal-35" element={<MCU_HAL_35/>}/>
            <Route exact path="/projects/micro-tut/stm-hal/stm-hal-36" element={<MCU_HAL_36/>}/>

            <Route exact path="/projects/micro-tut/stm-bm" element={<STM_BM/>}/>
            <Route exact path="/projects/micro-tut/stm-bm/stm-bm-1" element={<MCU_BM_1/>}/>
            <Route exact path="/projects/micro-tut/stm-bm/stm-bm-2" element={<MCU_BM_2/>}/>
            <Route exact path="/projects/micro-tut/stm-bm/stm-bm-3" element={<MCU_BM_3/>}/>
            <Route exact path="/projects/micro-tut/stm-bm/stm-bm-4" element={<MCU_BM_4/>}/>
            <Route exact path="/projects/micro-tut/stm-bm/stm-bm-5" element={<MCU_BM_5/>}/>

            <Route exact path="/projects/micro-tut/stm-rtos" element={<STM_RTOS/>}/>
            <Route exact path="/projects/micro-tut/stm-rtos/stm-rtos-1" element={<STM_RTOS_1/>}/>
            <Route exact path="/projects/micro-tut/stm-rtos/stm-rtos-2" element={<STM_RTOS_2/>}/>
            <Route exact path="/projects/micro-tut/stm-rtos/stm-rtos-3" element={<STM_RTOS_3/>}/>
            <Route exact path="/projects/micro-tut/stm-rtos/stm-rtos-4" element={<STM_RTOS_4/>}/>
            <Route exact path="/projects/micro-tut/stm-rtos/stm-rtos-5" element={<STM_RTOS_5/>}/>
            <Route exact path="/projects/micro-tut/stm-rtos/stm-rtos-6" element={<STM_RTOS_6/>}/>
            <Route exact path="/projects/micro-tut/stm-rtos/stm-rtos-7" element={<STM_RTOS_7/>}/>


            <Route exact path="/projects/fpga-tut" element={<FPGA/>}/>
            <Route exact path="/projects/fpga-tut/fpga-tut-1" element={<FPGA_1/>} />
            <Route exact path="/projects/fpga-tut/fpga-tut-2" element={<FPGA_2/>} />
            <Route exact path="/projects/fpga-tut/fpga-tut-3" element={<FPGA_3/>} />
            <Route exact path="/projects/fpga-tut/fpga-tut-4" element={<FPGA_4/>} />
            <Route exact path="/projects/fpga-tut/fpga-tut-5" element={<FPGA_5/>} />
            <Route exact path="/projects/fpga-tut/fpga-tut-6" element={<FPGA_6/>} />
            <Route exact path="/projects/fpga-tut/fpga-tut-7" element={<FPGA_7/>} />
            <Route exact path="/projects/fpga-tut/fpga-tut-8" element={<FPGA_8/>} />
            <Route exact path="/projects/fpga-tut/fpga-tut-9" element={<FPGA_9/>} />
            <Route exact path="/projects/fpga-tut/fpga-tut-10" element={<FPGA_10/>} />

            <Route exact path="/projects/dsp-tut" element={<DSP_PROJ/>}/>
            <Route exact path="/projects/dsp-tut/dsp-1" element={<DSP_1/>}/>
            <Route exact path="/projects/dsp-tut/dsp-2" element={<DSP_2/>}/>
            <Route exact path="/projects/dsp-tut/dsp-3" element={<DSP_3/>}/>
            <Route exact path="/projects/dsp-tut/dsp-4" element={<DSP_4/>}/>
            <Route exact path="/projects/dsp-tut/dsp-5" element={<DSP_5/>}/>
            <Route exact path="/projects/dsp-tut/dsp-6" element={<DSP_6/>}/>
            <Route exact path="/projects/dsp-tut/dsp-7" element={<DSP_7/>}/>
            <Route exact path="/projects/dsp-tut/dsp-8" element={<DSP_8/>}/>
            <Route exact path="/projects/dsp-tut/dsp-9" element={<DSP_9/>}/>
            <Route exact path="/projects/dsp-tut/dsp-10" element={<DSP_10/>}/>
            <Route exact path="/projects/dsp-tut/dsp-11" element={<DSP_11/>}/>
            <Route exact path="/projects/dsp-tut/dsp-12" element={<DSP_12/>}/>

            <Route exact path="/teaching" element={<Teaching/>}/>
            <Route exact path="/hobby" element={<Hobby/>}/>

            <Route exact path ="/hobby/tea-hub" element={<TEAHUB/>}/>
            <Route exact path ="/hobby/tea-hub/tea-hub-white" element={<WHITEHUB/>}/>
            <Route exact path ="/hobby/tea-hub/tea-hub-black" element={<BLACKHUB/>}/>
            <Route exact path ="/hobby/tea-hub/tea-hub-hei" element={<HEIHUB/>}/>
            <Route exact path ="/hobby/tea-hub/tea-hub-green" element={<GREENHUB/>}/>
            <Route exact path ="/hobby/tea-hub/tea-hub-oolong" element={<OOLONGHUB/>}/>
            <Route exact path ="/hobby/tea-hub/tea-hub-purple" element={<PURPLEHUB/>}/>
            <Route exact path ="/hobby/tea-hub/tea-hub-sheng" element={<SHENGHUB/>}/>
            <Route exact path ="/hobby/tea-hub/tea-hub-shou" element={<SHOUHUB/>}/>
            <Route exact path ="/hobby/tea-hub/tea-hub-yellow" element={<YELLOWHUB/>}/>
        </Routes>
        <Footer/>
        </ScrollToTop>
    </Router>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
