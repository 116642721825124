import React from "react";
import "./mcu_hal10.css";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";

import rtc_schem from "./rtc_schem.jpg";
import rtc_oscillator from "./rtc_setup_1.jpg";
import rtc_osc_setup_pll from "./rtc_setup_2.jpg";
import rtc_basic_setup from "./rtc_setup_3.jpg";
import rtc_irq from "./rtc_setup_4.jpg";
import rtc_terminal from "./rtc_terminal.jpg";

const set_fun = `
void set_time(){
    RTC_TimeTypeDef sTime;
    RTC_DateTypeDef sDate;

    sTime.Hours = 0x11;
    sTime.Minutes = 0x38;
    sTime.Seconds = 0x00;
    sTime.DayLightSaving = RTC_DAYLIGHTSAVING_NONE;
    sTime.StoreOperation = RTC_STOREOPERATION_RESET;

    if(HAL_RTC_SetTime(&hrtc, &sTime, RTC_FORMAT_BCD) != HAL_OK)
        Error_Handler();

    sDate.WeekDay = RTC_WEEKDAY_MONDAY;
    sDate.Month = RTC_MONTH_APRIL;
    sDate.Date = 0x25;
    sDate.Year = 0x22;

    if(HAL_RTC_SetDate(&hrtc, &sDate, RTC_FORMAT_BCD) != HAL_OK)
        Error_Handler();

    HAL_RTCEx_BKUPWrite(&hrtc, RTC_BKP_DR1, 0xBEEF);
}`;

const get_fun = `
void get_time(){
    RTC_TimeTypeDef gTime;
    RTC_DateTypeDef gDate;

    HAL_RTC_GetTime(&hrtc, &gTime, RTC_FORMAT_BIN);
    HAL_RTC_GetDate(&hrtc, &gDate, RTC_FORMAT_BIN);

    printf("\\r%2d-%02d-%02d-%02d:%02d:%02d", 2000+gDate.Year, gDate.Month, gDate.Date, gTime.Hours,
           gTime.Minutes, gTime.Seconds);
    fflush(stdout);
}`;

const set_alarm = `
void set_alarm(){
    RTC_AlarmTypeDef sAlarm;

    sAlarm.AlarmTime.Hours = 0x11;
    sAlarm.AlarmTime.Minutes = 0x40;
    sAlarm.AlarmTime.Seconds = 0;
    sAlarm.AlarmTime.DayLightSaving = RTC_DAYLIGHTSAVING_NONE;
    sAlarm.AlarmTime.StoreOperation = RTC_STOREOPERATION_RESET;
    sAlarm.AlarmMask = RTC_ALARMMASK_NONE;
    sAlarm.AlarmSubSecondMask = RTC_ALARMSUBSECONDMASK_ALL;
    sAlarm.AlarmDateWeekDaySel = RTC_ALARMDATEWEEKDAYSEL_DATE;
    sAlarm.AlarmDateWeekDay = 0x25;
    sAlarm.Alarm = RTC_ALARM_A;

    if(HAL_RTC_SetAlarm_IT(&hrtc, &sAlarm, RTC_FORMAT_BCD) != HAL_OK)
        Error_Handler();
}`;

const rtc_irq_fun = `
void HAL_RTC_AlarmAEventCallback(RTC_HandleTypeDef *hrtcx){
    HAL_GPIO_WritePin(LD2_GPIO_Port, LD2_Pin, GPIO_PIN_SET);
}
`;


function MCU_HAL10(){
    return(
        <div className="em__post">
            <div className="em__post-title">
                <h1>Real Time Clock - RTC</h1>
            </div>

            <div className="em__post-section">
                <h3>Aim of this tutorial:</h3>
                <p>
                    In this tutorial, we will learn how to configure and use the RTC module for time-keeping purposes,
                    and for date dependent alarms/interrupts.
                </p>
            </div>

            <div className="em__post-section">
                <h3>The RTC:</h3>
                <p>
                    An RTC keeps track of time once an initial input is provided to it.
                    This module comes with its own crystal oscillator and power supply,
                    so that the time keeping continues even when there is a disturbance in <s>the force</s> power supply.
                </p>
                <p>
                    There are external RTC modules like the <a href="https://datasheets.maximintegrated.com/en/ds/DS3231.pdf">DS3231</a>,
                    <a href="https://ww1.microchip.com/downloads/en/DeviceDoc/MCP7940M-Low-Cost%20I2C-RTCC-with-SRAM-20002292C.pdf">MCP7940</a>, etc.
                    The STM32 has an internal RTC module, so wi will take a closer look at it.
                </p>
                <Popup trigger={<img className="img_rtc_schem_hal10 clickable_img" src={rtc_schem} alt="rtc_schem_hal10"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={rtc_schem} alt="rtc_schem_hal10" />
                    )}
                </Popup>
                <p>
                    The RTC module offers calendar with year, month, date, week day, hours in 12/24h format, minutes, seconds and subseconds.
                    Automatic correction for 28, 29(leap year), 30, 31 days of the month.
                    It offers two programmable alarms, on the fly correction for RTC clock pulses.
                </p>
                <p>
                    We can supply the RTC from either VDD or VBAT.
                    We can use a 32.768kHz external crystal or external resonator (LSE), the 32kHz internal RC oscillator (LSI),
                    or a prescaled high-speed external clock signal (HSE) for clock source.
                </p>
                <p>
                    Besides the obvious timing and alarm functionalities, the RTC module has a few importan functions:
                    It can generate automatic wake-up events in all low-power modes.
                    It provides backup registers which retain the memory even in low-power modes.
                    An internal or external tamper event detection is implemented.
                </p>
                <p>
                    The 32.768kHz oscillator is a nice choice, because a 15b divider can generate the required 1Hz pulse
                    (usually this is first done with a 128 divisor to have a 256Hz signal for the subseconds, and then a 256 divisor for the seconds, months, etc.).
                </p>
            </div>

            <div className="em__post-section">
                <h3>Hardware configuration:</h3>
                <p>
                    Create a new project using the on-board external oscillator, the debug connection and the USART2 communication module.
                    Enable the low speed clock (LSE).
                </p>
                <Popup trigger={<img className="img_rtc_oscillator_hal10 clickable_img" src={rtc_oscillator} alt="rtc_oscillator_hal10"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={rtc_oscillator} alt="rtc_oscillator_hal10" />
                    )}
                </Popup>
                <p>
                    In the clock configuration window, set the RTC Mux to LSE.
                </p>
                <img className="img_rtc_osc_setup_pll_hal10" src={rtc_osc_setup_pll} alt="rtc_osc_setup_pll_hal10"/>
                <p>
                    In the Pinout and Configuration window, select RTC from the Timers.
                    Activate the clock source and the calendar, leave everything on default.
                    We will set the time in code.
                </p>
                <Popup trigger={<img className="img_rtc_basic_setup_hal10 clickable_img" src={rtc_basic_setup} alt="rtc_basic_setup_hal10"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={rtc_basic_setup} alt="rtc_basic_setup_hal10" />
                    )}
                </Popup>
                <p>
                    Generate the project.
                </p>
                <p>
                    Include the standard IO header, and copy the <i>_write</i> function from an older project so that we can use printf.
                    We will create a function that configures the internal RTC at power-up;
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {set_fun}
                </SyntaxHighlighter>
                <p>
                    The RTC has different structs for time and date.
                    We give every numeric value in binary coded decimal (BCD) for better readability.
                    In my case 0x11 means 11h, 0x38 means 38min etc.
                    We could use binary format, but that must be specified in the <i>HAL_RTC_SetTime</i> function too.
                </p>
                <p>
                    We can set the date similarly to the time.
                    The final line of code is to write to the RTC back-up registers.
                    These registers retain their values in every low-powered operation mode (which we will see in the future).
                </p>

                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {get_fun}
                </SyntaxHighlighter>
                <p>
                    The get function should be pretty straight-forward.
                    We store the values of the RTC time and date in two local structs, which we can print using UART TX.
                </p>
                <p>
                    If we use the usual '\r\n' termination, we would end up with a wall of text mess.
                    For this reason we omit the string termination and new line.
                    On the other hand, we insert a carriage return to the start of the string, this way the text will be
                    rewritten on the same line.
                </p>
                <p>
                    Finally, if we don't use the newline character, the string buffer will be emptied or shown only when the puffer is full.
                    We can call the <i>fflush</i> function to force the output at every print.
                </p>
                <p>
                    Call the <i>set_time</i> function after the init functions in the main.
                    Call the <i>get_time</i> function in the main loop with a 1s delay.
                </p>
                <img className="img_rtc_terminal_hal10" src={rtc_terminal} alt="rtc_terminal_hal10"/>
                <p>
                    Upload the compiled program and start the Serial terminal.
                    You should see the current time displayed, and refreshed at every second.
                </p>
                <p>
                    Next, we will try to use one of the alarm functions.
                    Open the .ioc file and go to the RTC setup.
                    Select Internal Alarm for Alarm A and enable the RTC interrupts.
                </p>
                <Popup trigger={<img className="img_rtc_irq_hal10 clickable_img" src={rtc_irq} alt="rtc_irq_hal10"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={rtc_irq} alt="rtc_irq_hal10" />
                    )}
                </Popup>
                <p>
                    The setup for the alarm is similar to the setup of the clock and it's self explanatory:
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {set_alarm}
                </SyntaxHighlighter>
                <p>
                    When the date and time of the RTC matches that of the alarm, an interrupt event is generated, which can be used with the following function:
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {rtc_irq_fun}
                </SyntaxHighlighter>
                <p>
                    Here, I've only used the on-board LED for IRQ signalling because of a lack of imagination, but it will suffice for demonstration purposes.
                    The source files can be downloaded from <a href="https://gitlab.com/stm32_mcu_group/stm32_hal/10_rtc.git">here</a>.
                </p>
            </div>



            <div className="em__post-navigation">

                <NavLink to="./../stm-hal-9">
                    <Button btnID={"leftBTN"} buttonSize="btn--medium"> Previous Post</Button>
                </NavLink>

                <NavLink to="./../stm-hal-11">
                    <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
                </NavLink>
            </div>
        </div>
    );
}

export default MCU_HAL10;