import React from "react";
import "./stm_rtos2.css";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";

import task1_mx from "./task1_mx.jpg";
import task2_mx from "./task2_mx.jpg";
import context_logic from "./context_logic.jpg";
import task_no_delay from "./tasks_no_delay.jpg";
import task_no_hip from "./tasks_no_hip.jpg";
import prio_change_chart from "./prio_change_chart.jpg";
import prio_charge_out from "./prio_change_out.jpg";
import yield_chart from "./yiled_chart.jpg";

const context_task1 = `
__asm__ volatile ("sev");
GPIOA->ODR ^= 0x00000001;
GPIOC->ODR |= (1 << 8);
osDelay(10);
`;

const context_task2 = `
GPIOC->ODR &= ~(1<<8);
GPIOB->ODR ^= 0x00000001;
__asm__ volatile ("sev");
osDelay(10);
`;

const prio_change_task1 = `
 __asm__ volatile ("sev");
GPIOA->ODR ^= 0x00000001;
printf("Task 1\\r\\n");
priorityT2 = osThreadGetPriority(Task2Handle);
osThreadSetPriority(Task2Handle, priorityT2 + 1);
HAL_Delay(100);
`;

const prio_change_task2 = `
GPIOB->ODR ^= 0x00000001;
printf("Task 2\\r\\n");
osThreadSetPriority(Task2Handle, osPriorityNormal);
`;

const yield_task1 = `
uint8_t counter = 0;
/* Infinite loop */
for(;;)
{
__asm__ volatile ("sev");
GPIOA->ODR ^= 0x00000001;
printf("Task 1\\r\\n");
counter++;
if(counter == 2){
    counter = 0;
    osThreadYield();
}
}
`;

const yield_task2 = `
GPIOB->ODR ^= 0x00000001;
printf("Task 2\\r\\n");
`;

const man_task1 = `
void StartTask1(void *argument)
{
  /* USER CODE BEGIN StartTask1 */
  uint8_t counter = 0;
  const osThreadAttr_t Task2_attributes = {
          .name = "Task2",
          .priority = (osPriority_t) osPriorityNormal,
          .stack_size = 128
  };
  /* Infinite loop */
  for(;;)
  {
    __asm__ volatile ("sev");
    GPIOA->ODR ^= 0x00000001;
    printf("Task 1\\r\\n");
    ++counter;
    if(counter == 4){
        counter = 0;
        Task2Handle = osThreadNew(StartTask2, NULL, &Task2_attributes);
    }
    osDelay(1000);
  }
  /* USER CODE END StartTask1 */
}
`;

const man_task2 = `
GPIOB->ODR ^= 0x00000001;
printf("Task 2\\r\\n");
osThreadTerminate(Task2Handle);
printf("Task X\\r\\n");
`;


function STM_RTOS2(){
    return(
        <div className="em__post">
            <div className="em__post-title">
                <h1>CMSIS Tasks</h1>
            </div>

            <div className="em__post-section">
                <h3>Aim of this tutorial:</h3>
                <p>
                    In this tutorial we will learn how to create and delete tasks.
                    We will block them, yield to other tasks and change their priority,
                    all while monitoring their states and behavior.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Context switching:</h3>
                <p>
                    Open the ioc file of the previously created project.
                    Go to Middleware/FreeRTOS and open the Tasks and queues tab.
                    Change the name of the default task to Taks1 and the entry function to StartTask1.
                    Create a new task using the Add button, name it Task2, set the stack size to 128 words, and priority to normal.
                </p>
                <img className="img_task1_mx_rtos2" src={task1_mx} alt="task1_mx_rtos2"/>
                <img className="img_task2_mx_rtos2" src={task2_mx} alt="task2_mx_rtos2"/>
            </div>
            <div className="em__post-section">

                <p>
                    Add three ports as high-speed outputs, and add another port as event out.
                    I've used PA0, PB0, PC8 as outputs, and PC9 as event out.
                    Generate the project and open up the <i>app_freertos.c</i> source file.
                </p>
                <p>
                    The event output, when used can generate a one cycle wide high pulse.
                    This is useful to wake up peripheral or to synchronize events.
                    We will use this signal for digital trigger to the logic analyser.
                </p>
                <p>
                    Put the following lines into Task1:
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {context_task1}
                </SyntaxHighlighter>
                <p>
                    The <i>"sev"</i> assembly instruction creates the event out pulse.
                    The <i>ODR</i> register can be used to set or reset the output of GPIOx.
                    The xor line implements a toggle instruction on PA0, the or line sets PC8;
                    The last line blocks Task 1 for 10ms.
                    <br/>
                    Then the loop of Task2 looks like:
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {context_task2}
                </SyntaxHighlighter>
                <p>
                    Here, we reset the PC8, we toggle PB0 and release a pulse on the event out.
                    Task2 is blocked for 10ms too.
                    <br/>
                    Upload the firmware and open the logic analyzer software.
                    The first thing that you can notice is that the toggling frequency on PA0 and PB0 is 50Hz, which is expected.
                </p>
                <img className="img_context_logic_rtos2" src={context_logic} alt="context_logic_rtos2"/>
                <p>
                    The switching is done in 4.3us.
                    You can try enabling the floating point operations to check out the increased switching time.
                    Also, try to place the port operation into the <i>SysTick_Handler()</i> and <i>xPortPendSVHandler()</i>
                    to check the length of the different stages during context switch.
                    Oh, the event impulse is shown as a 42ns impulse, but that is not the case.
                    My sampling frequency is 24MSps so that is the minimum width.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Changing the priority of tasks:</h3>
                <p>
                    Remove the osDelay from the tasks.
                    This means that each task is continuously executed for 1ms, then the time slice is over and the other task is activated.
                </p>
                <img className="img_task_no_delay_rtos2" src={task_no_delay} alt="task_no_delay_rtos2"/>
                <p>
                    Now open the ioc file, go to the tasks and increase the priority of Task1.
                    Now, when the time slice of Task1 is over, the scheduler will check the queue, where Task1 has higher priority, so it will be executed.
                    Upload the firmware, and check that Task2 will never be executed.
                </p>
                <img className="img_task_no_hip_rtos2" src={task_no_hip} alt="task_no_hip_rtos2"/>
                <p>
                    This is nice, but what happens if we want to change the priority of a task during runtime?
                    <br/>
                    We can use the <i>osThreadGetPriority()</i> and <i>osThreadSetPriority()</i> functions.
                    Consider the following task scheduling chart:
                </p>
                <img className="img_prio_change_chart_rtos2" src={prio_change_chart} alt="prio_change_chart_rtos2"/>
                <p>
                    Let's create a system, where T1 has higher priority than T2.
                    Every time T1 is executed, it increases the priority of T2.
                    If T2 gets executed, the priority reverts to the original value.
                    <br/>
                    Create a new variable before the loop of T1 <i>osPriority_t priorityT2</i> to store the priority of T2.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {prio_change_task1}
                </SyntaxHighlighter>
                <p>
                    Then read the priority of T2, and increase it by one.
                    Finally there is a nasty instruction, the <b>Delay</b>.
                    It is highly advised to not use cycle wasting delays while using RTOS.
                    In our case the delay symbolises a lengthy operation just to decrease the messages that arrive on the COM port.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {prio_change_task2}
                </SyntaxHighlighter>
                <p>
                    In T2, we can reset the priority to osPriorityNormal and since there is a task with higher priority,
                    the scheduler will start that task immediately.
                </p>
                <img className="img_prio_charge_out_rtos2" src={prio_charge_out} alt="prio_charge_out_rtos2"/>
                <p>
                    The red trace is the GPIO manipulated by T1.
                    It is executed twice.
                    After that, T2 will have higher priority, so it executes a GPIO state change, and the priority is lowered,
                    so T1 will be executed.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Yielding tasks:</h3>
                <p>
                    We saw that in a preemptive scheduling scheme, after every tick the system puts the currently running
                    task into ready state and chooses another one to run depending on the round robin queue and priority.
                    In the case that a task finished some operation sooner than a single tick, the time will be wasted.
                    <br/>
                    In order to take advantage of the full tick length, we could use <i>osThreadYiled()</i>,
                    which moves the task from run state to ready and the next task with the same priority will be
                    executed until the end of the current time slice.
                </p>
                <img className="img_yield_chart_rtos2" src={yield_chart} alt="yield_chart_rtos2"/>
                <p>
                    Let's create a scenario, where T1 has a counter and at every second execution it will yield to T2.
                    <br/>
                    Change the previous project so that T1 and T2 have the same normal priority.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {yield_task1}
                </SyntaxHighlighter>
                <p>
                    In T1, create a variable and increment it in the loop.
                    When the counter is 2, reset it and yield to T2.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {yield_task2}
                </SyntaxHighlighter>
                <p>
                    Upload the firmware and debug the code. Check the GPIO outputs with logic analyser and the UART messages.
                    You can look at the task state changes in the RTOS debug perspective.
                </p>
            </div>

            <div className="em__post-section">
                <h3>Creating and deleting tasks:</h3>
                <p>
                    There are scenarios when an objective is completed or a new objective arises.
                    In these cases one might want to create or delete tasks.
                    We saw that <i>osThreadNew()</i> creates a task.
                    We can delete a task with the function <i>osThreadTerminate()</i>,
                    which removes the specified task from the ready list, and from the waiting list.
                    The memory that was allocated by the task is not automatically freed.
                    It should be freed before the task is deleted.
                    The TCB and the stack is freed by the IDLE task.
                </p>

                <p>
                    We will try to manually create and delete T2 from our previous code.
                    <br/>
                    In <i>app_freertos.c</i> go to the variables section and comment out the Task2 attributes.
                    Similarly comment out the creation of Task2 in <i>MX_FREERTOS_Init</i> function.
                    Now we only have a single task T1, and an unused task handle for T2.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {man_task1}
                </SyntaxHighlighter>
                <p>
                    In T1, let's have a counter and the attributes of T2.
                    At the fifth execution we create T2 and we reset the counter.
                    Since T1 and T2 are of equal priority, on the next time slice T2 will be executed.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {man_task2}
                </SyntaxHighlighter>
                <p>
                    The code above is in the loop of T2.
                    If everything goes well, the last message (Task X) should not appear on the COM port.
                </p>
                <p>
                    <b>Exercise problem:</b> Create a project with two tasks, T1 should have a higher priority.
                    T1 listens to the UART RX and parses the received data into a global buffer.
                    If a newline character is detected T1 increases the priority of T2 and yields the execution.
                    T2 sends an "OK" back on UART.
                    If the received message is "ON" and a T3 task is not present, T2 creates a task T3 of equal priority to T1.
                    If there is a T3 task present, T2 sends back the message "T3 exists".
                    if the received message is "OFF" and T3 task is present, T2 deletes the task T3.
                    If there is no T3 task present, T2 sends back the message "T3 does not exits".
                    Finally, T2 changes its priority to the original one.
                    T3 blinks an LED with a period of 1s.
                </p>
                <p>
                    The solution can be found <a href="https://gitlab.com/stm32_mcu_group/stm32_rtos/solutions.git">here</a>
                </p>
            </div>


            <div className="em__post-navigation">

                <NavLink to="./../stm-rtos-1">
                    <Button btnID={"leftBTN"} buttonSize="btn--medium"> Previous Post</Button>
                </NavLink>

                <NavLink to="./../stm-rtos-3">
                    <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
                </NavLink>
            </div>
        </div>
    );
}

export default STM_RTOS2;