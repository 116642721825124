import React from "react";
import "./mcu_hal15.css";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";

import adc_hard from "./adc_hard.jpg";
import adc_setup from "./adc_setup.jpg";
import adc_con from "./poll_adc_imp.jpg";
import adc_dma from "./adc_dma.jpg";

const poll_setup = `
init_gauge(0,4095);
HAL_ADCEx_Calibration_Start(&hadc1, ADC_SINGLE_ENDED);
`;

const poll_loop = `
HAL_ADC_Start(&hadc1);
HAL_ADC_PollForConversion(&hadc1, 1);
i = HAL_ADC_GetValue(&hadc1);
update_gauge(i);
HAL_Delay(10);
`;

const irq_setup = `
volatile uint8_t adc_irq_flag = 0;
volatile uint16_t adc_val = 0;
`;

const irq_loop = `
if(adc_irq_flag){
    update_gauge(adc_val);
    adc_irq_flag = 0;
    HAL_ADC_Start_IT(&hadc1);
}
`;

const irq_func = `
void HAL_ADC_ConvCpltCallback(ADC_HandleTypeDef *hadc){
    adc_irq_flag = 1;
    adc_val = HAL_ADC_GetValue(hadc);
}
`;

const dma_setup = `
uint16_t adc_data[5] = {0};
uint32_t adc_filt = 0;
`;

const dma_loop = `
if(adc_irq_flag){
    adc_filt = (adc_data[0] + adc_data[1] + adc_data[2] + adc_data[3] + adc_data[4]) / 5;
    update_gauge((uint16_t)adc_filt);
    adc_irq_flag = 0;
    HAL_ADC_Start_DMA(&hadc1, (uint32_t*)adc_data, 5);
}
`;

function MCU_HAL15(){
    return(
        <div className="em__post">
            <div className="em__post-title">
                <h1>ADC regular channel</h1>
            </div>

            <div className="em__post-section">
                <h3>Aim of this tutorial:</h3>
                <p>
                    So far we've covered the timing and most of the basic digital peripheral of the STM32 MCU.
                    If we want to interact with the analogue world, we should be able to read and write analogue signals.
                    In this tutorial, we will begin our analog journey by configuring a single channel of the
                    analogue to digital converter (ADC), read the voltage from a resistive divider circuit and display it on an I2C OLED display.
                </p>
            </div>

            <div className="em__post-section">
                <h3>ADCs in general:</h3>
                <p>
                    In electronics, ADCs are systems that convert continuous-time and continuous-amplitude analog signals
                    into discrete-time and discrete-amplitude digital signals using some sort of sampling method.
                    This conversion involves a quantization, so a small noise will always be present (quantization noise).
                </p>
                <p>
                    There are different types of ADCs. Three of the most used ones are:
                    <ul>
                        <li>
                            Flash ADCs - use a linear voltage ladder (resistive or capacitive division) and
                            comparators to quantize the analog signal.
                            The output of the comparator is then fed into a priority encoder and/or error corrector.
                            These ADCs have almost no latency, they require a huge number of components so they become  bulky
                            (this increases power consumption too) with the increase of resolution,
                            but they are the fastest converter. You can read more about flash
                            ADC-s <a href="https://en.wikipedia.org/wiki/Flash_ADC">here</a> and <a href="https://www.electronics-tutorials.ws/combination/analogue-to-digital-converter.html">here</a>.
                        </li>
                        <li>
                            Successive approximation (SAR) ADCs - use a sample and hold circuit,
                            an approximation register with digital to analog converter and an analog comparator.
                            The input voltage is sampled, and the digital representation is found using a binary search algorithm.
                            This ADC has high accuracy, low latency, low power consumption, but the design complexity is high (lower then the equivalent flash ADC).
                            You can read more about flash
                            ADC-s <a href="https://en.wikipedia.org/wiki/Successive-approximation_ADC">here</a> and <a href="https://circuitdigest.com/article/how-does-successive-approximation-sar-adc-work-and-where-is-it-best-used">here</a>.
                        </li>
                        <li>
                            Delta-Sigma ADCs - use a complex <a href="https://en.wikipedia.org/wiki/Delta-sigma_modulation">delta-sigma</a> modulator with a digital filter and decimator.
                            Most of the ADC circuit is digital, so the design can be implemented on a wide range of ICs.
                            The performance will have only minor drifts with time and temperature. The digital output has always the same slope as the analog input.
                            They are linear, and they do not require external sample and hold circuits. High resolution can be achieved at the cost of conversion time.
                            The latency is large and the digital circuits are complex.
                            You can read more about delta-sigma ADCs <a href="https://www.rpi.edu/dept/ecse/rta/LMS/Delta-Sigma_ADCs.pdf">here</a>,
                            and <NavLink to="./../../../fpga-tut/fpga-tut-7">here</NavLink> I have an implementation in FPGA.

                        </li>
                    </ul>
                </p>
            </div>
            <div className="em__post-section">
                <h3>ADCs in the STM32:</h3>
                <p>
                    The STM32G4 has 3 SAR ADCs configurable to 6b, 8b 10b or 12b wide operation.
                    Each ADC has up to 19 multiplexed channels, which can be configured as single ended or differential inputs.
                    The conversions can be performed in single, continuous or discontinuous mode.
                    The result can be stored in a left- or right-aligned 16b register.
                    ADC1 and ADC2 are tightly coupled and can operate in dual mode (ADC1 is master).
                    Analog watchdog can be used to detect if the input voltage goes outside a user-defined threshold.
                    The hardware oversampler increases the performance dut does not load the CPU.
                    Low-power mode is implemented for low consumption, low frequency operation.
                </p>
                <Popup trigger={<img className="img_adc_hard_hal15 clickable_img" src={adc_hard} alt="spi_adc_hard_hal15"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={adc_hard} alt="spi_adc_hard_hal15" />
                    )}
                </Popup>

                <p>
                    The ADCs have self-calibration routines, gain and offset compensation.
                    In addition to the external channels, there are several internal channels to monitor the:
                    reference voltage, internal temperature, the battery voltage or to sample the internal operation amplifiers.
                </p>
                <p>
                    The ADC can be started using software, or hardware (external trigger or internal timer).
                    Each ADC can convert a single channel or it can scan a sequence of channels.
                    This can be done once per trigger or continuously.
                </p>
                <p>
                    The ADC can generate interrupts at the end of conversion, at the end of sampling,
                    when the ADC is ready for sampling, at the end of sequence or at a pre programmed watchdog event.
                </p>



                <Popup trigger={<img className="img_adc_setup_hal15 clickable_img" src={adc_setup} alt="spi_adc_setup_hal15"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={adc_setup} alt="spi_adc_setup_hal15" />
                    )}
                </Popup>

                <p>
                    Let's create a new project in the usual manner. Enable the I2C under connectivity.
                    Then set the IN1 channel of Analog/ADC1 to single-ended.
                    We know that the accuracy of the SAR ADC increases if we increase the sampling time,
                    so let's change it from 2.5 Cycles to 92.5 Cycles and generate the project.
                </p>

                <p>
                    We will use the SSD1306 OLED to display the adc data, so download the source files from <a href="https://gitlab.com/stm32_mcu_group/stm32_hal/15_adc_regular.git">here</a>.
                    the display drivers are the same as in the I2C tutorial, the new addition is a circular gauge which we will bes using now.
                </p>

            </div>

            <div className="em__post-section">
                <h3>Polling the ADC result:</h3>
                <p>
                    The simplest ADC mode is the polling mode.
                    First we need to initialise the display gauge.
                    Since we set the ADC to 12b resolution, we can set the gauge interval to [0, 4095].
                    Next we calibrate the ADC to remove any offset or gain errors.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {poll_setup}
                </SyntaxHighlighter>

                <p>
                    Finally, we can start the ADC in the infinite loop.
                    We poll the conversion, and in case that the data is ready, we read the value, and display it on the OLED.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {poll_loop}
                </SyntaxHighlighter>
                <p>
                    Upload the generated firmware.
                    Connect the power, ground, SCL, SDA to the OLED display.
                    Grab a potentiometer, connect the outer pins to power and ground.
                    Connect a small current limiting resistor to the middle pin, and connect the resistor to pin CN7/28 on the board.
                    I've got a 10k potentiometer with 470R resistor in my setup.
                </p>
                <p>
                    You should see a circular gauge on the screen with a wobbly indicator,
                    which changes position in function of the resistance of the potentiometer.
                </p>

                <img className="img_adc_con_hal15" src={adc_con} alt="spi_adc_con_hal15"/>

            </div>

            <div className="em__post-section">
                <h3>ADC with interrupts:</h3>
                <p>
                    The polling method is not very efficient since the CPU will stall until the conversion or the
                    specified time-out interval is over.
                    Let's modify our code so that we can read the ADC value when the conversion interrupt is asserted.
                </p>

                <p>
                    First, enable the ADC interrupts in CubeMX under ADC1/NVIC settings.
                    Then we need two volatile global variables in the main.c scope.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {irq_setup}
                </SyntaxHighlighter>
                <p>
                    The <i>irq_flag</i> will show when there is new data available. We will change this value in the main function and in the interrupt,
                    so it's essential to mark as volatile. That way the compiler won't do naughty things to the variable.
                    The <i>adc_val</i> will hold the conversion result.
                </p>

                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {irq_func}
                </SyntaxHighlighter>
                <p>
                    The interrupt callback is quite simple.
                    If the routine is called, set the flag, and get the new value.
                </p>
                <p>
                    The main function needs some minor adjustments.
                    The calibration remains, but we must start the data acquisition outside the loop using
                    the <i>HAL_ADC_Start_IT(&hadc1)</i> function.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {irq_loop}
                </SyntaxHighlighter>
                <p>
                    In the infinite loop we can check if there is a new data available.
                    If there is, then we can update the data on the screen, we can reset the flag, and finally we can start a new conversion.
                    In other cases the CPU can work on other tasks.
                    You can upload and debug the code.
                    Visually it's the same wobbly indicator as before, but this time the main routine stalls less.
                </p>
            </div>

            <div className="em__post-section">
                <h3>ADC with DMA:</h3>
                <p>
                    Finally, it would be nice to reduce the shaking of the indicator, which is due to acquisition and hardware introduced noises.
                    We can solve this problem by implementing a simple filter (more on filtering methods in the DSP section).
                    The simplest filtering method is a moving average filter, which has a buffer with depth of N, sums up the values, then divides by N.
                    This can be done sample-based or frame based. The first one implements a FIFO buffer, and with every sampled data we get a filtered output.
                    The second method gathers N values and gives a filtered value only when the whole buffer is filled, after which the buffer is reset.
                    We will implement the second filter using ADC with DMA.
                </p>
                <p>
                    Open CubeMX, and go to Analog/ADC1/DMA Settings.
                    Set the Continuous conversion mode to Enabled, this way we can have multiple samples with a single instruction.
                    Add a new DMA request with normal mode and half word data width (12b data fits nicely in a 16b register).

                </p>
                <Popup trigger={<img className="img_adc_dma_hal15 clickable_img" src={adc_dma} alt="spi_adc_dma_hal15"/>} modal nested>
                    {close => (
                        <img className="em__img_full" src={adc_dma} alt="spi_adc_dma_hal15" />
                    )}
                </Popup>
                <p>
                    In the interrupt we won't need to read the adc data, so leave only the flag.
                    Furthermore, we need two local variables in the main function.
                    <i>adc_data</i> will contain the raw samples. This time we have N = 5.
                    <i>adc_filt</i> will contain the filtered value.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {dma_setup}
                </SyntaxHighlighter>
                <p>
                    Start the acquisition after the calibration using the <i>HAL_ADC_Start_DMA(&hadc1, (uint32_t*)adc_data, 5);</i> function.
                    This way we can not only choose the adc handle, but the adc_data will be situated in the DMA zone.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {dma_loop}
                </SyntaxHighlighter>
                <p>
                    In the loop, we can check for data completion.
                    If the flag is set, then we have a full buffer, so we can filter the data, and update the gauge.
                    Finally, we can reset the flag and start the conversion again.
                    This way we reduced the noise in the signal using a primitive digital filter, and we learned how to use DMA to store multiple samples on the same ADC channel.
                    Of course, this could be refined by adding an analog filter before the ADC
                    (from a simple RC low-pass to complicated multiple feedback active filters),
                    or by designing sophisticated digital filters like an Equiripple FIR or Elliptic IIR,
                    but that is beyond the scope of this tutorial.
                </p>
                <p>
                    If you did not download the files before, you could do it from <a href="https://gitlab.com/stm32_mcu_group/stm32_hal/15_adc_regular.git">this</a> repo.
                </p>
            </div>

            <div className="em__post-navigation">

                <NavLink to="./../stm-hal-14">
                    <Button btnID={"leftBTN"} buttonSize="btn--medium"> Previous Post</Button>
                </NavLink>

                <NavLink to="./../stm-hal-16">
                    <Button btnID={"rightBTN"} buttonSize="btn--medium"> Next Post</Button>
                </NavLink>
            </div>
        </div>
    );
}

export default MCU_HAL15;