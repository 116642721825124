import React from "react";
import "./stm_rtos7.css";
import Popup from "reactjs-popup";
import {NavLink} from "react-router-dom";
import {Button} from "../../../../common";
import AtomOneDark from "react-syntax-highlighter/src/styles/hljs/atom-one-dark";
import SyntaxHighlighter from "react-syntax-highlighter";

import stim_base_mx from "./stim_base_mx.jpg";
import stim_tim_mx from "./stim_tim_mx.jpg";
import stim_one_logic from "./stim_one_logic.jpg";
import stim_periodic_logic from "./stim_periodic_logic.jpg";

const stim_t1_once = `
const uint8_t string[] = "Task1 action. \\r\\n";
osTimerStart(sTIM1Handle, 1000);
HAL_GPIO_WritePin(TA_GPIO_Port, TA_Pin, GPIO_PIN_SET);
/* Infinite loop */
for(;;){
    HAL_GPIO_WritePin(TB_GPIO_Port, TB_Pin, GPIO_PIN_SET);
    HAL_UART_Transmit(&huart2, string, strlen((const char*) string), 100);
    HAL_GPIO_WritePin(TB_GPIO_Port, TB_Pin, GPIO_PIN_RESET);
    osDelay(500);
}
`;

const stim_cb_once = `
void Callback1(void *argument){
  /* USER CODE BEGIN Callback1 */
    const uint8_t string[] = "sTIM1 action. \\r\\n";
    HAL_UART_Transmit(&huart2, string, strlen((const char*) string), 100);
    HAL_GPIO_WritePin(TA_GPIO_Port, TA_Pin, GPIO_PIN_RESET);
  /* USER CODE END Callback1 */
}
`;


function STM_RTOS7(){
    return(
        <div className="em__post">
            <div className="em__post-title">
                <h1>CMSIS Software timers</h1>
            </div>

            <div className="em__post-section">
                <h3>Aim of this tutorial:</h3>
                <p>
                    In this tutorial, we will use software timers in one-shot and periodic mode to periodically call a function.
                </p>
            </div>

            <div className="em__post-section">
                <h3>About software timers:</h3>
                <p>
                    Software timers are one of the standard components of RTOS,
                    they allow a callback to be executed at a set time (in one-shot mode) or to be executed with a given period (in periodic mode).
                    <span className="em__post_warning">It is essential that the callback functions are non-blocking (eg. do not use osDelay()).</span>
                    These timers are at most as precise as the time base source, so it is recommended to change the SysTick to a hardware timer.
                    Furthermore, the callback time of the software timer can't be less than the tick period of the RTOS (1ms in our case).
                    When the use of timers is enabled, the scheduler creates a timer service task which controls and monitors all the created software timers.
                    A message queue is also created to send commands to the timer task.
                </p>
            </div>

            <div className="em__post-section">
                <h3>One-shot timing:</h3>
                <p>
                    Open a previously created project.
                    Under System Core / SYS change the Timebase Source from SysTick to a basic timer (like TIM6).
                </p>
                <Popup trigger={<img className="img_stim_base_mx_rtos7 clickable_img" src={stim_base_mx} alt="stim_base_mx_rtos7"/>} modal nested>
                    {
                        close =>(
                            <img className="em__img_full" src={stim_base_mx} alt="stim_base_mx_rtos7"/>
                        )
                    }
                </Popup>
                <p>
                    Create a single task (Task1 or T1) with normal priority.
                    Under Timers and Semaphores tab add a new timer as <i>osTimerOnce</i> type.
                </p>
                <Popup trigger={<img className="img_stim_tim_mx_rtos7 clickable_img" src={stim_tim_mx} alt="stim_tim_mx_rtos7"/>} modal nested>
                    {
                        close =>(
                            <img className="em__img_full" src={stim_tim_mx} alt="stim_tim_mx_rtos7"/>
                        )
                    }
                </Popup>
                <p>
                    Generate the project files and open the <i>app_freertos.c</i> source.
                    You can see that the timer is created before the task using the function <i>osTimerNew</i>.
                    T1 will be used to start the timer ant to show sign of life information:
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {stim_t1_once}
                </SyntaxHighlighter>
                <p>
                    Here 1000 ticks means that the timer will execute the callback after 1s elapsed from calling the start.
                </p>
                <SyntaxHighlighter language="c" style={AtomOneDark}>
                    {stim_cb_once}
                </SyntaxHighlighter>
                <p>
                    Upload the firmware and test the project using the serial port and a logic analyser (alternatively you could toggle the on-board LED instead of PA0).
                </p>
                <img className="img_stim_one_logic_rtos7" src={stim_one_logic} alt="stim_one_logic_rtos7"/>
            </div>

            <div className="em__post-section">
                <h3>Periodic timing:</h3>
                <p>
                    In order to test the periodic timing, we have to make two minor changes.
                    First, open up the ioc file, go to Middleware/FREERTOS and in Timers adn Semaphores tab, change the timer type to
                    <i>osTimerPeriodic</i>.
                    Next, in the callback function, change the <i>HAL_GPIO_WritePin</i> to <i>HAL_GPIO_TogglePin</i>.
                    Upload the firmware and observe the behaviour in debug mode with RTOS perspective.
                </p>
                <img className="img_stim_periodic_logic_rtos7" src={stim_periodic_logic} alt="stim_periodic_logic_rtos7"/>
            </div>


            <div className="em__post-navigation">

                <NavLink to="./../stm-rtos-6">
                    <Button btnID={"leftBTN"} buttonSize="btn--medium"> Previous Post</Button>
                </NavLink>

                <NavLink to="./..">
                    <Button btnID={"rightBTN"} buttonSize="btn--medium">RTOS Projects</Button>
                </NavLink>
            </div>
        </div>
    );
}

export default STM_RTOS7;